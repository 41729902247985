import Category from '@/entities/common/Category';
import Pagination from '@/entities/common/Pagination';
import axios from 'axios';

type categoryPaginationType = {
  categories: Category[];
  meta: Pagination;
};

type eventType = {
  type: string;
  entity_type: string;
  entity_id: number;
};

export default {
  async getCategories(
    type: string,
    moduleId: number,
    page: number,
  ): Promise<categoryPaginationType> {
    const categories: Category[] = [];

    const payload = await axios.post(`/categories`, {
      page,
      type,
      module_id: moduleId,
    });

    payload.data.success.categories.forEach((category: any) => {
      categories.push(new Category(category));
    });

    const meta = new Pagination(payload.data.success.meta.pagination);

    return {
      categories,
      meta,
    };
  },

  async fileUpload(formData: any) {
    const response = await axios.post('/file/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return [response.data.success.token, response.data.success.url];
  },

  async getFileToken() {
    const response = await axios.get('/file/token');

    return response.data.success.token;
  },

  eventsView(events: Array<eventType>) {
    return axios.put('/process/events', { events });
  },
};
