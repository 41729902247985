import CorrectName from '@/entities/common/CorrectName';

export default interface IFavorite {
  id: number;
  moduleCode: CorrectName;
  isFavorite: boolean;

  setFavorite(status: boolean): void;
}

export function isIFavorite(x: any): x is IFavorite {
  return x && x.id && x.moduleCode && 'isFavorite' in x;
}
