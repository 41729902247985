import BaseWidget from '@/entities/modules/dashboard/BaseWidget';
import ImageSource, { createImageLgSource } from '@/entities/common/sources/ImageSource';
import CustomRoute from '@/entities/common/CustomRoute';
import { LearningItemTypes } from '@/entities/modules/learning-items/LearningItemType';

export default class NextMaterialWidget extends BaseWidget {
  materialId: number;
  materialName: string;
  moduleName: string;
  moduleId: number;
  materialType: string;
  deeplink: string;
  to: Object;
  image?: ImageSource;

  constructor(payload: any) {
    super(payload);

    this.materialId = parseInt(payload.data.id, 10);
    this.materialName = payload.data.name;
    this.moduleName = payload.data.module_name;
    this.moduleId = parseInt(payload.data.module_id, 10);
    this.materialType = payload.data.type;
    this.deeplink = payload.data.deeplink;
    this.type = payload.type;

    this.image = payload.data.image_wide
      ? createImageLgSource(payload.data.image_wide)
      : createImageLgSource(payload.data.image);

    this.to = {
      name:
        this.type === LearningItemTypes.TRACK
          ? CustomRoute.TO_LEARNING_TRACK
          : CustomRoute.TO_LEARNING_PROGRAMS_MATERIAL,
      params: {
        moduleId: this.moduleId,
        id: this.materialId,
      },
      query: { from: 'continue-learning' },
    };
  }
}
