import { each, has } from 'lodash';
import axios from 'axios';
import { createPasswordRecoveryCode } from '@/entities/modules/auth/PasswordRecoveryCode';
import { createPhoneOrMailVerificationCode } from '@/entities/modules/auth/PhoneOrMailVerificationCode';
import { createVerifyFormData } from '@/entities/modules/auth/VerifyFormData';
import RegexpPolicy from '@/entities/modules/settings/password-policies/RegexpPolicy';
import FieldMathPolicy from '@/entities/modules/settings/password-policies/FieldMathPolicy';
import { prepareHtml } from '@/providers/wysiwygRedactorProvider';
import User from '@/entities/modules/auth/User';
import DeadTokenException from '@/entities/common/exceptions/DeadTokenException';
import Pagination from '@/entities/common/Pagination';

function parseDataToPasswordRestore(
  isEmail,
  emailOrPhone,
  code,
  password,
  companyId,
  preferredType,
) {
  const params = {};

  if (isEmail) {
    params.type = 'email';
    params.email = emailOrPhone;
  } else {
    params.type = 'phone';
    params.phone = emailOrPhone;
  }

  params.code = code;
  params.password = password;
  params.preferred_type = preferredType;

  if (companyId) {
    params.company_id = companyId;
  }

  return params;
}

function getPasswordPoliciesFromResponse(response) {
  const passwordPolicies = [];

  if (has(response, 'data.success.password_validation_result')) {
    each(response.data.success.password_validation_result, passwordPolicy => {
      if (passwordPolicy.type === 'regexp') {
        passwordPolicies.push(new RegexpPolicy(passwordPolicy));
      } else if (passwordPolicy.type === 'field_match') {
        passwordPolicies.push(new FieldMathPolicy(passwordPolicy));
      }
    });
  }

  return passwordPolicies;
}

export default {
  /**
   * Авторизация пользователя
   * @param companyId
   * @param login
   * @param password
   * @returns {Promise<void | never>}
   */
  login(companyId, login, password) {
    return axios
      .post(
        '/auth',
        {
          company_id: companyId,
          login,
          pass: password,
        },
        { headers: { globalErrorHandler: false } },
      )
      .then(response => new User(response.data.success));
  },

  /**
   * Вернет url для перехода oauth2
   * @param id
   * @param redirectUri
   * @returns {Promise<AxiosResponse<any>>}
   */
  getOAuthExternalUrl(id, redirectUri) {
    return axios
      .get(`/sso/oauth/${id}/implicit-flow/auth-url?redirect_uri=${redirectUri}`)
      .then(response => response.data.success.implicit_flow_auth_url);
  },

  /**
   * Проверит oauth2 токен на корректность и авторизует пользователя, если всё ок
   * @param data
   * @returns {Promise<Readonly<User>>}
   */
  checkOAuthToken(data) {
    return axios
      .post('/sso/oauth/check-auth', data, { headers: { globalErrorHandler: false } })
      .then(response => new User(response.data.success));
  },

  async validateRegistrationPassword(companyId, login, password) {
    const response = await axios.post(
      '/auth/registration/validate-password',
      {
        login,
        password,
        company_id: companyId,
      },
      { headers: { globalErrorHandler: false } },
    );

    return getPasswordPoliciesFromResponse(response);
  },

  async verificationPassword(password) {
    return axios.post(
      '/verification/password',
      {
        password,
      },
      { headers: { globalErrorHandler: false } },
    );
  },

  register(companyId, login) {
    return axios
      .post(
        'auth/registration',
        {
          login,
          company_id: companyId,
        },
        { headers: { globalErrorHandler: false } },
      )
      .then(response => new User(response.data.success));
  },

  async refreshToken(token) {
    return axios.post(
      '/auth/refresh-token',
      { refresh_token: token },
      { headers: { globalErrorHandler: false } },
    );
  },

  async getProfile() {
    try {
      const response = await axios.get('/profile?withGroups=1', {
        headers: { globalErrorHandler: false },
      });

      return new User({
        profile: response.data.success,
      });
    } catch (e) {
      if (
        has(e, 'response.data.error.code') &&
        has(e, 'response.status') &&
        e.response.data.error.code === 3009 &&
        e.response.status === 401
      ) {
        throw new DeadTokenException();
      }

      throw e;
    }
  },

  sendAccessCode(code) {
    return axios
      .post(
        '/require-code',
        {
          code,
        },
        { headers: { globalErrorHandler: false } },
      )
      .then(response => response.data.success);
  },

  getRecoveryCode(isEmail, emailOrPhone, companyId, preferredType) {
    const params = {};

    if (isEmail) {
      params.type = 'email';
      params.email = emailOrPhone;
    } else {
      params.type = 'phone';
      params.phone = emailOrPhone;
    }

    if (companyId) {
      params.company_id = companyId;
    }

    params.preferred_type = preferredType;

    return axios
      .post('/recovery/get-code', params, { headers: { globalErrorHandler: false } })
      .then(response => createPasswordRecoveryCode(response.data.success));
  },

  /**
   * Вернет true, если пользователь правильно ввел код
   * @param isEmail
   * @param emailOrPhone
   * @param code
   * @param companyId
   * @param preferredType
   */
  validateRecoveryCode(isEmail, emailOrPhone, code, companyId, preferredType) {
    const params = {};

    if (isEmail) {
      params.type = 'email';
      params.email = emailOrPhone;
    } else {
      params.type = 'phone';
      params.phone = emailOrPhone;
    }
    params.code = code;
    params.preferred_type = preferredType;

    if (companyId) {
      params.company_id = companyId;
    }

    return axios
      .post('/recovery/validate-code', params, { headers: { globalErrorHandler: false } })
      .then(response => ({ companyId: parseInt(response.data.success.company_id, 10) }));
  },

  async validatePasswordRestorePassword(
    isEmail,
    emailOrPhone,
    code,
    password,
    companyId,
    preferredType = null,
  ) {
    const response = await axios.post(
      '/recovery/validate-password',
      parseDataToPasswordRestore(isEmail, emailOrPhone, code, password, companyId, preferredType),
      { headers: { globalErrorHandler: false } },
    );

    return getPasswordPoliciesFromResponse(response);
  },

  saveRecoveryPassword(isEmail, emailOrPhone, code, password, companyId, preferredType) {
    return axios
      .post(
        '/recovery/save-password',
        parseDataToPasswordRestore(isEmail, emailOrPhone, code, password, companyId, preferredType),
        { headers: { globalErrorHandler: false } },
      )
      .then(() => true);
  },

  async validateOldPassword(password) {
    await axios.post(
      '/user/validate-old-password',
      { password },
      { headers: { globalErrorHandler: false } },
    );
  },

  async validateEditPassword(password) {
    const response = await axios.post(
      '/user/validate-password',
      { password },
      { headers: { globalErrorHandler: false } },
    );

    return getPasswordPoliciesFromResponse(response);
  },

  async editPassword(oldPassword, newPassword) {
    const response = await axios.post(
      '/user/update-password',
      {
        old_password: oldPassword,
        new_password: newPassword,
      },
      { headers: { globalErrorHandler: false } },
    );

    return response.data.success;
  },

  /**
   * Смена аватара в профиле пользователя
   * @param avatar
   * @returns {Promise<boolean | never>}
   */
  editAvatarUserProfile(avatar) {
    return axios
      .post('/user', { avatar }, { headers: { globalErrorHandler: false } })
      .then(response => new User(response.data.success));
  },

  getLicense() {
    return axios.get('/auth/license').then(response => prepareHtml(response.data.success.license));
  },

  licenseAgree() {
    return axios.post('/auth/license').then(response => response.data.success);
  },

  /**
   * Отправляет информацию о пользователе при верификации Региона/городов
   * @param verificationInfo
   * @returns {Promise<boolean | never>}
   */
  saveVerifiedInfo(verificationInfo) {
    return axios
      .post('/verification/user-info', verificationInfo, { headers: { globalErrorHandler: false } })
      .then(() => true);
  },

  /**
   * Верификация номера телефона
   * @param phone
   * @returns {Promise<AxiosResponse<any> | never>}
   */
  verificationUserPhone(phone) {
    return axios
      .post('/verification/phone', { phone }, { headers: { globalErrorHandler: false } })
      .then(response => createPhoneOrMailVerificationCode(response.data.success));
  },

  /**
   * Проверка кода доступа для телефона
   * @param code
   * @returns {Promise<boolean | never>}
   */
  verificationPhoneCode(code) {
    return axios
      .post('/verification/phone-code', { code }, { headers: { globalErrorHandler: false } })
      .then(() => true);
  },

  /**
   * Проверка кода доступа для почтового ящика
   * @param code
   * @returns {Promise<boolean | never>}
   */
  verificationMailCode(code) {
    return axios
      .post('/verification/email-code', { code }, { headers: { globalErrorHandler: false } })
      .then(() => true);
  },

  getVerificationCode(phone) {
    return axios
      .get('/verification/phone-resend', phone)
      .then(response => createPhoneOrMailVerificationCode(response.data.success));
  },

  verificationUserMail(email) {
    return axios
      .post('/verification/email', { email }, { headers: { globalErrorHandler: false } })
      .then(response => createPhoneOrMailVerificationCode(response.data.success));
  },

  /**
   * Верификация формы анкетных данных
   * @returns {Promise<AxiosResponse<any> | never>}
   */
  getVerificationForm() {
    return axios
      .get('/forms/questions')
      .then(response => createVerifyFormData(response.data.success));
  },

  /**
   * Отправка формы анкетных данных
   * @param answers
   * @returns {Promise<AxiosResponse<any> | never>}
   */
  sendVerificationForm(answers) {
    return axios
      .post('/forms/answers', { answers }, { headers: { globalErrorHandler: false } })
      .then(response => response.data.success);
  },

  /**
   * Список возможных ответов для вопросов dropdown раздела «Анкетирование»
   * @param questionId
   * @param searchValue
   * @param page
   */
  getAvailableAnswers(questionId, searchValue, page) {
    const convertSearchValue = encodeURIComponent(searchValue);

    const search = convertSearchValue.length > 0 ? `search=${convertSearchValue}` : 'search=';

    return axios
      .get(`/forms/available_answers/${questionId}?page=${page}&${search}`)
      .then(response => {
        const answers = response.data.success.available_answers;

        const meta = new Pagination(response.data.success.meta.pagination);

        return {
          answers,
          meta,
        };
      });
  },

  /**
   * Логин через номера телефона
   * @param phone
   * @param companyId
   * @returns {Promise<Readonly<PasswordRecoveryCode>>}
   */
  async smsAuthUserPhone(phone, companyId) {
    const response = await axios.post('/sms-auth/send-code', {
      phone,
      company_id: companyId,
    });

    return createPasswordRecoveryCode(response.data.success);
  },

  /**
   * Проверка кода из СМС для логина
   * @param code
   * @param phone
   * @param companyId
   * @returns {Promise<User>}
   */
  async smsAuthUserPhoneCode(code, phone, companyId) {
    const response = await axios.post('/sms-auth/validate-code', {
      code,
      phone,
      company_id: companyId,
    });

    return new User(response.data.success);
  },
};
