<template>
  <app-tooltip>
    <div
      class="app-deadline app-tooltip__outer"
      :class="deadlineClasses"
    >
      <div
        v-if="tooltip"
        class="app-tooltip__tooltip"
      >
        {{ tooltip }}
      </div>
      <div
        v-if="$slots.icon"
        class="app-deadline__icon"
      >
        <slot name="icon"></slot>
      </div>
      <DeadlineClockIcon
        v-else
        :is-white="isWhite"
      />
      <span
        v-if="isShowDeadlineText"
        class="app-deadline__date"
      >
        {{ deadline }}
      </span>
    </div>
  </app-tooltip>
</template>

<script>
import DeadlineClockIcon from '@/components/svg/icons/deadline-clock.vue';
import AppTooltip from '@/components/helpers/tooltips/tooltip.vue';

export default {
  name: 'date-passing',

  components: {
    DeadlineClockIcon,
    AppTooltip,
  },

  props: {
    deadline: {
      type: String,
      default: '',
    },

    status: {
      type: String,
      default: '',
    },

    tooltip: {
      type: String,
      default: '',
    },

    isShowDeadlineText: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    isWhite() {
      return this.status === 'orange' || this.status === 'success' || this.status === 'error';
    },

    deadlineClasses() {
      const classes = [];

      if (this.status) {
        classes.push(`app-deadline--${this.status}`);

        if (!this.tooltip) {
          classes.push('app-deadline--long');
        }
      }

      return classes;
    },
  },
};
</script>
