import slugProvider from '@/providers/slugProvider';
import deepLinksProvider from '@/providers/deepLinksProvider';
import ImageSource, { createImageFromString } from '@/entities/common/sources/ImageSource';
import CustomRoute from '@/entities/common/CustomRoute';

export default class InfoMaterial {
  id: number;
  categoryId: number;
  subcategoryId: number;
  name: string;
  image?: ImageSource;
  type: string;
  isNew: boolean;
  url?: string;
  urlTitle?: string;
  useIntegrationToken?: boolean;
  isViewed: boolean;
  to?: Object;
  moduleName: string;

  constructor(payload: any) {
    this.id = parseInt(payload.entity_id, 10);
    this.categoryId = parseInt(payload.category_id, 10);
    this.subcategoryId = parseInt(payload.subcategory_id, 10);
    this.name = payload.name;
    this.type = payload.type;
    this.isNew = parseInt(payload.is_new, 10) === 1;

    if (this.type === 'link') {
      this.url = deepLinksProvider(payload.url);
      this.urlTitle = payload.url_title;
      this.useIntegrationToken = parseInt(payload.use_integration_token, 10) === 1;
    }
    this.isViewed = parseInt(payload.viewed, 10) === 1;
    this.image = createImageFromString(payload.image.origin.url);
    this.moduleName = payload.module_name;

    if (this.type === 'document') {
      this.to = {
        name: CustomRoute.TO_INFORMATION_MATERIAL,
        params: {
          id: this.id,
          slug: slugProvider(this.name),
          categoryId: this.categoryId,
          categorySlug: 'not-provided',
          subcategoryId: this.subcategoryId,
          subcategorySlug: 'not-provided',
        },
      };
    }
  }
}
