// eslint-disable-next-line no-shadow
enum CustomRoute {
  TO_AUTH_LOGIN = 'login',
  TO_AUTH_LOGIN_PHONE = 'login-phone',
  TO_AUTH_LOGIN_OAUTH = 'login-oauth',
  TO_AUTH_ENTRY_POINT = 'login-entry-point',
  TO_AUTH_REGISTER = 'register',
  TO_AUTH_PASSWORD_RESET = 'password-reset',
  TO_AUTH_PASSWORD_RESET_BY = 'password-reset-by',
  TO_AUTH_PASSWORD_RESET_EDIT = 'password-reset-edit',
  TO_AUTH_VERIFICATION = 'verification-index',
  TO_AUTH_VERIFICATION_PHONE = 'verification-phone',
  TO_AUTH_VERIFICATION_PHONE_VERIFY = 'verification-phone-verify',
  TO_AUTH_VERIFICATION_EMAIL = 'verification-email',
  TO_AUTH_VERIFICATION_EMAIL_VERIFY = 'verification-email-verify',
  TO_AUTH_VERIFICATION_FORM = 'verification-form',
  TO_AUTH_VERIFICATION_PASSWORD = 'verification-password',
  TO_AUTH_LICENSE = 'license',

  TO_TESTS = 'tests',
  TO_TESTS_MATERIAL = 'tests-test-page',
  TO_TESTS_GO = 'tests-question-page',
  TO_TESTS_RESULTS = 'tests-question-page-results',
  TO_TESTS_MY_ANSWERS = 'tests-question-page-correct',

  TO_COMPETENCIES = 'competencies',
  TO_COMPETENCIES_MATERIAL = 'competencies-competency-page',
  TO_COMPETENCIES_GO = 'competencies-question-page',
  TO_COMPETENCIES_RESULTS = 'competencies-competency-results',
  TO_COMPETENCIES_MY_COMPETENCIES = 'competencies-my-competencies',
  TO_COMPETENCIES_MY_ANSWERS = 'competencies-competency-correct',

  TO_INTERVIEWS = 'interviews',
  TO_INTERVIEWS_MATERIAL = 'interviews-show',
  TO_INTERVIEWS_GO = 'interviews-start',
  TO_INTERVIEWS_RESULTS = 'interviews-results',

  TO_DASHBOARD_HOME = 'dashboard-home',
  TO_DASHBOARD_PROFILE_INFO = 'dashboard-profile-info',
  TO_DASHBOARD_PROFILE_SETTINGS = 'dashboard-profile-info-settings',
  TO_DASHBOARD_PROFILE_SETTINGS_LANGUAGES = 'dashboard-profile-settings-languages',
  TO_DASHBOARD_PROFILE_FAVORITES = 'dashboard-profile-favorites',
  TO_DASHBOARD_PROFILE_MATERIALS_OF_DEADLINE = 'dashboard-profile-materials-of-deadline',
  TO_DASHBOARD_PROFILE_PASSWORD_EDIT = 'dashboard-profile-password-edit',
  TO_DASHBOARD_PROFILE_QUESTIONNAIRE = 'dashboard-profile-questionnaire',

  TO_EVENTS_SCHEDULED = 'events',
  TO_EVENTS_SCHEDULED_MATERIAL = 'events-page-event',
  TO_EVENTS_PLANS = 'events-plans',
  TO_EVENTS_PLANS_MATERIAL = 'events-page-plan',

  TO_GIFT_STORE = 'gift-store',
  TO_GIFT_STORE_CART = 'gift-store-cart',
  TO_GIFT_STORE_HISTORY = 'gift-store-history',

  TO_INFORMATION = 'information',
  TO_INFORMATION_HTML = 'information-material-html',
  TO_INFORMATION_CATEGORY = 'information-category',
  TO_INFORMATION_SUBCATEGORY = 'information-subcategory',
  TO_INFORMATION_MATERIAL = 'information-material',

  TO_LEARNING_MATERIALS = 'learning-materials',

  TO_LEARNING_TRACK = 'learning-track',
  TO_LEARNING_TRACK_PROGRAMS = 'learning-track-show-programs',
  TO_LEARNING_TRACK_PROGRAMS_VIDEO = 'learning-track-show-programs-video',
  TO_LEARNING_TRACK_PROGRAMS_PDF = 'learning-track-show-programs-pdf',
  TO_LEARNING_TRACK_PROGRAMS_DOCUMENT = 'learning-track-show-programs-document',
  TO_LEARNING_TRACK_PROGRAMS_SCORM = 'learning-track-show-programs-scorm',
  TO_LEARNING_TRACK_PROGRAMS_HTML = 'learning-track-show-programs-html',
  TO_LEARNING_TRACK_PROGRAMS_LINK = 'learning-track-show-programs-link',
  TO_LEARNING_TRACK_PROGRAMS_LONGREAD = 'learning-track-show-programs-longread',
  TO_LEARNING_TRACK_PROGRAMS_TEST = 'learning-track-show-programs-test',
  TO_LEARNING_TRACK_PROGRAMS_TEST_GO = 'learning-track-show-programs-test-go',
  TO_LEARNING_TRACK_PROGRAMS_TEST_RESULTS = 'learning-track-show-programs-test-results',
  TO_LEARNING_TRACK_PROGRAMS_TEST_MY_ANSWERS = 'learning-track-show-programs-test-correct',

  TO_LEARNING_PROGRAMS = 'learning-programs',
  TO_LEARNING_PROGRAMS_MATERIAL = 'learning-programs-show',
  TO_TRACK_LEARNING_PROGRAMS_MATERIAL = 'track-learning-programs-show',
  TO_LEARNING_PROGRAMS_VIDEO = 'learning-programs-show-video',
  TO_LEARNING_PROGRAMS_PDF = 'learning-programs-show-pdf',
  TO_LEARNING_PROGRAMS_DOCUMENT = 'learning-programs-show-document',
  TO_LEARNING_PROGRAMS_SCORM = 'learning-programs-show-scorm',
  TO_LEARNING_PROGRAMS_HTML = 'learning-programs-show-html',
  TO_LEARNING_PROGRAMS_LINK = 'learning-programs-show-link',
  TO_LEARNING_PROGRAMS_LONGREAD = 'learning-programs-show-longread',
  TO_LEARNING_PROGRAMS_TEST = 'learning-programs-start-test-page',
  TO_LEARNING_PROGRAMS_TEST_GO = 'learning-program-passing-test',
  TO_LEARNING_PROGRAMS_TEST_RESULTS = 'learning-programs-test-results',
  TO_LEARNING_PROGRAMS_TEST_MY_ANSWERS = 'learning-program-test-correct',

  TO_MESSAGES = 'messages',
  TO_MESSAGES_NEWS = 'messages-news',
  TO_MESSAGES_SUPPORT = 'messages-support',
  TO_MESSAGES_SUPPORT_RESULT = 'messages-support-feedback',
  TO_MESSAGES_FEED = 'messages-feed',
  TO_MESSAGES_FEED_CREATE = 'messages-feed-add',
  TO_MESSAGES_FEED_EDIT = 'messages-feed-edit',

  TO_MY_RESULTS_LEARNING = 'my-results',
  TO_MY_RESULTS_LEARNING_INTERVIEW = 'my-results-learning-interview',
  TO_MY_RESULTS_LEARNING_TEST = 'my-results-learning-test',
  TO_MY_RESULTS_LEARNING_LEARNING_MATERIALS = 'my-results-learning-learning-materials',
  TO_MY_RESULTS_KPI = 'my-results-kpi',
  TO_MY_RESULTS_KPI_MATERIAL = 'my-results-kpi-inner',
  TO_MY_RESULTS_KPI_ALL_MATERIALS = 'my-results-kpi-inner-all',
  TO_MY_RESULTS_CERTIFICATES = 'my-results-certificates',

  TO_MY_TEAM = 'my-team',
  TO_MY_TEAM_EMPLOYEE_MATERIAL = 'my-team-employee',
  TO_MY_TEAM_EMPLOYEE_AWARD = 'employee-awards-award',
  TO_MY_TEAM_EMPLOYEE_WARN = 'employee-awards-warn',
  TO_MY_TEAM_EMPLOYEE_BADGES = 'my-team-employee-badges',
  TO_MY_TEAM_EMPLOYEE_INFO = 'my-team-employee-info',
  TO_MY_TEAM_EMPLOYEE_RESULTS = 'my-team-employee-result',
  TO_MY_TEAM_EMPLOYEE_RESULTS_BY_CODE_DETAILS = 'my-team-employee-result-details',
  TO_MY_TEAM_EMPLOYEE_RESULTS_FOR_LEARNING_PROGRAMS_DETAILS = 'my-team-employee-learning-programs-details',
  TO_MY_TEAM_EMPLOYEE_RESULTS_FOR_LEARNING_PROGRAMS_DETAILS_TEST = 'my-team-employee-learning-programs-details-test',
  TO_MY_TEAM_EMPLOYEE_RESULTS_FOR_LEARNING_TRACK_DETAILS = 'my-team-employee-learning-track-details',
  TO_MY_TEAM_EMPLOYEE_RESULTS_FOR_LEARNING_TRACK_PROGRAM_DETAILS = 'my-team-employee-learning-track-program-details',
  TO_MY_TEAM_EMPLOYEE_RESULTS_FOR_LEARNING_TRACK_PROGRAM_DETAILS_TEST = 'my-team-employee-learning-track-program-details-test',
  TO_MY_TEAM_EMPLOYEE_RESULTS_FOR_INTERVIEW = 'my-team-interview-report',
  TO_MY_TEAM_EMPLOYEE_RESULTS_FOR_INTERVIEW_DETAILS = 'my-team-interview-report-details',
  TO_MY_TEAM_CHECK_TASKS = 'my-team-check-tasks',
  TO_MY_TEAM_LEARNING = 'my-team-learning',
  TO_MY_TEAM_LEARNING_MATERIAL_STATUS = 'my-team-learning-material-status',
  TO_MY_TEAM_LEARNING_TASK_USER_RESULT = 'my-team-learning-task-user-result',
  TO_MY_TEAM_LEARNING_MATERIAL_STATUSES = 'my-team-learning-material-statuses',
  TO_MY_TEAM_MATERIALS = 'my-team-materials',
  TO_MY_TEAM_MATERIALS_MATERIAL_CARD = 'my-team-material-card',
  TO_MY_TEAM_MANAGER_MATERIAL = 'my-team-manager',
  TO_MY_TEAM_MANAGER_MATERIALS = 'my-team-manager-materials',
  TO_MY_TEAM_REPORTS = 'my-team-result',
  TO_MY_TEAM_REPORTS_USERS_ACTIVITY = 'my-team-reports-users-activity',
  TO_MY_TEAM_REPORTS_MATERIALS_PASSING = 'my-team-reports-materials-passing',
  TO_MY_TEAM_REPORTS_TESTS_PASSING = 'my-team-reports-tests-passing',
  TO_MY_TEAM_REPORTS_TESTS_SCORES = 'my-team-reports-tests-scores',

  TO_RATING = 'rating',
  TO_RATING_MY_RATING = 'my-rating',
  TO_RATING_MY_RATING_BADGES = 'rating-badges',
  TO_RATING_USER = 'user-rating',
  TO_RATING_USER_BADGES = 'rating-other-badges',

  TO_SEARCH = 'search',
  TO_SEARCH_BY_MODULE = 'search-materials',

  TO_TASKS = 'tasks',
  TO_TASKS_HISTORY = 'tasks-history',
  TO_TASKS_MATERIAL = 'task-page',
  TO_TASKS_FEEDBACK = 'task-feedback',

  TO_VIDEOS = 'video',
  TO_VIDEOS_CATEGORY = 'video-category',
  TO_VIDEOS_MATERIAL = 'video-page',
}

export default CustomRoute;
