export default class Competency {
  id: number;
  name: string;
  percent: number;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.name = payload.name;
    this.percent = parseInt(payload.percent, 10);
  }
}
