import Gift from '@/entities/modules/gift-store/Gift';

export default class CartPosition {
  gift: Gift;
  amount: number;
  isEnough: boolean;

  constructor(payload: any) {
    this.gift = new Gift(payload);
    this.amount = parseInt(payload.amount, 10);
    this.isEnough = parseInt(payload.enough_products, 10) === 1;
  }

  setEnough(status: boolean): void {
    this.isEnough = status;
  }
}
