import _Vue from 'vue';
import { i18n } from '@/lang';
// eslint-disable-next-line import/no-cycle
import store from '@/store';
import { errorLogProvider } from '@/providers/logProvider';
import { getDomain } from '@/providers/analyticsProvider';

export function pt(key: string) {
  const companyId = store.getters['settings/id'];

  const domain = getDomain();

  const companyKey = `${key}.${companyId}`;

  const defaultKey = `${key}.default`;

  if (!i18n.messages[i18n.locale]) {
    errorLogProvider('lokalisePlugin: no i18n.messages for i18n.locale');

    return '';
  }

  const path: any = i18n.messages[i18n.locale][key];

  // так как большинство ключей не кастомизированные,
  // сначала проверяем что ключ имеет одно значение
  if (typeof path !== 'object') {
    return i18n.t(key).toString();
  }

  // если id компании 0,
  // показываем дефолный ключ
  if (companyId === 0) {
    if (path.default) {
      return i18n.t(defaultKey).toString();
    }

    errorLogProvider(`lokalisePlugin: no key for ${key}`);

    return '';
  }

  // если значение ключа объект,
  // тогда проверяем есть ли кастомизация для данного окружения
  if (path[domain]) {
    // @ts-ignore
    return i18n.t(key)[domain].toString();
  }

  // проверяем есть ли кастомизация для данной компании
  if (path[companyId.toString()]) {
    return i18n.t(companyKey).toString();
  }

  // если кастомизации нет,
  // то проверяем если ли дефолтное значение
  if (path.default) {
    return i18n.t(defaultKey).toString();
  }

  errorLogProvider(`lokalisePlugin: no key for ${key}`);

  return '';
}

const lokalisePlugin = {
  install: (Vue: typeof _Vue) => {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$pt = pt;
  },
};

export default lokalisePlugin;
