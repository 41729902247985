import { has } from 'lodash';
import SearchResult from '@/entities/modules/search/SearchResult';

export default class SearchResultInformation extends SearchResult {
  type: string = 'information';

  categoryId: number;
  subcategoryId?: number;

  constructor(payload: any) {
    super(payload);

    this.categoryId = payload.additional_data
      ? parseInt(payload.additional_data.category_id, 10)
      : parseInt(payload.category_id, 10);

    if (has(payload, 'additional_data.subcategory_id')) {
      this.subcategoryId = parseInt(payload.additional_data.subcategory_id, 10);
    } else if (has(payload, 'subcategory_id')) {
      this.subcategoryId = parseInt(payload.subcategory_id, 10);
    }
  }
}
