import { Module } from 'vuex';
import { RootState } from '../../types';
// @ts-ignore
import { MyResultsState } from './types';
import { getters } from './getters';
// eslint-disable-next-line import/no-cycle
import { actions } from './actions';
import { mutations } from './mutations';

export const state: MyResultsState = {
  selectedRequiredFilter: false,
  selectedModuleFilter: [],
  selectedSortFilter: '',
  selectedDeadlineFilter: [],
  materials: [],
  sliders: [
    {
      title: 'learn_filter_assigned_text',
      status: 'assigned',
      materials: [],
    },
    {
      title: 'learn_filter_failed_text',
      status: 'failed',
      materials: [],
    },
    {
      title: 'learn_filter_passed_text',
      status: 'passed',
      materials: [],
    },
  ],
};

const namespaced: boolean = true;

export const myResults: Module<MyResultsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
