import { each } from 'lodash';
import KpiEntity from '@/entities/modules/kpi/KpiEntity';

export default class KpiActionsInfo {
  infoString: string;
  lastTransactionAmount: string;
  lastTransactionAmountUnit: string;
  kpis: KpiEntity[] = [];
  lastTransactionAmountDate: string;

  constructor(payload: any) {
    this.infoString = payload.info_string;
    this.lastTransactionAmount = payload.last_transaction_amount;
    this.lastTransactionAmountUnit = payload.last_transaction_amount_unit;

    each(payload.kpis, kpi => {
      this.kpis.push(new KpiEntity(kpi));
    });

    this.lastTransactionAmountDate = payload.last_transaction_date;
  }
}
