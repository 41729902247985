import CorrectName from '@/entities/common/CorrectName';
import CustomRoute from '@/entities/common/CustomRoute';

export default [
  {
    path: '/messages/news',
    name: CustomRoute.TO_MESSAGES,
    meta: {
      title: 'messages_screen_title_text',
      moduleCode: CorrectName.MESSAGES,
      eventOnEnter: 'messages_notifications_view_btn',
    },
    component: () => import(/* webpackChunkName: "messages" */ '@/views/messages/index.vue'),
  },
  {
    path: '/messages/support',
    name: CustomRoute.TO_MESSAGES_SUPPORT,
    meta: {
      title: 'messages_screen_title_text',
      moduleCode: CorrectName.MESSAGES,
      showBlockedMessages: false,
    },
    component: () => import(/* webpackChunkName: "messages" */ '@/views/messages/support.vue'),
  },
  {
    path: '/messages/feed',
    name: CustomRoute.TO_MESSAGES_FEED,
    meta: {
      title: 'messages_screen_title_text',
      moduleCode: CorrectName.MESSAGES,
      eventOnEnter: 'news_tab_open_btn',
    },
    component: () => import(/* webpackChunkName: "messages" */ '@/views/messages/feed.vue'),
  },
  {
    path: '/messages/:id',
    name: CustomRoute.TO_MESSAGES_NEWS,
    meta: {
      title: 'messages_screen_title_text',
      moduleCode: CorrectName.MESSAGES,
      eventOnEnter: 'messages_notifications_view_btn',
    },
    component: () => import(/* webpackChunkName: "messages" */ '@/views/messages/single-news.vue'),
  },
  {
    path: '/messages/support/feedback',
    name: CustomRoute.TO_MESSAGES_SUPPORT_RESULT,
    meta: { title: 'messages_screen_title_text', moduleCode: CorrectName.MESSAGES },
    component: () => import(/* webpackChunkName: "messages" */ '@/views/messages/feedback.vue'),
  },
  {
    path: '/messages/feed/add',
    name: CustomRoute.TO_MESSAGES_FEED_CREATE,
    meta: {
      title: 'add news',
      moduleCode: CorrectName.MESSAGES,
      eventOnEnter: 'news_create_btn',
      showBlockedMessages: false,
    },
    component: () => import(/* webpackChunkName: "messages" */ '@/views/messages/add-feed.vue'),
  },
  {
    path: '/messages/feed/:id/edit',
    name: CustomRoute.TO_MESSAGES_FEED_EDIT,
    meta: {
      title: 'edit news',
      moduleCode: CorrectName.MESSAGES,
      eventOnEnter: 'news_edit_btn',
      showBlockedMessages: false,
    },
    component: () => import(/* webpackChunkName: "messages" */ '@/views/messages/edit-feed.vue'),
  },
];
