<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="svg-stroke-surface-emphasis20"
      d="M11.2859 7.44665L11.9698 8.08762L12.6536 7.44665C13.4578 6.69296 14.47 6.1772 15.4499 6.03798C16.3902
    5.90441 17.2845 6.11394 18.0183 6.77982C18.8893 7.81773 19.1005 8.80504 18.9602 9.75289C18.8104 10.7658 18.2421 11.8339
    17.3756 12.916C15.8353 14.8394 13.5908 16.5292 11.9701 17.7472C10.3546 16.5305 8.1252 14.8409 6.60077 12.9195C5.74303
    11.8384 5.18212 10.7709 5.03735 9.75777C4.90172 8.80867 5.11675 7.81923 5.98924 6.77975C6.72573 6.1109 7.60449 5.90555
    8.52159 6.0377C9.48069 6.17589 10.4774 6.68884 11.2859 7.44665Z"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'feedback-user-rating-comment',

  props: {
    isErrorColor: {
      type: Boolean,
      default: false,
    },

    isSurfaceEmphasis20: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classes() {
      const classes = [];

      if (this.isErrorColor) {
        classes.push('error');
      }

      if (this.isSurfaceEmphasis20) {
        classes.push('svg-stroke-surface-emphasis20');
      }

      return classes;
    },
  },
};
</script>

<style scoped>
.error {
  stroke: var(--error);
  fill: var(--error);
}
</style>
