import axios from 'axios';
import each from 'lodash/each';
import CertificateSectionList from '@/entities/modules/certificates/CertificateSectionList';

function toBase64(data) {
  const typedArray = new Uint8Array(data);

  const stringChar = typedArray.reduce((acc, byte) => acc + String.fromCharCode(byte), '');

  return window.btoa(stringChar);
}

function mergeSameModules(sections) {
  const mergedObjects = {};

  sections.forEach(obj => {
    const moduleId = obj.module_id;

    if (!mergedObjects[moduleId]) {
      mergedObjects[moduleId] = { ...obj, items: [...obj.items] };
    } else {
      mergedObjects[moduleId].items = [...mergedObjects[moduleId].items, ...obj.items];
    }
  });

  return Object.values(mergedObjects);
}

export default {
  /**
   * Вернет список сертификатов
   */
  async getCertificatesList() {
    const response = await axios.get('/certificates');

    const sections = [];

    const apiSections = mergeSameModules(response.data.success);

    each(apiSections, section => {
      sections.push(new CertificateSectionList(section));
    });

    return sections;
  },

  async getCertificate({}, { id, type }) {
    try {
      const response = await axios.get(`/certificates/${type}/${id}`, {
        responseType: 'arraybuffer',
        headers: { globalErrorHandler: false },
      });

      return toBase64(response.data);
    } catch (e) {
      return false;
    }
  },

  getTestCertificate(testId) {
    return axios
      .get(`/certificates/tests/${testId}`, {
        responseType: 'arraybuffer',
        headers: { globalErrorHandler: false },
      })
      .then(response => toBase64(response.data))
      .catch(() => {});
  },

  getLearningProgramCertificate(learningProgramId) {
    return axios
      .get(`/certificates/learning_program/${learningProgramId}`, {
        responseType: 'arraybuffer',
        headers: { globalErrorHandler: false },
      })
      .then(response => toBase64(response.data))
      .catch(() => {});
  },

  getLearningProgramCertificateById({ id, learningProgramId }) {
    return axios
      .get(`/certificates/learning_program/${learningProgramId}/${id}`, {
        responseType: 'arraybuffer',
        headers: { globalErrorHandler: false },
      })
      .then(response => toBase64(response.data))
      .catch(() => {});
  },

  getLearningTrackCertificate(learningTrackId) {
    return axios
      .get(`/certificates/learning_track/${learningTrackId}`, {
        responseType: 'arraybuffer',
        headers: { globalErrorHandler: false },
      })
      .then(response => toBase64(response.data))
      .catch(() => {});
  },

  getLearningProgramTrackById({ id, learningTrackId }) {
    return axios
      .get(`/certificates/learning_track/${learningTrackId}/${id}`, {
        responseType: 'arraybuffer',
        headers: { globalErrorHandler: false },
      })
      .then(response => toBase64(response.data))
      .catch(() => {});
  },
};
