import IMaterial from '@/entities/common/interfaces/material-card/IMaterial';
import CorrectName from '@/entities/common/CorrectName';
import ImageSource, { createImageMdSource } from '@/entities/common/sources/ImageSource';
import { has } from 'lodash';
import CustomRoute from '@/entities/common/CustomRoute';
import { i18n } from '@/lang';
import slugProvider from '@/providers/slugProvider';
import deepLinksProvider from '@/providers/deepLinksProvider';
import MaterialStatus from '@/entities/common/testing/MaterialStatus';
// @ts-ignore
import GenderSpecific from '@/entities/common/GenderSpecific';

export default class MaterialResult implements IMaterial {
  id: number;
  name: string;
  moduleCode: CorrectName;
  image: ImageSource | undefined;
  materialComponentName: CustomRoute;
  genderSpecific: GenderSpecific = GenderSpecific.MASCULINE;
  tagLabel: string;
  isUnknown: boolean = false;
  isRating: boolean = true;
  link: string;
  to: any;
  type: string;
  status: string;
  maxScores: number = 0;
  isAvailable: boolean;
  currentScores: number = 0;
  completeDate: number;

  constructor(payload: any, isMyRating?: boolean) {
    this.id = parseInt(payload.id, 10);
    this.name = payload.name;
    this.link = deepLinksProvider(payload.deeplink);
    this.type = payload.subtype;
    this.tagLabel = '';
    this.materialComponentName = CustomRoute.TO_LEARNING_PROGRAMS_MATERIAL;
    this.moduleCode = CorrectName.LEARNING_PROGRAMS;
    this.status = payload.status;
    this.image = this.getImage(payload);
    this.maxScores = payload.max_scores ? parseInt(payload.max_scores, 10) : 0;
    this.isAvailable = has(payload, 'is_available')
      ? parseInt(payload.is_available, 10) === 1
      : true;
    this.currentScores = has(payload, 'current_scores') ? parseInt(payload.current_scores, 10) : 0;
    this.completeDate = parseInt(payload.complete_date, 10);

    switch (payload.type) {
      case 'tests': {
        this.materialComponentName = CustomRoute.TO_TESTS_MATERIAL;
        this.tagLabel = i18n.t('common_test_text').toString();
        this.moduleCode = CorrectName.TESTS;
        break;
      }

      case 'material': {
        switch (payload.subtype) {
          case 'video': {
            this.tagLabel = i18n.t('common_video_text').toString();
            break;
          }

          case 'scorm': {
            this.tagLabel = i18n.t('learn_course_digital_course_text').toString();
            break;
          }

          case 'test': {
            this.tagLabel = i18n.t('common_test_lowcase_text').toString();
            break;
          }

          default: {
            this.isUnknown = true;
          }
        }
        break;
      }

      default:
        break;
    }

    this.to = isMyRating ? this.getLink() : '';
  }

  private getImage(payload: any) {
    if (has(payload, 'image_wide') && payload.image_wide) {
      return payload.image_wide.xlg.url;
    }

    if (has(payload, 'image') && has(payload.image, 'xlg')) {
      return payload.image.xlg.url;
    }

    return createImageMdSource(payload.image);
  }

  getParts(): object[] {
    const parts = [];

    if (this.getStatus() === MaterialStatus.PASSED) {
      parts.push([
        'common_d_from_d_points_text',
        this.maxScores,
        { 0: this.currentScores, 1: this.maxScores },
      ]);
    }

    if (this.getStatus() === MaterialStatus.ASSIGNED) {
      parts.push([
        'learn_earn_d_points_text',
        this.maxScores,
        { 0: this.maxScores.toLocaleString() },
      ]);
    }

    if (this.getStatus() === MaterialStatus.FAILED) {
      parts.push(['common_d_from_d_points_text', this.maxScores, { 0: 0, 1: this.maxScores }]);
    }

    return parts;
  }

  private getLink() {
    if (!this.isAvailable) {
      return '';
    }

    if (this.moduleCode === CorrectName.TESTS) {
      return {
        name: this.materialComponentName,
        params: {
          id: this.id,
          slug: slugProvider(this.name),
        },
      };
    }

    return this.link;
  }

  getStatus() {
    if (this.status === 'in_progress') {
      return MaterialStatus.IN_PROGRESS;
    }

    if (this.status === 'fail') {
      return MaterialStatus.FAILED;
    }

    if (this.status === 'success') {
      return MaterialStatus.PASSED;
    }

    return MaterialStatus.ASSIGNED;
  }

  getWidget() {
    return this;
  }
}
