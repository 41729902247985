<template>
  <svg
    :class="classes"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.2786 17.6904C16.6691 18.081 17.2866 18.066 17.6578 17.6571C18.029
    17.2481 18.0133 16.6 17.6228 16.2095L13.4135 12.0002L17.6912 7.72257C18.0817 7.33204 18.0668 6.71455 17.6578 6.34337C17.2489
    5.97218 16.6008 5.98786 16.2102 6.37838L12.001 10.5877L7.7233 6.31001C7.33278 5.91948 6.71529 5.93442 6.3441 6.34337C5.97291
    6.75232 5.98859 7.40041 6.37911 7.79094L10.5884 12.0002L6.31074 16.2779C5.92022 16.6684 5.93515 17.2859 6.3441 17.6571C6.75305
    18.0283 7.40115 18.0126 7.79167 17.6221L12.001 13.4128L16.2786 17.6904Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    hasHover: {
      type: Boolean,
      default: true,
    },

    isWhite: {
      type: Boolean,
      default: false,
    },

    isSurfaceMediumEmphasis: {
      type: Boolean,
      default: false,
    },

    isError: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classes() {
      const classes = [];

      if (this.hasHover) {
        classes.push('has-hover');
      }

      if (this.isWhite) {
        classes.push('white');
      }

      if (this.isSurfaceMediumEmphasis) {
        classes.push('surface-medium-emphasis');
      }

      if (this.isError) {
        classes.push('error');
      }

      return classes;
    },
  },
};
</script>

<style scoped>
svg path {
  fill: var(--surfaceHighEmphasis);
  transition-duration: 0.15s;
}

svg.white path {
  fill: var(--surfaceWhite);
}

svg.error path {
  fill: var(--error);
}

svg.surface-medium-emphasis path {
  fill: var(--surfaceMediumEmphasis);
}

svg.has-hover:hover path {
  fill: var(--primary);
  transition-duration: 0.15s;
}
</style>
