import User from '@/entities/modules/rating/User';
// eslint-disable-next-line import/no-cycle
import Badge from '@/entities/common/Badge';
import MaterialResult from '@/entities/modules/rating/MaterialResult';
import Category from '@/entities/common/Category';
import MaterialResultSection from '@/entities/modules/rating/MaterialResultSection';
import MaterialStatus from '@/entities/common/testing/MaterialStatus';
import RatingKpi from '@/entities/modules/rating/RatingKpi';
import { LearningProgramResult } from '@/entities/modules/rating/LearningProgramResult';
import { TaskResult } from '@/entities/modules/rating/TaskResult';
import { EventResult } from '@/entities/modules/rating/EventResult';
import AnswerResult from '@/entities/modules/rating/AnswerResult';

export default class Details {
  user: User;
  badges: Badge[];
  materials: MaterialResult[];
  learningPrograms: LearningProgramResult[];
  taskResults: TaskResult[];
  answerResults: AnswerResult[];
  eventResults: EventResult[];
  categories: Category[];
  kpi: RatingKpi[];

  constructor(payload: any, isMyRating: boolean) {
    this.user = new User(payload.data.success.user);
    this.badges = this.prepareItem(payload.data.success.badges, Badge);
    this.materials = this.prepareItem(payload.data.success.materials, MaterialResult, isMyRating);
    this.learningPrograms = this.prepareItem(
      payload.data.success.learning_programs,
      LearningProgramResult,
    );
    this.answerResults = this.prepareItem(payload.data.success.answers, AnswerResult);
    this.taskResults = this.prepareItem(payload.data.success.tasks, TaskResult);
    this.eventResults = this.prepareItem(payload.data.success.events, EventResult);
    this.categories = this.prepareItem(payload.data.success.categories, Category);
    this.kpi = this.prepareItem(payload.data.success.kpi, RatingKpi, isMyRating);
  }

  private prepareItem<T>(payload: any[], constructFn: any, isMyRating: boolean = false): T[] {
    // eslint-disable-next-line new-cap
    return payload.map(item => new constructFn(item, isMyRating));
  }

  getMaterialResultSections(): MaterialResultSection[] {
    return [MaterialStatus.ASSIGNED, MaterialStatus.FAILED, MaterialStatus.PASSED].map(
      (status, index) => {
        const payload = {
          status,
          sort: index + 1,
          materials: this.materials.filter(a => a.getStatus() === status),
          learningPrograms: this.learningPrograms.filter(a => a.getStatus() === status),
          taskResults: this.taskResults.filter(a => a.getStatus() === status),
          answerResults: this.answerResults.filter(a => a.getStatus() === status),
          eventResults: this.eventResults.filter(a => a.getStatus() === status),
        };

        return new MaterialResultSection(payload);
      },
    );
  }
}
