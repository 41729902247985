import { ActionTree } from 'vuex';
import { MyResultsState } from '@/store/modules/my-results/types';
import { cloneDeep } from 'lodash';
import CustomRoute from '@/entities/common/CustomRoute';
import CorrectName from '@/entities/common/CorrectName';
import { LearningItemTypes } from '@/entities/modules/learning-items/LearningItemType';
import learningItemsApi from '@/services/learning-items';
import { i18n } from '@/lang';
import { RootState } from '../../types';

export const actions: ActionTree<MyResultsState, RootState> = {
  async loadMaterials({ commit, dispatch, rootGetters }) {
    const materials = [];

    for (const module of rootGetters['settings/modulesList']) {
      switch (module.code) {
        case CorrectName.EVALUATIONS: {
          // eslint-disable-next-line no-await-in-loop
          await dispatch('tests/syncMaterials', null, { root: true });

          const testsClone = cloneDeep(rootGetters['tests/materials']);

          for (const test of testsClone) {
            test.customRoute = CustomRoute.TO_MY_RESULTS_LEARNING_TEST;
            test.tagLabel = i18n.t('common_test_lowcase_text');
          }
          materials.push(...testsClone);

          // eslint-disable-next-line no-await-in-loop
          await dispatch('interviews/syncMaterials', null, { root: true });

          const interviewsClone = cloneDeep(rootGetters['interviews/materials']);

          for (const interview of interviewsClone) {
            interview.customRoute = CustomRoute.TO_MY_RESULTS_LEARNING_INTERVIEW;
            interview.tagLabel = i18n.t('common_survey_lowcase_text');
          }
          materials.push(...interviewsClone);
          break;
        }

        case CorrectName.LEARNING_MATERIALS: {
          // eslint-disable-next-line no-await-in-loop
          const learningMaterials = await learningItemsApi.getAllLearningItems({
            moduleId: module.id,
          });

          learningMaterials.forEach(material => {
            const widget = material.getWidget(true);

            const tagLabelKey =
              material.entityType === LearningItemTypes.TRACK
                ? 'common_trajectories_default_text'
                : 'myteam_learning_journey_title_text';

            widget.tagLabel = i18n.t(tagLabelKey);
            widget.to.name = CustomRoute.TO_MY_RESULTS_LEARNING_LEARNING_MATERIALS;
            widget.to.params.type = material.entityType;
            material.widget = widget;
          });

          materials.push(...learningMaterials);
          break;
        }
        default:
          break;
      }
    }
    commit('addMaterials', materials);
  },

  changeRequiredFilter({ commit }, type) {
    commit('changeRequiredFilter', type);
  },

  changeModuleFilter({ commit }, type) {
    commit('changeModuleFilter', type);
  },

  changeSortFilter({ commit }, type) {
    commit('changeSortFilter', type);
  },

  changeDeadlineFilter({ commit }, type) {
    commit('changeDeadlineFilter', type);
  },
};
