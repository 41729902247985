export class LearningProgramMaterial {
  constructor({
    id,
    name,
    type,
    timeLimit,
    attemptsCount,
    hasTestTime,
    isRandomQuestions,
    isRating,
    maxPoints,
    questionsCount,
    updatedAt,
  }) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.timeLimit = timeLimit;
    this.attemptsCount = attemptsCount;
    this.hasTestTime = hasTestTime;
    this.isRandomQuestions = isRandomQuestions;
    this.isRating = isRating;
    this.maxPoints = maxPoints;
    this.questionsCount = questionsCount;
    this.updatedAt = updatedAt;
  }
}

export function createLearningProgramMaterial(payload) {
  return Object.freeze(
    new LearningProgramMaterial({
      id: parseInt(payload.id, 10),
      name: payload.name,
      type: payload.type,
      timeLimit: parseInt(payload.test_time, 10),
      attemptsCount: parseInt(payload.attempts_count, 10),
      hasTestTime: parseInt(payload.has_test_time, 10) === 1,
      isRandomQuestions: parseInt(payload.is_random_questions, 10) === 1,
      isRating: parseInt(payload.is_rating, 10) === 1,
      maxPoints: parseInt(payload.max_points, 10),
      questionsCount: parseInt(payload.questions_count, 10),
      updatedAt: parseInt(payload.updated_at, 10),
    }),
  );
}
