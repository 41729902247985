import { each } from 'lodash';
import BaseWidget from '@/entities/modules/dashboard/BaseWidget';
import { FavoriteMaterial } from '@/entities/modules/dashboard/FavoriteMaterial';

export default class FavoritesWidget extends BaseWidget {
  favorites: Object[];

  constructor(payload: any) {
    super(payload);

    this.favorites = [];
    each(payload.data, material => {
      this.favorites.push(new FavoriteMaterial(material).getWidget());
    });
  }
}
