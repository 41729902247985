// eslint-disable-next-line max-classes-per-file
import each from 'lodash/each';
import has from 'lodash/has';

export class MaterialsPassingReport {
  constructor({ notStarted, success, fail, notStartedPercent, successPercent, failPercent }) {
    this.notStarted = notStarted;
    this.success = success;
    this.fail = fail;
    this.notStartedPercent = notStartedPercent;
    this.successPercent = successPercent;
    this.failPercent = failPercent;
  }
}

class Material {
  constructor({ id, name, percent, image, type, moduleId, moduleName }) {
    this.id = id;
    this.name = name;
    this.percent = percent;
    this.image = image;
    this.type = type;
    this.moduleId = moduleId;
    this.moduleName = moduleName;

    // совместимость
    this.showTag = true;
  }
}

export function createMaterialsPassingReport(payload) {
  const notStarted = [];

  const success = [];

  const fail = [];

  each(payload.not_started, material => {
    notStarted.push(
      Object.freeze(
        new Material({
          moduleId: parseInt(material.module_id, 10),
          moduleName: material.module_name,
          id: material.id,
          name: material.name,
          percent: material.percent,
          image: has(material, 'image.md.url') ? material.image.md.url : null,
          type: material.type,
        }),
      ),
    );
  });

  each(payload.success, material => {
    success.push(
      Object.freeze(
        new Material({
          moduleId: parseInt(material.module_id, 10),
          moduleName: material.module_name,
          id: material.id,
          name: material.name,
          percent: material.percent,
          image: has(material, 'image.md.url') ? material.image.md.url : null,
          type: material.type,
        }),
      ),
    );
  });

  each(payload.fail, material => {
    fail.push(
      Object.freeze(
        new Material({
          moduleId: parseInt(material.module_id, 10),
          moduleName: material.module_name,
          id: material.id,
          name: material.name,
          percent: material.percent,
          image: has(material, 'image.md.url') ? material.image.md.url : null,
          type: material.type,
        }),
      ),
    );
  });

  return Object.freeze(
    new MaterialsPassingReport({
      notStartedPercent: payload.not_started_percent,
      successPercent: payload.success_percent,
      failPercent: payload.fail_percent,
      notStarted,
      success,
      fail,
    }),
  );
}
