<template>
  <div
    class="proportional-card-16x9"
    :class="{ 'proportional-card-16x9--image': hasImage || hasOverlayAlways }"
  >
    <div
      class="proportional-card-16x9__outer"
      :style="hasImage ? `background-image: url(${image})` : ''"
    >
      <div class="proportional-card-16x9__inner">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'proportional-card-16x9',

  props: {
    image: {
      type: String,
      default: null,
    },

    hasOverlayAlways: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    hasImage() {
      return !!this.image;
    },
  },
};
</script>
