<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :class="classes"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.8518 11.7935C11.1121 12.0538 11.5238 12.0439 11.7712
    11.7712C12.0187 11.4986 12.0082 11.0665 11.7479 10.8062L8.94169 7.99998L11.7935 5.14821C12.0538 4.88787 12.0439 4.47621
    11.7712 4.22875C11.4986 3.98129 11.0665 3.99174 10.8062 4.25209L7.99999 7.05828L5.14822 4.20651C4.88787 3.94616 4.47621
    3.95612 4.22875 4.22875C3.98129 4.50138 3.99174 4.93345 4.25209 5.1938L7.05828 7.99998L4.20651 10.8518C3.94616 11.1121
    3.95612 11.5238 4.22875 11.7712C4.50138 12.0187 4.93345 12.0082 5.1938 11.7479L7.99998 8.94169L10.8518 11.7935Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    isWhite: {
      type: Boolean,
      default: true,
    },

    isSurfaceDisabledEmphasis: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classes() {
      const classes = [];

      if (this.isWhite) {
        classes.push('svg-fill-surface-white');
      }

      if (this.isSurfaceDisabledEmphasis) {
        classes.push('svg-fill-surface-disabled-emphasis');
      }

      return classes;
    },
  },
};
</script>
