import { filter, map } from 'lodash';
import { ImageWideType } from '@/entities/common/ImageTypes';
import { BooleanType } from '@/entities/common/BooleanType';
import { i18n } from '@/lang';
// @ts-ignore
import { createMaterialCardWidget } from '@/entities/common/MaterialCardWidget';
import {
  LearningTrackMaterialAvailabilityType,
  LearningTrackOrderType,
} from '@/entities/modules/learning-track/LearningTrackOrderType';
import OffsetPagination from '@/entities/common/OffsetPagination';
import PaginationDirection from '@/entities/common/PaginationDirection';
import UpDownPagination from '@/entities/common/UpDownPagination';
import UserComment from '@/entities/modules/messages/UserComment';
import CorrectName from '@/entities/common/CorrectName';
import Category from '@/entities/common/Category';
import {
  getApiName,
  getCorrectName,
  getRouteName,
  LearningItemType,
  LearningItemTypes,
} from './LearningItemType';
import LearningItemStatistic from './LearningItemStatistic';
import LearningItemTag, { LearningItemTagScheme } from './LearningItemTag';

export interface LearningItemScheme {
  id: string;
  entity_type: LearningItemType;
  entity_id: number;
  name: string;
  module_id: number;
  module_name: string;
  description: string;
  updated_at: number;
  order: number;
  is_required: BooleanType;
  is_another_user_feedback_available: BooleanType;
  is_feedback_available: BooleanType;
  is_feedback_comment_available: BooleanType;
  feedback_average_mark: string;
  feedback_count: number;
  allow_commenting: BooleanType;
  count_comments: number;
  count_new_comments: number;
  is_commented: BooleanType;
  deadline_for_passing_at: number;
  deadline_for_passing_notice_for_time: number;
  image_wide: ImageWideType;
  category_id: number;
  category_name: string;
  tags: Array<LearningItemTagScheme>;
  is_favorite: BooleanType;
  is_rating: BooleanType;
  track_order?: LearningTrackOrderType;
  show_available_materials: LearningTrackMaterialAvailabilityType;
  max_points: number;
}

export default class LearningItem {
  id: string;
  entityType: LearningItemType;
  entityId: number;
  name: string;
  moduleId: number;
  moduleCode: string = CorrectName.LEARNING_MATERIALS;
  moduleName: string;
  description: string;
  updatedAt: number;
  order: number;
  isRequired: boolean;
  isAnotherUserFeedbackAvailable: boolean;
  isFeedbackAvailable: boolean;
  isFeedbackCommentAvailable: boolean;
  feedbackAverageMark: number;
  feedbackCount: number;
  isAllowCommenting: boolean;
  comments: number;
  newComments: number;
  isCommented: boolean;
  passingDeadline: number;
  passingNoticeDeadline: number;
  image: string | null = null;
  category: Category;
  tags: Array<LearningItemTag>;
  isFavorite: boolean;
  isRating: boolean;
  statistic: LearningItemStatistic | null = null;
  trackOrder?: LearningTrackOrderType;
  showAvailableMaterials: LearningTrackMaterialAvailabilityType;
  maxPoints: number;

  commentsPagination?: UpDownPagination | undefined;
  commentsList: UserComment[] = [];

  widget: Object | null = null;

  minNumbersOfFeedback: number = 5;

  constructor(payload: LearningItemScheme) {
    this.id = payload.id;
    this.entityType = payload.entity_type;
    this.entityId = payload.entity_id;
    this.name = payload.name;
    this.moduleId = payload.module_id;
    this.moduleName = payload.module_name;
    this.description = payload.description;
    this.updatedAt = payload.updated_at;
    this.order = payload.order;
    this.isRequired = payload.is_required === 1;
    this.isAnotherUserFeedbackAvailable = payload.is_another_user_feedback_available === 1;
    this.isFeedbackAvailable = payload.is_feedback_available === 1;
    this.isFeedbackCommentAvailable = payload.is_feedback_comment_available === 1;
    this.feedbackAverageMark = parseFloat(payload.feedback_average_mark);
    this.feedbackCount = payload.feedback_count;
    this.isAllowCommenting = payload.allow_commenting === 1;
    this.comments = payload.count_comments;
    this.newComments = payload.count_new_comments;
    this.isCommented = payload.is_commented === 1;
    this.passingDeadline = payload.deadline_for_passing_at;
    this.passingNoticeDeadline = payload.deadline_for_passing_notice_for_time;

    if (payload?.image_wide?.lg?.url) {
      this.image = payload.image_wide.lg.url;
    }

    this.category = new Category({ id: payload.category_id, name: payload.category_name });
    this.tags = payload.tags.map(tag => new LearningItemTag(tag));
    this.isFavorite = payload.is_favorite === 1;
    this.isRating = payload.is_rating === 1;
    this.maxPoints = payload.max_points;
    this.showAvailableMaterials = payload.show_available_materials;

    if (payload.track_order) {
      this.trackOrder = payload.track_order;
    }
  }

  setStatistic(payload: LearningItemStatistic) {
    this.statistic = payload;
  }

  setFavorite(payload: boolean) {
    this.isFavorite = payload;
  }

  getParts(fromResults: boolean = false) {
    if (!this.statistic) {
      return null;
    }

    const parts: any[] = [];

    const itemInProgress =
      this.statistic.percent > 0 && this.statistic.percent < 100 && !this.statistic.isChecking;

    const itemFailed = this.statistic.isFailed && this.statistic.percent === 0;

    if (fromResults && (itemInProgress || itemFailed)) {
      parts.push(`${this.statistic.percent}&#8239;%`);
    }

    if (this.isRating && this.maxPoints > 0) {
      // если новый или назначен, пишем только сколько он может заработать
      if (this.statistic.isNew || this.statistic.isAppointed || this.statistic.isChecking) {
        parts.push([
          'learn_earn_d_points_text',
          this.maxPoints,
          { 0: this.maxPoints.toLocaleString() },
        ]);
      }

      if (this.statistic.isInProgress) {
        // в процессе прохождения, выводим из скольки-то баллов
        parts.push([
          'learn_d_from_d_points_text',
          this.maxPoints,
          {
            0: this.statistic.userPoints.toLocaleString(),
            1: this.maxPoints.toLocaleString(),
          },
        ]);
      }

      if (this.statistic.isCompleted || this.statistic.isFailed) {
        // успешно пройден, если на максимум -- выводим без количества попыток только баллы,
        // если не на максимум -- если есть попытки, выводим сколько и сколько баллов из скольки
        if (this.statistic.userPoints < this.maxPoints) {
          parts.push([
            'learn_d_from_d_points_text',
            this.maxPoints,
            {
              0: this.statistic.userPoints.toLocaleString(),
              1: this.maxPoints.toLocaleString(),
            },
          ]);
        } else {
          parts.push([
            'common_point_number_text',
            this.maxPoints,
            { 0: this.maxPoints.toLocaleString() },
          ]);
        }
      }
    }

    return parts;
  }

  getWidget(fromResults: boolean = false) {
    if (!this.statistic) {
      return null;
    }

    const isFewRating = this.feedbackCount < this.minNumbersOfFeedback;

    return createMaterialCardWidget({
      id: this.entityId,
      order: this.order,
      name: this.name,
      apiName: getApiName(this.entityType),
      type: getCorrectName(this.entityType),
      to: {
        name: getRouteName(this.entityType),
        params: {
          id: this.entityId,
          moduleId: this.moduleId,
        },
      },
      tagLabel:
        this.entityType === LearningItemTypes.PROGRAM
          ? i18n.t('common_learning_journeys_text')
          : '',
      image: this.image,
      isRating: this.isRating,
      isRequired: this.isRequired,
      isInProgress: this.statistic.isInProgress,
      isPassed: this.statistic.isCompleted,
      isChecking: this.statistic.isChecking,
      isChecked: this.statistic.isChecked,
      isFailed: this.statistic.isFailed,
      isAssigned: this.statistic.isAppointed,
      isNew: this.statistic.isNew,
      isFavorite: this.isFavorite,
      isFewRating,
      parts: this.getParts(fromResults),
      userScore: this.statistic.userPoints,
      maxScore: this.maxPoints,
      userHasFeedback: this.statistic.userHasFeedback,
      feedbackAverageMark: this.feedbackAverageMark,
      isFeedbackShowed: this.isFeedbackAvailable,
      percent: this.statistic.percent,
      passingDeadline: this.passingDeadline,
      passingNoticeDeadline: this.passingNoticeDeadline,
      endTime: this.statistic.endTime,
    });
  }

  getReadComments(): UserComment[] {
    return filter(this.commentsList, comment => !comment.isNew);
  }

  getUnreadComments(): UserComment[] {
    return filter(this.commentsList, comment => comment.isNew);
  }

  isFirstPageComments(): boolean {
    return !!(this.commentsPagination && this.commentsPagination.isFirstPage());
  }

  isLastPageComments(): boolean {
    return !!(this.commentsPagination && this.commentsPagination.isLastPage());
  }

  setCommentsPagination(pagination: OffsetPagination, paginationDirection: PaginationDirection) {
    if (paginationDirection === PaginationDirection.DEFAULT || !this.commentsPagination) {
      this.commentsPagination = new UpDownPagination(pagination);
    } else if (this.commentsPagination && paginationDirection === PaginationDirection.UP) {
      this.commentsPagination.setMinOffset(pagination);
    } else if (this.commentsPagination && paginationDirection === PaginationDirection.DOWN) {
      this.commentsPagination.setMaxOffset(pagination);
    }
  }

  setCommentsList(comments: UserComment[], total: number) {
    this.commentsList = comments;
    this.comments = total;
  }

  getCommentsIsNewIds() {
    return map(
      filter(this.commentsList, o => o.isNew),
      'id',
    );
  }

  setIsCommentingAllowed(isCommentingAllowed: boolean) {
    this.isAllowCommenting = isCommentingAllowed;
  }

  markAsCommented() {
    this.isCommented = true;
  }

  markAllCommentsAsRead() {
    this.newComments = 0;
  }

  setFeedback(payload: { count: number; average: number; userHasFeedback: boolean }) {
    this.feedbackCount = payload.count;
    this.feedbackAverageMark = payload.average;

    if (this.statistic) {
      this.statistic.setFeedback(payload.userHasFeedback);
    }
  }
}
