// @ts-ignore
import { getCookie } from '@/providers/js/cookieProvider';

const EXCEPTIONS = ['hk'];

export default function getActiveLanguage(languageType: string) {
  const lang = getCookie(languageType);

  if (typeof lang === 'undefined') {
    const locale = navigator.language.toLowerCase().split('-');

    return EXCEPTIONS.includes(locale[1]) ? locale[1] : locale[0];
  }

  return lang;
}
