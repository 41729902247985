<template>
  <div
    class="app-textarea"
    :class="{
      'app-textarea--no-title': !title,
      'app-textarea--disabled': disabled,
      'app-textarea--error': hasError,
    }"
  >
    <p
      v-if="title"
      class="app-textarea__title"
    >
      {{ $t(title) }}
    </p>
    <textarea
      :id="'textarea_' + componentId"
      ref="textarea"
      :style="computedStyles"
      :name="areaName"
      :placeholder="$t(placeholder)"
      :disabled="disabled"
      :value="value"
      :maxLength="maxLength"
      @input="updateValue"
      @change="$emit('onChange', $event)"
      @focus="onFocus"
      @keyup.delete="resize()"
      @blur="onBlur"
    >
    </textarea>
    <label
      :class="classesForLabel"
      :for="'textarea_' + componentId"
      class="app-textarea__label"
      >{{ labelText }}
    </label>

    <slot></slot>
    <i
      v-if="isComment"
      class="app-textarea__send-comment"
      :class="{ 'app-textarea__send-comment--colored': isActive }"
      @click="sendComment"
    >
      <SendSvg />
    </i>
    <span
      v-if="isShowCount"
      class="app-textarea__count"
    >
      {{ `${value.length}/${maxLength}` }}
    </span>
  </div>
</template>

<script>
import SendSvg from '@/components/svg/icons/send.vue';

export default {
  name: 'textarea-component',

  components: {
    SendSvg,
  },

  props: {
    areaName: {
      type: String,
      default: '',
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      default: null,
    },

    placeholder: {
      type: String,
      default: null,
    },

    textValue: {
      type: String,
      default: null,
    },

    labelText: {
      type: String,
      default: null,
    },

    value: {
      type: null,
      default: null,
    },

    isComment: {
      type: Boolean,
      default: false,
    },

    maxLength: {
      type: Number,
      default: 4000,
    },

    isShowCount: {
      type: Boolean,
      default: false,
    },

    hasError: {
      type: Boolean,
      default: false,
    },

    minHeight: {
      type: Number,
      default: 0,
    },

    maxHeight: {
      type: Number,
      default: 0,
    },

    hasLineBreakForEnter: {
      type: Boolean,
      default: false,
    },

    type: {
      type: String,
      default: 'text',
    },
  },

  data: () => ({
    componentId: null,

    isFocused: false,

    maxHeightScroll: false,

    contentHeight: 0,
  }),

  computed: {
    isActive() {
      return this.value ? this.value.length > 0 && this.value.trim() : false;
    },

    classesForLabel() {
      const classes = [];

      if (this.isActive || this.isFocused) {
        classes.push('app-textarea__label--active');
      }

      return classes;
    },

    computedStyles() {
      return {
        height: this.height,
        maxHeight: this.maxHeight ? `${this.maxHeight}px` : '100%',
        overflow: this.isMaxHeight ? 'auto' : 'hidden',
      };
    },

    height() {
      if (this.value.length === 0 && this.minHeight > 0) {
        return `${this.minHeight}px`;
      }

      if (this.contentHeight) {
        return `${this.contentHeight < this.minHeight ? this.minHeight : this.contentHeight}px`;
      }

      return 'auto';
    },

    isMaxHeight() {
      return this.maxHeight > 0 && this.contentHeight > this.maxHeight;
    },
  },

  watch: {
    value() {
      this.$nextTick(this.resize);
    },
  },

  mounted() {
    this.componentId = this._uid;

    this.$refs.textarea.addEventListener('keyup', this.keyboardWatcher);

    this.$refs.textarea.addEventListener('keydown', e => {
      if (e.keyCode === 13 && !e.shiftKey) {
        if (this.isComment) {
          this.sendComment();
        }

        if (this.hasLineBreakForEnter) {
          return;
        }

        e.preventDefault();
      }
    });

    this.resize();
  },

  beforeDestroy() {
    if (this.$refs.textarea) {
      this.$refs.textarea.removeEventListener('keyup', this.keyboardWatcher);
    }
  },

  methods: {
    keyboardWatcher(event) {
      event.stopPropagation();
    },

    onFocus() {
      this.isFocused = true;

      this.$emit('onFocus');
      this.resize();
    },

    resize() {
      this.$nextTick(() => {
        this.contentHeight = this.$refs.textarea.scrollHeight;
      });
    },

    onBlur() {
      this.isFocused = false;

      this.$emit('onBlur');
    },

    sendComment() {
      if (this.isActive) {
        this.$emit('sendComment', this.value);
      }
    },

    textareaFocus() {
      this.$refs.textarea.focus();
    },

    updateValue(event) {
      let value = event.target.value;

      if (this.type === 'number') {
        value = value.trim();
      }

      this.$emit('input', value);
    },
  },
};
</script>
