export default class CompetenciesTestUpdate {
  id: number;
  updatedAt: number;
  timestamp: number;
  isNew: boolean;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.updatedAt = parseInt(payload.updated_at, 10);
    this.timestamp = parseInt(payload.timestamp, 10);
    this.isNew = parseInt(payload.is_new, 10) === 1;
  }
}
