import { each, has, filter, find } from 'lodash';
import RegexpPolicy from '@/entities/modules/settings/password-policies/RegexpPolicy';
import FieldMathPolicy from '@/entities/modules/settings/password-policies/FieldMathPolicy';
import CredentialSettings from '@/entities/modules/settings/CredentialSettings';
import MenuItem from '@/entities/modules/settings/MenuItem';
import FrameSettings from '@/entities/modules/settings/FrameSettings';
import IntegrationAuthType from '@/entities/modules/settings/auth-types/IntegrationAuthType';
import SmsAuthType from '@/entities/modules/settings/auth-types/SmsAuthType';
import LanguageSettings from '@/entities/modules/settings/LanguageSettings';
import LoginAuthType from '@/entities/modules/settings/auth-types/LoginAuthType';
// eslint-disable-next-line import/no-cycle
import RegistrationSettings from '@/entities/modules/settings/auth-types/RegistrationSettings';
import { createCompanyModule } from './CompanyModule';

export class CompanySettings {
  constructor({
    credentialsSettings,
    authTypes,
    passwordPolicies,
    globals,
    support,
    companyName,
    frameSettings,
    registrationSettings,
    languageSettings,
  }) {
    this.credentialsSettings = credentialsSettings;
    this.authTypes = authTypes;
    this.passwordPolicies = passwordPolicies;
    this.globals = globals;
    this.support = support;
    this.companyName = companyName;
    this.frameSettings = frameSettings;
    this.registrationSettings = registrationSettings;
    this.languageSettings = languageSettings;
  }
}

export function createCompanySettings(data) {
  const credentialsSettings = [];

  if (has(data, 'credentials_settings')) {
    each(data.credentials_settings, (settings, code) => {
      credentialsSettings.push(new CredentialSettings(code, settings));
    });
  }

  let frameSettings = null;

  if (has(data, 'frame_setting')) {
    frameSettings = new FrameSettings(data.frame_setting);
  }

  const authTypes = [];

  if (has(data, 'auth_types')) {
    each(data.auth_types, auth => {
      // eslint-disable-next-line default-case
      switch (auth.type) {
        case 'login':
          authTypes.push(new LoginAuthType(auth));
          break;
        case 'sms':
          authTypes.push(new SmsAuthType(auth));
          break;
        case 'integration':
          authTypes.push(new IntegrationAuthType(auth));
          break;
      }
    });
  }

  const passwordPolicies = [];

  if (has(data, 'password_policy_collection')) {
    each(data.password_policy_collection, passwordPolicy => {
      if (passwordPolicy.type === 'regexp') {
        passwordPolicies.push(new RegexpPolicy(passwordPolicy));
      } else if (passwordPolicy.type === 'field_match') {
        passwordPolicies.push(new FieldMathPolicy(passwordPolicy));
      }
    });
  }

  let globals = null;

  if (has(data, 'settings')) {
    globals = {
      allowPdfSharing: data.settings.allow_pdf_sharing,
      testsWithoutInterruptions: data.settings.tests_without_interruptions,
      publicCompanyName: data.settings.company_name,
      textGreeting: data.settings.text_greeting,
      isNewsFeedEnabled: parseInt(data.settings.is_news_feed_enabled, 10) === 1,
      webTestQuestionsResponse: parseInt(data.settings.web_test_questions_response, 10) === 1,
      webEmptyQuestionsSolution: parseInt(data.settings.web_empty_questions_solution, 10),
      webFrameEnabled: parseInt(data.settings.web_frame_enabled, 10) === 1,
      elementsCountInHiddenSection:
        parseInt(data.settings.elements_count_in_hidden_section, 10) === 1,
      disableRfarmEvents: parseInt(data.settings.disable_rfarm_events, 10) === 1,
    };
  }

  let support = null;

  if (has(data, 'support')) {
    support = {
      faq: data.support.faq_url,
      jabber: data.support.jabber_account,
      phone: data.support.phone_account,
      mail: data.support.support_mail,
      supportTimeDelay: data.support.support_time_delay,
      telegramAccount: data.support.telegram_account,
      intercomAppId: data.support.intercom_app_id,
    };
  }

  const companyName = data.company.name;

  const registrationSettings = new RegistrationSettings(data.registration_settings);

  let languageSettings = null;

  if (data?.language_settings) {
    languageSettings = new LanguageSettings(data.language_settings);
  }

  return Object.freeze(
    new CompanySettings({
      credentialsSettings,
      authTypes,
      passwordPolicies,
      globals,
      support,
      companyName,
      frameSettings,
      registrationSettings,
      languageSettings,
    }),
  );
}

/**
 * Вернет модули
 * @param payload
 * @returns {[]}
 */
export function createCompanyModules(payload) {
  const allModules = [];

  const menuItems = [];

  // не отображаем модули: 'scorm', 'certificates', 'registration', 'forms', 'personal_data', 'kpi', 'competencies'
  const displayedModules = [
    'dashboard',
    'learning_materials',
    'evaluations',
    'messages',
    'team',
    'infos',
    'discovers',
    'events',
    'results',
    'shop',
    'achievements',
    'tasks',
    'company_links',
  ];

  each(payload, module => {
    // eslint-disable-next-line no-param-reassign
    module.isDisplayed = displayedModules.includes(module.type);

    allModules.push(createCompanyModule(module.type, module.title, module));

    if (module.available && module.isDisplayed) {
      menuItems.push(new MenuItem(module));
    }

    if (module.available && module.type === 'competencies') {
      const evaluation = find(payload, { type: 'evaluations' });

      if (!evaluation || !evaluation.available) {
        menuItems.push(new MenuItem(module));
      }
    }
  });

  const availableModules = filter(allModules, module => module.available);

  return {
    allModules,
    availableModules,
    menuItems,
  };
}
