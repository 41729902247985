import { i18n } from '@/lang';
// eslint-disable-next-line import/no-cycle
import { pt } from '@/plugins/lokalisePlugin';

export default class RegistrationSettings {
  description: string;
  name: string;
  isRegistrationAvailable: boolean = false;

  constructor(payload: any) {
    this.description = payload.description;
    this.name = payload.name;
    this.isRegistrationAvailable = parseInt(payload.is_registration_available, 10) === 1;
  }

  getName() {
    return this.name === '' ? pt('auth_complete_registration_button').toString() : this.name;
  }

  getDescription() {
    return this.description === ''
      ? i18n.t('auth_register_hint_text').toString()
      : this.description;
  }
}
