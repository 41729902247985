import { has } from 'lodash';
import IAnswerOption from '@/entities/common/testing/answer-oprions/IAnswerOption';

export default class DNDAnswerOption implements IAnswerOption {
  answerId: number;
  option: string;
  answer: string;

  isSuccess: boolean = false;
  isFailed: boolean = false;

  constructor(payload: any) {
    this.answerId = parseInt(payload.id, 10);

    if (has(payload, 'answer')) {
      this.option = payload.answer;
    } else {
      this.option = '';
    }

    if (has(payload, 'correct')) {
      this.answer = payload.correct;
    } else {
      this.answer = '';
    }
  }
}
