export default class User {
  id: number;
  name: string;
  avatar: string;
  mail: string;
  login: string;
  phone: string;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.name = `${payload.first_name} ${payload.last_name}`;
    this.avatar = payload.avatar.url;
    this.mail = payload.email;
    this.login = payload.login;
    this.phone = payload.phone;
  }
}
