import { each } from 'lodash';

export default class EmployeeProgress {
  notStarted: number = 0;
  successed: number = 0;
  failed: number = 0;

  constructor(payload: any) {
    each(payload, entity => {
      this.notStarted += entity.amount.not_started;
      this.successed += entity.amount.success;
      this.failed += entity.amount.fail;
    });
  }
}
