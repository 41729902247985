export default class UserToken {
  accessToken: string;
  refreshToken: string;
  expiresIn: number;

  constructor(payload: any) {
    this.accessToken = payload.access_token;
    this.refreshToken = payload.refresh_token;
    this.expiresIn = payload.expires_in;
  }
}
