<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.2032 2.29289C3.81268 1.90237 3.17951 1.90237 2.78899 2.29289C2.39846 2.68342 2.39846 3.31658 2.78899 3.70711L7.14325 8.06137L3.7984 12.0752C3.64408 12.2603 3.48778 12.4478 3.37398 12.6126C3.26635 12.7684 3.06984 13.0748 3.06377 13.4774C3.05682 13.9384 3.26223 14.3769 3.62081 14.6667C3.93402 14.9198 4.29519 14.965 4.48379 14.9821C4.68322 15.0001 4.92732 15.0001 5.16832 15L11.3633 15L10.5038 21.876C10.4487 22.3165 10.6907 22.7406 11.0979 22.9173C11.5051 23.0941 11.9801 22.9812 12.2643 22.6402L16.5633 17.4814L20.789 21.7071C21.1795 22.0976 21.8127 22.0976 22.2032 21.7071C22.5937 21.3166 22.5937 20.6834 22.2032 20.2929C16.2032 14.2929 10.2032 8.29292 4.2032 2.29289Z"
      fill="#111827"
    />
    <path
      d="M19.1346 14.3959L21.1938 11.9249C21.3481 11.7398 21.5044 11.5523 21.6182 11.3875C21.7258 11.2317 21.9223 10.9253 21.9284 10.5227C21.9353 10.0617 21.7299 9.62314 21.3714 9.33338C21.0581 9.08027 20.697 9.03509 20.5084 9.01802C20.3089 8.99996 20.0648 9.00001 19.8238 9.00005L13.7388 9.00006L19.1346 14.3959Z"
      fill="#111827"
    />
    <path
      d="M13.6411 8.90234L14.4884 2.12409C14.5434 1.68359 14.3015 1.25952 13.8943 1.08275C13.4871 0.905986 13.0121 1.01884 12.7279 1.35987L9.71456 4.97583L13.6411 8.90234Z"
      fill="#111827"
    />
  </svg>
</template>

<script>
export default {
  name: 'zap-off-solid-icon',
};
</script>
