export default class Certificate {
  date?: number;
  id: number;
  name?: string;
  url: string;
  image: string;
  certificateList?: Certificate[];

  constructor(payload: any) {
    this.date = parseInt(payload.date, 10);
    this.id = parseInt(payload.id, 10);

    if (payload.name) {
      this.name = payload.name;
    }

    this.url = payload.url;
    this.image = '';

    if (payload.certificate_list) {
      this.certificateList = [];
      payload.certificate_list.forEach((item: Object) => {
        this.certificateList?.push(new Certificate(item));
      });
    }
  }
}
