export default class Context {
  entityId: number;

  entityType: string;

  constructor(payload: any) {
    this.entityId = payload.entity_id;
    this.entityType = payload.entity_type;
  }
}
