import { warningLogProvider } from '@/providers/logProvider';
import deepLinksProvider, { isSameDomain } from '@/providers/deepLinksProvider';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const showdown = require('showdown');

/**
 * @param rawString {String} markdown text
 * @param isSafe {Boolean} TRUE, if you need to return text without tags
 */
export default function markdownProvider(rawString: unknown, isSafe: boolean = false): string {
  if (typeof rawString === 'undefined' || rawString === null) {
    return '';
  }

  const parsedString = String(rawString).toString();

  try {
    let parsed = parsedString.replace(/</gm, '&lt;');
    parsed = parsed.replace(/\\\n/gm, '\n<br />');

    parsed = parsed.replace(/&lt;[^@]+@[^@]+\.[^@]+&gt;/gm, str =>
      str.replace('&lt;', '<').replace('&gt;', '>'),
    );
    // eslint-disable-next-line no-useless-escape
    parsed = parsed.replace(
      /&lt;https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)&gt;/gm,
      str => str.replace('&lt;', '<').replace('&gt;', '>'),
    );
    // eslint-disable-next-line no-useless-escape
    parsed = parsed.replace(
      /&lt;[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)&gt;/gm,
      str => str.replace('&lt;', '<http://').replace('&gt;', '>'),
    );

    // eslint-disable-next-line no-useless-escape
    parsed = parsed.replace(/\[([^\]]+)\]\(([^\)]+)\)/gm, url => {
      // eslint-disable-next-line no-useless-escape
      const match = /\[([^\]]+)\]\(([^\)]+)\)/gm.exec(url);

      if (!match || match.length !== 3) {
        warningLogProvider('markdownProvider: wrong markdown url!');

        return url;
      }

      const [, label, href] = match;

      const parsedUrl = deepLinksProvider(href);

      let base = `[${label}](${parsedUrl})`;

      if (!isSafe && !isSameDomain(href)) {
        base += '{:target="\\_blank"}';
      }

      return base;
    });

    const converter = new showdown.Converter();

    if (isSafe) {
      // we dont want to remove location from all links, so
      parsed = parsed.replace(/\[([^[]+)\](\(.*\))/gm, (x: any, y: any, z: any) =>
        z.substr(1, z.length - 2),
      );

      return converter.makeHtml(parsed).replace(/<[^>]*>?/gm, '');
    }

    return converter
      .makeHtml(parsed)
      .replace(/<a href="([^"]+)">([^<]+)<\/a>{:target="_blank"}/gm, (link: any) =>
        link.replace(
          /<a href="([^"]+)">/gm,
          '<a href="$1" target="_blank" rel="noopener noreferrer">',
        ),
      )
      .replace(/{:target="_blank"}/gm, '');
  } catch {
    return parsedString;
  }
}
