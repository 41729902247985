import { ImageWideType } from '@/entities/common/ImageTypes';
import LearningItemStatistic from '@/entities/modules/learning-items/LearningItemStatistic';
import { BooleanType } from '@/entities/common/BooleanType';

export interface NodeItemScheme {
  id?: number;
  type?: string;
  entity_type?: string;
  entity_id?: number;
  name?: string;
  updated_at?: number;
  image_wide?: ImageWideType;
  order: number;
  is_available: BooleanType;
  is_last: BooleanType;
}

export default class NodeItem {
  id: number = 0;
  type: string = '';
  entityId: number = 0;
  name: string = '';
  image: ImageWideType | null = null;
  order: number;
  statistic: LearningItemStatistic | null = null;
  passingDeadline: number | null = null;
  passingNoticeDeadline: number | null = null;
  maxPoints: number = 0;
  isAvailable: boolean;
  isLast: boolean;

  constructor(payload: NodeItemScheme) {
    this.id = payload.id || 0;
    this.type = payload.entity_type || '';
    this.entityId = payload.entity_id || 0;
    this.name = payload.name || '';
    this.image = payload.image_wide || null;
    this.order = payload.order;
    this.isAvailable = payload.is_available === 1;
    this.isLast = payload.is_last === 1;
  }

  setIsAvailable(value: boolean) {
    this.isAvailable = value;
  }

  setIsLast(value: boolean) {
    this.isLast = value;
  }

  setStatistic(statistic: LearningItemStatistic) {
    this.statistic = statistic;
  }

  setDeadline(passingDeadline: number | null, passingNoticeDeadline: number | null) {
    this.passingDeadline = passingDeadline;
    this.passingNoticeDeadline = passingNoticeDeadline;
  }

  setMaxPoints(payload: number) {
    this.maxPoints = payload;
  }
}
