/**
 * У нас в проекте одни названия, а у бекенда -- другие
 * Чтобы каждый раз не писать названия модулей, разделов или еще чего-то,
 * юзаем у себя эти красивые типы
 */
import ApiName from '@/entities/common/ApiName';

// eslint-disable-next-line no-shadow
enum CorrectName {
  DASHBOARD = 'dashboard',
  TESTS = 'tests',
  INTERVIEW = 'interviews',
  COMPETENCY = 'competency',
  EVALUATIONS = 'evaluations',
  RATING = 'rating',
  MY_RESULTS = 'my-results',
  LEARNING_MATERIALS = 'learning-materials',
  LEARNING_TRACK = 'learning-track',
  LEARNING_PROGRAMS = 'learning-programs',
  VIDEO = 'video',
  EVENT = 'events',
  TEAM = 'my-team',
  GIFT_STORE = 'gift-store',
  TASK = 'tasks',
  INFORMATION = 'information',
  EXTERNAL_LINK = 'external-link',
  MESSAGES = 'messages',
  MODULE_UNSUPPORTED = 'module-not-known',
  MATERIAL = 'material',
}

export function getCorrectNameByApiCode(code: string): CorrectName {
  switch (code) {
    case ApiName.DASHBOARD:
      return CorrectName.DASHBOARD;

    case ApiName.TEST:
    case ApiName.TEST_MODULE:
    case ApiName.TEST_CHECKED_MODULE:
      return CorrectName.TESTS;

    case ApiName.INTERVIEW:
    case ApiName.INTERVIEW_MODULE:
      return CorrectName.INTERVIEW;

    case ApiName.COMPETENCY:
    case ApiName.COMPETENCY_MODULE:
    case ApiName.COMPETENCY_MODULE_2:
      return CorrectName.COMPETENCY;

    case ApiName.EVALUATIONS:
      return CorrectName.EVALUATIONS;

    case ApiName.RATING:
    case ApiName.RATING_BADGES_MODULE:
      return CorrectName.RATING;

    case ApiName.MY_RESULTS:
      return CorrectName.MY_RESULTS;

    case ApiName.INFORMATION:
    case ApiName.INFORMATION_MODULE:
    case ApiName.INFORMATION_CATEGORY:
    case ApiName.INFORMATION_CATEGORY_MODULE:
      return CorrectName.INFORMATION;

    case ApiName.VIDEO:
    case ApiName.VIDEO_MODULE:
      return CorrectName.VIDEO;

    case ApiName.LEARNING_MATERIALS:
    case ApiName.LEARNING_TRACK:
    case ApiName.LEARNING_TRACK_MODULE:
    case ApiName.LEARNING_PROGRAM:
    case ApiName.LEARNING_PROGRAM_MODULE:
    case ApiName.LEARNING_PROGRAM_CHECKED_MODULE:
      return CorrectName.LEARNING_MATERIALS;

    case ApiName.EVENT:
    case ApiName.EVENT_MODULE:
      return CorrectName.EVENT;

    case ApiName.TEAM:
    case ApiName.MY_TEAM:
      return CorrectName.TEAM;

    case ApiName.GIFT_STORE:
    case ApiName.GIFT_STORE_MODULE:
    case ApiName.GIFT_STORE_ORDERS_MODULE:
      return CorrectName.GIFT_STORE;

    case ApiName.TASK:
    case ApiName.TASK_MODULE:
    case ApiName.TASK_ANSWER_MODULE:
      return CorrectName.TASK;

    case ApiName.EXTERNAL_LINK:
      return CorrectName.EXTERNAL_LINK;

    case ApiName.MESSAGES:
    case ApiName.MESSAGES_MODULE:
      return CorrectName.MESSAGES;

    case ApiName.MATERIAL:
      return CorrectName.MATERIAL;

    default:
      return CorrectName.MODULE_UNSUPPORTED;
  }
}

export function getApiCodeByCorrectName(code: CorrectName): ApiName {
  switch (code) {
    case CorrectName.VIDEO:
      return ApiName.VIDEO;
    case CorrectName.INTERVIEW:
      return ApiName.INTERVIEW;
    case CorrectName.TESTS:
      return ApiName.TEST;
    case CorrectName.COMPETENCY:
      return ApiName.COMPETENCY;
    case CorrectName.LEARNING_PROGRAMS:
      return ApiName.LEARNING_PROGRAM;
    case CorrectName.LEARNING_TRACK:
      return ApiName.LEARNING_TRACK;

    default:
      return ApiName.MODULE_UNSUPPORTED;
  }
}

export default CorrectName;
