import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { GiftStoreState } from '@/store/modules/gift-store/types';

export const getters: GetterTree<GiftStoreState, RootState> = {
  gifts: state => state.gifts,
  orders: state => state.orders,
  meta: state => state.meta,
  cart: state => state.cart,
  currentPage: state => state.currentPage,
  points: state => state.points,
};
