import dateProvider from '@/providers/dateProvider';
import Pagination from '@/entities/common/Pagination';
import { each, find } from 'lodash';
import LongreadPageStatistic from '@/entities/modules/learning-programs/longread/LongreadPageStatistic';

export default class LongreadPage {
  uuid: string;
  materialId: number;

  title: string;
  body?: string;
  format: string;

  isLoaded: boolean = false;
  canSkip: boolean = true;
  updatedAt?: Date;
  order: number;

  statistic: LongreadPageStatistic;

  constructor(payload: any, statistic?: LongreadPageStatistic) {
    this.uuid = payload.uuid;
    this.materialId = parseInt(payload.longread_id, 10);

    this.title = payload.title;
    this.body = payload.body;
    this.format = payload.body_format;

    this.updatedAt = dateProvider(payload.updated_at);
    this.order = parseInt(payload.order, 10);

    if (statistic) {
      this.statistic = statistic;
    } else {
      this.statistic = new LongreadPageStatistic({
        uuid: this.uuid,
        longread_id: this.materialId,
        percent: 0,
        updated_at: 0,
      });
    }
  }

  markAsLoaded(): void {
    this.isLoaded = true;
  }

  setSkip(status: boolean): void {
    this.canSkip = status;
  }
}

export function createLongreadPages(
  payload: any,
  statistics: LongreadPageStatistic[],
): [Pagination, LongreadPage[]] {
  const pagination = new Pagination(payload.meta.pagination);

  const titles: LongreadPage[] = [];
  each(payload.page_titles, page => {
    const id = parseInt(page.longread_id, 10);

    const statistic = find(statistics, o => o.materialId === id && o.uuid === page.uuid);
    titles.push(new LongreadPage(page, statistic));
  });

  return [pagination, titles];
}
