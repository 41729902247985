export default class PersonalData {
  comment: string;
  isAvailable: boolean;
  status: string;

  constructor(payload: any) {
    this.comment = payload.comment;
    this.isAvailable = parseInt(payload.is_available, 10) === 1;
    this.status = payload.status;
  }
}
