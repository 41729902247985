import { ActionTree } from 'vuex';
import { MessagesState } from '@/store/modules/messages/types';
import { RootState } from '@/store/types';
import messagesApi from '@/services/messages';
import commentApi from '@/services/comment';
import PaginationDirection from '@/entities/common/PaginationDirection';
import ApiName from '@/entities/common/ApiName';
import { each, find, sortBy } from 'lodash';

export const actions: ActionTree<MessagesState, RootState> = {
  async getMessages({ dispatch }) {
    await dispatch('loadMessages');
  },

  async getStartMessagesPage() {
    return messagesApi.getStartMessagesPage();
  },

  async getMessageNews(o, id) {
    return messagesApi.getMessageNews(id);
  },

  async loadMessagesTags({}, { page }) {
    return messagesApi.loadMessagesTags(page);
  },

  async loadMessages({}, { page, scope, tagId }) {
    return messagesApi.getMessages(page, scope, tagId);
  },

  async putMessageLike({}, id) {
    return messagesApi.putMessageLike(id);
  },

  async removeMessageLike({}, id) {
    return messagesApi.removeMessageLike(id);
  },

  async goToPageWithOriginalComment({}, { module, message, paginationDirection, commentId }) {
    const [items, offsetPagination] = await commentApi.getComments(
      module,
      message.id,
      commentId,
      null,
      null,
    );
    await message.setCommentsPagination(offsetPagination, paginationDirection);

    await message.setCommentsList(items, offsetPagination.total);
  },

  async getMessageComments({}, { message, paginationDirection }) {
    if (paginationDirection === PaginationDirection.DEFAULT) {
      const [items, offsetPagination] = await commentApi.getComments(
        ApiName.MESSAGES,
        message.id,
        null,
        null,
        null,
      );
      message.setCommentsPagination(offsetPagination, paginationDirection);
      message.setCommentsList(items, offsetPagination.total);

      return;
    }

    const limit = message.commentsPagination.getLimit(paginationDirection);

    const offset = message.commentsPagination.getOffset(paginationDirection);

    const comments = message.commentsList;

    const [items, offsetPagination] = await commentApi.getComments(
      ApiName.MESSAGES,
      message.id,
      null,
      offset,
      limit,
    );
    message.setCommentsPagination(offsetPagination, paginationDirection);

    if (paginationDirection === PaginationDirection.DOWN) {
      comments.push(...items);
    }

    if (paginationDirection === PaginationDirection.UP) {
      comments.unshift(...items);
    }
    await message.setCommentsList(comments, offsetPagination.total);
  },

  async sendMessageComment({}, { id, comment, parentId }) {
    return commentApi.sendComment(ApiName.MESSAGES, id, comment, parentId);
  },

  async clearMessagesIsNew({}, ids) {
    if (!ids || ids.length === 0) {
      return;
    }

    await messagesApi.clearMessagesIsNew(ids);
  },

  async clearMessagesCommentsIsNew({}, { id, ids }) {
    if (!ids || ids.length === 0) {
      return;
    }

    await commentApi.clearCommentsIsNew(ApiName.MESSAGES, id, ids);
  },

  /**
   * Отправка сообщения в тех поддержкку
   * @params subject, body, info
   */
  async sendFeedback(o, { subject, body, info }) {
    return messagesApi.sendFeedback(subject, body, info);
  },

  async loadFeedPage({}, page) {
    return messagesApi.getFeed(page, 20);
  },

  async toggleFeedLike({}, id) {
    await messagesApi.toggleFeedLike(id);
  },

  async sendFeedSurveyAnswer({}, { newsId, itemId }) {
    await messagesApi.answer(newsId, itemId);
  },

  async getFeedComments({}, { feed, paginationDirection }) {
    if (paginationDirection === PaginationDirection.DEFAULT) {
      const { comments, meta } = await messagesApi.getFeedComments(feed.id, null, null);
      feed.setCommentsPagination(meta, 1);
      feed.setCommentsList(sortBy(comments, ['createdAt']), meta.totalCount);

      return;
    }

    const page = feed.commentsPagination.currentPage + 1;

    const { perPage } = feed.commentsPagination;

    const feedComments = feed.commentsList;

    const { comments, meta } = await messagesApi.getFeedComments(feed.id, page, perPage);
    feed.setCommentsPagination(meta, page);
    each(comments, item => {
      if (!find(feedComments, comment => comment.id === item.id)) {
        feedComments.push(item);
      }
    });
    feed.setCommentsList(sortBy(feedComments, ['createdAt']), meta.totalCount);
  },

  async sendFeedComment({}, { id, comment }) {
    return messagesApi.sendFeedComment(id, comment);
  },

  async uploadImage(store, formData) {
    return messagesApi.uploadImage(formData);
  },

  async uploadFile(store, formData) {
    return messagesApi.uploadFile(formData);
  },

  async createFeedPost({}, body) {
    await messagesApi.createFeedPost(body);
  },

  async getFeedPostById({}, id) {
    return messagesApi.getFeedPostById(id);
  },

  async editFeedPostById({}, { newsId, body }) {
    await messagesApi.editFeedPostById(newsId, body);
  },

  async deleteFeedById({}, id) {
    await messagesApi.deleteFeedById(id);
  },

  async clearFeedIsNew({}, ids) {
    if (!ids || ids.length === 0) {
      return;
    }

    await messagesApi.clearFeedIsNew(ids);
  },

  async getBlockedMessages({}, { scope }) {
    const payload = await messagesApi.getMessages(1, scope);

    return payload.posts;
  },

  async unblockMessage({}, id) {
    await messagesApi.unblockMessage(id);
  },
};
