import { each, find } from 'lodash';
import BaseQuestionStatistic from '@/entities/common/testing/BaseQuestionStatistic';
import QuestionType from '@/entities/common/testing/QuestionType';
import Question from '@/entities/common/testing/Question';
import SelectImageAnswerOption from '@/entities/common/testing/answer-oprions/SelectImageAnswerOption';
import UserAnswer from '@/entities/common/testing/user-answers/UserAnswer';
import MCQAnswerOption from '@/entities/common/testing/answer-oprions/MCQAnswerOption';

export default class QuestionStatistic extends BaseQuestionStatistic {
  type: QuestionType;

  constructor(payload: any, question: Question) {
    super(payload);

    this.name = question.title;
    this.recommendation = question.recommendation;
    this.comment = payload.comment;
    this.scores = parseInt(payload.scores, 10);
    this.type = question.type;

    switch (this.type) {
      case QuestionType.MCQ:
        each(payload.user_answers, userAnswer => {
          const id = parseInt(userAnswer.id, 10);

          const questionAnswer = find(question.options, o => o.answerId === id);

          if (questionAnswer && questionAnswer instanceof MCQAnswerOption) {
            this.userAnswers.push(
              new UserAnswer({
                id,
                checked: userAnswer.correct,
                correct: questionAnswer.isCorrect ? 1 : 0,
                answer: questionAnswer.option,
              }),
            );
          }
        });
        break;

      case QuestionType.SELECT_IMAGE:
        each(payload.user_answers, userAnswer => {
          const id = parseInt(userAnswer.id, 10);

          const questionAnswer = find(question.options, o => o.answerId === id);

          if (questionAnswer && questionAnswer instanceof SelectImageAnswerOption) {
            const answer = new SelectImageAnswerOption({
              id,
              checked: userAnswer.correct,
              correct: questionAnswer.isCorrect ? 1 : 0,
            });
            answer.image = questionAnswer.image;
            answer.setStatuses();
            this.userAnswers.push(answer);
          }
        });
        break;

      case QuestionType.OPEN_QUESTION:
        this.openQuestionAnswer = payload.answer;
        break;

      default:
        break;
    }

    this.isSuccess = payload.status === 'success' || payload.isSuccess;
    this.isFailed = payload.status === 'fail' || payload.isFailed;
    this.notAnswer = payload.status === 'not_answer' || payload.isAnswered;
    this.isChecking = payload.status === 'is_checked' || payload.isChecking;
  }
}
