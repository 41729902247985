import ImageSource, { createImageMdSource } from '@/entities/common/sources/ImageSource';
import VideoSource, { createVideoSource } from '@/entities/common/sources/VideoSource';
import IMaterialVideo from '@/entities/common/interfaces/material-card/IMaterialVideo';
import IFavorite from '@/entities/common/interfaces/helpers/IFavorite';
import IRouteMaterial from '@/entities/common/interfaces/routes/IRouteMaterial';
import CorrectName from '@/entities/common/CorrectName';
import slugProvider from '@/providers/slugProvider';
import CustomRoute from '@/entities/common/CustomRoute';
import GenderSpecific from '@/entities/common/GenderSpecific';
import MaterialStatus from '@/entities/common/testing/MaterialStatus';
import UserComment from '@/entities/modules/messages/UserComment';
import ICommentable from '@/entities/common/interfaces/ICommentable';
import UpDownPagination from '@/entities/common/UpDownPagination';
import OffsetPagination from '@/entities/common/OffsetPagination';
import PaginationDirection from '@/entities/common/PaginationDirection';
import { each, filter, map } from 'lodash';

export default class Video implements IMaterialVideo, IFavorite, IRouteMaterial, ICommentable {
  id: number;

  customRoute: CustomRoute = CustomRoute.TO_VIDEOS_MATERIAL;
  moduleCode: CorrectName = CorrectName.VIDEO;
  genderSpecific: GenderSpecific = GenderSpecific.NEUTER;

  type: string = 'video';
  categoryId: number;
  categoryName: string;
  name: string;
  slug: string;
  image?: ImageSource;
  sources?: VideoSource;
  description: string;
  isNew: boolean;
  isFavorite: boolean;
  order: number;
  duration: number;
  views: number;
  likes: number;
  isViewed: boolean;
  isLiked: boolean;
  isCommentingAllowed: boolean;
  comments: number = 0;
  commentsList: UserComment[] = [];
  isCommented: boolean = false;
  newComments: number = 0;
  commentsPagination?: UpDownPagination;
  tags: string[] = [];
  updatedAt: number;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.categoryId = parseInt(payload.category_id, 10);
    this.categoryName = payload.category_name;

    this.name = payload.name;
    this.slug = slugProvider(payload.name);
    this.image = createImageMdSource(payload.image);
    this.sources = createVideoSource(payload.video);
    this.description = payload.description;
    this.order = parseInt(payload.order, 10);
    this.duration = parseInt(payload.duration, 10);
    this.views = parseInt(payload.views, 10);
    this.likes = parseInt(payload.likes, 10);

    this.isFavorite = parseInt(payload.is_favorite, 10) === 1;
    this.isNew = parseInt(payload.is_new, 10) === 1;

    this.isViewed = parseInt(payload.is_viewed, 10) === 1;
    this.isLiked = parseInt(payload.is_liked, 10) === 1;
    this.isCommentingAllowed = parseInt(payload.allow_commenting, 10) === 1;
    this.comments = parseInt(payload.count_comments, 10);
    this.isCommented = parseInt(payload.is_commented, 10) === 1;
    this.newComments = parseInt(payload.count_new_comments, 10);
    this.tags = payload.tags;

    this.updatedAt = parseInt(payload.updated_at, 10);
  }

  incrementViews() {
    this.views += 1;
  }

  setIsViewed(isVeiwed: boolean) {
    this.isViewed = isVeiwed;
  }

  setIsCommentingAllowed(isCommentingAllowed: boolean) {
    this.isCommentingAllowed = isCommentingAllowed;
  }

  markAsRead() {
    this.isNew = false;
  }

  markAsCommented() {
    this.isCommented = true;
  }

  markAllCommentsAsRead() {
    this.newComments = 0;
  }

  putLike() {
    this.isLiked = true;
    this.likes += 1;
  }

  removeLike() {
    this.isLiked = false;
    this.likes -= 1;
  }

  setFavorite(status: boolean): void {
    this.isFavorite = status;
  }

  setCommentsPagination(pagination: OffsetPagination, paginationDirection: PaginationDirection) {
    if (paginationDirection === PaginationDirection.DEFAULT || !this.commentsPagination) {
      this.commentsPagination = new UpDownPagination(pagination);
    } else if (this.commentsPagination && paginationDirection === PaginationDirection.UP) {
      this.commentsPagination.setMinOffset(pagination);
    } else if (this.commentsPagination && paginationDirection === PaginationDirection.DOWN) {
      this.commentsPagination.setMaxOffset(pagination);
    }
  }

  setCommentsList(comments: UserComment[], total: number) {
    this.commentsList = comments;
    this.comments = total;
  }

  getUnreadComments(): UserComment[] {
    return filter(this.commentsList, comment => comment.isNew);
  }

  getReadComments(): UserComment[] {
    return filter(this.commentsList, comment => !comment.isNew);
  }

  isFirstPageComments(): boolean {
    return !!(this.commentsPagination && this.commentsPagination.isFirstPage());
  }

  isLastPageComments(): boolean {
    return !!(this.commentsPagination && this.commentsPagination.isLastPage());
  }

  markCommentsAsRead() {
    const isNewCommentsCount = filter(this.commentsList, comment => comment.isNew).length;
    this.newComments -= isNewCommentsCount;
    each(this.commentsList, comment => comment.markAsRead());
  }

  getCommentsIsNewIds() {
    return map(
      filter(this.commentsList, o => o.isNew),
      'id',
    );
  }

  getParts(): object[] {
    return [];
  }

  getStatus(): MaterialStatus {
    return this.isNew ? MaterialStatus.NEW : MaterialStatus.ANY;
  }
}
