import moment from 'moment';
import Field from '@/entities/modules/tasks/Field';
import FieldType from '@/entities/modules/tasks/FieldType';

export default class SingleField extends Field {
  hint: string;
  isScanAvailable: boolean;
  file: object;
  downloadFileStatus: string;

  constructor(payload: any) {
    super(payload);

    this.hint = payload.hint;
    this.isScanAvailable = parseInt(payload.is_scan_available, 10) === 1;
    this.value = '';
    this.error = '';
    this.size = 0;
    this.file = {};
    this.downloadFileStatus = '';
  }

  setFile(file: any): void {
    this.file = file;
  }

  setSize(size: number): void {
    this.size = size;
  }

  setValue(value: string | Date): void {
    this.value = value;
  }

  setDownloadFileStatus(status: string): void {
    this.downloadFileStatus = status;
  }

  clearFile(): void {
    this.value = '';
    this.size = 0;
    this.file = {};
    this.error = '';
    this.downloadFileStatus = '';
    this.setHasError(false);
  }

  getSaving(): object {
    const answer: any = {};

    if (this.value) {
      answer.id = this.id;

      if (this.type === FieldType.DATE) {
        const date = moment(this.value, 'DD.MM.YYYY');
        answer.value = date.format('YYYY-MM-DD');
      } else {
        answer.value = this.value;
      }

      if (this.type === FieldType.IMAGE || this.type === FieldType.FILE) {
        answer.file_size = this.size;
      }
    }

    return answer;
  }
}
