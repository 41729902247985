import axios from 'axios';
import ApiName from '@/entities/common/ApiName';
import SearchResult from '@/entities/modules/search/SearchResult';
import { each } from 'lodash';
import SearchResultInformation from '@/entities/modules/search/SearchResultInformation';
import SearchResultEvent from '@/entities/modules/search/SearchResultEvent';
import SearchResultCommon from '@/entities/modules/search/SearchResultCommon';
import Context from '@/entities/modules/search/Context';

export default {
  setFavoriteStatus(type: ApiName, id: number, status: boolean, context: Context | null = null) {
    const data = {
      type,
      items: [
        {
          id,
          is_favorite: status ? 0 : 1,
        },
      ],
    };

    if (context) {
      // @ts-ignore
      data.context = {
        entity_id: context.entityId,
        entity_type: context.entityType,
      };
    }

    return axios.post('/favorites/update', data);
  },

  async getFavorites() {
    const response: any = await axios.get('/favorites');

    const items: SearchResult[] = [];

    each(response.data.success.favorites, favorite => {
      let item;

      if (
        favorite.entity_type === ApiName.INFORMATION_CATEGORY ||
        favorite.entity_type === ApiName.INFORMATION
      ) {
        item = new SearchResultInformation(favorite);
      } else if (favorite.entity_type === ApiName.EVENT) {
        item = new SearchResultEvent(favorite);
      } else {
        item = new SearchResultCommon(favorite);
      }

      item.setFavorite(true);

      items.push(item);
    });

    return items;
  },
};
