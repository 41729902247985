import moment from 'moment';
import dateProvider from '@/providers/dateProvider';
import { createUserStatus, UserStatus } from '@/entities/modules/events/UserStatus';
import SearchResult from '@/entities/modules/search/SearchResult';

export default class SearchResultEvent extends SearchResult {
  type: string = 'events';

  status?: UserStatus;

  isEvent: boolean = false;
  isPlan: boolean = false;
  eventDate?: Date;

  parts: string[] = [];

  constructor(payload: any) {
    super(payload);

    if (payload.additional_data) {
      this.isEvent = parseInt(payload.additional_data.without_date, 10) === 0;
      this.isPlan = parseInt(payload.additional_data.without_date, 10) === 1;
      this.status = createUserStatus(payload.additional_data.user_status);

      if (this.isEvent) {
        this.eventDate = dateProvider(payload.additional_data.start_date);
      }
    } else {
      this.isEvent = !!payload.start_date;
      this.isPlan = !payload.start_date;

      if (this.isEvent) {
        this.eventDate = dateProvider(payload.start_date);
      }
    }

    if (this.eventDate) {
      this.parts.push(
        `${moment(this.eventDate).format('D MMMM')}, ${moment(this.eventDate).format(
          'dd',
        )} ${moment(this.eventDate).format('LT')}`,
      );
    }
  }
}
