<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :class="className"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.17312 1.33325L6.00065 1.33325C6.36884 1.33325 6.66732 1.63173
    6.66732 1.99992C6.66732 2.36811 6.36884 2.66659 6.00065 2.66659H5.20065C4.6296 2.66659 4.2414 2.6671 3.94134
    2.69162C3.64907 2.7155 3.4996 2.75878 3.39533 2.81191C3.14445 2.93974 2.94047 3.14372 2.81264 3.3946C2.75952
    3.49886 2.71623 3.64833 2.69235 3.94061C2.66784 4.24067 2.66732 4.62887 2.66732 5.19992V10.7999C2.66732 11.371
    2.66784 11.7592 2.69235 12.0592C2.71623 12.3515 2.75952 12.501 2.81264 12.6052C2.94047 12.8561 3.14445 13.0601
    3.39533 13.1879C3.4996 13.2411 3.64907 13.2843 3.94134 13.3082C4.2414 13.3327 4.6296 13.3333 5.20065
    13.3333H6.00065C6.36884 13.3333 6.66732 13.6317 6.66732 13.9999C6.66732 14.3681 6.36884 14.6666 6.00065
    14.6666H5.1731C4.63646 14.6666 4.19356 14.6666 3.83277 14.6371C3.45804 14.6065 3.11357 14.5408 2.79001
    14.3759C2.28825 14.1203 1.8803 13.7123 1.62463 13.2106C1.45977 12.887 1.39406 12.5425 1.36345 12.1678C1.33397
    11.807 1.33398 11.3641 1.33398 10.8275V5.17239C1.33398 4.63574 1.33397 4.19283 1.36345 3.83204C1.39406 3.4573
    1.45977 3.11283 1.62464 2.78928C1.8803 2.28751 2.28825 1.87956 2.79001 1.6239C3.11357 1.45904 3.45804 1.39333
    3.83277 1.36271C4.19356 1.33324 4.63647 1.33324 5.17312 1.33325ZM10.1959 4.19518C10.4563 3.93483 10.8784 3.93483
    11.1387 4.19518L14.4721 7.52851C14.7324 7.78886 14.7324 8.21097 14.4721 8.47132L11.1387 11.8047C10.8784 12.065
    10.4563 12.065 10.1959 11.8047C9.93556 11.5443 9.93556 11.1222 10.1959 10.8618L12.3912 8.66659H6.00065C5.63246
    8.66659 5.33399 8.36811 5.33399 7.99992C5.33399 7.63173 5.63246 7.33325 6.00065 7.33325H12.3912L10.1959
    5.13799C9.93556 4.87764 9.93556 4.45553 10.1959 4.19518Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    className: {
      type: String,
      default: 'svg-fill-surface-high-emphasis',
    },
  },
};
</script>
