export default class UserState {
  hasBlockedMessages: boolean;

  constructor(payload: any) {
    if (payload.user_state && payload.user_state.has_blocked_messages) {
      this.hasBlockedMessages = parseInt(payload.user_state.has_blocked_messages, 10) === 1;
    } else {
      this.hasBlockedMessages = false;
    }
  }
}
