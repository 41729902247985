import MaterialStatus from '@/entities/common/testing/MaterialStatus';
// @ts-ignore
import dateProvider from '@/providers/js/dateProvider';
import TestQuestionStatistic from '@/entities/modules/learning-programs/test/TestQuestionStatistic';
import each from 'lodash/each';
import IStatistic from '@/entities/modules/learning-programs/IStatistic';

export default class MaterialTestStatistic implements IStatistic {
  materialId: number;
  updatedAt?: Date;
  percent: number;
  status: string;
  points: number;
  attempt: number;
  currentTurn: number;
  startTime?: Date;
  endTime?: Date;
  userResults: Array<TestQuestionStatistic>;
  updatedAtTimestamp: number;
  isChecked: boolean;
  completed: boolean = false;

  constructor(payload: any) {
    this.materialId = parseInt(payload.id, 10);
    this.percent = payload.percent ? parseInt(payload.percent, 10) : 0;
    this.status = payload.status;
    this.updatedAt = dateProvider(payload.updated_at);
    this.points = parseInt(payload.points, 10);
    this.attempt = parseInt(payload.attempt, 10);
    this.currentTurn = this.attempt;
    this.startTime = payload.start_time;
    this.endTime = dateProvider(payload.end_time);

    this.userResults = [];
    each(payload.user_results, userResultItem => {
      this.userResults.push(new TestQuestionStatistic(userResultItem));
    });

    this.updatedAtTimestamp = payload.updated_at;
    this.isChecked = parseInt(payload.is_checked, 10) === 1;

    this.completed = [MaterialStatus.PASSED, MaterialStatus.FAILED].some(
      status => this.getMaterialStatus() === status,
    );
  }

  getMaterialStatus(): MaterialStatus {
    // проверить
    if (this.status === 'in_progress') {
      return MaterialStatus.IN_PROGRESS;
    }

    if (this.status === 'fail') {
      return MaterialStatus.FAILED;
    }

    if (this.status === 'completed') {
      return MaterialStatus.PASSED;
    }

    if (this.status === 'checking') {
      return MaterialStatus.CHECKING;
    }

    return MaterialStatus.ASSIGNED;
  }

  getPoints(): number {
    return this.points;
  }

  getPercent(): number {
    return this.percent;
  }
}
