var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-notification__item",class:{
    'app-notification__item--is-big': _vm.notification.title,
    'app-notification__item--white': _vm.notification.isWhite,
  }},[(_vm.notification.title)?_c('AppMarkdown',{staticClass:"app-notification__title",attrs:{"source":_vm.notification.title}}):_vm._e(),_c('AppMarkdown',{staticClass:"app-notification__text",attrs:{"source":_vm.notification.message}}),(!_vm.notification.buttons || _vm.notification.hasCloseIcon)?_c('span',{staticClass:"app-notification__close",class:{
      'app-notification__close--small-padding':
        _vm.notification.buttons && _vm.notification.buttons.length === 1,
    },on:{"click":function($event){return _vm.removeNotification(_vm.notification.id)}}},[_c('AppCloseIcon',{attrs:{"is-white":true}})],1):_vm._e(),(_vm.notification.buttons)?_c('div',{staticClass:"app-notification__buttons"},_vm._l((_vm.notification.buttons),function(button,i){return _c('a',{key:i,on:{"click":function($event){return _vm.clickNotificationButton(_vm.notification, button)}}},[_vm._v(" "+_vm._s(button.label)+" ")])}),0):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }