import Vue from 'vue';
import Vuex from 'vuex';
import { RootState } from './types';

import { events } from './modules/events/index';
// eslint-disable-next-line import/no-cycle
import { learningPrograms } from './modules/learning-programs/index';
import { myResults } from './modules/my-results/index';
import { search } from './modules/search/index';
import { tasks } from './modules/tasks/index';
import { video } from './modules/video/index';
import { information } from './modules/information/index';
import { intercom } from './modules/intercom/index';
import { interviews } from './modules/interviews/index';
import { tests } from './modules/tests/index';
import { competencies } from './modules/competencies/index';
// eslint-disable-next-line import/no-cycle
import { dashboard } from './modules/dashboard/index';
import { personalData } from './modules/personal-data/index';
import { favorites } from './modules/favorites/index';
import { giftStore } from './modules/gift-store/index';
// eslint-disable-next-line import/no-cycle
import { rating } from './modules/rating/index';
// eslint-disable-next-line import/no-cycle
import { myTeam } from './modules/my-team/index';
// eslint-disable-next-line import/no-cycle
import { messages } from './modules/messages/index';

// @ts-ignore
import auth from './modules/auth';
// @ts-ignore
import helpers from './modules/helpers';
// @ts-ignore
// eslint-disable-next-line import/no-cycle
import settings from './modules/settings';
// @ts-ignore
import certificates from './modules/certificates';
// @ts-ignore
import kpi from './modules/kpi';

Vue.use(Vuex);

export default new Vuex.Store<RootState>({
  state: {
    version: '2.0.0',
    auth: null,
    settings: null,
    helpers: null,
  },

  modules: {
    auth,
    events,
    learningPrograms,
    helpers,
    settings,
    messages,
    tests,
    interviews,
    competencies,
    dashboard,
    video,
    myTeam,
    personalData,
    information,
    certificates,
    rating,
    kpi,
    search,
    intercom,
    tasks,
    favorites,
    giftStore,
    myResults,
  },
});
