import axios from 'axios';
import { each, has } from 'lodash';
// eslint-disable-next-line import/no-cycle
import {
  createCompanyModules,
  createCompanySettings,
} from '@/entities/modules/settings/CompanySettings';
import { createCompanyBranding } from '@/entities/modules/settings/CompanyBranding';
import { createImageFromString } from '@/entities/common/sources/ImageSource';
import MenuNewItem from '@/entities/modules/settings/MenuNewItem';
import ApiException from '@/entities/common/ApiException';
import { userLocale } from '@/locale';

const basicAuthConfig = {
  headers: {
    Authorization: `Basic ${process.env.VUE_APP_API_AUTHORIZATION_CREDENTIALS}`,
  },
};

export default {
  /**
   * Вернет настройки брендирования
   * @param domain
   * @return {Promise<Readonly<CompanyBranding>>}
   */
  getBranding(domain) {
    return axios
      .get(`/branding/${domain}`, basicAuthConfig)
      .then(response => createCompanyBranding(response.data.success));
  },

  /**
   * Вернет настройки брендирования
   * @return {Promise<Readonly<CompanyBranding>>}
   */
  getBrandingForAuthUser() {
    return axios.get('/branding').then(response => createCompanyBranding(response.data.success));
  },

  /**
   * Вернет настройки компании
   * @param companyId
   * @return {Promise<Readonly<CompanySettings>>}
   */
  getCompanySettings(companyId) {
    return axios
      .post('/settings', { company_id: companyId }, basicAuthConfig)
      .then(response => createCompanySettings(response.data.success));
  },

  async getModules() {
    const response = await axios.get('/modules', {
      headers: {
        'Accept-Language': userLocale,
      },
    });

    return createCompanyModules(response.data.success);
  },

  async updateMenuNewItems() {
    const response = await axios.get('/is-new');

    const menuNewItems = [];

    each(response.data.success, element => {
      menuNewItems.push(new MenuNewItem(element));
    });

    return menuNewItems;
  },

  async getAppLinks(os) {
    const response = await axios.post('/get-app-links', { os });

    const { url } = response.data.success;

    if (!url) {
      return null;
    }

    const { domain } = response.data.success;
    const image = has(response, 'data.success.icon.origin.url')
      ? createImageFromString(response.data.success.icon.origin.url)
      : null;

    const qr = has(response, 'data.success.qr') ? response.data.success.qr : null;

    return {
      url,
      image,
      domain,
      qr,
    };
  },

  async saveTestDataForElastic(payload) {
    try {
      return await axios.post('https://elastic.e-queo.xyz/web/_doc', payload, {
        headers: {
          globalErrorHandler: false,
          Authorization: 'Basic ZnJvbnQ6SlgkeGpVSnpETmdieDljXw==',
          'Content-Type': 'application/json',
          mode: 'cors',
        },
      });
    } catch (e) {
      if (!(e instanceof ApiException) || !e.hasStatus201()) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    }
  },
};
