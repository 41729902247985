import { MutationTree } from 'vuex';
import sortBy from 'lodash/sortBy';
import { VideoState } from './types';

export const mutations: MutationTree<VideoState> = {
  changeVideos(state, payload) {
    state.videos = sortBy(payload, ['order']).reverse();
  },

  changeCategories(state, payload) {
    state.categories = sortBy(payload, ['order']);
  },

  changeSelectedTag(state, selectedTag) {
    state.selectedTag = selectedTag;
  },

  changeSelectedCategory(state, selectedCategory) {
    state.selectedCategory = selectedCategory;
  },

  changeSelectedSortMethod(state, selectedSortMethod) {
    state.selectedSortMethod = selectedSortMethod;
  },
};
