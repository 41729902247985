import axios from 'axios';
import { has, sortBy } from 'lodash';
import { createMaterialsList } from '@/entities/modules/learning-programs/MaterialsList';
import { createProgramsList } from '@/entities/modules/learning-programs/ProgramsList';
import {
  createStatistic,
  createProgramStatisticByUpdatesList,
  createMaterialStatisticByUpdatesList,
  createStatisticsByMaterialsList,
} from '@/entities/modules/learning-programs/StatisticCreator';
import IStatistic from '@/entities/modules/learning-programs/IStatistic';
import Program from '@/entities/modules/learning-programs/Program';
import { createTestQuestions } from '@/entities/modules/learning-programs/test/TestQuestions';
import CustomSettings from '@/entities/modules/learning-programs/CustomSettings';
import UpdateItem from '@/entities/modules/learning-programs/UpdateItem';
import TestQuestion from '@/entities/modules/learning-programs/test/TestQuestion';
import {
  paginationResolver,
  paginationResolverWithAddedMeta,
} from '@/providers/paginationProvider';
import TestSection from '@/entities/modules/learning-programs/test/TestSection';
import { createTestSections } from '@/entities/modules/learning-programs/test/TestSections';
import { resolver } from '@/providers/promiseReturnerProvider';
import { createProgramsStatisticList } from '@/entities/modules/learning-programs/ProgramsStatisticList';
import ProgramStatistic from '@/entities/modules/learning-programs/ProgramStatistic';
import { createFeedbackList } from '@/entities/modules/learning-programs/feedback/Feedback';
import FeedbackItem from '@/entities/modules/learning-programs/feedback/FeedbackItem';
import BaseMaterial from '@/entities/modules/learning-programs/BaseMaterial';
import LongreadPage, {
  createLongreadPages,
} from '@/entities/modules/learning-programs/longread/LongreadPage';
import LongreadPageStatistic, {
  createLongreadPageStatistics,
} from '@/entities/modules/learning-programs/longread/LongreadPageStatistic';

async function paginationGetMaterialsStatistics(page: number) {
  const response = await axios.get(`/materials/stat-cr?page=${page}`);

  return createStatisticsByMaterialsList(response.data.success);
}

async function paginationPostMaterialsStatistics(page: number, materialIds: number[]) {
  const response = await axios.post(`/materials/stat-cr?page=${page}`, { materials: materialIds });

  return createStatisticsByMaterialsList(response.data.success);
}

async function paginationPostMaterialsStatUpdates(page: number, materialIds: number[]) {
  const response = await axios.post(`/materials/stat/updates?page=${page}`, {
    materials: materialIds,
  });

  return createMaterialStatisticByUpdatesList(response.data.success);
}

async function paginationPostMaterialsUpdates(page: number, materialIds: number[]) {
  const response = await axios.post(`/materials/updates?page=${page}`, { materials: materialIds });

  return createMaterialStatisticByUpdatesList(response.data.success);
}

async function paginationGetMaterials(
  page: number,
  statistics: IStatistic[],
  learningProgramsSettings: CustomSettings,
) {
  const response = await axios.get(`/materials-cr?page=${page}`);

  return createMaterialsList(statistics, response.data.success, learningProgramsSettings);
}

async function paginationPostMaterials(
  page: number,
  materialIds: number[],
  statistics: IStatistic[],
  learningProgramsSettings: CustomSettings,
) {
  const response = await axios.post(`/materials-cr?page=${page}`, { materials: materialIds });

  return createMaterialsList(statistics, response.data.success, learningProgramsSettings);
}

async function paginationGetPrograms(
  page: number,
  moduleId: number,
  allowToSkip: boolean,
  programStatistics: ProgramStatistic[],
) {
  const response = await axios.get(`/learning-programs?page=${page}&module_id=${moduleId}`);

  return createProgramsList(allowToSkip, programStatistics, response.data.success);
}

async function paginationPostPrograms(
  page: number,
  moduleId: number,
  programIds: number[],
  allowToSkip: boolean,
  programStatistics: ProgramStatistic[],
) {
  const response = await axios.post(`/learning-programs?page=${page}&module_id=${moduleId}`, {
    learning_programs: programIds,
  });

  return createProgramsList(allowToSkip, programStatistics, response.data.success);
}

async function paginationGetProgramsUpdates(page: number, moduleId: number) {
  const response = await axios.get(`/learning-programs/updates?page=${page}&module_id=${moduleId}`);

  return createProgramStatisticByUpdatesList(response.data.success);
}

async function paginationGetProgramsStat(page: number, moduleId: number) {
  const response = await axios.get(`/learning-programs/stat?page=${page}&module_id=${moduleId}`);

  return createProgramsStatisticList(response.data.success);
}

async function paginationPostProgramsStat(page: number, moduleId: number, programIds: number[]) {
  const response = await axios.post(`/learning-programs/stat?page=${page}&module_id=${moduleId}`, {
    learning_programs: programIds,
  });

  return createProgramsStatisticList(response.data.success);
}

async function paginationGetProgramsStatUpdates(page: number, moduleId: number) {
  const response = await axios.get(
    `/learning-programs/stat/updates?page=${page}&module_id=${moduleId}`,
  );

  return createProgramStatisticByUpdatesList(response.data.success);
}

async function paginationGetTestQuestions(page: number, id: number) {
  const response = await axios.get(`/materials/test/${id}/questions-cr?page=${page}`);

  return createTestQuestions(response.data.success);
}

async function paginationGetTestSections(page: number, id: number) {
  const response = await axios.get(`/materials/test/${id}/sections?page=${page}`);

  return createTestSections(response.data.success);
}

async function paginationGetFeedback(page: number, programId: number) {
  const response = await axios.get(`/learning-programs/${programId}/feedback?page=${page}`);

  return createFeedbackList(response.data.success);
}

async function paginationGetLongreadTitles(
  page: number,
  id: number,
  statistics: LongreadPageStatistic[],
) {
  const response = await axios.post(`/materials/longreads/pages/titles?page=${page}`, {
    longreads: [id],
  });

  return createLongreadPages(response.data.success, statistics);
}

async function paginationGetLongreadPagesStatistic(page: number, id: number) {
  const response = await axios.post(`/materials/longreads/pages/stat?page=${page}`, {
    longreads: [id],
  });

  return createLongreadPageStatistics(response.data.success);
}

export default {
  /**
   * Вернет статистику по материалам
   */
  async getMaterialsStatistics(): Promise<IStatistic[]> {
    return paginationResolver<IStatistic>(paginationGetMaterialsStatistics, []);
  },

  /**
   * Вернет статистику по материалам
   */
  async postMaterialsStatistics(materialIds: number[]): Promise<IStatistic[]> {
    return paginationResolver<IStatistic>(paginationPostMaterialsStatistics, [materialIds]);
  },

  /**
   * Вернет updates статистики по материалам
   */
  async postMaterialsStatUpdates(materialIds: number[]): Promise<UpdateItem[]> {
    return paginationResolver<UpdateItem>(paginationPostMaterialsStatUpdates, [materialIds]);
  },

  /**
   * Вернет updates по материалам
   */
  async postMaterialsUpdates(materialIds: number[]): Promise<UpdateItem[]> {
    return paginationResolver<UpdateItem>(paginationPostMaterialsUpdates, [materialIds]);
  },

  /**
   * Вернет список материалов
   * @param statistics
   * @param learningProgramsSettings
   */
  async getMaterials(
    statistics: IStatistic[],
    learningProgramsSettings: CustomSettings,
  ): Promise<BaseMaterial[]> {
    return paginationResolver<BaseMaterial>(paginationGetMaterials, [
      statistics,
      learningProgramsSettings,
    ]);
  },

  /**
   * Вернет список материалов
   * @param materialIds
   * @param statistics
   * @param learningProgramsSettings
   */
  async postMaterials(
    materialIds: number[],
    statistics: IStatistic[],
    learningProgramsSettings: CustomSettings,
  ): Promise<BaseMaterial[]> {
    return paginationResolver<BaseMaterial>(paginationPostMaterials, [
      materialIds,
      statistics,
      learningProgramsSettings,
    ]);
  },

  /**
   * Вернет список програм обучения
   */
  async getPrograms(moduleId: number, allowToSkip: boolean, programStatistics: ProgramStatistic[]) {
    return paginationResolver<Program>(paginationGetPrograms, [
      moduleId,
      allowToSkip,
      programStatistics,
    ]);
  },

  /**
   * Вернет список програм обучения
   */
  async postPrograms(
    moduleId: number,
    programIds: number[],
    allowToSkip: boolean,
    programStatistics: ProgramStatistic[],
  ) {
    return paginationResolver<Program>(paginationPostPrograms, [
      moduleId,
      programIds,
      allowToSkip,
      programStatistics,
    ]);
  },

  /**
   * Вернет updates по программам
   */
  async getProgramsUpdates(moduleId: number): Promise<UpdateItem[]> {
    return paginationResolver<UpdateItem>(paginationGetProgramsUpdates, [moduleId]);
  },

  /**
   * Вернет список статистики програм обучения
   */
  async getProgramsStatistic(moduleId: number) {
    return paginationResolver<ProgramStatistic>(paginationGetProgramsStat, [moduleId]);
  },

  /**
   * Вернет список програм обучения
   */
  async postProgramsStatistic(moduleId: number, programIds: number[]) {
    return paginationResolver<ProgramStatistic>(paginationPostProgramsStat, [moduleId, programIds]);
  },

  /**
   * Вернет updates по статистике программ
   */
  async getProgramsStatUpdates(moduleId: number): Promise<UpdateItem[]> {
    return paginationResolver<UpdateItem>(paginationGetProgramsStatUpdates, [moduleId]);
  },

  /**
   * Сбросит метку NEW
   */
  async clearIsNew(programId: number) {
    return axios.post('/learning-programs/stat/clear-is-new', {
      learning_programs: [programId],
    });
  },

  async clearIsChecked(materialId: number) {
    return axios.post('/materials/stat/clear-is-checked', {
      materials: [materialId],
    });
  },

  /**
   * Отправка статистики по материалу
   * @param statistic
   */
  async sendStatistic(statistic: any) {
    const response = await axios.post('/materials/stat/update-cr', {
      materials: [statistic],
    });

    return createStatistic(response.data.success);
  },

  /**
   * Вернет список вопросов
   */
  async getTestQuestions(id: number) {
    return paginationResolver<TestQuestion>(paginationGetTestQuestions, [id]);
  },

  /**
   * Вернет uuid попытки
   * @param id
   */
  async getTestUuid(id: number): Promise<string | null> {
    const response = await axios.post(`/materials/test/${id}/stat/increment-attempt`);

    if (has(response, 'data.success.attempt_uuid')) {
      return resolver(response.data.success.attempt_uuid);
    }

    return resolver(null);
  },

  /**
   * Вернет список вопросов
   */
  async getTestSections(id: number) {
    return paginationResolver<TestSection>(paginationGetTestSections, [id]);
  },

  /**
   * Вернет список отзывов
   */
  async getFeedback(programId: number) {
    return paginationResolverWithAddedMeta<FeedbackItem>(paginationGetFeedback, [programId]);
  },

  /**
   * Вернет отзыв текущего пользователя
   */
  async getFeedbackCurrentUser(programId: number) {
    const response = await axios.get(
      `/learning-programs/${programId}/feedback/current-user-feedback`,
    );

    return new FeedbackItem(response.data.success.feedback);
  },

  /**
   * Вернет программу по id
   */
  async postProgram(moduleId: number, programId: number) {
    const response = await axios.post(`/learning-programs?module_id=${moduleId}`, {
      learning_programs: [programId],
    });

    return new Program(response.data.success.learning_programs[0]);
  },

  /**
   * Сохранение отзыва
   */
  async saveFeedback(feedbackItem: FeedbackItem) {
    return axios.post('/learning-programs/feedback', {
      feedback_list: [feedbackItem.getSaving()],
    });
  },

  getLongreadPagesStatistic(id: number) {
    return paginationResolver<LongreadPageStatistic>(paginationGetLongreadPagesStatistic, [id]);
  },

  async getLongreadTitles(id: number, statistics: LongreadPageStatistic[]) {
    const pages = await paginationResolver<LongreadPage>(paginationGetLongreadTitles, [
      id,
      statistics,
    ]);

    return sortBy(pages, ['order']);
  },

  async getLongreadPageByUuid(page: LongreadPage) {
    const response = await axios.post('/materials/longreads/page', {
      longread: page.materialId,
      page: page.uuid,
    });

    return new LongreadPage(response.data.success.page, page.statistic);
  },
};
