<template>
  <vue-custom-scrollbar
    v-if="scrollContentClass"
    :settings="appScrollSettings"
    :class="scrollContentClass"
    :swicher="swicher"
    @ps-scroll-y="$emit('scrollY')"
    @ps-y-reach-end="$emit('scrollYEnd')"
    @ps-y-reach-start="$emit('scrollYStart')"
    @ps-scroll-down="$emit('scrollYDown')"
  >
    <slot></slot>
  </vue-custom-scrollbar>
</template>

<script>
import VueCustomScrollbar from 'vue-custom-scrollbar';

export default {
  name: 'scroll-content',

  components: {
    VueCustomScrollbar,
  },

  props: {
    appScrollSettings: {
      type: Object,
      default: null,
    },

    scrollContentClass: {
      // eslint-disable-next-line no-bitwise
      type: [String, Array],
      default: null,
    },
  },

  computed: {
    swicher() {
      if (this.appScrollSettings && 'swicher' in this.appScrollSettings) {
        return this.appScrollSettings.swicher;
      }

      return true;
    },
  },
};
</script>

<style>
.ps__thumb-y {
  right: 4px;
  width: 4px;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  background-color: transparent;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  width: 8px;
  background-color: var(--surfaceEmphasis20);
}

.ps--scrolling-y .ps__thumb-y {
  width: 8px;
}
</style>
