import { each } from 'lodash';
import BaseWidget from '@/entities/modules/dashboard/BaseWidget';
import VideoMaterial from '@/entities/modules/dashboard/VideoMaterial';

export default class VideoWidget extends BaseWidget {
  videos: Object[];

  constructor(payload: any) {
    super(payload);

    this.videos = [];
    each(payload.data.discovers, video => {
      this.videos.push(new VideoMaterial(video).getWidget());
    });
  }
}
