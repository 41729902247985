import SearchResult from '@/entities/modules/search/SearchResult';
import ApiName from '@/entities/common/ApiName';
import CorrectName from '@/entities/common/CorrectName';

export default class SearchResultCommon extends SearchResult {
  type: string;

  constructor(payload: any) {
    super(payload);

    const type = payload.type ? payload.type : payload.entity_type;

    switch (type) {
      case ApiName.TEST:
        this.type = CorrectName.TESTS;
        break;
      case ApiName.INTERVIEW:
        this.type = CorrectName.INTERVIEW;
        break;
      case ApiName.LEARNING_TRACK:
        this.type = CorrectName.LEARNING_TRACK;
        break;
      case ApiName.LEARNING_PROGRAM:
        this.type = CorrectName.LEARNING_PROGRAMS;
        break;
      case ApiName.COMPETENCY:
        this.type = CorrectName.COMPETENCY;
        break;
      case ApiName.VIDEO:
        this.type = CorrectName.VIDEO;
        break;
      case ApiName.EVENT:
        this.type = CorrectName.EVENT;
        break;
      case ApiName.TEAM:
        this.type = CorrectName.TEAM;
        break;
      case ApiName.GIFT_STORE:
        this.type = CorrectName.GIFT_STORE;
        break;
      case ApiName.TASK:
        this.type = CorrectName.TASK;
        break;
      default:
        this.type = '';
    }
  }
}
