import IAnswerOption from '@/entities/common/testing/answer-oprions/IAnswerOption';

export default class MCQAnswerOption implements IAnswerOption {
  answerId: number;
  option: string;
  isCorrect: boolean;

  isSuccess: boolean = false;
  isFailed: boolean = false;

  constructor(payload: any) {
    this.answerId = parseInt(payload.id, 10);

    this.option = payload.answer || '';

    this.isCorrect = !!payload.correct;
  }
}
