import find from 'lodash/find';
import { GetterTree } from 'vuex';
import each from 'lodash/each';
import filter from 'lodash/filter';
import Video from '@/entities/modules/video/Video';
import { VideoState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<VideoState, RootState> = {
  categories(state) {
    return state.categories;
  },

  videos(state) {
    if (state.selectedTag) {
      const selectedVideos: Video[] = [];
      each(state.videos, video => {
        if (find(video.tags, tag => tag === state.selectedTag)) {
          selectedVideos.push(video);
        }
      });

      return selectedVideos;
    }

    return state.videos;
  },

  selectedCategory(state): any {
    return state.selectedCategory;
  },

  tags: (state): string[] => {
    const tags: string[] = [];

    let filteredVideos: Video[] = state.videos;

    const { selectedCategory } = state;

    if (selectedCategory) {
      filteredVideos = filter(state.videos, video => video.categoryId === selectedCategory.id);
    }

    const tagsArray: { name: string; count: number }[] = [];
    each(filteredVideos, video => {
      each(video.tags, tagItem => {
        const currentTag = find(tagsArray, (tag: any) => tag.name === tagItem);

        if (currentTag) {
          currentTag.count += 1;
        } else {
          tagsArray.push({
            name: tagItem,
            count: 1,
          });
        }
      });
    });

    tagsArray.sort((a: any, b: any) => {
      if (a.count > b.count) return -1;
      if (a.count < b.count) return 1;

      if (a.count === b.count) {
        if (a.name > b.name) {
          return 1;
        }

        if (a.name < b.name) {
          return -1;
        }
      }

      return 0;
    });

    each(tagsArray, (tagItem: any) => {
      tags.push(tagItem.name);
    });

    return tags;
  },

  selectedTag(state): String | null {
    return state.selectedTag;
  },

  selectedSortMethod(state): String | null {
    return state.selectedSortMethod;
  },
};
