import { each, find } from 'lodash';
import dateProvider from '@/providers/js/dateProvider';
import TestQuestionStatistic from '../learning-programs/test/TestQuestionStatistic';

export class LearningProgramMaterialStatistic {
  constructor({
    id,
    percent,
    status,
    updatedAt,
    points,
    attempt,
    startTime,
    endTime,
    userResults,
  }) {
    this.id = id;
    this.percent = percent;
    this.status = status;
    this.updatedAt = updatedAt;
    this.points = points;
    this.attempt = attempt;
    this.startTime = startTime;
    this.endTime = endTime;
    this.userResults = userResults;
  }
}

export function createLearningProgramMaterialStatistic(payload, questions) {
  const userResults = [];
  each(payload.user_results, userResultItem => {
    const obj = new TestQuestionStatistic(userResultItem);

    const question = find(questions, q => q.id === obj.id);

    if (question) {
      obj.name = question.name;
      userResults.push(obj);
    }
  });

  return Object.freeze(
    new LearningProgramMaterialStatistic({
      id: parseInt(payload.id, 10),
      percent: payload.percent ? parseInt(payload.percent, 10) : 0,
      status: payload.status,
      updatedAt: dateProvider(payload.updated_at),
      points: parseInt(payload.points, 10),
      attempt: parseInt(payload.attempt, 10),
      startTime: payload.start_time,
      endTime: payload.end_time,
      userResults,
    }),
  );
}
