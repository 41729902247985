import helperApi from '@/services/common';

export default {
  namespaced: true,

  state: {
    /**
     * Открыт или закрыт sidebar
     */
    isSidebarOpened: false,

    /**
     * Разрешен показ sidebar'а или нет
     */
    isSidebarEnabled: true,

    isAddedMenuOpened: false,

    /**
     * Ширина экрана
     */
    ww: 0,

    /**
     * Высота экрана
     */
    wh: 0,

    /**
     * Если true, к body будет добавлен класс has-background
     */
    hasBackground: false,

    /**
     * Массив уведомлений
     */
    notifications: [],
    notificationId: 0,
    categories: [],

    /**
     * Если не существует такой страницы
     */
    has404: false,
  },

  getters: {
    /**
     * True, если sidebar открыт
     * @param state
     * @returns {boolean}
     */
    isSidebarOpened: state => state.isSidebarOpened,

    isSidebarEnabled: state => state.isSidebarEnabled,

    /**
     * True, если доп меню открыто
     * @param state
     * @returns {boolean}
     */
    isAddedMenuOpened: state => state.isAddedMenuOpened,

    /**
     * Вернет ширину экрана в px
     * @param state
     * @returns {number}
     */
    ww: state => state.ww,

    /**
     * Вернет параметр экрана: мобилка, планшет или десктоп
     * @param state
     * @returns {boolean}
     */
    isMobile: state => state.ww < 768,
    isTablet: state => state.ww > 767 && state.ww < 1280,
    isDesktop: state => state.ww > 1279,

    /**
     * Вернет высоту экрана в px
     * @param state
     * @returns {number}
     */
    wh: state => state.wh,

    ww1Columns: state => (state.ww < 1279 ? 1 : 3),
    ww1ColumnsForIsX: state => (state.ww < 1279 ? 'is-full' : 'is-one-third'),

    ww2Columns: state => (state.ww >= 768 ? 2 : 1),
    ww2ColumnsForIsX: state => (state.ww >= 768 ? 'is-half' : 'is-full'),

    ww3Columns: state => (state.ww >= 1920 ? 3 : 2),
    ww3ColumnsForIsX: state => (state.ww >= 1920 ? 'is-one-third' : 'is-half'),

    ww4Columns: state => (state.ww >= 1920 ? 4 : 3),
    ww4ColumnsForIsX: state => (state.ww >= 1920 ? 'is-one-quarter' : 'is-one-third'),

    ww5Columns: state => (state.ww >= 1920 ? 5 : 4),
    ww5ColumnsForIsX: state => (state.ww >= 1920 ? 'is-one-fifth' : 'is-one-quarter'),

    hasBackground: state => state.hasBackground,

    notifications: state => state.notifications,

    categories: state => state.categories,

    has404: state => state.has404,
  },

  actions: {
    /**
     * Откроет sidebar
     * @param commit
     */
    showSidebar({ commit }) {
      commit('setSidebarStatus', true);
    },

    /**
     * Закроет sidebar
     * @param commit
     */
    hideSidebar({ commit }) {
      commit('setSidebarStatus', false);
    },

    enableSidebar({ commit }) {
      commit('setSidebarEnabled', true);
    },

    disableSidebar({ commit }) {
      commit('setSidebarEnabled', false);
    },

    /**
     * Добавит фон
     * @param commit
     */
    showBackground({ commit }) {
      commit('setBackground', true);
    },

    /**
     * Уберет фон
     * @param commit
     */
    hideBackground({ commit }) {
      commit('setBackground', false);
    },

    /**
     * Изменит ширину экрана
     * @param commit
     * @param ww
     */
    updateWindowWidth({ commit }, ww) {
      commit('updateWindowWidth', ww);
    },

    /**
     * Изменит высоту экрана
     * @param commit
     * @param wh
     */
    updateWindowHeight({ commit }, wh) {
      commit('updateWindowHeight', wh);
    },

    /*
     * Показать уведомление
     * @param commit
     * @param state
     * @param message
     */
    showNotification(
      { commit, state },
      {
        message,
        title,
        buttons,
        timeout,
        isWhite,
        hasNotOverlay,
        hasTransparentOverlay,
        hasCloseIcon,
      },
    ) {
      const id = state.notificationId + 1;
      const notificationObj = {
        id,
        message,
        title,
        buttons,
        timeout,
        isWhite,
        hasNotOverlay,
        hasTransparentOverlay,
        hasCloseIcon,
      };

      commit('addNotification', notificationObj);
      commit('updateNotificationId', id);
    },

    /**
     * Удалить уведомление
     * @param commit
     * @param state
     * @param id
     */
    removeNotification({ commit, state }, id) {
      state.notifications.forEach(notification => {
        if (notification.id === id) {
          commit('removeNotification', notification.id);
        }
      });
    },

    /**
     * вернет пустой массив уведомлений
     * @param state
     */
    removeNotifications({ commit }) {
      commit('removeNotifications');
    },

    show404({ commit }) {
      commit('changeHas404', true);
    },

    hide404({ commit }) {
      commit('changeHas404', false);
    },

    async getCategories(o, { type, moduleId, page }) {
      return helperApi.getCategories(type, moduleId, page);
    },

    async fileUpload({}, formData) {
      return helperApi.fileUpload(formData);
    },

    getFileToken() {
      return helperApi.getFileToken();
    },
  },

  mutations: {
    /**
     * Изменит состояние sidebar'а
     * @param state
     * @param payload
     */
    setSidebarStatus(state, payload) {
      state.isSidebarOpened = payload;
    },

    setSidebarEnabled(state, payload) {
      state.isSidebarEnabled = payload;
    },

    /**
     * Изменит состояние доп меню
     * @param state
     * @param payload
     */
    setAddedMenuStatus(state, payload) {
      state.isAddedMenuOpened = payload;
    },

    /**
     * Изменит фон
     * @param state
     * @param payload
     */
    setBackground(state, payload) {
      state.hasBackground = payload;
    },

    /**
     * Изменит состояние ширины экрана
     * @param state
     * @param payload
     */
    updateWindowWidth(state, payload) {
      state.ww = payload;
    },

    /**
     * Изменит состояние высоты экрана
     * @param state
     * @param payload
     */
    updateWindowHeight(state, payload) {
      state.wh = payload;
    },

    /*
     * Изменить id уведомления
     * @param state
     * @param payload
     */
    updateNotificationId(state, payload) {
      state.notificationId = payload;
    },

    /**
     * Добавить уведомление
     * @param state
     * @param payload
     */
    addNotification(state, payload) {
      state.notifications.unshift(payload);
    },

    /**
     * Удалить уведомление
     * @param state
     */
    removeNotification(state) {
      state.notifications.pop();
    },

    /**
     * вернет пустой массив уведомлений
     * @param state
     */
    removeNotifications(state) {
      state.notifications = [];
    },

    changeHas404(state, payload) {
      state.has404 = payload;
    },

    setCategories(state, payload) {
      state.categories = payload;
    },
  },
};
