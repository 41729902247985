export default class AnswerReviewer {
  id: number;
  name: string | null;
  type: string;
  avatar: string | null;
  isDeleted: boolean;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.name = payload.name;
    this.type = payload.type;
    this.avatar = payload.avatar?.url || null;
    this.isDeleted = parseInt(payload.is_deleted, 10) === 1;
  }
}
