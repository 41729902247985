import UserVerified from '@/entities/modules/auth/UserVerified';

export default class FakeUser {
  licenseAgree: boolean;
  userVerified: UserVerified;

  constructor(payload: any) {
    this.licenseAgree = parseInt(payload.profile.is_need_license_agree, 10) === 0;
    this.userVerified = new UserVerified(payload.profile.need_verify);
  }
}
