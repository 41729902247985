export class MyTeam {
  constructor({ isReportsAvailable }) {
    this.isReportsAvailable = isReportsAvailable;
  }
}

export function createMyTeamSettings(data) {
  return Object.freeze(
    new MyTeam({
      isReportsAvailable: parseInt(data.reports_available, 10) === 1,
    }),
  );
}
