import kpiApi from '@/services/kpi';

export default {
  namespaced: true,

  state: {
    // список кпи
    kpiList: null,
    // список транзакций по кпи с запрошенным id
    kpiTransactions: null,
    // мета информация по списку транзакций с запрошенным kpi id
    canIUseKpiList: false,
    // инфо по балансу
    kpiInfo: null,
    canIUseKpiTransaction: false,
  },

  getters: {
    // вернет флаг можно ли использовать список кпи
    canIUseKpiList: state => state.canIUseKpiList,
    // вернет флаг можно ли использовать список транзакций по кпи
    canIUseKpiTransactions: state => state.canIUseKpiTransaction,
    // вернет список кпи
    kpiList: state => state.kpiList,
    // вернет список транзакций по кпи ид и мету
    kpiTransactions: state => state.kpiTransactions,
    // вернет kpi инфу
    kpiInfo: state => state.kpiInfo,
  },

  actions: {
    /**
     * Список юзеров
     * @param commit
     * @param Object
     */
    getKpiList({ commit }) {
      commit('changeCanIUseKpiList', false);

      return kpiApi.getKpiList().then(payload => {
        if (!payload) {
          return null;
        }

        commit('setKpiList', payload);
        commit('changeCanIUseKpiList', true);

        return payload;
      });
    },

    // получит с сервера список транзакций по кпи, мета инфу по транзакциям и сохранит в стор
    getKpiActions(store, params) {
      store.commit('changeCanIUseKpiTransactions', false);

      return kpiApi.getTransactions(params).then(response => {
        if (!response) {
          return null;
        }

        store.commit('setKpiTransactions', response);
        store.commit('changeCanIUseKpiTransactions', true);

        return response;
      });
    },

    getKpiInfo({ commit }) {
      return kpiApi.getKpiInfo().then(response => {
        if (!response) {
          return null;
        }
        commit('setKpiInfo', response);

        return response;
      });
    },
  },

  mutations: {
    /**
     * Изменит canIUseKpiList
     * @param state
     * @param payload
     */
    changeCanIUseKpiList(state, payload) {
      state.canIUseKpiList = payload;
    },

    // меняем флаг "можно использовать список транзакций"
    changeCanIUseKpiTransactions(state, payload) {
      state.canIUseKpiTransaction = payload;
    },

    // добавим в стор список транзакций по кпи
    setKpiTransactions(state, payload) {
      state.kpiTransactions = payload;
    },

    // меняем в сторе список кпи
    setKpiList(state, payload) {
      state.kpiList = payload;
    },

    // запишем кпи инфо баланса
    setKpiInfo(state, payload) {
      state.kpiInfo = payload;
    },
  },
};
