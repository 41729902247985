import CorrectName from '@/entities/common/CorrectName';
import CustomRoute from '@/entities/common/CustomRoute';

export default [
  {
    path: '/information',
    name: CustomRoute.TO_INFORMATION,
    meta: {
      title: 'info_screen_title_text',
      moduleCode: CorrectName.INFORMATION,
      eventOnEnter: 'info_module_open',
    },
    component: () => import(/* webpackChunkName: "information" */ '@/views/information/index.vue'),
  },
  {
    path: '/information/html/:id-:slug',
    name: CustomRoute.TO_INFORMATION_HTML,
    meta: {
      title: 'info_screen_title_text',
      moduleCode: CorrectName.INFORMATION,
      eventOnEnter: 'info_interact_catalog_open_btn',
      showIntercom: false,
      showBlockedMessages: false,
    },
    component: () => import(/* webpackChunkName: "information" */ '@/views/information/html.vue'),
  },
  {
    path: '/information/categories/:id-:slug',
    name: CustomRoute.TO_INFORMATION_CATEGORY,
    meta: { title: 'info_screen_title_text', moduleCode: CorrectName.INFORMATION },
    component: () =>
      import(/* webpackChunkName: "information" */ '@/views/information/category.vue'),
  },
  {
    path: '/information/categories/:categoryId-:categorySlug/subcategories/:id-:slug',
    name: CustomRoute.TO_INFORMATION_SUBCATEGORY,
    meta: { title: 'info_screen_title_text', moduleCode: CorrectName.INFORMATION },
    component: () =>
      import(/* webpackChunkName: "information" */ '@/views/information/subcategory.vue'),
  },
  {
    path: '/information/categories/:categoryId-:categorySlug/subcategories/:subcategoryId-:subcategorySlug/materials/:id-:slug',
    name: CustomRoute.TO_INFORMATION_MATERIAL,
    meta: {
      title: 'info_screen_title_text',
      moduleCode: CorrectName.INFORMATION,
      eventOnEnter: 'info_material_document_open_btn',
      showIntercom: false,
      showBlockedMessages: false,
    },
    component: () =>
      import(/* webpackChunkName: "information" */ '@/views/information/material.vue'),
  },
];
