<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.8941 1.08275C14.3014 1.25952 14.5433 1.68359 14.4882 2.12409L13.6287 9.00006L19.8237 9.00005C20.0647 9.00001 20.3088 8.99996 20.5082 9.01802C20.6968 9.03509 21.058 9.08027 21.3712 9.33338C21.7298 9.62314 21.9352 10.0617 21.9283 10.5227C21.9222 10.9253 21.7257 11.2317 21.6181 11.3875C21.5043 11.5523 21.348 11.7398 21.1936 11.9249L12.2642 22.6402C11.98 22.9813 11.505 23.0941 11.0978 22.9174C10.6905 22.7406 10.4486 22.3165 10.5037 21.876L11.3632 15.0001L5.16818 15.0001C4.92719 15.0001 4.6831 15.0002 4.48367 14.9821C4.29506 14.965 3.9339 14.9198 3.62068 14.6667C3.26211 14.377 3.0567 13.9384 3.06365 13.4774C3.06972 13.0748 3.26623 12.7684 3.37385 12.6126C3.48765 12.4478 3.64396 12.2603 3.79827 12.0752C3.80592 12.066 3.81356 12.0569 3.82119 12.0477L12.7277 1.35987C13.0119 1.01884 13.4869 0.905986 13.8941 1.08275Z"
      fill="#111827"
    />
  </svg>
</template>

<script>
export default {
  name: 'zap-solid-icon',
};
</script>
