export class Tasks {
  constructor({ isCheckedPersonalDataAccessOnly, isHistoryAvailable }) {
    this.isCheckedPersonalDataAccessOnly = isCheckedPersonalDataAccessOnly;
    this.isHistoryAvailable = isHistoryAvailable;
  }
}

export function createTasksSettings(data) {
  return Object.freeze(
    new Tasks({
      isCheckedPersonalDataAccessOnly:
        parseInt(data.is_checked_personal_data_access_only, 10) === 1,
      isHistoryAvailable: parseInt(data.is_history_available, 10) === 1,
    }),
  );
}
