<template>
  <div
    class="app-success-circle"
    :class="{
      'app-success-circle--filled': isFilled,
      'app-success-circle--not-completed': !isSectionCompleted,
    }"
  >
    <CheckMarkSvg />
  </div>
</template>

<script>
import CheckMarkSvg from '@/components/svg/icons/check-mark';

export default {
  name: 'app-success-circle',

  components: {
    CheckMarkSvg,
  },

  props: {
    isFilled: {
      type: Boolean,
      default: true,
    },

    isSectionCompleted: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
