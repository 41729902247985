import { each, find } from 'lodash';
import IFilter from '@/entities/modules/my-team/filters/IFilter';
import has from 'lodash/has';
import filter from 'lodash/filter';
import RootGroup from '../../common/groups/RootGroup';

export default class EmployeeFormFilter implements IFilter {
  id: number;
  name: string;
  order: number;
  sectionId: number;
  subfilters: IFilter[] = [];
  type: string;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.name = payload.name;
    this.order = parseInt(payload.order, 10);
    this.sectionId = parseInt(payload.section_id, 10);
    this.type = payload.type;
  }

  addSubfilter(subfilter: IFilter) {
    if (!find(this.subfilters, o => o.id === subfilter.id)) {
      this.subfilters.push(subfilter);
    }
  }

  removeSubfilter(subfilter: IFilter) {
    if (find(this.subfilters, o => o.id === subfilter.id)) {
      const index = this.subfilters.indexOf(subfilter);
      this.subfilters.splice(index, 1);
    }
  }

  clearSubfilters() {
    this.subfilters = [];
  }
}

export function createEmployeeFilter(data: any): EmployeeFormFilter[][] {
  const employeeFilterArr: EmployeeFormFilter[][] = [];

  // так как у нас анкета может быть выключена то мы проверяем приходит ли к нам массив анкеты
  if (has(data, 'form_questions') && data.form_questions) {
    // если массив приходит то мы каждый элемент массива оборачиваем в массив,
    // так как по дизайну у нас предусмотрены подгруппы
    each(data.form_sections, section => {
      const sectionQuestions: Array<EmployeeFormFilter> = [];

      const sectionQuestionData = filter(data.form_questions, o => o.section_id === section.id);
      each(sectionQuestionData, question => {
        sectionQuestions.push(new EmployeeFormFilter(question));
      });

      employeeFilterArr.push(sectionQuestions);
    });
  }

  return employeeFilterArr;
}

export function getSavingDataEmployeeFilters(
  employeeFilters: (EmployeeFormFilter | RootGroup)[][],
): Object {
  const formFilters: Object[] = [];

  const groupFilters: Object[] = [];

  each(employeeFilters, section => {
    each(section, sectionFilter => {
      if (sectionFilter.subfilters.length > 0) {
        if (sectionFilter instanceof RootGroup) {
          const ids: number[] = [];

          const idsWithInheritance: Object[] = [];
          each(sectionFilter.subfilters, subfilter => {
            if (subfilter.children && subfilter.children.length > 0) {
              idsWithInheritance.push(subfilter.id);
            } else {
              ids.push(subfilter.id);
            }
          });
          groupFilters.push({
            root_id: sectionFilter.id,
            ids,
            ids_with_inheritance: idsWithInheritance,
          });
        } else {
          const answerArr: string[] = [];
          each(sectionFilter.subfilters, subfilter => {
            answerArr.push(subfilter.name);
          });

          formFilters.push({
            id: sectionFilter.id,
            answers: answerArr,
          });
        }
      }
    });
  });

  return {
    filter: {
      forms: formFilters,
      groups: groupFilters,
    },
  };
}
