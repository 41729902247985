import { IUserTaskValueFilter } from '@/types/my-team/filters';

export default class UserTasksValueFilter implements IUserTaskValueFilter {
  countDays: number;
  categories: number[] = [];
  users: number[] = [];

  constructor(payload: any) {
    this.countDays = payload.count_days ? parseInt(payload.count_days, 10) : 0;

    if (payload.category_ids) {
      payload.category_ids.forEach((id: number) => {
        this.categories.push(id);
      });
    }

    if (payload.user_ids) {
      payload.user_ids.forEach((id: number) => {
        this.users.push(id);
      });
    }
  }
}
