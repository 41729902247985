<template>
  <app-tooltip :is-fixed="isFixed">
    <div class="app-tooltip__outer">
      <div class="app-tooltip__icon">
        <FeedbackUserRatingCard
          :has-stroke="false"
          :is-info-color="userHasFeedback"
          :is-surface-medium-emphasis="!userHasFeedback && !isFewRating"
          :is-surface-disabled-emphasis="!userHasFeedback && isFewRating"
        />
        <span
          v-if="!isFewRating"
          class="app-tooltip__text"
          :class="{ 'app-tooltip__text--info': userHasFeedback }"
        >
          {{ rating }}
        </span>
      </div>
      <div
        v-if="feedbackTooltip"
        class="app-tooltip__tooltip"
      >
        {{ feedbackTooltip }}
      </div>
    </div>
  </app-tooltip>
</template>

<script>
import FeedbackUserRatingCard from '@/components/svg/icons/feedback/feedback-user-rating-card.vue';
import AppTooltip from '@/components/helpers/tooltips/tooltip.vue';

export default {
  name: 'feedback-icon',

  components: {
    AppTooltip,
    FeedbackUserRatingCard,
  },

  props: {
    isFixed: {
      type: Boolean,
      default: false,
    },

    userHasFeedback: {
      type: Boolean,
      default: false,
    },

    isFewRating: {
      type: Boolean,
      required: true,
    },

    rating: {
      type: Number,
      default: 0,
    },

    feedbackTooltip: {
      type: String,
      default: '',
    },
  },
};
</script>
