import { MutationTree } from 'vuex';
import { MyTeamState } from '@/store/modules/my-team/types';
import { each, filter, findIndex } from 'lodash';
import UserFilter from '@/entities/modules/my-team/filters/UserFilter';
import Module from '@/entities/modules/my-team/Module';

export const mutations: MutationTree<MyTeamState> = {
  setEmployeeTeamsFilters(state, payload) {
    state.employeeTeamFilters = payload;
  },

  setEmptyFilter(state) {
    each(state.employeeTeamFilters, section => {
      each(section, sectionFilter => {
        // eslint-disable-next-line no-param-reassign
        sectionFilter.subfilters = [];
      });
    });
  },

  changeCountEmployeeForFilter(state, payload) {
    state.countEmployeeForFilter = payload;
  },

  changeCountAppliedSubfilters(state) {
    let count: number = 0;
    each(state.employeeTeamFilters, group => {
      count += filter(group, filterItem => filterItem.subfilters.length > 0).length;
    });
    state.countAppliedSubfilters = count;
  },

  setUserFilters(state, payload) {
    state.userFilters = payload;
  },

  updateUserFilters(state, actual: UserFilter) {
    const index = findIndex(state.userFilters, userFilter => userFilter.name === actual.name);

    if (index === -1) {
      state.userFilters.push(actual);
    } else {
      state.userFilters.splice(index, 1);
      state.userFilters.push(actual);
    }
  },

  setModules(state, modules: Module[]) {
    state.modules = modules;
  },
};
