// eslint-disable-next-line no-shadow
enum FieldType {
  FILE = 'file',
  IMAGE = 'image',
  DATE = 'datetime',
  EMAIL = 'email',
  PHONE = 'phone',
  MULTIPLY_CHOICE = 'multiple_choice',
  TEXT = 'text',
  NUMBER = 'number',
}

export default FieldType;
