import { Route } from 'vue-router';
import BaseExternalAuthType from '@/entities/modules/settings/auth-types/BaseExternalAuthType';

export default class OAuth2AuthType extends BaseExternalAuthType {
  accessTokenParam: string;

  constructor(payload: any) {
    super(payload);

    this.accessTokenParam = payload.params.access_token_param;
  }

  getCheckData(route: Route) {
    const date: any = {
      id: this.id,
      token: route.query[this.accessTokenParam],
    };

    return date;
  }
}
