<template>
  <div
    class="app-section-title"
    :class="{ 'app-section-title--clickable': routeTo || isClickableTitle }"
    v-on="$listeners"
  >
    <div class="app-section-title__row">
      <div
        v-if="$slots['left-icon']"
        class="app-section-title__icon app-section-title__icon--left"
      >
        <slot name="left-icon"></slot>
      </div>
      <router-link
        v-if="routeTo"
        class="app-section-title__content"
        :to="routeTo"
      >
        <slot name="default"></slot>
      </router-link>
      <div
        v-else
        class="app-section-title__content"
      >
        <p class="app-section-title__text">
          <slot name="default"></slot>
        </p>
        <span
          v-if="$slots['right-icon']"
          class="app-section-title__icon app-section-title__icon--right"
        >
          <slot name="right-icon"></slot>
        </span>
      </div>
    </div>
    <div class="app-section-title__row">
      <div
        v-if="$slots.description"
        class="app-section-title__description"
        :class="{ 'app-section-title__description--no-padding': !$slots['left-icon'] }"
      >
        <slot name="description"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app-section-title',

  props: {
    routeTo: {
      type: Object,
      default: () => {},
    },

    isClickableTitle: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
