export interface LearningItemCertificateScheme {
  id: number;
  name?: string;
  date: number;
  url: string;
  certificate_list?: Array<LearningItemCertificateScheme>;
}

export default class LearningItemCertificate {
  date?: number;
  id: number;
  name?: string;
  url: string;
  image: string = '';
  certificateList?: LearningItemCertificate[];

  constructor(payload: LearningItemCertificateScheme) {
    this.date = payload.date;
    this.id = payload.id;

    if (payload.name) {
      this.name = payload.name;
    }

    this.url = payload.url;

    if (payload.certificate_list) {
      this.certificateList = [];
      payload.certificate_list.forEach((item: LearningItemCertificateScheme) => {
        this.certificateList?.push(new LearningItemCertificate(item));
      });
    }
  }

  setImage(image: string) {
    this.image = image;
  }
}
