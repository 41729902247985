import { Module } from 'vuex';
import { RootState } from '../../types';
import { LearningProgramsState } from './types';
import { getters } from './getters';
// eslint-disable-next-line import/no-cycle
import { actions } from './actions';
import { mutations } from './mutations';

export const state: LearningProgramsState = {
  programs: new Map(),

  selectedCategory: null,

  selectedRequiredFilter: false,
  selectedStatusFilter: [],
  selectedSortFilter: '',
  selectedDeadlineFilter: [],

  selectedTag: null,

  currentModuleId: 0,
};

const namespaced: boolean = true;

export const learningPrograms: Module<LearningProgramsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
