import dateProvider from '@/providers/dateProvider';
import { BooleanType } from '@/entities/common/BooleanType';
import LearningItemCertificate, { LearningItemCertificateScheme } from './LearningItemCertificate';
import { LearningItemStatus, LearningItemStatuses } from './LearningItemStatus';
import { LearningItemType } from './LearningItemType';

export interface LearningItemStatisticScheme {
  learning_item_id: string;
  learning_item_entity_type: LearningItemType;
  learning_item_entity_id: number;
  points: number;
  percent: number;
  start_time: number;
  end_time: number;
  status: LearningItemStatus;
  is_new: BooleanType;
  is_checked: BooleanType;
  user_has_feedback: BooleanType;
  updated_at: number;
  certificate: LearningItemCertificateScheme | null;
}

export default class LearningItemStatistic {
  id: string;
  entityType: LearningItemType;
  entityId: number;
  userPoints: number;
  percent: number;
  startTime: number;
  endTime: number;
  status: LearningItemStatus;
  isNew: boolean = false;
  isInProgress: boolean = false;
  isCompleted: boolean = false;
  isFailed: boolean = false;
  isAppointed: boolean = false;
  isChecking: boolean = false;
  isChecked: boolean;
  userHasFeedback: boolean;
  updatedAt: number;
  lastActivity?: Date;
  certificate?: LearningItemCertificate;

  constructor(payload: LearningItemStatisticScheme) {
    this.id = payload.learning_item_id;
    this.entityType = payload.learning_item_entity_type;
    this.entityId = payload.learning_item_entity_id;
    this.userPoints = payload.points;
    this.percent = payload.percent;
    this.startTime = payload.start_time;
    this.endTime = payload.end_time;
    this.status = payload.status;
    this.isNew = payload.is_new === 1;
    this.isChecked = payload.is_checked === 1;
    this.userHasFeedback = payload.user_has_feedback === 1;
    this.updatedAt = payload.updated_at;
    this.lastActivity = dateProvider(payload.updated_at);

    if (payload.certificate) {
      this.certificate = new LearningItemCertificate(payload.certificate);
    }

    this.setStatuses();
  }

  setStatuses() {
    this.isInProgress = this.status === LearningItemStatuses.IN_PROGRESS;
    this.isCompleted = this.status === LearningItemStatuses.COMPLETED;
    this.isFailed = this.status === LearningItemStatuses.FAILED;
    this.isAppointed = this.status === LearningItemStatuses.APPOINTED;
    this.isChecking = this.status === LearningItemStatuses.CHECKING;
  }

  setIsNew(isNew: boolean) {
    this.isNew = isNew;
    this.setStatuses();
  }

  setFeedback(userHasFeedback: boolean) {
    this.userHasFeedback = userHasFeedback;
  }
}
