<template>
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      :class="isWhite ? 'svg-fill-surface-white' : 'svg-fill-surface-medium-emphasis'"
      d="M8.19141 14C11.5051 14 14.1914 11.3137 14.1914 8C14.1914 4.68629 11.5051 2 8.19141
          2C4.8777 2 2.19141 4.68629 2.19141 8C2.19141 11.3137 4.8777 14 8.19141 14ZM8.69141
          4.33259C8.69141 4.05645 8.46755 3.83259 8.19141 3.83259C7.91526 3.83259 7.69141
          4.05645 7.69141 4.33259V8.08259C7.69141 8.2152 7.74408 8.34237 7.83785 8.43614L9.71285
          10.3111C9.90811 10.5064 10.2247 10.5064 10.42 10.3111C10.6152 10.1159 10.6152 9.7993
          10.42 9.60403L8.69141 7.87548V4.33259Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'deadline-clock',

  props: {
    isWhite: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
