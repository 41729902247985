// eslint-disable-next-line max-classes-per-file
import each from 'lodash/each';
import sortBy from 'lodash/sortBy';
import dateProvider from '@/providers/js/dateProvider';

export class UsersActivityReport {
  constructor({ activePercent, notActivePercent, actives, notActives }) {
    this.activePercent = activePercent;
    this.notActivePercent = notActivePercent;
    this.actives = actives;
    this.notActives = notActives;
  }
}

class User {
  constructor({ id, name, lastActive, image }) {
    this.id = id;
    this.name = name;
    this.lastActive = lastActive;
    this.image = image;
  }
}

export function createUsersActivityReport(payload) {
  let actives = [];

  let notActives = [];

  each(payload.active_users, user => {
    actives.push(
      Object.freeze(
        new User({
          id: user.id,
          name: user.name,
          lastActive: dateProvider(user.last_active),
          image: user.image.url,
        }),
      ),
    );
  });

  each(payload.not_active_users, user => {
    notActives.push(
      Object.freeze(
        new User({
          id: user.id,
          name: user.name,
          lastActive: dateProvider(user.last_active),
          image: user.image.url,
        }),
      ),
    );
  });

  actives = sortBy(actives, ['name']);
  notActives = sortBy(notActives, ['name']);

  return Object.freeze(
    new UsersActivityReport({
      activePercent: payload.active_users_percent,
      notActivePercent: payload.not_active_users_percent,
      actives,
      notActives,
    }),
  );
}
