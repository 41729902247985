<template>
  <svg
    class="donut"
    width="100%"
    height="100%"
    viewBox="0 0 42 42"
  >
    <circle
      class="donut--main-color"
      cx="21"
      cy="21"
      r="15.91549430918954"
      fill="transparent"
      stroke-width="10"
    />
    <template v-if="total > 0">
      <circle
        v-for="(element, i) in donutSegments"
        :key="i"
        class="donut-segment"
        :class="
          element.percent > 0 ? `donut-segment__${element.status}` : 'donut-segment__transparent'
        "
        cx="21"
        cy="21"
        r="15.91549430918954"
        fill="transparent"
        :stroke-width="element.percent ? 10 : null"
        :stroke-dasharray="`${element.percent} ${100 - element.percent}`"
        :stroke-dashoffset="element.offset"
      />
    </template>
  </svg>
</template>

<script>
import each from 'lodash/each';

export default {
  name: 'donut-chart',

  props: {
    segments: {
      type: Array,
      default: null,
    },

    total: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    donutSegments() {
      const percentageSegment = [];
      each(this.segments, (segment, index) => {
        percentageSegment.push({
          offset:
            index === 0
              ? 25
              : percentageSegment[index - 1].offset - percentageSegment[index - 1].percent,
          status: segment.status,
          percent: 100 * (segment.count / this.total),
        });
      });

      return percentageSegment;
    },
  },
};
</script>
