import ITestSettings from '@/entities/common/testing/settings/ITestSettings';
import ShowAnswersFormat from '@/entities/common/testing/settings/ShowAnswersFormat';

export default class CustomSettings implements ITestSettings {
  allowSeeDetails: boolean;
  allowSkipQuestions: boolean;
  resultValue: number;
  showAnswers: ShowAnswersFormat;
  showResultText: boolean;
  testBarrier: number;
  testFailed: string;
  testPassed: string;
  testPerfect: string;
  showRecommendation: boolean;
  allowPdfSharing: boolean;

  constructor(payload: any) {
    this.allowSeeDetails = parseInt(payload.allow_see_details, 10) === 1;
    this.allowSkipQuestions = parseInt(payload.allow_skip_questions, 10) === 1;
    this.resultValue = parseInt(payload.result_value, 10);

    // при переписывании тестов раскомментить
    /*   switch (parseInt(payload.result_value, 10)) {
      case 1: this.resultValue = ResultFormat.PERCENT; break;
      case 2: this.resultValue = ResultFormat.POINTS; break;
      default: this.resultValue = ResultFormat.HIDDEN; break;
    } */

    // show_answers
    // 1 - не отображать ответы,
    // 2 - отображать все,
    // 3 - отображать только неправильные

    switch (parseInt(payload.show_answers, 10)) {
      case 1:
        this.showAnswers = ShowAnswersFormat.NOT_SHOW;
        break;
      case 3:
        this.showAnswers = ShowAnswersFormat.SHOW_INCORRECT;
        break;
      default:
        this.showAnswers = ShowAnswersFormat.SHOW_ALL;
    }

    // при переписывании тестов раскомментить
    /*   switch (parseInt(payload.show_answers, 10)) {
      case 0: this.showAnswers = ShowAnswersFormat.NOT_SHOW; break;
      case 1: this.showAnswers = ShowAnswersFormat.SHOW_ALL; break;
      default: this.showAnswers = ShowAnswersFormat.SHOW_INCORRECT; break;
    } */

    this.showResultText = parseInt(payload.show_result_text, 10) === 1;
    this.testBarrier = parseInt(payload.test_barrier, 10);
    this.testFailed = payload.test_failed;
    this.testPassed = payload.test_passed;
    this.testPerfect = payload.test_perfect;
    this.showRecommendation = parseInt(payload.show_recommendations, 10) === 1;
    this.allowPdfSharing = parseInt(payload.allow_pdf_sharing, 10) === 1;
  }
}
