import { each, filter } from 'lodash';
import IQuestionStatistic from '@/entities/common/testing/IQuestionStatistic';
import QuestionType from '@/entities/common/testing/QuestionType';
import IAnswerOption from '@/entities/common/testing/answer-oprions/IAnswerOption';

export default abstract class BaseQuestionStatistic implements IQuestionStatistic {
  id: number;
  abstract type: QuestionType;

  name: string = '';
  scores: number = 0;
  comment: string = '';
  recommendation: string = '';
  openQuestionAnswer: string = '';
  userAnswers: IAnswerOption[] = [];

  isSuccess: boolean = false;
  isFailed: boolean = false;
  isChecking: boolean = false;
  notAnswer: boolean = false;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
  }

  getQuestionForDetails(): any {
    const userAnswersIds: number[] = [];
    each(this.userAnswers, answer => {
      if (answer.isChecked) {
        userAnswersIds.push(answer.answerId);
      }
    });

    return {
      id: this.id,
      type: this.type,
      title: this.name,
      isMultipleAnswers: filter(this.userAnswers, answer => answer.isCorrect).length > 1,
      options: this.userAnswers,
      userAnswers: userAnswersIds,
      isAnswered:
        filter(this.userAnswers, answer => answer.isChecked).length > 0 ||
        !!this.openQuestionAnswer,
      isCorrect: this.isSuccess,
      isIncorrect: this.isFailed,
      recommendation: this.recommendation,
      comment: this.comment,
      hasOpenQuestion: this.type === QuestionType.OPEN_QUESTION,
      openQuestionAnswer: this.openQuestionAnswer,
      userScores: this.scores,
    };
  }
}
