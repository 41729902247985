import find from 'lodash/find';
import MaterialStatus from '@/entities/common/testing/MaterialStatus';
// @ts-ignore
import dateProvider, { getCurrentTimestamp } from '@/providers/js/dateProvider';
import each from 'lodash/each';
import InteractionStatistic from '@/entities/modules/learning-programs/video/InteractionStatistic';
import IStatistic from '@/entities/modules/learning-programs/IStatistic';

export default class MaterialVideoStatistic implements IStatistic {
  materialId: number;
  updatedAt?: Date;
  percent: number;
  status: string;
  points: number;
  startTime: number;
  endTime: number;
  duration: number;
  interactions: InteractionStatistic[] = [];
  updatedAtTimestamp: number;

  constructor(payload: any) {
    this.materialId = parseInt(payload.id, 10);
    this.percent = payload.percent ? parseInt(payload.percent, 10) : 0;
    this.status = payload.status;
    this.updatedAt = dateProvider(payload.updated_at);
    this.points = parseInt(payload.points, 10);
    this.startTime = parseInt(payload.start_time, 10);
    this.endTime = parseInt(payload.end_time, 10);
    this.duration = parseInt(payload.duration, 10);

    each(payload.interactions, interactionStatisticItem => {
      this.interactions.push(new InteractionStatistic(interactionStatisticItem));
    });

    this.updatedAtTimestamp = payload.updated_at;
  }

  /**
   * Вернет статус материала
   */
  getMaterialStatus(): MaterialStatus {
    if (this.status === 'in_progress') {
      return MaterialStatus.IN_PROGRESS;
    }

    if (this.status === 'completed') {
      return MaterialStatus.PASSED;
    }

    return MaterialStatus.ASSIGNED;
  }

  /**
   * Вернет статистику интерактивной вставки
   * @param id
   */
  getInteractionStatisticById(id: number): InteractionStatistic | undefined {
    return find(this.interactions, o => o.id === id);
  }

  getPoints(): number {
    return this.points;
  }

  getPercent(): number {
    return this.percent;
  }

  /**
   * Может быть ситуация, когда время просмотра видео === 0 секунде
   * В этом случае мы должны сохранять 1 секунду просмотра
   */
  getDuration(): number {
    return this.duration < 1 ? 1 : this.duration;
  }

  /**
   * Обновит время первого взаимодействия с видео
   */
  updateStartTimeIfWeNeeded(): void {
    if (this.startTime === 0) {
      // чтобы никогда не было ошибки "Старт тайм должен быть меньше енд тайм"
      this.startTime = getCurrentTimestamp() - 1;
    }
  }

  /**
   * Обновит время последнего взаимодействия с видео
   */
  updateEndTime(): void {
    this.endTime = getCurrentTimestamp();
  }
}
