import { Module } from 'vuex';
import { RootState } from '../../types';
import { PersonalDataState } from './types';
import { actions } from './actions';

export const state: PersonalDataState = {};

const namespaced: boolean = true;

export const personalData: Module<PersonalDataState, RootState> = {
  namespaced,
  state,
  actions,
};
