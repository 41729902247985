import { GetterTree } from 'vuex';
// eslint-disable-next-line import/no-cycle
import { RatingState } from '@/store/modules/rating/types';
import { RootState } from '@/store/types';
import UserInfo from '@/entities/modules/rating/UserInfo';
import Category from '@/entities/common/Category';
import OffsetPagination from '@/entities/common/OffsetPagination';
import User from '@/entities/modules/rating/User';
// eslint-disable-next-line import/no-cycle
import Badge from '@/entities/common/Badge';
import MaterialResultSection from '@/entities/modules/rating/MaterialResultSection';
import Rating from '@/entities/modules/rating/Rating';
import RatingKpi from '@/entities/modules/rating/RatingKpi';

export const getters: GetterTree<RatingState, RootState> = {
  usersList(state): UserInfo[] {
    return state.usersList;
  },

  rating(state): Rating | null {
    return state.rating;
  },

  myBadgesCategories(state): Category[] {
    return state.myBadgesCategories;
  },

  usersMeta(state): OffsetPagination | null {
    return state.usersMeta;
  },

  currentUser(state): User | null {
    return state.currentUser;
  },

  badges(state): Badge[] {
    return state.badges;
  },

  myBadges(state): Badge[] {
    return state.myBadges;
  },

  materialResultSections(state): MaterialResultSection[] {
    return state.materialResultSections;
  },

  badgesCategory(state): Category[] {
    return state.badgesCategory;
  },

  kpi(state): RatingKpi[] {
    return state.kpi;
  },
};
