import axios from 'axios';
import { each } from 'lodash';
import UserComment, { createUserCommentFromFeed } from '@/entities/modules/messages/UserComment';
import MessagePost from '@/entities/modules/messages/MessagePost';
import FeedPost from '@/entities/modules/messages/FeedPost';
import Pagination from '@/entities/common/Pagination';

export default {
  async getStartMessagesPage() {
    const payload = await axios.get('/messages/index');

    const posts: MessagePost[] = [];
    each(payload.data.success.message_list.messages, message =>
      posts.push(new MessagePost(message)),
    );

    return {
      posts,
      postPagination: new Pagination(payload.data.success.message_list.meta.pagination),
      tags: payload.data.success.tag_list.tags,
      tagsPagination: new Pagination(payload.data.success.tag_list.meta.pagination),
    };
  },

  async loadMessagesTags(page: number = 1) {
    const payload = await axios.get(`/messages/tags?page=${page}`);

    return {
      tags: payload.data.success.tags,
      pagination: new Pagination(payload.data.success.meta.pagination),
    };
  },

  async getMessages(page: number = 0, scope: string = 'all', tagId: number = 0) {
    const params = scope === 'block' ? 'scope=block' : `page=${page}&scope=${scope}`;

    const response = await axios.post(`/messages?${params}`, {
      filters: {
        tags: tagId ? [tagId] : [],
      },
    });

    const posts: MessagePost[] = [];
    each(response.data.success.messages, message => posts.push(new MessagePost(message)));

    return {
      posts,
      pagination: new Pagination(response.data.success.meta.pagination),
    };
  },

  async getMessageNews(id: number) {
    const response = await axios.get(`/messages/${id}`);

    return new MessagePost(response.data.success.message);
  },

  async putMessageLike(id: number) {
    const response = await axios.post(`/messages/${id}/like`);

    return new MessagePost(response.data.success.message);
  },

  async removeMessageLike(id: number) {
    const response = await axios.post(`/messages/${id}/unlike`);

    return new MessagePost(response.data.success.message);
  },

  async clearMessagesIsNew(ids: number[]) {
    await axios.post('/messages/clear-is-new', { messages: ids });
  },

  async getFeed(page: number, perPage: number) {
    const response = await axios.get(`/news/?page=${page}&per_page=${perPage}`);

    const news: FeedPost[] = [];
    each(response.data.success.news, newsItem => news.push(new FeedPost(newsItem)));

    const meta = new Pagination(response.data.success.meta);

    return {
      news,
      meta,
    };
  },

  async clearFeedIsNew(ids: number[]) {
    await axios.post('/news/not_new', ids);
  },

  sendFeedback(subject: any, body: any, info: any) {
    return axios.post('/feedback', {
      subject,
      body,
      info,
    });
  },

  async toggleFeedLike(id: number) {
    await axios.post('/news/like', [id]);
  },

  async answer(id: number, itemId: number) {
    const response = await axios.post(`/news/item/${id}/survey`, [itemId]);

    return !!response.data.success;
  },

  async getFeedComments(id: number, page: number | null, perPage: number | null) {
    const response = await axios.get(
      `/news/item/${id}/comments?${page !== null ? `&page=${page}` : ''}${
        perPage !== null ? `&per_page=${perPage}` : ''
      }`,
    );

    const meta = new Pagination(response.data.success.meta);

    const comments: UserComment[] = [];
    each(response.data.success.comments, comment =>
      comments.push(createUserCommentFromFeed(comment)),
    );

    return { comments, meta };
  },

  async sendFeedComment(id: number, message: string) {
    await axios.post(`/news/item/${id}/comments`, { message });
  },

  async uploadImage(formData: any) {
    const response = await axios.post('/news/images', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        globalErrorHandler: false,
      },
    });

    return response.data.success;
  },

  async uploadFile(formData: any) {
    const response = await axios.post('/news/files', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data.success;
  },

  async createFeedPost(body: any) {
    await axios.post('/news/', body);
  },

  async getFeedPostById(id: number) {
    const response = await axios.get(`/news/item/${id}`);

    return new FeedPost(response.data.success);
  },

  async editFeedPostById(id: number, body: any) {
    const response = await axios.post(`/news/item/${id}`, body);

    return new FeedPost(response.data.success);
  },

  async deleteFeedById(id: number) {
    await axios.get(`/news/item/${id}/remove`);
  },

  async unblockMessage(id: number) {
    await axios.post(`/messages/${id}/unblock`);
  },
};
