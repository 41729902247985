/**
 * Вернет успешный промис с данными
 * @param payload
 */
export function resolver<T>(payload: T): Promise<T> {
  return new Promise(resolve => {
    resolve(payload);
  });
}

/**
 * Вернет отклоненный промис с данными
 * @param payload
 */
export function rejector<T>(payload: T): Promise<T> {
  return new Promise((resolve, reject) => {
    reject(payload);
  });
}
