// eslint-disable-next-line no-shadow
enum UserFilterType {
  TEAM_MATERIALS = 'team_materials',
  TEAM_MATERIALS_LEARNING_MATERIALS = 'team_materials_learning_materials',
  TEAM_MATERIALS_INTERVIEWS = 'team_materials_interviews',
  TEAM_MATERIALS_EVENTS = 'team_materials_events',
  TEAM_MATERIALS_TESTS = 'team_materials_tests',
  TEAM_USER_MATERIALS = 'team_user_materials',
  TEAM_REPORT_TESTS_AVERAGE_PASSING = 'team_report_tests_average_passing',
  TEAM_REPORT_MATERIALS_PASSING = 'team_report_materials_passing',
  TEAM_REPORT_USER_ACTIVITY = 'team_report_user_activity',
  TEAM_USER_TASKS = 'team_materials_tasks',
}

export default UserFilterType;
