<template>
  <i>
    <LearningProgramSvg v-if="moduleCode === 'learning-materials'" />

    <DashboardSvg v-if="moduleCode === 'dashboard'" />

    <EvaluationsSvg v-if="moduleCode === 'evaluations' || moduleCode === 'competency'" />

    <EventsSvg v-if="moduleCode === 'events'" />

    <GiftStoreSvg v-if="moduleCode === 'gift-store'" />

    <InformationSvg v-if="moduleCode === 'information'" />

    <MessagesSvg v-if="moduleCode === 'messages'" />

    <MyResultsSvg v-if="moduleCode === 'my-results'" />

    <MyTeamSvg v-if="moduleCode === 'my-team'" />

    <RatingSvg v-if="moduleCode === 'rating'" />

    <VideoSvg v-if="moduleCode === 'video'" />

    <TasksSvg v-if="moduleCode === 'tasks'" />
  </i>
</template>

<script>
import LearningProgramSvg from '@/components/svg/icons/modules/learning-programs.vue';
import DashboardSvg from '@/components/svg/icons/modules/dashboard.vue';
import EvaluationsSvg from '@/components/svg/icons/modules/evaluations.vue';
import EventsSvg from '@/components/svg/icons/modules/events.vue';
import GiftStoreSvg from '@/components/svg/icons/modules/gift-store.vue';
import InformationSvg from '@/components/svg/icons/modules/information.vue';
import MessagesSvg from '@/components/svg/icons/modules/messages.vue';
import MyResultsSvg from '@/components/svg/icons/modules/my-results.vue';
import MyTeamSvg from '@/components/svg/icons/modules/my-team.vue';
import RatingSvg from '@/components/svg/icons/modules/rating.vue';
import VideoSvg from '@/components/svg/icons/modules/video.vue';
import TasksSvg from '@/components/svg/icons/modules/tasks.vue';

export default {
  name: 'module-icon',

  components: {
    LearningProgramSvg,
    DashboardSvg,
    EvaluationsSvg,
    EventsSvg,
    GiftStoreSvg,
    InformationSvg,
    MessagesSvg,
    MyResultsSvg,
    MyTeamSvg,
    RatingSvg,
    VideoSvg,
    TasksSvg,
  },

  props: {
    moduleCode: {
      type: String,
      default: null,
    },
  },
};
</script>
