<template>
  <svg
    :class="{
      gray: color === 'gray',
      white: color === 'white',
    }"
    width="10"
    height="14"
    viewBox="0 0 10 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.32854 13.3995C7.71906 13.79 8.35223 13.79 8.74275 13.3995C9.13328 13.009 9.13328 12.3758 8.74275 11.9853L3.793 7.03552L8.74275 2.08577C9.13328 1.69524 9.13328 1.06208 8.74275 0.671555C8.35223 0.281031 7.71906 0.281031 7.32854 0.671555L0.964576 7.03552L7.32854 13.3995Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'back-arrow',

  props: {
    color: {
      type: String,
      default: 'black',
    },
  },
};
</script>

<style scoped>
svg path {
  fill: var(--surfaceHighEmphasis);
  transition-duration: 0.15s;
}

.gray path {
  fill: var(--surfaceDisabledEmphasis);
}

svg:hover path {
  fill: var(--primary);
  transition-duration: 0.15s;
}

.white path {
  fill: var(--surfaceWhite);
}

.white:hover path {
  fill: var(--surfaceWhite);
}
</style>
