import LearningItem from '@/entities/modules/learning-items/LearningItem';
import LearningItemStatistic, {
  LearningItemStatisticScheme,
} from '@/entities/modules/learning-items/LearningItemStatistic';
import NodeItem from '@/entities/modules/learning-track/NodeItem';
import { LearningItemTypes } from '@/entities/modules/learning-items/LearningItemType';

function getStatistic(track: any): LearningItemStatisticScheme {
  return {
    learning_item_id: track.id,
    learning_item_entity_type: LearningItemTypes.TRACK,
    learning_item_entity_id: track.id,
    points: track.points,
    percent: track.track_stat_percent,
    start_time: track.track_stat_start_time,
    end_time: track.track_stat_end_time,
    is_new: 0,
    is_checked: 0,
    user_has_feedback: 0,
    certificate: null,
    status: track.track_stat_status,
    updated_at: track.updated_at,
  };
}

export async function createDetailsForLearningTrack(payload: any) {
  const { learning_track: learningTrack, nodes: learningTrackNodes } = payload;

  learningTrack.entity_type = LearningItemTypes.TRACK;
  learningTrack.entity_id = learningTrack.id;

  const track = new LearningItem(learningTrack);

  const learningTrackStat = getStatistic(learningTrack);

  track.setStatistic(new LearningItemStatistic(learningTrackStat));

  const nodes = learningTrackNodes.map((item: any) => {
    const node = new NodeItem(item);

    if (item.statistic) {
      node.setStatistic(new LearningItemStatistic(item.statistic));
    }

    if (item.deadline_for_passing_at || item.deadline_for_passing_notice_for_time) {
      node.setDeadline(item.deadline_for_passing_at, item.deadline_for_passing_notice_for_time);
    }

    if (item.statistic.max_points) {
      node.setMaxPoints(item.statistic.max_points);
    }

    return node;
  });

  return { track, nodes };
}
