<template>
  <div
    class="app-material-card-footer"
    :class="{ 'app-material-card-footer--outer': isOuterFooter }"
  >
    <div class="app-material-card-footer__wrapper">
      <div
        v-if="showProgressBar"
        class="app-material-card-footer__progress"
      >
        <div
          class="app-material-card-footer__progress-line"
          :style="`width:${widget.percent}%`"
        ></div>
      </div>
      <div
        class="app-material-card-footer__title"
        :class="{ 'app-material-card-footer__title--large': countTitleLines > 1 }"
      >
        {{ widget.name }}
      </div>

      <div
        v-if="showPointsArea"
        class="app-material-card-footer__info"
      >
        <AppMarkdown
          v-if="info"
          tag="span"
          class="app-material-card-footer__price__info--text"
          :source="info"
        />
        <span
          v-if="widget.price"
          class="app-material-card-footer__price"
        >
          {{ price }}
        </span>
        <app-motivation-tooltip
          v-if="hasAnyBonusOrPenaltyData"
          badge-style="outline"
          badge-color="dark-gray"
          :tooltip-text="$t(tooltipText)"
          :status="getIconStatus"
        >
          <template #left-icon>
            <zap-solid-icon v-if="showBonusIcon" />
            <zap-off-solid-icon v-else />
          </template>
          {{ bonusOrPenaltyPoints }}
        </app-motivation-tooltip>
      </div>

      <div
        v-if="hasStatusBar"
        class="app-material-card-footer__status-bar"
      >
        <div
          v-if="widget.type !== 'events' && statusLabel && !widget.diagram"
          :class="[`app-tag--${statusStyle}`]"
          class="app-material-card-footer__material-tag app-tag app-tag--medium"
        >
          {{ statusLabel }}
        </div>
        <AppDatePassing
          v-if="isShowDeadlineIcon"
          :is-show-deadline-text="isShowDeadlineText"
          :deadline="deadline"
          :status="status"
          :tooltip="tooltip"
        />
        <FlagIcon v-if="widget.isRequired" />
        <CupIcon v-if="widget.isRating" />
        <FeedbackIcon
          v-if="widget.isFeedbackShowed"
          :rating="widget.feedbackAverageMark"
          :user-has-feedback="widget.userHasFeedback"
          :is-few-rating="widget.isFewRating"
          :feedback-tooltip="feedbackTooltip"
        />

        <div
          v-if="widget.type === 'events' && !widget.diagram"
          :class="[`app-tag--${statusStyle}`]"
          class="app-material-card-footer__event-tag app-tag app-tag--medium"
        >
          {{ $t(widget.eventStatus.myTeamLokaliseKey) }}
        </div>

        <div
          v-if="widget.diagram"
          class="material-card-footer__donut"
        >
          <DonutDiagram
            :segments="widget.diagram.entities"
            :total="widget.diagram.amountOfMaterialsForChart"
          />

          <div class="app-material-card-footer__description">
            {{ diagramDescription }}
          </div>
        </div>

        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import each from 'lodash/each';
import isArray from 'lodash/isArray';
import CupIcon from '@/components/helpers/tooltips/icons/cup.vue';
import AppDatePassing from '@/components/helpers/date-passing.vue';
import FlagIcon from '@/components/helpers/tooltips/icons/flag.vue';
import FeedbackIcon from '@/components/helpers/tooltips/icons/feedback.vue';
import DonutDiagram from '@/components/helpers/charts/donut.vue';
import AppMarkdown from '@/components/helpers/markdown.vue';
import MaterialStatus from '@/entities/common/testing/MaterialStatus';
import GenderSpecific from '@/entities/common/GenderSpecific';
import {
  getDatePassing,
  getDeadlineStatusTeam,
  getDeadlineStatusMaterial,
  getTooltipText,
} from '@/providers/deedlineProvider';
import CorrectName from '@/entities/common/CorrectName';
import CustomRoute from '@/entities/common/CustomRoute';
import AppMotivationTooltip from '@/components/helpers/motivation/motivation-tooltip.vue';
import ZapSolidIcon from '@/components/svg/icons/zap-solid.vue';
import ZapOffSolidIcon from '@/components/svg/icons/zap-off-solid.vue';

export default {
  name: 'material-card-footer',

  components: {
    ZapOffSolidIcon,
    ZapSolidIcon,
    AppMotivationTooltip,
    CupIcon,
    AppDatePassing,
    FlagIcon,
    FeedbackIcon,
    DonutDiagram,
    AppMarkdown,
  },

  props: {
    widget: {
      type: Object,
      required: true,
    },

    isOuterFooter: {
      type: Boolean,
      default: false,
    },

    isShowDeadlineText: {
      type: Boolean,
      default: true,
    },

    pointsDisplayStatus: {
      type: String,
      default: '',
    },
  },

  computed: {
    feedbackTooltip() {
      if (this.widget.isFewRating) {
        return this.$t('common_review_few_ratings_text');
      }

      if (this.widget.type === CorrectName.LEARNING_PROGRAMS) {
        return this.$t('lj_journey_rating_lowcase_text');
      }

      if (this.widget.type === CorrectName.LEARNING_TRACK) {
        return this.$t('lm_trajectory_lowcase_text');
      }

      return '';
    },

    showBonusIcon() {
      if ((this.hasBonus || this.hasMaxBonus) && !this.hasPenalty) {
        return true;
      }

      if (this.hasBonus && this.hasPenalty) {
        return true;
      }

      return false;
    },

    showPointsArea() {
      return (
        (!this.widget.diagram && (this.info || this.widget.price)) ||
        this.hasAnyBonusOrPenaltyData ||
        this.hasMaxProgramPoints
      );
    },

    hasBonus() {
      return !!this.widget.bonusPoints;
    },

    hasPenalty() {
      return !!this.widget.penaltyPoints;
    },

    hasProgramPoints() {
      return !!this.widget.programPoints;
    },

    hasMaxBonus() {
      return !!this.widget.maxBonusPoints;
    },

    hasMaxPenalty() {
      return !!this.widget.maxBonusPoints;
    },

    isBonusOrPenaltyReceived() {
      return this.hasBonus || this.hasPenalty;
    },

    hasMaxProgramPoints() {
      return !!this.widget.maxProgramPoints;
    },

    hasAnyBonusOrPenaltyData() {
      if (!this.widget.isRating) {
        return false;
      }

      if (this.isStatusFailed) {
        return !!this.hasPenalty;
      }

      return [this.hasMaxBonus, this.hasMaxPenalty, this.hasBonus, this.hasPenalty].some(item =>
        Boolean(item),
      );
    },

    getIconStatus() {
      if (!this.isBonusOrPenaltyReceived) {
        return 'assigned';
      }

      return this.hasBonus ? 'success' : 'failed';
    },

    isStatusFailed() {
      return this.pointsDisplayStatus === 'failed';
    },

    bonusOrPenaltyPoints() {
      if (this.isBonusOrPenaltyReceived) {
        return this.widget.bonusPoints && !this.isStatusFailed
          ? `+${this.widget.bonusPoints}`
          : `-${this.widget.penaltyPoints}`;
      }

      return this.widget.maxBonusPoints
        ? `+${this.widget.maxBonusPoints}`
        : `-${this.widget.maxPenaltyPoints}`;
    },

    tooltipText() {
      if (!this.isBonusOrPenaltyReceived) {
        return this.hasMaxBonus
          ? 'learn_pass_on_time_available_bonus_points_tooltip_text'
          : 'learn_not_pass_on_time_available_fine_points_tooltip_text';
      }

      return this.hasBonus
        ? 'learn_pass_on_time_received_bonus_points_tooltip_text'
        : 'learn_not_pass_on_time_received_fine_points_tooltip_text';
    },

    isShowDeadlineIcon() {
      return this.widget.passingDeadline && this.$route.name !== CustomRoute.TO_MY_TEAM_MATERIALS;
    },

    isNew() {
      return typeof this.widget.getStatus === 'function'
        ? this.widget.getStatus() === MaterialStatus.NEW
        : this.widget.isNew;
    },

    isAssigned() {
      return typeof this.widget.getStatus === 'function'
        ? this.widget.getStatus() === MaterialStatus.ASSIGNED
        : this.widget.isAssigned;
    },

    isInProgress() {
      return typeof this.widget.getStatus === 'function'
        ? this.widget.getStatus() === MaterialStatus.IN_PROGRESS
        : this.widget.isInProgress;
    },

    isPassed() {
      return typeof this.widget.getStatus === 'function'
        ? this.widget.getStatus() === MaterialStatus.PASSED
        : this.widget.isPassed;
    },

    isFailed() {
      return typeof this.widget.getStatus === 'function'
        ? this.widget.getStatus() === MaterialStatus.FAILED
        : this.widget.isFailed;
    },

    isChecking() {
      return typeof this.widget.getStatus === 'function'
        ? this.widget.getStatus() === MaterialStatus.CHECKING
        : this.widget.isChecking;
    },

    isChecked() {
      return !!this.widget.isChecked;
    },

    statusStyle() {
      if (this.widget.eventStatus) {
        switch (this.widget.eventStatus.code) {
          case 'not-filed':
            return 'gray';
          case 'filed':
            return 'gray';
          case 'waiting-for-confirmation':
            return 'dark-gray';
          case 'approved':
            return 'dark-gray';
          case 'missed':
            return 'error';
          case 'rejected':
            return 'gray';
          case 'participated':
            return 'success';
          default:
            break;
        }
      }

      if (this.isNew || this.isChecked) {
        return 'orange';
      }

      if (this.isPassed) {
        return 'success';
      }

      if (this.isInProgress || this.isAssigned) {
        return 'gray';
      }

      if (this.isChecking) {
        return 'dark-gray';
      }

      if (this.isFailed) {
        return 'error';
      }

      return '';
    },

    statusLabel() {
      if (this.isNew) {
        switch (this.widget.genderSpecific) {
          case GenderSpecific.FEMININE:
            return this.$t('common_new_fem_gen_status_text');
          default:
            return this.$t('common_new_status_text');
        }
      }

      if (this.isChecked) {
        return this.$t('common_checked_status_text');
      }

      if (this.isChecking) {
        return this.$t('common_under_check_status_text');
      }

      if (this.isPassed) {
        switch (this.widget.genderSpecific) {
          case GenderSpecific.FEMININE:
            return this.$t('common_passed_fem_gen_status_text');
          case GenderSpecific.NEUTER:
            return this.$t('events_status_complete_text');
          default:
            return this.$t('common_completed_status_text');
        }
      }

      if (this.isFailed) {
        switch (this.widget.genderSpecific) {
          case GenderSpecific.FEMININE:
            return this.$t('common_failed_fem_gen_status_text');
          default:
            return this.$t('common_failed_status_text');
        }
      }

      if (this.isAssigned) {
        switch (this.widget.genderSpecific) {
          case GenderSpecific.FEMININE:
            return this.$t('common_assigned_fem_gen_status_text');
          case GenderSpecific.NEUTER:
            return this.$t('common_not_started_text');
          default:
            return this.$t('common_assigned_lowcase_text');
        }
      }

      if (this.isInProgress) {
        return this.$t('common_in_progress_status_text');
      }

      if (this.widget.isSkipped) {
        return this.$t('events_status_missed_text');
      }

      return '';
    },

    hasStatusBar() {
      return (
        this.statusLabel ||
        this.widget.isRequired ||
        this.widget.isRating ||
        this.isChecked ||
        this.widget.diagram ||
        this.widget.type === 'video' ||
        this.widget.type === 'gifts'
      );
    },

    info() {
      const parts =
        typeof this.widget.getParts === 'function' ? this.widget.getParts() : this.widget.parts;

      if (!parts) {
        return '';
      }

      const p = [];
      each(parts, part => {
        if (isArray(part)) {
          p.push(this.$tc(...part));
        } else {
          p.push(part);
        }
      });

      return p.join(' · ');
    },

    diagramDescription() {
      if (!this.widget.diagram) {
        return '';
      }

      const parts =
        typeof this.widget.getParts === 'function' ? this.widget.getParts() : this.widget.parts;

      const p = [];
      each(parts, part => {
        if (isArray(part)) {
          p.push(this.$tc(...part));
        } else {
          p.push(part);
        }
      });

      return p.join(' ');
    },

    showProgressBar() {
      return this.isOuterFooter && this.isInProgress && this.widget.percent !== undefined;
    },

    countTitleLines() {
      if (
        (this.info && this.isOuterFooter && !this.widget.diagram) ||
        this.widget.type === 'gifts'
      ) {
        return 1;
      }

      if ((!this.info || this.widget.diagram) && this.isOuterFooter) {
        return 2;
      }

      return 3;
    },

    price() {
      return this.$tc('common_point_number_text', this.widget.price, {
        0: this.widget.price.toLocaleString(),
      });
    },

    deadline() {
      return getDatePassing(this.widget.passingDeadline);
    },

    status() {
      if (this.widget.diagram) {
        return getDeadlineStatusTeam(
          this.widget.diagram,
          this.widget.passingDeadline,
          this.widget.passingNoticeDeadline,
          this.widget.hasNotPassedUsers,
        );
      }

      return getDeadlineStatusMaterial(
        this.widget.passingDeadline,
        this.widget.passingNoticeDeadline,
        this.widget.endTime,
        this.widget.isFailed,
        this.widget.isPassed,
      );
    },

    tooltip() {
      return getTooltipText(this.widget.passingDeadline);
    },
  },
};
</script>
