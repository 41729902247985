import home from '@/views/home/index.vue';
import CorrectName from '@/entities/common/CorrectName';
import CustomRoute from '@/entities/common/CustomRoute';
import { NavigationGuardNext, Route } from 'vue-router';
import store from '@/store';
import firebase from 'firebase/app';
import { sendEvent } from '@/providers/analyticsProvider';

export default [
  {
    path: '/',
    name: CustomRoute.TO_DASHBOARD_HOME,
    meta: {
      title: 'dashboard_screen_title_text',
      moduleCode: CorrectName.DASHBOARD,
      eventOnEnter: 'dashboard_section_open',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext) => {
      if (sessionStorage.getItem('need_request_blocked_messages')) {
        const blockedMessages = await store.dispatch('messages/getBlockedMessages', {
          scope: 'block',
        });

        if (blockedMessages.length) {
          await store.dispatch('auth/doBlockMessages');
        }
        sessionStorage.removeItem('need_request_blocked_messages');
      }

      next();
    },
    component: home,
  },
  {
    path: '/profile/info',
    name: CustomRoute.TO_DASHBOARD_PROFILE_INFO,
    meta: {
      title: 'profile_screen_title_text',
      moduleCode: CorrectName.DASHBOARD,
      eventOnEnter: 'dashboard_profile_tab_btn',
    },
    component: () =>
      import(/* webpackChunkName: "dashboard-info" */ '../../views/home/profile-info.vue'),
  },
  {
    path: '/profile/settings',
    name: CustomRoute.TO_DASHBOARD_PROFILE_SETTINGS,
    meta: {
      title: 'profile_screen_title_text',
      moduleCode: CorrectName.DASHBOARD,
      eventOnEnter: 'dashboard_profile_tab_btn',
    },
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '@/views/home/profile-settings.vue'),
  },
  {
    path: '/profile/settings/language/:languageType',
    name: CustomRoute.TO_DASHBOARD_PROFILE_SETTINGS_LANGUAGES,
    meta: {
      title: 'profile_screen_title_text',
      moduleCode: CorrectName.DASHBOARD,
      eventOnEnter: 'dashboard_profile_tab_btn',
    },
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '@/views/home/profile-settings-languages.vue'),
  },
  {
    path: '/profile/settings',
    name: CustomRoute.TO_DASHBOARD_PROFILE_SETTINGS,
    meta: {
      title: 'profile_screen_title_text',
      moduleCode: CorrectName.DASHBOARD,
      eventOnEnter: 'dashboard_profile_tab_btn',
    },
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '@/views/home/profile-settings.vue'),
  },
  {
    path: '/profile/settings/language/:languageType',
    name: CustomRoute.TO_DASHBOARD_PROFILE_SETTINGS_LANGUAGES,
    meta: {
      title: 'profile_screen_title_text',
      moduleCode: CorrectName.DASHBOARD,
      eventOnEnter: 'dashboard_profile_tab_btn',
    },
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '@/views/home/profile-settings-languages.vue'),
  },
  {
    path: '/profile/favorites',
    name: CustomRoute.TO_DASHBOARD_PROFILE_FAVORITES,
    meta: {
      title: 'profile_screen_title_text',
      moduleCode: CorrectName.DASHBOARD,
      eventOnEnter: 'dashboard_profile_tab_btn',
      from: { 'dashboard-widget': 'dashboard_favorite_name_btn' },
    },
    component: () =>
      import(/* webpackChunkName: "dashboard-favorites" */ '@/views/home/favorites.vue'),
  },
  {
    path: '/profile/materials-of-deadline',
    name: CustomRoute.TO_DASHBOARD_PROFILE_MATERIALS_OF_DEADLINE,
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext) => {
      sendEvent(firebase.analytics(), 'dashboard_date_of_passage_name_btn');
      next();
    },
    meta: {
      title: 'profile_screen_title_text',
      moduleCode: CorrectName.DASHBOARD,
      eventOnEnter: 'dashboard_profile_tab_btn',
    },
    component: () =>
      import(
        /* webpackChunkName: "dashboard-materials" */ '@/views/home/materials-of-deadline.vue'
      ),
  },
  {
    path: '/profile/password/edit',
    name: CustomRoute.TO_DASHBOARD_PROFILE_PASSWORD_EDIT,
    meta: {
      title: 'profile_screen_title_text',
      moduleCode: CorrectName.DASHBOARD,
      showBlockedMessages: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "profile-password-edit" */ '@/views/home/profile-password-edit.vue'
      ),
  },
  {
    path: '/profile/questionnaire',
    name: CustomRoute.TO_DASHBOARD_PROFILE_QUESTIONNAIRE,
    meta: {
      title: 'profile_personal_data_status_fill_in_form_title_text',
      moduleCode: CorrectName.DASHBOARD,
      eventOnEnter: 'personaldata_form_open',
      showBlockedMessages: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "profile-questionnaire" */ '@/views/home/profile-questionnaire.vue'
      ),
  },
];
