import { each, filter, sortBy } from 'lodash';
import QuestionnaireQuestion from '@/entities/modules/profile/QuestionnaireQuestion';

export default class QuestionnaireSection {
  id: number;
  name: string;
  order: number;
  questions: QuestionnaireQuestion[];
  isOpened: boolean = false;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.name = payload.name;
    this.order = parseInt(payload.order, 10);
    this.questions = [];
    each(payload.questions, question => {
      this.questions.push(new QuestionnaireQuestion(question));
    });
    this.questions = sortBy(this.questions, ['order']);
  }

  countOfRequiredFields(): number {
    return filter(this.questions, o => o.isRequired).length;
  }

  countOfFilledFields(): number {
    return filter(this.questions, o => o.isFilled()).length;
  }

  countOfValidFilledFields(): number {
    return filter(this.questions, o => o.isValidFilled()).length;
  }
}
