// @ts-ignore
import { createMaterialCardWidget } from '@/entities/common/MaterialCardWidget';
import MaterialStatus from '@/entities/common/testing/MaterialStatus';
import CustomRoute from '@/entities/common/CustomRoute';

export class TaskResult {
  id: number;
  name: string;
  points: number;
  maxPoints: number;
  status: string;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.name = payload.name;
    this.points = parseInt(payload.points, 10);
    this.maxPoints = parseInt(payload.max_points, 10);
    this.status = payload.status;
  }

  getStatus() {
    switch (this.status) {
      case 'completed':
        return MaterialStatus.PASSED;
      case 'fail':
        return MaterialStatus.FAILED;
      case 'checking':
        return MaterialStatus.CHECKING;
      case 'active':
        return MaterialStatus.ASSIGNED;
      default:
        return;
    }
  }

  getWidget() {
    const isAssigned = this.getStatus() === MaterialStatus.ASSIGNED;

    const isPassed = this.getStatus() === MaterialStatus.PASSED;

    const isFailed = this.getStatus() === MaterialStatus.FAILED;

    const isChecking = this.getStatus() === MaterialStatus.CHECKING;

    const parts = [];

    if (isPassed) {
      parts.push([
        'common_d_from_d_points_text',
        this.maxPoints,
        { 0: this.points, 1: this.maxPoints },
      ]);
    }

    if (isAssigned) {
      parts.push([
        'learn_earn_d_points_text',
        this.maxPoints,
        { 0: this.maxPoints.toLocaleString() },
      ]);
    }

    if (isFailed) {
      parts.push(['common_d_from_d_points_text', this.maxPoints, { 0: 0, 1: this.maxPoints }]);
    }

    return createMaterialCardWidget({
      name: this.name,
      to: {
        name: CustomRoute.TO_TASKS_MATERIAL,
        params: {
          id: this.id,
        },
        query: { from: 'task-list' },
      },
      type: 'tasks',
      isRating: true,
      parts,
      isPassed,
      isChecking,
      isFailed,
    });
  }
}
