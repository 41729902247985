import AnswerField from '@/entities/modules/tasks/AnswerField';

export default class AnswerFileField extends AnswerField {
  comment: string;
  value: string;
  size: number;
  pathToken?: string;

  constructor(payload: any) {
    super(payload);

    this.comment = payload.cooment;
    this.value = payload.value;
    this.size = parseInt(payload.file_size, 10) || 0;
    this.pathToken = payload.path_token;
  }
}
