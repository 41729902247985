import IStatistic from '@/entities/modules/learning-programs/IStatistic';
import SectionMaterial from '@/entities/modules/learning-programs/SectionMaterial';
import ToggleSectionMaterial from '@/entities/modules/learning-programs/ToggleSectionMaterial';
import MaterialStatus from '@/entities/common/testing/MaterialStatus';
import BaseMaterial from '@/entities/modules/learning-programs/BaseMaterial';
import ImageSource, { createImageSmSource } from '@/entities/common/sources/ImageSource';
import CustomRoute from '@/entities/common/CustomRoute';
// @ts-ignore
import { encrypt } from '@/providers/js/cryptProvider';

export default class MaterialScorm extends BaseMaterial {
  id: number;
  name: string;
  isRating: boolean;
  url: string;
  maxScore: number;
  updatedAtTimestamp: number;
  image?: ImageSource;

  statistic: IStatistic;

  constructor(statistic: IStatistic, payload: any) {
    super();

    this.statistic = statistic;
    this.id = parseInt(payload.id, 10);
    this.name = payload.name;
    this.isRating = parseInt(payload.is_rating, 10) === 1;
    this.url = payload.scorm.url;
    this.maxScore = parseInt(payload.max_points, 10);
    this.updatedAtTimestamp = payload.updated_at;
    this.image = createImageSmSource(payload.image);
  }

  getMaterialType(): string {
    return 'scorm';
  }

  /**
   * Виджет материала для страницы просмотра программы обучения (выводится в секциях)
   * @param sectionMaterial
   */
  getToggleWidget(sectionMaterial: SectionMaterial): ToggleSectionMaterial {
    const status = this.statistic.getMaterialStatus();

    const parts = [];

    if (this.isRating) {
      switch (this.statistic.getMaterialStatus()) {
        case MaterialStatus.IN_PROGRESS:
          parts.push([
            'learn_d_from_d_points_text',
            this.maxScore,
            { 0: this.statistic.getPoints(), 1: this.maxScore.toLocaleString() },
          ]);
          break;

        case MaterialStatus.PASSED:
          parts.push([
            'common_point_number_text',
            this.statistic.getPoints(),
            { 0: this.statistic.getPoints().toLocaleString() },
          ]);
          break;

        case MaterialStatus.ASSIGNED:
          parts.push([
            'learn_earn_d_points_text',
            this.maxScore,
            { 0: this.maxScore.toLocaleString() },
          ]);
          break;

        default:
          break;
      }
    }

    return new ToggleSectionMaterial({
      id: this.id,
      type: this.getMaterialType(),
      routerTo: CustomRoute.TO_LEARNING_PROGRAMS_SCORM,
      routerTrackTo: CustomRoute.TO_LEARNING_TRACK_PROGRAMS_SCORM,
      name: this.name,
      maxScore: this.maxScore,
      image: this.image,

      lokaliseTagLabel: 'learn_course_digital_course_text',
      buttonLabel: null,
      lokaliseButtonLabel:
        status !== MaterialStatus.PASSED
          ? 'learn_course_pass_button'
          : 'common_pass_one_more_time_button',
      isButtonOutlined: status === MaterialStatus.PASSED,

      numberingTypeThrough: this.numberingTypeThrough,
      numberingTypeSections: this.numberingTypeSections,
      numberingTypeHierarchical: this.numberingTypeHierarchical,

      isRating: this.isRating,
      isRequired: sectionMaterial.isRequired,
      isLocked: sectionMaterial.isLocked,

      isPassed: status === MaterialStatus.PASSED,
      isFailed: status === MaterialStatus.FAILED,
      inProgress: status === MaterialStatus.IN_PROGRESS,
      isAssigned: status === MaterialStatus.ASSIGNED,

      parts,
    });
  }

  getSaving(payload: any): any {
    const data = payload.data || payload.materials[0].data;

    return {
      id: this.id,
      type: this.getMaterialType(),
      data: encrypt(JSON.stringify({ s: data })),
    };
  }
}
