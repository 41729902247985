import BaseGroup from '@/entities/common/groups/BaseGroup';
import Pagination from '@/entities/common/Pagination';
import { each } from 'lodash';

export default class FilterGroup extends BaseGroup {
  hasChildren: boolean;
  children: FilterGroup[] = [];
  // @ts-ignore
  route?: FilterGroup;

  constructor(payload: any) {
    super(payload);

    this.hasChildren = parseInt(payload.has_children, 10) === 1;
    this.hasUsers = parseInt(payload.has_users, 10) === 1;
  }
}

export function createFilterGroupsList(payload: any): [Pagination, FilterGroup[], FilterGroup] {
  const pagination = new Pagination(payload.meta.pagination);

  const groups: FilterGroup[] = [];

  const parent = new FilterGroup(payload.root_group);

  each(payload.groups, group => {
    groups.push(new FilterGroup(group));
  });

  return [pagination, groups, parent];
}
