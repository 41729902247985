import Vue from 'vue';
import axios from 'axios';
import moment from 'moment';
// @ts-ignore
import infiniteScroll from 'vue-infinite-scroll';
import router from '@/router/index';
import { i18n } from '@/lang';
import store from '@/store/index';
import App from '@/App.vue';
import firebase from 'firebase/app';
import lokalisePlugin from '@/plugins/lokalisePlugin';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

// @ts-ignore
// eslint-disable-next-line import/no-unresolved

import { beforeRequest, onFailure, onSuccess } from './interceptors';
import { userLocale, userMaterialsLocale } from './locale';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/performance';

require('@/assets/styles/main.scss');
require('vue-custom-scrollbar/dist/vueScrollbar.css');

(async () => {
  // так как у китайского языка несколько диалектов,
  // устанавливаем нужный для работы бибилиотеки moment.js
  let locale = userLocale;

  if (userLocale === 'zh') {
    locale = 'zh-cn';
  }

  if (userLocale === 'hk') {
    locale = 'zh-hk';
  }

  moment.locale(locale);

  // if (window.location.hostname === 'localhost' || window.location.hostname === '192.168.31.245') {
  if (window.location.hostname === 'localhost') {
    axios.defaults.baseURL = process.env.VUE_APP_API_PATH;
  } else {
    axios.defaults.baseURL = `${window.location.protocol}//${process.env.VUE_APP_API_PATH}`;
  }

  axios.defaults.headers.common.Authorization = `Basic ${process.env.VUE_APP_API_AUTHORIZATION_CREDENTIALS}`;

  axios.defaults.headers.common['Accept-Language'] = userMaterialsLocale;
  axios.defaults.headers.common['X-Frame-Options'] = 'SAMEORIGIN';
  axios.defaults.headers.common['X-Content-Type-Options'] = 'nonsniff';
  axios.defaults.headers.common['X-XSS-Protection'] = '1; mode=block';
  axios.defaults.headers.common['Access-Control-Allow-Origin'] = window.location.origin;

  // @ts-ignore
  axios.interceptors.request.use(beforeRequest);
  axios.interceptors.response.use(onSuccess, onFailure);

  await store.dispatch('auth/init');

  Vue.config.productionTip = false;

  Vue.use(infiniteScroll);
  Vue.use(lokalisePlugin);

  const firebaseConfig = {
    apiKey: 'AIzaSyChjVXtYBk7Y6wsfym4CU3K2NLWGFYY-6o',
    authDomain: 'e-queo.firebaseapp.com',
    databaseURL: 'https://e-queo.firebaseio.com',
    projectId: 'e-queo',
    storageBucket: 'e-queo.appspot.com',
    messagingSenderId: '791915872795',
    appId: '1:791915872795:web:49928588de61272f4ceebe',
    measurementId: 'G-YW96XNYT4X',
  };

  firebase.initializeApp(firebaseConfig);
  firebase.performance();
  Vue.prototype.$analytics = firebase.analytics();

  if (router) {
    Sentry.init({
      Vue,
      dsn: process.env.VUE_APP_SENTRY_DSN,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: [process.env.VUE_APP_TRACING_ORIGINS],
        }),
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
      environment: process.env.VUE_APP_SENTRY_ENV,
      enabled: parseInt(process.env.VUE_APP_SENTRY_ENABLED, 10) === 1,
    });
  }

  new Vue({
    router,
    i18n,
    // @ts-ignore
    store,
    render: h => h(App),
  }).$mount('#app');
})();
