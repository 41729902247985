import Pagination from '@/entities/common/Pagination';
import { each } from 'lodash';
import moment from 'moment';
import Task from '@/entities/modules/tasks/Task';
import AnswerField from '@/entities/modules/tasks/AnswerField';
import AnswerMultiplyField from '@/entities/modules/tasks/AnswerMultiplyField';
import AnswerFileField from '@/entities/modules/tasks/AnswerFileField';
import AnswerTextField from '@/entities/modules/tasks/AnswerTextField';
import FieldType from '@/entities/modules/tasks/FieldType';
import AnswerReviewer from '@/entities/modules/tasks/AnswerReviewer';
import User from '@/entities/modules/tasks/User';
import AnswerReviewsComment from '@/entities/modules/tasks/AnswerReviewerComment';

export default class Answer {
  id: number;
  status: string;
  createdAt: number;
  hasComment: boolean;
  hasStatusChanged: boolean;
  comment: string;
  task: Task;
  fields: AnswerField[];
  reviewer: AnswerReviewer;
  reviewDate: number;
  points: number;
  user?: User;
  isCheckRequiredByManager?: boolean;
  isNew: boolean;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.status = payload.status;
    this.createdAt = parseInt(payload.created_at, 10);
    this.comment = payload.comment;
    this.hasComment = parseInt(payload.has_comment, 10) === 1;
    this.hasStatusChanged = parseInt(payload.has_status_changed, 10) === 1;
    this.task = new Task(payload.task);
    this.reviewer = new AnswerReviewer({});
    this.reviewDate = 0;
    this.points = parseInt(payload.answer_points, 10);
    this.isNew = parseInt(payload.is_new, 10) === 1;

    this.isCheckRequiredByManager = Boolean(payload?.is_check_required_by_manager);

    if (payload.user) {
      this.user = new User(payload.user);
    }

    if (payload.reviewer) {
      this.reviewer = new AnswerReviewer(payload.reviewer);
    }

    if (payload.review_date) {
      this.reviewDate = payload.review_date;
    }

    this.fields = [];
    each(payload.fields, field => {
      switch (field.type) {
        case FieldType.MULTIPLY_CHOICE:
          this.fields.push(new AnswerMultiplyField(field));
          break;
        case FieldType.FILE:
        case FieldType.IMAGE:
          this.fields.push(new AnswerFileField(field));
          break;
        default:
          this.fields.push(new AnswerTextField(field));
      }
    });
  }

  getFormattedDate(): string {
    return moment(this.createdAt * 1000).format('MMM D YY');
  }

  getFormattedReviewDate(): string {
    return this.reviewDate ? moment(this.reviewDate * 1000).format('HH:mm, D MMM YYYY') : '';
  }

  setStatus(status: boolean): void {
    this.hasStatusChanged = status;
  }

  getFieldReviewerComments(fieldId: number): Array<AnswerReviewsComment> {
    const field = this.fields.find(item => item.id === fieldId);

    if (!field) {
      return [];
    }

    return field.reviewerComments;
  }
}

export function createAnswersList(payload: any): [Pagination, Answer[]] {
  const pagination = new Pagination(payload.meta.pagination);

  const answers: Answer[] = [];

  each(payload.answers, answerItem => {
    answers.push(new Answer(answerItem));
  });

  return [pagination, answers];
}
