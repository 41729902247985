// eslint-disable-next-line import/no-named-default
import { default as ScormStatistic } from '@/entities/modules/learning-programs/MaterialScormStatistic';

export default class MaterialScormStatistic extends ScormStatistic {
  percent: number;
  status: string;
  points: number;

  constructor(payload: any) {
    // eslint-disable-next-line camelcase
    const { id, updated_at, data, updated_at_timestamp } = payload;

    // eslint-disable-next-line camelcase
    super({ id, updated_at, data, updated_at_timestamp });

    this.percent = payload.percent || 0;
    this.status = payload.status;
    this.points = payload.points;
  }
}
