import IUpdatable from '@/entities/common/testing/updates/IUpdatable';

export default class Level implements IUpdatable {
  id: number;
  name: string;
  order: number;
  updatedAt: number;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.name = payload.name;
    this.order = parseInt(payload.order, 10);
    this.updatedAt = parseInt(payload.updated_at, 10);
  }

  getTimestamp(): number {
    return 0;
  }
}
