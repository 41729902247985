import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { GiftStoreState } from '@/store/modules/gift-store/types';
import giftStoreApi from '@/services/gift-store';
import Gift from '@/entities/modules/gift-store/Gift';
import Cart from '@/entities/modules/gift-store/Cart';

export const actions: ActionTree<GiftStoreState, RootState> = {
  async getGifts({ commit }, { page, sort }) {
    const payload: any = await giftStoreApi.getGifts(page, 15, sort);

    commit('changeGifts', payload.products);
    commit('setMeta', payload.meta);
    commit('setPoints', payload.points);
    commit('changeCurrentPage', page);
  },

  async getCart({ commit }) {
    const payload: Cart = await giftStoreApi.getCart();
    commit('changeCart', payload);
  },

  getTerms() {
    return giftStoreApi.getTerms();
  },

  async getGift(o, id: number): Promise<Gift> {
    return giftStoreApi.getGift(id);
  },

  changeCurrentPage({ commit }, page) {
    commit('changeCurrentPage', page);
  },

  async addGiftToCart({ commit }, { id, amount }) {
    const payload: Cart = await giftStoreApi.addGiftToCart(id, amount);

    commit('changeCart', payload);
  },

  async removeGiftFromCart({ commit }, id) {
    const payload = await giftStoreApi.removeGiftFromCart(id);

    commit('changeCart', payload);
  },

  async editGiftToCart({ commit }, { id, amount }) {
    const payload = await giftStoreApi.editGiftToCart(id, amount);

    commit('changeCart', payload);
  },

  async getOrders({ commit }, page) {
    const payload: any = await giftStoreApi.getOrders(page, 0);

    commit('changeOrders', payload.orders);
    commit('setPoints', payload.points);
  },

  async showOrder(o, id) {
    return giftStoreApi.showOrder(id);
  },

  async createOrder() {
    return giftStoreApi.createOrder();
  },

  cancelOrder(o, id) {
    return giftStoreApi.cancelOrder(id);
  },

  setNotReceivedOrder(o, id) {
    return giftStoreApi.setNotReceivedOrder(id);
  },
};
