<template>
  <app-tooltip
    position="top"
    class="app-motivation-tooltip"
  >
    <div class="app-tooltip__outer">
      <div
        v-if="tooltipText"
        class="app-tooltip__tooltip"
      >
        {{ tooltipText }}
      </div>
      <app-badge
        :badge-style="badgeStyle"
        :color="badgeColor"
        size="sm"
      >
        <template
          v-if="$slots['left-icon']"
          slot="left-icon"
        >
          <div
            class="app-motivation-tooltip__icon"
            :class="motivationIconClass"
            slot="left-icon"
          >
            <slot name="left-icon"></slot>
          </div>
        </template>
        <slot></slot>
      </app-badge>
    </div>
  </app-tooltip>
</template>

<script>
import AppTooltip from '@/components/helpers/tooltips/tooltip.vue';
import AppBadge from '@/components/helpers/badge.vue';

export default {
  name: 'app-motivation-tooltip',

  components: {
    AppTooltip,
    AppBadge,
  },

  props: {
    status: {
      type: String,
      default: 'assigned',
      validator: val => ['white', 'assigned', 'success', 'failed'].includes(val),
    },
    tooltipText: {
      type: String,
      default: '',
    },
    badgeStyle: {
      type: String,
      default: 'filled',
      validate: val => ['filled', 'outline'].includes(val),
    },
    badgeColor: {
      type: String,
      default: 'gray',
      validate: val => ['gray', 'success', 'error'].includes(val),
    },
  },

  computed: {
    motivationIconClass() {
      return `app-motivation-tooltip__icon--${this.status}`;
    },
  },
};
</script>

<style lang="scss">
.app-motivation-tooltip {
  &__icon {
    align-items: center;
    display: flex;

    svg {
      height: 16px;
      margin: 0;
      width: 16px;
    }

    &--white {
      svg path {
        fill: var(--surfaceWhite);
      }
    }

    &--assigned {
      svg path {
        // neutral-500
        fill: var(--neutral500);
      }
    }

    &--success {
      svg path {
        fill: var(--success);
      }
    }

    &--failed {
      svg path {
        fill: var(--error);
      }
    }
  }
}
</style>
