import { GetterTree } from 'vuex';
import { filter } from 'lodash';
import { CompetenciesState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<CompetenciesState, RootState> = {
  materials(state) {
    switch (state.selectedFilter) {
      case 'all':
        return state.materials;
      case 'is-new':
        return filter(state.materials, material => material.isNew);
      case 'not-started':
        return filter(state.materials, material => !material.isNew && !material.isPassed());
      case 'is-passed':
        return filter(state.materials, material => material.isPassed());
      default:
        break;
    }
  },

  filters(state): Object[] {
    return [
      {
        type: 'all',
        lokalise: 'common_all_text',
        count: state.materials.length,
      },
      {
        type: 'is-new',
        lokalise: 'common_new_status_text_first_letter_uppercase',
        count: filter(state.materials, material => material.isNew).length,
      },
      {
        type: 'not-started',
        lokalise: 'common_not_started_text',
        count: filter(state.materials, material => !material.isNew && !material.isPassed()).length,
      },
      {
        type: 'is-passed',
        lokalise: 'common_passed_text',
        count: filter(state.materials, material => material.isPassed()).length,
      },
    ];
  },

  selectedFilter(state): Object | null {
    return state.selectedFilter;
  },
};
