import each from 'lodash/each';
import IFilter from '@/entities/modules/my-team/filters/IFilter';

export default class EmployeeFormFilterResult implements IFilter {
  id: number;
  name: string;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }
}

export function createEmployeeFormFilterResult(data: any): IFilter[] {
  const formFilters: IFilter[] = [];

  if (!data[0] || !data[0].form_question_id) {
    return formFilters;
  }

  // генерируем айдишник чтобы сделать значения уникальными
  let newId = parseInt(data[0].form_question_id, 10) * 1000;
  each(data, result => {
    newId += 1;

    formFilters.push(new EmployeeFormFilterResult(newId, result.value));
  });

  return formFilters;
}
