import has from 'lodash/has';

export class CompanyBranding {
  constructor({
    id,

    primaryColor,
    infoColor,
    successColor,
    errorColor,
    linkColor,
    textOnSurfaceColor,
    textOnPrimaryColor,
    surfaceWhiteColor,
    surfaceGreyColor,

    whiteLogo,
    coloredLogo,
    favicon,
    authBg,
    sidebarBg,
    authTextColor,
    useMsLibForPreview,
  }) {
    this.id = id;

    this.primaryColor = primaryColor;
    this.infoColor = infoColor;
    this.successColor = successColor;
    this.errorColor = errorColor;
    this.linkColor = linkColor;
    this.textOnSurfaceColor = textOnSurfaceColor;
    this.textOnPrimaryColor = textOnPrimaryColor;
    this.surfaceWhiteColor = surfaceWhiteColor;
    this.surfaceGreyColor = surfaceGreyColor;

    this.whiteLogo = whiteLogo;
    this.coloredLogo = coloredLogo;
    this.favicon = favicon;
    this.authBg = authBg;
    this.sidebarBg = sidebarBg;
    this.authTextColor = authTextColor;
    this.useMsLibForPreview = useMsLibForPreview;
  }
}

export function createCompanyBranding(data) {
  let authBg = null;

  if (has(data, 'auth_background_image.origin.url')) {
    authBg = data.auth_background_image.origin.url;
  }

  let sidebarBg = null;

  if (has(data, 'sidebar_image.origin.url')) {
    sidebarBg = data.sidebar_image.origin.url;
  }

  let whiteLogo = null;

  if (has(data, 'logo_white.origin.url')) {
    whiteLogo = data.logo_white.origin.url;
  }

  let coloredLogo = null;

  if (has(data, 'logo_colored.origin.url')) {
    coloredLogo = data.logo_colored.origin.url;
  }

  let favicon = null;

  if (has(data, 'favicon.origin.url')) {
    favicon = data.favicon.origin.url;
  }

  let successColor = null;

  if (has(data, 'success_color')) {
    successColor = `#${data.success_color}`;
  }

  let errorColor = null;

  if (has(data, 'error_color')) {
    errorColor = `#${data.error_color}`;
  }

  let linkColor = null;

  if (has(data, 'link_color')) {
    linkColor = `#${data.link_color}`;
  }

  let textOnSurfaceColor = null;

  if (has(data, 'text_on_surface_color')) {
    textOnSurfaceColor = `#${data.text_on_surface_color}`;
  }

  let textOnPrimaryColor = null;

  if (has(data, 'text_on_primary_color')) {
    textOnPrimaryColor = `#${data.text_on_primary_color}`;
  }

  let surfaceWhiteColor = null;

  if (has(data, 'surface_white_color')) {
    surfaceWhiteColor = `#${data.surface_white_color}`;
  }

  let surfaceGreyColor = null;

  if (has(data, 'surface_grey_color')) {
    surfaceGreyColor = `#${data.surface_grey_color}`;
  }

  let authTextColor = null;

  if (has(data, 'auth_text_color')) {
    authTextColor = `#${data.auth_text_color}`;
  }

  const useMsLibForPreview = parseInt(data.use_ms_lib_for_preview, 10) === 1;

  return Object.freeze(
    new CompanyBranding({
      id: parseInt(data.company_id, 10) || 0,

      primaryColor: `#${data.primary_color}`,
      infoColor: `#${data.info_color}`,

      successColor,
      errorColor,
      linkColor,
      textOnSurfaceColor,
      textOnPrimaryColor,
      surfaceWhiteColor,
      surfaceGreyColor,

      whiteLogo,
      coloredLogo,
      favicon,
      authBg,
      sidebarBg,
      authTextColor,
      useMsLibForPreview,
    }),
  );
}
