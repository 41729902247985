// @ts-ignore
import { createMaterialCardWidget } from '@/entities/common/MaterialCardWidget';
import slugProvider from '@/providers/slugProvider';
import CustomRoute from '@/entities/common/CustomRoute';

export class InterviewMaterial {
  id: number;
  name: string;
  isNew: boolean;
  status: string;

  constructor(payload: any) {
    this.id = payload.id ? parseInt(payload.id, 10) : parseInt(payload.entity_id, 10);
    this.name = payload.name;
    this.isNew = parseInt(payload.is_new, 10) === 1;
    this.status = payload.status;
  }

  getWidget() {
    return createMaterialCardWidget({
      name: this.name,
      to: {
        name: CustomRoute.TO_INTERVIEWS_MATERIAL,
        params: {
          id: this.id,
          slug: slugProvider(this.name),
        },
      },
      type: 'interviews',
      isNew: this.isNew,
    });
  }
}
