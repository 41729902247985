import { Module } from 'vuex';
import { RootState } from '../../types';
import { SearchState } from './types';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

export const state: SearchState = {};

const namespaced: boolean = true;

export const search: Module<SearchState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
