<template>
  <app-material-card
    :widget="video"
    :event-label="eventLabel"
    :has-outer-footer="true"
    @changeFavoriteStatus="changeFavoriteStatus"
  >
    <template #footer>
      <template v-if="!video.isNew">
        <AppLike
          :is-big="false"
          :is-liked="isLiked"
          :likes-count="countLikes"
          @toggleLike="likeOrRemoveLike"
        />

        <AppDiscussion
          v-if="video.isCommentingAllowed"
          :is-big="false"
          :material="video"
          @toggleComment="routeToVideoPage"
        />

        <AppView
          :is-gray-label="true"
          :is-viewed="video.isViewed"
          :views-count="video.views"
        />
      </template>
    </template>
  </app-material-card>
</template>

<script>
import AppView from '@/components/helpers/view';
import AppLike from '@/components/helpers/like';
import AppDiscussion from '@/components/helpers/discussion';
import AppMaterialCard from '@/components/helpers/cards/material-card';
import { sendEvent } from '@/providers/analyticsProvider';
import CustomRoute from '@/entities/common/CustomRoute';

export default {
  components: {
    AppMaterialCard,
    AppLike,
    AppDiscussion,
    AppView,
  },

  props: {
    video: {
      type: Object,
      required: true,
    },

    eventLabel: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    isLiked: false,
    isLikeLoading: false,
    countLikes: 0,
  }),

  created() {
    this.countLikes = this.video.likes;
    this.isLiked = !!this.video.isLiked;
  },

  methods: {
    likeOrRemoveLike() {
      if (this.isLikeLoading) {
        return;
      }

      this.isLikeLoading = true;

      if (this.isLiked) {
        this.$store
          .dispatch('video/removeLike', this.video.id)
          .then(() => {
            this.isLiked = false;
            this.countLikes -= 1;
          })
          .finally(() => {
            this.isLikeLoading = false;
          });
      } else {
        this.$store
          .dispatch('video/like', this.video.id)
          .then(() => {
            this.isLiked = true;
            this.countLikes += 1;
          })
          .finally(() => {
            this.isLikeLoading = false;
            sendEvent(this.$analytics, 'discover_video_like_btn');
          });
      }
    },

    changeFavoriteStatus(status, id) {
      this.$emit('changeFavoriteStatus', status, id);
    },

    routeToVideoPage() {
      this.$router.push({
        name: CustomRoute.TO_VIDEOS_MATERIAL,
        params: {
          id: this.video.id,
          slug: this.video.slug,
          isOpenComments: true,
        },
      });
    },
  },
};
</script>
