import BasePolicy from '@/entities/modules/settings/password-policies/BasePolicy';
import { i18n } from '@/lang';

export default class FieldMathPolicy extends BasePolicy {
  private readonly isLikeLogin?: boolean;
  private readonly isLikeFirstName?: boolean;
  private readonly isLikeLastName?: boolean;
  private readonly isLikeEmail?: boolean;
  private readonly isLikePhone?: boolean;

  constructor(payload: any) {
    super(payload);

    this.isLikeLogin = parseInt(payload.use_login, 10) === 1;
    this.isLikeFirstName = parseInt(payload.use_first_name, 10) === 1;
    this.isLikeLastName = parseInt(payload.use_last_name, 10) === 1;
    this.isLikeEmail = parseInt(payload.use_email, 10) === 1;
    this.isLikePhone = parseInt(payload.use_phone, 10) === 1;
  }

  getLabel(): string {
    const policies = [];

    // eslint-disable-next-line no-unused-expressions
    this.isLikeLogin && policies.push(i18n.t('common_login_whom_text').toString());
    // eslint-disable-next-line no-unused-expressions
    this.isLikeFirstName && policies.push(i18n.t('common_name_whom_text').toString());
    // eslint-disable-next-line no-unused-expressions
    this.isLikeLastName && policies.push(i18n.t('common_surname_whom_text').toString());
    // eslint-disable-next-line no-unused-expressions
    this.isLikeEmail && policies.push(i18n.t('common_email_whom_text').toString());
    // eslint-disable-next-line no-unused-expressions
    this.isLikePhone && policies.push(i18n.t('common_phone_whom_text').toString());

    return `${i18n.t('auth_pass_cannot_match_text').toString()} ${policies.join(', ')}`;
  }
}
