import ResultFormat from '@/entities/common/testing/settings/ResultFormat';
import ShowAnswersFormat from '@/entities/common/testing/settings/ShowAnswersFormat';
import ITestSettings from '@/entities/common/testing/settings/ITestSettings';

export default class Settings implements ITestSettings {
  allowSeeDetails: boolean;
  resultValue: ResultFormat;
  showBestCompetencies: boolean;
  showWorstCompetencies: boolean;
  showAnswers: ShowAnswersFormat;
  showResultText: boolean;
  testPassed: string;
  showRecommendation: boolean;

  constructor(payload: any) {
    this.allowSeeDetails = parseInt(payload.allow_see_details, 10) === 1;

    switch (parseInt(payload.result_value, 10)) {
      case 1:
        this.resultValue = ResultFormat.PERCENT;
        break;
      case 2:
        this.resultValue = ResultFormat.POINTS;
        break;
      default:
        this.resultValue = ResultFormat.HIDDEN;
        break;
    }

    this.showBestCompetencies = parseInt(payload.show_best_competencies, 10) === 1;
    this.showWorstCompetencies = parseInt(payload.show_worst_competencies, 10) === 1;

    switch (parseInt(payload.show_correct_answers, 10)) {
      case 0:
        this.showAnswers = ShowAnswersFormat.NOT_SHOW;
        break;
      default:
        this.showAnswers = ShowAnswersFormat.SHOW_ALL;
        break;
    }

    this.showResultText = parseInt(payload.show_result_text, 10) === 1;
    this.testPassed = payload.test_passed;
    this.showRecommendation = parseInt(payload.show_recommendations, 10) === 1;
  }
}
