import IRoute from '@/entities/common/interfaces/routes/IRoute';

export default interface IRouteModule extends IRoute {
  moduleId: number;
  moduleSlug: string;
}

export function isIRouteModule(x: any): x is IRouteModule {
  return x.moduleId && x.moduleSlug;
}
