import CorrectName from '@/entities/common/CorrectName';
import CustomRoute from '@/entities/common/CustomRoute';
import { NavigationGuardNext, Route } from 'vue-router';

export default [
  {
    path: '/evaluation/tests',
    name: CustomRoute.TO_TESTS,
    meta: {
      title: 'tests_screen_title_text',
      moduleCode: CorrectName.EVALUATIONS,
      eventOnEnter: 'evaluation_tests_tab_open',
      from: { 'dashboard-widget': 'dashboard_tests_name_btn' },
    },
    component: () =>
      import(/* webpackChunkName: "evaluation" */ '@/views/evaluation/tests/index.vue'),
  },
  {
    path: '/evaluation/tests/:id-:slug',
    name: CustomRoute.TO_TESTS_MATERIAL,
    meta: {
      title: 'tests_screen_title_text',
      moduleCode: CorrectName.EVALUATIONS,
      moduleName: CorrectName.TESTS,
      from: { 'my-results': 'myresults_go_to_test_btn' },
    },
    component: () =>
      import(/* webpackChunkName: "evaluation" */ '@/views/evaluation/tests/material.vue'),
  },
  {
    path: '/evaluation/tests/:id-:slug/start',
    name: CustomRoute.TO_TESTS_GO,
    meta: {
      title: 'tests_screen_title_text',
      moduleCode: CorrectName.EVALUATIONS,
      moduleName: CorrectName.TESTS,
      eventOnEnter: 'evaluation_test_open_btn',
      showIntercom: false,
      showBlockedMessages: false,
      enableSidebar: false,
    },
    beforeEnter: (to: Route, from: Route, next: NavigationGuardNext) => {
      if (from.name === CustomRoute.TO_TESTS_MATERIAL && !to.params.passTest) {
        return next({
          name: CustomRoute.TO_TESTS,
        });
      }

      if (!to.params.passTest) {
        return next({
          name: CustomRoute.TO_TESTS_MATERIAL,
          params: {
            id: to.params.id,
            slug: to.params.slug,
          },
        });
      }

      next();
    },
    component: () => import(/* webpackChunkName: "evaluation" */ '@/views/evaluation/tests/go.vue'),
  },
  {
    path: '/evaluation/tests/:id-:slug/results',
    name: CustomRoute.TO_TESTS_RESULTS,
    meta: {
      title: 'tests_screen_title_text',
      moduleCode: CorrectName.EVALUATIONS,
      moduleName: CorrectName.TESTS,
    },
    component: () =>
      import(/* webpackChunkName: "evaluation" */ '@/views/evaluation/tests/results.vue'),
  },
  {
    path: '/evaluation/tests/:id-:slug/correct',
    name: CustomRoute.TO_TESTS_MY_ANSWERS,
    meta: {
      title: 'tests_screen_title_text',
      moduleCode: CorrectName.EVALUATIONS,
      eventOnEnter: 'evaluation_test_details_btn',
      showIntercom: false,
      showBlockedMessages: false,
      enableSidebar: false,
    },
    component: () =>
      import(/* webpackChunkName: "evaluation" */ '@/views/evaluation/tests/my-answers.vue'),
  },
  {
    path: '/evaluation/competencies',
    name: CustomRoute.TO_COMPETENCIES,
    meta: {
      title: 'tests_screen_title_text',
      moduleCode: CorrectName.EVALUATIONS,
      eventOnEnter: 'competency_module_open',
    },
    component: () =>
      import(/* webpackChunkName: "evaluation" */ '@/views/evaluation/competencies/index.vue'),
  },
  {
    path: '/evaluation/competencies/:id-:slug',
    name: CustomRoute.TO_COMPETENCIES_MATERIAL,
    meta: {
      title: 'tests_screen_title_text',
      moduleCode: CorrectName.EVALUATIONS,
      moduleName: CorrectName.COMPETENCY,
    },
    component: () =>
      import(/* webpackChunkName: "evaluation" */ '@/views/evaluation/competencies/material.vue'),
  },
  {
    path: '/evaluation/competencies/:id-:slug/start',
    name: CustomRoute.TO_COMPETENCIES_GO,
    meta: {
      title: 'tests_screen_title_text',
      moduleCode: CorrectName.EVALUATIONS,
      moduleName: CorrectName.COMPETENCY,
      eventOnEnter: 'competency_test_open_btn',
      showIntercom: false,
      showBlockedMessages: false,
      enableSidebar: false,
    },
    beforeEnter: (to: Route, from: Route, next: NavigationGuardNext) => {
      if (from.name === CustomRoute.TO_COMPETENCIES_MATERIAL && !to.params.passTest) {
        return next({
          name: CustomRoute.TO_COMPETENCIES,
        });
      }

      if (!to.params.passTest) {
        return next({
          name: CustomRoute.TO_COMPETENCIES_MATERIAL,
          params: {
            id: to.params.id,
            slug: to.params.slug,
          },
        });
      }

      next();
    },
    component: () =>
      import(/* webpackChunkName: "evaluation" */ '@/views/evaluation/competencies/go.vue'),
  },
  {
    path: '/evaluation/competencies/:id-:slug/my-competencies',
    name: CustomRoute.TO_COMPETENCIES_MY_COMPETENCIES,
    meta: {
      title: 'tests_screen_title_text',
      moduleCode: CorrectName.EVALUATIONS,
      eventOnEnter: 'competency_details_skills_list_btn',
    },
    component: () =>
      import(
        /* webpackChunkName: "evaluation" */ '@/views/evaluation/competencies/my-competencies.vue'
      ),
  },
  {
    path: '/evaluation/competencies/:id-:slug/results',
    name: CustomRoute.TO_COMPETENCIES_RESULTS,
    meta: {
      title: 'tests_screen_title_text',
      moduleCode: CorrectName.EVALUATIONS,
      moduleName: CorrectName.COMPETENCY,
    },
    component: () =>
      import(/* webpackChunkName: "evaluation" */ '@/views/evaluation/competencies/results.vue'),
  },
  {
    path: '/evaluation/competencies/:id-:slug/correct',
    name: CustomRoute.TO_COMPETENCIES_MY_ANSWERS,
    meta: {
      title: 'tests_screen_title_text',
      moduleCode: CorrectName.EVALUATIONS,
      eventOnEnter: 'competency_test_details_btn',
      showIntercom: false,
      showBlockedMessages: false,
      enableSidebar: false,
    },
    component: () =>
      import(/* webpackChunkName: "evaluation" */ '@/views/evaluation/competencies/my-answers.vue'),
  },
  {
    path: '/evaluation/interviews',
    name: CustomRoute.TO_INTERVIEWS,
    meta: {
      title: 'tests_screen_title_text',
      moduleCode: CorrectName.EVALUATIONS,
      eventOnEnter: 'evaluation_surveys_tab_open',
    },
    component: () =>
      import(/* webpackChunkName: "evaluation" */ '@/views/evaluation/interviews/index.vue'),
  },
  {
    path: '/evaluation/interviews/:id-:slug',
    name: CustomRoute.TO_INTERVIEWS_MATERIAL,
    meta: {
      title: 'tests_screen_title_text',
      moduleCode: CorrectName.EVALUATIONS,
      moduleName: CorrectName.INTERVIEW,
      from: { 'my-results': 'myresults_go_to_survey_btn' },
    },
    component: () =>
      import(/* webpackChunkName: "evaluation" */ '@/views/evaluation/interviews/material.vue'),
  },
  {
    path: '/evaluation/interviews/:id-:slug/start',
    name: CustomRoute.TO_INTERVIEWS_GO,
    meta: {
      title: 'tests_screen_title_text',
      moduleCode: CorrectName.EVALUATIONS,
      moduleName: CorrectName.INTERVIEW,
      eventOnEnter: 'evaluation_interview_open_btn',
      showIntercom: false,
      showBlockedMessages: false,
      enableSidebar: false,
    },
    beforeEnter: (to: Route, from: Route, next: NavigationGuardNext) => {
      if (from.name === CustomRoute.TO_INTERVIEWS_MATERIAL && !to.params.passTest) {
        return next({
          name: CustomRoute.TO_INTERVIEWS,
        });
      }

      if (!to.params.passTest) {
        return next({
          name: CustomRoute.TO_INTERVIEWS_MATERIAL,
          params: {
            id: to.params.id,
            slug: to.params.slug,
          },
        });
      }

      next();
    },
    component: () =>
      import(/* webpackChunkName: "evaluation" */ '@/views/evaluation/interviews/go.vue'),
  },
  {
    path: '/evaluation/interviews/:id-:slug/results',
    name: CustomRoute.TO_INTERVIEWS_RESULTS,
    meta: {
      title: 'tests_screen_title_text',
      moduleCode: CorrectName.EVALUATIONS,
      moduleName: CorrectName.INTERVIEW,
    },
    component: () =>
      import(/* webpackChunkName: "evaluation" */ '@/views/evaluation/interviews/results.vue'),
  },
];
