export class VisitType {
  code: string;
  lokaliseKey: string;

  constructor(code: string, lokaliseKey: string) {
    this.code = code;
    this.lokaliseKey = lokaliseKey;
  }
}

export function createVisitType(type: number): VisitType {
  switch (type) {
    case 1:
      return new VisitType('required', 'events_tag_compulsory_text');
    case 2:
      return new VisitType('optional', 'events_tag_optional_text');
    default:
      return new VisitType('unknown', '');
  }
}
