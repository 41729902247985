import QuestionTask from '@/entities/common/testing/QuestionTask';
import FieldType from '@/entities/modules/tasks/FieldType';

export default abstract class Field {
  id: number;
  type: FieldType;
  order: number = 0;
  name: string;
  error: string = '';
  size: number = 0;
  options: QuestionTask[] = [];
  comment: string;
  value: string | Date;
  isRequired: boolean;
  hasError: boolean = false;

  protected constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.type = payload.type;
    this.order = payload.order;
    this.name = payload.name;
    this.comment = payload.comment;
    this.value = payload.value;
    this.isRequired = parseInt(payload.is_required, 10) === 1;

    switch (payload.type) {
      case 'file':
        this.type = FieldType.FILE;
        break;
      case 'image':
        this.type = FieldType.IMAGE;
        break;
      case 'datetime':
        this.type = FieldType.DATE;
        break;
      case 'email':
        this.type = FieldType.EMAIL;
        break;
      case 'phone':
        this.type = FieldType.PHONE;
        break;
      case 'multiple_choice':
        this.type = FieldType.MULTIPLY_CHOICE;
        break;
      case 'number':
        this.type = FieldType.NUMBER;
        break;
      default:
        this.type = FieldType.TEXT;
    }
  }

  abstract getSaving(): object;

  setError(errorText: string) {
    this.error = errorText;
  }

  setHasError(hasError: boolean) {
    this.hasError = hasError;
  }
}
