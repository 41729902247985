import { Module } from 'vuex';
import { RootState } from '../../types';
import { actions } from './actions';

const namespaced: boolean = true;

export const information: Module<any, RootState> = {
  namespaced,
  actions,
};
