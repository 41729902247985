import { ActionTree } from 'vuex';
import personalDataApi from '@/services/personal-data';
import moment from 'moment';
import QuestionnaireSection from '@/entities/modules/profile/QuestionnaireSection';
import { PersonalDataState } from './types';
import { RootState } from '../../types';

export const actions: ActionTree<PersonalDataState, RootState> = {
  /**
   * Вернет список секций с набором inputs
   */
  async getProfileQuestionnaire(): Promise<QuestionnaireSection[]> {
    return personalDataApi.getProfileQuestionnaire();
  },

  /**
   * Вернет статус при модерации
   */
  async getProfileStatus({ commit }) {
    const personalData = await personalDataApi.getProfileStatus();
    commit('dashboard/changePersonalData', personalData, { root: true });
  },

  /**
   * Вернет успешный ответ или ошибку по конкретному input
   */
  validateQuestion({}, fieldObject) {
    let { value } = fieldObject;

    const { error } = fieldObject;

    if (fieldObject.type === 'date') {
      if (!error) {
        const date = moment(value);
        value = date.format('YYYY-MM-DD');
      }
    }

    return personalDataApi.validateQuestion(fieldObject.id, value);
  },

  /**
   * Вернет успешный ответ или ошибки по всем inputs
   */
  sendProfileQuestionnaire({}, formData) {
    return personalDataApi.sendProfileQuestionnaire(formData);
  },
};
