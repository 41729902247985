import { each } from 'lodash';
import MCQUserAnswer from '@/entities/common/testing/user-answers/MCQUserAnswer';

export default class QuestionAnswer {
  questionId: number;
  userComment?: string;
  userAnswers: MCQUserAnswer[] = [];

  constructor(payload: any) {
    this.questionId = parseInt(payload.id, 10);

    if (payload.user_comment) {
      this.userComment = payload.user_comment;
    }

    each(payload.user_answers, o => this.userAnswers.push(new MCQUserAnswer(o)));
  }
}
