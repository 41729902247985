<template>
  <svg
    :class="{ gray: color === 'gray', primary: color === 'primary' }"
    width="14"
    height="10"
    viewBox="0 0 14 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.3995 2.67146C13.79 2.28094 13.79 1.64777 13.3995 1.25725C13.009 0.866724 12.3758 0.866724 11.9853 1.25725L7.03552 6.207L2.08577 1.25725C1.69524 0.866724 1.06208 0.866724 0.671555 1.25725C0.281031 1.64777 0.281031 2.28094 0.671555 2.67146L7.03552 9.03542L13.3995 2.67146Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'down-arrow-svg',

  props: {
    color: {
      type: String,
      default: 'black',
    },
  },
};
</script>

<style scoped>
svg path {
  fill: var(--surfaceHighEmphasis);
}

.gray path {
  fill: var(--surfaceDisabledEmphasis);
}

.primary path {
  fill: var(--primary);
}
</style>
