import each from 'lodash/each';
import SectionMaterial from '@/entities/modules/learning-programs/SectionMaterial';
import MaterialStatus from '@/entities/common/testing/MaterialStatus';
import ToggleSection from '@/entities/modules/learning-programs/ToggleSection';
import BaseMaterial from '@/entities/modules/learning-programs/BaseMaterial';
import MaterialTest from '@/entities/modules/learning-programs/MaterialTest';

export default class Section {
  id: number;
  name?: string;
  order: number;
  description: string;
  materials: SectionMaterial[];
  position: number;

  constructor(payload: any, position: number) {
    this.id = parseInt(payload.id, 10);
    this.name = payload.name;
    this.order = parseInt(payload.order, 10);
    this.position = position;
    this.description = payload.description;

    this.materials = [];
    each(payload.materials, material => {
      this.materials.push(new SectionMaterial(material));
    });
  }

  /**
   * Виджет секции для страницы просмотра программы обучения (выводится сбоку)
   * @param materials
   */
  getToggleWidget(materials: BaseMaterial[]): ToggleSection {
    let countOfMaterials = 0;

    let countOfPassedMaterials = 0;

    let countOfAssignedMaterials = 0;

    let countOfCheckedMaterials = 0;

    let countOfRatingMaterials = 0;

    let countMaxScore = 0;

    let userScore = 0;

    const infoScore = [];

    const parts = [];

    each(materials, material => {
      countOfMaterials += 1;

      if (material.getStatistic().getMaterialStatus() === MaterialStatus.PASSED) {
        countOfPassedMaterials += 1;
      }

      if (material.getStatistic().getMaterialStatus() === MaterialStatus.ASSIGNED) {
        countOfAssignedMaterials += 1;
      }

      if (material instanceof MaterialTest && material.getStatistic().isChecked) {
        countOfCheckedMaterials += 1;
      }

      if (material.isRating) {
        countOfRatingMaterials += 1;
        countMaxScore += material.maxScore;
        userScore += material.getStatistic().getPoints();
      }
    });

    if (countOfRatingMaterials > 0) {
      if (countOfAssignedMaterials === countOfMaterials) {
        infoScore.push('learn_earn_d_points_text', countMaxScore, {
          0: countMaxScore.toLocaleString(),
        });
      } else if (countOfRatingMaterials === countOfPassedMaterials) {
        infoScore.push('learn_d_points_earned_text', userScore, { 0: userScore.toLocaleString() });
      } else {
        infoScore.push('learn_d_from_d_points_earned_text', countMaxScore, {
          0: userScore,
          1: countMaxScore,
        });
      }

      parts.push([
        'learn_d_from_d_passed_text',
        0,
        { 0: countOfPassedMaterials, 1: countOfMaterials },
      ]);
      parts.push(infoScore);
    } else {
      parts.push([
        'learn_d_from_d_passed_text',
        0,
        { 0: countOfPassedMaterials, 1: countOfMaterials },
      ]);
    }

    return new ToggleSection({
      id: this.id,
      name: this.name,
      position: this.position,
      description: this.description,

      isCompleted: countOfPassedMaterials === countOfMaterials,
      checkedMaterials: countOfCheckedMaterials,

      parts,
    });
  }
}
