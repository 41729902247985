import axios from 'axios';
import QuestionnaireSection from '@/entities/modules/profile/QuestionnaireSection';
import PersonalData from '@/entities/modules/profile/PersonalData';
import { each, sortBy } from 'lodash';

export default {
  async getProfileQuestionnaire(): Promise<QuestionnaireSection[]> {
    const response = await axios.get('/personal_data/questions');

    const sections: QuestionnaireSection[] = [];
    each(response.data.success.sections, section => {
      sections.push(new QuestionnaireSection(section));
    });

    return sortBy(sections, ['order']);
  },

  async getProfileStatus(): Promise<PersonalData> {
    const response = await axios.get('/personal_data/status');

    return new PersonalData(response.data.success);
  },

  validateQuestion(questionID: number, answerString: string) {
    return axios.post(
      `/personal_data/${questionID}/validate`,
      { answer: answerString },
      { headers: { globalErrorHandler: false } },
    );
  },

  sendProfileQuestionnaire(formData: any) {
    return axios.post('/personal_data/answers', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        globalErrorHandler: false,
      },
    });
  },
};
