import { BooleanType } from '@/entities/common/BooleanType';

interface IAllocation {
  mark: number;
  count: number;
}

interface IFeedbackMetaResponse {
  count: number;
  average: number;
  user_has_feedback: BooleanType;
  allocation: IAllocation[];
}

export default class LearningItemFeedbackMeta {
  count: number;

  average: number;

  userHasFeedback: boolean;

  allocation: IAllocation[];

  constructor(payload: IFeedbackMetaResponse) {
    this.count = payload.count;
    this.average = payload.average;
    this.userHasFeedback = payload.user_has_feedback === 1;
    this.allocation = payload.allocation;
  }
}
