import BasePolicy from '@/entities/modules/settings/password-policies/BasePolicy';

export default class RegexpPolicy extends BasePolicy {
  private readonly hint: string;

  constructor(payload: any) {
    super(payload);

    this.hint = payload.hint;
  }

  getLabel(): string {
    return this.hint;
  }
}
