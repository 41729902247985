import { each } from 'lodash';
import { Route } from 'vue-router';
import BaseExternalAuthType from '@/entities/modules/settings/auth-types/BaseExternalAuthType';

export default class BlitzIdentityAuthType extends BaseExternalAuthType {
  getCheckData(route: Route) {
    const date: any = {
      id: this.id,
    };

    const separatedHash = route.hash.replace('#', '').split('&');
    each(separatedHash, query => {
      const queryPair = query.split('=');
      // eslint-disable-next-line prefer-destructuring
      date[queryPair[0]] = queryPair[1];
    });

    return date;
  }
}
