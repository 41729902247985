export class MaterialCardWidget {
  constructor({
    id,
    moduleId,
    moduleName,
    order,
    name,
    apiName,
    to,
    tagLabel,
    image,
    isRating,
    isRequired,
    isInProgress,
    isPassed,
    isFailed,
    isAssigned,
    isChecking,
    isChecked,
    isSkipped,
    isNew,
    isFavorite,
    parts,
    diagram,
    type,
    subtype,
    userScore,
    maxScore,
    bonusPoints,
    programPoints,
    penaltyPoints,
    maxBonusPoints,
    maxProgramPoints,
    maxPenaltyPoints,
    showDetails,
    eventStatus,
    userHasFeedback,
    isFeedbackShowed,
    isFewRating,
    feedbackAverageMark,
    isUnknown,
    percent,
    views,
    isLiked,
    isViewed,
    likes,
    duration,
    price,
    genderSpecific,
    passingDeadline,
    passingNoticeDeadline,
    endTime,
    hasNotPassedUsers,
    deadlineType,
    deadlineNotificationTime,
    deadlineTime,
    deadlineAccomplishedUsers,
    deadlineNotificationApproachingUsers,
    deadlineApproachingUsers,
    deadlineOverdueUsers,
  }) {
    this.id = id;
    this.moduleId = moduleId;
    this.moduleName = moduleName;
    this.order = order;
    this.name = name;
    this.apiName = apiName;
    this.to = to;
    this.tagLabel = tagLabel;
    this.image = image;
    this.isRating = isRating;
    this.isRequired = isRequired;
    this.isInProgress = isInProgress;
    this.isPassed = isPassed;
    this.isFailed = isFailed;
    this.isAssigned = isAssigned;
    this.isChecking = isChecking;
    this.isChecked = isChecked;
    this.isSkipped = isSkipped;
    this.isNew = isNew;
    this.isFavorite = isFavorite;
    this.parts = parts;
    this.diagram = diagram;
    this.type = type;
    this.subtype = subtype;
    this.userScore = userScore;
    this.maxScore = maxScore;
    this.maxBonusPoints = maxBonusPoints;
    this.maxProgramPoints = maxProgramPoints;
    this.maxPenaltyPoints = maxPenaltyPoints;
    this.bonusPoints = bonusPoints;
    this.programPoints = programPoints;
    this.penaltyPoints = penaltyPoints;
    this.showDetails = showDetails;
    this.eventStatus = eventStatus;
    this.userHasFeedback = userHasFeedback;
    this.isFeedbackShowed = isFeedbackShowed;
    this.isFewRating = isFewRating;
    this.feedbackAverageMark = feedbackAverageMark;
    this.isUnknown = isUnknown;
    this.percent = percent;
    this.views = views;
    this.isLiked = isLiked;
    this.isViewed = isViewed;
    this.likes = likes;
    this.duration = duration;
    this.price = price;
    this.genderSpecific = genderSpecific;
    this.passingDeadline = passingDeadline;
    this.passingNoticeDeadline = passingNoticeDeadline;
    this.endTime = endTime;
    this.hasNotPassedUsers = hasNotPassedUsers;
    this.deadlineType = deadlineType;
    this.deadlineNotificationTime = deadlineNotificationTime;
    this.deadlineTime = deadlineTime;
    this.deadlineAccomplishedUsers = deadlineAccomplishedUsers;
    this.deadlineNotificationApproachingUsers = deadlineNotificationApproachingUsers;
    this.deadlineApproachingUsers = deadlineApproachingUsers;
    this.deadlineOverdueUsers = deadlineOverdueUsers;
  }

  getParts() {
    return this.parts;
  }
}

export function createMaterialCardWidget(payload) {
  return new MaterialCardWidget(payload);
}
