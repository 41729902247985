// @ts-ignore
import { createMaterialCardWidget } from '@/entities/common/MaterialCardWidget';
import slugProvider from '@/providers/slugProvider';
import { has } from 'lodash';
import CustomRoute from '@/entities/common/CustomRoute';

export class TestMaterial {
  id: number;
  name: string;
  order: number;
  isNew: boolean;
  isChecked: boolean;
  isChecking: boolean;
  isRating: boolean;
  isCompleted: boolean;

  constructor(payload: any) {
    this.id = payload.id ? parseInt(payload.id, 10) : parseInt(payload.entity_id, 10);
    this.name = payload.name;
    this.order = parseInt(payload.order, 10);
    this.isNew = parseInt(payload.is_new, 10) === 1;
    this.isChecked = parseInt(payload.is_checked, 10) === 1;
    this.isChecking = parseInt(payload.is_checking, 10) === 1 && !this.isNew;
    this.isRating = has(payload, 'is_rating') ? parseInt(payload.is_rating, 10) === 1 : false;
    this.isCompleted = has(payload, 'completed') ? parseInt(payload.completed, 10) === 1 : false;
  }

  getWidget(monthFocus = false) {
    return createMaterialCardWidget({
      name: this.name,
      to: {
        name: CustomRoute.TO_TESTS_MATERIAL,
        params: { id: this.id, slug: slugProvider(this.name) },
      },
      type: 'tests',
      isChecking: monthFocus ? false : this.isChecking,
      isChecked: this.isChecked,
      isRating: this.isRating,
      isNew: this.isNew,
      isPassed: this.isChecking || monthFocus ? false : this.isCompleted,
    });
  }
}
