<template>
  <div class="home-awards-modal">
    <div class="home-awards-modal__wrapper">
      <div class="home-awards-modal__image-score-wrapper">
        <div class="home-awards-modal__image">
          <div
            v-if="badge.image"
            :class="{ 'home-awards-modal__logo--lockedBlock': !badge.assignmentId && !isManager }"
            class="home-awards-modal__logo"
          >
            <div
              v-if="!badge.assignmentId && !isManager"
              class="home-awards-modal__locked-icon"
            >
              <AppLockedIcon />
            </div>
            <div
              :style="{ 'background-image': 'url(' + badge.image + ')' }"
              :class="{ 'home-awards-modal__logo--locked': !badge.assignmentId && !isManager }"
              class="home-awards-modal__logo--image"
            ></div>
          </div>
        </div>
        <span
          :class="{ 'home-awards-modal__scores--high': badge.scores < 0 }"
          class="home-awards-modal__scores"
        >
          {{ badge.getAwardsScores() }}
        </span>
      </div>
      <div class="home-awards-modal__info">
        <div class="home-awards-modal__content">
          <p class="home-awards-modal__title">{{ badge.name }}</p>

          <!-- если награда не назначена и руководитель не назначает в своей команде -->
          <div v-if="!badge.assignmentId && !isManager">
            <p
              v-if="badge.description"
              class="home-awards-modal__label"
            >
              {{ $t('badges_description_text') }}
            </p>
            <AppMarkdown
              v-if="badge.description"
              tag="p"
              class="home-awards-modal__value"
              :source="badge.description"
            />
            <template v-if="categoryName">
              <p class="home-awards-modal__label">
                {{ $t('achievements_badges_default_category_title_text') }}
              </p>
              <p class="home-awards-modal__value">
                {{ categoryName }}
              </p>
            </template>
          </div>

          <!-- если награда назначена -->
          <div v-if="badge.assignmentId">
            <div class="home-awards-modal-assigned-by">
              <div class="home-awards-modal-assigned-by__avatar">
                <img
                  v-if="badge.assignerAvatar"
                  :src="badge.assignerAvatar"
                  alt=""
                />

                <UserSvg v-else />
              </div>
              <div class="home-awards-modal-assigned-by__info">
                <div class="home-awards-modal-assigned-by__label">
                  {{ $t('badges_who_awarded_badge_text') }}
                </div>
                <div class="home-awards-modal-assigned-by__name">{{ badge.getAssignedName() }}</div>
                <div
                  v-if="badge.comment"
                  class="home-awards-modal-assigned-by__comment"
                >
                  {{ badge.comment }}
                </div>
              </div>
            </div>

            <p
              v-if="isDesktop && badge.description"
              class="home-awards-modal__label"
            >
              {{ $t('badges_description_text') }}
            </p>
            <AppMarkdown
              v-if="badge.description"
              tag="p"
              class="home-awards-modal__value"
              :source="badge.description"
            />

            <p
              v-if="categoryName"
              class="home-awards-modal__label"
            >
              {{ $t('achievements_badges_default_category_title_text') }}
            </p>
            <p
              v-if="categoryName"
              class="home-awards-modal__value"
            >
              {{ categoryName }}
            </p>

            <p class="home-awards-modal__label">{{ $t('badges_obtained_text') }}</p>
            <p class="home-awards-modal__value">{{ badge.getDate() }}</p>

            <AppButton
              v-if="canReject"
              :is-loading="cancellingInProgress"
              :is-large="true"
              :label="badge.getCancelButtonText()"
              :is-full-width="!isDesktop"
              @click="cancelBadge(badge.assignmentId)"
            />
          </div>

          <!-- руководитель назначает в своей команде -->
          <div
            v-if="isManager"
            class="home-awards-modal__manager"
          >
            <TextareaElement
              v-if="!badge.isLimited || badge.remainingCount > 0"
              v-model="comment"
              placeholder="myteam_leave_a_comment_text"
              @areaInput="inputInTextArea"
            />

            <div
              v-if="badge.isLimited"
              class="home-awards-modal__awards-count-wrapper"
            >
              <span class="home-awards-modal__count-qty">
                {{ badge.getAwardsCount() }}
              </span>
              <span class="home-awards-modal__count-text">
                {{ badge.getAwardsLimit() }}
              </span>
            </div>

            <p
              v-if="badge.description"
              class="home-awards-modal__label"
            >
              {{ $t('badges_description_text') }}
            </p>
            <AppMarkdown
              v-if="badge.description"
              tag="p"
              class="home-awards-modal__value"
              :source="badge.description"
            />
            <p
              v-if="categoryName"
              class="home-awards-modal__label"
            >
              {{ $t('achievements_badges_default_category_title_text') }}
            </p>
            <p
              v-if="categoryName"
              class="home-awards-modal__value"
            >
              {{ categoryName }}
            </p>

            <AppButton
              v-if="!badge.isLimited || badge.remainingCount > 0"
              :is-loading="assignedInProgress"
              :is-large="true"
              :label="btnText"
              :is-full-width="!isDesktop"
              @click="assignBadge"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TextareaElement from '@/components/form/textarea';
import AppButton from '@/components/helpers/button';
import UserSvg from '@/components/svg/icons/user';
import { sendEvent } from '@/providers/analyticsProvider';
import AppLockedIcon from '@/components/svg/icons/lock';
import AppMarkdown from '@/components/helpers/markdown';

export default {
  name: 'my-awards-modal',

  components: {
    TextareaElement,
    AppButton,
    AppLockedIcon,
    UserSvg,
    AppMarkdown,
  },

  props: {
    badge: {
      type: Object,
      default: null,
    },

    isManager: {
      type: Boolean,
      default: false,
    },

    canReject: {
      type: Boolean,
      default: false,
    },

    awardsType: {
      type: String,
      default: null,
    },

    categoryName: {
      type: String,
      default: null,
    },

    isItDeepManager: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    comment: '',
    assignedInProgress: false,
    cancellingInProgress: false,
  }),

  computed: {
    ...mapGetters('auth', ['user']),

    ...mapGetters('helpers', ['isDesktop']),

    btnText() {
      return this.awardsType === 'award'
        ? this.$t('myteam_to_award_text')
        : this.$t('myteam_to_warn_text');
    },
  },

  methods: {
    inputInTextArea(text) {
      this.comment = text;
    },

    assignBadge() {
      this.assignedInProgress = true;

      this.$store
        .dispatch('myTeam/assignEmployeeAward', {
          badgeId: this.badge.id,
          userId: this.$route.params.id,
          comment: this.comment,
        })
        .then(() => {
          this.$emit('updateAwards');
          this.$emit('closeModal');
          this.$store.dispatch('helpers/showNotification', {
            message:
              this.awardsType === 'award'
                ? this.$t('myteam_award_successful_text')
                : this.$t('myteam_warn_successful_text'),
          });
          sendEvent(this.$analytics, 'myteam_give_badge_btn');

          if (this.isItDeepManager) {
            sendEvent(this.$analytics, 'myteam_give_badge_hierarchemployee_btn');
          }
        })
        .catch(err => {
          if (err.getErrorCode() === 8003) {
            this.$emit('updateAwards');
          }
        })
        .finally(() => {
          this.assignedInProgress = false;
        });
    },

    cancelBadge(id) {
      this.cancellingInProgress = true;

      this.$store
        .dispatch('myTeam/cancelEmployeeAward', id)
        .then(() => {
          this.$emit('cancelBadge', id);
          this.$emit('closeModal');
          this.$store.dispatch('helpers/showNotification', {
            message:
              this.badge.scores >= 0
                ? this.$t('myteam_award_revoked_text')
                : this.$t('myteam_warning_revoked_text'),
          });
          sendEvent(this.$analytics, 'myteam_cancel_badge_btn');

          if (this.isItDeepManager) {
            sendEvent(this.$analytics, 'myteam_cancel_badge_hierarchemployee_btn');
          }
        })
        .finally(() => {
          this.cancellingInProgress = false;
        });
    },
  },
};
</script>
