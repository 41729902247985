import IRoute from '@/entities/common/interfaces/routes/IRoute';
import LearningItem from '@/entities/modules/learning-items/LearningItem';

export default interface IRouteTrack extends IRoute {
  track: LearningItem | null;
}

export function isIRouteTrack(x: any): x is IRouteTrack {
  return x.track;
}
