import axios from 'axios';
import LearningItem, { LearningItemScheme } from '@/entities/modules/learning-items/LearningItem';
import { createLearningItemsList } from '@/entities/modules/learning-items/LearningItemsList';
import Pagination from '@/entities/common/Pagination';
import LearningItemTag, {
  LearningItemTagScheme,
} from '@/entities/modules/learning-items/LearningItemTag';
import LearningItemStatistic, {
  LearningItemStatisticScheme,
} from '@/entities/modules/learning-items/LearningItemStatistic';
import { LearningItemStatus } from '@/entities/modules/learning-items/LearningItemStatus';
import { LearningItemDeadlineType } from '@/entities/modules/learning-items/DeadlineType';
import {
  getApiId,
  getApiName,
  LearningItemType,
  LearningItemTypes,
} from '@/entities/modules/learning-items/LearningItemType';
import NodeItem, { NodeItemScheme } from '@/entities/modules/learning-track/NodeItem';
import { createNodesList } from '@/entities/modules/learning-track/NodesList';
import NextNodeAvailability from '@/entities/modules/learning-track/NextNodeAvailability';
import { paginationResolver } from '@/providers/paginationProvider';
import { createFeedbackList } from '@/entities/modules/learning-items/feedback/FeedbackList';
import LearningItemFeedbackMeta from '@/entities/modules/learning-items/feedback/FeedbackMeta';
import LearningItemFeedback from '@/entities/modules/learning-items/feedback/Feedback';
import CurrentNodeItem from '@/entities/modules/learning-track/CurrentNodeItem';

interface PageParamsScheme {
  page?: number;
  perPage?: number;
  moduleId: number;
}

interface StatisticDataScheme {
  type?: LearningItemType;
  ids: Array<string>;
}

interface LearningItemDataScheme {
  isRequired?: boolean;
  ids?: Array<string>;
  status?: Array<LearningItemStatus>;
  deadline?: Array<LearningItemDeadlineType>;
  type?: Array<LearningItemType>;
  categories?: Array<number>;
  tags?: Array<number>;
  page?: number;
  moduleId: number;
}

interface FeedbackDataScheme {
  mark: number;
  comment: string;
}

async function postLearningItems(data: LearningItemDataScheme) {
  return axios.post(`/learning-items`, {
    is_required: !!data.isRequired,
    ids: data.ids || [],
    status: data.status,
    deadline: data.deadline,
    type: data.type,
    categories: data.categories || [],
    tags: data.tags || [],
    page: data.page || 1,
    module_id: data.moduleId,
  });
}

async function getLearningItemsStat(params: PageParamsScheme, data: StatisticDataScheme) {
  return axios.post(
    '/learning-items/stat',
    {
      filter: {
        ids: data.ids,
        type: data.type ? getApiName(data.type) : '',
      },
    },
    {
      params: {
        page: params.page || 1,
        per_page: params.perPage || 20,
        module_id: params.moduleId,
      },
    },
  );
}

async function getTags(params: PageParamsScheme) {
  return axios.get('/learning-items/tags', {
    params: {
      page: params.page || 1,
      per_page: params.perPage || 20,
      module_id: params.moduleId,
    },
  });
}

async function getNodes(trackId: string, params: PageParamsScheme) {
  return axios.get(`/learning-items/${trackId}/nodes`, {
    params: {
      page: params.page,
      module_id: params.moduleId,
    },
  });
}

async function getTrackStatus(trackId: string, moduleId: number) {
  return axios.get(`/learning-items/tracks/${trackId}/status`, {
    params: {
      module_id: moduleId,
    },
  });
}

async function loadLearningItems(data: LearningItemDataScheme) {
  const response = await postLearningItems(data);

  const { learning_items: learningItems, meta } = response.data.success;

  const pagination = new Pagination(meta.pagination);

  let learningItemsList: Array<LearningItem> = [];

  if (learningItems.length) {
    const ids = learningItems.map((item: LearningItemScheme) => item.id);

    const statisticResponse = await getLearningItemsStat({ moduleId: data.moduleId }, { ids });

    const { learning_items: statistic } = statisticResponse.data.success;

    learningItemsList = createLearningItemsList(learningItems, statistic);
  }

  return {
    learningItemsList,
    pagination,
  };
}

async function getLearningItemFeedback(id: string, params: PageParamsScheme) {
  return axios.get(`/learning-items/${id}/feedback`, {
    params: {
      page: params.page || 1,
      per_page: params.perPage || 20,
      module_id: params.moduleId,
    },
  });
}

async function getLearningItemUserFeedback(id: string) {
  return axios.get(`/learning-items/${id}/feedback/current-user-feedback`);
}

async function postLearningItemUserFeedback(id: string, data: FeedbackDataScheme) {
  return axios.post(`/learning-items/${id}/feedback/current-user-feedback`, {
    feedback: {
      mark: data.mark,
      comment: data.comment,
    },
  });
}

export default {
  async getLearningItemsTags(
    params: PageParamsScheme,
  ): Promise<{ tags: Array<LearningItemTag>; meta: Pagination }> {
    const response = await getTags(params);

    const { tags: tagsList, meta: tagsMeta } = response.data.success;

    const tags = tagsList.map((tag: LearningItemTagScheme) => new LearningItemTag(tag));

    const meta = new Pagination(tagsMeta.pagination);

    return {
      tags,
      meta,
    };
  },

  async getLearningItemsStatistic(
    params: PageParamsScheme,
    data: StatisticDataScheme,
  ): Promise<Array<LearningItemStatistic>> {
    const response = await getLearningItemsStat(params, data);

    const { learning_items: statistic } = response.data.success;

    return statistic.map((item: LearningItemStatisticScheme) => new LearningItemStatistic(item));
  },

  getLearningItems(
    data: LearningItemDataScheme,
  ): Promise<{ learningItemsList: Array<LearningItem>; pagination: Pagination }> {
    return loadLearningItems(data);
  },

  async paginationPostLearningItems(page: number, data: LearningItemDataScheme) {
    data.page = page;

    const response = await loadLearningItems(data);

    return [response.pagination, response.learningItemsList];
  },

  getAllLearningItems(data: LearningItemDataScheme) {
    return paginationResolver<LearningItem>(this.paginationPostLearningItems, [data]);
  },

  async getLearningItem(moduleId: number, id: string): Promise<LearningItem | null> {
    const response = await postLearningItems({
      ids: [id],
      moduleId,
    });

    if (!response.data.success.learning_items[0]) {
      return null;
    }

    return new LearningItem(response.data.success.learning_items[0]);
  },

  async getLearningTrackNodes(
    trackId: string,
    params: PageParamsScheme,
  ): Promise<{ nodesList: Array<NodeItem>; pagination: Pagination }> {
    const response = await getNodes(trackId, params);

    const { nodes, meta } = response.data.success;

    const pagination = new Pagination(meta.pagination);

    const ids = nodes.map(
      (node: NodeItemScheme) => `${getApiId(node.entity_type)}_${node.entity_id}`,
    );

    const learningItemsResponse = await postLearningItems({
      ids,
      moduleId: params.moduleId,
    });

    const statisticResponse = await getLearningItemsStat(
      { page: 1, moduleId: params.moduleId },
      { ids },
    );

    const nodesList = createNodesList(
      nodes,
      learningItemsResponse.data.success.learning_items,
      statisticResponse.data.success.learning_items,
    );

    return {
      nodesList,
      pagination,
    };
  },

  async getTrackStatus(trackId: string, moduleId: number) {
    const response = await getTrackStatus(trackId, moduleId);

    if (!response.data.success) {
      return null;
    }

    const currentNode = response.data.success.current_node
      ? new CurrentNodeItem(response.data.success.current_node)
      : null;

    if (currentNode) {
      const currentNodeStatResponse = await getLearningItemsStat(
        { moduleId },
        { ids: [`${LearningItemTypes.PROGRAM}_${currentNode.entityId}`] },
      );

      const currentNodeStatistic = currentNodeStatResponse.data.success.learning_items.map(
        (item: LearningItemStatisticScheme) => new LearningItemStatistic(item),
      )[0];

      currentNode.setIsAvailable(true);
      currentNode.setStatistic(currentNodeStatistic);
    }

    return {
      trackStat: new LearningItemStatistic(response.data.success.track_stat),
      nextNodeAvailability: new NextNodeAvailability(response.data.success.next_node_availability),
      currentNode,
    };
  },

  async getLearningItemFeedback(id: string, params: PageParamsScheme) {
    const response = await getLearningItemFeedback(id, params);

    const feedbackList = createFeedbackList(response.data.success.feedback_list);

    const feedbackMeta = new LearningItemFeedbackMeta(response.data.success.feedback_meta);

    const pagination = new Pagination(response.data.success.meta.pagination);

    return {
      feedbackList,
      feedbackMeta,
      pagination,
    };
  },

  async getLearningItemUserFeedback(id: string) {
    const response = await getLearningItemUserFeedback(id);

    return new LearningItemFeedback(response.data.success.feedback);
  },

  sendUserFeedback(id: string, data: FeedbackDataScheme) {
    return postLearningItemUserFeedback(id, data);
  },
};
