import CorrectName from '@/entities/common/CorrectName';
import CustomRoute from '@/entities/common/CustomRoute';

export default [
  {
    path: '/my-results/learning',
    name: CustomRoute.TO_MY_RESULTS_LEARNING,
    meta: {
      title: 'myresults_section_title',
      moduleCode: CorrectName.MY_RESULTS,
      from: {
        'dashboard-widget-name': 'dashboard_results_name_btn',
        'dashboard-widget': 'dashboard_results_widget_btn',
      },
    },
    component: () =>
      import(/* webpackChunkName: "my-results" */ '@/views/my-results/learning/index.vue'),
  },
  {
    path: '/my-results/learning/interview/:id-:slug',
    name: CustomRoute.TO_MY_RESULTS_LEARNING_INTERVIEW,
    meta: {
      title: 'myresults_section_title',
      moduleCode: CorrectName.MY_RESULTS,
      eventOnEnter: 'myresults_view_survey_stats_btn',
    },
    component: () =>
      import(/* webpackChunkName: "my-results" */ '@/views/my-results/learning/interview.vue'),
  },
  {
    path: '/my-results/learning/test/:id-:slug',
    name: CustomRoute.TO_MY_RESULTS_LEARNING_TEST,
    meta: {
      title: 'myresults_section_title',
      moduleCode: CorrectName.MY_RESULTS,
      eventOnEnter: 'myresults_view_test_stats_btn',
    },
    component: () =>
      import(/* webpackChunkName: "my-results" */ '@/views/my-results/learning/test.vue'),
  },
  {
    path: '/my-results/learning/lm/:moduleId/:type/:id',
    name: CustomRoute.TO_MY_RESULTS_LEARNING_LEARNING_MATERIALS,
    meta: {
      title: 'myresults_section_title',
      moduleCode: CorrectName.MY_RESULTS,
    },
    component: () =>
      import(
        /* webpackChunkName: "my-results" */ '@/views/my-results/learning/learning-materials.vue'
      ),
  },
  {
    path: '/my-results/kpi',
    name: CustomRoute.TO_MY_RESULTS_KPI,
    meta: {
      title: 'myresults_section_title',
      moduleCode: CorrectName.MY_RESULTS,
      eventOnEnter: 'myresults_kpi_tab_open',
      from: { 'dashboard-widget': 'dashboard_kpi_btn' },
    },
    component: () =>
      import(/* webpackChunkName: "my-results" */ '@/views/my-results/kpi/index.vue'),
  },
  {
    path: '/my-results/kpi/:id-:slug',
    name: CustomRoute.TO_MY_RESULTS_KPI_MATERIAL,
    meta: {
      title: 'myresults_section_title',
      moduleCode: CorrectName.MY_RESULTS,
      eventOnEnter: 'kpi_specific_actions_btn',
    },
    component: () =>
      import(/* webpackChunkName: "my-results" */ '@/views/my-results/kpi/kpi-inner.vue'),
  },
  {
    path: '/my-results/kpi/all',
    name: CustomRoute.TO_MY_RESULTS_KPI_ALL_MATERIALS,
    meta: {
      title: 'myresults_section_title',
      moduleCode: CorrectName.MY_RESULTS,
      eventOnEnter: 'kpi_all_actions_btn',
    },
    component: () =>
      import(/* webpackChunkName: "my-results" */ '@/views/my-results/kpi/kpi-inner.vue'),
  },
  {
    path: '/my-results/certificates',
    name: CustomRoute.TO_MY_RESULTS_CERTIFICATES,
    meta: {
      title: 'myresults_section_title',
      moduleCode: CorrectName.MY_RESULTS,
      eventOnEnter: 'myresults_certificates_tab_open',
    },
    component: () =>
      import(/* webpackChunkName: "my-results" */ '@/views/my-results/certificates/index.vue'),
  },
];
