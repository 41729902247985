export default class LanguageSettings {
  interfaceLang: string;
  sourceLang: string;
  languageList: string[];
  languages: object[];

  constructor(payload: any) {
    this.interfaceLang = payload.ui_lang;
    this.sourceLang = payload.source_lang;
    this.languageList = payload.lang_list;
    this.languages = payload.languages;
  }
}
