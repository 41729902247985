import { each, find } from 'lodash';
import SurveyAnswer from '@/entities/modules/messages/SurveyAnswer';

export default class PostSurvey {
  id: number;
  question: string;
  answers: SurveyAnswer[];
  countVotes: number = 0;
  hasResult: boolean = false;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.question = payload.question;

    this.answers = [];
    each(payload.answers, answer => {
      this.answers.push(new SurveyAnswer(answer));
      this.countVotes += answer.count;
    });

    this.hasResult = !!find(this.answers, answer => answer.isChosen);
  }

  addVote(answerId: number) {
    each(this.answers, answer => {
      if (answer.id === answerId) {
        // eslint-disable-next-line no-param-reassign
        answer.isChosen = true;
        // eslint-disable-next-line no-param-reassign
        answer.count += 1;
      }
    });

    this.countVotes += 1;
    this.hasResult = true;
  }
}
