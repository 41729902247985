import { each, has } from 'lodash';
import { localeDateFromTimestamp } from '@/providers/js/dateProvider';
import { createUserStatus } from '@/entities/modules/events/UserStatus';
import ApiName from '@/entities/common/ApiName';
import { i18n } from '@/lang';
import slugProvider from '@/providers/slugProvider';
import { createMaterialCardWidget } from '../../common/MaterialCardWidget';

export class EmployeeResult {
  constructor({ employee, materials }) {
    this.employee = employee;
    this.materials = materials;
  }
}

export function createEmployeeResult(data) {
  const materials = [];

  const notStarted = [];

  const successed = [];

  const failed = [];

  let tagLabel = '';

  each(data.materials, material => {
    const parts = [];

    let image = null;

    let passingDeadline = null;

    let passingNoticeDeadline = null;

    let hasNotPassedUsers = null;

    let endTime = null;

    if (has(material, 'deadline_for_passing_at')) {
      passingDeadline = material.deadline_for_passing_at;
    }

    if (has(material, 'deadline_for_passing_notice_for_time')) {
      passingNoticeDeadline = material.deadline_for_passing_notice_for_time;
    }

    if (has(material, 'has_not_passed_users')) {
      hasNotPassedUsers = material.has_not_passed_users;
    }

    if (has(material.progress, 'end_time')) {
      endTime = material.progress.end_time;
    }

    const isLearningMaterial = [ApiName.LEARNING_PROGRAM, ApiName.LEARNING_TRACK].includes(
      material.type,
    );

    if (
      isLearningMaterial &&
      material.progress.status === 'fail' &&
      material.progress.percent === 0
    ) {
      parts.push('0%');
    }

    if (isLearningMaterial && material.progress.percent > 0 && material.progress.percent !== 100) {
      parts.push(`${material.progress.percent} %`);
    }

    switch (material.type) {
      case ApiName.INTERVIEW_MODULE:
        tagLabel = i18n.t('common_survey_lowcase_text');

        break;

      case ApiName.TEST_MODULE:
        tagLabel = i18n.t('common_test_lowcase_text');

        break;

      case ApiName.LEARNING_PROGRAM:
        tagLabel = i18n.t('myteam_learning_journey_title_text');

        break;

      case ApiName.LEARNING_TRACK:
        tagLabel = i18n.t('common_trajectories_default_text');

        break;

      case ApiName.EVENT_MODULE:
        tagLabel = i18n.t('common_event_lowcase_text');

        break;

      default:
        break;
    }

    if (has(material, 'image_wide')) {
      image = material.image_wide.xlg.url;
    } else if (has(material, 'image')) {
      image = material.image.xlg.url;
    }

    if (material.progress.status === 'not_started') {
      notStarted.push(
        createMaterialCardWidget({
          id: material.id,
          moduleId: parseInt(material.module_id, 10),
          moduleName: slugProvider(material.module_name),
          name: material.name,
          image,
          tagLabel,
          isInProgress: isLearningMaterial && material.progress.percent > 0,
          isPassed: false,
          isFailed: false,
          isAssigned: true,
          isChecking:
            material.type === 'tests'
              ? parseInt(material.progress.test.is_checking, 10) === 1
              : null,
          to: {},
          type: material.type,
          parts,
          eventStatus:
            material.type === 'events'
              ? createUserStatus(material.progress.event.user_status)
              : null,
          passingDeadline,
          passingNoticeDeadline,
          hasNotPassedUsers,
        }),
      );
    }

    if (material.progress.status === 'success') {
      if (has(material.progress, 'interview')) {
        if (material.progress.interview.complete_date) {
          parts.push(localeDateFromTimestamp(material.progress.interview.complete_date));
        }
      }

      if (has(material.progress, 'test')) {
        if (material.progress.test.complete_date) {
          parts.push(localeDateFromTimestamp(material.progress.test.complete_date));
        }

        // проверяем есть ли еще попытки прохождения у материала
        if (material.progress.test.current_turn !== material.progress.test.turns_count) {
          const count = material.progress.test.turns_count - material.progress.test.current_turn;
          parts.push(['learn_d_attempts_more_text', count, { 0: count }]);
        }
      }

      successed.push(
        createMaterialCardWidget({
          id: material.id,
          moduleId: parseInt(material.module_id, 10),
          moduleName: slugProvider(material.module_name),
          name: material.name,
          image,
          tagLabel,
          isPassed: true,
          isFailed: false,
          isAssigned: false,
          to: {},
          type: material.type,
          parts,
          showDetails: material.type !== 'interviews' || parseInt(material.show_details, 10) === 1,
          eventStatus:
            material.type === 'events'
              ? createUserStatus(material.progress.event.user_status)
              : null,
          passingDeadline,
          passingNoticeDeadline,
          hasNotPassedUsers,
          endTime,
        }),
      );
    }

    if (material.progress.status === 'fail') {
      if (material.type === 'tests') {
        // проверяем есть ли дата у материала
        if (material.progress.test.complete_date) {
          parts.push(localeDateFromTimestamp(material.progress.test.complete_date));
        }

        // проверяем есть ли еще попытки прохождения у материала
        if (material.progress.test.current_turn !== material.progress.test.turns_count) {
          const count = material.progress.test.turns_count - material.progress.test.current_turn;
          parts.push(['learn_d_attempts_more_text', count, { 0: count }]);
        }
      }

      failed.push(
        createMaterialCardWidget({
          id: material.id,
          moduleId: parseInt(material.module_id, 10),
          moduleName: slugProvider(material.module_name),
          name: material.name,
          image,
          tagLabel,
          isPassed: false,
          isFailed: material.type !== 'events',
          isAssigned: false,
          isSkipped: material.type === 'events' ? true : null,
          to: {},
          type: material.type,
          parts,
          eventStatus:
            material.type === 'events'
              ? createUserStatus(material.progress.event.user_status)
              : null,
          passingDeadline,
          passingNoticeDeadline,
          hasNotPassedUsers,
          endTime,
        }),
      );
    }
  });

  materials.push({
    lokaliseTitle: 'learn_filter_assigned_text',
    sort: 1,
    materials: notStarted,
    status: 'assigned',
  });

  materials.push({
    lokaliseTitle: 'learn_filter_failed_text',
    sort: 2,
    materials: failed,
    status: 'failed',
  });

  materials.push({
    lokaliseTitle: 'learn_filter_passed_text',
    sort: 3,
    materials: successed,
    status: 'passed',
  });

  return Object.freeze(
    new EmployeeResult({
      employee: data.user,
      materials,
    }),
  );
}
