import { filter } from 'lodash';
import ImageSource from '@/entities/common/sources/ImageSource';
import UserComment from '@/entities/modules/messages/UserComment';
import PostOwner from '@/entities/modules/messages/PostOwner';
import ICommentable from '@/entities/common/interfaces/ICommentable';
import Article from '@/entities/modules/messages/Article';

export default abstract class Post extends Article implements ICommentable {
  id: number;

  owner?: PostOwner;

  image?: ImageSource;
  text: string = '';

  isNew: boolean = false;
  order: number = 0;
  createdAt?: Date;

  isCommentingAllowed: boolean = false;
  comments: number = 0;
  commentsList: UserComment[] = [];
  isCommented: boolean = false;
  newComments: number = 0;

  likes: number = 0;
  isLiked: boolean = false;

  hasViews: boolean = false;
  views: number = 0;

  isEditingAllowed: boolean = false;

  protected constructor(payload: any) {
    super(payload);
    this.id = parseInt(payload.id, 10);
  }

  abstract isLastPageComments(): boolean;
  abstract isFirstPageComments(): boolean;
  abstract markCommentsAsRead(): void;

  setCommentsList(comments: UserComment[], total: number) {
    this.commentsList = comments;
    this.comments = total;
  }

  toggleLike() {
    if (this.isLiked) {
      this.likes -= 1;
      this.isLiked = false;
    } else {
      this.likes += 1;
      this.isLiked = true;
    }
  }

  updateLikeStatus(isLiked: boolean, likes: number) {
    this.isLiked = isLiked;
    this.likes = likes;
  }

  markAsCommented() {
    this.isCommented = true;
  }

  markPostAsRead() {
    this.views += 1;
  }

  getUnreadComments(): UserComment[] {
    return filter(this.commentsList, comment => comment.isNew);
  }

  getReadComments(): UserComment[] {
    return filter(this.commentsList, comment => !comment.isNew);
  }

  hasImage() {
    return !!this.image;
  }

  setIsCommentingAllowed(isCommentingAllowed: boolean) {
    this.isCommentingAllowed = isCommentingAllowed;
  }
}
