import { each } from 'lodash';
import BaseAuthType from '@/entities/modules/settings/auth-types/BaseAuthType';
import BaseExternalAuthType from '@/entities/modules/settings/auth-types/BaseExternalAuthType';
import OAuth2AuthType from '@/entities/modules/settings/auth-types/OAuth2AuthType';
import BlitzIdentityAuthType from '@/entities/modules/settings/auth-types/BlitzIdentityAuthType';
import IdentityServerAuthType from '@/entities/modules/settings/auth-types/IdentityServerAuthType';
import { i18n } from '@/lang';

export default class IntegrationAuthType extends BaseAuthType {
  services: BaseExternalAuthType[] = [];

  constructor(payload: any) {
    super(payload);

    each(payload.services, service => {
      switch (service.type) {
        case 'oauth2':
          this.services.push(new OAuth2AuthType(service));
          break;
        case 'blitz_identity':
          this.services.push(new BlitzIdentityAuthType(service));
          break;
        case 'identity_server':
          this.services.push(new IdentityServerAuthType(service));
          break;
        default:
          this.services.push(new BaseExternalAuthType(service));
          break;
      }
    });
  }

  getName() {
    return this.name === '' ? i18n.t('auth_via_third_party_text').toString() : this.name;
  }

  getDescription() {
    return this.description === ''
      ? i18n.t('auth_select_auth_service_hint_text').toString()
      : this.description;
  }
}
