import { Module } from 'vuex';
import { RootState } from '../../types';
import { EventsState } from './types';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

export const state: EventsState = {
  listEvents: [],
  listPlans: [],
};

const namespaced: boolean = true;

export const events: Module<EventsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
