<template>
  <div class="app-text">
    <div class="app-text__wrapper">
      <input
        :id="'input_' + componentId"
        ref="saveFocus"
        :type="computedType"
        :class="classesForInput"
        :readonly="isReadOnly"
        :disabled="isDisabled"
        :placeholder="placeholder"
        :required="isRequired ? 'required' : false"
        :maxlength="maxLength ? maxLength : false"
        :autocomplete="isPassword ? 'on' : 'off'"
        :value="value"
        @click.stop="showOption"
        @input="$emit('input', $event.target.value)"
        @change="$emit('onChange', $event)"
        @focus="onFocus"
        @blur="onBlur"
      />

      <label
        v-if="labelText"
        :class="classesForLabel"
        :for="'input_' + componentId"
        class="app-text__label"
      >
        {{ labelText }}
      </label>

      <i
        v-if="isPassword"
        :class="{ 'app-text__show-password--colored': isShowingPassword }"
        class="app-text__show-password"
        @click="togglePassword"
      >
        <EyeSvg />
      </i>

      <i
        v-if="hasSlot"
        class="app-text__icon"
        @click.stop="showOption"
      >
        <slot></slot>
      </i>
    </div>
    <div
      v-if="error && errorShowLabel"
      class="app-text__error"
    >
      {{ error }}
    </div>
  </div>
</template>

<script>
import EyeSvg from '@/components/svg/icons/eye-empty.vue';

export default {
  name: 'form-input',

  components: {
    EyeSvg,
  },

  props: {
    value: {
      type: null,
      default: null,
    },

    labelText: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },

    isFullwidth: Boolean,

    isRequired: {
      type: Boolean,
      default: false,
    },

    isReadOnly: {
      type: Boolean,
      default: false,
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isPassword: {
      type: Boolean,
      default: false,
    },

    chromeAutofillPasswordDisabled: {
      type: Boolean,
      default: false,
    },

    error: {
      type: String,
      default: null,
    },

    errorShowLabel: {
      type: Boolean,
      default: true,
    },

    maxLength: {
      type: Number,
      default: 0,
    },
  },

  data: () => ({
    componentId: null,

    isFocused: false,

    isShowingPassword: false,

    inputVal: '',
  }),

  computed: {
    isActive() {
      return this.value ? this.value.length > 0 : false;
    },

    computedType() {
      if (this.isPassword) {
        if (!this.isShowingPassword) {
          return 'password';
        }
      }

      return 'text';
    },

    classesForLabel() {
      const classes = [];

      if (this.isActive || this.isFocused) {
        classes.push('app-text__label-active');
      }

      if (this.error) {
        classes.push('app-text__label-error');
      }

      return classes;
    },

    classesForInput() {
      const classes = [];

      classes.push('app-text__input');

      if (!this.labelText) {
        classes.push('app-text__input--without-label');
      }

      if (this.isFullwidth) {
        classes.push('app-text__input-fullwidth');
      }

      if (this.error) {
        classes.push('app-text__input-error');
      }

      return classes;
    },

    hasSlot() {
      return this.$slots.default;
    },
  },

  mounted() {
    this.componentId = this._uid;
  },

  methods: {
    onFocus() {
      if (this.isReadOnly) {
        return;
      }

      this.isFocused = true;

      this.$emit('focus');
    },

    showOption() {
      this.$emit('showOption');
    },

    onBlur() {
      this.isFocused = false;

      this.$emit('blur');
    },

    togglePassword() {
      this.isShowingPassword = !this.isShowingPassword;

      this.$refs.saveFocus.focus();
      setTimeout(() => {
        this.$refs.saveFocus.setSelectionRange(
          this.$refs.saveFocus.value.length,
          this.$refs.saveFocus.value.length,
        );
      }, 0);
    },
  },
};
</script>
