<template>
  <div class="app-profile-head-info">
    <div class="app-profile-head-info__avatar">
      <router-link :to="routeToProfile">
        <div
          v-if="hasAvatar"
          :style="{ 'background-image': 'url(' + user.avatar + ')' }"
          class="app-profile-head-info-avatar__background"
        ></div>
        <UserSvg />
      </router-link>
    </div>
    <div class="app-profile-head-info__about">
      <div class="app-profile-head-info__name-block">
        <div
          class="app-profile-head-info__name"
          @click="openInfoMenu"
        >
          <div class="app-profile-head-info__title">
            {{ userName }}
          </div>
          <i
            class="app-profile-head-info__icon"
            :class="{ 'app-profile-head-info__icon--opened': infoMenuOpened }"
          >
            <ArrowSvg />
          </i>
        </div>
        <ProfileMenu
          v-if="infoMenuOpened"
          v-click-outside="closeInfoMenu"
          :user-place="userPlace"
          :user-points="userPoints"
          class="app-profile-head-info-menu"
        />
      </div>

      <div class="app-profile-head-info__place">
        <router-link
          v-if="userPoints !== null"
          :to="userRatingRoute"
          class="app-profile-head-info-place__text"
        >
          {{ userPoints.toLocaleString() }}
          {{ $tc('common_point_without_number_text', userPoints) }}
        </router-link>
        <router-link
          v-if="userPlace"
          :to="ratingRoute"
          class="app-profile-head-info-place__text"
        >
          {{ $t('common_rating_place_text', [userPlace]) }}
          {{ $t('dashboard_rating_in_rating_text') }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UserSvg from '@/components/svg/icons/user.vue';
import ArrowSvg from '@/components/svg/icons/arrow-category-filter.vue';
import ClickOutside from 'vue-click-outside';
import CustomRoute from '@/entities/common/CustomRoute';
import { routeTo } from '@/providers/routerLinkProvider';
import ProfileMenu from '@/components/app/dashboard/profile-menu.vue';

export default {
  name: 'profile-card',

  directives: {
    ClickOutside,
  },

  components: {
    ProfileMenu,
    UserSvg,
    ArrowSvg,
  },

  props: {
    fromRating: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    infoMenuOpened: false,
  }),

  computed: {
    ...mapGetters('auth', ['user', 'userName']),

    ...mapGetters('dashboard', ['rating', 'personalData']),

    ...mapGetters('rating', ['currentUser']),

    hasAvatar() {
      return this.user && this.user.avatar;
    },

    routeToProfile() {
      return routeTo(CustomRoute.TO_DASHBOARD_PROFILE_INFO);
    },

    userRatingRoute() {
      return {
        name: CustomRoute.TO_RATING_MY_RATING,
        query: {
          from: 'dashboard-widget',
        },
      };
    },

    ratingRoute() {
      return {
        name: CustomRoute.TO_RATING,
        query: {
          from: 'dashboard-widget',
        },
      };
    },

    userPoints() {
      if (this.fromRating && this.currentUserInfo) {
        return this.currentUserInfo.points;
      }

      if (this.rating) {
        return this.rating.points;
      }

      return null;
    },

    userPlace() {
      if (this.fromRating && this.currentUserInfo) {
        return this.currentUserInfo.place;
      }

      if (this.rating) {
        return this.rating.place;
      }

      return null;
    },
  },

  methods: {
    openInfoMenu() {
      this.infoMenuOpened = !this.infoMenuOpened;
    },

    closeInfoMenu(event) {
      let i = 0;

      let element = event.target;

      do {
        if (element.className.toString().includes('app-profile-head-info__name')) {
          return;
        }
        element = element.parentNode;
        i += 1;
      } while (i < 5);

      this.infoMenuOpened = false;
    },
  },
};
</script>
