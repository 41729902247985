// @ts-ignore
import createDate from '@/providers/js/dateProvider';

export default class Kpi {
  id: number;
  name: string;
  order: number;
  totalAmount: number;
  lastAmount: number;
  lastUpdate: string;
  totalAmountUnit: string;
  lastAmountUnit: string;
  isRating: boolean;
  ratingConditions: string;
  totalRatingAmount: number;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.name = payload.name;
    this.order = parseInt(payload.order, 10);
    this.totalAmount = parseFloat(payload.total_amount);
    this.lastAmount = parseFloat(payload.last_amount);
    this.lastUpdate = createDate(payload.last_update);
    this.totalAmountUnit = payload.total_amount_unit;
    this.lastAmountUnit = payload.last_amount_unit;
    this.isRating = parseInt(payload.is_rating, 10) === 1;
    this.ratingConditions = payload.rating_conditions;
    this.totalRatingAmount = parseInt(payload.total_rating_amount, 10);
  }
}
