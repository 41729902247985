import { MutationTree } from 'vuex';
import { TasksState } from './types';

export const mutations: MutationTree<TasksState> = {
  /**
   * Изменит ответы пользователя
   * @param state
   * @param payload
   */
  changeAnswers(state, payload) {
    state.answers = payload;
  },
};
