import BaseWidget from '@/entities/modules/dashboard/BaseWidget';
import ImageSource, { createImageFromStringWithSize } from '@/entities/common/sources/ImageSource';

export default class RatingWidget extends BaseWidget {
  id: number;
  name: string;
  place: number;
  points: number;
  avatar?: ImageSource;

  constructor(payload: any) {
    super(payload);
    this.id = parseInt(payload.data.id, 10);
    this.name = payload.data.name;
    this.place = parseInt(payload.data.place, 10);
    this.points = parseInt(payload.data.points, 10);
    this.avatar = createImageFromStringWithSize(payload.data.avatar.url, payload.data.avatar.size);
  }
}
