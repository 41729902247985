import IStatistic from '@/entities/modules/learning-programs/IStatistic';
import SectionMaterial from '@/entities/modules/learning-programs/SectionMaterial';
import ToggleSectionMaterial from '@/entities/modules/learning-programs/ToggleSectionMaterial';
import MaterialStatus from '@/entities/common/testing/MaterialStatus';
import ImageSource, { createImageSmSource } from '@/entities/common/sources/ImageSource';
import BaseMaterial from '@/entities/modules/learning-programs/BaseMaterial';
import CustomRoute from '@/entities/common/CustomRoute';

export default class MaterialHtml extends BaseMaterial {
  id: number;
  name: string;
  isRating: boolean;
  url: string;
  maxScore: number = 0;
  updatedAtTimestamp: number;
  image?: ImageSource;

  statistic: IStatistic;

  constructor(statistic: IStatistic, payload: any) {
    super();

    this.statistic = statistic;
    this.id = parseInt(payload.id, 10);
    this.name = payload.name;
    this.isRating = payload.is_rating;
    this.url = payload.html.url;
    this.updatedAtTimestamp = payload.updated_at;
    this.image = createImageSmSource(payload.image);
  }

  /**
   * Тип материала
   */
  getMaterialType(): string {
    return 'html';
  }

  /**
   * Виджет материала для страницы просмотра программы обучения (выводится в секциях)
   * @param sectionMaterial
   */
  getToggleWidget(sectionMaterial: SectionMaterial): ToggleSectionMaterial {
    const status = this.statistic.getMaterialStatus();

    return new ToggleSectionMaterial({
      id: this.id,
      type: this.getMaterialType(),
      routerTo: CustomRoute.TO_LEARNING_PROGRAMS_HTML,
      routerTrackTo: CustomRoute.TO_LEARNING_TRACK_PROGRAMS_HTML,
      name: this.name,
      image: this.image,

      lokaliseTagLabel: 'learn_course_interactive_presentation_text',
      buttonLabel: null,
      lokaliseButtonLabel:
        status === MaterialStatus.ASSIGNED
          ? 'learn_course_watch_button'
          : 'learn_course_watch_again_button',
      isButtonOutlined: status !== MaterialStatus.ASSIGNED,

      numberingTypeThrough: this.numberingTypeThrough,
      numberingTypeSections: this.numberingTypeSections,
      numberingTypeHierarchical: this.numberingTypeHierarchical,

      isRating: this.isRating,
      isRequired: sectionMaterial.isRequired,
      isLocked: sectionMaterial.isLocked,

      isPassed: status === MaterialStatus.PASSED,
      isFailed: status === MaterialStatus.FAILED,

      parts: [],
    });
  }

  getSaving(): any {
    return {
      id: this.id,
      type: this.getMaterialType(),
      viewed_time: Math.round((new Date() as any) / 1000),
    };
  }
}
