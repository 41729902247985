import QuestionTask from '@/entities/common/testing/QuestionTask';
import { each } from 'lodash';
import Field from '@/entities/modules/tasks/Field';

export default class MultiplyField extends Field {
  isMultiply: boolean;
  allowedComment: boolean;
  showDropDown: boolean;

  constructor(payload: any) {
    super(payload);

    each(payload.options, option => {
      this.options.push(new QuestionTask(option));
    });
    this.allowedComment = parseInt(payload.is_comment_allowed, 10) === 1;
    this.isMultiply = parseInt(payload.is_multiple_choice_allowed, 10) === 1;
    this.showDropDown = parseInt(payload.display_as_dropdown, 10) === 1;
  }

  setComment(text: string): void {
    this.comment = text;
  }

  getSaving(): object {
    const answer: any = {};

    const selected: number[] = [];
    each(this.options, (answerTask: QuestionTask) => {
      if (answerTask.selected) {
        selected.push(answerTask.id);
      }
    });

    if (selected.length > 0) {
      answer.id = this.id;
      answer.option_ids = selected;
      answer.comment = this.comment;
    }

    return answer;
  }
}
