export default class KpiEntity {
  name: string;
  totalAmount: number;
  unit: string;
  id: number;
  isRating: boolean;
  ratingConditions: string;
  totalRatingAmount: number;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.name = payload.name;
    this.totalAmount = parseFloat(payload.total_amount);
    this.unit = payload.unit;
    this.isRating = parseInt(payload.is_rating, 10) === 1;
    this.ratingConditions = payload.rating_conditions;
    this.totalRatingAmount = parseInt(payload.total_rating_amount, 10);
  }
}
