import { isString } from 'lodash';

// eslint-disable-next-line no-shadow
enum SupportedFileType {
  PDF = 'pdf',
  TXT = 'txt',
  DOC = 'doc',
  DOCX = 'docx',

  XLS = 'xls',
  XLSX = 'xlsx',

  PPT = 'ppt',
  PPS = 'pps',
  PPTX = 'pptx',
  PPSX = 'ppsx',

  JPG = 'jpg',
  JPEG = 'jpeg',
  PNG = 'png',
}

export default SupportedFileType;

export function getFileExtension(file: any): string | undefined {
  if (!isString(file)) {
    return;
  }

  return file.split('.').pop();
}

export function isSupportedFileType(file: any): boolean {
  const extension = file.split('.').pop();

  if (!extension) {
    return false;
  }

  return Object.keys(SupportedFileType).includes(extension);
}

export function getSupportedFileTypeByFile(file: any): SupportedFileType | undefined {
  switch (getFileExtension(file)) {
    case 'pdf':
      return SupportedFileType.PDF;
    case 'txt':
      return SupportedFileType.TXT;
    case 'doc':
      return SupportedFileType.DOC;
    case 'docx':
      return SupportedFileType.DOCX;

    case 'xls':
      return SupportedFileType.XLS;
    case 'xlsx':
      return SupportedFileType.XLSX;

    case 'ppt':
      return SupportedFileType.PPT;
    case 'pps':
      return SupportedFileType.PPS;
    case 'pptx':
      return SupportedFileType.PPTX;
    case 'ppsx':
      return SupportedFileType.PPSX;

    case 'jpg':
      return SupportedFileType.JPG;
    case 'jpeg':
      return SupportedFileType.JPEG;
    case 'png':
      return SupportedFileType.PNG;
    default:
      break;
  }
}

export function getDocumentTypeByFile(file: any): string {
  switch (getSupportedFileTypeByFile(file)) {
    case SupportedFileType.PDF:
    case SupportedFileType.TXT:
    case SupportedFileType.DOC:
    case SupportedFileType.DOCX:
      return 'document';

    case SupportedFileType.XLS:
    case SupportedFileType.XLSX:
      return 'sheet';

    case SupportedFileType.PPT:
    case SupportedFileType.PPS:
    case SupportedFileType.PPTX:
    case SupportedFileType.PPSX:
      return 'presentation';

    case SupportedFileType.JPG:
    case SupportedFileType.JPEG:
    case SupportedFileType.PNG:
      return 'image';

    default:
      return 'file';
  }
}
