import { GetterTree } from 'vuex';
import { DashboardState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<DashboardState, RootState> = {
  banner: state => state.banner,
  nextMaterial: state => state.nextMaterial,
  personalData: state => state.personalData,
  rating: state => state.rating,
  widgets: state => state.widgets,
};
