import { each } from 'lodash';
import type { IUserDefaultValueFilter } from '@/types/my-team/filters';
import UserFilterType from '@/entities/modules/my-team/filters/UserFilterType';

export default class UserValueFilter implements IUserDefaultValueFilter {
  isRequired: boolean;
  modules: (number | string)[] = [];
  deadline: string[];
  sort: string;
  countDays: number;
  types: string[] = [];

  constructor(payload: any, filterType: string) {
    this.isRequired = parseInt(payload.is_required, 10) === 1;

    if (payload.module_ids) {
      each(payload.module_ids, moduleId => {
        this.modules.push(parseInt(moduleId, 10));
      });
    }

    if (payload.types) {
      this.modules.push(...payload.types);

      if (filterType === UserFilterType.TEAM_MATERIALS_LEARNING_MATERIALS) {
        this.types.push(...payload.types);
      }
    }
    this.deadline = payload.deadline;
    this.sort = payload.order;
    this.countDays = payload.count_days ? parseInt(payload.count_days, 10) : 0;
  }
}
