import each from 'lodash/each';
import IStatistic from '@/entities/modules/learning-programs/IStatistic';
import MaterialPdfStatistic from '@/entities/modules/learning-programs/MaterialPdfStatistic';
import MaterialScormStatistic from '@/entities/modules/learning-programs/MaterialScormStatistic';
import MaterialLinkStatistic from '@/entities/modules/learning-programs/MaterialLinkStatistic';
import MaterialHtmlStatistic from '@/entities/modules/learning-programs/MaterialHtmlStatistic';
import MaterialTestStatistic from '@/entities/modules/learning-programs/MaterialTestStatistic';
import MaterialVideoStatistic from '@/entities/modules/learning-programs/MaterialVideoStatistic';
import MaterialLongreadStatistic from '@/entities/modules/learning-programs/MaterialLongreadStatistic';
import MaterialDocumentStatistic from '@/entities/modules/learning-programs/MaterialDocumentStatistic';
import UpdateItem from '@/entities/modules/learning-programs/UpdateItem';
import MaterialUnknownStatistic from '@/entities/modules/learning-programs/MaterialUnknownStatistic';
import Pagination from '../../common/Pagination';

/**
 * Вернет массив статистик
 * @param payload
 */
export function createStatisticsByMaterialsList(payload: any): [Pagination, IStatistic[]] {
  const pagination = new Pagination(payload.meta.pagination);

  const statistics: IStatistic[] = [];
  each(payload.materials, statistic => {
    switch (statistic.type) {
      case 'pdf':
        statistics.push(new MaterialPdfStatistic(statistic));
        break;

      case 'link':
        statistics.push(new MaterialLinkStatistic(statistic));
        break;

      case 'scorm':
        statistics.push(new MaterialScormStatistic(statistic));
        break;

      case 'html':
        statistics.push(new MaterialHtmlStatistic(statistic));
        break;

      case 'test':
        statistics.push(new MaterialTestStatistic(statistic));
        break;

      case 'video':
        statistics.push(new MaterialVideoStatistic(statistic));
        break;

      case 'longread':
        statistics.push(new MaterialLongreadStatistic(statistic));
        break;

      case 'document':
        statistics.push(new MaterialDocumentStatistic(statistic));
        break;

      default:
        statistics.push(new MaterialUnknownStatistic(statistic));
    }
  });

  return [pagination, statistics];
}

/**
 * Вернет объект статистики
 * @param payload
 */
export function createStatistic(payload: any): IStatistic | null {
  const statistic = payload.materials.pop();

  if (statistic) {
    switch (statistic.type) {
      case 'pdf':
        return new MaterialPdfStatistic(statistic);

      case 'link':
        return new MaterialLinkStatistic(statistic);

      case 'scorm':
        return new MaterialScormStatistic(statistic);

      case 'html':
        return new MaterialHtmlStatistic(statistic);

      case 'test':
        return new MaterialTestStatistic(statistic);

      case 'video':
        return new MaterialVideoStatistic(statistic);

      case 'longread':
        return new MaterialLongreadStatistic(statistic);

      case 'document':
        return new MaterialDocumentStatistic(statistic);

      default:
        return new MaterialUnknownStatistic(statistic);
    }
  }

  return null;
}

/**
 * Вернет массив статистик
 * @param payload
 */
export function createMaterialStatisticByUpdatesList(payload: any): [Pagination, UpdateItem[]] {
  const pagination = new Pagination(payload.meta.pagination);

  const statisticsUpdates: UpdateItem[] = [];
  each(payload.materials, statistic => {
    statisticsUpdates.push(new UpdateItem(statistic));
  });

  return [pagination, statisticsUpdates];
}

/**
 * Вернет массив статистик
 * @param payload
 */
export function createProgramStatisticByUpdatesList(payload: any): [Pagination, UpdateItem[]] {
  const pagination = new Pagination(payload.meta.pagination);

  const statisticsUpdates: UpdateItem[] = [];
  each(payload.learning_programs, statistic => {
    statisticsUpdates.push(new UpdateItem(statistic));
  });

  return [pagination, statisticsUpdates];
}
