<template>
  <div class="app-profile-card-mobile">
    <button
      v-click-outside="closeProfileMenu"
      class="app-profile-card-mobile__avatar"
      :class="{ 'app-profile-card-mobile__avatar--active': isOpenedProfileMenu }"
      @click="toggleProfileMenu"
    >
      <img
        v-if="hasAvatar"
        :src="user.avatar"
        :alt="userName"
        class="app-profile-card-mobile__image"
      />
      <AvatarSvg v-else />

      <transition
        enter-active-class="animated fadeIn"
        leave-active-class="animated fadeOut"
      >
        <ProfileMenu
          v-if="isOpenedProfileMenu"
          :user-points="userPoints"
          :user-place="userPlace"
          class="app-profile-card-mobile__dropdown-menu app-profile-card-mobile-dropdown-menu"
        />
      </transition>
    </button>
  </div>
</template>

<script>
import AvatarSvg from '@/components/svg/icons/avatar.vue';
import { mapGetters } from 'vuex';
import ClickOutside from 'vue-click-outside';
import ProfileMenu from '@/components/app/dashboard/profile-menu.vue';

export default {
  name: 'profile-card-mobile',

  components: {
    ProfileMenu,
    AvatarSvg,
  },

  directives: {
    ClickOutside,
  },

  data() {
    return {
      isOpenedProfileMenu: false,
    };
  },

  computed: {
    ...mapGetters('auth', ['user', 'userName']),

    ...mapGetters('dashboard', ['rating']),

    ...mapGetters('rating', ['currentUser']),

    hasAvatar() {
      return this.user && this.user.avatar;
    },

    userPoints() {
      if (this.fromRating && this.currentUser) {
        return this.currentUser.points;
      }

      if (this.rating) {
        return this.rating.points;
      }

      return null;
    },

    userPlace() {
      if (this.fromRating && this.currentUser) {
        return this.currentUser.place;
      }

      if (this.rating) {
        return this.rating.place;
      }

      return null;
    },
  },

  methods: {
    toggleProfileMenu() {
      this.isOpenedProfileMenu = !this.isOpenedProfileMenu;
    },

    closeProfileMenu() {
      this.isOpenedProfileMenu = false;
    },
  },
};
</script>
