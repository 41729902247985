import IRoute from '@/entities/common/interfaces/routes/IRoute';

export default interface IRouteMaterial extends IRoute {
  id: number;
  slug: string;
}

export function isIRouteMaterial(x: any): x is IRouteMaterial {
  return x.id && x.slug;
}
