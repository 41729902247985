import CustomRoute from '@/entities/common/CustomRoute';

export default [
  {
    path: '/login',
    name: CustomRoute.TO_AUTH_LOGIN,
    meta: {
      title: 'auth_tab_name_authorization_web',
      showBlockedMessages: false,
    },
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/login.vue'),
  },
  {
    path: '/login/phone',
    name: CustomRoute.TO_AUTH_LOGIN_PHONE,
    meta: {
      title: 'auth_tab_name_authorization_web',
      showBlockedMessages: false,
    },
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/phone-login.vue'),
  },
  {
    path: '/auth',
    name: CustomRoute.TO_AUTH_LOGIN_OAUTH,
    meta: {
      title: 'auth_tab_name_authorization_web',
      showBlockedMessages: false,
    },
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/login.vue'),
  },
  {
    path: '/entrypoint',
    name: CustomRoute.TO_AUTH_ENTRY_POINT,
    meta: {
      title: 'auth_tab_name_authorization_web',
      showBlockedMessages: false,
    },
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/entry-point.vue'),
  },
  {
    path: '/register',
    name: CustomRoute.TO_AUTH_REGISTER,
    meta: {
      title: 'auth_tab_name_authorization_web',
      showBlockedMessages: false,
    },
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/register.vue'),
  },
  {
    path: '/password/reset',
    name: CustomRoute.TO_AUTH_PASSWORD_RESET,
    meta: {
      title: 'auth_recovery_pass_screen_title_text',
      showBlockedMessages: false,
    },
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/password/reset.vue'),
  },
  {
    path: '/password/reset/by',
    name: CustomRoute.TO_AUTH_PASSWORD_RESET_BY,
    meta: {
      title: 'auth_recovery_pass_screen_title_text',
      showBlockedMessages: false,
    },
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/password/reset-by.vue'),
    props: (route: any) => ({ ...route.params }),
  },
  {
    path: '/password/reset/edit',
    name: CustomRoute.TO_AUTH_PASSWORD_RESET_EDIT,
    meta: {
      title: 'auth_recovery_pass_screen_title_text',
      showBlockedMessages: false,
    },
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/password/reset-edit.vue'),
  },
  {
    path: '/verification',
    name: CustomRoute.TO_AUTH_VERIFICATION,
    meta: {
      title: 'auth_info_user_screen_title_info_text',
      showBlockedMessages: false,
    },
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/verification/index.vue'),
  },
  {
    path: '/verification/phone',
    name: CustomRoute.TO_AUTH_VERIFICATION_PHONE,
    meta: {
      title: 'auth_info_user_screen_title_info_text',
      showBlockedMessages: false,
    },
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/verification/phone.vue'),
  },
  {
    path: '/verification/phone/verify',
    name: CustomRoute.TO_AUTH_VERIFICATION_PHONE_VERIFY,
    meta: {
      title: 'auth_info_user_screen_title_info_text',
      showBlockedMessages: false,
    },
    component: () =>
      import(/* webpackChunkName: "auth" */ '@/views/auth/verification/phone-verify.vue'),
  },
  {
    path: '/verification/mail',
    name: CustomRoute.TO_AUTH_VERIFICATION_EMAIL,
    meta: {
      title: 'auth_info_user_screen_title_info_text',
      showBlockedMessages: false,
    },
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/verification/mail.vue'),
  },
  {
    path: '/verification/mail/verify',
    name: CustomRoute.TO_AUTH_VERIFICATION_EMAIL_VERIFY,
    meta: {
      title: 'auth_info_user_screen_title_info_text',
      showBlockedMessages: false,
    },
    component: () =>
      import(/* webpackChunkName: "auth" */ '@/views/auth/verification/mail-verify.vue'),
  },
  {
    path: '/verification/form/:id',
    name: CustomRoute.TO_AUTH_VERIFICATION_FORM,
    meta: {
      title: 'auth_info_user_screen_title_info_text',
      showBlockedMessages: false,
    },
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/verification/form.vue'),
  },
  {
    path: '/verification/password',
    name: CustomRoute.TO_AUTH_VERIFICATION_PASSWORD,
    meta: {
      title: 'auth_info_user_screen_title_info_text',
      showBlockedMessages: false,
    },
    component: () =>
      import(/* webpackChunkName: "auth" */ '@/views/auth/verification/password.vue'),
  },
  {
    path: '/license',
    name: CustomRoute.TO_AUTH_LICENSE,
    meta: {
      title: 'auth_info_user_screen_title_info_text',
      showBlockedMessages: false,
    },
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/verification/license.vue'),
  },
];
