// eslint-disable-next-line import/no-cycle
import store from '@/store/index';

export default function checkFrame() {
  if (
    store.getters['settings/globalSettings'] &&
    store.getters['settings/globalSettings'].webFrameEnabled
  ) {
    return true;
  }

  let isFramed = false;

  // if (!top?.document || !top.location) {
  //   console.warn('Can not find top.document or top.location');

  //   return;
  // }

  try {
    // @ts-ignore
    isFramed = window !== window.top || document !== top.document || self.location !== top.location;
  } catch (e) {
    isFramed = true;
  }

  return isFramed;
}
