import has from 'lodash/has';

export default class ImageSource {
  thumb: string;
  origin?: string;
  size?: number;

  constructor(thumb: string, origin?: string, size?: number) {
    this.origin = origin;
    this.thumb = thumb;
    this.size = size;
  }
}

/**
 * Создаст объект Image из url
 * @param url
 */
export function createImageFromString(url: any) {
  if (url) {
    return new ImageSource(url, url);
  }
}

/**
 * Создаст объект Image из url
 * @param url
 * @param size
 */
export function createImageFromStringWithSize(url: any, size: any) {
  if (url) {
    return new ImageSource(url, url, parseInt(size, 10));
  }
}

/**
 * Создаст объект Image из origin и sm
 * @param payload
 */
export function createImageSmSource(payload: any): ImageSource | undefined {
  if (!has(payload, 'origin.url') || (has(payload, 'origin.url') && !payload.origin.url)) {
    return;
  }

  if (has(payload, 'sm.url') && !!payload.sm.url) {
    return new ImageSource(payload.sm.url, payload.origin.url);
  }
}

/**
 * Создаст объект Image из origin и md
 * @param payload
 */
export function createImageMdSource(payload: any): ImageSource | undefined {
  if (!has(payload, 'origin.url') || (has(payload, 'origin.url') && !payload.origin.url)) {
    return;
  }

  if (has(payload, 'md.url') && !!payload.md.url) {
    return new ImageSource(payload.md.url, payload.origin.url);
  }
}

export function createOnlyImageMdSource(payload: any): ImageSource | undefined {
  if (!has(payload, 'md.url') || (has(payload, 'md.url') && !payload.md.url)) {
    return;
  }

  if (has(payload, 'md.url') && !!payload.md.url) {
    return new ImageSource(payload.md.url);
  }
}

export function createOnlyImageSiteSource(payload: any): ImageSource | undefined {
  if (!has(payload, 'site.url') || (has(payload, 'site.url') && !payload.site.url)) {
    return;
  }

  if (has(payload, 'site.url') && !!payload.site.url) {
    return new ImageSource(payload.site.url);
  }
}

/**
 * Создаст объект Image из origin и lg
 * @param payload
 */
export function createImageLgSource(payload: any): ImageSource | undefined {
  if (!has(payload, 'origin.url') || (has(payload, 'origin.url') && !payload.origin.url)) {
    return;
  }

  if (has(payload, 'lg.url') && !!payload.lg.url) {
    return new ImageSource(payload.lg.url, payload.origin.url);
  }
}
