import axios from 'axios';
import Category from '@/entities/modules/information/Category';
import Material from '@/entities/modules/information/Material';
import Pagination from '@/entities/common/Pagination';

export default {
  async getInformationCatalogs(catalogId: number, page: number) {
    const payload = await axios.post(`/info/catalogs?page=${page}&per_page=20`, {
      parent_id: catalogId,
    });

    const categories: Category[] = [];

    const pagination = new Pagination(payload.data.success.meta.pagination);

    payload.data.success.catalogs.forEach((category: any) => {
      categories.push(new Category(category));
    });

    return {
      categories,
      pagination,
    };
  },

  async getInformationMaterials(catalogId: number, page: number) {
    const payload = await axios.post(`/info/materials?page=${page}&per_page=20`, {
      subcategory_ids: [catalogId],
    });

    const materials: Material[] = [];

    const pagination = new Pagination(payload.data.success.meta.pagination);

    payload.data.success.materials.forEach((material: any) =>
      materials.push(new Material(material)),
    );

    return {
      materials,
      pagination,
    };
  },

  async getInformationMaterial(id: number) {
    try {
      const payload = await axios.get(`/info/materials/${id}`);

      return new Material(payload.data.success.material);
    } catch {
      return null;
    }
  },

  async getInformationCatalog(id: number) {
    const payload = await axios.get(`/info/catalogs/${id}`);

    return new Category(payload.data.success.catalog);
  },

  async getInformationBreadcrumbs(type: string, id: number) {
    const payload = await axios.post('/info/path', {
      entity_type: type,
      entity_id: id,
    });

    return payload.data.success.path;
  },

  updateViewMaterial(material: Category | Material) {
    return axios.post('/info/views', material);
  },

  markAsRead(id: number, type: string) {
    let data;

    if (type === 'material') {
      data = {
        materials: [id],
      };
    } else {
      data = {
        html_categories: [id],
      };
    }

    return axios.post('/info/clear-is-new', data);
  },

  sendViewStatistic(id: number, categoryId: number, viewDate: number) {
    return axios.post('/info/views', {
      materials: [{ id, view_date: viewDate }],
      categories: [{ id: categoryId, view_date: viewDate }],
    });
  },
};
