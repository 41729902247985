import { MutationTree } from 'vuex';
import { find, findIndex, sortBy } from 'lodash';
import { LearningProgramsState } from './types';

export const mutations: MutationTree<LearningProgramsState> = {
  /**
   * Изменит айди текущего модуля
   * @param state
   * @param moduleId
   */
  changeModuleId(state, moduleId) {
    state.currentModuleId = moduleId;
  },

  resetModuleId(state) {
    state.currentModuleId = 0;
  },

  /**
   * Запишет отсортированные программы для определенного модуля
   */
  changePrograms(state, { moduleId, programs }) {
    state.programs.set(moduleId, sortBy(programs, ['order']));
  },

  /**
   * Изменит активный тег
   * @param state
   * @param payload
   */
  changeSelectedTag(state, payload) {
    state.selectedTag = payload;
  },

  /**
   * Изменит категорию
   * @param state
   * @param payload
   */
  changeSelectedCategory(state, payload) {
    state.selectedCategory = payload;
  },

  /**
   * Изменит фильтр
   * @param state
   * @param payload
   */
  changeRequiredFilter(state, payload) {
    state.selectedRequiredFilter = payload;
  },

  changeStatusFilter(state, payload) {
    state.selectedStatusFilter = payload;
  },

  changeSortFilter(state, payload) {
    state.selectedSortFilter = payload;
  },

  changeDeadlineFilter(state, payload) {
    state.selectedDeadlineFilter = payload;
  },

  setProgram(state, { moduleId, program }) {
    const modulePrograms = state.programs.get(moduleId);

    if (modulePrograms) {
      const index = findIndex(modulePrograms, programItem => programItem.id === program.id);

      if (index === -1) {
        modulePrograms.push(program);
      } else {
        modulePrograms.splice(index, 1);
        modulePrograms.push(program);
      }
    }
  },

  setProgramMaterials(state, { moduleId, programId, materials }) {
    const program = find(state.programs.get(moduleId), programItem => programItem.id === programId);

    if (program) {
      program.setMaterials(materials);
    }
  },
};
