<template>
  <div
    class="app-tooltip"
    :class="classes"
    v-on="$listeners"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'app-tooltip',

  props: {
    isFixed: {
      type: Boolean,
      default: false,
    },

    position: {
      type: String,
      default: 'top',
      validate: val => ['top', 'left', 'bottom', 'right'].some(item => item === val),
    },
  },

  computed: {
    classes() {
      const classes = [`app-tooltip--${this.position}`];

      if (this.isFixed) {
        classes.push('app-tooltip--fixed');
      }

      return classes;
    },
  },
};
</script>
