// eslint-disable-next-line import/no-cycle
import { RatingState } from '@/store/modules/rating/types';
import { Module } from 'vuex';
import { RootState } from '@/store/types';
// eslint-disable-next-line import/no-cycle
import { getters } from './getters';
// eslint-disable-next-line import/no-cycle
import { actions } from './actions';
// eslint-disable-next-line import/no-cycle
import { mutations } from './mutations';

export const state: RatingState = {
  usersList: [],
  badges: [],
  myBadges: [],
  currentUser: null,
  rating: null,
  myBadgesCategories: [],
  usersMeta: null,
  materialResultSections: [],
  badgesCategory: [],
  kpi: [],
};

const namespaced: boolean = true;

export const rating: Module<RatingState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
