import axios from 'axios';
import { each } from 'lodash';
import { resolver } from '@/providers/promiseReturnerProvider';
// eslint-disable-next-line import/no-cycle
import Details from '@/entities/modules/rating/Details';
import UserInfoAndMeta from '@/entities/modules/rating/UserInfoAndMeta';
import Rating from '@/entities/modules/rating/Rating';
// eslint-disable-next-line import/no-cycle
import BadgeInfo from '@/entities/modules/rating/BadgeInfo';

export default {
  async getUsersList(ratingId: number, limit: number, offset: number): Promise<UserInfoAndMeta> {
    const payload = await axios.get(
      `/achievements/ratings/${ratingId}/users?${limit !== null ? `&limit=${limit}` : ''}${
        offset !== null ? `&offset=${offset}` : ''
      }`,
    );

    return resolver(new UserInfoAndMeta(payload));
  },

  async getRatingCategories(): Promise<Rating[]> {
    const payload = await axios.get('/achievements/ratings');

    const items: Rating[] = [];

    each(payload.data.success.ratings, rating => {
      items.push(new Rating(rating));
    });

    return items;
  },

  async getUser(ratingId: number, userId: number, isMyRating: boolean): Promise<Details> {
    const payload = await axios.get(`/achievements/rating/${ratingId}/user/${userId}/details`);

    return new Details(payload, isMyRating);
  },

  async getMyBadges(): Promise<BadgeInfo> {
    const payload = await axios.get('/achievements/badges');

    return new BadgeInfo(payload);
  },

  async getTerms(ratingId: number): Promise<String> {
    const payload = await axios.get(`/achievements/ratings/${ratingId}/conditions`);

    return payload.data.success.conditions;
  },

  async clearIsNew(id: number) {
    return axios.post('/achievements/badges/clear-is-new', {
      badge_assignments: [id],
    });
  },
};
