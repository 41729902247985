import dateProvider from '@/providers/dateProvider';
import Statistic from '@/entities/modules/competencies/Statistic';
import slugProvider from '@/providers/slugProvider';
import Settings from '@/entities/modules/competencies/Settings';
import Question from '@/entities/common/testing/Question';
import each from 'lodash/each';
import IFavorite from '@/entities/common/interfaces/helpers/IFavorite';
import IRouteMaterial from '@/entities/common/interfaces/routes/IRouteMaterial';
import CorrectName from '@/entities/common/CorrectName';
import IMaterial from '@/entities/common/interfaces/material-card/IMaterial';
import CustomRoute from '@/entities/common/CustomRoute';
import GenderSpecific from '@/entities/common/GenderSpecific';
import MaterialStatus from '@/entities/common/testing/MaterialStatus';
import IUpdatable from '@/entities/common/testing/updates/IUpdatable';
import IStrictMode from '@/entities/common/testing/IStrictMode';

export default class Test implements IUpdatable, IStrictMode, IMaterial, IFavorite, IRouteMaterial {
  id: number;

  customRoute: CustomRoute = CustomRoute.TO_COMPETENCIES_MATERIAL;
  moduleCode: CorrectName = CorrectName.COMPETENCY;
  genderSpecific: GenderSpecific = GenderSpecific.MASCULINE;

  slug: string;
  name: string;
  description: string;
  order: number;
  isNew: boolean;
  isFavorite: boolean;
  updatedAt: number;
  completeDate?: Date;
  countQuestions: number;
  currentTurn: number;
  timeLimit: number;
  turnsCount: number;
  questions: Question[] = [];
  questionsForDetails: Array<Question>;
  uuid: string = '';

  statistic: Statistic;
  settings: Settings;

  constructor(payload: any, statistic: Statistic, settings: Settings) {
    this.id = parseInt(payload.id, 10);

    this.name = payload.name;
    this.slug = slugProvider(payload.name);
    this.description = payload.description;
    this.order = parseInt(payload.order, 10);
    this.isNew = parseInt(payload.is_new, 10) === 1;
    this.isFavorite = parseInt(payload.is_favorite, 10) === 1;
    this.updatedAt = parseInt(payload.updated_at, 10);
    this.completeDate = dateProvider(parseInt(payload.complete_date, 10));
    this.countQuestions = parseInt(payload.count_questions, 10);
    this.currentTurn = parseInt(payload.current_turn, 10);
    this.timeLimit = parseInt(payload.time_limit, 10);
    this.turnsCount = parseInt(payload.turns_count, 10);
    this.questionsForDetails = [];

    if (payload.custom_settings) {
      this.settings = new Settings(payload.custom_settings);
    } else {
      this.settings = settings;
    }

    this.statistic = statistic;
  }

  getStatistics() {
    return this.statistic;
  }

  setStatistic(statistic: Statistic): void {
    this.statistic = statistic;
  }

  isPassed() {
    return this.statistic.completed;
  }

  getParts(): object[] {
    const parts = [];

    // выводим инфу только в пройденных тестах
    const countOfAttempts = this.turnsCount - this.statistic.currentTurn;

    if (this.isPassed()) {
      // если есть попытки -- выводим сколько есть
      if (countOfAttempts > 0 && this.statistic.percent !== 100) {
        parts.push([
          'learn_d_attempts_more_text',
          countOfAttempts,
          { 0: countOfAttempts.toLocaleString() },
        ]);
      }
    }

    return parts;
  }

  getStatus(): MaterialStatus {
    if (this.isNew) {
      return MaterialStatus.NEW;
    }

    if (this.isPassed()) {
      return MaterialStatus.PASSED;
    }

    return MaterialStatus.ASSIGNED;
  }

  getTimestamp(): number {
    return this.statistic.timestamp;
  }

  setFavorite(status: boolean) {
    this.isFavorite = status;
  }

  clearIsNew() {
    this.isNew = false;
  }

  setQuestions(questions: Question[]) {
    this.questions = questions;
  }

  enableStrictMode(uuid: string, timestamp?: number) {
    this.uuid = uuid;

    if (timestamp) {
      this.statistic.timestamp = timestamp;
    }
  }

  resetUserAnswers() {
    each(this.questions, question => {
      question.clearUserAnswers();
    });
  }

  getSaving(payload: any) {
    const userResults: any = [];
    each(this.questions, question => {
      userResults.push(question.getSaving());
    });

    const strictMode = this.uuid ? { turn_uuid: this.uuid } : {};

    return {
      id: this.id,
      complete_date: payload.completeDate,
      timestamp: this.statistic.timestamp,
      user_results: userResults,

      ...strictMode,
    };
  }

  getQuestionsForDetails(): Object[] {
    const questions: Object[] = [];
    this.questionsForDetails = [];
    each(this.statistic.userResults, question => {
      const userQuestion = question.getQuestionForDetails();
      questions.push(userQuestion);
      this.questionsForDetails.push(userQuestion);
    });

    return questions;
  }
}
