import { Module } from 'vuex';
import { RootState } from '../../types';
import { IntercomState } from './types';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

export const state: IntercomState = {
  showIntercomIcon: false,
  showIntercomWindow: false,
};

const namespaced: boolean = true;

export const intercom: Module<IntercomState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
