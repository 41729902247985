export default class CredentialSettings {
  code: string;
  isUsed: boolean;
  isRequired: boolean;

  constructor(code: string, payload: any) {
    this.code = code;
    this.isUsed = parseInt(payload.is_used, 10) === 1;
    this.isRequired = parseInt(payload.is_required_for_verification, 10) === 1;
  }
}
