import Certificate from '@/entities/modules/learning-programs/Certificate';
import MaterialStatus from '@/entities/common/testing/MaterialStatus';
import dateProvider from '@/providers/dateProvider';

export default class ProgramStatistic {
  id: number = 0;
  userPoints: number = 0;
  bonusPoints: number = 0;
  penaltyPoints: number = 0;
  programPoints: number = 0;
  percent: number = 0;
  startTime?: Date;
  endTime: number = 0;
  status?: string;
  isNew: boolean = false;
  isInProgress: boolean = false;
  isPassed: boolean = false;
  isFailed: boolean = false;
  isAssigned: boolean = false;
  isChecking: boolean = false;
  isChecked?: boolean;
  userHasFeedback?: boolean;
  updatedAt?: number;
  lastActivity?: Date;
  certificate?: Certificate;

  constructor(payload: any) {
    if (!payload) {
      return;
    }
    this.id = parseInt(payload.id, 10);
    this.userPoints = parseInt(payload.points, 10);
    this.bonusPoints = parseInt(payload.bonus_points, 10);
    this.penaltyPoints = parseInt(payload.penalty_points, 10);
    this.programPoints = parseInt(payload.program_points, 10);
    this.percent = parseInt(payload.percent, 10);
    this.startTime = dateProvider(payload.start_time);
    this.endTime = parseInt(payload.end_time, 10);
    this.status = payload.status;
    this.isNew = parseInt(payload.is_new, 10) === 1;
    this.isChecked = parseInt(payload.is_checked, 10) === 1;
    this.userHasFeedback = parseInt(payload.user_has_feedback, 10) === 1;
    this.updatedAt = payload.updated_at;
    this.lastActivity = dateProvider(payload.updated_at);
    if (payload.certificate) {
      this.certificate = new Certificate(payload.certificate);
    } else this.certificate = undefined;

    this.setStatuses();
  }

  setStatuses() {
    const programStatus = this.getProgramStatus();
    this.isInProgress = programStatus === MaterialStatus.IN_PROGRESS;
    this.isPassed = programStatus === MaterialStatus.PASSED;
    this.isFailed = programStatus === MaterialStatus.FAILED;
    this.isAssigned = programStatus === MaterialStatus.ASSIGNED;
    this.isChecking = programStatus === MaterialStatus.CHECKING;
  }

  getProgramStatus(): MaterialStatus {
    if (this.isNew) {
      return MaterialStatus.NEW;
    }

    if (this.status === 'fail') {
      return MaterialStatus.FAILED;
    }

    if (this.status === 'completed') {
      return MaterialStatus.PASSED;
    }

    if (this.status === 'checking') {
      return MaterialStatus.CHECKING;
    }

    if (this.status === 'in_progress') {
      return MaterialStatus.IN_PROGRESS;
    }

    return MaterialStatus.ASSIGNED;
  }

  setIsNew(isNew: boolean) {
    this.isNew = isNew;
    this.setStatuses();
  }
}
