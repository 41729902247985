import { Module } from 'vuex';
import { GiftStoreState } from '@/store/modules/gift-store/types';
import { RootState } from '../../types';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';

export const state: GiftStoreState = {
  gifts: [],
  cart: null,
  orders: [],
  meta: null,
  currentPage: 1,
  points: 0,
};

const namespaced: boolean = true;

export const giftStore: Module<GiftStoreState, RootState> = {
  namespaced,
  state,
  actions,
  getters,
  mutations,
};
