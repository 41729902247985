import { each, find } from 'lodash';
import Question from '@/entities/common/testing/Question';
import Statistic from '@/entities/modules/interviews/Statistic';
import slugProvider from '@/providers/slugProvider';
import { getCurrentTimestamp } from '@/providers/dateProvider';
import CustomRoute from '@/entities/common/CustomRoute';
import IUpdatable from '@/entities/common/testing/updates/IUpdatable';
import IMaterial from '@/entities/common/interfaces/material-card/IMaterial';
import GenderSpecific from '@/entities/common/GenderSpecific';
import CorrectName from '@/entities/common/CorrectName';
import MaterialStatus from '@/entities/common/testing/MaterialStatus';
import IRouteMaterial from '@/entities/common/interfaces/routes/IRouteMaterial';
import IFavorite from '@/entities/common/interfaces/helpers/IFavorite';

export default class Interview implements IUpdatable, IMaterial, IFavorite, IRouteMaterial {
  id: number;
  levelId: number;

  genderSpecific: GenderSpecific = GenderSpecific.MASCULINE;
  moduleCode: CorrectName = CorrectName.INTERVIEW;
  customRoute: CustomRoute = CustomRoute.TO_INTERVIEWS_MATERIAL;

  name: string;
  slug: string;
  description: string;
  order: number;
  updatedAt: number;

  questions: Question[] = [];

  statistic?: Statistic;

  isAssigned: boolean = false;
  isPassed: boolean = false;
  isNew: boolean;
  isFavorite: boolean;

  turnsCount: number = 1;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.levelId = parseInt(payload.level_id, 10);

    this.name = payload.name;
    this.slug = slugProvider(payload.name);
    this.description = payload.description;
    this.order = parseInt(payload.order, 10);
    this.isNew = parseInt(payload.is_new, 10) === 1;
    this.isFavorite = parseInt(payload.is_favorite, 10) === 1;
    this.updatedAt = parseInt(payload.updated_at, 10);

    each(payload.questions, o => this.questions.push(new Question(o)));
  }

  private recalculateStatuses(): void {
    if (!this.statistic) {
      return;
    }

    if (!this.isNew) {
      this.isAssigned = this.statistic.questionAnswers.length === 0;
      this.isPassed = this.statistic.questionAnswers.length > 0;
    }
  }

  setStatistic(statistic: Statistic): void {
    this.statistic = statistic;

    this.recalculateStatuses();
  }

  getStatistic(): Statistic | undefined {
    return this.statistic;
  }

  getParts(): object[] {
    return [];
  }

  getStatus(): MaterialStatus {
    if (this.isNew) {
      return MaterialStatus.NEW;
    }

    if (this.isPassed) {
      return MaterialStatus.PASSED;
    }

    if (this.isAssigned) {
      return MaterialStatus.ASSIGNED;
    }

    return MaterialStatus.ANY;
  }

  getQuestionById(id: number): Question | undefined {
    return find(this.questions, o => o.id === id);
  }

  getQuestionPositionById(id: number): number {
    let position = 0;

    each(this.questions, (o, key) => {
      if (o.id === id) {
        position = key;
      }
    });

    return position;
  }

  markAsViewed() {
    this.isNew = false;
    this.recalculateStatuses();
  }

  setFavorite(status: boolean) {
    this.isFavorite = status;
  }

  markAsPassed(): void {
    this.isPassed = true;
    this.isAssigned = false;
  }

  getSaving(payload: any) {
    const userResults: any = [];
    each(this.questions, question => {
      userResults.push(question.getSaving());
    });

    return {
      id: this.id,
      completed: 1,
      start_time: payload.startTime,
      timestamp: this.statistic ? this.statistic.timestamp : null,
      complete_date: getCurrentTimestamp(),
      user_results: userResults,
    };
  }

  getTimestamp(): number {
    if (this.statistic) {
      return this.statistic.timestamp;
    }

    return 0;
  }

  resetUserAnswers() {
    each(this.questions, question => {
      question.clearUserAnswers();
    });
  }
}
