import AnswerField from '@/entities/modules/tasks/AnswerField';
import QuestionTask from '@/entities/common/testing/QuestionTask';
import { each } from 'lodash';

export default class AnswerMultiplyField extends AnswerField {
  isMultiply: boolean;
  options: QuestionTask[];
  userIdsAnswers: number[];
  comment: string;

  constructor(payload: any) {
    super(payload);

    this.isMultiply = parseInt(payload.is_multiple_choice_allowed, 10) === 1;
    this.options = [];
    this.userIdsAnswers = payload.option_ids;
    this.comment = payload.comment;
    each(payload.options, option => {
      this.options.push(new QuestionTask(option));
    });
  }

  getAnswers(): QuestionTask[] {
    const answers = this.options;
    each(answers, option => {
      each(this.userIdsAnswers, id => {
        if (option.id === id) {
          option.setSelectedAnswer();
        }
      });
    });

    return answers;
  }
}
