export default class CertificatesListItem {
  id: number;
  name?: string;
  url: string;
  date?: number;
  order?: number;
  certificateList?: CertificatesListItem[];

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.url = payload.url;

    if (payload.name) {
      this.name = payload.name;
    }

    if (payload.date) {
      this.date = parseInt(payload.date, 10);
    }

    if (payload.order) {
      this.order = parseInt(payload.order, 10);
    }

    if (payload.certificate_list) {
      this.certificateList = [];
      payload.certificate_list.forEach((item: Object) => {
        this.certificateList?.push(new CertificatesListItem(item));
      });
    }
  }
}
