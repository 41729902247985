import axios from 'axios';
// @ts-ignore
import apiErrorsProvider from '@/providers/js/apiErrorsProvider';
import { isEmpty } from 'lodash';
import { routeTo } from '@/providers/routerLinkProvider';
import CustomRoute from '@/entities/common/CustomRoute';
import router from './router/index';
import store from './store/index';
// @ts-ignore
import ApiException from './entities/common/ApiException';
import { userLocaleJson } from './locale';

let hasAccessCodeError = false;

let hasAccessTokenError = false;

let isAccessTokenExecuted = false;

// const accessCodeTimerId = 0;

let has405error = false;

let accessToken = '';

let request = {};

/**
 * Обработчик вызывается перед каждым запросом
 * Наша задача - убедиться, что нет проблем с кодом доступа или авторизационным токеном,
 * а если есть - приостановить отправку запросов до тех пор, пока проблема не будет решена
 * @param config
 * @return {Promise<*>}
 */
export async function beforeRequest(config: any) {
  if (typeof config.headers.globalErrorHandler === 'undefined') {
    // eslint-disable-next-line no-param-reassign
    config.globalErrorHandler = true;
  } else {
    // eslint-disable-next-line no-param-reassign
    config.globalErrorHandler = !!config.headers.globalErrorHandler;
  }

  // eslint-disable-next-line no-param-reassign
  delete config.headers.globalErrorHandler;

  return new Promise(resolve => {
    // убираем кеширование у ie
    // eslint-disable-next-line no-param-reassign
    config.headers.Pragma = 'no-cache';

    if (hasAccessCodeError) {
      if (config.url === '/require-code') {
        resolve(config);
      } else {
        resolve(config);
        // возможны проблемы в некоторых кейсах с кодом доступа
        /* accessCodeTimerId = setInterval(() => {
          if (!hasAccessCodeError) {
            clearInterval(accessCodeTimerId);

            resolve(config);
          }
        }, 750); */
      }
    } else if (hasAccessTokenError) {
      if (config.url === '/auth/refresh-token' && !isAccessTokenExecuted) {
        isAccessTokenExecuted = true;
        resolve(config);
      } else {
        (() => {
          const y = setInterval(() => {
            if (!hasAccessTokenError) {
              clearInterval(y);

              // eslint-disable-next-line no-param-reassign
              config.headers.Authorization = `Bearer ${accessToken}`;
              resolve(config);
            }
          }, 750);
        })();
      }
    } else {
      // @ts-ignore
      const userToken = JSON.parse(localStorage.getItem('user_token'));

      if (
        userToken &&
        axios.defaults.headers.common.Authorization !== `Bearer ${userToken.accessToken}`
      ) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${userToken.accessToken}`;
        axios.defaults.headers.common.Authorization = `Bearer ${userToken.accessToken}`;
      }
      resolve(config);
    }
  });
}

/**
 * Обработчик на каждый ответ сервера в случае успеха
 * @param response
 * @return {Promise<void>}
 */
export async function onSuccess(response: any) {
  const originalRequest = response.config;

  if (response.config.url.indexOf('/require-code') !== -1) {
    if (response.status === 200 && parseInt(response.data.success, 10) === 1) {
      hasAccessCodeError = false;

      if (!isEmpty(request)) {
        axios(request);
        request = {};
      }
    }
  }

  if (
    Object.prototype.hasOwnProperty.call(response.data, 'error') &&
    response.data.error !== null &&
    response.data.error.code === 4009 &&
    !originalRequest.isRetry
  ) {
    originalRequest.isRetry = true;

    hasAccessCodeError = true;

    await store.dispatch('auth/accessCodeStatus', {
      log: response.data.error.log,
    });

    await store.dispatch('auth/enterAccessCode', {
      accessCode: false,
    });

    if (isEmpty(request)) {
      request = originalRequest;
    }

    return response;
  }

  return apiErrorsProvider(response);
}

/**
 * Обработчик на каждый ответ сервера в случае ошибок
 * @param error
 * @return {Promise<void>}
 */
export async function onFailure(error: any) {
  const originalRequest = error.config;

  if (error && !error.response) {
    await store.dispatch('settings/saveTestDataForElastic', {
      payload: {
        data: {
          error: JSON.stringify(error),
        },
        '@timestamp': new Date(),
        userId: store.getters['auth/user'].id,
        companyId: store.getters['settings/id'],
        endPoint: location.hostname,
        url: location.href,
        type: 'interceptors-error',
      },
    });
  }

  if (error.response.status === 401 && !originalRequest.isRetry) {
    if (hasAccessTokenError) {
      return axios(originalRequest);
    }

    hasAccessTokenError = true;
    originalRequest.isRetry = true;

    // @ts-ignore
    const userToken = JSON.parse(localStorage.getItem('user_token'));

    // Меняем тип авторизации на Basic
    axios.defaults.headers.common.Authorization = `Basic ${process.env.VUE_APP_API_AUTHORIZATION_CREDENTIALS}`;

    return axios
      .post(
        '/auth/refresh-token',
        { refresh_token: userToken.refreshToken },
        { headers: { globalErrorHandler: false } },
      )
      .then(response => {
        if (response instanceof ApiException) {
          // @ts-ignore
          if (response.getErrorCode() === 3006) {
            return Promise.reject();
          }
        }

        return store
          .dispatch('auth/updateAccessToken', response.data.success)
          .then((newUserToken: any) => {
            // Меняем обратно на Bearer
            axios.defaults.headers.common.Authorization = `Bearer ${newUserToken.accessToken}`;
            originalRequest.headers.Authorization = `Bearer ${newUserToken.accessToken}`;

            accessToken = newUserToken.accessToken;
            hasAccessTokenError = false;
            isAccessTokenExecuted = false;

            return axios(originalRequest);
          });
      })
      .catch(() => {
        store.dispatch('auth/logout');
        window.location.href = `/login?router_back=${window.location.pathname}`;
      });
  }

  if (error.response.status === 403 && error.response.data.error.code === 3010) {
    const needVerify = error.response.data.error.log.need_verify;

    const needVerifyFields = error.response.data.error.log.need_verify.user_fields;

    if (needVerifyFields.includes('email') || needVerifyFields.includes('phone')) {
      await store.dispatch('settings/getCompanySettings');
    }

    if (needVerifyFields.includes('password') && store.getters['auth/user']) {
      await store.dispatch('auth/logout');
      window.location.href = '/login';

      return;
    }

    store.dispatch('auth/flushVerification', needVerify);

    return;
  }

  if (error.response.status === 403 && error.response.data.error.code === 3001) {
    store.dispatch('auth/flushLicense');
    router?.push(routeTo(CustomRoute.TO_AUTH_LICENSE));
  }

  if (
    error.response.status === 405 &&
    (error.response.data.error.code === 1006 || error.response.data.error.code === 1008)
  ) {
    if (!has405error) {
      has405error = true;

      store.dispatch('helpers/showNotification', {
        message:
          error.response.data.error.code === 1006
            ? userLocaleJson.common_module_unavailable_1006_error
            : userLocaleJson.common_request_module_section_unavailable_1008_error_text,
      });

      setTimeout(() => {
        // @ts-ignore
        window.location.href = '/';
      }, 2000);

      return;
    }
  }

  return apiErrorsProvider(error);
}
