import AnswerReviewsComment from '@/entities/modules/tasks/AnswerReviewerComment';
import { each } from 'lodash';

export default abstract class AnswerField {
  id: number;
  type: string;
  name: string;
  reviewerComments: AnswerReviewsComment[];

  protected constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.type = payload.type;
    this.name = payload.name;
    this.reviewerComments = [];
    each(payload.reviewer_comments, comment => {
      this.reviewerComments.push(new AnswerReviewsComment(comment));
    });
  }
}
