import { has } from 'lodash';

export default class ToggleSection {
  id: number;
  name: string;
  position?: number;

  isCompleted: boolean;
  checkedMaterials: number = 0;
  hasntCompletedLogic: boolean = false;

  hasPercentLogic: boolean = false;
  percent: number = 0;

  isLocked: boolean = false;

  description: string;

  parts: any[];

  constructor(payload: any) {
    this.id = payload.id;
    this.name = payload.name;
    this.description = payload.description;

    if (payload.position) {
      this.position = payload.position;
    }

    this.isCompleted = payload.isCompleted;

    if (has(payload, 'hasntCompletedLogic')) {
      this.hasntCompletedLogic = payload.hasntCompletedLogic;
    }

    this.percent = payload.percent ? payload.percent : 0;

    if (has(payload, 'hasPercentLogic')) {
      this.hasPercentLogic = payload.hasPercentLogic;
    }

    this.isLocked = payload.isLocked;

    if (has(payload, 'checkedMaterials')) {
      this.checkedMaterials = parseInt(payload.checkedMaterials, 10);
    }

    this.parts = payload.parts;
  }
}
