export default abstract class BaseWidget {
  id: number = 0;
  type: string = '';
  name: string = '';
  order: number = 0;

  constructor(payload: any) {
    if (!payload.id) {
      return;
    }
    this.id = parseInt(payload.id, 10);
    this.type = payload.type;
    this.name = payload.name;
    this.order = parseInt(payload.order, 10);
  }
}
