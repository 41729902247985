import { each, find, filter, sortBy } from 'lodash';
import IStatistic from '@/entities/modules/learning-programs/IStatistic';
import SectionMaterial from '@/entities/modules/learning-programs/SectionMaterial';
import ToggleSectionMaterial from '@/entities/modules/learning-programs/ToggleSectionMaterial';
import MaterialStatus from '@/entities/common/testing/MaterialStatus';
import ImageSource, { createImageSmSource } from '@/entities/common/sources/ImageSource';
import BaseMaterial from '@/entities/modules/learning-programs/BaseMaterial';
import CustomRoute from '@/entities/common/CustomRoute';
import MaterialLongreadStatistic from '@/entities/modules/learning-programs/MaterialLongreadStatistic';
import LongreadPage from '@/entities/modules/learning-programs/longread/LongreadPage';
import { i18n } from '@/lang';

export default class MaterialLongread extends BaseMaterial {
  id: number;
  name: string;
  isRating: boolean;
  isAllowSkipPages: boolean;
  updatedAtTimestamp: number;
  image?: ImageSource;
  maxScore: number;

  pages: LongreadPage[] = [];
  statistic: MaterialLongreadStatistic;

  constructor(statistic: IStatistic, payload: any) {
    super();

    this.statistic = statistic as MaterialLongreadStatistic;

    this.id = parseInt(payload.id, 10);
    this.name = payload.name;
    this.isRating = parseInt(payload.is_rating, 10) === 1;
    this.isAllowSkipPages = parseInt(payload.allow_skip_pages, 10) === 1;
    this.updatedAtTimestamp = payload.updated_at;
    this.image = createImageSmSource(payload.image);
    this.maxScore = parseInt(payload.max_points, 10);
  }

  getMaterialType(): string {
    return 'longread';
  }

  getToggleWidget(sectionMaterial: SectionMaterial, config: any): ToggleSectionMaterial {
    const status = this.statistic.getMaterialStatus();

    let widgetParts: unknown[] = [];

    if (config && config.fromMyTeam) {
      if (
        this.statistic instanceof MaterialLongreadStatistic &&
        status !== MaterialStatus.ASSIGNED
      ) {
        const duration =
          this.statistic.duration > 59
            ? Math.floor(this.statistic.duration / 60)
            : this.statistic.duration;

        if (this.statistic.duration > 59) {
          const readLabel = i18n.tc('common_read_d_mins_lowcase', duration, { 0: duration });
          widgetParts.push(`${this.statistic.getPercent()}%, ${readLabel}`);
        } else {
          const readLabel = i18n.tc('common_read_d_seconds_lowcase', duration, { 0: duration });
          widgetParts.push(`${this.statistic.getPercent()}%, ${readLabel}`);
        }
      }
    } else {
      widgetParts.push(`${this.statistic.getPercent()}%`);
    }

    // отображаем баллы если longreead рейтинговый и есть баллы
    widgetParts = this.addPointsInfo(
      widgetParts,
      this.isRating,
      this.maxScore,
      this.statistic.points,
    );

    return new ToggleSectionMaterial({
      id: this.id,
      type: this.getMaterialType(),
      routerTo: CustomRoute.TO_LEARNING_PROGRAMS_LONGREAD,
      routerTrackTo: CustomRoute.TO_LEARNING_TRACK_PROGRAMS_LONGREAD,
      name: this.name,
      image: this.image,

      lokaliseTagLabel: 'common_longread_title',
      buttonLabel: null,
      lokaliseButtonLabel:
        status === MaterialStatus.ASSIGNED
          ? 'learn_course_watch_button'
          : 'learn_course_watch_again_button',
      isButtonOutlined: status !== MaterialStatus.ASSIGNED,

      numberingTypeThrough: this.numberingTypeThrough,
      numberingTypeSections: this.numberingTypeSections,
      numberingTypeHierarchical: this.numberingTypeHierarchical,

      isRating: this.isRating,
      isRequired: sectionMaterial.isRequired,
      isLocked: sectionMaterial.isLocked,

      isPassed: status === MaterialStatus.PASSED,

      parts: widgetParts,
    });
  }

  setPages(pages: LongreadPage[]): void {
    this.pages = pages;
    this.setVisibility();
  }

  setPage(page: LongreadPage): void {
    this.pages = sortBy([...filter(this.pages, o => o.uuid !== page.uuid), page], ['order']);
    this.setVisibility();
  }

  private setVisibility(): void {
    if (this.isAllowSkipPages) {
      return;
    }

    let allowed = true;
    each(this.pages, page => {
      if (!allowed) {
        page.setSkip(false);

        return;
      }

      if (page.statistic) {
        page.setSkip(true);

        if (page.statistic.percent < 100) {
          allowed = false;
        }
      }
    });
  }

  private setVisibilityAllPages(): void {
    each(this.pages, page => {
      page.setSkip(true);
    });
  }

  getUuidStartPage(): string {
    let uuid: string = '';

    let isDone = true;

    for (let i = 0; i < this.pages.length; i += 1) {
      if (this.pages[i].statistic && this.pages[i].statistic.percent < 100) {
        uuid = this.pages[i].uuid;
        isDone = false;
        break;
      }
    }

    if (isDone && this.pages.length > 0) {
      return this.pages[0].uuid;
    }

    return uuid;
  }

  initStartTime() {
    if (this.statistic instanceof MaterialLongreadStatistic && !this.statistic.startTime) {
      this.statistic.startTime = new Date();
    }
  }

  updatePercentForPage(percent: number, uuid: string, isAllowSkipGlobal: boolean) {
    const page = find(this.pages, o => o.uuid === uuid);

    if (page && percent > page.statistic.percent) {
      page.statistic.percent = percent;

      if (percent > 98) {
        page.statistic.percent = 100;
      }
    }

    this.updatePercent();

    if (!isAllowSkipGlobal) {
      this.setVisibility();
    } else {
      this.setVisibilityAllPages();
    }
  }

  updatePercent() {
    let percent = 0;
    each(this.pages, page => {
      percent += page.statistic.percent;
    });

    percent = Math.floor(percent / this.pages.length);

    if (percent > 100) {
      percent = 100;
    }

    if (this.statistic instanceof MaterialLongreadStatistic) {
      if (percent > this.statistic.percent) {
        this.statistic.percent = percent;
      }
    }
  }

  incrementDuration() {
    if (this.statistic instanceof MaterialLongreadStatistic) {
      this.statistic.duration += 1;
      this.statistic.endTime = new Date();
    }
  }

  getSaving(): any {
    const pages: any = [];
    each(this.pages, o =>
      pages.push({
        uuid: o.uuid,
        percent: o.statistic.percent,
      }),
    );

    if (this.statistic instanceof MaterialLongreadStatistic) {
      return {
        id: this.id,
        type: this.getMaterialType(),
        start_time: Math.round(Number(this.statistic.startTime) / 1000),
        end_time: this.statistic.endTime
          ? Math.round(Number(this.statistic.endTime) / 1000)
          : Math.round(Date.now() / 1000),
        duration: this.statistic.duration,
        updated_pages: pages,
      };
    }
  }
}
