import { ActionTree } from 'vuex';
import find from 'lodash/find';
import each from 'lodash/each';
import cloneDeep from 'lodash/cloneDeep';
// eslint-disable-next-line import/no-cycle
import learningItemsApi from '@/services/learning-items';
import { LearningItemTypes } from '@/entities/modules/learning-items/LearningItemType';
import learningProgramsApi from '@/services/learning-programs';
import Program from '@/entities/modules/learning-programs/Program';
import { resolver } from '@/providers/promiseReturnerProvider';
import MaterialTest from '@/entities/modules/learning-programs/MaterialTest';
import IStatistic from '@/entities/modules/learning-programs/IStatistic';
import MaterialVideo from '@/entities/modules/learning-programs/MaterialVideo';
import MaterialVideoStatistic from '@/entities/modules/learning-programs/MaterialVideoStatistic';
// @ts-ignore
import certificateApi from '@/services/certificates';
import Feedback from '@/entities/modules/learning-programs/feedback/Feedback';
import ProgramStatistic from '@/entities/modules/learning-programs/ProgramStatistic';
import MaterialLongread from '@/entities/modules/learning-programs/MaterialLongread';
import BaseMaterial from '@/entities/modules/learning-programs/BaseMaterial';
import commentApi from '@/services/comment';
import ApiName from '@/entities/common/ApiName';
import PaginationDirection from '@/entities/common/PaginationDirection';
import { RootState } from '../../types';
import { LearningProgramsState } from './types';

export const actions: ActionTree<LearningProgramsState, RootState> = {
  /**
   * Вернет программу обучения по ID если она загружена
   */
  async getById(
    { state, dispatch },
    { moduleId, trackId = 0, programId },
  ): Promise<Program | undefined> {
    const modulePrograms = state.programs.get(moduleId);

    let program = find(modulePrograms, o => o.id === programId);

    if (!program || program.getMaterials().length === 0) {
      // @ts-ignore
      program = dispatch('loadProgramById', { moduleId, trackId, programId });
    }

    return program;
  },

  /**
   * Загрузит все программы обучения и статистику по ним
   * если данные были загружены, то проверит акруальность и дозагрузит устаревшие
   */
  async loadPrograms({ rootState, state, commit }, moduleId: number): Promise<boolean> {
    let programs: Program[] = [];

    const modulePrograms = state.programs.get(moduleId);

    if (modulePrograms && modulePrograms.length > 0) {
      const statisticsUpdates = await learningProgramsApi.getProgramsStatUpdates(moduleId);

      const newStatisticsIds: number[] = [];

      const actualStatistics: ProgramStatistic[] = [];
      each(statisticsUpdates, currentStatistic => {
        const program = find(modulePrograms, o => o.id === currentStatistic.id);

        if (
          !program ||
          (program && program.getStatistic().updatedAt !== currentStatistic.updatedAt)
        ) {
          newStatisticsIds.push(currentStatistic.id);
        }

        if (program && program.getStatistic().updatedAt === currentStatistic.updatedAt) {
          actualStatistics.push(program.getStatistic());
        }
      });

      if (newStatisticsIds.length !== 0) {
        const newStatistics = await learningProgramsApi.postProgramsStatistic(
          moduleId,
          newStatisticsIds,
        );
        actualStatistics.push(...newStatistics);
      }

      const programUpdates = await learningProgramsApi.getProgramsUpdates(moduleId);

      const newProgramsIds: number[] = [];

      const actualPrograms: Program[] = [];
      each(programUpdates, currentProgram => {
        const program = find(modulePrograms, o => o.id === currentProgram.id);

        if (!program || (program && program.updatedAt !== currentProgram.updatedAt)) {
          newProgramsIds.push(currentProgram.id);
        }

        if (program && program.updatedAt === currentProgram.updatedAt) {
          const programActualStat = find(actualStatistics, stat => stat.id === program.id);

          if (
            programActualStat &&
            program.getStatistic().updatedAt !== programActualStat.updatedAt
          ) {
            program.setStatistic(programActualStat);
          }
          actualPrograms.push(program);
        }
      });

      if (newProgramsIds.length !== 0) {
        const newPrograms = await learningProgramsApi.postPrograms(
          moduleId,
          newProgramsIds,
          rootState.auth.user.isAllowToSkipMaterials,
          actualStatistics,
        );
        actualPrograms.push(...newPrograms);
      }
      programs = actualPrograms;
    } else {
      const programStatistics = await learningProgramsApi.getProgramsStatistic(moduleId);
      programs = await learningProgramsApi.getPrograms(
        moduleId,
        rootState.auth.user.isAllowToSkipMaterials,
        programStatistics,
      );
    }

    // изменяем moduleId дважды, чтобы все getters пересчитались
    commit('resetModuleId');
    commit('changePrograms', { moduleId, programs });
    commit('changeModuleId', moduleId);

    return resolver(true);
  },

  /**
   * Вернет программу обучения по ID
   * если она не загружена, то загрузит ее
   * подгрузит материалы, если они не были загружены и загрузит актуальные если что-то изменилось
   */
  async loadProgramById(
    { rootState, state, commit },
    { moduleId, trackId = 0, programId },
  ): Promise<Program | undefined> {
    const learningProgramsSettings = find(
      rootState.settings.modules,
      o => o.code === 'materials',
    ).settings;

    let programFromStore: Program | undefined;

    const modulePrograms = state.programs.get(moduleId);

    if (modulePrograms && modulePrograms.length > 0) {
      programFromStore = find(modulePrograms, o => o.id === programId);
    }

    const statistics = await learningProgramsApi.postProgramsStatistic(moduleId, [programId]);
    const program = (
      await learningProgramsApi.postPrograms(
        moduleId,
        [programId],
        rootState.auth.user.isAllowToSkipMaterials,
        statistics,
      )
    ).pop();

    if (typeof program === 'undefined') {
      return;
    }

    let track = null;

    if (trackId) {
      track = await learningItemsApi.getLearningItem(
        moduleId,
        `${LearningItemTypes.TRACK}_${trackId}`,
      );
    }

    if (track) {
      program.setTrack(track);
    } else {
      program.setTrack(null);
    }

    // если материалы были загружены ранее и хранятся в хранилище, то перепишем их к новой программе
    if (programFromStore) {
      program.setMaterials(programFromStore.getMaterials());
    }

    // если оказались на странице программы не загрузив список всех программ тогда запишем программу в хранилище
    // иначе обновим программу в хранилище
    if (!modulePrograms || modulePrograms.length === 0) {
      commit('changePrograms', { moduleId, programs: [program] });
    } else {
      commit('setProgram', { moduleId, program });
    }

    let materials: BaseMaterial[] = [];

    if (program.getMaterials().length > 0) {
      const materialStatUpdates = await learningProgramsApi.postMaterialsStatUpdates(
        program.getListOfMaterialIds(),
      );

      const newMaterialStatIds: number[] = [];

      const actualMaterialStat: IStatistic[] = [];
      each(program.getMaterials(), (material: BaseMaterial) => {
        const currentStatisticUpdatedAt = find(materialStatUpdates, o => o.id === material.id);

        if (
          currentStatisticUpdatedAt &&
          material.getStatistic().updatedAtTimestamp !== currentStatisticUpdatedAt.updatedAt
        ) {
          newMaterialStatIds.push(material.id);
        }

        if (
          currentStatisticUpdatedAt &&
          material.getStatistic().updatedAtTimestamp === currentStatisticUpdatedAt.updatedAt
        ) {
          actualMaterialStat.push(material.getStatistic());
        }
      });

      if (newMaterialStatIds.length !== 0) {
        const newStatistics = await learningProgramsApi.postMaterialsStatistics(newMaterialStatIds);
        actualMaterialStat.push(...newStatistics);
      }

      const materialsUpdates = await learningProgramsApi.postMaterialsUpdates(
        program.getListOfMaterialIds(),
      );

      const newMaterialsIds: number[] = [];

      const actualMaterials: BaseMaterial[] = [];
      each(program.getMaterials(), (material: BaseMaterial) => {
        const currentProgramUpdatedAt = find(materialsUpdates, o => o.id === material.id);

        if (
          currentProgramUpdatedAt &&
          material.updatedAtTimestamp !== currentProgramUpdatedAt.updatedAt
        ) {
          newMaterialsIds.push(material.id);
        }

        if (
          currentProgramUpdatedAt &&
          material.updatedAtTimestamp === currentProgramUpdatedAt.updatedAt
        ) {
          const materialActualStat = find(
            actualMaterialStat,
            stat => stat.materialId === material.id,
          );

          if (
            materialActualStat &&
            material.getStatistic().updatedAtTimestamp !== materialActualStat.updatedAtTimestamp
          ) {
            material.setStatistic(materialActualStat);
          }
          actualMaterials.push(material);
        }
      });

      if (newMaterialsIds.length !== 0) {
        const newMaterials = await learningProgramsApi.postMaterials(
          newMaterialsIds,
          actualMaterialStat,
          rootState.auth.user.isAllowToSkipMaterials,
        );
        actualMaterials.push(...newMaterials);
      }
      materials = actualMaterials;
    } else {
      const materialsStatistics = await learningProgramsApi.postMaterialsStatistics(
        program.getListOfMaterialIds(),
      );
      materials = await learningProgramsApi.postMaterials(
        program.getListOfMaterialIds(),
        materialsStatistics,
        learningProgramsSettings,
      );
    }

    program.setMaterials(materials);
    commit('setProgramMaterials', { moduleId, programId: program.id, materials });

    return program;
  },

  async clearIsNew({ state }, { moduleId, programId }) {
    const modulePrograms = state.programs.get(moduleId);

    const learningProgram = find(modulePrograms, o => o.id === programId);

    if (learningProgram) {
      await learningProgramsApi.clearIsNew(programId);

      learningProgram.getStatistic().setIsNew(false);
    }

    return resolver(true);
  },

  /**
   * Сбросит флаг isChecked
   * @param state
   * @param materialId
   */
  async clearIsChecked({}, materialId): Promise<boolean> {
    await learningProgramsApi.clearIsChecked(materialId);

    return resolver(true);
  },

  /**
   *
   * @param getters
   * @param commit
   * @param dispatch
   * @param tagName
   */
  changeTag({ getters, commit }, tagName) {
    if (tagName !== null) {
      const tag = find(getters.tags, o => o.name === tagName);

      if (!tag) {
        return;
      }
    }

    commit('changeSelectedTag', tagName);
  },

  changeRequiredFilter({ commit }, type) {
    commit('changeRequiredFilter', type);
  },

  changeStatusFilter({ commit }, type) {
    commit('changeStatusFilter', type);
  },

  changeSortFilter({ commit }, type) {
    commit('changeSortFilter', type);
  },

  changeDeadlineFilter({ commit }, type) {
    commit('changeDeadlineFilter', type);
  },

  /**
   *
   * @param getters
   * @param commit
   * @param dispatch
   * @param categoryId
   */
  changeCategory({ getters, commit }, categoryId) {
    let category = find(getters.categories, o => o.id === parseInt(categoryId, 10));

    if (!category) {
      category = null;
    }

    commit('changeSelectedCategory', category);
  },

  /**
   * Отправка статистики материала и обновление статистики в программах, у которых есть этот материал
   * @param state
   * @param dispatch
   * @param moduleId
   * @param programId
   * @param materialId id материала
   * @param payload доп инфа по материалу, может быть null
   */
  async saveMaterialStatistic(
    { state },
    { moduleId, programId, materialId, payload },
  ): Promise<BaseMaterial | undefined> {
    const modulePrograms = state.programs.get(moduleId);

    const program = find(modulePrograms, o => o.id === programId);

    const material = program && find(program.getMaterials(), o => o.id === materialId);

    if (material) {
      const statistic = await learningProgramsApi.sendStatistic(material.getSaving(payload));

      if (statistic && program) {
        program.updateMaterialStatistic(statistic);
      }
    }

    return resolver(material);
  },

  /**
   * Отправка времени просмотра видеоролика
   */
  async saveVideoCurrentTime(
    { state },
    { moduleId, programId, materialId, payload },
  ): Promise<boolean> {
    const modulePrograms = state.programs.get(moduleId);

    const program = find(modulePrograms, o => o.id === programId);

    const material = program && find(program.getMaterials(), o => o.id === materialId);

    if (material && material instanceof MaterialVideo) {
      const saving = material.getSaving({ type: 'updateCurrentTime', ct: payload });

      if (saving) {
        const statistic = await learningProgramsApi.sendStatistic(saving);

        if (statistic && statistic instanceof MaterialVideoStatistic && program) {
          program.updateMaterialStatistic(statistic);
        }
      }
    }

    return resolver(true);
  },

  /**
   * Сохранит результат взаимодействия пользователя с интерактивной вставкой
   */
  async saveVideoInteraction(
    { state },
    { moduleId, programId, materialId, interactionId, maxCurrentTime, payload },
  ) {
    const modulePrograms = state.programs.get(moduleId);

    const program = find(modulePrograms, o => o.id === programId);

    const material = program && find(program.getMaterials(), o => o.id === materialId);

    if (material && material instanceof MaterialVideo) {
      const saving = material.getSaving({
        type: 'saveInteraction',
        interactionId,
        maxCurrentTime,
        payload,
      });

      if (saving) {
        const statistic = await learningProgramsApi.sendStatistic(saving);

        if (statistic && statistic instanceof MaterialVideoStatistic && program) {
          program.updateMaterialStatistic(statistic);
        }
      }
    }
  },

  /**
   * Обновит статистику у материала
   */
  async updateMaterialStatistic({ state }, { moduleId, programId, materialId }) {
    const modulePrograms = state.programs.get(moduleId);

    const program = find(modulePrograms, o => o.id === programId);

    const material = program && find(program.getMaterials(), o => o.id === materialId);

    if (material) {
      const arrayStatistic = await learningProgramsApi.postMaterialsStatistics([materialId]);

      if (arrayStatistic.length > 0) {
        const statistic = arrayStatistic.pop();

        if (statistic && program) {
          program.updateMaterialStatistic(statistic);
        }
      }
    }
  },

  async getTestQuestions({ state }, { moduleId, programId, materialId }): Promise<boolean> {
    const modulePrograms = state.programs.get(moduleId);

    const program = find(modulePrograms, o => o.id === programId);

    const material = program && find(program.getMaterials(), o => o.id === materialId);

    if (material instanceof MaterialTest) {
      const sections = await learningProgramsApi.getTestSections(materialId);
      material.setSections(sections);

      const questions = await learningProgramsApi.getTestQuestions(materialId);
      material.setQuestions(questions);
    }

    return resolver(true);
  },

  /**
   * Вернет uuid попытки
   * @param id
   */
  async getTestUuid({}, id: number): Promise<string | null> {
    return learningProgramsApi.getTestUuid(id);
  },

  /**
   * Добавит картинку сертификата к сертификату из статистики
   */
  async getLearningProgramCertificate({ state }, { moduleId, programId }) {
    const modulePrograms = state.programs.get(moduleId);

    const program = find(modulePrograms, o => o.id === programId);

    if (program && program.getCertificate()) {
      const learningProgramCertificate = program.getCertificate();

      if (
        typeof learningProgramCertificate !== 'undefined' &&
        learningProgramCertificate.image === ''
      ) {
        learningProgramCertificate.image = await certificateApi.getLearningProgramCertificate(
          programId,
        );
        program.setCertificate(learningProgramCertificate);
      }
    }
  },

  /**
   * Вернет все отзывы
   */
  async getFeedback({ state }, { moduleId, programId }) {
    const modulePrograms = state.programs.get(moduleId);

    const program = find(modulePrograms, o => o.id === programId);

    if (program) {
      const payload = await learningProgramsApi.getFeedback(programId);
      program.setFeedback(new Feedback(payload));

      return resolver(true);
    }
  },

  /**
   * Вернет отзыв текущего пользователя
   */
  async getFeedbackCurrentUser({}, programId: number) {
    return learningProgramsApi.getFeedbackCurrentUser(programId);
  },

  /**
   * Сохранит отзыв
   */
  async saveFeedback({ state, dispatch }, { moduleId, feedback }) {
    const id = `${ApiName.LEARNING_PROGRAM}_${feedback.learningProgramId}`;

    await learningItemsApi.sendUserFeedback(id, feedback);
    await dispatch('getFeedback', { moduleId, programId: feedback.learningProgramId });

    const modulePrograms = state.programs.get(moduleId);

    const program = find(modulePrograms, o => o.id === feedback.learningProgramId);

    if (program) {
      const newProgram = await learningProgramsApi.postProgram(
        moduleId,
        feedback.learningProgramId,
      );
      program.updateProgramAfterFeedback(newProgram);
    }
  },

  async getLongreadTitles({ state }, { moduleId, programId, materialId }) {
    const modulePrograms = state.programs.get(moduleId);

    const program = find(modulePrograms, o => o.id === programId);

    if (!program) {
      return;
    }

    const material = find(program.getMaterials(), o => o.id === materialId);

    if (!(material instanceof MaterialLongread)) {
      return;
    }

    if (material.pages.length > 0) {
      return;
    }

    const pages = await learningProgramsApi.getLongreadTitles(
      materialId,
      cloneDeep(await learningProgramsApi.getLongreadPagesStatistic(materialId)),
    );

    material.setPages(cloneDeep(pages));
  },

  async getLongreadPage({ state }, { moduleId, programId, materialId, uuid }) {
    const modulePrograms = state.programs.get(moduleId);

    const program = find(modulePrograms, o => o.id === programId);

    if (!program) {
      return;
    }

    const material = find(program.getMaterials(), o => o.id === materialId);

    if (!(material instanceof MaterialLongread)) {
      return;
    }

    let page = find(material.pages, o => o.uuid === uuid);

    if (!page) {
      return;
    }

    if (page.isLoaded) {
      return;
    }

    page = await learningProgramsApi.getLongreadPageByUuid(page);
    page.markAsLoaded();

    material.setPage(cloneDeep(page));
  },

  async getProgramComments({}, { learningProgram, paginationDirection }) {
    if (paginationDirection === PaginationDirection.DEFAULT) {
      const [items, offsetPagination] = await commentApi.getComments(
        ApiName.LEARNING_PROGRAM,
        learningProgram.id,
        null,
        null,
        null,
      );
      learningProgram.setCommentsPagination(offsetPagination, paginationDirection);
      learningProgram.setCommentsList(items, offsetPagination.total);

      return;
    }

    const limit = learningProgram.commentsPagination.getLimit(paginationDirection);

    const offset = learningProgram.commentsPagination.getOffset(paginationDirection);

    const comments = learningProgram.commentsList;

    const [items, offsetPagination] = await commentApi.getComments(
      ApiName.LEARNING_PROGRAM,
      learningProgram.id,
      null,
      offset,
      limit,
    );
    learningProgram.setCommentsPagination(offsetPagination, paginationDirection);

    if (paginationDirection === PaginationDirection.DOWN) {
      comments.push(...items);
    }

    if (paginationDirection === PaginationDirection.UP) {
      comments.unshift(...items);
    }
    await learningProgram.setCommentsList(comments, offsetPagination.total);
  },

  async clearProgramCommentsIsNew({}, { id, ids }) {
    if (!ids || ids.length === 0) {
      return;
    }

    await commentApi.clearCommentsIsNew(ApiName.LEARNING_PROGRAM, id, ids);
  },

  async sendProgramComment({}, { id, comment, parentId }) {
    return commentApi.sendComment(ApiName.LEARNING_PROGRAM, id, comment, parentId);
  },
};
