import { each, find, shuffle } from 'lodash';
import Statistic from '@/entities/modules/tests/Statistic';
import IUpdatable from '@/entities/common/testing/updates/IUpdatable';
import IFavorite from '@/entities/common/interfaces/helpers/IFavorite';
import IRouteMaterial from '@/entities/common/interfaces/routes/IRouteMaterial';
import CustomRoute from '@/entities/common/CustomRoute';
import CorrectName from '@/entities/common/CorrectName';
import slugProvider from '@/providers/slugProvider';
import Question from '@/entities/common/testing/Question';
import Settings from '@/entities/modules/tests/Settings';
import IMaterial from '@/entities/common/interfaces/material-card/IMaterial';
import QuestionType from '@/entities/common/testing/QuestionType';
import MaterialStatus from '@/entities/common/testing/MaterialStatus';
import GenderSpecific from '@/entities/common/GenderSpecific';
import { getCurrentTimestamp } from '@/providers/dateProvider';
import IStrictMode from '@/entities/common/testing/IStrictMode';
import QuestionStatistic from '@/entities/modules/tests/QuestionStatistic';

export default class Test implements IUpdatable, IStrictMode, IMaterial, IFavorite, IRouteMaterial {
  id: number;
  levelId: number;

  moduleCode: CorrectName = CorrectName.TESTS;
  customRoute: CustomRoute = CustomRoute.TO_TESTS_MATERIAL;
  genderSpecific: GenderSpecific = GenderSpecific.MASCULINE;

  name: string;
  slug: string;
  description: string;
  order: number;
  updatedAt: number;

  uuid: string = '';

  isChecked: boolean;

  isNew: boolean;
  isRating: boolean;
  isFavorite: boolean;
  isAvailable: boolean = true;

  timeLimit: number;
  turnsCount: number;

  questions: Question[] = [];
  questionsForDetails: Array<Question>;

  statistic: Statistic;
  settings: Settings;

  scores: number;
  scoresForOpenQuestion: number;

  constructor(payload: any, statistic: Statistic, settings: Settings) {
    this.id = parseInt(payload.id, 10);
    this.levelId = parseInt(payload.level_id, 10);

    this.name = payload.name;
    this.slug = slugProvider(payload.name);
    this.description = payload.description;
    this.order = parseInt(payload.order, 10);
    this.updatedAt = parseInt(payload.updated_at, 10);

    this.isChecked = parseInt(payload.is_checked, 10) === 1;

    this.isNew = parseInt(payload.is_new, 10) === 1;
    this.isRating = parseInt(payload.is_rating, 10) === 1;
    this.isFavorite = parseInt(payload.is_favorite, 10) === 1;

    this.timeLimit = parseInt(payload.time_limit, 10);
    this.turnsCount = parseInt(payload.turns_count, 10);

    each(payload.questions, o => this.questions.push(new Question(o)));
    this.questionsForDetails = this.questions;

    if (payload.custom_settings) {
      this.settings = new Settings(payload.custom_settings);
    } else {
      this.settings = settings;
    }

    this.scores = parseInt(payload.scores, 10);
    this.scoresForOpenQuestion = parseInt(payload.scores_for_open_question, 10);

    this.statistic = statistic;
    this.setStatuses();
  }

  setStatuses() {
    this.statistic.setStatuses(this.questions);
  }

  setStatistic(statistic: Statistic): void {
    this.statistic = statistic;
  }

  setIsAvailable(status: boolean): void {
    this.isAvailable = status;
  }

  getStatus(): MaterialStatus {
    if (!this.isNew && this.statistic.isChecking) {
      return MaterialStatus.CHECKING;
    }

    if (this.statistic.completed && !this.statistic.isChecking && !this.isNew) {
      if (
        this.settings.testBarrier <= Math.floor((this.getUserScore() / this.getMaxScore()) * 100)
      ) {
        return MaterialStatus.PASSED;
      }

      if (
        this.settings.testBarrier > Math.floor((this.getUserScore() / this.getMaxScore()) * 100)
      ) {
        return MaterialStatus.FAILED;
      }
    }

    if (!this.isNew) {
      return MaterialStatus.ASSIGNED;
    }

    if (this.isNew) {
      return MaterialStatus.NEW;
    }

    return MaterialStatus.ANY;
  }

  markAsViewed() {
    this.isNew = false;
    this.isChecked = false;
  }

  getParts(): object[] {
    const parts: object[] = [];

    const status = this.getStatus();

    // для непрофильного раздела выводим дату прохождения
    // if (!isProfileModule && (isPassed || isFailed) && testStatistic.completeDate !== 0) {
    //   parts.push(localeDateFromTimestamp(testStatistic.completeDate));
    // }

    const turnsLeft = this.turnsCount - this.statistic.currentTurn;

    // в тестах на проверке ничего не выводим
    if (status !== MaterialStatus.CHECKING) {
      // если новый или назначен, пишем только сколько он может заработать
      if (this.isRating && (status === MaterialStatus.NEW || status === MaterialStatus.ASSIGNED)) {
        parts.push([
          'learn_earn_d_points_text',
          this.getMaxScore(),
          { 0: this.getMaxScore().toLocaleString() },
        ]);
      } else if (this.isRating && status === MaterialStatus.PASSED) {
        // тест успешно пройден, если на максимум -- выводим без количества попыток только баллы,
        // если не на максимум -- если есть попытки, выводим сколько и сколько баллов из скольки
        if (this.getUserScore() !== this.getMaxScore() && turnsLeft > 0) {
          parts.push(['learn_d_attempts_more_text', turnsLeft, { 0: turnsLeft }]);
        }

        if (this.getUserScore() < this.getMaxScore()) {
          parts.push([
            'learn_d_from_d_points_text',
            this.getMaxScore(),
            { 0: this.getUserScore().toLocaleString(), 1: this.getMaxScore().toLocaleString() },
          ]);
        } else {
          parts.push([
            'common_point_number_text',
            this.getMaxScore(),
            { 0: this.getMaxScore().toLocaleString() },
          ]);
        }
      } else if (status === MaterialStatus.FAILED) {
        // тест завален
        // если есть попытки -- выводим сколько есть
        if (turnsLeft > 0) {
          parts.push(['learn_d_attempts_more_text', turnsLeft, { 0: turnsLeft.toLocaleString() }]);
        }
      }
    }

    return parts;
  }

  getMaxScore(): number {
    let total = 0;

    each(this.questions, question => {
      if (question.type === QuestionType.OPEN_QUESTION) {
        total += this.scoresForOpenQuestion;
      } else {
        total += this.scores;
      }
    });

    return total;
  }

  getUserScore(): number {
    return this.statistic.userScores;
  }

  getUserPercent(): number {
    return Math.floor((this.getUserScore() / this.getMaxScore()) * 100);
  }

  getTimestamp(): number {
    return this.statistic.timestamp;
  }

  setFavorite(status: boolean): void {
    this.isFavorite = status;
  }

  hasOpenQuestions() {
    return !!find(this.questions, o => o.type === QuestionType.OPEN_QUESTION);
  }

  getQuestionById(id: number): Question | undefined {
    return find(this.questions, o => o.id === id);
  }

  getQuestionPositionById(id: number): number {
    let position = 0;

    each(this.questions, (o, key) => {
      if (o.id === id) {
        position = key;
      }
    });

    return position;
  }

  enableStrictMode(uuid: string, timestamp?: number) {
    this.uuid = uuid;

    if (timestamp) {
      this.statistic.timestamp = timestamp;
    }
  }

  shuffleQuestions() {
    this.questions = shuffle(this.questions);
    each(this.questions, question => {
      question.enableRandomizeAnswers();
      question.shuffleOptions();
    });
  }

  resetUserAnswers() {
    each(this.questions, question => {
      question.clearUserAnswers();
    });
  }

  getSaving(payload: any) {
    const userResults: any = [];
    each(this.questions, question => {
      userResults.push(question.getSaving());
    });

    let userScores = 0;
    each(this.questions, question => {
      if (question.type !== QuestionType.OPEN_QUESTION && question.isUserAnswerCorrect()) {
        userScores += this.scores;
      }
    });

    const strictMode = this.uuid ? { turn_uuid: this.uuid } : {};

    return {
      id: this.id,
      completed: 1,
      current_turn: this.statistic.currentTurn + 1,
      user_scores: userScores,
      start_time: payload.startTime,
      timestamp: this.statistic.timestamp,
      complete_date: getCurrentTimestamp(),
      user_results: userResults,

      ...strictMode,
    };
  }

  getQuestionsForDetails(): QuestionStatistic[] {
    const questions: QuestionStatistic[] = [];
    this.questionsForDetails = [];
    each(this.statistic.userResults, question => {
      const statisticQuestion = question.getQuestionForDetails();

      const dataQuestion = find(this.questions, o => o.id === statisticQuestion.id);

      if (dataQuestion) {
        statisticQuestion.image = dataQuestion.image;
      }

      questions.push(statisticQuestion);
      this.questionsForDetails.push(statisticQuestion);
    });

    return questions;
  }

  getQuestionsForResults(userResults: any): QuestionStatistic[] {
    const questions: QuestionStatistic[] = [];

    each(this.questions, question => {
      const userResult = find(userResults, o => o.id === question.id);

      if (userResult) {
        let status = 'not_answer';

        if (question.isAnswered) {
          status = question.isUserAnswerCorrect() ? 'success' : 'fail';
        }

        questions.push(
          new QuestionStatistic(
            {
              id: question.id,
              comment: userResult.user_comment,
              user_answers: userResult.user_answers,
              status,
            },
            question,
          ),
        );
      }
    });

    return questions;
  }
}
