import BaseWidget from '@/entities/modules/dashboard/BaseWidget';
import { each } from 'lodash';
import VideoMaterial from '@/entities/modules/dashboard/VideoMaterial';
import { InterviewMaterial } from '@/entities/modules/dashboard/InterviewMaterial';
import { LearningProgramMaterial } from '@/entities/modules/dashboard/LearningProgramMaterial';
import { TestMaterial } from '@/entities/modules/dashboard/TestMaterial';
import InfoMaterial from '@/entities/modules/dashboard/InfoMaterial';
import LearningItemsMaterial from '@/entities/modules/dashboard/LearningItemsMaterial';

export default class MonthFocusWidget extends BaseWidget {
  materials: Object[];

  constructor(payload: any) {
    super(payload);

    this.materials = [];
    each(payload.data, material => {
      switch (material.entity_type) {
        case 'interview':
          this.materials.push(new InterviewMaterial(material).getWidget());
          break;
        case 'learning_program':
          this.materials.push(new LearningProgramMaterial(material).getWidget());
          break;
        case 'learning_track':
          this.materials.push(new LearningItemsMaterial(material));
          break;
        case 'test':
          this.materials.push(new TestMaterial(material).getWidget(true));
          break;
        case 'discover':
          this.materials.push(new VideoMaterial(material).getWidget());
          break;
        case 'info':
          this.materials.push(new InfoMaterial(material));
          break;
        default:
          break;
      }
    });
  }
}
