import { ActionTree } from 'vuex';
import favoritesApi from '@/services/favorites';
import { each } from 'lodash';
import { FavoritesState } from './types';
import { RootState } from '../../types';

export const actions: ActionTree<FavoritesState, RootState> = {
  async getFavorites({ state, commit }) {
    if (state.favorites.length === 0) {
      commit('changeFavorites', await favoritesApi.getFavorites());
    }
  },

  async clearFavorites({ commit }) {
    commit('changeFavorites', []);
  },

  async setFavoriteStatus({ state }, { type, id, status, context = null }) {
    await favoritesApi.setFavoriteStatus(type, id, status, context);

    if (status) {
      let position = -1;
      each(state.favorites, (item, index) => {
        if (item.id === id) {
          position = index;
        }
      });

      if (position !== -1) {
        state.favorites.splice(position, 1);
      }
    }
  },
};
