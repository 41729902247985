<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBox"
    fill="none"
    :class="svgClass"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.20292 7.20264C8.01895 3.64954 11.1993 1 15 1C19.4183 1 23 4.58172 23 9C23 12.8007 20.3505 15.9811 16.7974 16.7971C15.9818 20.35 12.8004 23 9 23C4.58172 23 1 19.4183 1 15C1 11.1996 3.64997 8.01816 7.20292 7.20264ZM8.18056 9.05549C8.19067 9.05429 8.20074 9.05295 8.21076 9.05145C8.46903 9.01751 8.73247 9 9 9C12.3137 9 15 11.6863 15 15C15 15.2675 14.9825 15.531 14.9486 15.7892C14.9471 15.7993 14.9457 15.8093 14.9445 15.8194C14.5449 18.7455 12.0357 21 9 21C5.68629 21 3 18.3137 3 15C3 11.9643 5.25452 9.45509 8.18056 9.05549ZM16.993 14.6613C16.8202 10.5124 13.4876 7.17982 9.33866 7.00704C10.1602 4.6732 12.3853 3 15 3C18.3137 3 21 5.68629 21 9C21 11.6147 19.3268 13.8398 16.993 14.6613ZM9.47186 11.1183C9.79702 11.2923 10 11.6312 10 12V16.5H10.5C11.0523 16.5 11.5 16.9477 11.5 17.5C11.5 18.0523 11.0523 18.5 10.5 18.5H7.5C6.94772 18.5 6.5 18.0523 6.5 17.5C6.5 16.9477 6.94772 16.5 7.5 16.5H8V13.8661C7.54703 14.1282 6.96231 13.9962 6.66795 13.5547C6.3616 13.0952 6.48577 12.4743 6.9453 12.1679L8.4453 11.1679C8.75216 10.9634 9.1467 10.9443 9.47186 11.1183Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'coins-02-outline',

  props: {
    height: {
      type: Number,
      default: 24,
    },

    width: {
      type: Number,
      default: 24,
    },

    color: {
      type: String,
      default: 'primary',
    },
  },

  computed: {
    viewBox() {
      return `0 0 ${this.width} ${this.height}`;
    },

    svgClass() {
      return `svg-fill-${this.color}`;
    },
  },
};
</script>
