import { MutationTree } from 'vuex';
import { GiftStoreState } from '@/store/modules/gift-store/types';

export const mutations: MutationTree<GiftStoreState> = {
  changeGifts(state, payload) {
    state.gifts = payload;
  },

  setPoints(state, payload) {
    state.points = payload;
  },

  changeOrders(state, payload) {
    state.orders = payload;
  },

  setMeta(state, payload) {
    state.meta = payload;
  },

  changeCart(state, payload) {
    state.cart = payload;
  },

  changeCurrentPage(state, payload) {
    state.currentPage = payload;
  },
};
