import BaseWidget from '@/entities/modules/dashboard/BaseWidget';
import { each } from 'lodash';
// eslint-disable-next-line import/no-cycle
import Badge from '@/entities/common/Badge';
import Category from '@/entities/common/Category';

export default class BadgesWidget extends BaseWidget {
  badges: Badge[];
  categories: Category[];

  constructor(payload: any) {
    super(payload);

    this.badges = [];
    each(payload.data.badges, badge => {
      this.badges.push(new Badge(badge));
    });

    this.sortBadges();

    this.categories = [];
    each(payload.data.categories, category => {
      this.categories.push(new Category(category));
    });
  }

  sortBadges() {
    this.badges.sort((a, b) => {
      if (a.assignmentId !== 0 && b.assignmentId !== 0) {
        return b.awardDate - a.awardDate;
      }

      if (a.assignmentId !== 0 && b.assignmentId === 0) {
        return -1;
      }

      if (a.assignmentId === 0 && b.assignmentId !== 0) {
        return 1;
      }

      if (a.assignmentId === 0 && b.assignmentId === 0) {
        return b.order - a.order;
      }

      return 0;
    });
  }
}
