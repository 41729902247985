import AnswerReviewer from '@/entities/modules/tasks/AnswerReviewer';

export default class AnswerReviewsComment {
  id: number;
  text: string;
  sendTime: number;
  reviewer: AnswerReviewer;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.text = payload.text;
    this.sendTime = parseInt(payload.send_time, 10);
    this.reviewer = new AnswerReviewer(payload.user);
  }
}
