import { each, find, filter, includes } from 'lodash';
import Program from '@/entities/modules/learning-programs/Program';
import MaterialPdfStatistic from '@/entities/modules/learning-programs/MaterialPdfStatistic';
import MaterialLinkStatistic from '@/entities/modules/learning-programs/MaterialLinkStatistic';
import MaterialScormStatistic from '@/entities/modules/my-team/MaterialScormStatistic';
import MaterialHtmlStatistic from '@/entities/modules/learning-programs/MaterialHtmlStatistic';
import MaterialTestStatistic from '@/entities/modules/learning-programs/MaterialTestStatistic';
import MaterialVideoStatistic from '@/entities/modules/learning-programs/MaterialVideoStatistic';
import MaterialUnknownStatistic from '@/entities/modules/learning-programs/MaterialUnknownStatistic';
import MaterialPdf from '@/entities/modules/learning-programs/MaterialPdf';
import MaterialLink from '@/entities/modules/learning-programs/MaterialLink';
import MaterialScorm from '@/entities/modules/learning-programs/MaterialScorm';
import MaterialHtml from '@/entities/modules/learning-programs/MaterialHtml';
import MaterialTest from '@/entities/modules/learning-programs/MaterialTest';
import MaterialVideo from '@/entities/modules/learning-programs/MaterialVideo';
import MaterialUnknown from '@/entities/modules/learning-programs/MaterialUnknown';
import IStatistic from '@/entities/modules/learning-programs/IStatistic';
import BaseMaterial from '@/entities/modules/learning-programs/BaseMaterial';
import MaterialLongreadStatistic from '@/entities/modules/learning-programs/MaterialLongreadStatistic';
import MaterialLongread from '@/entities/modules/learning-programs/MaterialLongread';
import MaterialDocumentStatistic from '@/entities/modules/learning-programs/MaterialDocumentStatistic';
import MaterialDocument from '@/entities/modules/learning-programs/MaterialDocument';
import ProgramStatistic from '../learning-programs/ProgramStatistic';

export async function createDetailsForLearningProgram(response: any) {
  const statistics: IStatistic[] = [];

  each(response.data.success.statistic.materials, statistic => {
    switch (statistic.type) {
      case 'pdf':
        statistics.push(new MaterialPdfStatistic(statistic));
        break;

      case 'link':
        statistics.push(new MaterialLinkStatistic(statistic));
        break;

      case 'scorm':
        statistics.push(new MaterialScormStatistic(statistic));
        break;

      case 'html':
        statistics.push(new MaterialHtmlStatistic(statistic));
        break;

      case 'test':
        statistics.push(new MaterialTestStatistic(statistic));
        break;

      case 'video':
        statistics.push(new MaterialVideoStatistic(statistic));
        break;

      case 'longread':
        statistics.push(new MaterialLongreadStatistic(statistic));
        break;

      case 'document':
        statistics.push(new MaterialDocumentStatistic(statistic));
        break;

      default:
        statistics.push(new MaterialUnknownStatistic(statistic));
    }
  });

  const materials: BaseMaterial[] = [];
  each(response.data.success.materials, material => {
    const materialId = parseInt(material.id, 10);

    const statistic = find(statistics, o => o.materialId === materialId);

    if (statistic) {
      switch (material.type) {
        case 'pdf':
          materials.push(new MaterialPdf(statistic, material));
          break;

        case 'link':
          materials.push(new MaterialLink(statistic, material));
          break;

        case 'scorm':
          materials.push(new MaterialScorm(statistic, material));
          break;

        case 'html':
          materials.push(new MaterialHtml(statistic, material));
          break;

        case 'test':
          materials.push(new MaterialTest(statistic, material));
          break;

        case 'video':
          materials.push(new MaterialVideo(statistic, material));
          break;

        case 'longread':
          materials.push(new MaterialLongread(statistic, material));
          break;

        case 'document':
          materials.push(new MaterialDocument(statistic, material));
          break;

        default:
          materials.push(new MaterialUnknown(statistic, material));
      }
    }
  });

  const learningProgram = new Program(response.data.success.learning_program);
  const learningProgramStatistics = new ProgramStatistic(
    response.data.success.statistic.materials[0],
  );
  learningProgram.setStatistic(learningProgramStatistics);

  const listOfMaterialIds = learningProgram.getListOfMaterialIds();

  const programMaterials = filter(materials, o => includes(listOfMaterialIds, o.id));
  learningProgram.setMaterials(programMaterials);
  learningProgram.getStatisticByMaterials();

  return learningProgram;
}
