<template>
  <div class="app-sl__wrapper app-sl-mb35">
    <div class="app-sl__line app-sl-w1/3"></div>
  </div>
</template>

<script>
export default {
  name: 'section-loading',
};
</script>
