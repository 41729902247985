import dateProvider from '@/providers/dateProvider';
import { each } from 'lodash';
import QuestionStatistic from '@/entities/modules/competencies/QuestionStatistic';
import Competency from '@/entities/modules/competencies/Competency';

export default class Statistic {
  id: number;
  percent: number;
  startDate?: Date;
  completeDate?: Date;
  completed: boolean;
  currentTurn: number;
  timestamp: number;
  userResults: QuestionStatistic[] = [];
  competencies: Competency[] = [];

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.percent = parseInt(payload.percent, 10);
    this.startDate = dateProvider(parseInt(payload.start_time, 10));
    this.completeDate = dateProvider(parseInt(payload.complete_date, 10));
    this.completed = parseInt(payload.completed, 10) === 1;
    this.currentTurn = parseInt(payload.current_turn, 10);
    this.timestamp = parseInt(payload.timestamp, 10);
    each(payload.user_results, o => this.userResults.push(new QuestionStatistic(o)));
    each(payload.competencies, o => this.competencies.push(new Competency(o)));
  }
}
