import OffsetPagination from '@/entities/common/OffsetPagination';
import PaginationDirection from '@/entities/common/PaginationDirection';

export default class UpDownPagination {
  minOffset: number;
  maxOffset: number;
  limit: number;
  total: number;

  constructor(payload: OffsetPagination) {
    this.minOffset = payload.offset;
    this.maxOffset = payload.offset;
    this.limit = payload.limit;
    this.total = payload.total;
  }

  isFirstPage(): boolean {
    return this.minOffset <= 0;
  }

  isLastPage(): boolean {
    return this.maxOffset + this.limit >= this.total;
  }

  setMaxOffset(payload: OffsetPagination) {
    this.maxOffset = payload.offset;
    this.total = payload.total;
  }

  setMinOffset(payload: OffsetPagination) {
    this.minOffset = payload.offset;
    this.total = payload.total;
  }

  getOffset(paginationDirection: PaginationDirection) {
    if (paginationDirection === PaginationDirection.DOWN) {
      return this.maxOffset + this.limit;
    }

    if (paginationDirection === PaginationDirection.UP) {
      if (this.minOffset < this.limit) {
        return 0;
      }

      return this.minOffset - this.limit;
    }
  }

  getLimit(paginationDirection: PaginationDirection) {
    if (paginationDirection === PaginationDirection.UP && this.minOffset < this.limit) {
      return this.minOffset;
    }

    return this.limit;
  }
}
