import SectionMaterial from '@/entities/modules/learning-programs/SectionMaterial';
import ToggleSectionMaterial from '@/entities/modules/learning-programs/ToggleSectionMaterial';
import IStatistic from '@/entities/modules/learning-programs/IStatistic';
import ImageSource from '@/entities/common/sources/ImageSource';

export default abstract class BaseMaterial {
  abstract id: number;

  abstract isRating: boolean;
  abstract maxScore: number;

  numberingTypeThrough?: string;
  numberingTypeSections?: string;
  numberingTypeHierarchical?: string;

  abstract statistic: IStatistic;
  abstract updatedAtTimestamp: number;

  abstract image?: ImageSource;

  abstract getMaterialType(): string;

  getStatistic(): IStatistic {
    return this.statistic;
  }

  setStatistic(statistic: IStatistic): void {
    this.statistic = statistic;
  }

  setNumberingTypes(through: number, sections: number, position: number): void {
    this.numberingTypeThrough = `${through}. `;
    this.numberingTypeSections = `${sections}. `;
    this.numberingTypeHierarchical = `${position}.${sections}. `;
  }

  addPointsInfo(
    info: Array<unknown>,
    isRating: Boolean,
    maxPoints: number,
    points: number,
  ): Array<unknown> {
    if (isRating && maxPoints > 0) {
      if (points === maxPoints) {
        info.push(['common_point_number_text', maxPoints, { 0: maxPoints.toLocaleString() }]);
      } else {
        info.push(['learn_d_from_d_points_text', points, { 0: points, 1: maxPoints }]);
      }
    }

    return info;
  }

  // eslint-disable-next-line no-unused-vars
  abstract getToggleWidget(sectionMaterial: SectionMaterial, config?: any): ToggleSectionMaterial;

  // eslint-disable-next-line no-unused-vars
  abstract getSaving(payload: any): any;
}
