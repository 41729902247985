<template>
  <div
    class="app-language-select-content"
    :class="{ 'app-language-select-content--static': isStaticPosition }"
  >
    <div
      v-click-outside="closeLanguageSelect"
      class="app-language-select"
      :class="{
        'app-language-select--active': showLanguageList,
        'app-language-select--static': isStaticPosition,
      }"
      @click="showLanguageList = !showLanguageList"
    >
      <div class="app-language-select__active">
        <img :src="getIcon(currentLanguageType)" />
        <span v-if="showLanguageName">{{ languageName }}</span>
        <downArrowSvg />
      </div>
      <ul
        v-if="showLanguageList"
        class="app-language-list"
      >
        <li
          v-for="language in languageList"
          :key="language.id"
          class="app-language-list__item"
          @click="setLanguage(language)"
        >
          <img :src="getIcon(language.id)" />
          <span>
            {{ language.name }}
          </span>
          <checkMarkSvg v-if="language.id === currentLanguageType" />
        </li>
      </ul>
    </div>
    <a
      v-if="showAcceptButton"
      class="v2-button v2-button--view-filled"
      @click="setContentLanguage"
    >
      {{ $t('common_confirm_button') }}
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ClickOutside from 'vue-click-outside';
import checkMarkSvg from '@/components/svg/icons/check-mark.vue';
import downArrowSvg from '@/components/svg/icons/down-arrow.vue';
import { setCookie } from '@/providers/js/cookieProvider';
import { sendEvent } from '@/providers/analyticsProvider';

export default {
  name: 'language-select',

  components: {
    checkMarkSvg,
    downArrowSvg,
  },

  directives: {
    ClickOutside,
  },

  props: {
    showLanguageName: {
      type: Boolean,
      default: false,
    },

    isContentLanguageSelect: {
      type: Boolean,
      default: false,
    },

    showAcceptButton: {
      type: Boolean,
      default: false,
    },

    isStaticPosition: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    showLanguageList: false,
    activeLanguage: '',
  }),

  computed: {
    ...mapGetters('settings', [
      'defaultInterfaceLanguage',
      'defaultMaterialsLanguage',
      'interfaceLanguagesList',
      'materialsLanguagesList',
      'activeInterfaceLanguage',
      'activeMaterialsLanguage',
    ]),

    languageList() {
      if (this.isContentLanguageSelect) {
        return this.getSortLanguageList(
          this.materialsLanguagesList,
          this.activeMaterialsLanguage,
          this.defaultMaterialsLanguage,
        );
      }

      return this.getSortLanguageList(
        this.interfaceLanguagesList,
        this.activeInterfaceLanguage,
        this.defaultInterfaceLanguage,
      );
    },

    currentLanguageType() {
      if (this.isContentLanguageSelect) {
        if (!this.materialsLanguagesList.find(lang => lang.id === this.activeMaterialsLanguage)) {
          return this.defaultMaterialsLanguage;
        }

        return this.activeMaterialsLanguage;
      }

      return this.activeInterfaceLanguage;
    },

    languageName() {
      if (
        this.isContentLanguageSelect &&
        !this.materialsLanguagesList.find(lang => lang.id === this.activeMaterialsLanguage)
      ) {
        return this.interfaceLanguagesList.find(
          language => language.id === this.defaultMaterialsLanguage,
        ).name;
      }

      return this.interfaceLanguagesList.find(language => language.id === this.currentLanguageType)
        .name;
    },

    isLogin() {
      return this.$route.name === 'login' || this.$route.name === 'login-entry-point';
    },
  },

  methods: {
    setLanguage(language) {
      if (this.showAcceptButton) {
        this.$store.commit('settings/setActiveMaterialsLanguage', language.id);

        return;
      }

      if (language.id === this.currentLanguageType) {
        return;
      }

      if (this.isLogin) {
        setCookie('materials_lang', language.id);
        setCookie('interface_lang', language.id);
        sendEvent(this.$analytics, 'settings_change_language_content');
        sendEvent(this.$analytics, 'settings_change_language_interface');
      }

      if (this.isContentLanguageSelect) {
        setCookie('materials_lang', language.id);
        sendEvent(this.$analytics, 'settings_change_language_content');
      } else {
        setCookie('interface_lang', language.id);
        sendEvent(this.$analytics, 'settings_change_language_interface');
      }

      location.reload();
    },

    closeLanguageSelect() {
      this.showLanguageList = false;
    },

    getSortLanguageList(langList, activeLang, defaultLang) {
      const arr = [];

      if (this.isContentLanguageSelect) {
        if (langList.find(el => el.id === activeLang)) {
          arr.push(langList.find(el => el.id === activeLang));
        }
      } else {
        arr.push(langList.find(el => el.id === activeLang));
      }

      if (activeLang !== defaultLang) {
        arr.push(langList.find(el => el.id === defaultLang));
      }

      arr.push(
        ...langList
          .sort((a, b) => {
            if (a.name > b.name) {
              return 1;
            }

            if (a.name < b.name) {
              return -1;
            }

            return 0;
          })
          .filter(el => el.id !== activeLang && el.id !== defaultLang),
      );

      return arr;
    },

    getIcon(language) {
      try {
        // eslint-disable-next-line global-require
        return require(`@/assets/images/language/${language}.png`); // eslint-disable-line import/no-dynamic-require
      } catch (e) {
        console.error(`LangeuageSelect: image for ${language} not found. Check assets`);

        return '';
      }
    },

    setContentLanguage() {
      setCookie('materials_lang', this.activeMaterialsLanguage);
      sendEvent(this.$analytics, 'settings_change_language_content');

      location.reload();
    },
  },
};
</script>

<style lang="scss">
.app-language-select-content {
  display: flex;
  padding: 16px 0 0 16px;
  position: absolute;
  top: 0;
  left: 0;

  &--static {
    position: static;
    width: 100%;
    padding: 0;
  }

  @media (min-width: 980px) {
    left: initial;
    right: 0;
    padding-right: 16px;
    z-index: 1;
    display: block;
  }
}

.app-language-select {
  position: relative;
  height: 36px;
  width: 68px;
  line-height: 36px;
  border-radius: 8px;
  border: 1px solid #c9cdd4;
  padding-left: 9px;
  display: flex;
  align-items: center;
  cursor: pointer;

  @media (min-width: 980px) {
    left: initial;
    top: 0;
    width: 76px;
    height: 48px;
    padding-left: 14px;
  }

  img {
    width: 24px;
  }

  div {
    display: flex;
    align-items: center;
  }

  &--static {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;

    .app-language-list {
      width: 100%;
      left: 0;
    }
  }

  &--active {
    border-color: var(--primary);
    box-shadow: 0 0 0 4px rgba(0, 166, 163, 0.2);
    z-index: 1;
  }

  &__active {
    img {
      width: 22px;
    }

    span {
      padding-left: 9px;
    }

    svg {
      position: absolute;
      right: 14px;
      width: 11px;
    }
  }
}

.app-language-list {
  position: absolute;
  top: 50px;
  background-color: var(--surfaceWhite);
  border: 1px solid #e5e7eb;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.08), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  width: 292px;
  max-height: 336px;
  overflow: auto;
  left: -5px;

  @media (min-width: 980px) {
    left: initial;
    right: -5px;
  }

  @media (min-width: 1280px) {
    left: initial;
    right: -5px;
    top: 63px;
  }

  &__item {
    padding: 13px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: var(--surfaceHighEmphasis);
    border-bottom: 1px solid #e5e7eb;
    display: flex;
    align-items: center;
    cursor: pointer;

    @media (min-width: 1280px) {
      font-size: 16px;
      line-height: 24px;
    }

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background-color: #f9fafb;
    }

    img {
      width: 24px;
    }

    span {
      width: auto;
      padding-left: 9px;
    }

    svg {
      margin-left: auto;
    }
  }
}

.app-language-modal .v2-button {
  margin-top: 32px;
  height: 48px;
  max-height: 48px;
  color: #ffffff;
  width: 100%;
  max-width: 100%;
}
</style>
