// @ts-ignore
import { createMaterialCardWidget } from '@/entities/common/MaterialCardWidget';
import slugProvider from '@/providers/slugProvider';
import { has } from 'lodash';
import CustomRoute from '@/entities/common/CustomRoute';
import ApiName from '../../common/ApiName';
import ImageSource, { createImageFromString } from '../../common/sources/ImageSource';

export class FavoriteMaterial {
  id: number;
  name: string;
  moduleId?: number;
  moduleName?: string;
  type: string;
  createdAt: number;
  categoryId?: number;
  subcategoryId?: number;
  startDate?: number;
  image?: ImageSource;
  isNew: boolean;
  isRating: boolean;
  isRequired: boolean;
  status: string;
  isInProgress: boolean;
  isPassed: boolean;
  isChecking: boolean;
  isFailed: boolean;
  isAssigned: boolean;
  isChecked: boolean;
  percent: number;
  maxPoints: number;
  passingDeadline: number;
  passingNoticeDeadline: number;
  endTime: number;

  constructor(payload: any) {
    this.id = parseInt(payload.entity_id, 10);

    if (has(payload, 'category_id')) {
      this.categoryId = parseInt(payload.category_id, 10);
    }

    if (has(payload, 'subcategory_id')) {
      this.subcategoryId = parseInt(payload.subcategory_id, 10);
    }

    if (has(payload, 'start_date')) {
      this.startDate = parseInt(payload.start_date, 10);
    }

    if (has(payload, 'module_id')) {
      this.moduleId = parseInt(payload.module_id, 10);
    }

    if (has(payload, 'module_name')) {
      this.moduleName = payload.module_name;
    }
    this.name = payload.name;
    this.type = payload.entity_type;
    this.createdAt = parseInt(payload.created_at, 10);
    this.isNew = parseInt(payload.is_new, 10) === 1;
    this.isRating = parseInt(payload.is_rating, 10) === 1;
    this.isRequired = parseInt(payload.is_required, 10) === 1;
    this.status = has(payload, 'status') ? payload.status : payload.progress_status;
    this.isInProgress = this.status === 'in_progress' || this.status === 'progress';
    this.isPassed = this.status === 'completed';
    this.isChecking = this.status === 'checking';
    this.isFailed = this.status === 'fail';
    this.isAssigned = this.status === 'appointed';
    this.isChecked = parseInt(payload.is_checked, 10) === 1;
    this.percent = parseInt(payload.percent, 10);
    this.maxPoints = parseInt(payload.max_points, 10);
    this.passingDeadline = parseInt(payload.deadline_for_passing_at, 10);
    this.passingNoticeDeadline = parseInt(payload.deadline_for_passing_notice_for_time, 10);
    this.endTime = parseInt(payload.end_time, 10);

    switch (this.type) {
      case ApiName.TEAM:
      case ApiName.VIDEO:
      case ApiName.GIFT_STORE:
        this.image = createImageFromString(payload.image.origin.url);
        break;

      default:
        const imageUrl = payload.image_wide?.origin?.url || payload.image?.origin?.url;

        this.image = imageUrl || createImageFromString(imageUrl);
        break;
    }
  }

  getWidget() {
    return createMaterialCardWidget({
      id: this.id,
      name: this.name,
      parts: [[this.getLokaliseKey()]],
      to: this.getLocation(),
      image: this.image,
      apiName: this.type,
      isFavorite: true,
      type: this.type,
      isNew: this.isNew,
      isRating: this.isRating,
      isRequired: this.isRequired,
      status: this.status,
      isInProgress: this.isInProgress,
      isPassed: this.isPassed,
      isChecking: this.isChecking,
      isFailed: this.isFailed,
      isAssigned: this.isAssigned,
      isChecked: this.isChecked,
      percent: this.percent,
      maxPoints: this.maxPoints,
      passingDeadline: this.passingDeadline,
      passingNoticeDeadline: this.passingNoticeDeadline,
      endTime: this.endTime,
    });
  }

  getLokaliseKey() {
    switch (this.type) {
      case ApiName.TEST:
        return 'common_test_text';
      case ApiName.INTERVIEW:
        return 'common_survey_text';
      case ApiName.COMPETENCY:
        return 'tests_competencies_text';
      case ApiName.INFORMATION_CATEGORY:
        return 'info_folder_navigation_text';
      case ApiName.INFORMATION:
        return 'info_screen_title_text';
      case ApiName.VIDEO:
        return 'discover_screen_title_text';
      case ApiName.LEARNING_PROGRAM:
        return 'common_learning_journeys_text';
      case ApiName.EVENT:
        return 'common_event_title_text';
      case ApiName.TEAM:
        return 'myteam_section_name_label';
      case ApiName.GIFT_STORE:
        return 'common_gift_text';
      case ApiName.TASK:
        return 'common_task_text';
      default:
        return '';
    }
  }

  getLocation() {
    switch (this.type) {
      case ApiName.INFORMATION:
      case ApiName.INFORMATION_CATEGORY:
        if (this.categoryId && this.subcategoryId) {
          return {
            name: CustomRoute.TO_INFORMATION_MATERIAL,
            params: {
              categoryId: this.categoryId,
              categorySlug: 'not-provided',
              subcategoryId: this.subcategoryId,
              subcategorySlug: 'not-provided',
              id: this.id,
              slug: slugProvider(this.name),
            },
          };
        }

        if (this.categoryId && !this.subcategoryId) {
          return {
            name: CustomRoute.TO_INFORMATION_SUBCATEGORY,
            params: {
              categoryId: this.categoryId,
              categorySlug: 'not-provided',
              id: this.id,
              slug: slugProvider(this.name),
            },
          };
        }

        return {
          name: CustomRoute.TO_INFORMATION_CATEGORY,
          params: {
            id: this.id,
            slug: slugProvider(this.name),
          },
        };

      case ApiName.TEST:
        return {
          name: CustomRoute.TO_TESTS_MATERIAL,
          params: {
            id: this.id,
            slug: slugProvider(this.name),
          },
        };

      case ApiName.COMPETENCY:
        return {
          name: CustomRoute.TO_COMPETENCIES_MATERIAL,
          params: {
            id: this.id,
            slug: slugProvider(this.name),
          },
        };

      case ApiName.INTERVIEW:
        return {
          name: CustomRoute.TO_INTERVIEWS_MATERIAL,
          params: {
            id: this.id,
            slug: slugProvider(this.name),
          },
        };

      case ApiName.LEARNING_PROGRAM:
        return {
          name: CustomRoute.TO_LEARNING_PROGRAMS_MATERIAL,
          params: {
            id: this.id,
            slug: slugProvider(this.name),
            moduleId: this.moduleId,
            moduleSlug: slugProvider(this.moduleName),
          },
        };

      case ApiName.VIDEO:
        return {
          name: CustomRoute.TO_VIDEOS_MATERIAL,
          params: {
            id: this.id,
            slug: slugProvider(this.name),
          },
        };

      case ApiName.EVENT:
        return {
          name: this.startDate
            ? CustomRoute.TO_EVENTS_SCHEDULED_MATERIAL
            : CustomRoute.TO_EVENTS_PLANS_MATERIAL,
          params: {
            id: this.id,
            slug: slugProvider(this.name),
          },
        };

      case ApiName.TEAM:
        return {
          name: CustomRoute.TO_MY_TEAM_EMPLOYEE_MATERIAL,
          params: {
            managerId: 0,
            id: this.id,
            slug: slugProvider(this.name),
          },
        };

      case ApiName.GIFT_STORE:
        return {
          name: CustomRoute.TO_GIFT_STORE,
          query: {
            giftId: this.id,
          },
        };

      case ApiName.TASK:
        return {
          name: CustomRoute.TO_TASKS_MATERIAL,
          params: {
            id: this.id,
          },
        };

      case ApiName.LEARNING_TRACK:
        return {
          name: CustomRoute.TO_LEARNING_TRACK,
          params: {
            moduleId: this.moduleId,
            id: this.id,
          },
        };

      default:
        return {};
    }
  }
}
