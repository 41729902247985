// @ts-ignore
import getActiveLanguage from '@/providers/languageProvider';

import ru from './assets/locale/ru.json';
import en from './assets/locale/en.json';
import fr from './assets/locale/fr.json';
import et from './assets/locale/et.json';
import pl from './assets/locale/pl.json';
import kk from './assets/locale/kk.json';
import uk from './assets/locale/uk.json';
import ka from './assets/locale/ka.json';
import hy from './assets/locale/hy.json';
import uz from './assets/locale/uz.json';
import mn from './assets/locale/mn.json';
import az from './assets/locale/az.json';
import ro from './assets/locale/ro.json';
import tr from './assets/locale/tr.json';
import zh from './assets/locale/zh.json';
import ky from './assets/locale/ky.json';
import de from './assets/locale/de.json';
import ar from './assets/locale/ar.json';
import tg from './assets/locale/tg.json';
import hk from './assets/locale/hk.json';

/**
 * Вернет какую локаль стоит отображать пользователю
 * с учетом языка браузера и доступных к использованию локалей
 *
 * Функция вернет английскую локаль, если не найдет нужную в доступных к использованию
 *
 * @returns {string}
 */
const getUserInterfaceLocale = () => {
  const locale = getActiveLanguage('interface_lang');

  switch (locale) {
    case 'ru':
    case 'en':
    case 'fr':
    case 'et':
    case 'pl':
    case 'kk':
    case 'uk':
    case 'ka':
    case 'hy':
    case 'uz':
    case 'mn':
    case 'az':
    case 'ro':
    case 'tr':
    case 'zh':
    case 'ky':
    case 'de':
    case 'ar':
    case 'tg':
    case 'hk':
      return locale;
    default:
      return 'en';
  }
};

const getUserMaterialsLocale = () => {
  const locale = getActiveLanguage('materials_lang');

  switch (locale) {
    case 'ru':
    case 'en':
    case 'fr':
    case 'et':
    case 'pl':
    case 'kk':
    case 'uk':
    case 'ka':
    case 'hy':
    case 'uz':
    case 'mn':
    case 'az':
    case 'ro':
    case 'tr':
    case 'zh':
    case 'ky':
    case 'de':
    case 'ar':
    case 'tg':
    case 'hk':
      return locale;
    default:
      return 'en';
  }
};

/**
 * Вернет json с ключами из lokalise
 * @returns {Array}
 */
const getUserLocaleJson = () => {
  const userLocale = getUserInterfaceLocale();

  switch (userLocale) {
    case 'ru':
      return ru;
    case 'en':
      return en;
    case 'fr':
      return fr;
    case 'et':
      return et;
    case 'pl':
      return pl;
    case 'kk':
      return kk;
    case 'uk':
      return uk;
    case 'ka':
      return ka;
    case 'hy':
      return hy;
    case 'uz':
      return uz;
    case 'mn':
      return mn;
    case 'az':
      return az;
    case 'ro':
      return ro;
    case 'tr':
      return tr;
    case 'zh':
      return zh;
    case 'ky':
      return ky;
    case 'de':
      return de;
    case 'ar':
      return ar;
    case 'tg':
      return tg;
    case 'hk':
      return hk;
    default:
      return en;
  }
};

export const userLocale = getUserInterfaceLocale();
export const userMaterialsLocale = getUserMaterialsLocale();
export const userLocaleJson = getUserLocaleJson();

/**
 * В зависимости от языка будем по разному обрабатывать форму слова
 * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
 * @returns {number} a final choice index to select plural word by
 */
export function getChoiceIndex(choice: any) {
  // @ts-ignore
  if (this.locale !== 'ru') {
    return choice > 1 ? 1 : 0;
  }

  return getRuChoiceIndex(choice);
}

export function getRuChoiceIndex(choice: any) {
  // i % 10 = 1 and
  // i % 100 != 11
  if (choice % 10 === 1 && choice % 100 !== 11) {
    return 0;
  }

  // i % 10 = 2..4 and
  // i % 100 != 12..14
  if (choice % 10 > 1 && choice % 10 < 5 && !(choice % 100 > 11 && choice % 100 < 15)) {
    return 1;
  }

  // i % 10 = 0 or
  // i % 10 = 5..9 or
  // i % 100 = 11..14
  if (
    choice % 10 === 0 ||
    (choice % 10 > 4 && choice % 10 < 10) ||
    (choice % 100 > 10 && choice % 100 < 15)
  ) {
    return 2;
  }

  return 3;
}
