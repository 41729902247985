import FeedbackStatus from '@/entities/modules/learning-programs/feedback/FeedbackStatus';

export default class FeedbackItem {
  id: number;
  learningProgramId: number;
  userId: number;
  userName?: string;
  mark: number;
  status: string;
  comment?: string;
  // если нам нужен только один размер картинки можно хранить одну url стрингой
  image: object;
  createdAt: number;
  updatedAt: number;

  constructor(payload: any) {
    this.id = payload ? parseInt(payload.id, 10) : 0;
    this.learningProgramId = payload ? parseInt(payload.learning_program_id, 10) : 0;
    this.userId = payload ? parseInt(payload.user_id, 10) : 0;
    this.userName = payload ? payload.user_name : '';
    this.mark = payload ? parseInt(payload.mark, 10) : 0;
    this.status = payload ? payload.status : '';
    this.comment = payload ? payload.comment : '';
    this.image = payload ? payload.image : null;
    this.createdAt = payload ? payload.created_at : 0;
    this.updatedAt = payload ? payload.updated_at : 0;
  }

  getSaving(): any {
    return {
      learning_program_id: this.learningProgramId,
      user_id: this.userId,
      mark: this.mark,
      comment: this.comment,
    };
  }

  getFeedbackStatus(): FeedbackStatus {
    if (this.status === 'hidden_comment') {
      return FeedbackStatus.HIDDEN_COMMENT;
    }

    return FeedbackStatus.ACTIVE;
  }
}
