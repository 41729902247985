<template>
  <a
    v-if="data && data.isAvailable"
    class="app-profile-head-anketa-new"
    @click.prevent="goToQuestionnaire()"
  >
    <div>
      <div
        v-if="data.status === 'filling'"
        :class="[`app-profile-head-anketa-status__${data.status}`]"
        class="app-profile-head-anketa-status"
      >
        <p class="app-profile-head-anketa__text">
          {{ $t('profile_personal_data_status_fill_in_form_title_text') }}
        </p>
        <FillingSvg />
      </div>
      <div
        v-if="data.status === 'reject'"
        :class="[`app-profile-head-anketa-status__${data.status}`]"
        class="app-profile-head-anketa-status"
      >
        <p class="app-profile-head-anketa__text">
          {{ $t('profile_personal_data_status_fill_in_form_again_title_text') }}
        </p>
        <RejectEmptySvg v-if="data.comment === ''" />
        <RejectCommentSvg v-if="data.comment !== ''" />
      </div>
      <div
        v-if="data.status === 'check'"
        :class="[`app-profile-head-anketa-status__${data.status}`]"
        class="app-profile-head-anketa-status"
      >
        <p class="app-profile-head-anketa__text">
          {{ $t('profile_personal_data_status_under_verification_title_text') }}
        </p>
        <CheckingSvg />
      </div>
      <div
        v-if="data.status === 'confirm' && isProfile"
        :class="[`app-profile-head-anketa-status__${data.status}`]"
        class="app-profile-head-anketa-status"
      >
        <p class="app-profile-head-anketa__text">
          {{ $t('profile_personal_data_status_form_verified_title_text') }}
        </p>
        <ConfirmEmptySvg v-if="data.comment === ''" />
        <ConfirmCommentSvg v-if="data.comment !== ''" />
      </div>
      <div
        v-if="data.status === 'blocked' && isProfile"
        :class="[`app-profile-head-anketa-status__${data.status}`]"
        class="app-profile-head-anketa-status"
      >
        <p class="app-profile-head-anketa__text">
          {{ $t('profile_personal_data_status_form_blocked_title_text') }}
        </p>
        <BlockedEmptySvg v-if="data.comment === ''" />
        <BlockedCommentSvg v-if="data.comment !== ''" />
      </div>
    </div>
  </a>
</template>

<script>
import FillingSvg from '@/components/svg/icons/profile-anketa/filling';
import CheckingSvg from '@/components/svg/icons/profile-anketa/checking';
import RejectEmptySvg from '@/components/svg/icons/profile-anketa/reject-empty';
import RejectCommentSvg from '@/components/svg/icons/profile-anketa/reject-comment';
import ConfirmEmptySvg from '@/components/svg/icons/profile-anketa/confirm-empty';
import ConfirmCommentSvg from '@/components/svg/icons/profile-anketa/confirm-comment';
import BlockedEmptySvg from '@/components/svg/icons/profile-anketa/blocked-empty';
import BlockedCommentSvg from '@/components/svg/icons/profile-anketa/blocked-comment';
import CustomRoute from '@/entities/common/CustomRoute';
import { routeTo } from '@/providers/routerLinkProvider';

export default {
  name: 'profile-anketa',

  components: {
    FillingSvg,
    CheckingSvg,
    RejectEmptySvg,
    RejectCommentSvg,
    ConfirmEmptySvg,
    ConfirmCommentSvg,
    BlockedEmptySvg,
    BlockedCommentSvg,
  },

  props: {
    data: {
      type: Object,
      default: null,
    },
  },

  computed: {
    isProfile() {
      return this.$route.name === CustomRoute.TO_DASHBOARD_PROFILE_INFO;
    },
  },

  methods: {
    goToQuestionnaire() {
      if (this.data.isAvailable && this.data.status === 'filling') {
        this.$router.push(routeTo(CustomRoute.TO_DASHBOARD_PROFILE_QUESTIONNAIRE));

        return;
      }

      if (this.data.isAvailable && this.data.status === 'confirm') {
        this.$store.dispatch('helpers/showNotification', {
          title: this.$t('profile_personal_data_status_form_verified_title_text'),
          message: this.$t('profile_personal_data_form_verified_if_changes_fill_in_again_text'),
          timeout: 'infinite',
          buttons: [
            {
              label: this.$t('profile_personal_data_fill_in_button_text'),
              callback: () =>
                this.$router.push(routeTo(CustomRoute.TO_DASHBOARD_PROFILE_QUESTIONNAIRE)),
            },
            {
              label: this.$t('common_cancel_button'),
            },
          ],
        });
      } else if (this.data.isAvailable && this.data.status === 'reject') {
        this.$store.dispatch('helpers/showNotification', {
          title: this.$t('profile_personal_data_form_wasnot_verified_title_text'),
          message: this.$t('profile_personal_data_status_fill_in_form_again_title_text'),
          timeout: 'infinite',
          buttons: [
            {
              label: this.$t('profile_personal_data_fill_in_again_button_text'),
              callback: () =>
                this.$router.push(routeTo(CustomRoute.TO_DASHBOARD_PROFILE_QUESTIONNAIRE)),
            },
            {
              label: this.$t('common_cancel_button'),
            },
          ],
        });
      } else if (this.data.isAvailable && this.data.status === 'check') {
        this.$store.dispatch('helpers/showNotification', {
          title: this.$t('profile_personal_data_thank_you_title_text'),
          message: this.$t('profile_personal_data_we_received_form_text'),
        });
      } else if (this.data.isAvailable && this.data.status === 'blocked') {
        this.$store.dispatch('helpers/showNotification', {
          title: this.$t('profile_personal_data_status_form_blocked_title_text'),
          message: this.data.comment !== '' ? this.data.comment : '',
        });
      }
    },
  },
};
</script>
