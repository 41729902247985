import CorrectName from '@/entities/common/CorrectName';
import CustomRoute from '@/entities/common/CustomRoute';

export default [
  {
    path: '/events/scheduled',
    name: CustomRoute.TO_EVENTS_SCHEDULED,
    meta: {
      title: 'events_section_title',
      moduleCode: CorrectName.EVENT,
      eventOnEnter: 'events_open_allevents_tab_btn',
    },
    component: () => import(/* webpackChunkName: "events" */ '@/views/events/index.vue'),
  },
  {
    path: '/events/plans',
    name: CustomRoute.TO_EVENTS_PLANS,
    meta: {
      title: 'events_section_title',
      moduleCode: CorrectName.EVENT,
      eventOnEnter: 'events_open_plans_tab_btn',
    },
    component: () => import(/* webpackChunkName: "events" */ '@/views/events/index.vue'),
  },
  {
    path: '/events/scheduled/:id-:slug',
    name: CustomRoute.TO_EVENTS_SCHEDULED_MATERIAL,
    meta: {
      title: 'events_section_title',
      moduleCode: CorrectName.EVENT,
      eventOnEnter: 'events_open_btn',
    },
    component: () => import(/* webpackChunkName: "events" */ '@/views/events/show.vue'),
  },
  {
    path: '/events/plans/:id-:slug',
    name: CustomRoute.TO_EVENTS_PLANS_MATERIAL,
    meta: {
      title: 'events_section_title',
      moduleCode: CorrectName.EVENT,
      eventOnEnter: 'events_open_btn',
    },
    component: () => import(/* webpackChunkName: "events" */ '@/views/events/show.vue'),
  },
];
