import CustomRoute from '@/entities/common/CustomRoute';
import ApiName from '@/entities/common/ApiName';
import CorrectName from '@/entities/common/CorrectName';

export const LearningItemTypes = {
  PROGRAM: 'learning_program',
  TRACK: 'learning_track',
};

export type LearningItemType = typeof LearningItemTypes[keyof typeof LearningItemTypes];

export function getApiType(type: LearningItemType | undefined) {
  if (!type) {
    return '';
  }

  const typesMap = new Map([
    [LearningItemTypes.PROGRAM, 'learning_program'],
    [LearningItemTypes.TRACK, 'learning_track'],
  ]);

  return typesMap.get(type) || '';
}

export function getRouteName(type: LearningItemType): CustomRoute {
  const typesMap = new Map([
    [LearningItemTypes.PROGRAM, CustomRoute.TO_LEARNING_PROGRAMS_MATERIAL],
    [LearningItemTypes.TRACK, CustomRoute.TO_LEARNING_TRACK],
  ]);

  return typesMap.get(type) || CustomRoute.TO_LEARNING_MATERIALS;
}

export function getApiName(type: LearningItemType): ApiName {
  const typesMap = new Map([
    [LearningItemTypes.PROGRAM, ApiName.LEARNING_PROGRAM],
    [LearningItemTypes.TRACK, ApiName.LEARNING_TRACK],
  ]);

  return typesMap.get(type) || ApiName.LEARNING_MATERIALS;
}

export function getCorrectName(type: LearningItemType): CorrectName {
  const typesMap = new Map([
    [LearningItemTypes.PROGRAM, CorrectName.LEARNING_PROGRAMS],
    [LearningItemTypes.TRACK, CorrectName.LEARNING_TRACK],
  ]);

  return typesMap.get(type) || CorrectName.LEARNING_MATERIALS;
}

export function getApiId(type: string | undefined) {
  if (!type) {
    return '';
  }

  const typesMap = new Map([
    [ApiName.LEARNING_PROGRAM, 'learning_program'],
    [ApiName.LEARNING_TRACK, 'learning_track'],
  ]);

  return typesMap.get(type as ApiName);
}
