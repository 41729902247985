<template>
  <base-home-widget
    :widget-name="monthFocus.name"
    :count-text="countText"
  >
    <template #content>
      <app-slider
        :columns="ww4Columns"
        :column-size="ww4ColumnsForIsX"
      >
        <template v-for="(item, i) in monthFocus.materials">
          <AppLinkCard
            v-if="item.type === 'link'"
            :key="i"
            :info-material="item"
          />

          <AppVideoCard
            v-else-if="item.type === 'video'"
            :key="i"
            :video="item"
            :event-label="'dashboard_focus_widget_material_btn'"
          />

          <app-material-card
            v-else
            :key="i"
            :event-label="'dashboard_focus_widget_material_btn'"
            :has-outer-footer="true"
            :is-stack="item.type === 'learning_track'"
            :widget="item"
          >
            <template
              v-if="item.type === 'learning_track'"
              #badge
            >
              <div class="app-material-card__badge">
                <div class="app-material-card__badge-icon">
                  <LearningTrackIcon />
                </div>
                <span class="app-material-card__badge-label">
                  {{ $tc('common_trajectories_default_text') }}
                </span>
              </div>
            </template>
          </app-material-card>
        </template>
      </app-slider>
    </template>
  </base-home-widget>
</template>

<script>
import { mapGetters } from 'vuex';
import { each, find } from 'lodash';
import AppSlider from '@/components/helpers/slider.vue';
import AppMaterialCard from '@/components/helpers/cards/material-card.vue';
import AppVideoCard from '@/components/helpers/cards/material-card-video.vue';
import AppLinkCard from '@/components/helpers/cards/material-card-link.vue';
import MonthFocusWidget from '@/entities/modules/dashboard/MonthFocusWidget';
import BaseHomeWidget from '@/components/app/home/base-home-widget.vue';
import LearningTrackIcon from '@/components/svg/icons/learning-track.vue';

export default {
  components: {
    LearningTrackIcon,
    AppSlider,
    AppMaterialCard,
    AppVideoCard,
    AppLinkCard,
    BaseHomeWidget,
  },

  props: {
    monthFocus: {
      type: MonthFocusWidget,
      default: null,
    },
  },

  computed: {
    ...mapGetters('settings', ['modulesList']),

    ...mapGetters('helpers', ['ww4Columns', 'ww4ColumnsForIsX']),

    countText() {
      return this.$tc('common_d_materials_text_caps', this.monthFocus.materials.length, {
        0: this.monthFocus.materials.length,
      });
    },
  },

  created() {
    each(this.monthFocus.materials, item => {
      // eslint-disable-next-line no-param-reassign
      item.tagLabel = this.getWidgetName(item);
    });
  },

  methods: {
    /**
     * Вернет название виджета
     * @param type
     * @returns {*|TranslateResult}
     */
    getWidgetName(item) {
      let module;

      switch (item.type) {
        case 'interviews':
          module = find(this.modulesList, o => o.code === 'evaluations');

          return module && module.title ? module.title : this.$t('common_surveys_text');

        case 'link':
          module = find(this.modulesList, o => o.code === 'information');

          return module && module.title ? module.title : this.$t('learn_course_hyperlink_text');

        case 'tests':
          module = find(this.modulesList, o => o.code === 'evaluations');

          return module && module.title ? module.title : this.$t('tests_test_tab');

        case 'video':
          module = find(this.modulesList, o => o.code === 'video');

          return module && module.title ? module.title : this.$t('discover_screen_title_text');

        case 'document':
          module = find(this.modulesList, o => o.code === 'information');

          return module && module.title ? module.title : this.$t('info_screen_title_text');

        case 'learning-program':
          module = find(this.modulesList, o => o.id === item.moduleId);

          return module?.title
            ? module.title
            : item.moduleName || this.$t('common_learning_journeys_text');

        default:
          return '';
      }
    },
  },
};
</script>
