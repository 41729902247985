<template>
  <card16x9 class="home-my-results">
    <div class="home-my-results__header">
      <router-link :to="linkTo">
        <span class="home-my-results__module-name">{{ entity.getName() }}</span>
        <span
          v-if="isShowPassedPercent"
          class="home-my-results__module-percent"
        >
          {{ passedPercent }}
        </span>
      </router-link>
    </div>
    <div
      v-if="!entity.isBlocked"
      class="home-my-results__body"
    >
      <div class="home-my-results__legend">
        <table>
          <tbody v-if="!isEventsEntity">
            <tr>
              <td>{{ entity.notStarted }}</td>
              <td>{{ getSectionName($t('common_assigned_many_lowcase_text')) }}</td>
            </tr>
            <tr v-if="entity.type !== 'interviews'">
              <td :class="{ 'home-my-results__legend--failed': entity.fail > 0 }">
                {{ entity.fail }}
              </td>
              <td>{{ getSectionName($t('common_failed_many_lowcase_text')) }}</td>
            </tr>
            <tr>
              <td :class="{ 'home-my-results__legend--passed': entity.success > 0 }">
                {{ entity.success }}
              </td>
              <td>{{ getSectionName($t('common_passed_many_lowcase_text')) }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td :class="{ 'home-my-results__legend--request-success': entity.request > 0 }">
                {{ entity.request }}
              </td>
              <td>{{ $t('myteam_events_under_consideration_shortened_text') }}</td>
            </tr>
            <tr>
              <td :class="{ 'home-my-results__legend--request': entity.requestSuccess > 0 }">
                {{ entity.requestSuccess }}
              </td>
              <td>{{ $t('myteam_events_approved_shortened_text') }}</td>
            </tr>
            <tr>
              <td :class="{ 'home-my-results__legend--failed': entity.notParticipated > 0 }">
                {{ entity.notParticipated }}
              </td>
              <td>{{ $t('myteam_events_missed_text') }}</td>
            </tr>
            <tr>
              <td :class="{ 'home-my-results__legend--passed': entity.participated > 0 }">
                {{ entity.participated }}
              </td>
              <td>{{ $t('myteam_events_participated_text') }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="home-my-results__diagram">
        <AppDonutDiagram
          :segments="diagram.segments"
          :total="diagram.amount"
        />
      </div>
    </div>
    <div
      v-else
      class="home-my-results__body home-my-results__body--empty"
    >
      {{ $t('common_filters_change_filter_to_display_module_text') }}
    </div>
  </card16x9>
</template>

<script>
import Card16x9 from '@/components/helpers/proportional-cards/16x9.vue';
import AppDonutDiagram from '@/components/helpers/charts/donut.vue';
import ResultsItem from '@/entities/modules/dashboard/ResultsItem';
import CustomRoute from '@/entities/common/CustomRoute';
import CorrectName from '@/entities/common/CorrectName';
import { each } from 'lodash';
import { mapGetters } from 'vuex';
import ApiName from '@/entities/common/ApiName';

export default {
  name: 'my-result-entity',

  components: {
    Card16x9,
    AppDonutDiagram,
  },

  props: {
    entity: {
      type: ResultsItem,
      default: null,
    },

    myTeam: {
      type: Boolean,
      default: false,
    },

    id: {
      type: Number,
      default: null,
    },

    slug: {
      type: String,
      default: null,
    },
  },

  computed: {
    ...mapGetters('helpers', ['isDesktop']),

    isEventsEntity() {
      return this.entity.type === CorrectName.EVENT;
    },

    diagram() {
      const segments = [];

      if (this.isEventsEntity) {
        segments.push(
          {
            count: this.entity.request,
            status: 'transparent',
          },
          {
            count: this.entity.requestSuccess,
            status: 'request',
          },
          {
            count: this.entity.notParticipated,
            status: 'failed',
          },
          {
            count: this.entity.participated,
            status: 'success',
          },
        );
      } else {
        segments.push({
          count: this.entity.notStarted,
          status: 'transparent',
        });

        if (this.entity.type !== 'interviews') {
          segments.push({
            count: this.entity.fail,
            status: 'failed',
          });
        }

        segments.push({
          count: this.entity.success,
          status: 'success',
        });
      }

      let amount = 0;
      each(segments, segment => (amount += segment.count));

      return { segments, amount };
    },

    linkTo() {
      if (this.myTeam) {
        const modules = [];

        switch (this.entity.type) {
          case ApiName.EVENT_MODULE:
            modules.push(this.entity.type);
            break;

          case ApiName.TEST_MODULE:
            modules.push(this.entity.type);
            break;

          case ApiName.INTERVIEW_MODULE: {
            modules.push(this.entity.type);
            break;
          }

          case ApiName.LEARNING_MATERIALS: {
            modules.push(this.entity.moduleId);
            break;
          }

          default:
            break;
        }

        return {
          name: CustomRoute.TO_MY_TEAM_EMPLOYEE_RESULTS,
          params: {
            id: this.id,
            slug: this.slug,
            modules,
          },
          query: this.$route.query,
        };
      }

      let module;

      if (this.entity.type === ApiName.LEARNING_ITEM_MODULE) {
        module = `${CorrectName.LEARNING_MATERIALS}-${this.entity.moduleId}`;
      } else {
        module = this.entity.type;
      }

      return {
        name: CustomRoute.TO_MY_RESULTS_LEARNING,
        params: {
          id: this.id,
          slug: this.slug,
        },
        query: { module, from: 'dashboard-widget' },
      };
    },

    isShowPassedPercent() {
      return !(this.entity.isBlocked || this.myTeam);
    },

    passedPercent() {
      return this.$t('myresults_section_pass_percentage_material_text_web', [
        this.entity.studiedPercent,
      ]);
    },
  },

  methods: {
    getSectionName(name) {
      return name[0].toUpperCase() + name.slice(1);
    },
  },
};
</script>
