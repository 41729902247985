<template>
  <div
    ref="appSidebar"
    class="app-sidebar"
    :class="{ 'app-sidebar--with-padding': mobileStore }"
  >
    <div
      ref="mobileStore"
      class="app-sidebar__toolbar"
      :class="{ 'app-slider__toolbar--shadow': !isScrolled }"
    >
      <OpenInMobile
        v-if="mobileStore"
        :mobile-store="mobileStore"
        @close="$emit('closeMobileApp')"
      />

      <Toolbar @toggle-sidebar="showSidebar">
        <template #title>
          <img
            v-if="branding && branding.coloredLogo"
            :src="branding.coloredLogo"
            alt="logo"
          />
          <img
            v-else
            src="@/assets/images/logo-primary.svg"
            alt="logo"
          />
        </template>
      </Toolbar>
    </div>

    <div
      class="app-sidebar__navbar app-navbar"
      @click="hideSidebar"
    >
      <div
        class="app-navbar__outer"
        @click="stopEvent"
      >
        <div class="app-navbar__inner">
          <app-scroll-content
            scroll-content-class="app-navbar__main"
            :app-scroll-settings="appScrollSettings"
          >
            <div
              class="app-navbar__burger app-toolbar-burger"
              @click="showSidebar"
            >
              <div class="app-toolbar-burger__item"></div>
              <div class="app-toolbar-burger__item"></div>
              <div class="app-toolbar-burger__item"></div>
            </div>
            <div
              class="app-navbar__user app-navbar-user"
              :style="
                branding && branding.sidebarBg ? `background-image: url(${branding.sidebarBg})` : ''
              "
            >
              <div class="app-navbar-user__outer">
                <div class="app-navbar-user__inner">
                  <div class="app-navbar-user__image">
                    <div
                      v-if="user.avatar"
                      :style="{ 'background-image': 'url(' + user.avatar + ')' }"
                      class="app-navbar-user-image__background"
                    ></div>
                    <UserSvg v-else />
                  </div>

                  <div class="app-navbar-user__name">{{ userName }}</div>

                  <div class="app-navbar-user__status app-navbar-user-status">
                    <template v-if="hasRating">
                      <div
                        v-if="rating && rating.place"
                        class="app-navbar-user-status__item"
                      >
                        <div class="app-navbar-user-status__title">
                          {{ $t('common_rating_place_text', [rating.place]) }}
                        </div>
                        <div class="app-navbar-user-status__subtitle">
                          {{ $t('dashboard_rating_in_rating_text') }}
                        </div>
                      </div>
                      <div
                        v-if="rating && rating.points !== null"
                        class="app-navbar-user-status__item"
                      >
                        <div class="app-navbar-user-status__title">
                          {{ rating.points.toLocaleString() }}
                        </div>
                        <div class="app-navbar-user-status__subtitle">
                          {{ $tc('common_point_without_number_text', rating.points) }}
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <div class="app-navbar-user-status__item">
                        <div class="app-navbar-user-status__title">&nbsp;</div>
                        <div class="app-navbar-user-status__subtitle">&nbsp;</div>
                      </div>
                    </template>
                  </div>

                  <div
                    class="app-navbar-user__logout"
                    @click="logout"
                  >
                    <LogoutSvg class-name="svg-fill-surface-white" />
                    <div class="app-navbar-links__link-hint">
                      <div class="app-navbar-links__link-text">
                        {{ $t('common_logout_button') }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="app-navbar__links app-navbar-links">
              <div
                v-for="(module, index) in visibleModules"
                :key="index"
                class="app-navbar-links__link"
                :class="{
                  'app-navbar-links__link--active': isActiveModule(module),
                  'not-empty': module.getCount() > 0,
                }"
              >
                <a
                  v-if="module.link"
                  target="_blank"
                  rel="noopener noreferrer"
                  :href="module.link"
                  @click="sendExternalModuleAnalytics"
                ></a>
                <router-link
                  v-else
                  :to="module.getRouterLink()"
                  data-sidebar-module
                  :data-sidebar-module-id="module.moduleId"
                  :data-sidebar-module-code="module.moduleCode"
                />

                <i v-if="module.icon">
                  <img
                    :src="module.icon.origin"
                    alt=""
                  />
                </i>
                <ModuleIcon
                  v-else
                  :module-code="module.moduleCode"
                />

                <div
                  v-if="!isSidebarOpened"
                  class="app-navbar-links__link-hint"
                >
                  <div class="app-navbar-links__link-text">{{ getModuleName(module) }}</div>
                </div>

                <span class="app-navbar-links__link-label">{{ getModuleName(module) }}</span>

                <template v-if="module.getCount()">
                  <span class="app-tag app-tag--orange">{{ module.getCount() }}</span>
                  <span class="app-navbar-links__dot"></span>
                </template>
              </div>

              <div
                v-if="addedModules.length > 0"
                class="app-navbar-links__link app-navbar-links-added"
                :class="{ 'app-navbar-links__link--active': isAddedMenuActive }"
                @click="changeStatusAddedMenu"
              >
                <AddedMenuSvg />
                <span
                  v-if="getNewAddedModules()"
                  class="app-navbar-links__dot"
                >
                </span>
                <div
                  v-if="!isSidebarOpened && isAddedMenuOpened"
                  v-click-outside="hideAddedMenu"
                  class="app-navbar-links-added__block"
                >
                  <app-scroll-content
                    scroll-content-class="app-navbar-links-added__menu"
                    :app-scroll-settings="appAddedScrollSettings"
                  >
                    <div
                      v-for="(module, index) in addedModules"
                      :key="index"
                      class="app-navbar-links__link"
                      :class="{
                        'app-navbar-links__link--active': isActiveModule(module),
                        'not-empty': module.getCount() > 0,
                      }"
                      @click="stopEvent"
                    >
                      <a
                        v-if="module.link"
                        target="_blank"
                        rel="noopener noreferrer"
                        :href="module.link"
                        @click="sendExternalModuleAnalytics"
                      ></a>
                      <router-link
                        v-else
                        :to="module.getRouterLink()"
                        data-sidebar-module
                        :data-sidebar-module-id="module.moduleId"
                        :data-sidebar-module-code="module.moduleCode"
                      />

                      <i v-if="module.icon">
                        <img
                          :src="module.icon.origin"
                          alt=""
                        />
                      </i>
                      <ModuleIcon
                        v-else
                        :module-code="module.moduleCode"
                      />

                      <span class="app-navbar-links__link-label">{{ getModuleName(module) }}</span>

                      <template v-if="module.getCount() > 0">
                        <span class="app-tag app-tag--orange">{{ module.getCount() }}</span>
                      </template>
                    </div>
                  </app-scroll-content>
                </div>
              </div>
            </div>
          </app-scroll-content>
          <div class="app-navbar__version">
            {{ $t('auth_version_hint_text_web', [version]) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { find, filter } from 'lodash';
import OpenInMobile from '@/components/layouts/open-in-mobile.vue';
import AddedMenuSvg from '@/components/svg/icons/added-menu.vue';
import AppScrollContent from '@/components/helpers/scroll-content.vue';
import LogoutSvg from '@/components/svg/icons/logout.vue';
import UserSvg from '@/components/svg/icons/user.vue';
import ClickOutside from 'vue-click-outside';
import { sendEvent } from '@/providers/analyticsProvider';
import CorrectName from '@/entities/common/CorrectName';
import ModuleIcon from '@/components/helpers/module-icon.vue';
import getModuleNameByCorrectName from '@/providers/correctNameProvider';
import Toolbar from '@/components/helpers/toolbar.vue';

export default {
  name: 'app-sidebar',

  components: {
    Toolbar,
    OpenInMobile,
    ModuleIcon,
    AddedMenuSvg,
    LogoutSvg,
    UserSvg,
    AppScrollContent,
  },

  directives: {
    ClickOutside,
  },

  props: {
    mobileStore: {
      type: Object,
      required: false,
      default: () => {},
    },
  },

  data: () => ({
    version: '',
    visibleModules: [],
    addedModules: [],
    appScrollSettings: {
      wheelPropagation: true,
      swicher: false,
    },
    appAddedScrollSettings: {
      wheelPropagation: false,
    },
    scrollPosition: 0,
  }),

  computed: {
    ...mapGetters('auth', ['user', 'userName']),

    ...mapGetters('helpers', ['isSidebarOpened', 'isAddedMenuOpened']),

    ...mapGetters('settings', ['branding', 'menuItems']),

    ...mapGetters('dashboard', ['rating', 'personalData']),

    ...mapGetters('helpers', ['wh', 'ww']),

    /**
     * Вернет true, если нужно выводить рейтинг в меню
     */
    hasRating() {
      return (this.rating && this.rating.place) || (this.rating && this.rating.points !== null);
    },

    /**
     * Вернет true, если в доп меню есть активный модуль
     */
    isAddedMenuActive() {
      return find(this.addedModules, module => this.isActiveModule(module));
    },

    isScrolled() {
      return this.scrollPosition > 80;
    },
  },

  watch: {
    isSidebarOpened(opened) {
      if (opened) {
        this.appScrollSettings.swicher = true;
        this.appScrollSettings.wheelPropagation = false;
        document.querySelector('body').classList.add('app-navbar--opened');
      } else {
        this.appScrollSettings.swicher = false;
        this.appScrollSettings.wheelPropagation = true;
        document.querySelector('body').classList.remove('app-navbar--opened');
      }

      this.splitVisibleModules();
    },

    wh() {
      this.splitVisibleModules();
    },
  },

  created() {
    this.version = process.env.VUE_APP_VERSION;
    this.$nextTick(() => {
      this.splitVisibleModules();
    });
  },

  mounted() {
    document.addEventListener('scroll', this.onScroll);
  },

  beforeDestroy() {
    document.removeEventListener('scroll', this.onScroll);
  },

  methods: {
    showSidebar() {
      this.$store.dispatch('helpers/showSidebar');
    },

    hideSidebar() {
      this.$store.dispatch('helpers/hideSidebar');
    },

    hideAddedMenu() {
      this.$store.commit('helpers/setAddedMenuStatus', false);
    },

    changeStatusAddedMenu() {
      this.$store.commit('helpers/setAddedMenuStatus', !this.isAddedMenuOpened);
    },

    getModuleName(module) {
      if (module.name) {
        return module.name;
      }

      return getModuleNameByCorrectName(module.moduleCode, module.moduleId);
    },

    stopEvent(e) {
      e.stopPropagation();
    },

    logout() {
      sendEvent(this.$analytics, 'auth_action_logout');
      this.$store.dispatch('auth/logout');
      setTimeout(() => {
        window.location.href = '/';
      }, 100);
    },

    /**
     * Вернет все модули для отображения
     * не отображаем модуль "моя команда", если пользователь не руководитель или руководитель без заполненного email
     */
    getAllModules() {
      const myTeam = find(this.menuItems, module => module.moduleCode === CorrectName.TEAM);

      if (myTeam && (!this.user.isManager || (this.user.isManager && !this.user.email.length))) {
        return filter(this.menuItems, module => module.moduleCode !== CorrectName.TEAM);
      }

      return this.menuItems;
    },

    splitVisibleModules() {
      const allModules = this.getAllModules();
      this.visibleModules = [];
      this.addedModules = [];

      if (this.isSidebarOpened) {
        this.visibleModules = allModules;

        return;
      }

      const menuElement = document.querySelector('.app-navbar__main');

      const burgerElement = document.querySelector('.app-navbar__burger');

      if (!menuElement || !burgerElement) {
        return;
      }

      const menuHeight = menuElement.offsetHeight;

      const burgerHeight = burgerElement.offsetHeight;

      const iconHeight = 56;

      let countVisibleIcons = Math.floor((menuHeight - burgerHeight) / iconHeight);

      // если не умешается только один раздел, тогда показываем его вместо троеточия
      if (countVisibleIcons === allModules.length - 1) {
        countVisibleIcons = allModules.length;
      }

      this.visibleModules = allModules.slice(0, countVisibleIcons);
      this.addedModules = allModules.slice(countVisibleIcons);
    },

    getNewAddedModules() {
      return filter(this.addedModules, module => module.getCount() > 0).length > 0;
    },

    isActiveModule({ moduleCode, moduleId }) {
      const { moduleCode: metaModuleCode } = this.$route.meta;

      const { moduleId: routeModuleId } = this.$route.params;

      if (moduleCode === CorrectName.LEARNING_MATERIALS) {
        return moduleCode === metaModuleCode && moduleId === parseInt(routeModuleId, 10);
      }

      if (moduleCode === CorrectName.COMPETENCY) {
        return metaModuleCode === CorrectName.EVALUATIONS;
      }

      return moduleCode === metaModuleCode;
    },

    sendExternalModuleAnalytics() {
      sendEvent(this.$analytics, 'external_module_menu_btn');
    },

    onScroll() {
      this.scrollPosition = window.scrollY || document.documentElement.scrollTop;
    },
  },
};
</script>
