<template>
  <div class="app-auth">
    <div class="app-auth__outer">
      <AppLanguageListSelect />
      <div
        class="app-auth__sidebar app-auth-sidebar"
        :style="branding && branding.authBg ? `background-image: url(${branding.authBg})` : ''"
      >
        <div
          v-if="showLogo"
          class="app-auth-sidebar__logo"
        >
          <img
            v-if="branding.whiteLogo"
            :src="branding.whiteLogo"
            alt="logo"
          />
          <img
            v-else
            src="@/assets/images/logo.svg"
            alt="logo"
          />
        </div>

        <div class="app-auth-sidebar__version">
          {{ $t('auth_version_hint_text_web', [version]) }}
        </div>
      </div>
      <scroll-content
        scroll-content-class="app-auth__content"
        :app-scroll-settings="appScrollSettings"
      >
        <router-view />
      </scroll-content>
    </div>

    <div class="app-auth__version">{{ $t('auth_version_hint_text_web', [version]) }}</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ScrollContent from '@/components/helpers/scroll-content.vue';
import AppLanguageListSelect from '@/components/helpers/language-select.vue';
import checkFrame from '@/providers/checkFrameProvider';

export default {
  name: 'auth-layout',

  components: {
    ScrollContent,
    AppLanguageListSelect,
  },

  data: () => ({
    version: '',

    appScrollSettings: {
      suppressScrollX: true,
    },
  }),

  computed: {
    ...mapGetters('settings', ['branding', 'support', 'frameSettings']),

    ...mapGetters('helpers', ['ww']),

    showLogo() {
      if (checkFrame()) {
        return this.frameSettings.showLogo;
      }

      return this.branding;
    },
  },

  watch: {
    ww() {
      this.toggleBodyScroll();
    },
  },

  created() {
    this.toggleBodyScroll();
    this.version = process.env.VUE_APP_VERSION;
  },

  beforeDestroy() {
    this.enableBodyScroll();
  },

  methods: {
    toggleBodyScroll() {
      if (this.ww > 979) {
        document.body.style.overflow = 'hidden';
        document.body.style.height = 'auto';
        document.body.style.width = 'auto';
        document.body.style.position = 'relative';
      } else {
        this.enableBodyScroll();
      }
    },

    enableBodyScroll() {
      document.body.removeAttribute('style');
    },
  },
};
</script>
