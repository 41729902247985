import certificatesApi from '@/services/certificates';

export default {
  namespaced: true,

  state: {},

  actions: {
    async getCertificatesList() {
      return certificatesApi.getCertificatesList();
    },

    async getCertificate(id, type) {
      return certificatesApi.getCertificate(id, type);
    },

    async getLearningProgramCertificateById(id, learningProgramId) {
      return certificatesApi.getLearningProgramCertificateById(id, learningProgramId);
    },

    getTestCertificate(store, testId) {
      return certificatesApi.getTestCertificate(testId).then(payload => {
        if (!payload) {
          return null;
        }

        return payload;
      });
    },
  },

  mutations: {},
};
