import IRouteMaterial, {
  isIRouteMaterial,
} from '@/entities/common/interfaces/routes/IRouteMaterial';
import IRouteModule, { isIRouteModule } from '@/entities/common/interfaces/routes/IRouteModule';
import { Location } from 'vue-router';
import CustomRoute from '@/entities/common/CustomRoute';
import { isIRouteTrack } from '@/entities/common/interfaces/routes/IRouteTrack';

export function routeTo(
  payload: CustomRoute | IRouteMaterial | IRouteModule,
  specify?: CustomRoute,
  query?: any,
): Location {
  let name;

  let params = {};

  if (typeof payload === 'string') {
    name = payload;
  } else {
    name = payload.customRoute;
  }

  if (isIRouteMaterial(payload)) {
    params = {
      ...params,

      id: payload.id,
      slug: payload.slug,
    };
  }

  if (isIRouteModule(payload)) {
    params = {
      ...params,

      moduleId: payload.moduleId,
      moduleSlug: payload.moduleSlug,
    };
  }

  if (isIRouteTrack(payload)) {
    params = {
      ...params,

      trackId: payload.track?.entityId,
    };
  }

  return {
    name: specify || name,
    params,
    query,
  };
}
