import dateProvider from '@/providers/dateProvider';
import Pagination from '@/entities/common/Pagination';
import { each } from 'lodash';

export default class LongreadPageStatistic {
  uuid: string;
  materialId: number;
  percent: number;
  updatedAt?: Date;

  constructor(payload: any) {
    this.uuid = payload.uuid;
    this.materialId = parseInt(payload.longread_id, 10);
    this.percent = parseInt(payload.percent, 10);
    this.updatedAt = dateProvider(payload.updated_at);
  }
}

export function createLongreadPageStatistics(payload: any): [Pagination, LongreadPageStatistic[]] {
  const pagination = new Pagination(payload.meta.pagination);

  const statistics: LongreadPageStatistic[] = [];
  each(payload.pages_stat, page => {
    statistics.push(new LongreadPageStatistic(page));
  });

  return [pagination, statistics];
}
