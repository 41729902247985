<template>
  <svg
    :class="classes"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.91774 1.55592C7.94757 1.48136 8.05311 1.48136 8.08294 1.55592L9.81591 5.88716C9.82863
    5.91894 9.85845 5.94061 9.8926 5.94288L14.5474 6.2526C14.6275 6.25794 14.6601 6.35831 14.5984
    6.40972L11.0147 9.3963C10.9884 9.41821 10.977 9.45327 10.9854 9.48645L12.1292 14.0091C12.1489
    14.087 12.0635 14.149 11.9956 14.1062L8.04774 11.6208C8.01877 11.6025 7.98191 11.6025 7.95294
    11.6208L4.0051 14.1062C3.93715 14.149 3.85176 14.087 3.87145 14.0091L5.01529 9.48645C5.02369
    9.45327 5.0123 9.41821 4.986 9.3963L1.40226 6.40972C1.34057 6.35831 1.37318 6.25794 1.45331
    6.2526L6.10808 5.94288C6.14224 5.94061 6.17206 5.91894 6.18477 5.88716L7.91774 1.55592Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'feedback-user-rating-comment',

  props: {
    isProgramPage: {
      type: Boolean,
      default: false,
    },

    isInfoColor: {
      type: Boolean,
      default: false,
    },

    isSurfaceMediumEmphasis: {
      type: Boolean,
      default: false,
    },

    isSurfaceDisabledEmphasis: {
      type: Boolean,
      default: false,
    },

    isSurfaceEmphasis20: {
      type: Boolean,
      default: false,
    },

    hasStroke: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    classes() {
      const classes = [];

      if (this.isProgramPage && this.isSurfaceEmphasis20) {
        classes.push('empty-star');
      }

      if (this.isInfoColor) {
        classes.push('info');
      }

      if (this.isSurfaceMediumEmphasis) {
        classes.push('surface-medium-emphasis');
      }

      if (this.isSurfaceDisabledEmphasis) {
        classes.push('surface-disabled-emphasis');
      }

      if (this.isSurfaceEmphasis20) {
        classes.push('surface-emphasis20');
      }

      return classes;
    },

    strokeWidth() {
      if (this.hasStroke) {
        return 2;
      }

      return 0;
    },
  },
};
</script>

<style scoped>
.info path {
  fill: var(--info);
}

.surface-medium-emphasis path {
  fill: var(--surfaceMediumEmphasis);
}

.surface-disabled-emphasis path {
  fill: var(--surfaceDisabledEmphasis);
}

.surface-emphasis20 path {
  fill: var(--surfaceEmphasis20);
}

.empty-star path {
  fill: var(--surfaceWhite);
  stroke: var(--primary);
  stroke-width: 1.5;
}
</style>
