import { i18n } from '@/lang';
import VueI18n from 'vue-i18n';
// eslint-disable-next-line import/no-unresolved
import { MATERIAL_STATUS_NAME } from '@/entities/modules/my-team/MaterialStatusName';

export default class MaterialStatus {
  name: VueI18n.TranslateResult;
  code: string;
  users: number;
  attempts: number;

  constructor(payload: any) {
    this.users = parseInt(payload.users, 10);
    this.attempts = parseInt(payload.attempts, 10);
    this.code = payload.regular_name;
    this.name = this.getStatusName(payload.regular_name);
  }

  getStatusName(statusName: string) {
    const key = MATERIAL_STATUS_NAME.get(statusName);

    return key ? i18n.t(key) : '';
  }
}
