import { each } from 'lodash';
import KpiWidget from '@/entities/modules/dashboard/KpiWidget';
import BaseWidget from '@/entities/modules/dashboard/BaseWidget';
import TestsWidget from '@/entities/modules/dashboard/TestsWidget';
// eslint-disable-next-line import/no-cycle
import BadgesWidget from '@/entities/modules/dashboard/BadgesWidget';
import MyTeamWidget from '@/entities/modules/dashboard/MyTeamWidget';
import VideoWidget from '@/entities/modules/dashboard/VideoWidget';
import RatingWidget from '@/entities/modules/dashboard/RatingWidget';
import ResultsWidget from '@/entities/modules/dashboard/ResultsWidget';
import NextMaterialWidget from '@/entities/modules/dashboard/NextMaterialWidget';
import FavoritesWidget from '@/entities/modules/dashboard/FavoritesWidget';
import MonthFocusWidget from '@/entities/modules/dashboard/MonthFocusWidget';
import BannerWidget from '@/entities/modules/dashboard/BannerWidget';
import LearningItemsWidget from '@/entities/modules/dashboard/LearningItemsWidget';

export default class Dashboard {
  profile: Object;
  nextMaterial?: NextMaterialWidget;
  rating?: RatingWidget;
  widgets: BaseWidget[];
  banner?: BannerWidget;

  constructor(payload: any) {
    this.profile = payload.profile;

    this.widgets = [];

    each(payload.widgets, widget => {
      switch (widget.type) {
        case 'kpi':
          this.widgets.push(new KpiWidget(widget));
          break;
        case 'entities_with_passing_date':
        case 'learning_items':
          this.widgets.push(new LearningItemsWidget(widget));
          break;
        case 'test':
          this.widgets.push(new TestsWidget(widget));
          break;
        case 'month_focus':
          this.widgets.push(new MonthFocusWidget(widget));
          break;
        case 'badge':
          this.widgets.push(new BadgesWidget(widget));
          break;
        case 'team':
          this.widgets.push(new MyTeamWidget(widget));
          break;
        case 'discover':
          this.widgets.push(new VideoWidget(widget));
          break;
        case 'results':
          this.widgets.push(new ResultsWidget(widget));
          break;
        case 'favorites':
          this.widgets.push(new FavoritesWidget(widget));
          break;
        case 'continue_learning':
          this.nextMaterial = new NextMaterialWidget(widget);
          break;
        case 'rating':
          this.rating = new RatingWidget(widget);
          break;
        case 'banner':
          this.banner = new BannerWidget(widget);
          break;
        default:
          break;
      }
    });
  }
}
