import { each, filter, find, sortBy, includes, orderBy } from 'lodash';
import { GetterTree } from 'vuex';
import Category from '@/entities/modules/learning-programs/Category';
import Program from '@/entities/modules/learning-programs/Program';
import { LearningProgramsState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<LearningProgramsState, RootState> = {
  programs(state): Program[] | undefined {
    if (state.programs.has(state.currentModuleId)) {
      return state.programs.get(state.currentModuleId);
    }

    return [];
  },

  /**
   * Фильтр по флагу обязательных программ
   * @param state
   */
  selectedRequiredFilter(state): boolean {
    return state.selectedRequiredFilter;
  },

  /**
   * Фильтр по статусу программы
   * @param state
   */
  selectedStatusFilter(state): string[] | string {
    return state.selectedStatusFilter;
  },

  /**
   * Статус сортировки по дедлайну
   * @param state
   */
  selectedDeadlineFilter(state): string[] {
    return state.selectedDeadlineFilter;
  },

  /**
   * Статус сортировки по рейтингу
   * @param state
   */
  selectedSortFilter(state): string {
    return state.selectedSortFilter;
  },

  /**
   * Список категорий
   * @param state
   */
  categories(state): Category[] {
    const categories: Category[] = [];

    each(state.programs.get(state.currentModuleId), program => {
      if (!find(categories, o => o.id === program.category.id)) {
        categories.push(program.category);
      }
    });

    return sortBy(categories, 'order');
  },

  /**
   * Выбранная категория
   * @param state
   */
  selectedCategory(state): any {
    return state.selectedCategory;
  },

  /**
   * Список тегов
   * @param state
   */
  tags: (state): object[] => {
    let filteredPrograms = filter(
      state.programs.get(state.currentModuleId),
      program => program.tags.length > 0,
    );

    const {
      selectedCategory,
      selectedRequiredFilter,
      selectedStatusFilter,
      selectedDeadlineFilter,
    } = state;

    if (selectedCategory) {
      filteredPrograms = filter(
        filteredPrograms,
        program => program.category.id === selectedCategory.id,
      );
    }

    if (selectedRequiredFilter) {
      filteredPrograms = filter(filteredPrograms, program => program.isRequired);
    }

    if (selectedStatusFilter.length > 0) {
      filteredPrograms = filter(
        filteredPrograms,
        program =>
          (includes(selectedStatusFilter, 'is-assigned') && program.getStatistic().isAssigned) ||
          (includes(selectedStatusFilter, 'in-progress') && program.getStatistic().isInProgress) ||
          (includes(selectedStatusFilter, 'in-checking') && program.getStatistic().isChecking) ||
          (includes(selectedStatusFilter, 'is-failed') && program.getStatistic().isFailed) ||
          (includes(selectedStatusFilter, 'is-passed') && program.getStatistic().isPassed),
      );
    }

    if (selectedDeadlineFilter.length > 0) {
      filteredPrograms = filter(
        filteredPrograms,
        program =>
          (includes(selectedDeadlineFilter, 'without-deadline') &&
            program.getDeadlineStates().hasntDeadline) ||
          (includes(selectedDeadlineFilter, 'deadline') &&
            program.getDeadlineStates().hasDeadline) ||
          (includes(selectedDeadlineFilter, 'passed-deadline') &&
            program.getDeadlineStates().isPassedDeadline) ||
          (includes(selectedDeadlineFilter, 'missed-deadline') &&
            program.getDeadlineStates().isExceededDeadline),
      );
    }

    let tagsArray: any[] = [];

    each(filteredPrograms, program => {
      each(program.tags, tagItem => {
        const tagObj = find(tagsArray, tag => tag.payload.id === tagItem.id);

        if (tagObj) {
          tagObj.count += 1;
        } else {
          tagsArray.push({
            payload: tagItem,
            count: 1,
          });
        }
      });
    });

    tagsArray = orderBy(tagsArray, ['count', 'payload.name'], ['desc']);

    return tagsArray.map(o => o.payload);
  },

  /**
   * Выбранный тег
   * @param state
   */
  selectedTag(state): String | null {
    return state.selectedTag;
  },

  /** Фильтрация программ по тегам
   * @param state
   * @param getters
   */
  // eslint-disable-next-line no-shadow
  filteredByTagPrograms(state, getters) {
    if (getters.selectedTag !== null) {
      return filter(getters.programs, program =>
        includes(program.getTextTags(), getters.selectedTag),
      );
    }

    return getters.programs;
  },

  /** Фильтрация программ по обязательности
   * @param state
   * @param getters
   */
  // eslint-disable-next-line no-shadow
  filteredByRequiredPrograms(state, getters): Program[] {
    if (getters.selectedRequiredFilter) {
      return filter(getters.filteredByTagPrograms, program => program.isRequired);
    }

    return getters.filteredByTagPrograms;
  },

  /** Фильтрация программ по статусу
   * @param state
   * @param getters
   */
  // eslint-disable-next-line no-shadow
  filteredByStatusPrograms(state, getters): Program[] {
    if (getters.selectedStatusFilter.length > 0) {
      return filter(
        getters.filteredByRequiredPrograms,
        program =>
          (includes(getters.selectedStatusFilter, 'is-assigned') &&
            (program.getStatistic().isAssigned || program.getStatistic().isNew)) ||
          (includes(getters.selectedStatusFilter, 'in-progress') &&
            program.getStatistic().isInProgress) ||
          (includes(getters.selectedStatusFilter, 'in-checking') &&
            program.getStatistic().isChecking) ||
          (includes(getters.selectedStatusFilter, 'is-failed') &&
            program.getStatistic().isFailed) ||
          (includes(getters.selectedStatusFilter, 'is-passed') && program.getStatistic().isPassed),
      );
    }

    return getters.filteredByRequiredPrograms;
  },

  /** Фильтрация программ по дедлайну
   * @param state
   * @param getters
   */
  // eslint-disable-next-line no-shadow
  filteredByDeadlinePrograms(state, getters): Program[] {
    if (getters.selectedDeadlineFilter.length > 0) {
      return filter(
        getters.filteredByStatusPrograms,
        program =>
          (includes(getters.selectedDeadlineFilter, 'without-deadline') &&
            program.getDeadlineStates().hasntDeadline) ||
          (includes(getters.selectedDeadlineFilter, 'deadline') &&
            program.getDeadlineStates().hasDeadline) ||
          (includes(getters.selectedDeadlineFilter, 'passed-deadline') &&
            program.getDeadlineStates().isPassedDeadline) ||
          (includes(getters.selectedDeadlineFilter, 'missed-deadline') &&
            program.getDeadlineStates().isExceededDeadline),
      );
    }

    return getters.filteredByStatusPrograms;
  },

  /** Сортировка программ
   * @param state
   * @param getters
   */
  // eslint-disable-next-line no-shadow
  filteredBySortPrograms(state, getters): Program[] {
    if (getters.selectedSortFilter) {
      switch (getters.selectedSortFilter) {
        case 'by-new': {
          return orderBy(getters.filteredByDeadlinePrograms, ['id'], ['desc']);
        }

        case 'by-alphabetically': {
          return sortBy(getters.filteredByDeadlinePrograms, [o => o.name.toLowerCase()]);
        }

        case 'by-deadline': {
          const containsDeadline = filter(
            getters.filteredByDeadlinePrograms,
            program => program.passingDeadline,
          );

          const sortByDeadline = sortBy(containsDeadline, ['passingDeadline']);

          const noContainsDeadline = filter(
            getters.filteredByDeadlinePrograms,
            program => !program.passingDeadline,
          );

          return [...sortByDeadline, ...noContainsDeadline];
        }

        case 'by-rating': {
          // наполняем массив программами с доступом к оценке
          const feedbackAvailableProgram = filter(
            getters.filteredByDeadlinePrograms,
            o => o.isFeedbackAvailable,
          );

          const feedbackAvailableProgramMax = orderBy(
            filter(feedbackAvailableProgram, o => o.feedbackCount >= 5),
            ['feedbackAverageMark'],
            ['desc'],
          );

          const feedbackAvailableProgramMin = filter(
            feedbackAvailableProgram,
            o => o.feedbackCount < 5,
          );

          // наполняем массив программами у которых нет доступа к оценке
          const feedbackUnavailableProgram = filter(
            getters.filteredByDeadlinePrograms,
            o => !o.isFeedbackAvailable,
          );

          return [
            ...feedbackAvailableProgramMax,
            ...feedbackAvailableProgramMin,
            ...feedbackUnavailableProgram,
          ];
        }

        default:
      }
    }

    return orderBy(getters.filteredByDeadlinePrograms, ['order'], ['desc']);
  },

  /** Фильтрация программ по категориям
   * @param state
   * @param getters
   */
  // eslint-disable-next-line no-shadow
  filteredByCategoryPrograms(state, getters): any {
    const filteredByCategoryPrograms: any = [];
    each(getters.categories, category => {
      if (
        filter(
          getters.filteredBySortPrograms,
          (program: Program) => program.category.id === category.id,
        ).length > 0
      ) {
        filteredByCategoryPrograms.push({
          category,
          programs: filter(
            getters.filteredBySortPrograms,
            (program: Program) => program.category.id === category.id,
          ),
        });
      }
    });

    if (getters.selectedCategory) {
      return filter(
        filteredByCategoryPrograms,
        (program: any) => program.category.id === getters.selectedCategory.id,
      );
    }

    return filteredByCategoryPrograms;
  },

  currentModuleId(state): Number {
    return state.currentModuleId;
  },
};
