<template>
  <div>
    <HeaderLoading v-if="hasHeader" />

    <div class="container">
      <div class="app-sl__preloader">
        <SectionLoading />
        <div class="app-sl-section columns">
          <div
            v-for="index in [1, 2, 3]"
            :key="index"
            class="column is-one-third"
          >
            <CardLoading />
          </div>
        </div>
      </div>
      <div class="app-sl__preloader">
        <SectionLoading />
        <div class="columns app-sl-section">
          <div
            v-for="index in [1, 2]"
            :key="index"
            class="column is-one-third"
          >
            <CardLoading />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionLoading from '@/components/helpers/pre-loading-page/section-loading';
import CardLoading from '@/components/helpers/pre-loading-page/card-loading';
import HeaderLoading from '@/components/helpers/pre-loading-page/header-loading';

export default {
  name: 'base-loading',

  components: {
    SectionLoading,
    CardLoading,
    HeaderLoading,
  },

  props: {
    hasHeader: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
