import ImageSource from '@/entities/common/sources/ImageSource';

export default class ToggleSectionMaterial {
  id: number;
  type: string;
  routerTo: string;
  routerTrackTo: string;
  name: string;
  url: string;
  numberingTypeThrough?: string;
  numberingTypeSections?: string;
  numberingTypeHierarchical?: string;

  // ключ из локалайза на типе документа (pdf, scorm...)
  lokaliseTagLabel: string;

  // есть кнопки, текст которых задается в админке, в этом случае нужно его задать сюда, он приоритетен
  buttonLabel: string | null;

  // ключ из локалайза, если в админке нельзя задать текст на кнопке или текст на кнопке отсутствует, будет использоваться этот
  lokaliseButtonLabel: string;

  // true, если кнопка должна быть outlined (назначен статус, или если видосик просмотрен полностью, еще что-то)
  isButtonOutlined: boolean;

  isRating: boolean;
  isRequired: boolean;
  isLocked: boolean;

  isPassed: boolean;
  isFailed: boolean;
  isChecking: boolean;
  inProgress: boolean;
  isChecked: boolean;
  image: ImageSource;

  parts: any[];

  constructor(payload: any) {
    this.id = payload.id;
    this.type = payload.type;
    this.routerTo = payload.routerTo;
    this.routerTrackTo = payload.routerTrackTo;
    this.name = payload.name;
    this.url = payload.url;
    this.image = payload.image;

    this.lokaliseTagLabel = payload.lokaliseTagLabel;
    this.buttonLabel = payload.buttonLabel;
    this.lokaliseButtonLabel = payload.lokaliseButtonLabel;
    this.isButtonOutlined = payload.isButtonOutlined;

    this.numberingTypeThrough = payload.numberingTypeThrough;
    this.numberingTypeSections = payload.numberingTypeSections;
    this.numberingTypeHierarchical = payload.numberingTypeHierarchical;

    this.isRating = payload.isRating;
    this.isRequired = payload.isRequired;
    this.isLocked = payload.isLocked;

    this.isPassed = payload.isPassed;
    this.isFailed = payload.isFailed;
    this.isChecking = payload.isChecking;
    this.inProgress = payload.inProgress;
    this.isChecked = payload.isChecked;

    this.parts = payload.parts;
  }
}
