import IStatistic from '@/entities/modules/learning-programs/IStatistic';
import SectionMaterial from '@/entities/modules/learning-programs/SectionMaterial';
import ToggleSectionMaterial from '@/entities/modules/learning-programs/ToggleSectionMaterial';
import MaterialStatus from '@/entities/common/testing/MaterialStatus';
import NotImplementedException from '@/entities/common/exceptions/NotImplementedException';
import ImageSource, { createImageSmSource } from '@/entities/common/sources/ImageSource';
import BaseMaterial from '@/entities/modules/learning-programs/BaseMaterial';

export default class MaterialUnknown extends BaseMaterial {
  id: number;
  name: string;
  isRating: boolean;
  maxScore: number = 0;
  updatedAtTimestamp: number;
  timeLimit: number;
  statistic: IStatistic;
  image?: ImageSource;

  constructor(statistic: IStatistic, payload: any) {
    super();

    this.statistic = statistic;

    this.id = parseInt(payload.id, 10);
    this.name = payload.name;
    this.isRating = parseInt(payload.is_rating, 10) === 1;
    this.updatedAtTimestamp = payload.updated_at;
    this.timeLimit = parseInt(payload.test_time, 10);
    this.maxScore = parseInt(payload.max_points, 10);
    this.image = createImageSmSource(payload.image);
  }

  /**
   * Тип материала
   */
  getMaterialType(): string {
    return 'unknown';
  }

  /**
   * Виджет материала для страницы просмотра программы обучения (выводится в секциях)
   * @param sectionMaterial
   */
  getToggleWidget(sectionMaterial: SectionMaterial): ToggleSectionMaterial {
    const status = this.statistic.getMaterialStatus();

    const widgetParts = [];

    if (this.isRating) {
      widgetParts.push(`${this.statistic.getPercent()}%`);

      if (status !== MaterialStatus.PASSED) {
        widgetParts.push(['learn_earn_d_points_text', this.maxScore, { 0: this.maxScore }]);
      } else if (this.statistic.getPercent() < 100) {
        if (this.statistic.getPoints() !== this.maxScore) {
          widgetParts.push([
            'learn_d_from_d_points_text',
            this.statistic.getPoints(),
            { 0: this.statistic.getPoints(), 1: this.maxScore },
          ]);
        } else {
          widgetParts.push([
            'common_point_number_text',
            this.maxScore,
            { 0: this.maxScore.toLocaleString() },
          ]);
        }
      } else {
        widgetParts.push([
          'common_point_number_text',
          this.maxScore,
          { 0: this.maxScore.toLocaleString() },
        ]);
      }
    }

    return new ToggleSectionMaterial({
      id: this.id,
      type: this.getMaterialType(),
      routerTo: null,
      name: this.name,
      image: this.image,

      lokaliseTagLabel: '',
      buttonLabel: null,
      lokaliseButtonLabel: '',
      isButtonOutlined: status !== MaterialStatus.ASSIGNED,

      numberingTypeThrough: this.numberingTypeThrough,
      numberingTypeSections: this.numberingTypeSections,
      numberingTypeHierarchical: this.numberingTypeHierarchical,

      isRating: this.isRating,
      isRequired: sectionMaterial.isRequired,
      isLocked: true,

      isPassed: status === MaterialStatus.PASSED,
      isChecked: false,

      parts: widgetParts,
    });
  }

  /**
   * Вернет статистику
   */
  getStatistic(): IStatistic {
    return this.statistic;
  }

  /**
   * Обновит статистику
   * @param statistic
   */
  setStatistic(statistic: IStatistic): void {
    this.statistic = statistic;
  }

  getSaving(): any {
    throw new NotImplementedException();
  }
}
