import has from 'lodash/has';
import MaterialStatus from '@/entities/common/testing/MaterialStatus';

export default class InteractionStatistic {
  id: number;
  type: string;
  status: string;

  duration: number | null = null;
  points: number | null = null;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.type = payload.type;
    this.status = payload.status;

    if (has(payload, 'duration')) {
      this.duration = parseInt(payload.duration, 10);
    }

    if (has(payload, 'points')) {
      this.points = parseInt(payload.points, 10);
    }
  }

  /**
   * Вернет статус материала
   */
  getMaterialStatus(): MaterialStatus {
    if (this.status === 'completed') {
      return MaterialStatus.PASSED;
    }

    if (this.status === 'fail') {
      return MaterialStatus.FAILED;
    }

    return MaterialStatus.ASSIGNED;
  }
}
