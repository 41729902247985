export default class MaterialProgressMeta {
  notStarted: number;
  success: number;
  fail: number;

  constructor(payload: any) {
    this.notStarted = parseInt(payload.not_started, 10);
    this.success = parseInt(payload.success, 10);
    this.fail = parseInt(payload.fail, 10);
  }
}
