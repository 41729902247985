import ImageSource, { createImageLgSource } from '@/entities/common/sources/ImageSource';
import deepLinksProvider from '@/providers/deepLinksProvider';
import FileSource, { createFileSource } from '@/entities/common/sources/FileSource';

export default class Material {
  id: number;
  categoryId: number;
  subcategoryId: number;
  name: string;
  description: string;
  content: string;
  isNew: boolean;
  isFavorite: boolean;
  order: number;
  updatedAt: number;
  viewDate: number;
  image?: ImageSource;
  url: string;
  urlTitle: string | null;
  useIntegrationToken: boolean | null;

  file?: FileSource;
  fileName?: string;
  allowPdfSharing: boolean | null;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.order = parseInt(payload.order, 10);
    this.categoryId = parseInt(payload.category_id, 10);
    this.subcategoryId = 0;
    this.name = payload.name;
    this.description = payload.description;
    this.content = payload.content;
    this.isNew = parseInt(payload.is_new, 10) === 1;
    this.isFavorite = parseInt(payload.is_favorite, 10) === 1;
    this.updatedAt = parseInt(payload.updated_at, 10);
    this.viewDate = parseInt(payload.view_date, 10);
    this.image = createImageLgSource(payload.image);
    this.url = deepLinksProvider(payload.url);
    this.urlTitle = payload.url_title;
    this.useIntegrationToken = parseInt(payload.use_integration_token, 10) === 1;

    this.file = createFileSource(payload.attachment);
    this.fileName = payload.attachment_name ? payload.attachment_name : '';
    this.allowPdfSharing = payload.custom_settings
      ? parseInt(payload.custom_settings.allow_pdf_sharing, 10) === 1
      : null;
  }

  setSubcategoryId(id: number): void {
    this.subcategoryId = id;
  }

  setFavorite(status: boolean): void {
    this.isFavorite = status;
  }

  clearIsNew(): void {
    this.isNew = false;
  }

  markAsViewed(currentTimestamp: number): void {
    this.viewDate = currentTimestamp;
  }
}
