import axios from 'axios';
import authApi from '@/services/auth';
import groupsApi from '@/services/groups';
import { setCookie } from '@/providers/js/cookieProvider';
import { createAccessCode } from '@/entities/modules/auth/AccessCode';
import UserToken from '@/entities/modules/auth/UserToken';
import UserState from '@/entities/modules/auth/UserState';
import { cloneDeep, each } from 'lodash';
import { getCountFieldsForVerification } from '@/entities/modules/auth/UserVerified';
import DeadTokenException from '@/entities/common/exceptions/DeadTokenException';
import FakeUser from '@/entities/modules/auth/FakeUser';

export default {
  namespaced: true,

  state: {
    user: null,
    isAppActive: true,

    accessCode: true,
    loadedGroups: new Map(),
    showBlockedMessages: false,
    accessCodeStatus: null,
  },

  getters: {
    user: state => state.user,

    /**
     * true, если можно показывать приложение
     * @param state
     * @returns {boolean}
     */
    isAppActive: state => state.isAppActive,

    userName: getters => {
      if (!getters.user) {
        return '';
      }

      return `${getters.user.lastName} ${getters.user.firstName}`;
    },

    isAccessCode: state => state.accessCode,

    loadedGroups: state => state.loadedGroups,

    showBlockedMessages: state => state.showBlockedMessages,

    isAccessCodeStatus: state => {
      if (!state.accessCode) {
        return state.accessCodeStatus;
      }

      return '';
    },

    /**
     * Верификация информации пользователя
     * @param getters
     * @returns {*}
     */
    isUserNeedsVerifySomeone: getters => {
      if (getters.user) {
        return (
          getters.user.userVerified.firstName === false ||
          getters.user.userVerified.lastName === false ||
          getters.user.userVerified.email === false ||
          getters.user.userVerified.phone === false ||
          getters.user.userVerified.groups === false ||
          getters.user.userVerified.form === false ||
          getters.user.userVerified.password === false
        );
      }

      return null;
    },

    isUserLicenseAgree: getters => {
      if (getters.user) {
        return getters.user.licenseAgree;
      }

      return null;
    },
  },

  actions: {
    async login({ dispatch }, { companyId, login, password, rememberMe }) {
      const user = await authApi.login(companyId, login, password);

      await dispatch('authUserObject', user);

      if (rememberMe) {
        localStorage.setItem('rememberMe', '1');
      } else {
        localStorage.setItem('rememberMe', '0');
        setCookie('sessionCookiesMoment', 1);
      }

      return user;
    },

    /**
     * Получит ссылку на внешнюю авторизацию
     * @param id
     * @param redirectUri
     * @returns {Promise<AxiosResponse<any>>}
     */
    async getOAuthExternalUrl({}, { id, redirectUri }) {
      return authApi.getOAuthExternalUrl(id, redirectUri);
    },

    /**
     * Проверит токен на корректность и если да, авторизует юзера
     * @param dispatch
     * @param data
     * @returns {Promise<Readonly<User>>}
     */
    async checkOAuthToken({ dispatch }, data) {
      const user = await authApi.checkOAuthToken(data);

      await dispatch('authUserObject', user);

      return user;
    },

    /**
     * Авторизует объект юзера
     * @param commit
     * @param payload
     * @returns {Promise<null|*>}
     */
    async authUserObject({ commit }, payload) {
      if (!payload) {
        return null;
      }

      commit('clearStorage');

      commit('setIsAppActive', false);
      commit('setUser', payload);

      localStorage.setItem(
        'count_verification_steps',
        JSON.stringify(getCountFieldsForVerification(payload.userVerified)),
      );

      // Зададим токен для последующих запросов
      axios.defaults.headers.common.Authorization = `Bearer ${payload.userToken.accessToken}`;
    },

    async verificationPassword({ state, commit }, password) {
      const payload = await authApi.verificationPassword(password);

      if (payload) {
        const user = cloneDeep(state.user);
        user.userVerified.password = true;

        commit('setUser', user);
      }

      return payload;
    },

    async register({ commit }, { companyId, login, password }) {
      const payload = await authApi.register(companyId, login, password);

      localStorage.clear();

      commit('setUser', payload);
      localStorage.setItem(
        'count_verification_steps',
        JSON.stringify(getCountFieldsForVerification(payload.userVerified)),
      );

      axios.defaults.headers.common.Authorization = `Bearer ${payload.userToken.accessToken}`;

      return payload;
    },

    async flushVerification({ state, commit, dispatch }, verifyArr) {
      if (state.user === null || state.user instanceof FakeUser) {
        const user = new FakeUser({
          profile: {
            is_need_license_agree: 0,
            need_verify: verifyArr,
          },
        });

        if (!user.userVerified.phone) {
          localStorage.removeItem('user_skipped_verification_phone');
        }

        if (!user.userVerified.email) {
          localStorage.removeItem('user_skipped_verification_email');
        }

        user.userToken = await dispatch('getUserTokenFromStorage');
        commit('setUser', user);

        return;
      }

      const user = cloneDeep(state.user);

      for (const key in user.userVerified) {
        if (Object.prototype.hasOwnProperty.call(user.userVerified, key)) {
          verifyArr.user_fields.forEach(verify => {
            if (verify === 'first_name') {
              user.userVerified.firstName = false;
            }

            if (verify === 'last_name') {
              user.userVerified.lastName = false;
            }

            if (key === verify) {
              user.userVerified[key] = false;
            }
          });
        }
      }

      commit('setUser', user);

      location.reload();
    },

    async flushLicense({ state, commit, dispatch }) {
      if (state.user === null || state.user instanceof FakeUser) {
        const user = new FakeUser({
          profile: {
            is_need_license_agree: 1,
            need_verify: [],
          },
        });
        user.userToken = await dispatch('getUserTokenFromStorage');
        commit('setUser', user);

        return;
      }

      const user = cloneDeep(state.user);
      user.licenseAgree = false;

      commit('setUser', user);
    },

    async reloadProfile({ commit, dispatch }) {
      try {
        const responseUser = await authApi.getProfile();
        responseUser.userToken = await dispatch('getUserTokenFromStorage');
        responseUser.userState = await dispatch('getUserStateFromStorage');
        commit('setUser', responseUser);
      } catch (e) {
        if (e instanceof DeadTokenException) {
          dispatch('logout');
          window.location.href = `/login?router_back=${window.location.pathname}`;
        }
      }
    },

    updateAccessToken({ state, commit }, response) {
      const token = Object.freeze(new UserToken(response));

      const userState = new UserState(response);

      if (state.user) {
        const user = cloneDeep(state.user);
        user.userToken = token;
        user.userState = userState;

        commit('setUser', user);
      } else {
        commit('setUserToken', token);
      }

      return token;
    },

    /**
     * Разблокирует сообщения
     * @param state
     * @param commit
     */
    unblockMessages({ state, commit }) {
      const user = cloneDeep(state.user);

      user.userState.hasBlockedMessages = false;

      commit('setUser', user);
    },

    /**
     * Заблокирует сообщения
     * @param state
     * @param commit
     */
    doBlockMessages({ state, commit }) {
      const user = cloneDeep(state.user);

      user.userState.hasBlockedMessages = true;

      commit('setUser', user);
    },

    async getRecoveryCode({}, { isEmail, emailOrPhone, companyId, preferredType = null }) {
      return authApi.getRecoveryCode(isEmail, emailOrPhone, companyId, preferredType);
    },

    /**
     * Вернет true, если пользователь ввел корректный code
     * @param o
     * @param isEmail
     * @param emailOrPhone
     * @param code
     * @param companyId
     * @param preferredType
     */
    async validateRecoveryCode(
      o,
      { isEmail, emailOrPhone, code, companyId, preferredType = null },
    ) {
      return authApi.validateRecoveryCode(isEmail, emailOrPhone, code, companyId, preferredType);
    },

    async validatePasswordRestorePassword(
      {},
      { isEmail, emailOrPhone, code, password, companyId, preferredType },
    ) {
      return authApi.validatePasswordRestorePassword(
        isEmail,
        emailOrPhone,
        code,
        password,
        companyId,
        preferredType,
      );
    },

    async saveRecoveryPassword(
      {},
      { isEmail, emailOrPhone, code, password, companyId, preferredType = null },
    ) {
      return authApi.saveRecoveryPassword(
        isEmail,
        emailOrPhone,
        code,
        password,
        companyId,
        preferredType,
      );
    },

    async validateOldPassword({}, password) {
      await authApi.validateOldPassword(password);
    },

    async validateEditPassword({}, password) {
      return authApi.validateEditPassword(password);
    },

    async editPassword({ state, dispatch }, { oldPassword, newPassword }) {
      const response = await authApi.editPassword(oldPassword, newPassword);
      dispatch('updateAccessToken', response);
      axios.defaults.headers.common.Authorization = `Bearer ${state.user.userToken.accessToken}`;
    },

    /**
     * Смена аватара в профиле пользователя
     * @param state
     * @param commit
     * @param avatar
     * @returns {Promise<T | never>}
     */
    editAvatarUserProfile({ state, commit }, avatar) {
      return authApi.editAvatarUserProfile(avatar).then(payload => {
        if (!payload) {
          return null;
        }

        const user = cloneDeep(state.user);

        user.avatar = payload.avatar;

        commit('setUser', user);

        return payload;
      });
    },

    async getUserTokenFromStorage() {
      const jsonToken = localStorage.getItem('user_token');

      if (!jsonToken) {
        return null;
      }

      const userToken = JSON.parse(jsonToken);

      if (!userToken) {
        return null;
      }

      return userToken;
    },

    async getUserStateFromStorage() {
      const jsonState = localStorage.getItem('user_state');

      if (!jsonState) {
        return null;
      }

      const userState = JSON.parse(jsonState);

      if (!userState) {
        return null;
      }

      return userState;
    },

    /**
     * Восстановление пользователя из хранилища
     * @param commit
     * @param dispatch
     * @returns {boolean}
     */
    async init({ commit, dispatch }) {
      const userToken = await dispatch('getUserTokenFromStorage');

      if (!userToken) {
        commit('clearStorage');

        return false;
      }

      // Зададим токен для последующих запросов
      axios.defaults.headers.common.Authorization = `Bearer ${userToken.accessToken}`;

      await dispatch('reloadProfile');

      return true;
    },

    /**
     * Разлогинивает пользователя
     * @param commit
     */
    logout({ commit }) {
      commit('clearStorage');

      // Поставим токен в Basic
      axios.defaults.headers.common.Authorization = `Basic ${process.env.VUE_APP_API_AUTHORIZATION_CREDENTIALS}`;

      commit('setIsAppActive', false);
      commit('setUser', null);
    },

    async getRootGroups({ commit }) {
      const groups = await groupsApi.getRootGroups();
      each(groups, group => {
        commit('setLoadedGroups', { id: group.id, group });
      });

      return groups.sort((a, b) => a.name.localeCompare(b.name));
    },

    async getAvailableOnRegistrationGroups({ state }, { rootId, groupId }) {
      const rootGroup = state.loadedGroups.get(rootId);

      if (rootGroup.id !== groupId) {
        const { elements } = await groupsApi.getAvailableOnRegistrationGroups(groupId, rootId);

        if (rootGroup.setChildrenById(elements, groupId)) {
          return false;
        }
      } else {
        const page = rootGroup.childrenPagination
          ? rootGroup.childrenPagination.currentPage + 1
          : 1;

        const [pagination, elements, meta] = await groupsApi.getAvailableOnRegistrationGroupsByPage(
          groupId,
          rootId,
          page,
        );
        rootGroup.setIsAvailableOnRegistration(meta.isAvailableOnRegistration);
        rootGroup.setChildrenPagination(pagination);
        rootGroup.addChildren(elements);
      }
    },

    clearLoadedRootGroup({ state, commit }, { rootId }) {
      const rootGroup = state.loadedGroups.get(rootId);
      rootGroup.setChildren([]);
      commit('setLoadedGroups', { id: rootId, group: rootGroup });
    },

    async getAvailableOnRegistrationGroupsBySearch({}, { id, value, page }) {
      const [pagination, groups] = await groupsApi.getAvailableOnRegistrationGroupsBySearch(
        id,
        value,
        page,
      );

      return [pagination, groups.sort((a, b) => a.name.localeCompare(b.name))];
    },

    getLicense() {
      return authApi.getLicense().then(payload => {
        if (!payload) {
          return null;
        }

        return payload;
      });
    },

    licenseAgree({ state, commit }) {
      return authApi.licenseAgree().then(payload => {
        if (!payload) {
          return null;
        }

        const user = cloneDeep(state.user);
        user.licenseAgree = true;
        commit('setUser', user);

        return payload;
      });
    },

    async enterAccessCode({ dispatch, state, commit }, { accessCode }) {
      let { user } = state;

      if (state.user === null || state.user instanceof FakeUser) {
        user = new FakeUser({
          profile: {
            is_need_license_agree: 0,
            need_verify: [],
          },
        });
        user.userToken = await dispatch('getUserTokenFromStorage');
      } else {
        user = cloneDeep(state.user);
      }
      user.isAccessCodeRequired = !accessCode;

      commit('setUser', user);
      commit('enterAccessCode', accessCode);
    },

    accessCodeStatus({ commit }, { log }) {
      const objLog = {};
      objLog.log = createAccessCode(log);
      commit('accessCodeStatus', objLog);
    },

    getAccessCode({ state }) {
      return state.accessCodeStatus.log.expiresIn;
    },

    sendAccessCode(o, { code }) {
      return authApi.sendAccessCode(code).then(payload => {
        if (!payload) {
          return null;
        }

        return payload;
      });
    },

    verifiedInfo({ state, commit }, verificationInfo) {
      return authApi.saveVerifiedInfo(verificationInfo).then(payload => {
        if (!payload) {
          return null;
        }

        const user = cloneDeep(state.user);
        user.userVerified.firstName = true;
        user.userVerified.lastName = true;
        user.userVerified.groups = true;
        commit('setUser', user);

        return payload;
      });
    },

    verificationUserPhone({}, { phone }) {
      return authApi.verificationUserPhone(phone).then(payload => {
        if (!payload) {
          return null;
        }

        return payload;
      });
    },

    verificationPhoneCode({ state, commit }, { code }) {
      return authApi.verificationPhoneCode(code).then(payload => {
        if (!payload) {
          return null;
        }

        const user = cloneDeep(state.user);
        user.userVerified.phone = true;
        commit('setUser', user);

        return payload;
      });
    },

    getVerificationCode() {
      return authApi.getVerificationCode().then(payload => {
        if (!payload) {
          return null;
        }

        return payload;
      });
    },

    verificationUserMail({ state, commit }, { email }) {
      return authApi.verificationUserMail(email).then(payload => {
        if (!payload) {
          return null;
        }

        const user = cloneDeep(state.user);
        user.email = email;
        commit('setUser', user);

        return payload;
      });
    },

    verificationMailCode({ state, commit }, { code }) {
      return authApi.verificationMailCode(code).then(payload => {
        if (!payload) {
          return null;
        }

        const user = cloneDeep(state.user);
        user.userVerified.email = true;
        commit('setUser', user);

        return payload;
      });
    },

    skipPhone({ state, commit }) {
      const user = cloneDeep(state.user);
      user.userVerified.phone = true;
      localStorage.setItem('user_skipped_verification_phone', '1');
      commit('setUser', user);
    },

    skipEmail({ state, commit }) {
      const user = cloneDeep(state.user);
      user.userVerified.email = true;
      localStorage.setItem('user_skipped_verification_email', '1');
      commit('setUser', user);
    },

    getVerificationForm() {
      return authApi.getVerificationForm().then(payload => {
        if (!payload) {
          return null;
        }

        return payload;
      });
    },

    sendVerificationForm({ state, commit }, { answers }) {
      return authApi.sendVerificationForm(answers).then(payload => {
        if (!payload) {
          return null;
        }

        const user = cloneDeep(state.user);
        user.userVerified.form = true;
        commit('setUser', user);

        return payload;
      });
    },

    getAvailableAnswers(o, { questionId, searchValue, page }) {
      return authApi.getAvailableAnswers(questionId, searchValue, page).then(payload => {
        if (!payload) {
          return false;
        }

        return payload;
      });
    },

    async smsAuthUserPhone({}, { phone, companyId }) {
      return authApi.smsAuthUserPhone(phone, companyId);
    },

    async smsAuthUserPhoneCode({ dispatch }, { code, phone, companyId }) {
      const user = await authApi.smsAuthUserPhoneCode(code, phone, companyId);

      await dispatch('authUserObject', user);

      return user;
    },
  },

  mutations: {
    /**
     * Установит пользователя
     * @param state
     * @param payload
     */
    setUser(state, payload) {
      const user = payload;

      if (!user) {
        localStorage.removeItem('user_state');
        localStorage.removeItem('user_token');
        localStorage.removeItem('company_id');
        localStorage.removeItem('user_skipped_verification_phone');
        localStorage.removeItem('user_skipped_verification_email');

        state.user = null;

        return;
      }

      if (parseInt(localStorage.getItem('user_skipped_verification_phone'), 10) === 1) {
        user.userVerified.phone = true;
      }

      if (parseInt(localStorage.getItem('user_skipped_verification_email'), 10) === 1) {
        user.userVerified.email = true;
      }

      localStorage.setItem('user_token', JSON.stringify(user.userToken));

      if (user.userState) {
        localStorage.setItem('user_state', JSON.stringify(user.userState));
      }

      if (user.companyId) {
        localStorage.setItem('company_id', user.companyId);
      }

      state.user = user;
    },

    setUserToken(state, payload) {
      localStorage.setItem('user_token', JSON.stringify(payload));
    },

    clearStorage() {
      const login = localStorage.getItem('saved_login');

      const quality = localStorage.getItem('saved_quality');

      const idAuthType = localStorage.getItem('id_auth_type');

      const routerBack = localStorage.getItem('router_back');

      const urlTags = localStorage.getItem('url_tags');

      localStorage.clear();

      if (login) {
        localStorage.setItem('saved_login', login);
      }

      if (quality) {
        localStorage.setItem('saved_quality', quality);
      }

      if (idAuthType) {
        localStorage.setItem('id_auth_type', idAuthType);
      }

      if (routerBack) {
        localStorage.setItem('router_back', routerBack);
      }

      if (urlTags) {
        localStorage.setItem('url_tags', urlTags);
      }
    },

    setIsAppActive(state, payload) {
      state.isAppActive = payload;
    },

    enterAccessCode(state, payload) {
      state.accessCode = payload;
    },

    setShowBlockedMessages(state, payload) {
      state.showBlockedMessages = payload;
    },

    accessCodeStatus(state, payload) {
      state.accessCodeStatus = payload;
    },

    setLoadedGroups(state, { id, group }) {
      state.loadedGroups.set(id, group);
    },
  },
};
