import axios from 'axios';
// @ts-ignore
import ApiException from '@/entities/common/ApiException';
import store from '@/store/index';
import { i18n } from '@/lang';

export default async function openExternalLink(url: string, isBlank: boolean = false) {
  try {
    const payload = await axios.post('/external/token');

    const externalToken = payload.data.success.token;

    const externalLink =
      url.indexOf('?') === -1 ? `${url}?token=${externalToken}` : `${url}&token=${externalToken}`;

    if (isBlank) {
      window.open(externalLink, '_blank');
    } else {
      // @ts-ignore
      window.location = externalLink;
    }
  } catch (e) {
    if (e instanceof ApiException) {
      await store.dispatch('helpers/showNotification', {
        // TODO: REFACTOR
        // @ts-ignore
        message: i18n.t(e.getErrorKey()),
      });

      return;
    }

    throw e;
  }
}
