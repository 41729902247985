import CorrectName from '@/entities/common/CorrectName';
import CustomRoute from '@/entities/common/CustomRoute';

export default [
  {
    path: '/rating',
    name: CustomRoute.TO_RATING,
    meta: {
      title: 'achievements_section_name_label',
      moduleCode: CorrectName.RATING,
      eventOnEnter: 'rating_module_open',
      from: { 'dashboard-widget': 'dashboard_rating_btn' },
    },
    component: () => import(/* webpackChunkName: "rating" */ '@/views/rating/index.vue'),
  },
  {
    path: '/rating/my',
    name: CustomRoute.TO_RATING_MY_RATING,
    meta: {
      title: 'achievements_section_name_label',
      moduleCode: CorrectName.RATING,
      eventOnEnter: 'rating_personal_details_btn',
      from: { 'dashboard-widget': 'dashboard_rating_btn' },
    },
    component: () => import(/* webpackChunkName: "rating" */ '@/views/rating/my-rating.vue'),
  },
  {
    path: '/rating/:ratingId-user-rating/:userId-:userSlug',
    name: CustomRoute.TO_RATING_USER,
    meta: {
      title: 'achievements_section_name_label',
      moduleCode: CorrectName.RATING,
      eventOnEnter: 'rating_collegue_details_btn',
    },
    component: () => import(/* webpackChunkName: "rating" */ '@/views/rating/user-rating.vue'),
  },
  {
    path: '/rating/badges',
    alias: '/rating/badges/:id-:slug', // deeplink
    name: CustomRoute.TO_RATING_MY_RATING_BADGES,
    meta: {
      title: 'achievements_section_name_label',
      moduleCode: CorrectName.RATING,
      from: { 'dashboard-widget': 'dashboard_badge_name_btn' },
    },
    component: () => import(/* webpackChunkName: "rating" */ '@/views/rating/badges.vue'),
  },
  {
    path: '/rating/:ratingId-user-rating/:userId-:userSlug/badges',
    name: CustomRoute.TO_RATING_USER_BADGES,
    meta: { title: 'achievements_section_name_label', moduleCode: CorrectName.RATING },
    component: () => import(/* webpackChunkName: "rating" */ '@/views/rating/badges.vue'),
  },
];
