// eslint-disable-next-line max-classes-per-file
import { each } from 'lodash';

export class InterviewPassingReport {
  constructor(entries) {
    this.entries = entries;
  }
}

class QuestionReport {
  constructor({ answers, comment, id, image, order, question, type }) {
    this.answers = answers
      ? answers.map(item => ({
          answer: item.answer,
          id: parseInt(item.id, 10),
          chooseUser: parseInt(item.user_answer, 10) === 1,
        }))
      : null;

    let commentValue;

    if (!comment) {
      commentValue = '\u2013';
    } else if (comment && comment.length === 0) {
      commentValue = '\u2013';
    } else {
      commentValue = comment;
    }
    this.comment = commentValue;
    this.id = parseInt(id, 10);
    this.image = {
      sm: {
        url: image.sm.url,
      },
    };
    this.order = parseInt(order, 10);
    this.question = question;
    this.type = type;

    let count = 0;
    each(this.answers, answer => {
      if (answer.chooseUser) {
        count += 1;
      }
    });

    this.isMultiply = count > 1;
  }
}

export function createInterviewPassingReport(payload) {
  const questions = [];
  each(payload, question => {
    questions.push(new QuestionReport(question));
  });

  return Object.freeze(new InterviewPassingReport(questions));
}
