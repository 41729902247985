import IStatistic from '@/entities/modules/learning-programs/IStatistic';

// @ts-ignore
import dateProvider from '@/providers/js/dateProvider';
import MaterialStatus from '@/entities/common/testing/MaterialStatus';

export default class MaterialUnknownStatistic implements IStatistic {
  percent: number;
  status: string;
  points: number;
  materialId: number;
  viewedTime?: Date;
  updatedAt?: Date;
  updatedAtTimestamp: number;
  startTime?: Date;
  endTime?: Date;

  constructor(payload: any) {
    this.materialId = parseInt(payload.id, 10);
    this.viewedTime = dateProvider(payload.viewed_time);
    this.updatedAt = dateProvider(payload.updated_at);
    this.updatedAtTimestamp = payload.updated_at;
    this.percent = payload.percent ? parseInt(payload.percent, 10) : 0;
    this.status = payload.status;
    this.points = parseInt(payload.points, 10);
  }

  /**
   * Вернет статус материала
   */
  getMaterialStatus(): MaterialStatus {
    if (
      this.viewedTime ||
      this.status === 'complete' ||
      this.status === 'passed' ||
      this.status === 'completed'
    ) {
      return MaterialStatus.PASSED;
    }

    return MaterialStatus.ASSIGNED;
  }

  getPoints(): number {
    return this.points;
  }

  getPercent(): number {
    return this.percent;
  }
}
