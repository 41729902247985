<template>
  <app-tooltip :is-fixed="isFixed">
    <div class="app-tooltip__outer">
      <div class="app-tooltip__icon">
        <FlagSvg />
      </div>
      <div class="app-tooltip__tooltip">{{ $t('common_is_compulsory_text') }}</div>
    </div>
  </app-tooltip>
</template>

<script>
import FlagSvg from '@/components/svg/icons/flag';
import AppTooltip from '@/components/helpers/tooltips/tooltip';

export default {
  components: {
    AppTooltip,
    FlagSvg,
  },

  props: {
    isFixed: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
