import { each, filter, find, sortBy, includes, orderBy } from 'lodash';
import { GetterTree } from 'vuex';
import Program from '@/entities/modules/learning-programs/Program';
import { MyResultsState } from '@/store/modules/my-results/types';
import CorrectName from '@/entities/common/CorrectName';
import MaterialStatus from '@/entities/common/testing/MaterialStatus';
import { LearningItemStatuses } from '@/entities/modules/learning-items/LearningItemStatus';
import { RootState } from '../../types';

export const getters: GetterTree<MyResultsState, RootState> = {
  getAllMaterials(state): string[] {
    return state.materials;
  },

  selectedRequiredFilter(state): boolean {
    return state.selectedRequiredFilter;
  },

  selectedModuleFilter(state): string[] | string {
    return state.selectedModuleFilter;
  },

  selectedDeadlineFilter(state): string[] {
    return state.selectedDeadlineFilter;
  },

  selectedSortFilter(state): string {
    return state.selectedSortFilter;
  },

  /** Фильтрация программ по обязательности
   * @param state
   */
  filteredByRequiredMaterials(state) {
    if (state.selectedRequiredFilter) {
      return filter(state.materials, program => program.isRequired);
    }

    return state.materials;
  },

  /** Фильтрация программ по дедлайну
   * @param state
   * @param getters
   */
  // eslint-disable-next-line no-shadow
  filteredByDeadlineMaterials(state, getters) {
    if (getters.selectedDeadlineFilter.length > 0) {
      return filter(
        getters.filteredByRequiredMaterials,
        program =>
          (includes(getters.selectedDeadlineFilter, 'without-deadline') &&
            program.moduleCode !== CorrectName.LEARNING_PROGRAMS) ||
          (program.moduleCode === CorrectName.LEARNING_PROGRAMS &&
            ((includes(getters.selectedDeadlineFilter, 'without-deadline') &&
              program.getDeadlineStates().hasntDeadline) ||
              (includes(getters.selectedDeadlineFilter, 'deadline') &&
                program.getDeadlineStates().hasDeadline) ||
              (includes(getters.selectedDeadlineFilter, 'passed-deadline') &&
                program.getDeadlineStates().isPassedDeadline) ||
              (includes(getters.selectedDeadlineFilter, 'missed-deadline') &&
                program.getDeadlineStates().isExceededDeadline))),
      );
    }

    return getters.filteredByRequiredMaterials;
  },

  // eslint-disable-next-line no-shadow
  filteredByModuleMaterials(state, getters): Program[] {
    if (getters.selectedModuleFilter.length > 0) {
      const materials: any[] = [];
      each(getters.selectedModuleFilter, moduleFilter => {
        if (moduleFilter === CorrectName.TESTS || moduleFilter === CorrectName.INTERVIEW) {
          materials.push(
            ...filter(
              getters.filteredByDeadlineMaterials,
              material => material.moduleCode === moduleFilter,
            ),
          );
        } else if (includes(moduleFilter, CorrectName.LEARNING_MATERIALS)) {
          const moduleId = parseInt(
            moduleFilter.split(`${CorrectName.LEARNING_MATERIALS}-`).pop(),
            10,
          );
          materials.push(
            ...filter(
              getters.filteredByDeadlineMaterials,
              material => material.moduleId === moduleId,
            ),
          );
        }
      });

      return materials;
    }

    return getters.filteredByDeadlineMaterials;
  },

  /** Сортировка программ
   * @param state
   * @param getters
   */
  // eslint-disable-next-line no-shadow
  filteredBySortMaterials(state, getters): Program[] {
    if (getters.selectedSortFilter) {
      // eslint-disable-next-line default-case
      switch (getters.selectedSortFilter) {
        case 'by-new': {
          return orderBy(getters.filteredByModuleMaterials, ['order'], ['desc']);
        }

        case 'by-alphabetically': {
          return sortBy(getters.filteredByModuleMaterials, [o => o.name.toLowerCase()]);
        }

        case 'by-deadline': {
          const containsDeadline = filter(
            getters.filteredByModuleMaterials,
            program => program.passingDeadline,
          );

          const sortByDeadline = sortBy(containsDeadline, ['passingDeadline']);

          const noContainsDeadline = filter(
            getters.filteredByModuleMaterials,
            program => !program.passingDeadline,
          );

          return [...sortByDeadline, ...noContainsDeadline];
        }

        default:
          break;
      }
    }

    return orderBy(getters.filteredByModuleMaterials, ['id'], ['desc']);
  },

  // eslint-disable-next-line no-shadow
  sliders(state, getters) {
    const passedMaterials: Object[] = [];

    const failedMaterials: Object[] = [];

    const assignedMaterials: Object[] = [];

    each(getters.filteredBySortMaterials, material => {
      switch (material.moduleCode) {
        case CorrectName.TESTS: {
          const status = material.getStatus();

          // eslint-disable-next-line default-case
          switch (status) {
            case MaterialStatus.PASSED: {
              passedMaterials.push(material);
              break;
            }

            case MaterialStatus.FAILED: {
              failedMaterials.push(material);
              break;
            }
            case MaterialStatus.ASSIGNED:
              assignedMaterials.push(material);
              break;

            case MaterialStatus.NEW:
              assignedMaterials.push(material);
              break;

            case MaterialStatus.CHECKING: {
              assignedMaterials.push(material);
              break;
            }

            default:
              break;
          }
          break;
        }

        case CorrectName.INTERVIEW: {
          if (material.getStatistic()?.completed) {
            passedMaterials.push(material);
          } else {
            assignedMaterials.push(material);
          }
          break;
        }

        case CorrectName.LEARNING_MATERIALS: {
          switch (material.statistic.status) {
            case LearningItemStatuses.COMPLETED: {
              passedMaterials.push(material);
              break;
            }

            case LearningItemStatuses.FAILED: {
              failedMaterials.push(material);
              break;
            }

            default:
              assignedMaterials.push(material);
          }
          break;
        }
        default:
          break;
      }
    });

    const passedSlider = find(state.sliders, sliderItem => sliderItem.status === 'passed');
    passedSlider.materials = passedMaterials;

    const failedSlider = find(state.sliders, sliderItem => sliderItem.status === 'failed');
    failedSlider.materials = failedMaterials;

    const assignedSlider = find(state.sliders, sliderItem => sliderItem.status === 'assigned');
    assignedSlider.materials = assignedMaterials;

    return state.sliders;
  },
};
