import { i18n } from '@/lang';
import store from '@/store/index';
import has from 'lodash/has';
import ApiException from '@/entities/common/ApiException';
import isArray from 'lodash/isArray';
import Http404Exception from '@/entities/common/exceptions/Http404Exception';

export default function apiErrorsProvider(obj) {
  const getResponse = () => ('response' in obj ? obj.response : obj);

  const apiResponse = getResponse();

  if (apiResponse.status === 403) {
    if (has(apiResponse, 'data.error.code') && apiResponse.data.error.code === 3001) {
      return apiResponse;
    }
  }

  if (apiResponse.status === 404) {
    throw new Http404Exception();
  }

  try {
    if (apiResponse.status !== 200 || apiResponse.data.error) {
      throw new ApiException(apiResponse);
    } else if (
      has(apiResponse, 'data.success') &&
      isArray(apiResponse.data.success) &&
      apiResponse.data.success.length > 0
    ) {
      for (let i = 0; i < apiResponse.data.success.length; i += 1) {
        if (apiResponse.data.success[i].error) {
          throw new ApiException({
            status: 200,
            data: apiResponse.data.success[i],
          });
        }
      }
    }
  } catch (e) {
    if (has(obj, 'config.globalErrorHandler') && obj.config.globalErrorHandler) {
      if (e.getErrorKey() || e.getErrorTitleKey()) {
        store.dispatch('helpers/showNotification', {
          title: i18n.t(e.getErrorTitleKey()) || '',
          message: i18n.t(e.getErrorKey()) || '',
        });
      }
    }

    throw e;
  }

  return apiResponse;
}
