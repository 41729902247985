import has from 'lodash/has';

export default class PdfSource {
  url: string;
  name?: string;

  constructor(url: string, name?: string) {
    this.url = url;
    this.name = name;
  }
}

/**
 * Создаст объект PdfSource
 * @param payload
 */
export function createPdfSource(payload: any): PdfSource | undefined {
  if (has(payload, 'url')) {
    const name = has(payload, 'name') ? payload.name : undefined;

    return new PdfSource(payload.url, name);
  }
}
