import CustomRoute from '@/entities/common/CustomRoute';

export default [
  {
    path: '/search',
    name: CustomRoute.TO_SEARCH,
    meta: {
      title: 'common_search_text',
      from: { 'global-search': 'search_results_btn' },
    },
    component: () => import(/* webpackChunkName: "other" */ '@/views/search/index.vue'),
  },
  {
    path: '/search/:moduleId-:moduleCode',
    name: CustomRoute.TO_SEARCH_BY_MODULE,
    meta: { title: 'common_search_text' },
    component: () => import(/* webpackChunkName: "other" */ '@/views/search/index.vue'),
  },
];
