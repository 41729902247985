export class PasswordRecoveryCode {
  constructor({ resendTime, isNewCode }) {
    this.resendTime = resendTime;
    this.isNewCode = isNewCode;
  }
}

export function createPasswordRecoveryCode(data) {
  return Object.freeze(
    new PasswordRecoveryCode({
      resendTime: data.resend_time,
      isNewCode: data.is_new_code,
    }),
  );
}
