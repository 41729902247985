import startsWith from 'lodash/startsWith';
import { warningLogProvider } from '@/providers/logProvider';

export default function deepLinksProvider(url: any) {
  if (!url) {
    return '';
  }

  if (startsWith(url, 'mailto:') || startsWith(url, 'tel:')) {
    return url;
  }

  try {
    if (isSameDomain(url)) {
      // eslint-disable-next-line no-useless-escape
      const match = /:\/\/([^\/]+)\/(\d+)\/(.*)$/gm.exec(url);

      if (!match || match.length !== 4) {
        warningLogProvider(`deepLinksProvider: wrong branding url ${url}!`);

        return url;
      }

      const [, , companyId, otherURL] = match;

      return `/${companyId}/${otherURL}`;
    }

    return url;
  } catch {
    return '';
  }
}

export function isSameDomain(url: string): boolean {
  if (startsWith(url, 'mailto:') || startsWith(url, 'tel:')) {
    return true;
  }

  let host = window.location.hostname;

  if (host === 'localhost') {
    host = 'dev.dev-eq.ru';
  }

  const domainPattern = /(([a-z0-9|-]+\.)*[a-z0-9|-]+\.[a-z]+)/;

  const match = url.match(domainPattern);

  if (match) {
    const urlDomain = match.shift();

    return !!urlDomain && host.search(urlDomain) !== -1;
  }

  return false;
}
