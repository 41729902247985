import each from 'lodash/each';
import has from 'lodash/has';
import filter from 'lodash/filter';
import { createUserStatus } from '@/entities/modules/events/UserStatus';
import MaterialCardMeta from '@/entities/modules/my-team/MaterialCardMeta';
import ApiName from '@/entities/common/ApiName';

export class MaterialCard {
  constructor({
    id,
    name,
    image,
    lastActive,
    order,
    progress,
    eventStatus,
    eventWithoutDate,
    passingDeadline,
    passingNoticeDeadline,
    endTime,
  }) {
    this.id = id;
    this.name = name;
    this.image = image;
    this.lastActive = lastActive;
    this.order = order;
    this.progress = progress;
    this.eventStatus = eventStatus;
    this.eventWithoutDate = eventWithoutDate;
    this.passingDeadline = passingDeadline;
    this.passingNoticeDeadline = passingNoticeDeadline;
    this.endTime = endTime;
  }
}

export function createMaterialCard(data) {
  const cards = [];

  const eventWithoutDate = has(data.material, 'event')
    ? parseInt(data.material.event.without_date, 10) === 1
    : null;

  const material = new MaterialCardMeta(data.material);

  // eslint-disable-next-line no-param-reassign
  data = data.users;

  const notStarted = filter(
    data,
    o =>
      o.progress.status === 'not_started' ||
      o.progress.status === 'in_progress' ||
      o.progress.status === 'appointed' ||
      o.progress.status === 'checking',
  );

  const successed = filter(
    data,
    o => o.progress.status === 'success' || o.progress.status === 'completed',
  );

  const failed = filter(data, o => o.progress.status === 'fail');

  const isLearningMaterial = [ApiName.LEARNING_PROGRAM, ApiName.LEARNING_TRACK].includes(
    material.type,
  );

  const notStartedArr = [];
  each(notStarted, card => {
    notStartedArr.push(
      Object.freeze(
        new MaterialCard({
          id: card.id,
          name: card.name,
          image: has(card, 'image') ? card.image.md.url : card.image_wide.md.url,
          lastActive: card.last_active,
          order: card.order,
          progress: card.progress,
          eventStatus: has(card.progress, 'event')
            ? createUserStatus(card.progress.event.user_status)
            : null,
          eventWithoutDate,
          passingDeadline: card.deadline_at,
          passingNoticeDeadline: isLearningMaterial
            ? material.learningItem.deadlineNotificationTime
            : null,
          endTime: isLearningMaterial ? card.progress.end_time : null,
        }),
      ),
    );
  });

  const successedArr = [];
  each(successed, card => {
    successedArr.push(
      Object.freeze(
        new MaterialCard({
          id: card.id,
          name: card.name,
          image: has(card, 'image') ? card.image.md.url : card.image_wide.md.url,
          lastActive: card.last_active,
          order: card.order,
          progress: card.progress,
          eventStatus: has(card.progress, 'event')
            ? createUserStatus(card.progress.event.user_status)
            : null,
          eventWithoutDate,
          passingDeadline: card.deadline_at,
          passingNoticeDeadline: isLearningMaterial
            ? material.learningItem.passingNoticeDeadline
            : null,
          endTime: isLearningMaterial ? card.progress.end_time : null,
        }),
      ),
    );
  });

  const failedArr = [];
  each(failed, card => {
    failedArr.push(
      Object.freeze(
        new MaterialCard({
          id: card.id,
          name: card.name,
          image: has(card, 'image') ? card.image.md.url : card.image_wide.md.url,
          lastActive: card.last_active,
          order: card.order,
          progress: card.progress,
          eventStatus: has(card.progress, 'event')
            ? createUserStatus(card.progress.event.user_status)
            : null,
          eventWithoutDate,
          passingDeadline: card.deadline_at,
          passingNoticeDeadline: isLearningMaterial
            ? material.learningItem.passingNoticeDeadline
            : null,
          endTime: isLearningMaterial ? card.progress.end_time : null,
        }),
      ),
    );
  });

  cards.push(
    {
      status: 'notStarted',
      cards: notStartedArr,
    },
    {
      status: 'failed',
      cards: failedArr,
    },
    {
      status: 'successed',
      cards: successedArr,
    },
  );

  return {
    cards,
    eventWithoutDate,
  };
}
