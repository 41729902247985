<template>
  <div class="app-auth-header">
    <div
      v-if="isShowLogo"
      class="app-auth-header__logo"
    >
      <img
        v-if="branding && branding.coloredLogo"
        :src="branding.coloredLogo"
        alt="logo"
      />
      <img
        v-else
        src="@/assets/images/logo-primary.svg"
        alt="logo"
      />
    </div>

    <div class="app-auth-header__title">
      {{ title }}
      <slot name="title"></slot>
    </div>
    <div
      :class="{ 'app-auth-header__subtitle--not': !subtitle }"
      class="app-auth-header__subtitle"
    >
      {{ subtitle }}
      <slot></slot>

      <div
        v-if="recoveryBy"
        class="app-auth-header__number"
      >
        <router-link
          :to="routeToPasswordReset"
          class="app-auth-header__link"
        >
          {{ recoveryBy }}
          <EditSvg />
        </router-link>
      </div>
    </div>
    <div class="app-auth-mobile-buttons">
      <div
        v-if="backToLogin"
        class="app-auth-header__back-to-login"
      >
        <router-link :to="routeToLogin">
          <CloseSvg />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import EditSvg from '@/components/svg/icons/edit';
import CloseSvg from '@/components/svg/icons/close-24px';
import { routeTo } from '@/providers/routerLinkProvider';
import CustomRoute from '@/entities/common/CustomRoute';
import { mapGetters } from 'vuex';

export default {
  components: {
    EditSvg,
    CloseSvg,
  },

  props: {
    title: {
      type: String,
      default: '',
    },

    subtitle: {
      type: String,
      default: '',
    },

    recoveryBy: {
      type: String,
      default: null,
    },

    backToLogin: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters('settings', ['branding', 'isShowLogo']),

    routeToLogin() {
      return routeTo(CustomRoute.TO_AUTH_LOGIN);
    },

    routeToPasswordReset() {
      return routeTo(CustomRoute.TO_AUTH_PASSWORD_RESET);
    },
  },
};
</script>
