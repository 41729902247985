import IStatistic from '@/entities/modules/learning-programs/IStatistic';
import SectionMaterial from '@/entities/modules/learning-programs/SectionMaterial';
import ToggleSectionMaterial from '@/entities/modules/learning-programs/ToggleSectionMaterial';
import MaterialStatus from '@/entities/common/testing/MaterialStatus';
import ImageSource, { createImageSmSource } from '@/entities/common/sources/ImageSource';
import FileSource, { createFileSource } from '@/entities/common/sources/FileSource';
import BaseMaterial from '@/entities/modules/learning-programs/BaseMaterial';
import CustomRoute from '@/entities/common/CustomRoute';
import { getDocumentTypeByFile, getFileExtension } from '@/entities/common/SupportedFileType';
import MaterialDocumentStatistic from '@/entities/modules/learning-programs/MaterialDocumentStatistic';

export default class MaterialDocument extends BaseMaterial {
  id: number;
  name: string;
  isRating: boolean;
  maxScore: number;
  updatedAtTimestamp: number;
  image?: ImageSource;
  file?: FileSource;
  fileName?: string;
  allowPdfSharing: boolean | null;

  statistic: MaterialDocumentStatistic;

  constructor(statistic: IStatistic, payload: any) {
    super();

    this.statistic = statistic as MaterialDocumentStatistic;

    this.id = parseInt(payload.id, 10);
    this.name = payload.name;
    this.isRating = parseInt(payload.is_rating, 10) === 1;
    this.maxScore = parseInt(payload.max_points, 10);
    this.updatedAtTimestamp = payload.updated_at;
    this.image = createImageSmSource(payload.image);
    this.file = createFileSource(payload.file);
    this.fileName = payload.file_name ? payload.file_name : '';
    this.allowPdfSharing = payload.custom_settings
      ? parseInt(payload.custom_settings.allow_pdf_sharing, 10) === 1
      : null;
  }

  getMaterialType(): string {
    return getDocumentTypeByFile(this.file?.url);
  }

  /**
   * Виджет материала для страницы просмотра программы обучения (выводится в секциях)
   * @param sectionMaterial
   */
  getToggleWidget(sectionMaterial: SectionMaterial): ToggleSectionMaterial {
    const status = this.statistic.getMaterialStatus();

    let widgetParts: unknown[] = [];

    // отображаем баллы если Document рейтинговый и есть баллы
    widgetParts = this.addPointsInfo(
      widgetParts,
      this.isRating,
      this.maxScore,
      this.statistic.points,
    );

    return new ToggleSectionMaterial({
      id: this.id,
      type: this.getMaterialType(),
      routerTo: CustomRoute.TO_LEARNING_PROGRAMS_DOCUMENT,
      routerTrackTo: CustomRoute.TO_LEARNING_TRACK_PROGRAMS_DOCUMENT,
      name: this.name,
      image: this.image,

      lokaliseTagLabel: this.getTagLabel(),
      buttonLabel: null,
      lokaliseButtonLabel:
        status === MaterialStatus.ASSIGNED
          ? 'learn_course_read_button'
          : 'learn_course_read_again_button',
      isButtonOutlined: status !== MaterialStatus.ASSIGNED,

      numberingTypeThrough: this.numberingTypeThrough,
      numberingTypeSections: this.numberingTypeSections,
      numberingTypeHierarchical: this.numberingTypeHierarchical,

      isRating: this.isRating,
      isRequired: sectionMaterial.isRequired,
      isLocked: sectionMaterial.isLocked,

      isPassed: status === MaterialStatus.PASSED,

      parts: widgetParts,
    });
  }

  private getTagLabel(): string {
    switch (this.getMaterialType()) {
      case 'sheet':
        return 'lj_document_table_type_label_text';
      case 'presentation':
        return 'lj_document_presentation_type_label_text';
      case 'image':
        return 'lj_document_image_type_label_text';
      case 'file':
        return 'lj_document_file_type_label_text';
      default:
        return 'lj_document_document_type_label_text';
    }
  }

  getFileExtension() {
    return getFileExtension(this.file?.url);
  }

  getSaving(): any {
    return {
      id: this.id,
      type: 'document',
      viewed_time: Math.round((new Date() as any) / 1000),
    };
  }
}
