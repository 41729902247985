<template>
  <div
    class="banner-slide"
    :style="cssVars"
    :class="slideClass"
    v-on="$listeners"
    @click="clickToSlide"
  >
    <div class="banner-slide__wrapper">
      <picture>
        <source
          :srcset="desktopImage"
          media="(min-width: 600px)"
        />
        <img
          :src="mobileImage"
          alt=""
          class="banner-slide__image"
        />
      </picture>
      <div class="banner-slide__dimming"></div>
      <div
        v-if="isShowDescription"
        class="banner-slide__description"
      >
        <p
          v-if="slide.title"
          class="banner-slide__title"
        >
          {{ slide.title }}
        </p>
        <p
          v-if="slide.subtitle"
          class="banner-slide__subtitle"
        >
          {{ slide.subtitle }}
        </p>
        <button
          v-if="isShowButton"
          ref="button"
          class="banner-slide__button"
          @click="clickToButton"
        >
          {{ slide.deeplink.name }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Slide } from '@/entities/modules/dashboard/Slide';

export default {
  name: 'banner-slide',

  props: {
    /**
     * @param {number} id - id слайда
     * @param {number} order - позиция слайда
     * @param {string} title - заголовок слайда
     * @param {string} subtitle - подзаголовок слайда
     * @param {Object} image - изображение слайда
     * @param {string} image.desktop - ссылка на десктопное изображение слайда
     * @param {string} image.mobile - ссылка на мобильное изображение слайда
     * @param {Object} deeplink - информация для перехода к материалу
     * @param {string} deeplink.url - ссылка на материал
     * @param {string} deeplink.name - подпись кнопки перехода к материалу
     * @param {boolean} deeplink.isInternal - флаг, определяющий внутренняя или нет ссылка
     * @param {boolean} hasButton - наличие кнопки
     * @param {Object} colors - цвета слайда
     * @param {string} colors.title - цвет заголовка
     * @param {string} colors.subtitle - цвет подзаголовка
     * @param {string} colors.buttonBackground - цвет фона кнопки
     * @param {string} colors.buttonText - цвет текста кнопки
     * @param {string} colors.backgroundDimming - цвет затемнения слайда
     * @param {number} backgroundDimmingOpacity - процент прозрачности затемнения слайда
     */
    slide: {
      type: Slide,
      default: () => null,
      required: true,
    },
  },

  computed: {
    ...mapGetters('helpers', ['isMobile']),

    image() {
      return this.slide?.image[this.isMobile ? 'mobile' : 'desktop'];
    },

    slideClass() {
      return this.slide.deeplink?.url ? 'banner-slide--pointer-cursor' : '';
    },

    cssVars() {
      if (!this.slide) {
        return [];
      }

      return {
        '--title-color': this.slide.colors.title,
        '--subtitle-color': this.slide.colors.subtitle,
        '--button-background-color': this.slide.colors.buttonBackground,
        '--button-text-color': this.slide.colors.buttonText,
        '--dimming-color': this.slide.colors.backgroundDimming,
        '--dimming-opacity': `${this.slide.backgroundDimmingOpacity}%`,
      };
    },

    desktopImage() {
      return this.slide.image.desktop.url;
    },

    mobileImage() {
      return this.slide.image.mobile.url;
    },

    isShowDescription() {
      return this.slide.title || this.slide.subtitle || this.slide.deeplink;
    },

    isShowButton() {
      return this.slide.hasButton && this.slide.deeplink;
    },
  },

  methods: {
    clickToSlide(event) {
      if (event.target === this.$refs.button) {
        return;
      }

      this.$emit('clickToSlide', this.slide.id);
    },

    clickToButton() {
      this.$emit('clickToButton', this.slide.id);
    },
  },
};
</script>

<style lang="scss">
$screen-mobile: 600px;
$screen-laptop: 1024px;

.banner-slide {
  border-radius: 16px;
  height: auto;
  overflow: hidden;
  padding-top: 100%;
  position: relative;
  width: 100%;

  &--pointer-cursor {
    cursor: pointer;
  }

  @media (min-width: $screen-mobile) {
    padding-top: 35.764%;
  }

  @media (min-width: $screen-xxxl) {
    height: 600px;
    padding: 0;
    width: 1680px;
  }

  &__wrapper {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    @media (min-width: $screen-xxxl) {
      position: relative;
    }
  }

  &__image {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  &__dimming {
    background: linear-gradient(to top, var(--dimming-color), transparent);
    height: 100%;
    left: 0;
    opacity: var(--dimming-opacity);
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;

    @media (min-width: $screen-mobile) {
      background: linear-gradient(to right, var(--dimming-color), transparent);
      width: 50%;
    }
  }

  &__description {
    bottom: 0;
    left: 0;
    padding: 20px;
    position: absolute;
    width: 100%;
    z-index: 2;

    @media (min-width: $screen-mobile) {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
    }

    @media (min-width: $screen-md) {
      max-width: 406px;
      padding: 32px;
    }

    @media (min-width: $screen-lg) {
      max-width: 520px;
      padding: 48px;
    }
  }

  &__title {
    @include assets-typography-headline;
    color: var(--title-color);
    font-weight: 700;

    @media (min-width: $screen-mobile) {
      font-size: 24px;
      letter-spacing: -0.02em;
      line-height: 34px;
    }

    @media (min-width: $screen-lg) {
      font-size: 36px;
      line-height: 50px;
    }
  }

  &__subtitle {
    @include assets-typography-button-small;
    color: var(--subtitle-color);
    margin-top: 8px;

    @media (min-width: $screen-lg) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__button {
    @include assets-typography-button-small;
    background-color: var(--button-background-color);
    border: none;
    border-radius: 8px;
    color: var(--button-text-color);
    cursor: pointer;
    margin-top: 16px;
    outline: none;
    padding: 10px;
    text-align: center;
    width: 100%;
    position: relative;
    overflow: hidden;

    @media (min-width: $screen-mobile) {
      font-size: 12px;
      line-height: 20px;
      margin-top: 12px;
      padding: 10px 12px;
      width: max-content;
    }

    @media (min-width: $screen-laptop) {
      font-size: 16px;
      line-height: 24px;
      margin-top: 16px;
      padding: 12px 16px;
    }

    &:hover {
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }
}
</style>
