import each from 'lodash/each';
import TestQuestion from '@/entities/modules/learning-programs/test/TestQuestion';
import Pagination from '../../../common/Pagination';

export function createTestQuestions(payload: any): [Pagination, TestQuestion[]] {
  const pagination = new Pagination(payload.meta.pagination);

  const questions: TestQuestion[] = [];

  each(payload.questions, question => {
    question.rating_type = payload.rating_type;
    questions.push(new TestQuestion(question));
  });

  return [pagination, questions];
}
