<template>
  <base-home-widget
    v-if="award.badges.length > 0"
    :widget-name="award.name"
    :to="toBadges"
    :count-text="countText"
  >
    <template #top-of-content>
      <AwardCategories
        v-if="showCategories"
        :categories="categoriesWithScores"
        :from-my-team="fromMyTeam"
        :is-it-deep-manager="isItDeepManager"
        :from-home="fromHome"
      />
    </template>

    <template #content>
      <app-slider
        :key="`slider-${componentId}-${sliderIndex}`"
        :columns="ww5Columns"
        :column-size="ww5ColumnsForIsX"
      >
        <MyAwardsBadge
          v-for="badge in award.badges"
          :key="`badge-${componentId}-${badge.badgeId}-${badge.assignmentId}`"
          :badge="badge"
          :from-rating="!fromMyTeam"
          :category-name="getCategoryName(badge.categoryId)"
          :is-it-deep-manager="isItDeepManager"
          :event-label="eventLabel"
          @cancelBadge="cancelBadge"
          @updateAward="updateAward"
        />
      </app-slider>
    </template>
  </base-home-widget>
</template>

<script>
import { mapGetters } from 'vuex';
import { each, find, cloneDeep, sortBy, filter } from 'lodash';
import BaseHomeWidget from '@/components/app/home/base-home-widget';
import AppSlider from '@/components/helpers/slider';
import MyAwardsBadge from '@/components/app/home/my-awards-badge';
import AwardCategories from '@/components/app/home/award-categories';
import { routeTo } from '@/providers/routerLinkProvider';
import CustomRoute from '@/entities/common/CustomRoute';

export default {
  name: 'my-awards-component',

  components: {
    AppSlider,
    MyAwardsBadge,
    AwardCategories,
    BaseHomeWidget,
  },

  props: {
    award: {
      type: Object,
      default: null,
    },

    isItDeepManager: {
      type: Boolean,
      default: false,
    },

    fromMyTeam: {
      type: Boolean,
      default: false,
    },

    fromHome: {
      type: Boolean,
      default: false,
    },

    eventLabel: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    componentId: null,
    countPoints: 0,
    sliderIndex: 0,
  }),

  computed: {
    ...mapGetters('helpers', ['ww5Columns', 'ww5ColumnsForIsX']),

    id() {
      return parseInt(this.$route.params.id, 10);
    },

    managerId() {
      return parseInt(this.$route.params.managerId, 10);
    },

    toBadges() {
      if (this.fromHome) {
        return {
          name: CustomRoute.TO_RATING_MY_RATING_BADGES,
          params: { fromHome: true },
          query: { from: 'dashboard-widget' },
        };
      }

      if (this.fromMyTeam) {
        return {
          name: CustomRoute.TO_MY_TEAM_EMPLOYEE_BADGES,
          params: {
            fromMyTeam: true,
            id: this.id,
            managerId: this.managerId,
            isItDeepManager: this.isItDeepManager,
          },
          query: this.$route.query,
        };
      }

      return routeTo(CustomRoute.TO_RATING_MY_RATING_BADGES);
    },

    showCategories() {
      return this.categoriesWithScores.length > 1;
    },

    categoriesWithScores() {
      let filteredCategories = cloneDeep(this.award.categories);
      each(this.award.badges, badge => {
        const currentCategory = find(
          filteredCategories,
          category => category.id === badge.categoryId,
        );
        currentCategory.used = true;

        if (badge.assignmentId !== 0) {
          currentCategory.count += badge.scores;
        }
      });

      filteredCategories = filter(filteredCategories, category => category.used);

      return sortBy(filteredCategories, ['order']);
    },

    countText() {
      return this.$tc('learn_d_points_received_text', this.countPoints, {
        0: this.countPoints.toLocaleString(),
      });
    },
  },

  watch: {
    award: {
      deep: true,
      handler(o) {
        this.countPoints = 0;
        each(o.badges, badge => {
          if (badge.assignmentId !== 0) {
            this.countPoints += badge.scores;
          }
        });

        this.sliderIndex += 1;
      },
    },
  },

  created() {
    this.componentId = this._uid;

    each(this.award.badges, badge => {
      if (badge.assignmentId !== 0) {
        this.countPoints += badge.scores;
      }
    });
  },

  methods: {
    updateAward(id) {
      this.$emit('updateAward', id);
    },

    cancelBadge(id) {
      this.$emit('cancelBadge', id);
    },

    getCategoryName(id) {
      return this.award.categories.length > 1
        ? find(this.categoriesWithScores, category => category.id === id).name
        : null;
    },
  },
};
</script>
