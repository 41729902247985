var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-material-card"},[_c('div',{staticClass:"app-material-card__wrapper",class:{
      'app-material-card__wrapper--locked': _vm.isLockedMaterial,
      'app-material-card__wrapper--unknown': _vm.widget.isUnknown,
      'app-material-card__wrapper--stack': _vm.isStack,
    },attrs:{"data-element-id":_vm.widget.id},on:{"click":_vm.checkMaterial}},[(_vm.isRouterLink && _vm.computedTo)?_c('router-link',{attrs:{"to":_vm.computedTo}}):_vm._e(),(!_vm.isRouterLink && _vm.computedTo)?_c('a',{attrs:{"href":_vm.computedTo}}):_vm._e(),_vm._t("default"),_c('div',{staticClass:"app-material-card__container"},[(_vm.isLocked || _vm.widget.isUnknown)?_c('LockedIcon'):_vm._e(),(!_vm.hasImage && _vm.hasOuterFooter)?_c('div',{staticClass:"app-material-card__empty-image"},[(['tests', 'test'].includes(_vm.widget.type) || _vm.widget.moduleCode === 'tests')?_c('TestEmptySvg'):(_vm.widget.type === 'interviews' || _vm.widget.moduleCode === 'interviews')?_c('SurveyEmptySvg'):(_vm.widget.type === 'events' || _vm.widget.type === 'myRatingEvents')?_c('EventEmptySvg'):(_vm.widget.subtype === 'scorm')?_c('ScormMaterialEmptySvg'):(_vm.widget.subtype === 'video')?_c('VideoMaterialEmptySvg'):(_vm.widget.type === 'tasks')?_c('TaskMaterialEmptySvg'):(_vm.isLearningItem)?_c('LearningProgramMaterialEmptySvg'):_c('NotImageSvg')],1):_vm._e(),_c('div',{staticClass:"app-material-card__outer",class:{ 'app-material-card__outer--bs-contain': _vm.isOriginalProportions },style:(_vm.hasImage ? `background-image: url(${_vm.image})` : '')},[_c('div',{staticClass:"app-material-card__inner",class:{ 'app-material-card__inner--empty': !_vm.hasOuterFooter }},[_c('div',{staticClass:"app-material-card__inner-badge-wrapper"},[(_vm.$slots.badge)?_c('div',{staticClass:"app-material-card__badge"},[_vm._t("badge")],2):_vm._e(),(_vm.widget.tagLabel)?_c('div',{staticClass:"app-material-card__tag app-tag app-tag--medium app-tag--dark-gray"},[_vm._v(" "+_vm._s(_vm.widget.tagLabel)+" ")]):_vm._e()]),(!_vm.isLocked && typeof _vm.isFavorite !== 'undefined')?_c('div',{staticClass:"app-material-card__favorite",class:{
              'app-material-card__favorite--light': _vm.isLightTheme,
              'app-material-card__favorite--not-checked': !_vm.isFavorite,
            }},[_c('AppFavorite',{attrs:{"material-id":_vm.widget.id,"material-type":_vm.widget.apiName,"is-favorite":_vm.isFavorite,"material":_vm.widget},on:{"changeFavoriteStatus":_vm.changeFavoriteStatus}})],1):_vm._e(),(_vm.widget.type === 'video' && _vm.widget.duration > 0)?_c('div',{staticClass:"app-material-card__duration"},[_vm._v(" "+_vm._s(_vm.duration)+" ")]):_vm._e()])])],1),_c('app-material-card-footer',{attrs:{"widget":_vm.widget,"is-show-deadline-text":_vm.isShowDeadlineText,"is-outer-footer":_vm.hasOuterFooter,"has-info-bottom":_vm.hasInfoBottom,"to":_vm.computedTo,"points-display-status":_vm.status},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_vm._t("footer")]},proxy:true}],null,true)})],2),(_vm.isStack)?_c('div',{staticClass:"app-material-card__stack"}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }