<template>
  <div class="app-profile-menu">
    <ul class="app-profile-menu__list">
      <template v-if="isShowRating">
        <li
          v-for="(item, index) in ratingMenu"
          :key="`rating-menu-${index}`"
          class="app-profile-menu__item app-profile-menu__item--rating"
        >
          <router-link :to="item.route">
            <component :is="item.icon" />
            <span>
              {{ item.text }}
            </span>
          </router-link>
        </li>
        <hr />
      </template>
      <li
        v-for="(item, index) in profileMenu"
        :key="`profile-menu-${index}`"
        class="app-profile-menu__item app-profile-menu__item--padding"
      >
        <component
          :is="item.route ? 'router-link' : 'div'"
          :to="item.route"
          @click="item.action"
        >
          <component :is="item.icon" />
          <span>
            {{ item.text }}
          </span>
        </component>
      </li>
    </ul>
  </div>
</template>

<script>
import { routeTo } from '@/providers/routerLinkProvider';
import CustomRoute from '@/entities/common/CustomRoute';
import { sendEvent } from '@/providers/analyticsProvider';
import UserIcon from '@/components/svg/icons/user-01-outline.vue';
import SettingsIcon from '@/components/svg/icons/settings.vue';
import LogoutIcon from '@/components/svg/icons/logout.vue';
import Coins02Outline from '@/components/svg/icons/coins-02-outline.vue';
import TrophyOutline from '@/components/svg/icons/trophy-outline.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'profile-menu',

  components: {
    TrophyOutline,
    Coins02Outline,
    LogoutIcon,
    SettingsIcon,
    UserIcon,
  },

  props: {
    userPoints: {
      type: String,
      default: '',
    },

    userPlace: {
      type: String,
      default: '',
    },
  },

  computed: {
    ...mapGetters('helpers', ['isDesktop']),

    userRatingRoute() {
      return {
        name: CustomRoute.TO_RATING_MY_RATING,
        query: {
          from: 'dashboard-widget',
        },
      };
    },

    ratingRoute() {
      return {
        name: CustomRoute.TO_RATING,
        query: {
          from: 'dashboard-widget',
        },
      };
    },

    routeToProfile() {
      return routeTo(CustomRoute.TO_DASHBOARD_PROFILE_INFO);
    },

    routeToSettings() {
      return routeTo(CustomRoute.TO_DASHBOARD_PROFILE_SETTINGS);
    },

    userPointsText() {
      if (!this.userPoints) {
        return '';
      }

      return `${this.userPoints.toLocaleString()} ${this.$tc('common_point_without_number_text', [
        this.userPoints,
      ])}`;
    },

    userPlaceText() {
      if (!this.userPlace) {
        return '';
      }

      return `${this.$t('common_rating_place_text', [this.userPlace])} ${this.$t(
        'dashboard_rating_in_rating_text',
      )}`;
    },

    ratingMenu() {
      const menu = [];

      if (this.userPoints) {
        menu.push({
          route: this.userRatingRoute,
          icon: 'Coins02Outline',
          text: this.userPointsText,
        });
      }

      if (this.userPlace) {
        menu.push({
          route: this.ratingRoute,
          icon: 'TrophyOutline',
          text: this.userPlaceText,
        });
      }

      return menu;
    },

    profileMenu() {
      return [
        {
          route: this.routeToProfile,
          icon: 'UserIcon',
          text: this.$t('profile_data_tab'),
        },
        {
          route: this.routeToSettings,
          icon: 'SettingsIcon',
          text: this.$t('common_settings_button'),
        },
        {
          action: this.logout,
          icon: 'LogoutIcon',
          text: this.$t('common_logout_button'),
        },
      ];
    },

    isShowRating() {
      return !this.isDesktop && !!this.ratingMenu.length;
    },
  },

  methods: {
    logout() {
      sendEvent(this.$analytics, 'auth_action_logout');
      this.$store.dispatch('auth/logout');
      setTimeout(() => {
        window.location.href = '/';
      }, 100);
    },
  },
};
</script>

<style lang="scss">
.app-profile-menu {
  background: var(--primaryHighEmphasis);
  border-radius: 8px;
  box-shadow: 0 4px 16px $black10;
  cursor: pointer;
  height: max-content;
  position: absolute;
  right: 0;
  text-align: left;
  top: 52px;
  width: 200px;
  z-index: 30;

  @media (min-width: $screen-lg) {
    right: -32px;
    top: 28px;
  }

  hr {
    border: none;
    background-color: rgba(0, 0, 0, 0.1);
    height: 1px;
    margin-top: 10px;
  }

  &__item {
    padding: 8px 16px;

    > * {
      @include assets-typography-caption-regular;
      align-items: center;
      display: flex;
      text-decoration: none;
    }

    svg {
      height: 16px;
      margin-right: 8px;
      width: 16px;
    }

    &--padding {
      padding: 8px 16px;
    }

    &--rating {
      padding: 0 16px;

      &:first-child {
        padding-top: 10px;
      }

      &:not(:first-child) {
        margin-top: 8px;
      }

      a {
        color: var(--primary);
        font-weight: 500;
      }
    }

    &:hover {
      background: var(--surfaceGrey);
    }
  }
}
</style>
