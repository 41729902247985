import User from '@/entities/modules/my-team/User';
import dateProvider from '@/providers/dateProvider';

export default class MaterialAttempt {
  id: number;
  taskId: number;
  taskName: string;
  status: string;
  comment: string;
  answerDate: Date | undefined;
  user: User;

  constructor(payload: any) {
    this.id = payload.id;
    this.taskId = payload.task_id;
    this.taskName = payload.task_name;
    this.status = payload.status;
    this.comment = payload.comment;
    this.answerDate = dateProvider(payload.answer_date);
    this.user = new User(payload.user);
  }
}
