import each from 'lodash/each';

export default class FeedbackMeta {
  count: number;
  average: number;
  userHasFeedback: boolean;
  // если нам нужен только один размер картинки можно хранить одну url стрингой
  allocation: Object[];

  constructor(payload: any) {
    this.count = parseInt(payload.count, 10);
    this.average = parseFloat(payload.average);
    this.userHasFeedback = parseInt(payload.user_has_feedback, 10) === 1;

    const allocationArr: any = [];
    each(payload.allocation, allocationItem => {
      allocationArr.push(allocationItem);
    });

    this.allocation = allocationArr;
  }
}
