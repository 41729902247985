<template>
  <div class="app-main-app">
    <template v-if="isAppActive && projectIsReady && isAccessCode && !isUserNeedsVerifySomeone">
      <BlockedMessages
        v-if="showBlockedMessages && user && user.userState && user.userState.hasBlockedMessages"
      />

      <Sidebar
        v-if="showSidebar"
        :mobile-store="mobileStoreForSidebar"
        @closeMobileApp="checkMobileStoreBar"
      />

      <AppLayout v-if="user && hasSettings && isUserLicenseAgree" />

      <AuthLayout v-if="!user || !isUserLicenseAgree" />

      <LoadingIcon
        v-if="user && !hasSettings"
        class="app-loading app-loading--extended"
      />
    </template>
    <template v-else>
      <AccessCodeModal v-if="!isAccessCode && isAppActive" />

      <AuthLayout
        v-else-if="
          (!isUserLicenseAgree || isUserNeedsVerifySomeone) && user && hasSettings && isAppActive
        "
      />

      <LoadingIcon
        v-else
        class="app-loading app-loading--extended"
      />
    </template>

    <AppNotificationList :notifications="notifications" />
  </div>
</template>

<script>
import { each, throttle } from 'lodash';
import { mapGetters } from 'vuex';
import LoadingIcon from '@/components/helpers/loading-icon.vue';
import AppLayout from '@/components/layouts/app.vue';
import AuthLayout from '@/components/layouts/auth.vue';
import Sidebar from '@/components/layouts/sidebar.vue';
import BlockedMessages from '@/components/layouts/blocked-messages.vue';
import AccessCodeModal from '@/components/app/auth/access-code-modal.vue';
import AppNotificationList from '@/components/helpers/notification/notification-list.vue';
import { getCookie, setCookie } from '@/providers/js/cookieProvider';
import multiTabsDetectProvider from '@/providers/multiTabsDetectProvider';
import {
  getDomain,
  getHashDomain,
  sendEvent,
  sendMultiTabsAlert,
} from '@/providers/analyticsProvider';
import FakeUser from '@/entities/modules/auth/FakeUser';
import CustomRoute from '@/entities/common/CustomRoute';
import checkFrame from '@/providers/checkFrameProvider';
import getActiveLanguage from '@/providers/languageProvider';

export default {
  name: 'the-app',

  components: {
    AppLayout,
    AuthLayout,
    Sidebar,
    AccessCodeModal,
    LoadingIcon,
    AppNotificationList,
    BlockedMessages,
  },

  data: () => ({
    projectIsReady: false,

    konami: '38384040373937396665',
    isKonami: false,

    ww: 0,
    wh: 0,

    mobileStore: null,
    showSideBarForFrame: false,
    isShowMobileStoreBar: true,
  }),

  computed: {
    ...mapGetters('auth', [
      'user',
      'isAppActive',
      'isUserNeedsVerifySomeone',
      'isUserLicenseAgree',
      'isAccessCode',
      'showBlockedMessages',
    ]),

    ...mapGetters('helpers', ['notifications', 'hasBackground', 'isSidebarEnabled', 'isDesktop']),

    ...mapGetters('settings', [
      'id',
      'title',
      'cssVariables',
      'hasSettings',
      'modulesList',
      'companyName',
      'branding',
      'isShowSidebar',
      'isShowInFrame',
      'redirectToFrameUrl',
      'interfaceLanguagesList',
      'defaultInterfaceLanguage',
    ]),

    ...mapGetters('intercom', ['showIntercomIcon']),

    enableRoutePages() {
      return [CustomRoute.TO_LEARNING_PROGRAMS_SCORM, CustomRoute.TO_LEARNING_PROGRAMS_HTML];
    },

    isShowingLinkToMobileStore() {
      if (!this.isUserLicenseAgree || this.isUserNeedsVerifySomeone) {
        return false;
      }

      if (this.user && this.ww < 1280) {
        return this.isShowMobileStoreBar;
      }

      return false;
    },

    mobileStoreForSidebar() {
      return this.isShowingLinkToMobileStore ? this.mobileStore : null;
    },

    isScormPage() {
      return (
        this.$route.path.search(/\/scorm$/i) !== -1 || this.$route.path.search(/\/scorm/i) !== -1
      );
    },

    isHtmlMaterialPage() {
      return (
        this.$route.path.search(/\/html$/i) !== -1 || this.$route.path.search(/\/html/i) !== -1
      );
    },

    isVerify() {
      if (this.user) {
        let count = 0;
        each(this.user.userVerified, verified => {
          if (verified === false) {
            count += 1;
          }
        });

        return count <= 0;
      }

      return false;
    },

    showSidebar() {
      if (checkFrame() && this.isSidebarEnabled) {
        return this.showSideBarForFrame;
      }

      if ((checkFrame() && !this.isSidebarEnabled) || !this.isSidebarEnabled) {
        return false;
      }

      return (
        this.user &&
        this.modulesList.length > 0 &&
        !this.isUserNeedsVerifySomeone &&
        this.isUserLicenseAgree &&
        !this.isScormPage &&
        !this.isHtmlMaterialPage
      );
    },

    canGetMenuNewItems() {
      return (
        this.isAppActive &&
        this.projectIsReady &&
        this.isAccessCode &&
        !this.isUserNeedsVerifySomeone &&
        this.user &&
        this.isVerify &&
        this.user.licenseAgree
      );
    },
  },

  watch: {
    cssVariables() {
      this.addCssVariablesToHead();
    },

    ww(w) {
      this.$store.dispatch('helpers/updateWindowWidth', w);
    },

    wh(h) {
      this.$store.dispatch('helpers/updateWindowHeight', h);
    },

    title() {
      this.changeTitle();
    },

    isShowingLinkToMobileStore(status) {
      if (status) {
        this.showLinkToMobileStore();
      }
    },

    /**
     * Если меняется фон, нужно добавить или удалить его у body
     */
    hasBackground() {
      this.backgroundProvider();
    },

    notifications(payload) {
      if (payload.length > 5) {
        this.$store.dispatch('helpers/removeNotification', payload[0].id);
      }

      each(payload, notification => {
        const timeout = notification.timeout || 7000;

        if (timeout === 'infinite') {
          return;
        }

        setTimeout(() => {
          this.$store.dispatch('helpers/removeNotification', notification.id);
        }, timeout);
      });
    },

    canGetMenuNewItems(status) {
      if (status) {
        this.$store.dispatch('settings/updateMenuNewItems');
      }
    },

    showIntercomIcon() {
      this.changeIntercom();
    },
  },

  async created() {
    this.startKonami();

    multiTabsDetectProvider(count => {
      sendMultiTabsAlert(this.$analytics, count);
    });

    // никогда не выполняется, удалить после прогона всех кейсов на код доступа
    // this.accessCodeProvider();
    this.backgroundProvider();
    this.resizeProvider();
    this.brandingProvider();
    this.menuProvider();

    if (this.user) {
      let error = 0;

      for (const value in this.user.userVerified) {
        if (this.user.userVerified[value] === false) {
          error += 1;
        }
      }

      if (this.user.licenseAgree) {
        if (error === 0) {
          this.$store.dispatch('settings/getModules').then(() => {
            this.projectIsReady = true;
          });

          if (this.$route.name !== CustomRoute.TO_DASHBOARD_HOME) {
            this.$store.dispatch('dashboard/getDashboard');
          }
        } else if (error === 1 && !this.user.userVerified.form) {
          this.$store.dispatch('settings/getModules');
        }
      }

      const rememberMe = localStorage.getItem('rememberMe');

      if (rememberMe === '0' && typeof getCookie('sessionCookiesMoment') === 'undefined') {
        localStorage.clear();
        location.reload();
      }
    }

    this.showLinkToMobileStore();
    this.changeTitle();
    this.checkMobileStoreBar();
  },

  methods: {
    checkMobileStoreBar() {
      this.isShowMobileStoreBar =
        parseInt(sessionStorage.getItem('do_not_show_link_to_store'), 10) !== 1;
    },

    changeTitle() {
      if (this.title) {
        document.title = this.title;
      }
    },

    changeIntercom() {
      window.Intercom('update', {
        hide_default_launcher: !this.showIntercomIcon,
      });

      if (!this.showIntercomIcon) {
        window.Intercom('hide');
      }
    },

    accessCodeProvider() {
      if (this.user && this.user.isAccessCodeRequired) {
        this.$store.dispatch('auth/enterAccessCode', {
          accessCode: false,
        });

        this.$store.dispatch('auth/accessCodeStatus', {
          log: {
            attempts: -10,
            expires_in: +new Date(),
          },
        });
      }
    },

    /**
     * Добавит или удалит класс у body
     */
    backgroundProvider() {
      if (this.hasBackground) {
        document.querySelector('body').classList.add('has-background');
      } else {
        document.querySelector('body').classList.remove('has-background');
      }
    },

    /**
     * Отвечает за ширину и высоту
     */
    resizeProvider() {
      // При изменении ширины экрана пересчитываем все значения
      window.addEventListener(
        'resize',
        throttle(() => {
          this.ww = window.innerWidth;
          this.wh = window.innerHeight;
        }, 250),
      );

      // Вызываем также и при загрузке страницы
      this.ww = window.innerWidth;
      this.wh = window.innerHeight;
    },

    addCssVariablesToHead() {
      const head = document.querySelector('head');

      let root = ':root {';
      each(this.cssVariables, (value, property) => {
        root += ` ${property}: ${value};`;
      });
      root += ' }';

      const element = document.createElement('style');
      element.appendChild(document.createTextNode(root));

      head.appendChild(element);

      // css-vars-ponyfill
      if (typeof cssVars !== 'undefined') {
        // eslint-disable-next-line no-undef
        cssVars({});
      }
    },

    /**
     * Отвечает за брендирование
     */
    async brandingProvider() {
      // Получим настройки компании
      let host = window.location.hostname;

      if (host === 'localhost') {
        host = process.env.VUE_APP_DEVELOP_DOMAIN;
      }

      // host = 'dev-eq.ru';e

      if (this.user) {
        await this.$store.dispatch('settings/getBrandingForAuthUser');
        await this.$store.dispatch('settings/getCompanySettings', this.user.companyId);
        this.firebaseSettings();
        this.setFavicon();

        if (this.user instanceof FakeUser) {
          this.$store.dispatch('intercom/setIntercomDefaultParameters');
        } else {
          this.$store.dispatch('intercom/setIntercomParameters');
        }
        this.changeIntercom();
      } else {
        const companyId = await this.$store.dispatch('settings/getBranding', host);

        if (companyId) {
          await this.$store.dispatch('settings/getCompanySettings', companyId);

          if (this.isDesktop) {
            await this.$store.dispatch('intercom/setIntercomDefaultParameters');
          }
        } else {
          this.projectIsReady = true;
        }

        this.setFavicon();
      }

      if (
        this.hasSettings &&
        getCookie('interface_lang') === undefined &&
        !this.interfaceLanguagesList.find(lang => lang.id === getActiveLanguage('interface_lang'))
      ) {
        setCookie('materials_lang', this.defaultInterfaceLanguage);
        setCookie('interface_lang', this.defaultInterfaceLanguage);
        sendEvent(this.$analytics, 'settings_change_language_content');
        sendEvent(this.$analytics, 'settings_change_language_interface');

        location.reload();
      }

      if (this.isShowInFrame) {
        if (
          !checkFrame() &&
          this.$route.name &&
          !this.enableRoutePages.includes(this.$route.name)
        ) {
          window.location.href = this.redirectToFrameUrl;

          return;
        }
      }

      if (checkFrame()) {
        this.showSideBarForFrame = this.isShowSidebar;

        if (!this.showSideBarForFrame) {
          document.querySelector('body').classList.add('body-without-sidebar');
        }
      }

      this.projectIsReady = true;
    },

    async showLinkToMobileStore() {
      if (!this.isShowingLinkToMobileStore) {
        return;
      }

      let os = null;

      if (navigator.userAgent.match(/(iPad|iPhone|iPod)/i)) {
        os = 'ios';
      } else if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
        os = 'android';
      } else {
        return;
      }

      this.mobileStore = await this.$store.dispatch('settings/getAppLinks', os);
    },

    /**
     * Отвечает за боковое меню
     */
    menuProvider() {
      setInterval(() => {
        const links = document.querySelectorAll('.active[data-sidebar-module]');

        if (links.length) {
          let hasWrongActive = false;
          each(links, link => {
            if (link.hasAttribute('data-sidebar-module-code')) {
              if (link.getAttribute('data-sidebar-module-code') !== this.$route.meta.moduleCode) {
                link.classList.remove('active');
                hasWrongActive = true;
              }
            }
          });

          if (hasWrongActive) {
            document
              .querySelector(`[data-sidebar-module-code="${this.$route.meta.moduleCode}"]`)
              .classList.add('active');
          }
        } else if (this.$route.meta.moduleCode) {
          const link = document.querySelector(
            `[data-sidebar-module-code="${this.$route.meta.moduleCode}"]`,
          );

          if (link) {
            link.classList.add('active');
          }
        }
      }, 500);
    },

    /**
     * Реализация Konami комбинации
     */
    startKonami() {
      let keys = '';
      window.addEventListener('keydown', e => {
        keys += e.keyCode;

        if (keys.match(this.konami)) {
          this.isKonami = !this.isKonami;
          keys = '';

          if (this.isKonami) {
            document.querySelector('body').style.filter = 'invert(1) hue-rotate(210deg)';
          } else {
            document.querySelector('body').style['-webkit-filter'] = 'none';
          }
        } else if (keys.length > 40) {
          keys = keys.substr(-30);
        }
      });
    },

    setFavicon() {
      const favicon = document.createElement('link');
      favicon.type = 'image/png';
      favicon.rel = 'icon';
      favicon.sizes = '32x32';

      if (!this.branding.favicon) {
        favicon.href = '/static-images/favicon.png';
      } else {
        favicon.href = this.branding.favicon;
      }
      document.getElementsByTagName('head')[0].appendChild(favicon);
    },

    firebaseSettings() {
      if (!this.user || this.user instanceof FakeUser) {
        return;
      }
      this.$analytics.setUserId(`${getHashDomain()}_${this.user.id}`);
      this.$analytics.setUserProperties({
        company_name: `[${this.user.companyId}] ${this.companyName}`,
        environment: getDomain(),
      });
    },
  },
};
</script>
