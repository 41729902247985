<template>
  <div class="q-container">
    <div class="home-section">
      <div class="home-section__outer">
        <div class="home-section__header">
          <AppSectionTitle :route-to="to">
            {{ widgetName }}
            <template #right-icon>
              <DownArrowSvg
                v-if="!isDesktop"
                class="home-section__title-icon"
                color="gray"
              />
            </template>
          </AppSectionTitle>
          <span
            v-if="countText"
            class="home-section__count"
          >
            {{ countText }}
          </span>
        </div>

        <slot name="top-of-content"></slot>
        <div class="home-section__content">
          <slot name="content"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isEmpty } from 'lodash';
import AppSectionTitle from '@/components/helpers/section-title';
import DownArrowSvg from '@/components/svg/icons/down-arrow';

export default {
  name: 'base-home-widget',

  components: {
    DownArrowSvg,
    AppSectionTitle,
  },

  props: {
    widgetName: {
      type: String,
      required: true,
    },

    to: {
      type: Object,
      default: null,
    },

    countText: {
      type: String,
      default: null,
    },
  },

  computed: {
    ...mapGetters('helpers', ['ww4Columns', 'ww4ColumnsForIsX', 'isDesktop']),

    isEmptyLink() {
      return isEmpty(this.to);
    },
  },
};
</script>
