<template>
  <div
    v-if="isShowProfileHead || isShowTabs"
    class="app-profile-head"
  >
    <div class="q-container">
      <div v-if="!isDesktop">
        <ProfileAnketa
          v-if="isShowPersonalData"
          :data="personalData"
        />
      </div>
      <div
        v-if="isShowProfileHead && isDesktop"
        class="app-profile-head__wrap"
      >
        <div
          v-if="isShowLogo"
          class="app-profile-head__logo"
        >
          <img
            v-if="branding && branding.coloredLogo"
            :src="branding.coloredLogo"
            alt="logo"
          />
          <img
            v-else
            src="@/assets/images/logo-primary.svg"
            alt="logo"
          />
        </div>
        <div class="app-profile-head__info">
          <ProfileAnketa
            v-if="isShowPersonalData"
            :data="personalData"
          />
          <AppSearch v-if="isShowSearch" />
          <ProfileCard v-if="isShowUserInfo" />
        </div>
      </div>
      <div
        v-if="isShowTabs"
        v-show="pageIsReady"
        class="app-profile-head-footer"
      >
        <AppTabs :tabs="tabs" />
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppSearch from '@/components/helpers/search/search';
import AppTabs from '@/components/helpers/tabs';
import ProfileCard from '@/components/app/dashboard/profile-card';
import ProfileAnketa from '@/components/app/dashboard/profile-anketa';
import { routeTo } from '@/providers/routerLinkProvider';
import CustomRoute from '@/entities/common/CustomRoute';
import checkFrame from '@/providers/checkFrameProvider';

export default {
  name: 'profile-head',

  components: {
    AppSearch,
    ProfileCard,
    ProfileAnketa,
    AppTabs,
  },

  props: {
    isFavoritePage: {
      type: Boolean,
      default: false,
    },

    isShowSortOrFilter: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    avatar: [],

    errors: {
      avatar: null,
    },

    pageIsReady: false,
  }),

  computed: {
    ...mapGetters('auth', ['user', 'userName']),

    ...mapGetters('dashboard', ['personalData']),

    ...mapGetters('settings', [
      'branding',
      'isShowLogo',
      'isShowUserInfo',
      'isShowSearch',
      'isShowTabs',
      'isShowPersonalData',
    ]),

    ...mapGetters('favorites', ['favorites']),

    ...mapGetters('helpers', ['isDesktop']),

    questionnaireSubtext() {
      if (this.personalData.comment) {
        return this.personalData.comment;
      }

      const standartTextForStatus = {
        filling: 'profile_personal_data_status_fill_in_form_hint_text',
        check: 'profile_personal_data_we_received_form_text',
        confirm: 'profile_personal_data_status_form_verified_hint_text',
        reject: 'profile_personal_data_status_fill_in_form_again_hint_text',
        blocked: 'profile_personal_data_status_form_blocked_hint_text',
      };

      return this.$t(standartTextForStatus[this.personalData.status]);
    },

    tabs() {
      const tabs = [];

      tabs.push({
        label: this.$t('profile_progress_text'),
        to: routeTo(CustomRoute.TO_DASHBOARD_HOME),
        isActive: this.$route.name === CustomRoute.TO_DASHBOARD_HOME,
      });

      if (this.favorites.length || this.isFavoritePage) {
        tabs.push({
          label: this.$t('common_favorites_text'),
          to: routeTo(CustomRoute.TO_DASHBOARD_PROFILE_FAVORITES),
          isActive: this.isFavoritePage,
        });
      }

      tabs.push({
        label: this.$t('profile_data_tab'),
        to: routeTo(CustomRoute.TO_DASHBOARD_PROFILE_INFO),
        isActive: this.$route.name === CustomRoute.TO_DASHBOARD_PROFILE_INFO,
      });

      return tabs;
    },

    isShowProfileHead() {
      if (checkFrame()) {
        return (
          this.isShowLogo ||
          this.isShowSearch ||
          this.isShowUserInfo ||
          this.isShowPersonalData ||
          this.isShowSortOrFilter
        );
      }

      return true;
    },
  },

  async created() {
    if (!this.isFavoritePage) {
      await this.$store.dispatch('favorites/getFavorites');
    }
    this.pageIsReady = true;
  },

  methods: {
    goToQuestionnaire() {
      if (this.personalData.isAvailable && this.personalData.status === 'filling') {
        this.$router.push(routeTo(CustomRoute.TO_DASHBOARD_PROFILE_QUESTIONNAIRE));

        return;
      }

      if (this.personalData.isAvailable && this.personalData.status === 'confirm') {
        this.$store.dispatch('helpers/showNotification', {
          title: this.$t('profile_personal_data_status_form_verified_title_text'),
          message: this.$t('profile_personal_data_form_verified_if_changes_fill_in_again_text'),
          timeout: 'infinite',
          buttons: [
            {
              label: this.$t('profile_personal_data_fill_in_button_text'),
              callback: () =>
                this.$router.push(routeTo(CustomRoute.TO_DASHBOARD_PROFILE_QUESTIONNAIRE)),
            },
            {
              label: this.$t('common_cancel_button'),
            },
          ],
        });
      } else if (this.personalData.isAvailable && this.personalData.status === 'reject') {
        this.$store.dispatch('helpers/showNotification', {
          title: this.$t('profile_personal_data_form_wasnot_verified_title_text'),
          message: this.$t('profile_personal_data_status_fill_in_form_again_title_text'),
          timeout: 'infinite',
          buttons: [
            {
              label: this.$t('profile_personal_data_fill_in_again_button_text'),
              callback: () =>
                this.$router.push(routeTo(CustomRoute.TO_DASHBOARD_PROFILE_QUESTIONNAIRE)),
            },
            {
              label: this.$t('common_cancel_button'),
            },
          ],
        });
      }
    },
  },
};
</script>
