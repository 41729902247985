import { ActionTree } from 'vuex';
import informationApi from '@/services/information';
import { RootState } from '../../types';

export const actions: ActionTree<any, RootState> = {
  async getInformationCatalogs(o, { catalogId, page }) {
    return informationApi.getInformationCatalogs(catalogId, page);
  },

  async getInformationMaterials(o, { catalogId, page }) {
    return informationApi.getInformationMaterials(catalogId, page);
  },

  async getInformationMaterial(o, id) {
    return informationApi.getInformationMaterial(id);
  },

  async getInformationCatalog(o, id) {
    return informationApi.getInformationCatalog(id);
  },

  getInformationBreadcrumbs(o, { type, id }) {
    return informationApi.getInformationBreadcrumbs(type, id);
  },

  updateViewMaterial(o, material) {
    return informationApi.updateViewMaterial(material);
  },

  async markAsRead(o, material) {
    let type = '';

    if (material) {
      type = material.hasHtml ? 'html' : 'material';
    }

    await informationApi.markAsRead(material.id, type);
  },

  sendViewStatistic(o, viewInfo) {
    const { id, categoryId, viewDate } = viewInfo;

    return informationApi.sendViewStatistic(id, categoryId, viewDate);
  },
};
