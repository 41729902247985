import dateProvider from '@/providers/dateProvider';

export default class ParentComment {
  id: number;
  text: string;
  type: string;
  username: string;
  createdAt: Date | undefined;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.text = payload.text;
    this.type = payload.type;
    this.username = payload.username;
    this.createdAt = dateProvider(payload.send_time);
  }
}
