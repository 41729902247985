import sortBy from 'lodash/sortBy';
import { createFormDataQuestions } from './FormDataQuestions';

export class VerifyFormData {
  constructor({ id, name, questions, order }) {
    this.id = id;
    this.name = name;
    this.questions = questions;
    this.order = order;
  }
}

export function createVerifyFormData(data) {
  const sections = [];

  for (let i = 0; i < data.sections.length; i += 1) {
    const questions = [];

    for (let j = 0; j < data.sections[i].questions.length; j += 1) {
      questions.push(createFormDataQuestions(data.sections[i].questions[j]));
    }

    sections.push(
      new VerifyFormData({
        id: parseInt(data.sections[i].id, 10),
        order: parseInt(data.sections[i].order, 10),
        name: data.sections[i].name,
        questions,
      }),
    );
  }

  return Object.freeze(sortBy(sections, ['order']));
}
