import { each } from 'lodash';
import BaseWidget from '@/entities/modules/dashboard/BaseWidget';

export default class KpiWidget extends BaseWidget {
  info: string;
  kpiNames: string[];

  constructor(payload: any) {
    super(payload);
    this.info = payload.data.info_string;

    this.kpiNames = [];
    each(payload.data.kpis, kpi => {
      this.kpiNames.push(kpi.name);
    });
  }
}
