export default class SurveyAnswer {
  id: number;
  text: string;
  count: number;
  isChosen: boolean;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.text = payload.text;
    this.count = parseInt(payload.count, 10);
    this.isChosen = payload.is_chosen;
  }
}
