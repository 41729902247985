import { MessagesState } from '@/store/modules/messages/types';
import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

export const state: MessagesState = {
  selectedTag: null,
};

const namespaced: boolean = true;

export const messages: Module<MessagesState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
