import MaterialResult from '@/entities/modules/rating/MaterialResult';
import MaterialStatus from '@/entities/common/testing/MaterialStatus';
import { LearningProgramResult } from '@/entities/modules/rating/LearningProgramResult';
import { TaskResult } from '@/entities/modules/rating/TaskResult';
import { EventResult } from '@/entities/modules/rating/EventResult';
import AnswerResult from '@/entities/modules/rating/AnswerResult';

export default class MaterialResultSection {
  status: MaterialStatus;
  title: string;
  sort: number;
  materials: MaterialResult[];
  learningPrograms: LearningProgramResult[];
  tasks: TaskResult[];
  answers: AnswerResult[];
  events: EventResult[];

  constructor(payload: any) {
    this.status = payload.status;
    this.title = this.getStatusTitle();
    this.sort = payload.sort;
    this.materials = payload.materials;
    this.learningPrograms = payload.learningPrograms;
    this.tasks = payload.taskResults;
    this.answers = payload.answerResults;
    this.events = payload.eventResults;
  }

  getStatusTitle(): string {
    switch (this.status) {
      case MaterialStatus.ASSIGNED:
        return 'common_not_started_text';
      case MaterialStatus.FAILED:
        return 'learn_filter_failed_text';
      case MaterialStatus.PASSED:
        return 'learn_filter_passed_text';

      default:
        return '';
    }
  }

  getCurrentScoresOfPassedMaterials(): number {
    if (this.status === MaterialStatus.PASSED) {
      const entities = [
        ...this.materials.map(item => item.currentScores),
        ...this.learningPrograms.map(item => item.programPoints),
        ...this.tasks.map(item => item.points),
        ...this.answers.map(item => item.points),
        ...this.events.map(item => item.points),
      ];

      return entities.reduce((acc, item) => acc + item, 0);
    }

    return 0;
  }

  getHowMuchUserCanScores(): number {
    if (this.status === MaterialStatus.ASSIGNED) {
      const entities = [
        ...this.materials.map(item => item.maxScores),
        ...this.learningPrograms.map(item => item.maxProgramPoints),
        ...this.tasks.map(item => item.maxPoints),
        ...this.events.map(item => item.maxPoints),
      ];

      return entities.reduce((acc, item) => acc + item, 0);
    }

    return 0;
  }
}
