<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.4521 1L12.6588 2.11111C12.7586 2.66334 13.0638 3.15737 13.5129 3.49401C13.962 3.83065
    14.5219 3.98497 15.0801 3.926C15.4499 3.88692 15.8039 3.75573 16.1098 3.54444L17.0456 2.9L19.0995
    4.95778L18.4548 5.89333C18.2446 6.19908 18.1143 6.55258 18.0757 6.92161C18.0372 7.29063 18.0917
    7.66343 18.2342 8.006C18.3768 8.34857 18.6028 8.65001 18.8918 8.88283C19.1808 9.11565 19.5235
    9.27243 19.8886 9.33889L21 9.54556V12.4544L19.8886 12.6667C19.524 12.7339 19.1821 12.8911 18.8939
    13.1242C18.6057 13.3572 18.3804 13.6586 18.2384 14.001C18.0965 14.3433 18.0424 14.7157 18.0812
    15.0842C18.12 15.4528 18.2503 15.8058 18.4604 16.1111L19.105 17.0467L17.0411 19.1L16.1053
    18.4556C15.7996 18.2466 15.4465 18.1173 15.0781 18.0794C14.7097 18.0415 14.3376 18.0963 13.9957
    18.2387C13.6539 18.3811 13.353 18.6066 13.1205 18.8948C12.888 19.183 12.7312 19.5247 12.6644
    19.8889L12.4576 21H9.54237L9.33565 19.8889C9.26953 19.5231 9.11272 19.1798 8.87957 18.8903C8.64642
    18.6008 8.34437 18.3743 8.00106 18.2317C7.6577 18.0891 7.28406 18.0349 6.91434 18.074C6.54461
    18.1131 6.1906 18.2443 5.88469 18.4556L4.94887 19.1L2.89497 17.0422L3.53959 16.1067C3.74964
    15.8013 3.87998 15.4483 3.91873 15.0798C3.95749 14.7113 3.90344 14.3389 3.7615 13.9966C3.61956
    13.6542 3.39425 13.3528 3.10605 13.1198C2.81786 12.8867 2.47594 12.7294 2.11142 12.6622L1
    12.4556V9.54556L2.11142 9.33889C2.47683 9.27212 2.81967 9.11483 3.10858 8.88139C3.39749 8.64796
    3.62325 8.34584 3.76521 8.00267C3.90726 7.65955 3.96104 7.28631 3.92162 6.91706C3.88221 6.54781 3.75087
    6.19432 3.53959 5.88889L2.89497 4.95333L4.95332 2.9L5.88914 3.54444C6.19456 3.75438 6.54765 3.88463
    6.91627 3.92334C7.2849 3.96206 7.65735 3.90801 7.99975 3.76612C8.34215 3.62423 8.64363 3.399 8.87675
    3.11092C9.10987 2.82284 9.26724 2.48106 9.33454 2.11667L9.54126 1.00556L12.4521 1Z"
      stroke-width="1.5"
      stroke-miterlimit="10"
      :class="className"
    />
    <path
      d="M11.0003 14.3333C12.8413 14.3333 14.3337 12.8409 14.3337 11C14.3337 9.15901 12.8413
      7.66663 11.0003 7.66663C9.15938 7.66663 7.66699 9.15901 7.66699 11C7.66699 12.8409
      9.15938 14.3333 11.0003 14.3333Z"
      stroke-width="1.5"
      :class="className"
    />
  </svg>
</template>

<script>
export default {
  name: 'settings-icon',

  props: {
    className: {
      type: String,
      default: 'svg-stroke-surface-high-emphasis',
    },
  },
};
</script>
