import ImageSource, { createOnlyImageMdSource } from '@/entities/common/sources/ImageSource';
import CorrectName, { getCorrectNameByApiCode } from '@/entities/common/CorrectName';
import Context from './Context';

export default abstract class SearchResult {
  id: number;
  abstract type: string;
  name: string;
  moduleId?: number;
  trackId?: number;
  moduleCode: CorrectName;
  image?: ImageSource;
  isNew: boolean | null;
  isFavorite: boolean | null;
  context?: object;

  constructor(payload: any) {
    this.id = parseInt(payload.id ? payload.id : payload.entity_id, 10);
    this.name = payload.name;

    if (payload.module_id) {
      this.moduleId = parseInt(payload.module_id, 10);
    }

    this.trackId = payload.track_id ? parseInt(payload.track_id, 10) : 0;

    this.moduleCode = getCorrectNameByApiCode(
      payload.entity_type ? payload.entity_type : payload.type,
    );

    this.image = createOnlyImageMdSource(payload.image);
    this.isNew = payload.additional_data
      ? parseInt(payload.additional_data.is_new, 10) === 1
      : null;
    this.isFavorite = payload.additional_data
      ? parseInt(payload.additional_data.is_favorite, 10) === 1
      : null;

    if (payload.context) {
      this.context = new Context(payload.context);
    }
  }

  setFavorite(status: boolean): void {
    this.isFavorite = status;
  }
}
