import dateProvider from '@/providers/dateProvider';
import UserCommentOwner from '@/entities/modules/messages/UserCommentOwner';
import ParentComment from '@/entities/modules/messages/ParentComment';

export default class UserComment {
  id: number;
  owner: UserCommentOwner;
  text: string;
  isNew: boolean;
  createdAt?: Date;
  isSupportMarkdown: boolean = true;
  parentComment: ParentComment | null;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.owner = new UserCommentOwner(payload.user);
    this.text = payload.text;
    this.isNew = parseInt(payload.is_new, 10) === 1;
    this.createdAt = dateProvider(payload.send_time);
    this.parentComment = payload.parent_comment ? new ParentComment(payload.parent_comment) : null;
  }

  markAsRead() {
    this.isNew = false;
  }
}

export function createUserCommentFromFeed(payload: any): UserComment {
  const comment = new UserComment({
    id: payload.id,
    text: payload.body.message,
    is_new: 0,
    send_time: payload.created_at,
    user: {
      id: payload.owner.id,
      name: payload.owner.name,
      type: 'user',
      avatar: {
        url: payload.owner.avatar ? payload.owner.avatar.sm.url : null,
      },
    },
  });

  comment.isSupportMarkdown = false;

  return comment;
}
