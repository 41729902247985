import { ActionTree } from 'vuex';
import eventsApi from '@/services/events';
import Event from '@/entities/modules/events/Event';
import Plan from '@/entities/modules/events/Plan';
import OffsetPagination from '@/entities/common/OffsetPagination';
import ZoomConfiguration from '@/entities/modules/events/ZoomConfiguration';
import { RootState } from '../../types';
import { EventsState } from './types';

export const actions: ActionTree<EventsState, RootState> = {
  async loadEvents({}, { limit, offset, filter }): Promise<[Event[], OffsetPagination]> {
    let parsedFilter = null;

    switch (filter) {
      case 'required':
        parsedFilter = 1;
        break;
      case 'optional':
        parsedFilter = 2;
        break;
      case 'online':
        parsedFilter = 3;
        break;
      case 'offline':
        parsedFilter = 4;
        break;
      case 'zoom':
        parsedFilter = 5;
        break;
      default:
        break;
    }

    return eventsApi.loadEvents(limit, offset, parsedFilter);
  },

  async loadPlans({}, { offset, filter }): Promise<[Plan[], OffsetPagination]> {
    let parsedFilter = null;

    switch (filter) {
      case 'required':
        parsedFilter = 1;
        break;
      case 'optional':
        parsedFilter = 2;
        break;
      case 'online':
        parsedFilter = 3;
        break;
      case 'offline':
        parsedFilter = 4;
        break;
      default:
        break;
    }

    return eventsApi.loadPlans(offset, parsedFilter);
  },

  async getById({}, id): Promise<Event | Plan> {
    return eventsApi.getById(id);
  },

  async getZoomConfiguration({}, id): Promise<ZoomConfiguration> {
    return eventsApi.getZoomConfiguration(id);
  },

  async joinEvent({}, id): Promise<Event | Plan> {
    return eventsApi.joinEvent(id);
  },

  async leaveEvent({}, id): Promise<Event | Plan> {
    return eventsApi.leaveEvent(id);
  },

  async clearIsNew({}, id): Promise<void> {
    await eventsApi.clearIsNew(id);
  },
};
