<template>
  <tooltip-component :is-fixed="isFixed">
    <div class="app-tooltip__outer">
      <div class="app-tooltip__icon">
        <CupSvg />
      </div>
      <div class="app-tooltip__tooltip">{{ $t('common_is_in_rating_text') }}</div>
    </div>
  </tooltip-component>
</template>

<script>
import CupSvg from '@/components/svg/icons/cup';
import tooltipComponent from '@/components/helpers/tooltips/tooltip';

export default {
  components: {
    tooltipComponent,
    CupSvg,
  },

  props: {
    isFixed: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
