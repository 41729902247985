<script>
import markdownProvider from '@/providers/markdownProvider';

export default {
  name: 'app-markdown',

  props: {
    source: {
      type: String,
      required: true,
    },

    tag: {
      type: String,
      default: 'div',
    },

    isSafe: {
      type: Boolean,
      default: false,
    },
  },

  render(createElement) {
    return createElement(this.tag, {
      class: ['app-markdown'],
      domProps: {
        innerHTML: markdownProvider(this.source, this.isSafe),
      },
    });
  },
};
</script>
