export default class LearningItemMaterialMeta {
  passingDeadline: number = 0;
  passingNoticeDeadline: number = 0;
  hasNotPassedUsers: number = 0;
  deadlineNotificationTime: number = 0;
  deadlineTime: number = 0;
  deadlineType: string = 'absolute';
  deadlineAccomplishedUsers: number = 0;
  deadlineApproachingUsers: number = 0;
  deadlineNotificationApproachingUsers: number = 0;
  deadlineOverdueUsers: number = 0;

  constructor(payload: any) {
    this.passingDeadline = parseInt(payload.deadline_for_passing_at, 10);
    this.passingNoticeDeadline = parseInt(payload.deadline_for_passing_notice_for_time, 10);
    this.hasNotPassedUsers = parseInt(payload.has_not_passed_users, 10);
    this.deadlineNotificationTime = parseInt(payload.deadline.notification_time, 10);
    this.deadlineTime = payload.deadline.time ? parseInt(payload.deadline.time, 10) : 0;
    this.deadlineType = payload.deadline.type;
    this.deadlineAccomplishedUsers = parseInt(payload.deadline_completion.accomplished, 10);
    this.deadlineApproachingUsers = parseInt(payload.deadline_completion.approaching, 10);
    this.deadlineNotificationApproachingUsers = parseInt(
      payload.deadline_completion.notification_approaching,
      10,
    );
    this.deadlineOverdueUsers = parseInt(payload.deadline_completion.overdue, 10);
  }
}
