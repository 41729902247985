import { createShopSettings } from '@/entities/modules/settings/settings/Shop';
import { createTasksSettings } from '@/entities/modules/settings/settings/Tasks';
import { createMyTeamSettings } from '@/entities/modules/settings/settings/MyTeam';
import { createEventsSettings } from '@/entities/modules/settings/settings/Events';
import { has } from 'lodash';
import { getCorrectNameByApiCode } from '@/entities/common/CorrectName';
import TestSettings from '@/entities/modules/tests/Settings';
import CompetencySettings from '@/entities/modules/competencies/Settings';
import { createKpiSettings } from './settings/Kpi';
import CustomSettings from '../learning-programs/CustomSettings';
import InformationCustomSettings from '../information/CustomSettings';

export class CompanyModule {
  constructor({
    id,
    code,
    correctName,
    title,
    sortIndex,
    isDisplayed,
    settings,
    available,
    link,
    icon,
  }) {
    this.id = id;
    this.code = code;
    this.correctName = correctName;
    this.title = title;
    this.sortIndex = sortIndex;
    this.isDisplayed = isDisplayed;
    this.settings = settings;
    this.available = available;
    this.link = link;
    this.icon = icon;
  }
}

/**
 * Вернет нормальный код в соответствии с кодом api
 * @param code
 * @return {string|*}
 */
function apiCodeForOurCode(code) {
  switch (code) {
    case 'learning_programs':
      return 'learning-programs';
    case 'learning_materials':
      return 'learning-materials';
    case 'infos':
      return 'information';
    case 'team':
      return 'my-team';
    case 'discovers':
      return 'video';
    case 'results':
      return 'my-results';
    case 'shop':
      return 'gift-store';
    case 'achievements':
      return 'rating';
    case 'company_links':
      return 'link';
    default:
      return code;
  }
}

export function createCompanyModule(code, title, module) {
  const ourCode = apiCodeForOurCode(code);

  let settings = null;

  switch (ourCode) {
    case 'evaluations':
      settings = new TestSettings(module.default_settings.tests);
      break;

    case 'competencies':
      settings = new CompetencySettings(module.default_settings.tests);
      break;

    case 'materials':
      if (has(module, 'default_settings.tests')) {
        settings = new CustomSettings(module.default_settings.tests);
      }
      break;

    case 'kpi':
      settings = createKpiSettings(module.default_settings);
      break;

    case 'gift-store':
      settings = createShopSettings(module.default_settings);
      break;

    case 'events':
      settings = createEventsSettings(module.default_settings);
      break;

    case 'tasks':
      settings = createTasksSettings(module.default_settings);
      break;

    case 'my-team':
      settings = createMyTeamSettings(module.default_settings);
      break;

    case 'information':
      settings = new InformationCustomSettings(module.default_settings);
      break;

    default:
      break;
  }

  let icon;

  if (has(module, 'icon.src_svg')) {
    icon = module.icon.src_svg;
  }

  return Object.freeze(
    new CompanyModule({
      id: parseInt(module.id, 10),
      code: ourCode,
      correctName: getCorrectNameByApiCode(code),
      title: module.title,
      sortIndex: module.order,
      isDisplayed: module.isDisplayed,
      available: module.available,
      link: module.link,
      icon,
      settings,
    }),
  );
}
