import { each, filter } from 'lodash';
import BaseWidget from '@/entities/modules/dashboard/BaseWidget';
import ResultsItem from '@/entities/modules/dashboard/ResultsItem';

export default class ResultsWidget extends BaseWidget {
  results: ResultsItem[];

  constructor(payload: any, fromMyTeam: boolean = false) {
    super(payload);

    this.results = [];

    if (fromMyTeam) {
      each(payload, resultsItem => {
        this.results.push(new ResultsItem(resultsItem));
      });
    } else {
      each(payload.data, resultsItem => {
        this.results.push(new ResultsItem(resultsItem));
      });
    }

    if (!fromMyTeam) {
      this.results = filter(this.results, resultItem => resultItem.totalAmount > 0);
    }
  }

  getStudiedMaterialsAmount() {
    let amount = 0;

    this.results.forEach(item => {
      amount += item.success;
    });

    return amount;
  }

  getTotalAmount() {
    let totalAmount = 0;
    each(this.results, resultsItem => {
      if (resultsItem.type !== 'all') {
        totalAmount += resultsItem.totalAmount;
      }
    });

    return totalAmount;
  }

  addResultItemAll() {
    const resultItemAll = new ResultsItem({
      amount: {
        fail: this.results.reduce((prev, item) => prev + item.fail + item.notParticipated, 0),
        not_started: this.results.reduce(
          (prev, item) => prev + item.notStarted + item.requestSuccess + item.request,
          0,
        ),
        success: this.results.reduce((prev, item) => prev + item.success + item.participated, 0),
      },
      type: 'all',
    });

    this.results = [resultItemAll, ...this.results];
  }
}
