interface NextNodeScheme {
  available?: boolean;
  availability_date?: number;
}

export default class NextNodeAvailability {
  isAvailable: boolean = false;
  availabilityDate: number = 0;

  constructor(payload: NextNodeScheme) {
    this.isAvailable = payload.available || false;
    this.availabilityDate = payload.availability_date || 0;
  }
}
