<template>
  <div class="app-toolbar q-container">
    <div class="app-toolbar__line">
      <div
        class="app-toolbar__burger"
        @click="$emit('toggle-sidebar')"
      >
        <div class="app-toolbar__burger-item"></div>
        <div class="app-toolbar__burger-item"></div>
        <div class="app-toolbar__burger-item"></div>
      </div>

      <div
        v-if="!isOpenedSearch"
        class="app-toolbar__title"
      >
        <slot name="title"></slot>
      </div>

      <Search
        :is-opened-search.sync="isOpenedSearch"
        class="app-toolbar__search"
      />

      <ProfileCardMobile class="app-toolbar__profile" />
    </div>
  </div>
</template>

<script>
import ProfileCardMobile from '@/components/app/dashboard/profile-card-mobile';
import Search from '@/components/helpers/search/search';

export default {
  name: 'toolbar',

  components: {
    Search,
    ProfileCardMobile,
  },

  data() {
    return {
      isOpenedSearch: false,
    };
  },
};
</script>
