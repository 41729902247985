import { each, find } from 'lodash';
import brandingProvider from '@/providers/js/brandingProvider';
// eslint-disable-next-line import/no-cycle
import settingsApi from '@/services/settings';
import CorrectName from '@/entities/common/CorrectName';
// eslint-disable-next-line import/no-cycle
import checkFrame from '@/providers/checkFrameProvider';
import getActiveLanguage from '@/providers/languageProvider';

export default {
  namespaced: true,

  state: {
    /**
     * ID компании
     * @type {Number}
     */
    id: 0,

    defaultInterfaceLanguage: '',

    defaultMaterialsLanguage: '',

    interfaceLanguagesList: [],

    materialsLanguagesList: [],

    activeInterfaceLanguage: '',

    activeMaterialsLanguage: '',

    menuNewItems: null,

    branding: null,

    authTypes: null,

    credentialsSettings: null,

    frameSettings: null,

    passwordPolicies: null,

    /**
     * Брендирование вебки
     */
    settings: null,

    /**
     * Данные службы поддержки
     */
    support: null,

    /**
     * Название компании
     */
    companyName: null,

    /**
     * Модули
     */
    modules: null,
    /**
     * Все модули для фильтров в моей команде
     */
    allModules: null,

    /** @type {MenuItem[]} */
    menuItems: [],

    /**
     * Доступность регистрации
     */
    registrationSettings: null,

    /**
     * Title страницы
     * @type {String}
     */
    title: '',

    needShowBalanceKpiTitle: null,

    showNextMaterial: true,
  },

  getters: {
    /**
     * Есть или нет настройки
     * @param state
     * @return {boolean}
     */
    hasSettings: state => !!state.settings,

    globalSettings: state => state.settings,

    checkEnabledModuleByType: state => moduleType =>
      !!state.allModules.find(module => module.correctName === moduleType),

    menuNewItems: state => state.menuNewItems,

    menuItems: state => state.menuItems,

    authTypes: state => state.authTypes,

    credentialsSettings: state => state.credentialsSettings,

    frameSettings: state => state.frameSettings,

    isShowInFrame: state => (state.frameSettings ? state.frameSettings.isShowInFrame : false),

    redirectToFrameUrl: state => state.frameSettings.redirectToFrameUrl,

    isShowSidebar: state => (checkFrame() ? state.frameSettings.isShowSidebar : true),

    isShowTitle: state => (checkFrame() ? state.frameSettings.isShowSectionName : true),

    isShowLogo: state => (checkFrame() ? state.frameSettings.isShowLogo : true),

    isShowUserInfo: state => (checkFrame() ? state.frameSettings.isShowUserInfo : true),

    isShowTabs: state => (checkFrame() ? state.frameSettings.isShowTabs : true),

    isShowPersonalData: state => (checkFrame() ? state.frameSettings.isShowPersonalData : true),

    isShowSearch: state => (checkFrame() ? state.frameSettings.isShowSearch : true),

    isShowFilter: state => (checkFrame() ? state.frameSettings.isShowFilterMaterialsParams : true),

    isShowTags: state => (checkFrame() ? state.frameSettings.isShowFilterMaterialsTags : true),

    isShowSortItems: state => (checkFrame() ? state.frameSettings.isShowSortItems : true),

    isShowSectionConditions: state =>
      checkFrame() ? state.frameSettings.isShowSectionConditions : true,

    isShowShopOrderElements: state =>
      checkFrame() ? state.frameSettings.isShowShopOrderElements : true,

    isShowBreadcrumbs: state => (checkFrame() ? state.frameSettings.isShowBreadcrumbs : true),

    passwordPolicies: state => state.passwordPolicies,

    support: state => state.support,

    companyName: state => state.companyName,

    /**
     * Есть или нет модули
     * @param state
     * @return {boolean}
     */
    hasModules: state => !!state.modules,

    /**
     * Настройки регистрации
     */
    registrationSettings: state => state.registrationSettings,

    /**
     * ID компании
     * @param state
     * @return {Number}
     */
    id: state => state.id,

    defaultInterfaceLanguage: state => state.defaultInterfaceLanguage,

    defaultMaterialsLanguage: state => state.defaultMaterialsLanguage,

    interfaceLanguagesList: state => state.interfaceLanguagesList,

    materialsLanguagesList: state => state.materialsLanguagesList,

    activeInterfaceLanguage: state => state.activeInterfaceLanguage,

    activeMaterialsLanguage: state => state.activeMaterialsLanguage,

    /**
     * Брендирование вебки
     * @param state
     * @return {CompanyBranding|null}
     */
    branding: state => state.branding,

    /**
     * Вернет css variables
     * @param state
     * @returns {*}
     */
    cssVariables: state => brandingProvider(state.branding),

    /**
     * Список модулей
     * @param state
     * @return {Array.<CompanyModule>}
     */
    modulesList: state => {
      if (!state.modules) {
        return [];
      }

      return state.modules;
    },

    allModules: state => state.allModules,

    /**
     * Title страницы
     * @param state
     * @return {String}
     */
    title: state => state.title,

    needShowBalanceKpiTitle: state => state.needShowBalanceKpiTitle,

    showNextMaterial: state => state.showNextMaterial,
  },

  actions: {
    async updateMenuNewItems({ commit }) {
      const menuNewItems = await settingsApi.updateMenuNewItems();

      commit('setMenuNewItems', menuNewItems);
    },

    /**
     * Вернет брендирование вебки
     * @param commit
     * @param domain
     * @return {Promise<T>}
     */
    getBranding({ commit }, domain) {
      return settingsApi.getBranding(domain).then(payload => {
        if (!payload) {
          return 0;
        }

        commit('setBranding', payload);

        return payload.id;
      });
    },

    checkBranding({}, domain) {
      return settingsApi.getBranding(domain);
    },

    async getAppLinks({}, os) {
      return settingsApi.getAppLinks(os);
    },

    /**
     * Вернет брендирование вебки для авторизованного пользователя
     * @param commit
     */
    getBrandingForAuthUser({ commit }) {
      return settingsApi.getBrandingForAuthUser().then(payload => {
        if (!payload) {
          return;
        }

        commit('setBranding', payload);
      });
    },

    /**
     * Вернет настройки компании
     * @param rootState
     * @param companyId
     * @return {Promise<T>}
     */
    async getCompanySettings({ commit }, companyId) {
      if (typeof companyId === 'undefined') {
        // eslint-disable-next-line no-param-reassign
        companyId = parseInt(localStorage.getItem('company_id'), 10);
      }

      const payload = await settingsApi.getCompanySettings(companyId);

      if (!payload) {
        return;
      }

      commit('setAuthTypes', payload.authTypes);
      commit('setFrameSettings', payload.frameSettings);
      commit('setCredentialsSettings', payload.credentialsSettings);
      commit('setPasswordPolicies', payload.passwordPolicies);
      commit('setSettings', payload.globals);
      commit('setSupport', payload.support);
      commit('setCompanyName', payload.companyName);
      commit('setRegistrationSettings', payload.registrationSettings);
      commit('setCompanyId', companyId);
      commit('setInterfaceLanguagesList', payload.languageSettings.languages);
      commit(
        'setMaterialsLanguagesList',
        payload.languageSettings.languages.filter(lang =>
          payload.languageSettings.languageList.includes(lang.id),
        ),
      );

      commit('setDefaultInterfaceLanguage', payload.languageSettings.interfaceLang);
      commit('setDefaultMaterialsLanguage', payload.languageSettings.sourceLang);
      commit('setActiveInterfaceLanguage', getActiveLanguage('interface_lang'));
      commit('setActiveMaterialsLanguage', getActiveLanguage('materials_lang'));
    },

    async getModules({ commit }) {
      const { allModules, availableModules, menuItems } = await settingsApi.getModules();

      commit('setAllModules', allModules);
      commit('setModules', availableModules);
      commit('setMenuItems', menuItems);

      const kpi = find(availableModules, o => o.code === 'kpi');

      if (kpi) {
        commit('setBalanceKpiTitle', kpi.settings.hideBalanceTitle);
      }
    },

    /**
     * Вернет настройки модуля по коду
     * @param state
     * @param code
     */
    getSettingsByCode({ state }, code) {
      return find(state.modules, o => o.code === code);
    },

    setTitle({ commit }, title) {
      commit('setTitle', title);
    },

    saveTestDataForElastic(o, { payload }) {
      return settingsApi.saveTestDataForElastic(payload);
    },
  },

  mutations: {
    setAuthTypes(state, payload) {
      state.authTypes = payload;
    },

    setCredentialsSettings(state, payload) {
      state.credentialsSettings = payload;
    },

    setFrameSettings(state, payload) {
      state.frameSettings = payload;
    },

    setPasswordPolicies(state, payload) {
      state.passwordPolicies = payload;
    },

    /**
     * Сохранит настройки
     * @param state
     * @param payload
     */
    setSettings(state, payload) {
      state.settings = payload;
    },

    /**
     * Сохранит данные службы поддержки
     * @param state
     * @param payload
     */
    setSupport(state, payload) {
      state.support = payload;
    },

    /**
     * Сохранит название компании
     * @param state
     * @param payload
     */
    setCompanyName(state, payload) {
      state.companyName = payload;
    },

    setModules(state, payload) {
      state.modules = payload;
    },

    setAllModules(state, payload) {
      state.allModules = payload;
    },

    setMenuItems(state, payload) {
      state.menuItems = payload;
    },

    setBalanceKpiTitle(state, payload) {
      state.needShowBalanceKpiTitle = !payload;
    },

    setCompanyId(state, payload) {
      state.id = payload;
    },

    setDefaultInterfaceLanguage(state, payload) {
      state.defaultInterfaceLanguage = payload;
    },

    setDefaultMaterialsLanguage(state, payload) {
      state.defaultMaterialsLanguage = payload;
    },

    setInterfaceLanguagesList(state, payload) {
      state.interfaceLanguagesList = payload;
    },

    setMaterialsLanguagesList(state, payload) {
      state.materialsLanguagesList = payload;
    },

    setActiveInterfaceLanguage(state, payload) {
      state.activeInterfaceLanguage = payload;
    },

    setActiveMaterialsLanguage(state, payload) {
      state.activeMaterialsLanguage = payload;
    },

    /**
     * Сохранит брендирование
     * @param state
     * @param payload
     */
    setBranding(state, payload) {
      state.branding = payload;
      state.id = payload.id;
    },

    /**
     * Сохранит title
     * @param state
     * @param payload
     */
    setTitle(state, payload) {
      state.title = payload;
    },

    setMenuNewItems(state, payload) {
      state.menuNewItems = payload;

      each(state.menuItems, element => {
        element.clearCount();
      });

      each(state.menuNewItems, element => {
        let moduleCode;

        if (
          element.moduleCode === CorrectName.TESTS ||
          element.moduleCode === CorrectName.INTERVIEW ||
          element.moduleCode === CorrectName.COMPETENCY
        ) {
          moduleCode = CorrectName.EVALUATIONS;
        } else {
          moduleCode = element.moduleCode;
        }

        let menuItem;

        if (moduleCode === CorrectName.LEARNING_MATERIALS) {
          menuItem = find(
            state.menuItems,
            o => o.moduleCode === moduleCode && o.moduleId === element.moduleId,
          );
        } else {
          menuItem = find(state.menuItems, o => o.moduleCode === moduleCode);
        }

        // может не прийти модуль gift-store, тогда menuItem будет undefined
        if (menuItem) {
          menuItem.incrementCount(element.count);
        }
      });
    },

    setRegistrationSettings(state, payload) {
      state.registrationSettings = payload;
    },

    /**
     * Сохранит состояние виджета "продолжить обучение"
     * @param state
     * @param payload
     */
    setShowNextMaterial(state, payload) {
      state.showNextMaterial = payload;
    },
  },
};
