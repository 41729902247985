export default class TestSection {
  id: number;
  name: string;
  testId: number;
  order: number;
  isPool: boolean;
  questionsCount: number;
  maxPoints: number;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.name = payload.name;
    this.testId = payload.test_id;
    this.order = parseInt(payload.order, 10);
    this.isPool = parseInt(payload.is_pool, 10) === 1;
    this.questionsCount = parseInt(payload.questions_count, 10);
    this.maxPoints = parseInt(payload.max_points, 10);
  }
}
