import BaseGroup from '@/entities/common/groups/BaseGroup';
import Pagination from '@/entities/common/Pagination';
import each from 'lodash/each';
import ChildGroup from '@/entities/common/groups/ChildGroup';
import FilterGroup from '@/entities/common/groups/FilterGroup';

export default class RootGroup extends BaseGroup {
  rootId: number;
  isAvailableOnRegistration: boolean = true;
  children: ChildGroup[] | FilterGroup[] = [];

  subfilters: BaseGroup[] = [];
  hasUsers: boolean = false;
  hasError: boolean = false;

  constructor(payload: any) {
    super(payload);

    this.rootId = payload.root_id ? parseInt(payload.root_id, 10) : 0;
  }

  setHasError(hasError: boolean) {
    this.hasError = hasError;
  }

  getFirstSelectedGroup(): BaseGroup | null {
    return this.subfilters ? this.subfilters[0] : null;
  }

  setHasUsers(hasUsers: boolean) {
    this.hasUsers = hasUsers;
  }

  setIsAvailableOnRegistration(isAvailableOnRegistration: boolean) {
    this.isAvailableOnRegistration = isAvailableOnRegistration;
  }

  setSubfilters(subfilters: BaseGroup[]) {
    this.subfilters = subfilters;
  }

  clearSubfilters() {
    this.subfilters = [];
    this.clearChecked();
  }
}

export function createGroupsList(payload: any): [Pagination, RootGroup[]] {
  const pagination = new Pagination(payload.meta.pagination);

  const groups: RootGroup[] = [];
  each(payload.groups, group => {
    groups.push(new RootGroup(group));
  });

  return [pagination, groups];
}
