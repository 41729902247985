import { MutationTree } from 'vuex';
// eslint-disable-next-line import/no-cycle
import { RatingState } from '@/store/modules/rating/types';

export const mutations: MutationTree<RatingState> = {
  setRating(state, payload) {
    state.rating = payload;
  },

  setMyBadgesCategories(state, payload) {
    state.myBadgesCategories = payload;
  },

  setUsersList(state, payload) {
    state.usersList = payload;
  },

  setUsersMeta(state, payload) {
    state.usersMeta = payload;
  },

  setCurrentUserInfo(state, payload) {
    state.currentUser = payload;
  },

  setBadges(state, payload) {
    state.badges = payload;
  },

  setMyBadges(state, payload) {
    state.myBadges = payload;
  },

  setMaterialResultSections(state, payload) {
    state.materialResultSections = payload;
  },

  setBadgesCategory(state, payload) {
    state.badgesCategory = payload;
  },

  setCurrentUser(state, payload) {
    state.currentUser = payload;
  },

  setKpi(state, payload) {
    state.kpi = payload;
  },
};
