<template>
  <svg
    :class="classes"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 5.02485C6 4.45884 6.44772 4 7 4L17 4C17.5523 4 18 4.45884 18 5.02485V18.9731C18 19.8861 16.9229 20.3434 16.2929 19.6978L12.7071 16.0229C12.3166 15.6227 11.6834 15.6227 11.2929 16.0229L7.70711 19.6978C7.07714 20.3434 6 19.8861 6 18.9731L6 5.02485Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'bookmark',
  props: {
    isSurfaceEmphasis20: {
      type: Boolean,
      default: true,
    },

    isInfoColor: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classes() {
      const classes = [];

      if (this.isInfoColor) {
        classes.push('info');
      }

      if (this.isSurfaceEmphasis20) {
        classes.push('surface-emphasis20');
      }

      return classes;
    },
  },
};
</script>

<style scoped>
.info path {
  fill: var(--info);
}

.surface-emphasis20 path {
  fill: var(--surfaceEmphasis20);
}

.surface-emphasis20:hover path {
  fill: var(--surfaceDisabledEmphasis);
  transition-duration: 0.15s;
}
</style>
