import UserInfo from '@/entities/modules/rating/UserInfo';
import OffsetPagination from '@/entities/common/OffsetPagination';
import { each } from 'lodash';

export default class UserInfoAndMeta {
  users: UserInfo[];
  meta: OffsetPagination;

  constructor(payload: any) {
    this.users = this.getUsers(payload.data.success.users);
    this.meta = new OffsetPagination(payload.data.success.meta);
  }

  private getUsers(payload: any): UserInfo[] {
    const users: UserInfo[] = [];

    each(payload, user => {
      users.push(new UserInfo(user));
    });

    return users;
  }
}
