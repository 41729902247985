// @ts-ignore
import { createMaterialCardWidget } from '@/entities/common/MaterialCardWidget';
import slugProvider from '@/providers/slugProvider';
import ImageSource, { createImageLgSource } from '@/entities/common/sources/ImageSource';
import { has } from 'lodash';
import CustomRoute from '@/entities/common/CustomRoute';
import MaterialStatus from '@/entities/common/testing/MaterialStatus';

export class LearningProgramResult {
  id: number;
  name: string;
  moduleName: string;
  moduleId: number;
  order: number;
  duration: number;
  isNew: boolean;
  isRating: boolean;
  isRequired: boolean;
  isChecked: boolean;
  maxPoints: number;
  percent: number;
  points: number;
  image?: ImageSource;
  status: string;
  passingDeadline: number;
  passingNoticeDeadline: number;
  endTime: number;
  maxBonusPoints: number;
  maxPenaltyPoints: number;
  maxProgramPoints: number = 0;
  programPoints: number = 0;
  bonusPoints: number;
  penaltyPoints: number;

  constructor(payload: any) {
    this.id = payload.id ? parseInt(payload.id, 10) : parseInt(payload.entity_id, 10);
    this.name = payload.name;
    this.moduleName = payload.module_name;
    this.moduleId = parseInt(payload.module_id, 10);
    this.order = parseInt(payload.order, 10);
    this.duration = parseInt(payload.duration, 10);
    this.isNew = parseInt(payload.is_new, 10) === 1;
    this.isRating = parseInt(payload.is_rating, 10) === 1;
    this.isRequired = parseInt(payload.is_required, 10) === 1;
    this.isChecked = parseInt(payload.is_checked, 10) === 1;
    this.percent = parseInt(payload.percent, 10);
    this.points = parseInt(payload.points, 10);
    this.maxPoints = parseInt(payload.max_points, 10);
    this.status = has(payload, 'status') ? payload.status : payload.progress_status;
    this.passingDeadline = parseInt(payload.deadline_for_passing_at, 10);
    this.passingNoticeDeadline = parseInt(payload.deadline_for_passing_notice_for_time, 10);
    this.endTime = parseInt(payload.end_time, 10);
    this.maxBonusPoints = parseInt(payload.max_bonus_points, 10);
    this.maxPenaltyPoints = parseInt(payload.max_penalty_points, 10);
    this.bonusPoints = parseInt(payload.bonus_points, 10);
    this.penaltyPoints = parseInt(payload.penalty_points, 10);
    this.maxProgramPoints = parseInt(payload.max_program_points, 10) || 0;
    this.programPoints = parseInt(payload.program_points, 10) || 0;

    this.image = payload.image_wide
      ? createImageLgSource(payload.image_wide)
      : createImageLgSource(payload.image);
  }

  getStatus() {
    if (this.status === 'fail') {
      return MaterialStatus.FAILED;
    }

    if (this.status === 'success') {
      return MaterialStatus.PASSED;
    }

    return MaterialStatus.ASSIGNED;
  }

  getWidget() {
    const isInProgress = this.status === 'in_progress' || 'progress';

    const isPassed = this.status === 'completed';

    const isFailed = this.status === 'fail';

    const isChecking = this.status === 'checking';

    const isAssigned = this.status === 'appointed';

    const parts = [];

    const maxPointsForProgram = this.maxProgramPoints;

    const pointsForProgram = this.programPoints;

    if (this.isRating && maxPointsForProgram > 0) {
      // если новый или назначен, пишем только сколько он может заработать
      if (this.isNew || isAssigned) {
        parts.push([
          'learn_earn_d_points_text',
          maxPointsForProgram,
          { 0: maxPointsForProgram.toLocaleString() },
        ]);
      } else if (isInProgress) {
        // в процессе прохождения, выводим из скольки-то баллов
        parts.push([
          'learn_d_from_d_points_text',
          maxPointsForProgram,
          { 0: pointsForProgram.toLocaleString(), 1: maxPointsForProgram.toLocaleString() },
        ]);
      } else if (isPassed || isFailed) {
        // успешно пройден, если на максимум -- выводим без количества попыток только баллы,
        // если не на максимум -- если есть попытки, выводим сколько и сколько баллов из скольки
        if (pointsForProgram < maxPointsForProgram) {
          parts.push([
            'learn_d_from_d_points_text',
            maxPointsForProgram,
            { 0: pointsForProgram.toLocaleString(), 1: maxPointsForProgram.toLocaleString() },
          ]);
        } else {
          parts.push([
            'common_point_number_text',
            maxPointsForProgram,
            { 0: maxPointsForProgram.toLocaleString() },
          ]);
        }
      }
    }

    return createMaterialCardWidget({
      name: this.name,
      order: this.order,
      to: {
        name: CustomRoute.TO_LEARNING_PROGRAMS_MATERIAL,
        params: {
          id: this.id,
          slug: slugProvider(this.name),
          moduleId: this.moduleId,
          moduleSlug: slugProvider(this.moduleName),
        },
      },
      type: 'learning-program',
      image: this.image,
      isNew: this.isNew,
      isRating: this.isRating,
      isRequired: this.isRequired,
      isInProgress,
      isPassed,
      isChecking,
      isFailed,
      isAssigned,
      isChecked: this.isChecked,
      parts,
      percent: this.percent,
      passingDeadline: this.passingDeadline,
      passingNoticeDeadline: this.passingNoticeDeadline,
      endTime: this.endTime,
      moduleId: this.moduleId,
      moduleName: this.moduleName,
      maxBonusPoints: this.maxBonusPoints,
      maxPenaltyPoints: this.maxPenaltyPoints,
      maxProgramPoints: this.maxProgramPoints,
      maxPoints: this.maxPoints,
      penaltyPoints: this.penaltyPoints,
      bonusPoints: this.bonusPoints,
      programPoints: this.programPoints,
      points: this.points,
    });
  }
}
