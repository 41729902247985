import CorrectName from '@/entities/common/CorrectName';
import CustomRoute from '@/entities/common/CustomRoute';

export default [
  {
    path: '/gift-store',
    name: CustomRoute.TO_GIFT_STORE,
    meta: {
      title: 'giftstore_title_text',
      moduleCode: CorrectName.GIFT_STORE,
      eventOnEnter: 'shop_open_btn',
    },
    component: () => import(/* webpackChunkName: "gift-store" */ '@/views/gift-store/index.vue'),
  },
  {
    path: '/gift-store/cart',
    name: CustomRoute.TO_GIFT_STORE_CART,
    meta: {
      title: 'giftstore_title_text',
      moduleCode: CorrectName.GIFT_STORE,
      eventOnEnter: 'shop_cart_btn',
    },
    component: () => import(/* webpackChunkName: "gift-store" */ '@/views/gift-store/cart.vue'),
  },
  {
    path: '/gift-store/history',
    alias: '/gift-store/history/:orderId-link',
    name: CustomRoute.TO_GIFT_STORE_HISTORY,
    meta: { title: 'giftstore_title_text', moduleCode: CorrectName.GIFT_STORE },
    component: () => import(/* webpackChunkName: "gift-store" */ '@/views/gift-store/history.vue'),
  },
];
