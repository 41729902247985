import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import Dashboard from '@/entities/modules/dashboard/Dashboard';

export default {
  async getDashboard(): Promise<Dashboard> {
    const response = await axios.get('/dashboard');

    return new Dashboard(response.data.success);
  },
};
