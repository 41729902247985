import CorrectName from '@/entities/common/CorrectName';
import CustomRoute from '@/entities/common/CustomRoute';

export default [
  {
    path: '/my-team/users',
    name: CustomRoute.TO_MY_TEAM,
    meta: {
      title: 'myteam_section_name_label',
      moduleCode: CorrectName.TEAM,
      eventOnEnter: 'myteam_users_tab_btn',
      from: { 'dashboard-widget': 'dashboard_myteam_name_btn' },
    },
    component: () => import(/* webpackChunkName: "my-team" */ '@/views/my-team/index.vue'),
  },
  {
    path: '/my-team/employee/:managerId-:id-:slug',
    name: CustomRoute.TO_MY_TEAM_EMPLOYEE_MATERIAL,
    meta: {
      title: 'myteam_section_name_label',
      moduleCode: CorrectName.TEAM,
      from: { 'myteam-report': 'myteam_report_user_btn' },
    },
    component: () => import(/* webpackChunkName: "my-team" */ '@/views/my-team/employee.vue'),
  },
  {
    path: '/my-team/employee/:managerId-:id-:slug/award',
    name: CustomRoute.TO_MY_TEAM_EMPLOYEE_AWARD,
    meta: { title: 'myteam_section_name_label', moduleCode: CorrectName.TEAM },
    component: () =>
      import(/* webpackChunkName: "my-team" */ '@/views/my-team/employee-awards.vue'),
  },
  {
    path: '/my-team/employee/:managerId-:id-:slug/warn',
    name: CustomRoute.TO_MY_TEAM_EMPLOYEE_WARN,
    meta: { title: 'myteam_section_name_label', moduleCode: CorrectName.TEAM },
    component: () =>
      import(/* webpackChunkName: "my-team" */ '@/views/my-team/employee-awards.vue'),
  },
  {
    path: '/my-team/employee/:managerId-:id-:slug/badges',
    name: CustomRoute.TO_MY_TEAM_EMPLOYEE_BADGES,
    meta: {
      title: 'myteam_section_name_label',
      moduleCode: CorrectName.TEAM,
    },
    component: () =>
      import(/* webpackChunkName: "my-team" */ '@/views/my-team/employee-badges.vue'),
  },
  {
    path: '/my-team/employee/:managerId-:id-:slug/info',
    name: CustomRoute.TO_MY_TEAM_EMPLOYEE_INFO,
    meta: {
      title: 'myteam_section_name_label',
      moduleCode: CorrectName.TEAM,
    },
    component: () => import(/* webpackChunkName: "my-team" */ '@/views/my-team/employee-info.vue'),
  },
  {
    path: '/my-team/employee/:managerId-:id-:slug/results',
    name: CustomRoute.TO_MY_TEAM_EMPLOYEE_RESULTS,
    meta: { title: 'myteam_section_name_label', moduleCode: CorrectName.TEAM },
    component: () =>
      import(/* webpackChunkName: "my-team" */ '@/views/my-team/details/employee-result.vue'),
  },
  {
    path: '/my-team/employee/:managerId-:id-:slug/results/:type/:typeId-:typeSlug',
    name: CustomRoute.TO_MY_TEAM_EMPLOYEE_RESULTS_BY_CODE_DETAILS,
    meta: {
      title: 'myteam_section_name_label',
      moduleCode: CorrectName.TEAM,
      eventOnEnter: 'myteam_test_details_btn',
    },
    component: () =>
      import(
        /* webpackChunkName: "my-team" */ '@/views/my-team/details/employee-result-details.vue'
      ),
  },
  {
    path: '/my-team/employee/:managerId-:id-:slug/results/interviews/details/:typeId-:typeSlug',
    name: CustomRoute.TO_MY_TEAM_EMPLOYEE_RESULTS_FOR_INTERVIEW,
    meta: {
      title: 'myteam_section_name_label',
      moduleCode: CorrectName.TEAM,
    },
    component: () =>
      import(/* webpackChunkName: "my-team" */ '@/views/my-team/details/employee-interview.vue'),
  },
  {
    path: '/my-team/employee/:managerId-:id-:slug/results/interviews/details/:typeId-:typeSlug/details',
    name: CustomRoute.TO_MY_TEAM_EMPLOYEE_RESULTS_FOR_INTERVIEW_DETAILS,
    meta: {
      title: 'myteam_section_name_label',
      moduleCode: CorrectName.TEAM,
      enableSidebar: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "my-team" */ '@/views/my-team/details/employee-interview-details.vue'
      ),
  },
  {
    path: '/my-team/employee/:managerId-:id-:slug/learning-programs/:typeId-:typeSlug',
    name: CustomRoute.TO_MY_TEAM_EMPLOYEE_RESULTS_FOR_LEARNING_PROGRAMS_DETAILS,
    meta: {
      title: 'myteam_section_name_label',
      moduleCode: CorrectName.TEAM,
      eventOnEnter: 'myteam_lj_details_cell',
    },
    component: () =>
      import(
        /* webpackChunkName: "my-team" */ '@/views/my-team/details/employee-learning-programs-details.vue'
      ),
  },
  {
    path: '/my-team/employee/:managerId-:id-:slug/learning-programs/:typeId-:typeSlug/:materialId-test-details',
    name: CustomRoute.TO_MY_TEAM_EMPLOYEE_RESULTS_FOR_LEARNING_PROGRAMS_DETAILS_TEST,
    meta: {
      title: 'myteam_section_name_label',
      moduleCode: CorrectName.TEAM,
      eventOnEnter: 'myteam_test_details_btn',
    },
    component: () =>
      import(
        /* webpackChunkName: "my-team" */ '@/views/my-team/details/employee-learning-programs-details-test.vue'
      ),
  },
  {
    path: '/my-team/employee/:managerId-:id-:slug/learning-track/:typeId',
    name: CustomRoute.TO_MY_TEAM_EMPLOYEE_RESULTS_FOR_LEARNING_TRACK_DETAILS,
    meta: {
      title: 'myteam_section_name_label',
      moduleCode: CorrectName.TEAM,
      eventOnEnter: 'myteam_track_details_cell',
    },
    component: () =>
      import(
        /* webpackChunkName: "my-team" */ '@/views/my-team/details/employee-learning-track-details.vue'
      ),
  },
  {
    path: '/my-team/employee/:managerId-:id-:slug/learning-track/learning-program/:trackId/:typeId',
    name: CustomRoute.TO_MY_TEAM_EMPLOYEE_RESULTS_FOR_LEARNING_TRACK_PROGRAM_DETAILS,
    meta: {
      title: 'myteam_section_name_label',
      moduleCode: CorrectName.TEAM,
      eventOnEnter: 'myteam_lj_details_cell',
    },
    component: () =>
      import(
        /* webpackChunkName: "my-team" */ '@/views/my-team/details/employee-learning-programs-details.vue'
      ),
  },
  {
    path: '/my-team/employee/:managerId-:id-:slug/learning-track/learning-program/:trackId/:typeId/:materialId-test-details',
    name: CustomRoute.TO_MY_TEAM_EMPLOYEE_RESULTS_FOR_LEARNING_TRACK_PROGRAM_DETAILS_TEST,
    meta: {
      title: 'myteam_section_name_label',
      moduleCode: CorrectName.TEAM,
      eventOnEnter: 'myteam_test_details_btn',
    },
    component: () =>
      import(
        /* webpackChunkName: "my-team" */ '@/views/my-team/details/employee-learning-programs-details-test.vue'
      ),
  },
  {
    path: '/my-team/check-tasks/:managerId?',
    name: CustomRoute.TO_MY_TEAM_CHECK_TASKS,
    meta: {
      title: 'myteam_section_name_label',
      moduleCode: CorrectName.TEAM,
    },
    component: () => import(/* webpackChunkName: "my-team" */ '@/views/my-team/check-tasks.vue'),
  },
  {
    path: '/my-team/learning/:id?/:managerId?/module/:moduleId/:moduleType',
    name: CustomRoute.TO_MY_TEAM_MATERIALS,
    meta: {
      title: 'myteam_section_name_label',
      moduleCode: CorrectName.TEAM,
      eventOnEnter: 'myteam_materials_tab_btn',
    },
    component: () =>
      import(/* webpackChunkName: "my-team" */ '@/views/my-team/learning/materials.vue'),
  },
  {
    path: '/my-team/learning/:id?/:managerId?',
    name: CustomRoute.TO_MY_TEAM_LEARNING,
    meta: {
      title: 'myteam_section_name_label',
      moduleCode: CorrectName.TEAM,
    },
    component: () =>
      import(/* webpackChunkName: "my-team" */ '@/views/my-team/learning/learning.vue'),
  },
  {
    path: '/my-team/learning/:id?/:managerId?/module/:moduleId/:moduleType/:materialId',
    name: CustomRoute.TO_MY_TEAM_LEARNING_MATERIAL_STATUSES,
    meta: {
      title: 'myteam_section_name_label',
      moduleCode: CorrectName.TEAM,
    },
    component: () =>
      import(/* webpackChunkName: "my-team" */ '@/views/my-team/learning/material-statuses.vue'),
  },
  {
    path: '/my-team/learning/:id?/:managerId?/module/:moduleId/:moduleType/:materialId/:status',
    name: CustomRoute.TO_MY_TEAM_LEARNING_MATERIAL_STATUS,
    meta: {
      title: 'myteam_section_name_label',
      moduleCode: CorrectName.TEAM,
    },
    component: () =>
      import(/* webpackChunkName: "my-team" */ '@/views/my-team/learning/material-status.vue'),
    props: (route: any) => ({ ...route.params }),
  },
  {
    path: '/my-team/:section/:id?/:managerId?/module/:moduleId/:moduleType/:materialId/:status/:userId/:attemptId',
    name: CustomRoute.TO_MY_TEAM_LEARNING_TASK_USER_RESULT,
    meta: {
      title: 'myteam_section_name_label',
      moduleCode: CorrectName.TEAM,
    },
    component: () =>
      import(/* webpackChunkName: "my-team" */ '@/views/my-team/learning/task-user-result.vue'),
  },
  {
    path: '/my-team/materials/:moduleId?/:moduleType/:id?/:managerId-:materialId-:slug',
    name: CustomRoute.TO_MY_TEAM_MATERIALS_MATERIAL_CARD,
    meta: {
      title: 'myteam_section_name_label',
      moduleCode: CorrectName.TEAM,
      from: { 'myteam-report': 'myteam_report_material_btn' },
    },
    component: () => import(/* webpackChunkName: "my-team" */ '@/views/my-team/material-card.vue'),
  },
  {
    path: '/my-team/manager/:managerId-:id-:slug',
    name: CustomRoute.TO_MY_TEAM_MANAGER_MATERIAL,
    meta: {
      title: 'myteam_section_name_label',
      moduleCode: CorrectName.TEAM,
      eventOnEnter: 'myteam_subsidiary_manager_view_btn',
    },
    component: () => import(/* webpackChunkName: "my-team" */ '@/views/my-team/manager.vue'),
  },
  {
    path: '/my-team/manager/materials/:id',
    name: CustomRoute.TO_MY_TEAM_MANAGER_MATERIALS,
    meta: { title: 'myteam_section_name_label', moduleCode: CorrectName.TEAM },
    component: () =>
      import(/* webpackChunkName: "my-team" */ '@/views/my-team/learning/materials.vue'),
  },
  {
    path: '/my-team/reports/my-team-result/:managerId',
    name: CustomRoute.TO_MY_TEAM_REPORTS,
    meta: {
      title: 'myteam_section_name_label',
      moduleCode: CorrectName.TEAM,
      eventOnEnter: 'myteam_report_list_btn',
    },
    component: () =>
      import(/* webpackChunkName: "my-team" */ '@/views/my-team/reports/my-team-result.vue'),
  },
  {
    path: '/my-team/reports/users-activity/:managerId',
    name: CustomRoute.TO_MY_TEAM_REPORTS_USERS_ACTIVITY,
    meta: {
      title: 'myteam_section_name_label',
      moduleCode: CorrectName.TEAM,
      eventOnEnter: 'myteam_report_activity_btn',
    },
    component: () =>
      import(/* webpackChunkName: "my-team" */ '@/views/my-team/reports/users-activity.vue'),
  },
  {
    path: '/my-team/reports/materials-passing/:managerId',
    name: CustomRoute.TO_MY_TEAM_REPORTS_MATERIALS_PASSING,
    meta: {
      title: 'myteam_section_name_label',
      moduleCode: CorrectName.TEAM,
      eventOnEnter: 'myteam_report_progress_btn',
    },
    component: () =>
      import(/* webpackChunkName: "my-team" */ '@/views/my-team/reports/materials-passing.vue'),
  },
  {
    path: '/my-team/reports/tests-passing/:managerId',
    name: CustomRoute.TO_MY_TEAM_REPORTS_TESTS_PASSING,
    meta: {
      title: 'myteam_section_name_label',
      moduleCode: CorrectName.TEAM,
      eventOnEnter: 'myteam_report_test_avg_score_btn',
    },
    component: () =>
      import(/* webpackChunkName: "my-team" */ '@/views/my-team/reports/tests-passing.vue'),
  },
  {
    path: '/my-team/reports/tests-scores/:managerId',
    name: CustomRoute.TO_MY_TEAM_REPORTS_TESTS_SCORES,
    meta: {
      title: 'myteam_section_name_label',
      moduleCode: CorrectName.TEAM,
      eventOnEnter: 'myteam_report_test_total_result_btn',
    },
    component: () =>
      import(/* webpackChunkName: "my-team" */ '@/views/my-team/reports/tests-scores.vue'),
  },
];
