import NodeItem, { NodeItemScheme } from '@/entities/modules/learning-track/NodeItem';
import { LearningItemScheme } from '@/entities/modules/learning-items/LearningItem';
import LearningItemStatistic, {
  LearningItemStatisticScheme,
} from '@/entities/modules/learning-items/LearningItemStatistic';

export function createNodesList(
  nodesList: Array<NodeItemScheme>,
  items: Array<LearningItemScheme>,
  statistics: Array<LearningItemStatisticScheme>,
): Array<NodeItem> {
  const nodes: NodeItem[] = [];

  nodesList.forEach((nodeItem: NodeItemScheme) => {
    const node = new NodeItem(nodeItem);

    const learningItem = items.find(
      (item: LearningItemScheme) => item.entity_id === nodeItem.entity_id,
    );

    if (learningItem) {
      const {
        deadline_for_passing_at: passingDeadline,
        deadline_for_passing_notice_for_time: passingNoticeDeadline,
        max_points: maxPoints,
      } = learningItem;

      if (passingDeadline || passingNoticeDeadline) {
        node.setDeadline(passingDeadline, passingNoticeDeadline);
      }

      if (maxPoints) {
        node.setMaxPoints(maxPoints);
      }
    }

    const nodeStatistic = statistics.find(
      statistic => statistic.learning_item_entity_id === nodeItem.entity_id,
    );

    if (nodeStatistic) {
      node.setStatistic(new LearningItemStatistic(nodeStatistic));
    }

    nodes.push(node);
  });

  return nodes;
}
