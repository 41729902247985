// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store//types';
import { Module } from 'vuex';
import { MyTeamState } from '@/store/modules/my-team/types';
// eslint-disable-next-line import/no-cycle
import { getters } from './getters';
// eslint-disable-next-line import/no-cycle
import { actions } from './actions';
import { mutations } from './mutations';

export const state: MyTeamState = {
  employeeTeamFilters: [],

  countEmployeeForFilter: 0,
  countAppliedSubfilters: 0,

  userFilters: [],

  modules: [],
};

const namespaced: boolean = true;

export const myTeam: Module<MyTeamState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
