<template>
  <base-home-widget
    :widget-name="widget.name"
    :to="routeToTests"
    :count-text="countText"
  >
    <template #content>
      <app-slider
        :columns="ww4Columns"
        :column-size="ww4ColumnsForIsX"
      >
        <AppMaterialCard
          v-for="(entity, key) in widget.tests"
          :key="key"
          :event-label="'dashboard_tests_view_btn'"
          :widget="entity"
        />
      </app-slider>
    </template>
  </base-home-widget>
</template>

<script>
import { mapGetters } from 'vuex';
import AppSlider from '@/components/helpers/slider';
import AppMaterialCard from '@/components/helpers/cards/material-card';
import TestsWidget from '@/entities/modules/dashboard/TestsWidget';
import BaseHomeWidget from '@/components/app/home/base-home-widget';
import CustomRoute from '@/entities/common/CustomRoute';

export default {
  name: 'tests',

  components: {
    AppSlider,
    AppMaterialCard,
    BaseHomeWidget,
  },

  props: {
    widget: {
      type: TestsWidget,
      required: true,
    },
  },

  computed: {
    ...mapGetters('helpers', ['ww4Columns', 'ww4ColumnsForIsX']),

    countText() {
      return this.$tc('common_total_number_of_tests_text', this.widget.tests.length, {
        0: this.widget.tests.length,
      });
    },

    routeToTests() {
      return {
        name: CustomRoute.TO_TESTS,
        query: {
          from: 'dashboard-widget',
        },
      };
    },
  },
};
</script>
