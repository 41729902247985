import createDate from '@/providers/dateProvider';

export default class RatingKpi {
  id: number;
  name: string;
  isAvailable: boolean;
  ratingPoints: number;
  date?: Date;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.name = payload.name;
    this.isAvailable = parseInt(payload.is_available, 10) === 1;
    this.ratingPoints = parseInt(payload.rating_points, 10);
    this.date = createDate(payload.date);
  }
}
