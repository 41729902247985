import IStatistic from '@/entities/modules/learning-programs/IStatistic';
import SectionMaterial from '@/entities/modules/learning-programs/SectionMaterial';
import ToggleSectionMaterial from '@/entities/modules/learning-programs/ToggleSectionMaterial';
import MaterialStatus from '@/entities/common/testing/MaterialStatus';
import ImageSource, { createImageSmSource } from '@/entities/common/sources/ImageSource';
import BaseMaterial from '@/entities/modules/learning-programs/BaseMaterial';
import CustomRoute from '@/entities/common/CustomRoute';
import MaterialPdfStatistic from '@/entities/modules/learning-programs/MaterialPdfStatistic';

export default class MaterialPdf extends BaseMaterial {
  id: number;
  name: string;
  isRating: boolean;
  url: string;
  maxScore: number;
  updatedAtTimestamp: number;
  allowPdfSharing: boolean | null;
  image?: ImageSource;

  statistic: MaterialPdfStatistic;

  constructor(statistic: IStatistic, payload: any) {
    super();

    this.statistic = statistic as MaterialPdfStatistic;

    this.id = parseInt(payload.id, 10);
    this.maxScore = parseInt(payload.max_points, 10);
    this.name = payload.name;
    this.isRating = parseInt(payload.is_rating, 10) === 1;
    this.url = payload.pdf.url;
    this.updatedAtTimestamp = payload.updated_at;
    this.image = createImageSmSource(payload.image);
    this.allowPdfSharing = payload.custom_settings
      ? parseInt(payload.custom_settings.allow_pdf_sharing, 10) === 1
      : null;
  }

  getMaterialType(): string {
    return 'pdf';
  }

  /**
   * Виджет материала для страницы просмотра программы обучения (выводится в секциях)
   * @param sectionMaterial
   */
  getToggleWidget(sectionMaterial: SectionMaterial): ToggleSectionMaterial {
    const status = this.statistic.getMaterialStatus();

    let widgetParts: unknown[] = [];

    // отображаем баллы если PDF рейтинговый и есть баллы
    widgetParts = this.addPointsInfo(
      widgetParts,
      this.isRating,
      this.maxScore,
      this.statistic.points,
    );

    return new ToggleSectionMaterial({
      id: this.id,
      type: this.getMaterialType(),
      routerTo: CustomRoute.TO_LEARNING_PROGRAMS_PDF,
      routerTrackTo: CustomRoute.TO_LEARNING_TRACK_PROGRAMS_PDF,
      name: this.name,
      image: this.image,

      lokaliseTagLabel: 'common_pdf_text',
      buttonLabel: null,
      lokaliseButtonLabel:
        status === MaterialStatus.ASSIGNED
          ? 'learn_course_read_button'
          : 'learn_course_read_again_button',
      isButtonOutlined: status !== MaterialStatus.ASSIGNED,

      numberingTypeThrough: this.numberingTypeThrough,
      numberingTypeSections: this.numberingTypeSections,
      numberingTypeHierarchical: this.numberingTypeHierarchical,

      isRating: this.isRating,
      isRequired: sectionMaterial.isRequired,
      isLocked: sectionMaterial.isLocked,

      isPassed: status === MaterialStatus.PASSED,
      isFailed: status === MaterialStatus.FAILED,

      parts: widgetParts,
    });
  }

  getSaving(): any {
    return {
      id: this.id,
      type: this.getMaterialType(),
      viewed_time: Math.round((new Date() as any) / 1000),
    };
  }
}
