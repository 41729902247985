<template>
  <div
    :class="{ 'app-favorite--active': favoriteStatus }"
    class="app-favorite"
    @click="setFavoriteMaterial"
  >
    <BookmarkSvg
      :is-info-color="favoriteStatus"
      :is-surface-emphasis20="!favoriteStatus"
    />
  </div>
</template>

<script>
import BookmarkSvg from '@/components/svg/icons/bookmark.vue';
import ApiName, { getApiCodeByCorrectName } from '@/entities/common/CorrectName';
import { sendEvent } from '@/providers/analyticsProvider';
import { routeTo } from '@/providers/routerLinkProvider';
import CustomRoute from '@/entities/common/CustomRoute';
import { isIFavorite } from '@/entities/common/interfaces/helpers/IFavorite';

export default {
  name: 'app-favorite',

  components: {
    BookmarkSvg,
  },

  props: {
    materialType: {
      type: String,
      default: '',
    },

    isFavorite: {
      type: Boolean,
      default: false,
    },

    materialId: {
      type: Number,
      default: null,
    },

    /** @type {IFavorite|IMaterial} */
    material: {
      type: Object,
      default: () => {},
    },

    context: {
      type: Object,
      default: null,
    },
  },

  computed: {
    favoriteStatus() {
      return this.material && isIFavorite(this.material)
        ? this.material.isFavorite
        : this.isFavorite;
    },

    computedMaterialType() {
      return this.material && isIFavorite(this.material)
        ? getApiCodeByCorrectName(this.material.moduleCode)
        : this.materialType;
    },

    getId() {
      return this.material && isIFavorite(this.material) ? this.material.id : this.materialId;
    },
  },

  methods: {
    setFavoriteMaterial() {
      this.$store.dispatch('favorites/setFavoriteStatus', {
        type: this.computedMaterialType,
        id: this.getId,
        status: this.favoriteStatus,
        context: this.context,
      });

      this.sendAnalytics();

      let isFavorite = false;

      if (this.material && isIFavorite(this.material)) {
        this.material.setFavorite(!this.favoriteStatus);
        isFavorite = this.favoriteStatus;
      } else {
        this.$emit('changeFavoriteStatus', !this.favoriteStatus, this.getId);
        isFavorite = !this.isFavorite;
      }

      if (!isFavorite) {
        this.$store.dispatch('helpers/showNotification', {
          message: this.$t('common_removed_from_favorites_alert'),
        });
      } else {
        this.$store.dispatch('helpers/showNotification', {
          message: this.$t('common_added_to_favorites_alert'),
          hasNotOverlay: true,
          hasCloseIcon: true,
          buttons: [
            {
              label: this.$t('common_go_to_link_button'),
              callback: () => {
                this.$store.dispatch('helpers/removeNotifications');
                this.$router.push(routeTo(CustomRoute.TO_DASHBOARD_PROFILE_FAVORITES));
              },
            },
          ],
        });
      }
    },

    sendAnalytics() {
      if (this.favoriteStatus) {
        sendEvent(this.$analytics, 'favorite_delete_btn');
      } else {
        switch (this.computedMaterialType) {
          case ApiName.LEARNING_PROGRAM:
            sendEvent(this.$analytics, 'lj_add_to_favorite_btn');
            break;
          case ApiName.TEST:
            sendEvent(this.$analytics, 'evaluation_test_add_to_favorite_btn');
            break;
          case ApiName.INTERVIEW:
            sendEvent(this.$analytics, 'evaluation_surveys_add_to_favorite_btn');
            break;
          case ApiName.COMPETENCY:
            sendEvent(this.$analytics, 'competency_test_add_to_favorite_btn');
            break;
          case ApiName.GIFT_STORE:
            sendEvent(this.$analytics, 'gift_add_to_favorite_btn');
            break;
          case ApiName.EVENT:
            sendEvent(this.$analytics, 'events_add_to_favorite_btn');
            break;
          case ApiName.VIDEO:
            sendEvent(this.$analytics, 'discover_add_to_favorite_btn');
            break;
          case ApiName.INFORMATION:
            sendEvent(this.$analytics, 'info_add_to_favorite_btn');
            break;
          case ApiName.INFORMATION_CATEGORY:
            sendEvent(this.$analytics, 'info_add_to_favorite_btn');
            break;
          case ApiName.TASK:
            sendEvent(this.$analytics, 'tasks_add_to_favorite_btn');
            break;
          case ApiName.TEAM:
            sendEvent(this.$analytics, 'myteam_employee_add_to_favorite_btn');
            break;
          default:
            break;
        }
      }
    },
  },
};
</script>
