import { ActionTree } from 'vuex';
import testsApi from '@/services/tests';
import { each, find } from 'lodash';
import CorrectName from '@/entities/common/CorrectName';
import { TestsState } from './types';
import { RootState } from '../../types';

// переписать с учетом обработки levelList
export const actions: ActionTree<TestsState, RootState> = {
  async syncMaterials({ rootState, commit }) {
    const [levels, tests] = await testsApi.getMaterials(
      await testsApi.getStatistic([]),
      find(rootState.settings.modules, o => o.correctName === CorrectName.EVALUATIONS).settings,
    );

    commit('setLevels', levels);
    commit('setTests', tests);
  },

  async getById({ state, dispatch }, id) {
    const material = find(state.materials, o => o.id === id);

    if (material) {
      return material;
    }

    await dispatch('syncMaterials');

    return find(state.materials, o => o.id === id);
  },

  async getNextMaterialFor({ state }, id) {
    let index = -1;
    each(state.materials, (test, position) => {
      if (test.id === id && position + 1 < state.materials.length) {
        index = position + 1;
      }
    });

    return index === -1 ? null : state.materials[index];
  },

  async getUuid({}, id) {
    return testsApi.getUuid(id);
  },

  async save({ state }, { id, payload }) {
    const material = find(state.materials, o => o.id === id);

    if (!material) {
      return;
    }

    const requestPayload = material.getSaving(payload);

    const responsePayload = await testsApi.sendStatistic(requestPayload);

    const statistic = await testsApi.getStatistic([id]);
    material.setStatistic(statistic[0]);

    return { requestPayload, responsePayload };
  },

  async clearIsNew({}, id) {
    await testsApi.clearIsNew(id);
  },

  async clearIsChecked({}, id) {
    await testsApi.clearIsChecked(id);
  },
};
