import IStatistic from '@/entities/modules/learning-programs/IStatistic';
import MaterialStatus from '@/entities/common/testing/MaterialStatus';
// @ts-ignore
import dateProvider from '@/providers/js/dateProvider';

export default class MaterialPdfStatistic implements IStatistic {
  materialId: number;
  viewedTime?: Date;
  updatedAt?: Date;
  updatedAtTimestamp: number;
  points: number;

  constructor(payload: any) {
    this.materialId = parseInt(payload.id, 10);
    this.viewedTime = dateProvider(payload.viewed_time);
    this.updatedAt = dateProvider(payload.updated_at);
    this.updatedAtTimestamp = payload.updated_at;
    this.points = parseInt(payload.points, 10);
  }

  /**
   * Вернет статус материала
   */
  getMaterialStatus(): MaterialStatus {
    if (this.viewedTime) {
      return MaterialStatus.PASSED;
    }

    return MaterialStatus.ASSIGNED;
  }

  getPoints(): number {
    return this.points;
  }

  getPercent(): number {
    return 0;
  }
}
