<template>
  <div class="app-sl-footer">
    <div class="app-sl-wrap">
      <div class="app-sl__line app-sl__line--small app-sl__line--50 app-sl-w1"></div>
    </div>
    <div class="app-sl-wrap">
      <div class="app-sl__content">
        <div class="app-sl__line app-sl__line--small app-sl__line--15 app-sl-w1"></div>
        <div class="app-sl__line app-sl__line--small app-sl__line--5 app-sl-w1"></div>
        <div class="app-sl__line app-sl__line--small app-sl__line--5 app-sl-w1"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'material-card-info',
};
</script>
