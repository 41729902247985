// @ts-ignore
import { createMaterialCardWidget } from '@/entities/common/MaterialCardWidget';
import Task from '@/entities/modules/tasks/Task';
import MaterialStatus from '@/entities/common/testing/MaterialStatus';
import CustomRoute from '@/entities/common/CustomRoute';
import AnswerStatus from '@/entities/common/tasks/AnswerStatus';

export default class AnswerResult {
  id: number;
  status: string;
  task: Task;
  points: number;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.status = payload.status;
    this.task = new Task(payload.task);
    this.points = parseInt(payload.points, 10);
  }
  getStatus() {
    switch (this.status) {
      case AnswerStatus.COMPLETED:
        return MaterialStatus.PASSED;
      default:
        return MaterialStatus.FAILED;
    }
  }
  getWidget() {
    const isPassed = this.status === 'completed';

    const isFailed = this.status === 'failed';

    const isChecking = this.status === 'checking';

    const isAssigned = this.status === 'assigned';

    const parts = [];

    if (this.getStatus() === MaterialStatus.PASSED) {
      parts.push([
        'common_d_from_d_points_text',
        this.points,
        { 0: this.points, 1: this.task.maxPoints },
      ]);
    }

    if (this.getStatus() === MaterialStatus.FAILED) {
      parts.push(['common_d_from_d_points_text', this.points, { 0: 0, 1: this.task.maxPoints }]);
    }

    return createMaterialCardWidget({
      name: this.task.name,
      to: {
        name: CustomRoute.TO_TASKS_MATERIAL,
        params: {
          id: this.task.id,
        },
        query: { from: 'task-list' },
      },
      type: 'tasks',
      isRating: true,
      parts,
      isPassed,
      isChecking,
      isFailed,
      isAssigned,
    });
  }
}
