export default function ApiException(responseData) {
  const response = responseData || {};

  const errors = {
    // если не найден ключ, берем этот
    DEFAULT_KEY: 'common_internal_server_error_alert_text',

    // ошибки запроса
    // сервер на техническом обслуживании
    MAINTENANCE: 1000,
    MAINTENANCE_KEY: 'common_maintenance_1000_error',

    // неверный формат
    REQUEST_FORMAT: 1001,

    // некорректные данные
    REQUEST_INCORRECT_DATA: 1002,

    // ошибка сервера
    REQUEST_SERVER_ERROR: 1003,

    // передан пустой запрос
    REQUEST_EMPTY_QUERY_GIVEN: 1004,

    // закрыт доступ к модулю
    REQUEST_MODULE_UNAVAILABLE: 1006,
    REQUEST_MODULE_UNAVAILABLE_KEY: 'common_module_unavailable_1006_error',

    // данные не прошли валидацию
    REQUEST_VALIDATION_ERROR: 1007,
    REQUEST_VALIDATION_ERROR_KEY: 'common_input_data_validation_1007_error',

    // глоссарий
    // глоссарий не найден
    GLOSSARY_NOT_FOUND_FILE: 2001,

    // компания недоступна
    COMPANY_UNAVAILABLE: 2002,
    COMPANY_UNAVAILABLE_KEY: 'common_company_unavailable_2002_error',

    // Пользователь не является менеджером или нет email
    USER_IS_NOT_A_MANAGER: 2003,
    USER_IS_NOT_A_MANAGER_KEY: 'common_manager_access_denied_2003_error',

    // Неверный пароль
    USER_INVALID_PASSWORD: 2004,
    USER_INVALID_PASSWORD_KEY: 'common_password_incorrect_format_2004_error',

    // Пароль не может совпадать с текущим
    USER_PASSWORD_IS_NOT_NEW: 2005,
    USER_PASSWORD_IS_NOT_NEW_KEY: 'common_pass_same_previous_2005_error',

    // Пароль не может совпадать с логином, почтовым email
    USER_PASSWORD_MATCHES_LOGIN_OR_EMAIL: 2006,
    USER_PASSWORD_MATCHES_LOGIN_OR_EMAIL_KEY: 'common_pass_as_login_2006_error',

    // Данный логин уже существует
    USER_LOGIN_ALREADY_EXIST: 2007,
    USER_LOGIN_ALREADY_EXIST_KEY: 'auth_login_in_use_error',

    // Превышено количество пользователей в компании
    COMPANY_USER_LIMIT: 2008,
    COMPANY_USER_LIMIT_KEY: 'common_company_user_limit_2008_error_text',

    // Неверный пароль (используется при проверке текущего пароля пользователя)
    USER_WRONG_PASSWORD: 2015,
    USER_WRONG_PASSWORD_KEY: 'common_incorrect_password_error',

    // Запрашиваемый комментарий не найден
    COMMENT_NOT_FOUND: 2018,
    COMMENT_NOT_FOUND_KEY: 'common_comment_not_found_error',

    // авторизация
    // Invalid credential
    AUTH_INVALID_CREDENTIAL: 3000,

    // лицензия не подтверждена
    AUTH_LICENSE_DISAGREE: 3001,

    // пользователь не найден
    AUTH_USER_NOT_FOUND: 3002,
    AUTH_USER_NOT_FOUND_KEY: 'common_user_not_found_3002_error',

    // токен просрочен
    AUTH_TOKEN_EXPIRED: 3003,

    // неверный логин или пароль
    AUTH_WRONG_LOGIN_OR_PASSWORD: 3004,
    AUTH_WRONG_LOGIN_OR_PASSWORD_KEY: 'auth_logopass_incorrect_3004_error',

    // невалидный запрос
    AUTH_INVALID_REQUEST: 3006,

    // неверный токен
    AUTH_TOKEN_INVALID: 3009,
    AUTH_TOKEN_INVALID_KEY: 'common_auth_error',

    // пользователь не верифицирован
    AUTH_NOT_VERIFIED: 3010,
    AUTH_NOT_VERIFIED_KEY: 'common_auth_error',

    // неверный id компании
    AUTH_WRONG_COMPANY_ID: 3012,
    AUTH_WRONG_COMPANY_ID_KEY: 'auth_company_id_incorrect_3012_error',

    // Логин используется в нескольких компаниях
    AUTH_SEVERAL_COMPANIES: 3016,
    AUTH_SEVERAL_COMPANIES_KEY: 'auth_several_companies_have_such_login_3016_error',

    // Пользователь заблокирован
    AUTH_USER_BLOCKED: 3013,
    AUTH_USER_BLOCKED_KEY: 'common_your_account_is_blocked_error_text',

    // Неверный тип авторизации для компании
    AUTH_WRONG_COMPANY_AUTH_TYPE: 3014,
    AUTH_WRONG_COMPANY_AUTH_TYPE_KEY: 'auth_incorrect_auth_type_error',

    // Превышено число запрос с данного ip адреса
    IP_LIMIT_ATTEMPTS: 3025,
    IP_LIMIT_ATTEMPTS_KEY: 'common_requests_exceeded_from_ip_error',

    // Истекло действие кода
    SMS_AUTH_CODE_EXPIRED: 3026,
    SMS_AUTH_CODE_EXPIRED_KEY: 'auth_verification_code_expired_4004_error',

    // Превышено количество попыток
    SMS_AUTH_EXCEEDED_ATTEMPTS_ERROR: 3027,
    SMS_AUTH_EXCEEDED_ATTEMPTS_ERROR_KEY: 'auth_recover_code_limit_5008_error',

    // Некорректный код подтверждения
    SMS_AUTH_WRONG_RECOVERY_CODE: 3028,
    SMS_AUTH_WRONG_RECOVERY_CODE_KEY: 'auth_recover_code_incorrect_5005_error',

    // Верификация
    // Некорректные данные (ошибка валидации)
    VERIFICATION_INCORRECT_DATA: 4001,

    // Несоответствие этапов верификации
    VERIFICATION_WRONG_STEP: 4002,

    // Пользовать уже верифицирован
    VERIFICATION_VERIFIED: 4003,
    VERIFICATION_VERIFIED_KEY: 'auth_user_is_verified_error',

    // Код верификации устарел
    VERIFICATION_CODE_EXPIRED: 4004,
    VERIFICATION_CODE_EXPIRED_KEY: 'auth_verification_code_expired_4004_error',

    // Неверный код
    VERIFICATION_INVALID_CODE: 4005,
    VERIFICATION_INVALID_CODE_KEY: 'auth_verification_code_incorrect_4005_error',

    // Ошибка повторной отправки
    VERIFICATION_RESEND: 4006,
    VERIFICATION_RESEND_KEY: 'auth_resend_code_incorrect_4006_error',

    // Ошибка не уникальности телефона
    VERIFICATION_PHONE_EXIST: 4007,
    VERIFICATION_PHONE_EXIST_KEY: 'auth_verification_phone_is_exist_4007_error',

    // Ошибка не уникальности email
    VERIFICATION_EMAIL_EXIST: 4008,
    VERIFICATION_EMAIL_EXIST_KEY: 'auth_verification_email_is_exist_4008_error',

    // Незаполнен обязательный код
    VERIFICATION_NEED_REQUIRE_CODE: 4009,
    VERIFICATION_NEED_REQUIRE_CODE_KEY: 'common_access_code_4009_error_text',

    // Неверный код доступа
    COMPANY_ACCESS_CODE_WRONG: 4010,
    COMPANY_ACCESS_CODE_WRONG_KEY: 'common_access_code_4010_error_text',

    // Превышено количество ввода кода доступа
    COMPANY_ACCESS_CODE_ATTEMPTS_COUNT: 4011,
    COMPANY_ACCESS_CODE_ATTEMPTS_COUNT_KEY: 'common_access_code_4011_error_text',

    // Ошибка получения кода подтверждения (косяк смс-провайдера, почтового провайдера) или неверный номер
    VERIFICATION_PHONE_ERROR: 4012,
    VERIFICATION_PHONE_ERROR_KEY: 'auth_verification_code_incorrect_4012_error',

    // Восстановление пароля
    // Невалидный телефон
    PASSWORD_RECOVERY_INVALID_PHONE: 5001,
    PASSWORD_RECOVERY_INVALID_PHONE_KEY: 'auth_phone_incorrect_5001_error',

    // Невалидный E-mail
    PASSWORD_RECOVERY_INVALID_EMAIL: 5002,
    PASSWORD_RECOVERY_INVALID_EMAIL_KEY: 'auth_email_incorrect_5002_error',

    // Не найден телефон
    PASSWORD_RECOVERY_PHONE_NOT_FOUND: 5003,
    PASSWORD_RECOVERY_PHONE_NOT_FOUND_KEY: 'auth_verification_phone_not_found_5003_error',

    // Не найден E-mail
    PASSWORD_RECOVERY_EMAIL_NOT_FOUND: 5004,
    PASSWORD_RECOVERY_EMAIL_NOT_FOUND_KEY: 'auth_verification_email_not_found_5004_error',

    // Некорректный код подтверждения
    PASSWORD_RECOVERY_WRONG_RECOVERY_CODE: 5005,
    PASSWORD_RECOVERY_WRONG_RECOVERY_CODE_KEY: 'auth_recover_code_incorrect_5005_error',

    // Пароли не совпадают
    PASSWORD_RECOVERY_PASSWORDS_DO_NOT_MATCHES: 5006,
    PASSWORD_RECOVERY_PASSWORDS_DO_NOT_MATCHES_KEY: 'auth_recover_passwords_no_match_5006_error',

    // Ошибка получения кода подтверждения (косяк смс-провайдера, почтового провайдера)
    PASSWORD_RECOVERY_SEND_CODE_ERROR: 5007,
    PASSWORD_RECOVERY_SEND_CODE_ERROR_KEY: 'auth_recover_code_incorrect_5007_error',

    // Превышено количество попыток ввода кода подтверждения. Необходимо сделать запрос на получение нового кода
    PASSWORD_RECOVERY_EXCEEDED_ATTEMPTS_ERROR: 5008,
    PASSWORD_RECOVERY_EXCEEDED_ATTEMPTS_ERROR_KEY: 'auth_recover_code_limit_5008_error',

    TRAINING_NOT_AVAILABLE: 5103,
    TRAINING_NOT_AVAILABLE_KEY: 'common_material_inaccessible_or_deleted_error',

    TRAINING_NOT_FOUND: 5106,
    TRAINING_NOT_FOUND_KEY: 'common_material_inaccessible_or_deleted_error',

    TRAINING_TEST_LIMIT_TURNS: 5108,
    TRAINING_TEST_LIMIT_TURNS_KEY: 'learn_course_no_more_attempts_error_text',

    INFO_MATERIAL_NOT_AVAILABLE: 5301,

    TEST_NOT_AVAILABLE: 5402,
    TEST_NOT_AVAILABLE_KEY: 'common_material_inaccessible_or_deleted_error',

    TEST_TURNS_LIMIT: 5403,
    TEST_TURNS_LIMIT_KEY: 'learn_course_no_more_attempts_error_text',

    INTERVIEW_NOT_AVAILABLE: 5406,
    INTERVIEW_NOT_AVAILABLE_KEY: 'common_material_inaccessible_or_deleted_error',

    // Подарок не доступен для пользователя или удален
    SHOP_PRODUCT_NOT_AVAILABLE: 7001,
    SHOP_PRODUCT_NOT_AVAILABLE_KEY: 'giftstore_not_available_text',

    // Ошибка возврата подарка в корзину(изменилось кол-во подарка в наличие)
    SHOP_PRODUCT_LIMIT: 7002, // 7001
    SHOP_PRODUCT_LIMIT_KEY: 'giftstore_not_enough_before_added_to_cart_text',

    // Недостаточно баллов
    SHOP_NOT_ENOUGH_POINTS: 7003,
    SHOP_NOT_ENOUGH_POINTS_KEY: 'giftstore_not_enough_points_text',

    // Заказ недоступен
    SHOP_ORDER_NOT_AVAILABLE: 7005,
    SHOP_ORDER_NOT_AVAILABLE_KEY: 'giftstore_order_not_available_text',

    // Пользователь не принадлежит менеджеру
    USER_NOT_BELONGS_TO_MANAGER: 8000,
    USER_NOT_BELONGS_TO_MANAGER_KEY: 'myteam_badges_user_doesnot_belong_to_manager_8000_error',

    // Бейдж недоступен для награды (can_give_manager = 0)
    BADGE_NOT_AVAILABLE_TO_AWARD: 8001,
    BADGE_NOT_AVAILABLE_TO_AWARD_KEY: 'myteam_badges_badge_is_not_available_for_award_8001_error',

    // На пользователя не расшарен бейдж для награды
    BADGET_NOT_AVAILABLE_TO_USER: 8002,
    BADGET_NOT_AVAILABLE_TO_USER_KEY:
      'myteam_badges_user_doesnot_have_access_to_this_badge_8002_error',

    // Лимит на выдачу награды
    BADGE_LIMIT: 8003,
    BADGE_LIMIT_KEY: 'common_badge_limit_exceeded_error_text',

    // Превышено количество участников
    EVENT_MAXIMUM_USERS: 9000,
    EVENT_MAXIMUM_USERS_KEY: 'events_participants_limit_exceeded_9000_error_text',

    // Пользователь уже участвует в событии
    EVENT_USER_ALREADY_IN_EVENT: 9001,
    EVENT_USER_ALREADY_IN_EVENT_KEY: 'events_user_already_in_event_9001_error_text',

    // регистрация в событии уже закончена
    EVENT_REGISTER_IS_ENDED: 9002,
    EVENT_REGISTER_IS_ENDED_KEY: 'events_registration_is_already_closed_9002_error_text',

    // Пользователь не участвует в событии
    EVENT_USER_NOT_IN_EVENT: 9003,
    EVENT_USER_NOT_IN_EVENT_KEY: 'events_user_doesnot_participate_9003_error_text',

    // Некорректный тип мероприятия
    EVENT_WRONG_EVENT_TYPE: 9004,
    EVENT_WRONG_EVENT_TYPE_KEY: 'events_wrong_type_9004_error',

    // К мероприятию не привязан митинг в Zoom
    EVENT_NO_RELATED_ZOOM_MEETING: 9005,
    EVENT_NO_RELATED_ZOOM_MEETING_KEY: 'events_no_related_zoom_meeting_9005_error',

    // Проблемы с сессией авторизации в Zoom
    EVENT_NO_RELATED_ZOOM_SESSION: 9006,
    EVENT_NO_RELATED_ZOOM_SESSION_KEY: 'events_no_related_zoom_session_9006_error',

    // Данные о Zoom-митинге временно недоступны
    EVENT_ZOOM_URL_IS_TEMPORARY_UNAVAILABLE: 9007,
    EVENT_ZOOM_URL_IS_TEMPORARY_UNAVAILABLE_KEY:
      'events_zoom_url_is_temporary_unavailable_9007_error_text',

    // Тест недоступен
    PROF_TEST_NOT_AVAILABLE: 10001,
    PROF_TEST_NOT_AVAILABLE_KEY: 'common_material_inaccessible_or_deleted_error',

    // Количество попыток превышено
    PROF_TEST_TURNS_LIMIT: 10002,
    PROF_TEST_TURNS_LIMIT_KEY: 'learn_course_no_more_attempts_error_text',

    // запрошен id несуществующего пользователя
    TEAM_USER_NOT_FOUND: 13001,
    TEAM_USER_NOT_FOUND_KEY: 'myteam_user_not_found_13001_error_text',

    // запрошен id пользователя не являющегося подчиненным текущего
    TEAM_USER_NOT_AVAILABLE: 13002,
    TEAM_USER_NOT_AVAILABLE_KEY: 'myteam_user_not_available_13002_error_text',

    // Запрошенный пользователь не является менеджером
    TEAM_USER_MANAGER_NOT_MANAGER: 13003,
    TEAM_USER_MANAGER_NOT_MANAGER_KEY: 'myteam_user_not_manager_13003_error_text',

    // Запрошенный менеджер не является подчиненным
    TEAM_MANAGER_IN_NOT_SUBORDINATE: 13004,
    TEAM_MANAGER_IN_NOT_SUBORDINATE_KEY: 'myteam_user_not_available_13002_error_text',

    // Отчет недоступен
    REPORT_UNAVAILABLE: 13005,
    REPORT_UNAVAILABLE_KEY: 'common_report_unavailable_13005_error_text',

    // Руководитель не может проверить задание
    MANAGER_CAN_NOT_CHECK_TASK: 13006,
    MANAGER_CAN_NOT_CHECK_TASK_TITLE_KEY: 'myteam_tasks_error_task_unavailable_title_text',
    MANAGER_CAN_NOT_CHECK_TASK_KEY: 'myteam_tasks_error_task_сhecked_unavailable_text',

    // Комментарий не найден
    DELETE_COMMENT_NOT_FOUND: 13008,
    DELETE_COMMENT_NOT_FOUND_TITLE_KEY: 'common_comment_not_found_error',

    // Запрошенный материал недоступен
    MATERIAL_NOT_AVAILABLE: 14001,
    MATERIAL_NOT_AVAILABLE_KEY: 'common_material_inaccessible_or_deleted_error',

    // Программа недоступна
    LEARNING_PROGRAM_IS_NOT_AVAILABLE: 18001,
    LEARNING_PROGRAM_IS_NOT_AVAILABLE_KEY: 'common_material_inaccessible_or_deleted_error',

    // Поле не заполнено
    FORMS_FIELD_EMPTY_VALUE: 60101,
    FORMS_FIELD_EMPTY_VALUE_KEY: 'common_form_field_is_empty_60101_error',

    // Значение не валидно (нету в списке возможных значений)
    FORMS_VALUE_IS_NOT_AVAILABLE: 60102,
    FORMS_VALUE_IS_NOT_AVAILABLE_KEY: 'common_form_value_not_available_60102_error',

    // Задание недоступно
    TASKS_TASK_IS_NOT_AVAILABLE: 19001,
    TASKS_TASK_IS_NOT_AVAILABLE_KEY: 'tasks_task_unavailable_refresh_page_19001_error',

    // Поле недоступно
    TASKS_FIELD_IS_NOT_AVAILABLE: 19002,
    TASKS_FIELD_IS_NOT_AVAILABLE_KEY: 'tasks_field_unavailable_refresh_page_19002_error',

    // Достигнут лимит ответов
    TASKS_ANSWERS_LIMIT_REACHED: 19009,
    TASKS_ANSWERS_LIMIT_REACHED_KEY: 'tasks_answers_limit_reached_refresh_page_19009_error',

    // У ответа изменился статус
    TASKS_ANSWERS_WRONG_STATUS: 19020,
    TASKS_ANSWERS_WRONG_STATUS_KEY: 'tasks_error_status_changed_text',

    // Сообщение недоступно
    MESSAGES_MESSAGE_IS_NOT_AVAILABLE: 20001,
    MESSAGES_MESSAGE_IS_NOT_AVAILABLE_KEY: 'messages_message_unavailable_error',

    // Комментирование сообщения недоступно
    MESSAGES_COMMENTING_IS_NOT_AVAILABLE: 20002,
    MESSAGES_COMMENTING_IS_NOT_AVAILABLE_KEY: 'messages_comments_unavailable_error',

    // Сущность недоступна
    ENTITY_NOT_AVAILABLE: 2016,
    ENTITY_NOT_AVAILABLE_KEY: 'common_material_inaccessible_or_deleted_error',

    // Комментирование недоступно
    ENTITY_COMMENTING_IS_NOT_AVAILABLE: 2017,
    ENTITY_COMMENTING_IS_NOT_AVAILABLE_KEY: 'messages_comments_unavailable_error',
  };

  /**
   * Вернет true, если в ответе сервера есть http ошибка,
   * например, 401
   * @returns {boolean}
   */
  const hasHttpError = () => response.status !== 200;

  /**
   * Вернет true, если в ответе api есть error блок
   * @returns {boolean}
   */
  const hasApiError = () => 'error' in response.data;

  /**
   * Код ошибки
   * @type {null}
   */
  const errorCode = hasApiError() ? response.data.error.code : null;

  /**
   * Вернет ключ по значению
   * @param object
   * @param value
   * @returns {string}
   */
  function getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
  }

  /**
   * Найдет ключ lokalise'а
   * @returns {*}
   */
  const getLokaliseKeyByCode = () => {
    let key = getKeyByValue(errors, errorCode);

    if (!key) {
      key = 'DEFAULT';
    }

    return errors[`${key}_KEY`];
  };

  /**
   * Найдет ключ lokalise'а для заголовка уведомления
   * @returns {*}
   */
  const getLokaliseTitleKeyByCode = () => {
    const key = getKeyByValue(errors, errorCode);

    return key ? errors[`${key}_TITLE_KEY`] : '';
  };

  /**
   * Вернет код ошибки
   * @returns {*}
   */
  this.getErrorCode = () => {
    if (hasApiError()) {
      return errorCode;
    }

    if (hasHttpError()) {
      return -1;
    }

    return -1;
  };

  this.getErrorLog = l => {
    const log = l || null;

    if (hasApiError()) {
      if (!log) {
        const errorArr = [];
        response.data.error.log.forEach(error => {
          let key = getKeyByValue(errors, error.code);

          if (!key) {
            key = 'DEFAULT';
          }
          const errorObj = {
            id: error.id,
            message: errors[`${key}_KEY`],
          };
          errorArr.push(errorObj);
        });

        return errorArr;
      }

      return response.data.error.log;
    }

    return null;
  };

  /**
   * Вернет ответ сервера
   * @returns {*}
   */
  this.getErrorResponse = () => response.data.error;

  /**
   * Вернет ключ lokalise'а для ошибки
   * @returns {*}
   */
  this.getErrorKey = () => getLokaliseKeyByCode();

  /**
   * Вернет ключ lokalise'а для заголовка ошибки
   * @returns {*}
   */
  this.getErrorTitleKey = () => getLokaliseTitleKeyByCode();

  /**
   * Вернет true, если запрос прошул со статусом 201, используется только
   * для запросов в elastic
   * @returns {boolean}
   */
  this.hasStatus201 = () => response.status === 201;
}
