<template>
  <section class="error-404">
    <div class="container">
      <div class="inner-container inner-container--left">
        <div class="inner-container--main">
          <div class="error-404__image-404">
            <p class="error-404__number">404</p>
            <EmptyPageSvg />
          </div>
        </div>
      </div>
      <div class="inner-container inner-container--right">
        <div class="error-404__text">
          <p class="error-404__title">
            {{ $t('common_something_went_wrong_error') }}
          </p>
          <p class="error-404__message">
            {{ $t('common_404_error_title_text_web') }}
          </p>
        </div>
        <a href="/">
          <button class="error-404__button button">
            {{ $t('common_go_to_main_button_text') }}
          </button>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import EmptyPageSvg from '@/components/svg/images/404-image';

export default {
  name: 'error-404',

  components: {
    EmptyPageSvg,
  },
};
</script>
