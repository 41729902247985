<template>
  <div>
    <Layout404 v-show="has404" />

    <router-view
      v-show="!has404"
      :key="$route.fullPath"
    />

    <AppModal
      v-if="showLanguageModal"
      :title="$t('profile_content_language_button')"
      :max-width="536"
      :is-displayed-close="false"
    >
      <div class="app-language-modal-content">
        <span>{{ $t('main_content_language_discr_text') }}</span>
        <AppLanguageListSelect
          :is-content-language-select="true"
          :show-language-name="true"
          :show-accept-button="true"
          :is-static-position="true"
        />
      </div>
    </AppModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Layout404 from '@/views/errors/404.vue';
import AppModal from '@/components/helpers/modals/modal.vue';
import AppLanguageListSelect from '@/components/helpers/language-select.vue';

export default {
  name: 'app-layout',

  components: {
    Layout404,
    AppModal,
    AppLanguageListSelect,
  },

  data: () => ({
    showLanguageModal: false,
  }),

  computed: {
    ...mapGetters('helpers', ['has404', 'isMobile']),

    ...mapGetters('settings', [
      'materialsLanguagesList',
      'activeMaterialsLanguage',
      'defaultMaterialsLanguage',
    ]),
  },

  created() {
    if (!this.materialsLanguagesList.find(el => el.id === this.activeMaterialsLanguage)) {
      this.$store.commit('settings/setActiveMaterialsLanguage', this.defaultMaterialsLanguage);
      this.showLanguageModal = this.materialsLanguagesList.length > 1;
    }
  },
};
</script>

<style lang="scss">
.app-language-modal-content {
  width: 100%;

  @media (min-width: $screen-lg) {
    padding: 8px;
  }

  p {
    @include assets-typography-body-bold;
    font-size: 30px;
    line-height: 42px;

    @media (min-width: $screen-lg) {
      font-size: 40px;
      line-height: 52px;
    }
  }

  span {
    @include assets-typography-caption-regular;

    @media (min-width: $screen-lg) {
      @include assets-typography-card-title-regular;
    }
  }

  .app-language-select {
    margin-top: 24px;
  }

  .v2-button {
    width: calc(100% - #{$indent-sm} * 2);
    margin-left: $indent-sm;
    margin-top: 32px;
    color: var(--surfaceWhite);
    max-height: 48px;
    height: 48px;
    position: absolute;
    bottom: 24px;
    left: 0;

    @media (min-width: $screen-md) {
      position: static;
      max-width: 100%;
      width: 100%;
      margin-left: 0;
    }
  }
}
</style>
