<template>
  <base-home-widget
    :widget-name="team.name"
    :to="routeToMyTeam"
    :count-text="countText"
  >
    <template #content>
      <app-slider
        :columns="ww4Columns"
        :column-size="ww4ColumnsForIsX"
      >
        <MyTeamEmployeeCard
          v-for="(worker, key) in team.users"
          :key="key"
          :employee="worker"
          :show-manager-id="user.id"
          :from-dashboard="true"
        />
      </app-slider>
    </template>
  </base-home-widget>
</template>

<script>
import { mapGetters } from 'vuex';
import MyTeamEmployeeCard from '@/components/app/my-team/my-team-employee-card';
import MyTeamWidget from '@/entities/modules/dashboard/MyTeamWidget';
import BaseHomeWidget from '@/components/app/home/base-home-widget';
import AppSlider from '@/components/helpers/slider';
import CustomRoute from '@/entities/common/CustomRoute';

export default {
  name: 'my-team',

  components: {
    AppSlider,
    MyTeamEmployeeCard,
    BaseHomeWidget,
  },

  props: {
    team: {
      type: MyTeamWidget,
      required: true,
    },
  },

  computed: {
    ...mapGetters('auth', ['user']),

    ...mapGetters('helpers', ['ww4Columns', 'ww4ColumnsForIsX']),

    countText() {
      return this.$tc('common_d_employee_text', this.team.users.length, {
        0: this.team.users.length,
      });
    },

    routeToMyTeam() {
      return {
        name: CustomRoute.TO_MY_TEAM,
        query: {
          from: 'dashboard-widget',
        },
      };
    },
  },
};
</script>
