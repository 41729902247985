import IStatistic from '@/entities/modules/learning-programs/IStatistic';
import MaterialStatus from '@/entities/common/testing/MaterialStatus';
import dateProvider from '@/providers/dateProvider';

export default class MaterialLongreadStatistic implements IStatistic {
  materialId: number;
  updatedAt?: Date;
  updatedAtTimestamp: number;
  startTime?: Date;
  endTime?: Date;
  pagesCount: number;
  completedPagesCount: number;
  percent: number;
  points: number;
  duration: number;

  // status: "appointed"

  constructor(payload: any) {
    this.materialId = parseInt(payload.id, 10);
    this.updatedAt = dateProvider(payload.updated_at);
    this.updatedAtTimestamp = payload.updated_at;
    this.startTime = dateProvider(payload.start_time);
    this.endTime = dateProvider(payload.end_time);
    this.pagesCount = parseInt(payload.pages_count, 10);
    this.completedPagesCount = parseInt(payload.completed_pages_count, 10);
    this.percent = parseInt(payload.percent, 10);
    this.points = parseInt(payload.points, 10);
    this.duration = parseInt(payload.duration, 10);
  }

  getMaterialStatus(): MaterialStatus {
    switch (this.percent) {
      case 0:
        return MaterialStatus.ASSIGNED;
      case 100:
        return MaterialStatus.PASSED;
      default:
        return MaterialStatus.IN_PROGRESS;
    }
  }

  getPoints(): number {
    return this.points;
  }

  getPercent(): number {
    return this.percent;
  }
}
