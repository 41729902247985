import { each, find, uniq } from 'lodash';

export default interface IUpdatable {
  id: number;
  updatedAt: number;

  getTimestamp(): number;
}

function compareAB(collectionA: IUpdatable[], collectionB: IUpdatable[]): [number[], number[]] {
  const areaA: number[] = [];

  const areaAB: number[] = [];

  each(collectionA, existed => {
    const founded = find(collectionB, actual => actual.id === existed.id);

    if (founded) {
      const hasTimestamp = existed.getTimestamp() > 0 || founded.getTimestamp() > 0;

      const updatedAtExpired = existed.updatedAt !== founded.updatedAt;

      const timestampExpired = hasTimestamp && existed.getTimestamp() !== founded.getTimestamp();

      if (updatedAtExpired || timestampExpired) {
        areaAB.push(founded.id);
      }
    } else {
      areaA.push(existed.id);
    }
  });

  return [areaA, areaAB];
}

export function compareUpdatableCollections(
  collectionA: IUpdatable[],
  collectionB: IUpdatable[],
): [number[], number[]] {
  const [areaA, areaABForA] = compareAB(collectionA, collectionB);

  const [areaB, areaABForB] = compareAB(collectionB, collectionA);

  return [uniq([...areaABForA, ...areaABForB]), uniq([...areaA, ...areaB])];
}
