export default class QuestionTask {
  id: number;
  name: string;
  selected: boolean = false;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.name = payload.answer;
  }

  resetSelectedAnswer(): void {
    this.selected = false;
  }

  setSelectedAnswer(): void {
    this.selected = true;
  }
}
