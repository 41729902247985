export default class EmployeeMeta {
  lastActive: number;
  points: number;
  isFavorite: boolean;

  constructor(payload: any) {
    this.lastActive = parseInt(payload.last_active, 10);
    this.points = parseInt(payload.points, 10);
    this.isFavorite = parseInt(payload.is_favorite, 10) === 1;
  }

  setFavorite(status: boolean) {
    this.isFavorite = status;
  }
}
