import each from 'lodash/each';
import ProgramStatistic from '@/entities/modules/learning-programs/ProgramStatistic';
import Pagination from '../../common/Pagination';

export function createProgramsStatisticList(payload: any): [Pagination, ProgramStatistic[]] {
  const pagination = new Pagination(payload.meta.pagination);

  const programStatistics: ProgramStatistic[] = [];
  each(payload.learning_programs, lp => {
    const programStat = new ProgramStatistic(lp);

    programStatistics.push(programStat);
  });

  return [pagination, programStatistics];
}
