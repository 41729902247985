import { each } from 'lodash';
import User from '@/entities/modules/auth/User';
// eslint-disable-next-line import/no-cycle
import Badge from '@/entities/common/Badge';
import Category from '@/entities/common/Category';
import ResultsWidget from '@/entities/modules/dashboard/ResultsWidget';

export default class ExtendedEmployee {
  id: number;
  user: User;
  isFavorite: boolean;
  badges: Badge[] = [];
  categories: Category[] = [];
  resultsWidget: ResultsWidget;

  constructor(payload: any) {
    this.id = parseInt(payload.profile.id, 10);
    this.user = new User(payload);
    this.isFavorite = parseInt(payload.is_favorite, 10) === 1;

    if (payload.badges?.length) {
      each(payload.badges, badge => {
        this.badges.push(new Badge(badge));
      });
    }

    if (payload.categories?.length) {
      each(payload.categories, category => {
        this.categories.push(new Category(category));
      });
    }

    this.resultsWidget = new ResultsWidget(payload.results, true);

    this.resultsWidget.addResultItemAll();
  }

  setFavorite(status: boolean) {
    this.isFavorite = status;
  }
}
