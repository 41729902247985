import UserState from '@/entities/modules/auth/UserState';
import UserVerified from '@/entities/modules/auth/UserVerified';
import UserToken from '@/entities/modules/auth/UserToken';
import each from 'lodash/each';
import BaseGroup from '@/entities/common/groups/BaseGroup';
import { sortBy } from 'lodash';
import UserFormAnswer from '@/entities/modules/auth/UserFormAnswer';

export default class User {
  id: number;
  companyId: number;
  login: string;
  email: string;
  firstName: string;
  lastName: string;
  licenseAgree: boolean;
  phone: string;
  chief: string;
  groups: BaseGroup[];
  avatar: string | null;
  userType: string;
  isAccessCodeRequired: boolean;
  isManager: boolean;
  isAllowToSkipMaterials: boolean;
  formAnswers: UserFormAnswer[];
  userState: UserState;
  userToken: UserToken;
  userVerified: UserVerified;

  constructor(payload: any) {
    this.id = parseInt(payload.profile.id, 10);
    this.companyId = parseInt(payload.profile.company_id, 10);
    this.login = payload.profile.login;
    this.email = payload.profile.email;
    this.firstName = payload.profile.first_name;
    this.lastName = payload.profile.last_name;
    this.licenseAgree = parseInt(payload.profile.is_need_license_agree, 10) === 0;
    this.phone = payload.profile.phone;
    this.chief = payload.profile.chief;
    this.groups = [];
    each(payload.profile.groups, group => {
      this.groups.push(new BaseGroup(group));
    });
    this.avatar = payload.profile.avatar.size > 0 ? payload.profile.avatar.url : null;
    this.userType = payload.profile.user_type;
    this.isAccessCodeRequired = parseInt(payload.profile.require_access_code, 10) === 1;
    this.isManager = parseInt(payload.profile.user_type, 10) === 1;
    this.isAllowToSkipMaterials = parseInt(payload.profile.allow_skip_material, 10) === 1;
    this.formAnswers = [];
    each(payload.profile.form_answers, answer => {
      this.formAnswers.push(new UserFormAnswer(answer));
    });
    this.formAnswers = sortBy(this.formAnswers, ['order']);
    this.userState = new UserState(payload);
    this.userToken = new UserToken(payload);
    this.userVerified = new UserVerified(payload.profile.need_verify);
  }
}
