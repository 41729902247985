import ResultFormat from '@/entities/common/testing/settings/ResultFormat';
import ShowAnswersFormat from '@/entities/common/testing/settings/ShowAnswersFormat';
import ITestSettings from '@/entities/common/testing/settings/ITestSettings';

export default class Settings implements ITestSettings {
  allowSeeDetails: boolean;
  allowUserSkipMaterials: boolean;
  allowManagerSkipMaterials: boolean;
  resultValue: ResultFormat;
  isRandomQuestions: boolean;
  showAnswers: ShowAnswersFormat;
  showRecommendation: boolean;
  showResultText: boolean;
  testBarrier: number;
  testPassed: string;
  testFailed: string;
  testPerfect: string;

  constructor(payload: any) {
    this.allowSeeDetails = parseInt(payload.allow_see_details, 10) === 1;

    this.allowUserSkipMaterials = parseInt(payload.allow_skip_material, 10) === 1;
    this.allowManagerSkipMaterials = parseInt(payload.allow_skip_material, 10) !== 3;

    switch (parseInt(payload.result_value, 10)) {
      case 1:
        this.resultValue = ResultFormat.PERCENT;
        break;
      case 2:
        this.resultValue = ResultFormat.POINTS;
        break;
      default:
        this.resultValue = ResultFormat.HIDDEN;
        break;
    }

    this.isRandomQuestions = parseInt(payload.random_questions, 10) === 1;

    switch (parseInt(payload.show_correct_answers, 10)) {
      case 0:
        this.showAnswers = ShowAnswersFormat.NOT_SHOW;
        break;
      default:
        this.showAnswers = ShowAnswersFormat.SHOW_ALL;
        break;
    }

    this.showRecommendation = parseInt(payload.show_recommendations, 10) === 1;
    this.showResultText = parseInt(payload.show_result_text, 10) === 1;
    this.testBarrier = parseInt(payload.test_barrier, 10);

    this.testPassed = payload.test_passed;
    this.testFailed = payload.test_failed;
    this.testPerfect = payload.test_perfect;
  }
}
