import Vue from 'vue';
import Router, { Route } from 'vue-router';
import { Path } from 'path-parser';
import has from 'lodash/has';
import store from '@/store/index';
import firebase from 'firebase/app';
import auth from '@/router/modules/auth';
import dashboard from '@/router/modules/dashboard';
import evaluation from '@/router/modules/evaluation';
import events from '@/router/modules/events';
import giftStore from '@/router/modules/gift-store';
import information from '@/router/modules/information';
import learningItems from '@/router/modules/learning-items';
import learningPrograms from '@/router/modules/learning-programs';
import messages from '@/router/modules/messages';
import myResults from '@/router/modules/my-results';
import myTeam from '@/router/modules/my-team';
import other from '@/router/modules/other';
import rating from '@/router/modules/rating';
import search from '@/router/modules/search';
import tasks from '@/router/modules/tasks';
import video from '@/router/modules/video';
import { sendEvent } from '@/providers/analyticsProvider';
import CorrectName from '@/entities/common/CorrectName';
import getModuleNameByCorrectName from '@/providers/correctNameProvider';
import { i18n } from '@/lang';
import CustomRoute from '@/entities/common/CustomRoute';

Vue.use(Router);

const router = (() => {
  let companyId = 0;

  let urlCompanyId = 0;

  const path = new Path('/:id/');

  const pattern: any = path.partialTest(window.location.pathname);

  if (pattern && has(pattern, 'id')) {
    urlCompanyId = parseInt(pattern.id, 10);
  }

  const rawCompanyId = localStorage.getItem('company_id');

  if (rawCompanyId) {
    companyId = parseInt(rawCompanyId, 10);
  }

  // id компании есть и он не совпадает с id в url -- редиректим на главную
  if (companyId > 0 && companyId !== urlCompanyId) {
    location.href = `/${companyId}/`;

    return;
  }

  // eslint-disable-next-line no-shadow
  const router = new Router({
    mode: 'history',

    base: `/${companyId}/`,

    routes: [
      ...auth,
      ...dashboard,
      ...evaluation,
      ...events,
      ...giftStore,
      ...learningItems,
      ...learningPrograms,
      ...messages,
      ...myResults,
      ...myTeam,
      ...other,
      ...information,
      ...rating,
      ...search,
      ...tasks,
      ...video,
    ],

    scrollBehavior() {
      return { x: 0, y: 0 };
    },
  });

  /**
   * Изменит заголовки
   */
  function titleProvider(to: Route) {
    let moduleId;

    const moduleCode = to?.meta?.moduleCode;

    const title = to?.meta?.title;

    if (moduleCode === CorrectName.LEARNING_MATERIALS) {
      moduleId = parseInt(to.params.moduleId, 10);
    }

    let moduleName = getModuleNameByCorrectName(moduleCode, moduleId);

    if (!moduleName) {
      moduleName = i18n.t(title).toString();
    }

    store.dispatch('settings/setTitle', moduleName);
  }

  /**
   * Список страниц, на которых не нужна авторизация
   * @type {string[]}
   */
  const exclude = [
    CustomRoute.TO_AUTH_LOGIN,
    CustomRoute.TO_AUTH_LOGIN_PHONE,
    CustomRoute.TO_AUTH_ENTRY_POINT,
    CustomRoute.TO_AUTH_LOGIN_OAUTH,
    CustomRoute.TO_AUTH_PASSWORD_RESET,
    CustomRoute.TO_AUTH_PASSWORD_RESET_BY,
    CustomRoute.TO_AUTH_PASSWORD_RESET_EDIT,
    CustomRoute.TO_AUTH_REGISTER,
  ];

  /**
   * Список страниц для верификации
   * @type {string[]}
   */
  const excludeVerification = [
    CustomRoute.TO_AUTH_VERIFICATION,
    CustomRoute.TO_AUTH_VERIFICATION_PHONE,
    CustomRoute.TO_AUTH_VERIFICATION_PHONE_VERIFY,
    CustomRoute.TO_AUTH_VERIFICATION_EMAIL,
    CustomRoute.TO_AUTH_VERIFICATION_EMAIL_VERIFY,
    CustomRoute.TO_AUTH_VERIFICATION_FORM,
    CustomRoute.TO_AUTH_VERIFICATION_PASSWORD,
  ];

  router.beforeEach(async (to, from, next) => {
    /**
     * Скрываем 404
     */
    store.dispatch('helpers/hide404');

    /**
     * Закрываем открытый sidebar
     */
    if (store.getters['helpers/isSidebarOpened']) {
      store.dispatch('helpers/hideSidebar');
    }

    /**
     * Закрывает открытое доп меню
     */
    if (store.getters['helpers/isAddedMenuOpened']) {
      store.commit('helpers/setAddedMenuStatus', false);
    }

    /**
     * Убираем белый фон
     */
    store.dispatch('helpers/hideBackground');

    let toName: string = '';

    if (to.name) {
      toName = to.name;
    }

    if (store.getters['auth/user']) {
      // @ts-ignore
      if (exclude.includes(toName)) {
        next('/');

        return;
      }

      const isUserNeedsVerifySomeone = store.getters['auth/isUserNeedsVerifySomeone'];

      const isUserLicenseAgree = store.getters['auth/isUserLicenseAgree'];

      // Если у пользователя не принято лицензионное соглашение,
      // покажем ему его
      if (!isUserLicenseAgree) {
        if (toName !== CustomRoute.TO_AUTH_LICENSE) {
          next('/license');

          return;
        }
      } else {
        // У пользователя принято лицензионное соглашение

        // Если ему нужно что-то верифицировать, заставим сделать его это
        // eslint-disable-next-line no-lonely-if
        if (isUserNeedsVerifySomeone) {
          // @ts-ignore
          if (!excludeVerification.includes(toName)) {
            next('/verification');

            return;
          }
          // @ts-ignore
        } else if (excludeVerification.includes(toName)) {
          // Ему ничего не нужно верифицировать

          // Главное чтобы он не заходил на страницы верификации тогда

          // Если есть сохранненый в локальном хранилище путь, то переходим на него
          const routerBackFromStorage = localStorage.getItem('router_back');

          if (routerBackFromStorage && !/verification/gm.test(routerBackFromStorage)) {
            window.location.href = encodeURIComponent(routerBackFromStorage).replace(/%2F/g, '/');

            return;
          }

          window.location.href = '/';

          return;
        }
      }
      // @ts-ignore
    } else if (!exclude.includes(toName)) {
      if (to.path === '/') {
        next('/login');
      } else {
        next(`/login?router_back=${to.path}`);
      }

      return;
    }

    const FROM_MODULE_CODE = from?.meta?.moduleCode;

    const TO_MODULE_CODE = to?.meta?.moduleCode;

    if (to.meta && TO_MODULE_CODE) {
      const timerId = setInterval(() => {
        if (store.getters['settings/hasModules']) {
          clearInterval(timerId);
          titleProvider(to);
        }
      }, 300);
    } else {
      titleProvider(to);
    }

    // если произошел переход между разделами программы обучения, то очистим все фильтры
    if (
      (to.name === CustomRoute.TO_LEARNING_PROGRAMS &&
        from.name !== CustomRoute.TO_LEARNING_PROGRAMS_MATERIAL &&
        from.name !== CustomRoute.TO_LEARNING_PROGRAMS) ||
      ((from.name === CustomRoute.TO_LEARNING_PROGRAMS_MATERIAL ||
        from.name === CustomRoute.TO_LEARNING_PROGRAMS) &&
        to.params.moduleId !== from.params.moduleId)
    ) {
      store.dispatch('learningPrograms/changeTag', null);
      store.dispatch('learningPrograms/changeCategory', null);
      store.dispatch('learningPrograms/changeStatusFilter', 'all');
      store.dispatch('learningPrograms/changeSortFilter', 'default');
    }

    // если происходит переход из избранного куда-то еще, то очишаем избранное,
    // чтобы при следущем заходе в раздел подгрузить свежие данные
    if (
      from.name !== CustomRoute.TO_DASHBOARD_PROFILE_FAVORITES &&
      to.name === CustomRoute.TO_DASHBOARD_PROFILE_FAVORITES
    ) {
      store.dispatch('favorites/clearFavorites', null);
    }

    if (FROM_MODULE_CODE === CorrectName.TEAM && TO_MODULE_CODE !== CorrectName.TEAM) {
      store.dispatch('myTeam/getEmptyFilter', null);
    }

    if (
      (from.name === CustomRoute.TO_MY_TEAM_MANAGER_MATERIAL &&
        to.name === CustomRoute.TO_MY_TEAM) ||
      (from.name === CustomRoute.TO_MY_TEAM_MANAGER_MATERIAL &&
        to.name === CustomRoute.TO_MY_TEAM_MANAGER_MATERIAL)
    ) {
      store.dispatch('myTeam/getEmptyFilter', null);
    }

    if (window.innerWidth >= 1280) {
      store.commit(
        'intercom/setShowIntercomIcon',
        to.meta && to.meta.showIntercom !== undefined ? to.meta.showIntercom : true,
      );
    } else {
      // @ts-ignore
      const skipPages =
        // @ts-ignore
        to.name && (exclude.includes(to.name) || excludeVerification.includes(to.name));
      store.commit(
        'intercom/setShowIntercomIcon',
        !!skipPages || to.name === CustomRoute.TO_MESSAGES_SUPPORT,
      );
    }

    if (FROM_MODULE_CODE === CorrectName.VIDEO && TO_MODULE_CODE !== CorrectName.VIDEO) {
      store.commit('video/changeSelectedTag', null);
      store.commit('video/changeSelectedCategory', null);
      store.commit('video/changeSelectedSortMethod', 'default');
    }

    if (FROM_MODULE_CODE === CorrectName.MESSAGES && TO_MODULE_CODE !== CorrectName.MESSAGES) {
      store.commit('messages/changeSelectedTag', null);
    }

    if (to.meta && typeof to.meta.enableSidebar !== 'undefined') {
      store.dispatch(to.meta.enableSidebar ? 'helpers/enableSidebar' : 'helpers/disableSidebar');
    } else {
      store.dispatch('helpers/enableSidebar');
    }

    if (to.meta && typeof to.meta.showBlockedMessages !== 'undefined') {
      store.commit('auth/setShowBlockedMessages', to.meta.showBlockedMessages);
    } else {
      store.commit('auth/setShowBlockedMessages', true);
    }

    if (
      from.name === CustomRoute.TO_DASHBOARD_PROFILE_MATERIALS_OF_DEADLINE &&
      to.name === CustomRoute.TO_LEARNING_PROGRAMS_MATERIAL
    ) {
      sendEvent(firebase.analytics(), 'dashboard_date_of_passage_view_btn');
    }

    next();
  });

  router.afterEach((to, from) => {
    const fromModuleCode = from?.meta?.moduleCode;

    const toModuleCode = to?.meta?.moduleCode;

    if (fromModuleCode && toModuleCode && fromModuleCode !== toModuleCode) {
      store.dispatch('settings/updateMenuNewItems');
    }

    // css-vars-ponyfill
    // @ts-ignore
    if (typeof cssVars !== 'undefined') {
      setTimeout(() => {
        // @ts-ignore
        // eslint-disable-next-line no-undef
        cssVars({});
      }, 330);
    }
  });

  router.afterEach(to => {
    const eventOnEnter = to?.meta?.eventOnEnter;

    if (eventOnEnter) {
      sendEvent(firebase.analytics(), eventOnEnter);
    }

    // @ts-ignore
    if (to.query.from && to.meta.from && to.meta.from[to.query.from]) {
      // @ts-ignore
      sendEvent(firebase.analytics(), to.meta.from[to.query.from]);
    }
  });

  router.onError(error => {
    if (
      error &&
      error.message &&
      error.message.includes('Loading chunk') &&
      error.message.includes('failed.')
    ) {
      sendEvent(firebase.analytics(), 'web_update_loading_chunk_failed');
      setTimeout(() => {
        location.reload();
      }, 250);
    }
  });

  return router;
})();

export default router;
