<template>
  <div
    v-click-outside="closeSearch"
    class="app-search"
  >
    <div
      v-if="showSearchText"
      class="app-search__query"
    >
      <input
        ref="saveFocus"
        :value="q"
        :placeholder="placeholder"
        type="text"
        @keyup.enter="goSearchPage"
        @input="inputOnMobile"
        @focus="focusInput"
        @blur="blurInput"
      />
    </div>
    <div
      class="app-search__icon"
      :class="{ 'app-search__icon--active': showSearchText }"
      @click="toggleSearch"
    >
      <SearchSvg />
    </div>
    <div
      v-if="isShowResult"
      :class="{ 'app-search-results__frame': isFrame }"
      :style="{ height: resultsBlockHeight }"
      class="app-search-results"
    >
      <AppSearchResultEntity
        v-for="(material, key) in firstMaterials"
        :key="key"
        :material="material"
        :q="q"
      />

      <div
        v-if="hasMoreResults"
        class="app-search-results-link"
      >
        <router-link :to="advancedRoute">
          {{
            $tc('common_show_all_d_results_text', totalResults, {
              0: totalResults.toLocaleString(),
            })
          }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { each, filter, has } from 'lodash';
import SearchSvg from '@/components/svg/icons/search';
import ClickOutside from 'vue-click-outside';
import { sendEvent } from '@/providers/analyticsProvider';
import AppSearchResultEntity from '@/components/helpers/search/search-result-entity';
import CustomRoute from '@/entities/common/CustomRoute';
import checkFrame from '@/providers/checkFrameProvider';
import { mapGetters } from 'vuex';

export default {
  name: 'app-search',

  components: {
    SearchSvg,
    AppSearchResultEntity,
  },

  directives: {
    ClickOutside,
  },

  props: {
    isOpenedSearch: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    q: '',
    results: [],
    showSearchText: false,
    isFocused: false,
    headerHeight: 0,
  }),

  computed: {
    ...mapGetters('helpers', ['ww']),

    isDesktop() {
      return this.ww > 1279;
    },

    isFrame() {
      return checkFrame();
    },

    moduleId() {
      if (has(this.$route, 'params.moduleId')) {
        return parseInt(this.$route.params.moduleId, 10);
      }

      return 0;
    },

    moduleCode() {
      if (has(this.$route, 'meta.moduleCode')) {
        return this.$route.meta.moduleCode;
      }

      return null;
    },

    materials() {
      const materials = [];

      let priorityModules = [];

      let otherModules = [];

      if (this.moduleId) {
        priorityModules = filter(this.results, o => o.moduleId === this.moduleId);
        otherModules = filter(this.results, o => o.moduleId !== this.moduleId);
      } else {
        priorityModules = filter(this.results, o => o.moduleId !== this.moduleId);
        otherModules = filter(this.results, o => o.moduleCode !== this.moduleCode);
      }

      if (priorityModules.length) {
        each(priorityModules, module => materials.push(...module.materials));
      } else {
        each(otherModules, module => materials.push(...module.materials));
      }

      return materials;
    },

    firstMaterials() {
      return this.materials.slice(0, 5);
    },

    hasMoreResults() {
      let count = 0;
      each(this.results, result => {
        count += result.count;
      });

      return count > 5;
    },

    advancedRoute() {
      return {
        name: CustomRoute.TO_SEARCH,
        query: {
          q: this.q,
          from: 'global-search',
        },
      };
    },

    totalResults() {
      return this.results
        .map(module => module.count)
        .reduce((total, current) => total + current, 0);
    },

    placeholder() {
      return this.isFocused ? '' : this.$t('common_search_placeholder_text');
    },

    isShowResult() {
      return this.q.length && this.firstMaterials && this.firstMaterials.length > 0;
    },

    resultsBlockHeight() {
      return this.isDesktop ? 'auto' : `calc(100vh - ${this.headerHeight}px)`;
    },
  },

  watch: {
    isOpenedSearch: {
      immediate: true,
      handler(val) {
        this.showSearchText = val;
      },
    },

    async q(q) {
      if (q.length < 3) {
        this.results = [];

        return;
      }

      await this.load();
      sendEvent(this.$analytics, 'search_btn');
    },

    showSearchText(status) {
      if (!status) {
        this.q = '';
      } else {
        this.$nextTick(() => {
          sendEvent(this.$analytics, 'search_btn');
          this.$refs.saveFocus.focus();
        });
      }
    },

    isShowResult(val) {
      document.body.style.overflow = val ? 'hidden' : 'visible';
    },

    ww: {
      immediate: true,
      handler() {
        this.calculateHeaderHeight();
      },
    },

    $route() {
      this.closeSearch();
    },
  },

  beforeDestroy() {
    document.body.removeAttribute('style');
  },

  methods: {
    async load() {
      this.results = await this.$store.dispatch('search/groupedSearch', this.q);
    },

    closeSearch() {
      this.showSearchText = false;
      this.$emit('update:isOpenedSearch', this.showSearchText);
    },

    goSearchPage() {
      if (this.hasMoreResults) {
        this.$router.push({
          name: CustomRoute.TO_SEARCH,
          query: {
            q: this.q,
            from: 'global-search',
          },
        });
      }
    },

    inputOnMobile(q) {
      this.q = q.target.value;
    },

    focusInput() {
      this.isFocused = true;
    },

    blurInput() {
      this.isFocused = false;
    },

    toggleSearch() {
      this.showSearchText = !this.showSearchText;
      this.$emit('update:isOpenedSearch', this.showSearchText);
    },

    calculateHeaderHeight() {
      this.headerHeight = document.querySelector('.app-sidebar__toolbar')?.clientHeight ?? 0;
    },
  },
};
</script>
