/* eslint-disable */
enum OrderStatus {
  NEW = 'new',
  INWORK = 'in-work',
  COMPLETED = 'completed',
  CANCELED = 'canceled',
  DELIVERED = 'delivered',
  NOTRECEIVED = 'not-received',
}

export default OrderStatus;
