import CorrectName from '@/entities/common/CorrectName';
import CustomRoute from '@/entities/common/CustomRoute';

export default [
  {
    path: '/video',
    name: CustomRoute.TO_VIDEOS,
    meta: {
      title: 'discover_screen_title_text',
      moduleCode: CorrectName.VIDEO,
      eventOnEnter: 'discover_module_open',
      from: { 'dashboard-widget': 'dashboard_discover_name_btn' },
    },
    component: () => import(/* webpackChunkName: "information" */ '@/views/video/index.vue'),
  },
  {
    path: '/video/category/:id-:slug',
    name: CustomRoute.TO_VIDEOS_CATEGORY,
    meta: {
      title: 'discover_screen_title_text',
      moduleCode: CorrectName.VIDEO,
      eventOnEnter: 'discover_module_open',
      from: { 'dashboard-widget': 'dashboard_discover_name_btn' },
    },
    component: () => import(/* webpackChunkName: "information" */ '@/views/video/index.vue'),
  },
  {
    path: '/video/:id-:slug',
    name: CustomRoute.TO_VIDEOS_MATERIAL,
    meta: {
      title: 'discover_screen_title_text',
      moduleCode: CorrectName.VIDEO,
      eventOnEnter: 'discover_open_btn',
      showIntercom: false,
      showBlockedMessages: false,
    },
    component: () => import(/* webpackChunkName: "information" */ '@/views/video/show.vue'),
  },
];
