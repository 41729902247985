import CorrectName, { getCorrectNameByApiCode } from '@/entities/common/CorrectName';
import ApiName, { getApiNameByApiCode } from '@/entities/common/ApiName';

export default class MenuNewItem {
  readonly moduleId: number;
  readonly moduleCode: CorrectName;
  readonly apiModuleCode: ApiName;
  readonly count: number;

  constructor(payload: any) {
    this.moduleId = parseInt(payload.module_id, 10);
    this.moduleCode = getCorrectNameByApiCode(payload.name);
    this.apiModuleCode = getApiNameByApiCode(payload.name);
    this.count = parseInt(payload.count, 10);
  }
}
