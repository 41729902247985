import moment from 'moment';
// @ts-ignore
import dateProvider, { getCurrentTimestamp } from '@/providers/js/dateProvider';
import ImageSource from '@/entities/common/sources/ImageSource';
import { i18n } from '@/lang';
import LearningItemMaterialMeta from '@/entities/modules/my-team/LearningItemMaterialMeta';
import ApiName from '@/entities/common/ApiName';
import MaterialProgressMeta from './MaterialProgressMeta';

export default class MaterialCardMeta {
  id: number;
  name: string;
  type: string;
  moduleId: number;
  moduleName: string;
  imageWide: ImageSource;
  test?: object;
  event?: object;
  interview?: object;
  learningItem?: LearningItemMaterialMeta;
  progress: MaterialProgressMeta;
  order: number;
  showDetails: boolean;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.name = payload.name;
    this.type = payload.type;
    this.moduleId = parseInt(payload.module_id, 10);
    this.moduleName = payload.module_name;
    this.imageWide = payload.image_wide;
    this.progress = new MaterialProgressMeta(payload.progress);
    this.order = parseInt(payload.order, 10);
    this.showDetails = parseInt(payload.show_details, 10) === 1;

    switch (this.type) {
      case ApiName.EVENT_MODULE: {
        this.event = payload.event;
        break;
      }

      case ApiName.TEST_MODULE: {
        this.test = payload.test;
        break;
      }

      case ApiName.LEARNING_PROGRAM: {
        this.learningItem = new LearningItemMaterialMeta(payload.learning_program);
        break;
      }

      case ApiName.LEARNING_TRACK: {
        this.learningItem = new LearningItemMaterialMeta(payload.learning_track);
        break;
      }

      case ApiName.INTERVIEW_MODULE: {
        this.interview = payload.interview;
        break;
      }

      default:
        break;
    }
  }

  getDatePassing() {
    if (!this.learningItem) {
      return;
    }

    const oneWeekMilliseconds = 7 * 24 * 60 * 60 * 1000;

    const oneDayMilliseconds = 24 * 60 * 60 * 1000;

    const oneHourMilliseconds = 60 * 60 * 1000;

    const oneMinuteMilliseconds = 60 * 1000;

    const currentTimestamp = getCurrentTimestamp();

    const currentMinute = dateProvider(currentTimestamp).getMinutes();

    const currentHour = dateProvider(currentTimestamp).getHours();

    const deadlineMinute = dateProvider(this.learningItem.passingDeadline).getMinutes();

    const deadlineHour = dateProvider(this.learningItem.passingDeadline).getHours();

    const dateDiff = this.learningItem.passingDeadline * 1000 - currentTimestamp * 1000;

    const formatDayMonth = moment(this.learningItem.passingDeadline * 1000).format('D MMMM');

    const formatTime = moment(this.learningItem.passingDeadline * 1000).format('LT');

    const formatForPassed = `, ${formatDayMonth}, ${formatTime}`;

    const diffDay = new Date(dateDiff).getDate();

    const defaultFormat = `${formatDayMonth}, ${formatTime}`;

    const commonPassDefault =
      this.learningItem.deadlineType === 'absolute'
        ? i18n.tc('common_pass_by_date_text', 0, { 0: defaultFormat })
        : i18n.tc('myteam_materials_users_time_text', 0, { 0: defaultFormat });

    const diffHour =
      deadlineHour - currentHour > 0
        ? deadlineHour - currentHour
        : 24 - (currentHour - deadlineHour);

    const diffMinutes =
      deadlineMinute - currentMinute > 0 ? deadlineMinute - currentMinute : 60 - currentMinute;

    if (dateDiff > 0) {
      if (dateDiff > this.learningItem.passingNoticeDeadline * 1000) {
        return commonPassDefault;
      }

      if (this.progress.notStarted && dateDiff < this.learningItem.passingNoticeDeadline * 1000) {
        // До крайнего срока осталось менее недели
        if (dateDiff < oneWeekMilliseconds && dateDiff > oneDayMilliseconds) {
          return `${commonPassDefault} (${diffDay} ${i18n.tc(
            'common_days_text_lowercase',
            diffDay,
            { 0: diffDay },
          )})`;
        }

        // До крайнего срока осталось менее дня
        if (dateDiff < oneDayMilliseconds && dateDiff > oneHourMilliseconds) {
          return `${commonPassDefault} (${i18n.tc('common_d_hours_text', diffHour, {
            0: diffHour,
          })})`;
        }

        // До крайнего срока осталось менее часа
        if (dateDiff < oneHourMilliseconds && dateDiff > oneMinuteMilliseconds) {
          return `${commonPassDefault} (${i18n.tc('common_minutes_number_text', diffMinutes, {
            0: diffMinutes,
          })})`;
        }

        return `${commonPassDefault} (${diffDay} ${i18n.tc('common_days_text_lowercase', diffDay, {
          0: diffDay,
        })})`;
      }

      if (!this.progress.notStarted && !this.learningItem.hasNotPassedUsers) {
        return i18n.tc('common_passed_the_deadline_date_time_text', 0, { 0: formatForPassed });
      }
    }

    if (dateDiff < 0) {
      if (!this.progress.notStarted && !this.learningItem.hasNotPassedUsers) {
        return i18n.tc('common_passed_the_deadline_date_time_text', 0, { 0: formatForPassed });
      }

      return commonPassDefault;
    }

    return commonPassDefault;
  }

  getTermPassing() {
    if (!this.learningItem) {
      return;
    }

    const daysPassing = Math.ceil(this.learningItem.deadlineTime / 86400);

    return i18n.tc('myteam_materials_users_time_text', daysPassing, { 0: daysPassing });
  }

  getDeadlineStatus() {
    if (!this.learningItem) {
      return;
    }

    if (this.learningItem.passingDeadline - getCurrentTimestamp() > 0) {
      if (
        this.progress.notStarted &&
        this.learningItem.passingDeadline - getCurrentTimestamp() <
          this.learningItem.passingNoticeDeadline
      ) {
        return 'orange';
      }

      if (!this.learningItem.hasNotPassedUsers && !this.progress.notStarted) {
        return 'gray';
      }
    }

    if (
      this.learningItem.passingDeadline - getCurrentTimestamp() < 0 &&
      (this.learningItem.hasNotPassedUsers || this.progress.notStarted)
    ) {
      return 'error';
    }

    return 'gray';
  }
}
