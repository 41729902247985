import Post from '@/entities/modules/messages/Post';
import { createImageMdSource } from '@/entities/common/sources/ImageSource';
import { map, filter, each } from 'lodash';
import dateProvider from '@/providers/dateProvider';
import OffsetPagination from '@/entities/common/OffsetPagination';
import UpDownPagination from '@/entities/common/UpDownPagination';
import PaginationDirection from '@/entities/common/PaginationDirection';
import Tag from '@/entities/modules/learning-programs/Tag';

export default class MessagePost extends Post {
  commentsPagination?: UpDownPagination;
  isBlocked: Boolean;
  adminAvatar: string;
  adminName: string;
  unblockText?: String;
  tags: Tag[] = [];

  constructor(payload: any) {
    super(payload);

    this.image = createImageMdSource(payload.image_wide);
    this.text = payload.text;

    this.isNew = parseInt(payload.is_new, 10) === 1;
    this.order = parseInt(payload.order, 10);
    this.createdAt = dateProvider(payload.activated_at);

    this.isCommentingAllowed = parseInt(payload.allow_commenting, 10) === 1;
    this.comments = parseInt(payload.count_comments, 10);
    this.isCommented = parseInt(payload.is_commented, 10) === 1;
    this.newComments = parseInt(payload.count_new_comments, 10);

    this.likes = parseInt(payload.count_likes, 10);
    this.isLiked = parseInt(payload.is_liked, 10) === 1;
    this.isBlocked = parseInt(payload.is_blocked, 10) === 1;
    this.adminAvatar = payload.admin_avatar ? payload.admin_avatar.url : null;
    this.adminName = payload.admin_name;
    this.unblockText = payload.unblock_text;
    each(payload.tags, tag => {
      this.tags.push(new Tag(tag));
    });
  }

  getCommentsIsNewIds() {
    return map(
      filter(this.commentsList, o => o.isNew),
      'id',
    );
  }

  markCommentsAsRead() {
    const isNewCommentsCount = filter(this.commentsList, comment => comment.isNew).length;
    this.newComments -= isNewCommentsCount;
    each(this.commentsList, comment => comment.markAsRead());
  }

  markAllCommentsAsRead() {
    this.newComments = 0;
  }

  setCommentsPagination(pagination: OffsetPagination, paginationDirection: PaginationDirection) {
    if (paginationDirection === PaginationDirection.DEFAULT || !this.commentsPagination) {
      this.commentsPagination = new UpDownPagination(pagination);
    } else if (this.commentsPagination && paginationDirection === PaginationDirection.UP) {
      this.commentsPagination.setMinOffset(pagination);
    } else if (this.commentsPagination && paginationDirection === PaginationDirection.DOWN) {
      this.commentsPagination.setMaxOffset(pagination);
    }
  }

  isFirstPageComments(): boolean {
    return !!(this.commentsPagination && this.commentsPagination.isFirstPage());
  }

  isLastPageComments(): boolean {
    return !!(this.commentsPagination && this.commentsPagination.isLastPage());
  }

  markAsUnblocked() {
    this.isBlocked = false;
  }

  getTextTags() {
    return this.tags.map(o => o.name);
  }
}
