import has from 'lodash/has';

export default class VideoSource {
  sd: string;
  hd?: string;

  constructor(sd: string, hd?: string) {
    this.sd = sd;
    this.hd = hd;
  }
}

/**
 * Создаст объект VideoSource
 * Учитывает что должен обязательно быть SD источник и, если есть HD, добавляет HD
 *
 * Если у видео нет SD качества, но есть HD -- записываем HD в SD, а HD убираем
 *
 * @param payload
 */
export function createVideoSource(payload: any): VideoSource | undefined {
  if (has(payload, 'sd.url') && !!payload.sd.url) {
    const hd = has(payload, 'hd.url') && !!payload.hd.url ? payload.hd.url : undefined;

    return new VideoSource(payload.sd.url, hd);
  }

  if (has(payload, 'hd.url') && !!payload.hd.url) {
    return new VideoSource(payload.hd.url);
  }
}
