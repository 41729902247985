import EmployeeProgress from '@/entities/modules/my-team/EmployeeProgress';
import ImageSource, { createOnlyImageMdSource } from '@/entities/common/sources/ImageSource';

export default class Employee {
  id: number;
  name: string;
  progress: EmployeeProgress;
  image: ImageSource | undefined;
  lastActiveTimestamp: number;
  points: number;
  isChief: boolean;
  employeesCount: number;
  isFavorite: boolean;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.name = payload.name;
    this.image = createOnlyImageMdSource(payload.image);
    this.lastActiveTimestamp = parseInt(payload.last_active, 10);
    this.points = parseInt(payload.points, 10);
    this.progress = new EmployeeProgress(payload.progress);
    this.isChief = parseInt(payload.is_chief, 10) === 1;
    this.employeesCount = parseInt(payload.employees_count, 10);
    this.isFavorite = parseInt(payload.is_favorite, 10) === 1;
  }

  setFavorite(status: boolean) {
    this.isFavorite = status;
  }
}
