import axios from 'axios';
import { each, find } from 'lodash';
import Pagination from '@/entities/common/Pagination';
import { paginationResolver } from '@/providers/paginationProvider';
import SearchResult from '@/entities/modules/search/SearchResult';
import SearchResultInformation from '@/entities/modules/search/SearchResultInformation';
import SearchResultCommon from '@/entities/modules/search/SearchResultCommon';
import SearchResultEvent from '@/entities/modules/search/SearchResultEvent';
import CorrectName, { getCorrectNameByApiCode } from '@/entities/common/CorrectName';
import ApiName from '@/entities/common/ApiName';
import SearchResultMaterial from '@/entities/modules/search/SearchResultMaterial';

function getMaterials(items: any, moduleCode: CorrectName): SearchResult[] {
  const materials: SearchResult[] = [];

  each(items, item => {
    switch (moduleCode) {
      case CorrectName.INFORMATION:
        materials.push(new SearchResultInformation(item));
        break;

      case CorrectName.EVENT:
        materials.push(new SearchResultEvent(item));
        break;

      case CorrectName.MATERIAL:
        materials.push(new SearchResultMaterial(item));
        break;

      default:
        materials.push(new SearchResultCommon(item));
        break;
    }
  });

  return materials;
}

function createSearchResultsList(
  payload: any,
  moduleCode: CorrectName,
): [Pagination, SearchResult[]] {
  return [new Pagination(payload.meta.pagination), getMaterials(payload.items, moduleCode)];
}

function addMaterialsToModule(sourceModule: any, additionalModule: any, moduleCode: CorrectName) {
  sourceModule.materials.push(...getMaterials(additionalModule.items, moduleCode));
  sourceModule.count += additionalModule.count;
}

export default {
  async groupedSearch(q: string) {
    const response = await axios.get(`/search-grouped?search=${encodeURIComponent(q)}`);

    const results: any[] = [];
    each(response.data.success.data, module => {
      const moduleCode = getCorrectNameByApiCode(module.type);

      const isInformation = moduleCode === CorrectName.INFORMATION;

      const existingInformationModule = find(
        results,
        o => o.moduleCode === CorrectName.INFORMATION,
      );

      if (isInformation && existingInformationModule) {
        addMaterialsToModule(existingInformationModule, module, moduleCode);

        return;
      }

      const isLearningMaterials = moduleCode === CorrectName.LEARNING_MATERIALS;

      const existingModule = find(
        results,
        o => o.moduleId === module.module_id && o.moduleCode !== CorrectName.MATERIAL,
      );

      if (isLearningMaterials && existingModule) {
        addMaterialsToModule(existingModule, module, moduleCode);

        return;
      }

      results.push({
        moduleId: parseInt(module.module_id, 10),
        moduleCode: isLearningMaterials ? CorrectName.LEARNING_MATERIALS : moduleCode,
        materials: getMaterials(module.items, moduleCode),
        count: module.count,
      });
    });

    return results;
  },

  async search(q: string, moduleCode: CorrectName, moduleIds: number[]) {
    const convertQ = encodeURIComponent(q);

    let types = '';

    switch (moduleCode) {
      case CorrectName.TESTS:
        types = `types[]=${ApiName.TEST}`;
        break;
      case CorrectName.INTERVIEW:
        types = `types[]=${ApiName.INTERVIEW}`;
        break;
      case CorrectName.INFORMATION:
        types = `types[]=${ApiName.INFORMATION_CATEGORY}&types[]=${ApiName.INFORMATION}`;
        break;
      case CorrectName.VIDEO:
        types = `types[]=${ApiName.VIDEO}`;
        break;
      case CorrectName.LEARNING_MATERIALS:
        types = `types[]=${ApiName.LEARNING_PROGRAM}&types[]=${ApiName.LEARNING_TRACK}`;
        break;
      case CorrectName.EVENT:
        types = `types[]=${ApiName.EVENT}`;
        break;
      case CorrectName.COMPETENCY:
        types = `types[]=${ApiName.COMPETENCY}`;
        break;
      case CorrectName.TEAM:
        types = `types[]=${ApiName.TEAM}`;
        break;
      case CorrectName.MATERIAL:
        types = `types[]=${ApiName.MATERIAL}`;
        break;
      default:
        break;
    }

    let moduleIdsQuery = '';
    each(moduleIds, id => {
      moduleIdsQuery += `&module_ids[]=${id}`;
    });

    // eslint-disable-next-line no-return-await,no-shadow
    return await paginationResolver<SearchResult>(
      async (page: number, query: string) =>
        axios
          .get(`/search?${types}${moduleIdsQuery}&search=${query}&page=${page}`)
          .then(payload => createSearchResultsList(payload.data.success, moduleCode)),
      [convertQ, moduleIds],
    );
  },
};
