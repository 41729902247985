import BaseWidget from '@/entities/modules/dashboard/BaseWidget';
import { Slide, SlideInterface } from './Slide';

interface BannerInterface {
  data: {
    speed: number;
    slides: SlideInterface[];
  };
}

export default class BannerWidget extends BaseWidget {
  speed: number = 10;
  slides: Slide[] = [];

  constructor(payload: BannerInterface) {
    super(payload);

    this.speed = payload.data.speed;
    payload.data.slides.forEach((slide: SlideInterface) => this.slides.push(new Slide(slide)));
  }
}
