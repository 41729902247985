import dropRight from 'lodash/dropRight';
import each from 'lodash/each';
import filter from 'lodash/filter';
import find from 'lodash/find';
import shuffle from 'lodash/shuffle';
import { ActionTree } from 'vuex';
import { resolver } from '@/providers/promiseReturnerProvider';
import videoApi from '@/services/video';
import commentApi from '@/services/comment';
import Video from '@/entities/modules/video/Video';
import PaginationDirection from '@/entities/common/PaginationDirection';
import ApiName from '@/entities/common/ApiName';
import Category from '@/entities/modules/video/Category';
import { RootState } from '../../types';
import { VideoState } from './types';

export const actions: ActionTree<VideoState, RootState> = {
  /**
   * Загрузит видеоматериалы
   * @param commit
   */
  async load({ state, commit }) {
    let categories: Category[] = [];

    let videos: Video[] = [];

    if (state.videos.length > 0) {
      const [discoversUpdates, categoriesUpdates] = await videoApi.getDiscoversUpdates();

      const newDiscoversId: number[] = [];

      const newCategoriesId: number[] = [];

      const actualVideos: Video[] = [];

      const actualCategories: Category[] = [];
      each(discoversUpdates, currentUpdate => {
        const video = find(state.videos, o => o.id === currentUpdate.id);

        if (!video || (video && video.updatedAt !== currentUpdate.updatedAt)) {
          newDiscoversId.push(currentUpdate.id);
        }

        if (video && video.updatedAt === currentUpdate.updatedAt) {
          actualVideos.push(video);
        }
      });
      each(categoriesUpdates, currentUpdate => {
        const category = find(state.categories, o => o.id === currentUpdate.id);

        if (!category || (category && category.updatedAt !== currentUpdate.updatedAt)) {
          newCategoriesId.push(currentUpdate.id);
        }

        if (category && category.updatedAt === currentUpdate.updatedAt) {
          actualCategories.push(category);
        }
      });

      if (newDiscoversId.length !== 0 || newCategoriesId.length !== 0) {
        const [newVideos, newCategories] = await videoApi.postDiscovers(
          newDiscoversId,
          newCategoriesId,
        );
        actualVideos.push(...newVideos);
        actualCategories.push(...newCategories);
      }
      videos = actualVideos;
      categories = actualCategories;
    } else {
      [videos, categories] = await videoApi.getDiscovers();
    }

    commit('changeVideos', videos);
    commit('changeCategories', categories);
  },

  async getById({}, id) {
    const [payload] = await videoApi.postDiscovers(id);

    return payload[0];
  },

  /**
   * Вернет видео из этой же категории
   * @param state
   * @param id
   */
  async getRelativeVideos({ state }, id): Promise<Video[]> {
    const video = find(state.videos, o => o.id === id);

    if (!video) {
      return resolver([]);
    }

    const videos = filter(state.videos, o => o.categoryId === video.categoryId && o.id !== id);

    if (videos.length < 8) {
      return resolver(shuffle(videos));
    }

    return resolver(dropRight(shuffle(videos), videos.length - 7));
  },

  /**
   * Поставит лайк
   * @param id
   */
  async like({}, id): Promise<boolean> {
    await videoApi.like(id);

    return resolver(true);
  },

  /**
   * Удалит лайк
   * @param id
   */
  async removeLike({}, id): Promise<boolean> {
    await videoApi.removeLike(id);

    return resolver(true);
  },

  /**
   * Сбросит флаг is new
   * @param id
   */
  async markAsRead({}, id): Promise<boolean> {
    await videoApi.markAsRead(id);

    return resolver(true);
  },

  /**
   * Отметит как просмотренный
   * @param id
   */
  async markAsViewed({}, id): Promise<boolean> {
    await videoApi.markAsViewed(id);

    return resolver(true);
  },

  async getVideoComments({}, { video, paginationDirection }) {
    if (paginationDirection === PaginationDirection.DEFAULT) {
      const [items, offsetPagination] = await commentApi.getComments(
        ApiName.VIDEO,
        video.id,
        null,
        null,
        null,
      );
      video.setCommentsPagination(offsetPagination, paginationDirection);
      video.setCommentsList(items, offsetPagination.total);

      return;
    }

    const limit = video.commentsPagination.getLimit(paginationDirection);

    const offset = video.commentsPagination.getOffset(paginationDirection);

    const comments = video.commentsList;

    const [items, offsetPagination] = await commentApi.getComments(
      ApiName.VIDEO,
      video.id,
      null,
      offset,
      limit,
    );
    video.setCommentsPagination(offsetPagination, paginationDirection);

    if (paginationDirection === PaginationDirection.DOWN) {
      comments.push(...items);
    }

    if (paginationDirection === PaginationDirection.UP) {
      comments.unshift(...items);
    }

    video.setCommentsList(comments, offsetPagination.total);
  },

  async sendVideoComment({}, { id, comment, parentId }) {
    return commentApi.sendComment(ApiName.VIDEO, id, comment, parentId);
  },

  async clearVideoCommentsIsNew({}, { id, ids }) {
    if (!ids || ids.length === 0) {
      return;
    }

    await commentApi.clearCommentsIsNew(ApiName.VIDEO, id, ids);
  },
};
