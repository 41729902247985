import CorrectName, { getCorrectNameByApiCode } from '@/entities/common/CorrectName';
import ImageSource, { createImageFromString } from '@/entities/common/sources/ImageSource';
import { Location } from 'vue-router';
import CustomRoute from '@/entities/common/CustomRoute';
import { routeTo } from '@/providers/routerLinkProvider';

export default class MenuItem {
  readonly moduleId: number;
  readonly moduleCode: CorrectName;

  readonly name: string;

  readonly icon?: ImageSource;
  readonly link?: string;
  private count: number = 0;
  readonly order: number;

  constructor(payload: any) {
    this.moduleId = parseInt(payload.id, 10);
    this.moduleCode = getCorrectNameByApiCode(payload.type);

    this.name = payload.title;

    if (payload.icon && payload.icon.src_svg) {
      this.icon = createImageFromString(payload.icon.src_svg);
    }

    if (payload.link) {
      this.link = payload.link;
    }

    this.order = parseInt(payload.order, 10);
  }

  clearCount(): void {
    this.count = 0;
  }

  incrementCount(delta: number): void {
    this.count += delta;
  }

  getCount(): number {
    return this.count;
  }

  getRouterLink(): Location {
    switch (this.moduleCode) {
      case CorrectName.DASHBOARD:
        return routeTo(CustomRoute.TO_DASHBOARD_HOME);
      case CorrectName.EVALUATIONS:
        return routeTo(CustomRoute.TO_TESTS);
      case CorrectName.COMPETENCY:
        return routeTo(CustomRoute.TO_COMPETENCIES);
      case CorrectName.RATING:
        return routeTo(CustomRoute.TO_RATING);
      case CorrectName.MY_RESULTS:
        return routeTo(CustomRoute.TO_MY_RESULTS_LEARNING);
      case CorrectName.LEARNING_MATERIALS:
        return {
          name: CustomRoute.TO_LEARNING_MATERIALS,
          params: {
            moduleId: this.moduleId.toString(),
          },
        };
      case CorrectName.VIDEO:
        return routeTo(CustomRoute.TO_VIDEOS);
      case CorrectName.EVENT:
        return routeTo(CustomRoute.TO_EVENTS_SCHEDULED);
      case CorrectName.TEAM:
        return routeTo(CustomRoute.TO_MY_TEAM);
      case CorrectName.GIFT_STORE:
        return routeTo(CustomRoute.TO_GIFT_STORE);
      case CorrectName.TASK:
        return routeTo(CustomRoute.TO_TASKS);
      case CorrectName.INFORMATION:
        return routeTo(CustomRoute.TO_INFORMATION);
      case CorrectName.MESSAGES:
        return routeTo(CustomRoute.TO_MESSAGES);
      default:
        return {};
    }
  }
}
