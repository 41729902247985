import ImageSource, { createImageFromString } from '@/entities/common/sources/ImageSource';

export default class UserCommentOwner {
  id: number;
  image?: ImageSource;
  name: string;
  type: string;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.name = payload.name;
    this.type = payload.type;

    if (payload.avatar) {
      this.image = createImageFromString(payload.avatar.url);
    }
  }
}
