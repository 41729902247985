export default function parseLinkFromMd(linkString: string) {
  const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/;

  const match = linkString.match(linkRegex);

  if (!match || match.length < 3) {
    throw new Error('Неверный формат строки ссылки');
  }

  const name = match[1].trim();

  const url = match[2].trim();

  return {
    name,
    url,
  };
}
