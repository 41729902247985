<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
  >
    <g clip-path="url(#clip0_14322_197309)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.06982 10.0723C2.06982 6.69066 4.81119 3.9493 8.19283 3.9493C11.5745 3.9493 14.3158 6.69066 14.3158 10.0723C14.3158 13.454 11.5745 16.1953 8.19283 16.1953C4.81119 16.1953 2.06982 13.454 2.06982 10.0723ZM8.23907 10.9077C7.77793 10.9077 7.4041 10.5339 7.40411 10.0728L7.40412 6.17631C7.40412 5.71517 7.77794 5.34135 8.23907 5.34135C8.70021 5.34135 9.07403 5.71517 9.07403 6.17631V10.0728C9.07403 10.5339 8.70021 10.9077 8.23907 10.9077Z"
        :fill="color"
      />
      <path
        d="M5.69313 2.05763C5.69313 1.5842 6.07692 1.20041 6.55035 1.20041H9.83591C10.3093 1.20041 10.6931 1.5842 10.6931 2.05763C10.6931 2.53106 10.3093 2.91485 9.83591 2.91485H6.55035C6.07692 2.91485 5.69313 2.53106 5.69313 2.05763Z"
        :fill="color"
      />
    </g>
    <defs>
      <clipPath id="clip0_14322_197309">
        <rect
          width="16.2996"
          height="16.2996"
          fill="white"
          transform="translate(0.0432129 0.700409)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'time-out',

  props: {
    color: {
      type: String,
      default: 'white',
    },
  },
};
</script>
