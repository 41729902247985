import isNaN from 'lodash/isNaN';
import moment from 'moment';

/**
 * Вернет Date, если на вход получим корректный timestamp
 * @param {Number|String} timestamp
 * @return {null|Date}
 */
export default function dateProvider(timestamp) {
  // eslint-disable-next-line no-param-reassign
  timestamp = parseInt(timestamp, 10);

  if (isNaN(timestamp)) {
    return null;
  }

  if (timestamp === 0) {
    return null;
  }

  return new Date(timestamp * 1000);
}

/**
 * Вернет текущую дату
 * @returns {Date}
 */
export function getCurrentTime() {
  return new Date();
}

/**
 * Вернет текущую дату в timestamp
 * @returns {number}
 */
export function getCurrentTimestamp() {
  return Math.round(new Date().getTime() / 1000);
}

/**
 * Вернет true, если две даты равны
 * @param {Date} firstDate
 * @param {Date} secondDate
 * @return {boolean}
 */
export function isSameDay(firstDate, secondDate) {
  return (
    firstDate.getFullYear() === secondDate.getFullYear() &&
    firstDate.getMonth() === secondDate.getMonth() &&
    firstDate.getDate() === secondDate.getDate()
  );
}

/**
 * Вернет true, если дата начала мероприятия - вчера
 * @param {Date} comparedDate
 * @return {boolean}
 */
export function isYesterday(comparedDate) {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  return isSameDay(yesterday, comparedDate);
}

/**
 * Вернет true, если дата начала мероприятия - сегодня
 * @param {Date} comparedDate
 * @return {boolean}
 */
export function isToday(comparedDate) {
  const today = new Date();

  return isSameDay(today, comparedDate);
}

/**
 * Вернет true, если дата начала мероприятия - завтра
 * @param {Date} comparedDate
 * @return {boolean}
 */
export function isTomorrow(comparedDate) {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  return isSameDay(tomorrow, comparedDate);
}

export function localeDate(date) {
  return moment(date).format('ll').replace(' г.', '');
}

export function localeDateFromTimestamp(date) {
  return localeDate(new Date(date * 1000));
}

/**
 * Дополнит нулем
 * @param number
 * @return {string}
 */
function pad(number) {
  let r = String(number);

  if (r.length === 1) {
    r = `0${r}`;
  }

  return r;
}

function getTimezone(date) {
  const timezone = -date.getTimezoneOffset() / 60;

  const hours = pad(Math.trunc(timezone));

  const minutes = pad(60 * (timezone - hours));

  return `${hours}:${minutes}`;
}

/**
 * Сделает ISO дату
 * Например, 19980118T230000
 * @param {Date} date
 * @return {string}
 */
export function toICSDate(date) {
  return (
    `${date.getUTCFullYear()}${pad(date.getUTCMonth() + 1)}${pad(date.getUTCDate())}` +
    `T${pad(date.getUTCHours())}${pad(date.getUTCMinutes())}${pad(date.getUTCSeconds())}`
  );
}

/**
 * Сделает ISO дату Z
 * Например, 19980118T230000Z
 * @param {Date} date
 * @return {string}
 */
export function toICSDateWithZ(date) {
  return `${toICSDate(date)}Z`;
}

/**
 * Сделает ISO 8601
 * Например, 2022-01-12T18:00:00+00:00
 * @param {Date} date
 * @return {string}
 */
export function toISO8601Date(date) {
  return (
    `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}` +
    `T${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}+${getTimezone(
      date,
    )}`
  );
}
