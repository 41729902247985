import { ActionTree } from 'vuex';
import { each, find } from 'lodash';
import interviewsApi from '@/services/interviews';
import { compareUpdatableCollections } from '@/entities/common/testing/updates/IUpdatable';
import { InterviewsState } from './types';
import { RootState } from '../../types';

export const actions: ActionTree<InterviewsState, RootState> = {
  async syncMaterials({ state, commit }) {
    const loadedIdList = [];

    if (state.materials.length > 0) {
      const [levelsUpdateList, materialsUpdateList] = await interviewsApi.getUpdates();

      const [expiredLevels, unpairedLevels] = compareUpdatableCollections(
        levelsUpdateList,
        state.levels,
      );

      const [expiredMaterials, unpairedMaterials] = compareUpdatableCollections(
        materialsUpdateList,
        state.materials,
      );

      if (
        expiredLevels.length === 0 &&
        unpairedLevels.length === 0 &&
        expiredMaterials.length === 0 &&
        unpairedMaterials.length === 0
      ) {
        return;
      }

      if (expiredMaterials.length > 0) {
        loadedIdList.push(...expiredMaterials);
      }
    }

    if (loadedIdList.length) {
      const statistics = await interviewsApi.getStatistic(loadedIdList);

      const materials = await interviewsApi.getMaterialsByList(loadedIdList);

      each(materials, material => {
        const statistic = find(statistics, o => o.interviewId === material.id);

        if (statistic) {
          material.setStatistic(statistic);
        }
      });

      commit('setMaterials', materials);
    } else {
      const statistics = await interviewsApi.getStatistic([]);

      const [levels, materials] = await interviewsApi.getMaterials();

      each(materials, material => {
        const statistic = find(statistics, o => o.interviewId === material.id);

        if (statistic) {
          material.setStatistic(statistic);
        }
      });

      commit('setLevels', levels);
      commit('setMaterials', materials);
    }
  },

  async getById({ state, dispatch }, id) {
    const material = find(state.materials, o => o.id === id);

    if (material) {
      return material;
    }

    await dispatch('syncMaterials');

    return find(state.materials, o => o.id === id);
  },

  async getNextMaterial({ state }, material) {
    const materials = state.materials.filter(
      o => o.levelId === material.levelId && o.id !== material.id,
    );

    const hasNextMaterial = materials.find(o => o.isNew || o.isAssigned);

    const sectionIndex = state.levels.findIndex(x => x.id === material.levelId);

    if (hasNextMaterial) {
      return hasNextMaterial;
    }

    if (sectionIndex < state.levels.length - 1) {
      const levelId = state.levels[sectionIndex + 1].id;

      return state.materials.find(
        o => o.id !== material.id && o.levelId === levelId && (o.isNew || o.isAssigned),
      );
    }

    return null;
  },

  async save({ state }, { id, payload }) {
    const material = find(state.materials, o => o.id === id);

    if (!material) {
      return;
    }

    await interviewsApi.sendStatistic(material.getSaving(payload));
    material.markAsPassed();
  },

  async clearIsNew({}, id) {
    await interviewsApi.clearIsNew(id);
  },
};
