import ImageSource, { createImageSmSource } from '@/entities/common/sources/ImageSource';
import IAnswerOption from '@/entities/common/testing/answer-oprions/IAnswerOption';
import { has } from 'lodash';

export default class SelectImageAnswerOption implements IAnswerOption {
  answerId: number;
  image?: ImageSource;
  isCorrect: boolean;
  isChecked?: boolean;

  isSuccess: boolean = false;
  isFailed: boolean = false;

  constructor(payload: any) {
    this.answerId = parseInt(payload.id, 10);
    this.image = createImageSmSource(payload.answer);
    this.isCorrect = !!payload.correct;

    if (has(payload, 'checked')) {
      this.isChecked = parseInt(payload.checked, 10) === 1;
    }
  }

  setStatuses() {
    this.isFailed = !!this.isChecked && !this.isCorrect;
    this.isSuccess = this.isCorrect;
  }
}
