import { find } from 'lodash';
// @ts-ignore
import sha1 from 'crypto-js/sha1';
// @ts-ignore
import hex from 'crypto-js/enc-hex';

type SendEventContentParamType = {
  content_type: string;
  content_name: string;
  parent_object_name?: string;
  trajectory_name?: string;
};

/**
 * Отправка события в аналитику
 * @param firebase
 * @param eventName название метрики
 */
export function sendEvent(firebase: any, eventName: string) {
  firebase.logEvent(eventName);
}

/**
 * Отправка события в аналитику о новой открытой (второй и более) вкладке
 * @param firebase
 * @param count
 */
export function sendMultiTabsAlert(firebase: any, count: number) {
  firebase.logEvent('web_duplicate_tabs_alert', { tabs_count: count });
}

/**
 * Отправка события в аналитику о том, что в тесте нет вопросов
 * @param firebase
 * @param materialId
 * @param materialName
 * @param date
 * @param pageName
 * @param attempt
 */
export function sendNoQuestionsAlert(
  firebase: any,
  materialId: number,
  materialName: string,
  date: Date | string,
  pageName: string,
  attempt: number,
) {
  firebase.logEvent('web_no_questions_alert', {
    content_name: `${attempt} attempt - ${date} ${pageName} [${materialId}] ${materialName}`,
  });
}

/**
 * Отправка события в аналитику
 * @param firebase
 * @param eventAction значение параметра
 * @param materialId id материала
 * @param materialName название материала
 * @param {Object} parent
 * @param {number} parent.learningProgramId id программы обучения
 * @param {string} parent.learningProgramName название программы обучения
 * @param {number} parent.learningTrackId id траектории обучения
 * @param {string} parent.learningTrackName название траектории обучения
 */
export function sendEventContent(
  firebase: any,
  eventAction: string,
  materialId: number,
  materialName: string,
  parent?: {
    learningProgramId?: number;
    learningProgramName?: string;
    learningTrackId?: number;
    learningTrackName?: string;
  },
) {
  const params: SendEventContentParamType = {
    content_type: eventAction,
    content_name: `[${materialId}] ${materialName}`,
  };

  if (parent) {
    if (parent.learningProgramId && parent.learningProgramName) {
      params.parent_object_name = `[${parent.learningProgramId}] ${parent.learningProgramName}`;
    }

    if (parent.learningTrackId && parent.learningTrackName) {
      params.trajectory_name = `[${parent.learningTrackId}] ${parent.learningTrackName}`;
    }
  }

  firebase.logEvent('started_learning_content', params);
}

/**
 * Отправка события в аналитику
 * @param firebase
 * @param learningProgramId id программы обучения
 * @param learningProgramName название программы обучения
 */
export function sendLearningProgram(
  firebase: any,
  learningProgramId: number,
  learningProgramName: string,
) {
  // @ts-ignore
  let gaLearningProgramIds = JSON.parse(localStorage.getItem('ga_learning_program'));

  if (gaLearningProgramIds && find(gaLearningProgramIds, o => o === learningProgramId)) {
    return;
  }
  gaLearningProgramIds = !gaLearningProgramIds ? [] : gaLearningProgramIds;
  gaLearningProgramIds.push(learningProgramId);
  localStorage.setItem('ga_learning_program', JSON.stringify(gaLearningProgramIds));

  firebase.logEvent('started_learning_content', {
    content_type: 'lj_program',
    content_name: `[${learningProgramId}] ${learningProgramName}`,
    parent_object_name: `[${learningProgramId}] ${learningProgramName}`,
  });
}

export function getDomain(): string {
  let domain: string = '';

  if (window.location.hostname === 'localhost') {
    domain = 'dev-eq.ru';
  } else {
    // eslint-disable-next-line no-useless-escape
    [, , domain] = process.env.VUE_APP_API_PATH.match(/(api.)?([^\/]*)/);
  }

  return domain;
}

export function getHashDomain(): string {
  const domain = `api.${getDomain()}`;

  const hash = sha1(domain);

  const stringHash = hex.stringify(hash);

  return stringHash.substring(0, 6);
}
