import ImageSource, { createImageMdSource } from '@/entities/common/sources/ImageSource';

export default class UserInfo {
  id: number;
  name: string;
  points: number;
  image: ImageSource | undefined;
  updatedAt: number;
  order: number;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.name = payload.name;
    this.points = parseInt(payload.points, 10);
    this.image = createImageMdSource(payload.image);
    this.updatedAt = parseInt(payload.updated_at, 10);
    this.order = parseInt(payload.order, 10);
  }
}
