import LearningItem, { LearningItemScheme } from './LearningItem';
import LearningItemStatistic, { LearningItemStatisticScheme } from './LearningItemStatistic';

export function createLearningItemsList(
  items: Array<LearningItemScheme>,
  statistics: Array<LearningItemStatisticScheme>,
): Array<LearningItem> {
  const learningItems: LearningItem[] = [];

  items.forEach((item: LearningItemScheme) => {
    const learningItem = new LearningItem(item);

    const learningItemStatistic = statistics.find(
      statistic => statistic.learning_item_id === learningItem.id,
    );

    if (learningItemStatistic) {
      learningItem.setStatistic(new LearningItemStatistic(learningItemStatistic));
    }

    learningItems.push(learningItem);
  });

  return learningItems;
}
