export class FormDataQuestions {
  constructor({ id, name, hint, sortIndex, value, type }) {
    this.id = id;
    this.name = name;
    this.hint = hint;
    this.sortIndex = sortIndex;
    this.value = value;
    this.type = type;
  }
}

export function createFormDataQuestions(data) {
  return Object.freeze(
    new FormDataQuestions({
      id: data.id,
      name: data.name,
      hint: data.hint,
      sortIndex: data.order,
      value: data.value,
      type: data.type,
    }),
  );
}
