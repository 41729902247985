import parseLinkFromMd from '@/providers/parseLinkFromMd';

interface BannerImage {
  url: string;
  size: string;
}

interface SlideImage {
  desktop: BannerImage;
  mobile: BannerImage;
}

interface Deeplink {
  url: string;
  name: string;
}

interface Colors {
  title: string;
  subtitle: string;
  buttonBackground: string;
  buttonText: string;
  backgroundDimming: string;
}

const DEFAULT_COLOR = '#FFFFFF';

const DEFAULT_DIMMING = '#000000';

export interface SlideInterface {
  entity_id: number;
  order: number;
  title: string;
  subtitle: string;
  image: SlideImage;
  deeplink: string;
  has_button: 0 | 1;
  title_color: string;
  subtitle_color: string;
  button_background_color: string;
  button_text_color: string;
  background_dimming_color: string;
  background_dimming_opacity: number;
}

export class Slide {
  id: number;
  order: number;
  title: string | null;
  subtitle: string | null;
  image: SlideImage;
  deeplink: Deeplink | null = null;
  hasButton: boolean;
  colors: Colors;
  backgroundDimmingOpacity: number;

  constructor(payload: SlideInterface) {
    this.id = payload.entity_id;
    this.order = payload.order;
    this.title = payload.title || null;
    this.subtitle = payload.subtitle || null;
    this.image = {
      desktop: payload.image?.desktop,
      mobile: payload.image?.mobile,
    };

    if (payload.deeplink) {
      this.deeplink = parseLinkFromMd(payload.deeplink);
    }

    this.hasButton = payload.has_button === 1;
    this.colors = {
      title: `#${payload.title_color}` || DEFAULT_COLOR,
      subtitle: `#${payload.subtitle_color}` || DEFAULT_COLOR,
      buttonBackground: `#${payload.button_background_color}` || DEFAULT_COLOR,
      buttonText: `#${payload.button_text_color}` || DEFAULT_COLOR,
      backgroundDimming: `#${payload.background_dimming_color}` || DEFAULT_DIMMING,
    };
    this.backgroundDimmingOpacity = payload.background_dimming_opacity || 0;
  }
}
