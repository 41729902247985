import { each, has } from 'lodash';
import BaseWidget from '@/entities/modules/dashboard/BaseWidget';
import { LearningProgramMaterial } from '@/entities/modules/dashboard/LearningProgramMaterial';

export default class LearningsProgramsWidget extends BaseWidget {
  learningPrograms: Object[];

  constructor(payload: any) {
    super(payload);

    this.learningPrograms = [];
    const data = has(payload, 'data.learning_programs')
      ? payload.data.learning_programs
      : payload.data;
    each(data, program => {
      this.learningPrograms.push(new LearningProgramMaterial(program).getWidget());
    });
  }
}
