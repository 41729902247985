import axios from 'axios';
import { each } from 'lodash';
import Level from '@/entities/modules/interviews/Level';
import Interview from '@/entities/modules/interviews/Interview';
import Statistic from '@/entities/modules/interviews/Statistic';
import UpdateState from '@/entities/common/testing/updates/UpdateState';

export default {
  async getUpdates(): Promise<[UpdateState[], UpdateState[]]> {
    const payload = await axios.get('/evaluations/interviews/updates');

    const levelsUpdateList: UpdateState[] = [];

    const interviewsUpdateList: UpdateState[] = [];

    each(payload.data.success.levels, o => levelsUpdateList.push(new UpdateState(o)));
    each(payload.data.success.interviews, o => interviewsUpdateList.push(new UpdateState(o)));

    return [levelsUpdateList, interviewsUpdateList];
  },

  async getStatistic(needed: number[]): Promise<Statistic[]> {
    let payload: any;

    if (needed.length === 0) {
      payload = await axios.get('/evaluations/interviews/statistic');
    } else {
      payload = await axios.post('/evaluations/interviews/statistic', {
        items: needed,
      });
    }

    const statistics: Statistic[] = [];

    each(payload.data.success.interviews, o => statistics.push(new Statistic(o)));

    return statistics;
  },

  async getMaterials(): Promise<[Level[], Interview[]]> {
    const payload = await axios.get('/evaluations/interviews');

    const levels: Level[] = [];

    const interviews: Interview[] = [];

    each(payload.data.success.levels, level => {
      levels.push(new Level(level));

      each(level.interviews, interview => {
        const pretender = new Interview(interview);

        if (pretender.questions.length === 0) {
          return;
        }

        let hasBrokenQuestion = false;
        each(pretender.questions, question => {
          if (question.options.length === 0 && !question.hasOpenQuestion) {
            hasBrokenQuestion = true;
          }
        });

        if (hasBrokenQuestion) {
          return;
        }

        interviews.push(pretender);
      });
    });

    return [levels, interviews];
  },

  async getMaterialsByList(needed: number[]): Promise<Interview[]> {
    const payload = await axios.post('/evaluations/interviews', {
      interviews: needed,
    });

    const interviews: Interview[] = [];

    each(payload.data.success.interviews, interview => {
      const pretender = new Interview(interview);

      if (pretender.questions.length === 0) {
        return;
      }

      let hasBrokenQuestion = false;
      each(pretender.questions, question => {
        if (question.options.length === 0 && !question.hasOpenQuestion) {
          hasBrokenQuestion = true;
        }
      });

      if (hasBrokenQuestion) {
        return;
      }

      interviews.push(pretender);
    });

    return interviews;
  },

  async sendStatistic(payload: any) {
    await axios.post('/evaluations/interviews/update-statistic', { interviews: [payload] });
  },

  async clearIsNew(id: number) {
    await axios.post('/evaluations/interviews/clear-is-new', {
      interviews: [id],
    });
  },
};
