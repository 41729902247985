import isNaN from 'lodash/isNaN';

/**
 * Вернет Date, если на вход получим корректный timestamp
 * @param timestamp
 */
export default function dateProvider(timestamp: any): Date | undefined {
  const parseTimestamp = parseInt(timestamp, 10);

  if (isNaN(parseTimestamp)) {
    return;
  }

  if (parseTimestamp === 0) {
    return;
  }

  return new Date(parseTimestamp * 1000);
}

export function getCurrentTimestamp(): number {
  return Math.floor(new Date().getTime() / 1000);
}

/**
 * Преобразует секунды в HH:MM:SS или MM:SS
 * @param totalSeconds
 */
export function secondsToHHMMSS(totalSeconds: any): string {
  let parseTotalSeconds = parseInt(totalSeconds, 10);

  if (isNaN(parseTotalSeconds)) {
    return '00:00';
  }

  const hours = Math.floor(parseTotalSeconds / 3600);
  parseTotalSeconds %= 3600;

  const minutes = Math.floor(parseTotalSeconds / 60);

  const seconds = parseTotalSeconds % 60;

  if (hours > 0) {
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(
      seconds,
    ).padStart(2, '0')}`;
  }

  return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
}

/**
 * Преобразует секунды в HH:MM:SS
 * @param totalSeconds
 */
export function secondsToFullHHMMSS(totalSeconds: any): string {
  let parseTotalSeconds = parseInt(totalSeconds, 10);

  if (isNaN(parseTotalSeconds)) {
    return '00:00';
  }

  const hours = Math.floor(parseTotalSeconds / 3600);
  parseTotalSeconds %= 3600;

  const minutes = Math.floor(parseTotalSeconds / 60);

  const seconds = parseTotalSeconds % 60;

  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(
    seconds,
  ).padStart(2, '0')}`;
}

/**
 * Функция возвращает минуты или секунды, если их меньше 60
 * @param totalSeconds
 */
export function getSecondsOrMinutes(totalSeconds: any): number {
  const parseTotalSeconds = parseInt(totalSeconds, 10);

  if (isNaN(parseTotalSeconds)) {
    return 0;
  }

  return parseTotalSeconds < 60 ? parseTotalSeconds : Math.trunc(parseTotalSeconds / 60);
}

export function getTimerInSeconds(second: number) {
  if (!second) {
    return 0;
  }

  const seconds = second % 60;

  return String(seconds).padStart(2, '0');
}

export function getTimerInMinutes(second: number) {
  if (!second) {
    return 0;
  }

  const minutes = second / 60;

  if (!minutes) {
    return '00';
  }

  return String(Math.floor(minutes % 60)).padStart(2, '0');
}

export function getTimerInHours(second: number) {
  if (!second) {
    return 0;
  }

  const hours = second / 60 / 60;

  if (hours < 1) {
    return 0;
  }

  return String(Math.floor(hours % 24)).padStart(2, '0');
}

export function getTimerInDays(second: number) {
  if (!second) {
    return 0;
  }

  const days = Math.floor(second / 86400);

  if (!days) {
    return 0;
  }

  return String(days).padStart(2, '0');
}
