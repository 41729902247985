import { Module } from 'vuex';
import { RootState } from '../../types';
import { DashboardState } from './types';
import { getters } from './getters';
// eslint-disable-next-line import/no-cycle
import { actions } from './actions';
import { mutations } from './mutations';

export const state: DashboardState = {
  banner: null,
  nextMaterial: null,
  personalData: null,
  rating: null,
  widgets: [],
};

const namespaced: boolean = true;

export const dashboard: Module<DashboardState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
