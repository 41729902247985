import axios from 'axios';
import each from 'lodash/each';
import Event from '@/entities/modules/events/Event';
import Plan from '@/entities/modules/events/Plan';
import OffsetPagination from '@/entities/common/OffsetPagination';
import Http404Exception from '@/entities/common/exceptions/Http404Exception';
import ZoomConfiguration from '@/entities/modules/events/ZoomConfiguration';

/**
 * Вернет мероприятие с датой или план
 * @param payload
 */
function getEventOrPlan(payload: any): Event | Plan {
  return parseInt(payload.without_date, 10) === 1 ? new Plan(payload) : new Event(payload);
}

export default {
  async loadEvents(
    limit: number | null,
    offset: number | null,
    filter: number | null,
  ): Promise<[Event[], OffsetPagination]> {
    const response = await axios.get(
      `/events?scope=events${limit !== null ? `&limit=${limit}` : ''}${
        offset !== null ? `&offset=${offset}` : ''
      }${filter !== null ? `&filters[]=${filter}` : ''}`,
    );

    const events: Event[] = [];

    each(response.data.success.events, event => events.push(new Event(event)));

    return [events, new OffsetPagination(response.data.success.meta.pagination)];
  },

  async loadPlans(
    offset: number | null,
    filter: number | null,
  ): Promise<[Plan[], OffsetPagination]> {
    const response = await axios.get(
      `/events?scope=plans${offset !== null ? `&offset=${offset}` : ''}${
        filter !== null ? `&filters[]=${filter}` : ''
      }`,
    );

    const plans: Plan[] = [];

    each(response.data.success.events, event => plans.push(new Plan(event)));

    return [plans, new OffsetPagination(response.data.success.meta.pagination)];
  },

  async getById(id: number): Promise<Event | Plan> {
    const response = await axios.post('/events', { events: [id] });

    if (response.data.success.events.length === 0) {
      throw new Http404Exception();
    }

    return getEventOrPlan(response.data.success.events[0]);
  },

  async getZoomConfiguration(id: number) {
    const response = await axios.get(`/events/${id}/zoom/configuration`, {
      headers: { globalErrorHandler: false },
    });

    return new ZoomConfiguration(response.data.success.configuration);
  },

  async joinEvent(id: number): Promise<Event | Plan> {
    const response = await axios.post(`/events/${id}/join`);

    return getEventOrPlan(response.data.success.event);
  },

  async leaveEvent(id: number): Promise<Event | Plan> {
    const response = await axios.post(`/events/${id}/leave`);

    return getEventOrPlan(response.data.success.event);
  },

  async clearIsNew(id: number): Promise<void> {
    await axios.post(
      '/events/clear-is-new',
      { events: [id] },
      { headers: { globalErrorHandler: false } },
    );
  },
};
