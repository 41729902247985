// @ts-ignore
import createDate from '@/providers/js/dateProvider';

export default class KpiTransaction {
  id: number;
  kpiId: number;
  kpiName: string;
  amount: number;
  date: string;
  comment: string;
  unit: string;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.kpiId = parseInt(payload.kpi_id, 10);
    this.kpiName = payload.kpi_name;
    this.amount = parseFloat(payload.amount);
    this.date = createDate(payload.date);
    this.comment = payload.comment;
    this.unit = payload.unit;
  }
}
