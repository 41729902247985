import { each } from 'lodash';
import BaseWidget from '@/entities/modules/dashboard/BaseWidget';
import { TestMaterial } from '@/entities/modules/dashboard/TestMaterial';

export default class TestsWidget extends BaseWidget {
  tests: Object[];

  constructor(payload: any) {
    super(payload);
    this.tests = [];
    each(payload.data.tests, test => {
      this.tests.push(new TestMaterial(test).getWidget());
    });
  }
}
