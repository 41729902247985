import ImageSource, { createImageMdSource } from '@/entities/common/sources/ImageSource';

export default class Category {
  id: number;
  order: number;
  name: string;
  description: string;
  image?: ImageSource;
  hasHtml: boolean;
  html: string | null;
  isNew: boolean;
  isFavorite: boolean | null;
  newMaterialsCount: number;
  viewDate?: number;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.order = parseInt(payload.order, 10);
    this.name = payload.name;
    this.description = payload.description;
    this.image = createImageMdSource(payload.image);
    this.hasHtml = payload?.is_html ? parseInt(payload.is_html, 10) === 1 : false;
    this.html = payload?.html?.desktop.url ? payload.html.desktop.url : null;
    this.isNew = parseInt(payload.new_materials_count, 10) === 1;
    this.newMaterialsCount = parseInt(payload.new_materials_count, 10);
    this.isFavorite = this.hasHtml ? parseInt(payload.is_favorite, 10) === 1 : null;
  }

  setFavorite(status: boolean): void {
    this.isFavorite = status;
  }

  markAsViewed(currentTimestamp: number): void {
    this.viewDate = currentTimestamp;
  }
}
