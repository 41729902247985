<template>
  <div
    class="app-badge"
    :class="classes"
  >
    <div
      v-if="$slots['left-icon'] && !isOnlyIcon"
      class="app-badge__left-icon"
    >
      <slot name="left-icon"></slot>
    </div>
    <span
      v-if="$slots.default"
      :class="isOnlyIcon ? 'app-badge__icon' : 'app-badge__label'"
    >
      <slot></slot>
    </span>
    <div
      v-if="$slots['right-icon'] && !isOnlyIcon"
      class="app-badge__right-icon"
    >
      <slot name="right-icon"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app-badge',

  props: {
    badgeStyle: {
      type: String,
      default: 'filled',
      validate: val => ['filled', 'tinted', 'plain', 'outline'].includes(val),
    },
    size: {
      type: String,
      default: 'md',
      validate: val => ['md', 'sm'].includes(val),
    },
    color: {
      type: String,
      default: 'primary',
      validate: val => ['primary', 'dark-gray', 'gray', 'success', 'error', 'orange'].includes(val),
    },
    isOnlyIcon: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classes() {
      return [
        `app-badge--${this.badgeStyle}`,
        `app-badge--size-${this.size}`,
        `app-badge--color-${this.color}`,
        this.isOnlyIcon ? 'app-badge--icon' : '',
      ];
    },
  },
};
</script>

<style lang="scss">
.app-badge {
  border-radius: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  max-height: 24px;
  gap: 4px;

  &.app-tooltip__outer {
    display: flex;
  }

  &__left-icon,
  &__right-icon {
    align-items: center;
    display: flex;

    svg {
      height: 16px;
      margin: 0;
      width: 16px;
    }
  }

  &--size-sm {
    @include assets-typography-button-small;
    //@include assets-typography-label;
    padding: 2px 6px;

    &.app-badge {
      &--icon {
        padding: 2px;
      }
    }
  }

  &--size-md {
    @extend .app-badge--size-sm;

    @media (min-width: $screen-md) {
      @include assets-typography-button-small;
      padding: 4px 6px;

      &.app-badge {
        &--icon {
          padding: 4px;
        }
      }
    }
  }

  &--filled {
    &.app-badge {
      &--color-primary {
        // primary-500
        background-color: var(--primary);
        color: var(--surfaceWhite);
      }

      &--color-dark-gray {
        // neutral-500
        background-color: var(--neutral500);
        color: var(--surfaceWhite);
      }

      &--color-gray {
        // neutral-100
        background-color: var(--neutral100);
        // neutral-500
        color: var(--neutral500);
      }

      &--color-success {
        // success-500
        background-color: var(--success);
        color: var(--surfaceWhite);
      }

      &--color-error {
        // error-500
        background-color: var(--error);
        color: var(--surfaceWhite);
      }

      &--color-orange {
        // secondary-500
        background-color: var(--info);
        color: var(--surfaceWhite);
      }
    }
  }

  &--tinted {
    &.app-badge {
      &--color-primary {
        // primary-50
        background-color: #f2fbfb;
        // primary-500
        color: var(--primary);
      }

      &--color-dark-gray {
        // neutral-300
        background-color: var(--neutral300);
        // neutral-500
        color: var(--neutral500);
      }

      &--color-gray {
        // neutral-100
        background-color: var(--neutral100);
        // neutral-500
        color: var(--neutral500);
      }

      &--color-success {
        // success-50
        background-color: #f4fcf7;
        // success-500
        color: #21c45d;
      }

      &--color-error {
        // error-50
        background-color: #fef6f6;
        // error-500
        color: #ef4343;
      }

      &--color-orange {
        // secondary-50
        background-color: #fffaf3;
        // secondary-500
        color: #f59f0a;
      }
    }
  }

  &--plain {
    &.app-badge {
      &--color-primary {
        background-color: transparent;
        // primary-500
        color: var(--primary);
      }

      &--color-dark-gray {
        background-color: transparent;
        // neutral-500
        color: var(--neutral500);
      }

      &--color-gray {
        background-color: transparent;
        // neutral-500
        color: var(--neutral500);
      }

      &--color-success {
        background-color: transparent;
        // success-500
        color: var(--success);
      }

      &--color-error {
        background-color: transparent;
        // error-500
        color: var(--error);
      }

      &--color-orange {
        background-color: transparent;
        // secondary-500
        color: var(--info);
      }
    }
  }

  &--outline {
    //gray-200
    box-shadow: inset 0 0 0 1px #e5e7eb;

    &.app-badge {
      &--color-primary {
        // primary-500
        color: var(--primary);
        box-shadow: inset 0 0 0 1px var(--primary);
      }

      &--color-dark-gray {
        // neutral-500
        color: var(--surfaceHighEmphasis);
        box-shadow: inset 0 0 0 1px #e5e7eb;
      }

      &--color-gray {
        // neutral-100
        color: var(--neutral100);
        box-shadow: inset 0 0 0 1px var(--neutral500);
      }

      &--color-success {
        // success-500
        color: var(--success);
        box-shadow: inset 0 0 0 1px var(--success);
      }

      &--color-error {
        // error-500
        color: var(--error);
        box-shadow: inset 0 0 0 1px var(--error);
      }

      &--color-orange {
        // secondary-500
        color: var(--info);
        box-shadow: inset 0 0 0 1px var(--info);
      }
    }
  }
}
</style>
