import dateProvider from '@/providers/dateProvider';

export default class User {
  id: number;
  avatar: string | null;
  login: string;
  phone: string;
  email: string;
  lastActive: Date | undefined;
  companyId: number;
  name: string;

  constructor(payload: any) {
    this.id = payload.id;
    this.avatar = payload.avatar?.url;
    this.login = payload.login;
    this.phone = payload.phone;
    this.email = payload.email;
    this.lastActive = dateProvider(payload.last_active);
    this.companyId = payload.company_id;
    this.name = `${payload.last_name} ${payload.first_name}`;
  }
}
