/**
 * У нас в проекте одни названия, а у бекенда -- другие
 * Чтобы каждый раз не писать названия модулей, разделов или еще чего-то,
 * юзаем у себя эти красивые типы
 */

/* eslint-disable */

enum ApiName {
  DASHBOARD = 'dashboard',

  TEST = 'test',
  TEST_MODULE = 'tests',
  TEST_CHECKED_MODULE = 'test_checked',

  INTERVIEW = 'interview',
  INTERVIEW_MODULE = 'interviews',

  COMPETENCY = 'competency_test',
  COMPETENCY_MODULE = 'competency_tests',
  COMPETENCY_MODULE_2 = 'competencies',

  EVALUATIONS = 'evaluations',

  RATING = 'achievements',
  RATING_BADGES_MODULE = 'badges_assignments',

  MY_RESULTS = 'results',

  INFORMATION = 'info',
  INFORMATION_MODULE = 'infos',
  INFORMATION_CATEGORY = 'info_category',
  INFORMATION_CATEGORY_MODULE = 'info_categories',

  VIDEO = 'discover',
  VIDEO_MODULE = 'discovers',

  LEARNING_MATERIALS = 'learning_materials',

  LEARNING_ITEM_MODULE = 'learning_items',

  LEARNING_TRACK = 'learning_track',
  LEARNING_TRACK_MODULE = 'learning_tracks',

  LEARNING_PROGRAM = 'learning_program',
  LEARNING_PROGRAM_MODULE = 'learning_programs',
  LEARNING_PROGRAM_CHECKED_MODULE = 'learning_program_checked',

  EVENT = 'event',
  EVENT_MODULE = 'events',

  TEAM = 'team',
  MY_TEAM = 'my_team',

  GIFT_STORE = 'shop_product',
  GIFT_STORE_MODULE = 'shop',
  GIFT_STORE_ORDERS_MODULE = 'shop_orders',

  TASK = 'task',
  TASK_MODULE = 'tasks',
  TASK_ANSWER_MODULE = 'task_answer_statuses',

  EXTERNAL_LINK = 'company_links',

  MESSAGES = 'message',
  MESSAGES_MODULE = 'messages',

  MODULE_UNSUPPORTED = 'module-not-known',
  MATERIAL = 'material',
}

export function getApiNameByApiCode(code: string): ApiName {
  switch (code) {
    case 'dashboard':
      return ApiName.DASHBOARD;
    case 'test':
      return ApiName.TEST;
    case 'tests':
      return ApiName.TEST_MODULE;
    case 'test_checked':
      return ApiName.TEST_CHECKED_MODULE;
    case 'interview':
      return ApiName.INTERVIEW;
    case 'interviews':
      return ApiName.INTERVIEW_MODULE;
    case 'competency_test':
      return ApiName.COMPETENCY;
    case 'competency_tests':
      return ApiName.COMPETENCY_MODULE;
    case 'competencies':
      return ApiName.COMPETENCY_MODULE_2;
    case 'evaluations':
      return ApiName.EVALUATIONS;
    case 'achievements':
      return ApiName.RATING;
    case 'badges_assignments':
      return ApiName.RATING_BADGES_MODULE;
    case 'results':
      return ApiName.MY_RESULTS;
    case 'info':
      return ApiName.INFORMATION;
    case 'infos':
      return ApiName.INFORMATION_MODULE;
    case 'info_category':
      return ApiName.INFORMATION_CATEGORY;
    case 'info_categories':
      return ApiName.INFORMATION_CATEGORY_MODULE;
    case 'discover':
      return ApiName.VIDEO;
    case 'discovers':
      return ApiName.VIDEO_MODULE;
    case 'learning_materials':
      return ApiName.LEARNING_MATERIALS;
    case 'learning_items':
      return ApiName.LEARNING_ITEM_MODULE;
    case 'learning_track':
      return ApiName.LEARNING_TRACK;
    case 'learning_tracks':
      return ApiName.LEARNING_TRACK_MODULE;
    case 'learning_program':
      return ApiName.LEARNING_PROGRAM;
    case 'learning_programs':
      return ApiName.LEARNING_PROGRAM_MODULE;
    case 'learning_program_checked':
      return ApiName.LEARNING_PROGRAM_CHECKED_MODULE;
    case 'event':
      return ApiName.EVENT;
    case 'events':
      return ApiName.EVENT_MODULE;
    case 'my_team':
      return ApiName.MY_TEAM;
    case 'team':
      return ApiName.TEAM;
    case 'shop_product':
      return ApiName.GIFT_STORE;
    case 'shop':
      return ApiName.GIFT_STORE_MODULE;
    case 'shop_orders':
      return ApiName.GIFT_STORE_ORDERS_MODULE;
    case 'task':
      return ApiName.TASK;
    case 'tasks':
      return ApiName.TASK_MODULE;
    case 'task_answer_statuses':
      return ApiName.TASK_ANSWER_MODULE;
    case 'company_links':
      return ApiName.EXTERNAL_LINK;
    case 'messages':
      return ApiName.MESSAGES_MODULE;

    default:
      return ApiName.MODULE_UNSUPPORTED;
  }
}

export default ApiName;
