<template>
  <div
    class="app-like"
    :class="classes"
    @click.stop="$emit('toggleLike')"
  >
    <LikeBigSvg v-if="isBig" />
    <LikeSmallSvg
      v-else
      :is-error-color="isLiked"
      :is-surface-emphasis20="!isLiked"
    />
    <div class="app-like__likes">{{ likesCount.toLocaleString() }}</div>
  </div>
</template>

<script>
import LikeBigSvg from '@/components/svg/icons/like-dark';
import LikeSmallSvg from '@/components/svg/icons/like-empty';

export default {
  components: {
    LikeBigSvg,
    LikeSmallSvg,
  },

  props: {
    likesCount: {
      type: Number,
      default: 0,
    },

    isLiked: {
      type: Boolean,
      default: false,
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isBig: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    classes() {
      const classes = [];

      if (this.isDisabled) {
        classes.push('app-like--disabled');
      }

      if (this.isLiked) {
        classes.push('app-like--liked');
      }

      return classes;
    },
  },
};
</script>
