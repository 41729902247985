// @ts-ignore
import createDate from '@/providers/js/dateProvider';
import OrderStatus from '@/entities/modules/gift-store/OrderStatus';

export default class Order {
  id: number;
  status: OrderStatus;
  createdAt: number;
  totalPrice: number;
  isNew: boolean;
  orderCode: number;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);

    switch (payload.status) {
      case 0:
        this.status = OrderStatus.NEW;
        break;
      case 1:
        this.status = OrderStatus.INWORK;
        break;
      case 2:
        this.status = OrderStatus.COMPLETED;
        break;
      case 3:
        this.status = OrderStatus.CANCELED;
        break;
      case 5:
        this.status = OrderStatus.DELIVERED;
        break;
      default:
        this.status = OrderStatus.NOTRECEIVED;
    }

    this.createdAt = createDate(payload.created_at);
    this.totalPrice = parseInt(payload.total_price, 10);
    this.isNew = parseInt(payload.is_new, 10) === 1;
    this.orderCode = parseInt(payload.order, 10);
  }

  getStatusText(): string {
    switch (this.status) {
      case OrderStatus.NEW:
        return 'giftstore_new_status_text';
      case OrderStatus.INWORK:
        return 'giftstore_in_process_status_text';
      case OrderStatus.COMPLETED:
        return 'giftstore_done_status_text';
      case OrderStatus.CANCELED:
        return 'giftstore_canceled_status_text';
      case OrderStatus.DELIVERED:
        return 'giftstore_in_delivery_status_text';
      default:
        return 'giftstore_not_received_status_text';
    }
  }

  clearIsNew(): void {
    this.isNew = false;
  }
}
