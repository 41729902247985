import BaseWidget from '@/entities/modules/dashboard/BaseWidget';
import { each } from 'lodash';
import { MyTeamUser } from '@/entities/modules/dashboard/MyTeamUser';

export default class MyTeamWidget extends BaseWidget {
  users: MyTeamUser[];

  constructor(payload: any) {
    super(payload);

    this.users = [];
    each(payload.data.users, user => {
      this.users.push(new MyTeamUser(user));
    });
  }
}
