import axios from 'axios';
import { resolver } from '@/providers/promiseReturnerProvider';
import Task from '@/entities/modules/tasks/Task';
import { paginationResolverWithAddedMeta } from '@/providers/paginationProvider';
import Answer, { createAnswersList } from '@/entities/modules/tasks/Answer';
import Pagination from '@/entities/common/Pagination';

async function paginationGetAnswers(page: number, statuses: Array<string>) {
  const response = await axios.post(`/tasks/answers?page=${page}`, { statuses });

  return createAnswersList(response.data.success);
}

export default {
  /**
   * Список заданий пользователя
   */
  async getTasks(category: number, page: number) {
    const tasks: Task[] = [];
    const response = await axios.post(`/tasks?page=${page}`, {
      categories: [category],
    });

    response.data.success.tasks.forEach((task: any) => {
      tasks.push(new Task(task));
    });

    const pagination = new Pagination(response.data.success.meta.pagination);

    return {
      tasks,
      pagination,
    };
  },

  async downloadFile(file: string) {
    const response = await axios.get(file, {
      responseType: 'arraybuffer',
    });

    return response.data;
  },

  /**
   * Удаление меток isNew с заданий
   * @param taskId
   */
  async clearIsNewTask(taskId: number) {
    await axios.post('/tasks/clear-is-new', {
      tasks: [taskId],
    });

    return resolver(true);
  },

  /**
   * Вернет task по id
   * @param taskId
   */
  async getTask(taskId: number) {
    const response = await axios.get(`/tasks/${taskId}`, {
      headers: { globalErrorHandler: false },
    });

    return new Task(response.data.success.task);
  },

  /**
   * Добавление ответов
   * @param task
   */
  async saveAnswers(task: Task) {
    const response = await axios.post(`/tasks/${task.id}/answers`, { answers: task.getSaving() });

    return new Task(response.data.success.task);
  },

  /**
   * Валидация ответа
   * @param taskId
   * @param fieldId
   * @param value
   */
  async validateAnswer(taskId: number, fieldId: number, value: string | object) {
    await axios.post(
      `/tasks/${taskId}/answers/${fieldId}/validate`,
      value instanceof Object ? value : { value },
      { headers: { globalErrorHandler: false } },
    );
  },

  /**
   * Список ответов пользователя
   */
  async getAnswers(statuses: Array<string>) {
    return paginationResolverWithAddedMeta<Answer>(paginationGetAnswers, [statuses]);
  },

  /**
   * Удаление меток "isNew" со статусов
   * @param taskId
   */
  // TODO: поменять taskId на массив если нужно
  async clearIsNewStatuses(taskId: number) {
    await axios.post('/tasks/answers/statuses/clear-is-new', {
      task_answers: [taskId],
    });

    return resolver(true);
  },

  /**
   * Просмотр ответа
   * @param answerId
   */
  async getAnswer(answerId: number) {
    const response = await axios.get(`/tasks/answers/${answerId}`);

    return new Answer(response.data.success.answer);
  },

  /**
   * Изменение ответа
   * @param taskId
   * @param answerId
   * @param task
   */
  async refinementAnswer(taskId: number, answerId: number, task: Task) {
    const response = await axios.put(`/tasks/${taskId}/answers/${answerId}`, {
      answers: task.getSaving(),
    });

    return new Task(response.data.success.task);
  },

  /**
   * Получение количества заданий в статусе "Требует доработки"
   */
  async getRefinementCount() {
    const response = await axios.get('/tasks/refinement-count-widget');

    return response?.data?.success?.count;
  },
};
