import { each, findIndex } from 'lodash';
import { MutationTree } from 'vuex';
import { TestsState } from './types';

export const mutations: MutationTree<TestsState> = {
  setLevels(state, payload) {
    state.levels = payload;
  },

  setTests(state, payload) {
    if (state.materials.length === 0 || payload.length === 0) {
      state.materials = payload;

      return;
    }

    each(payload, actual => {
      const index = findIndex(state.materials, test => test.id === actual.id);

      if (index === -1) {
        state.materials.push(actual);
      } else {
        state.materials.splice(index, 1);
        state.materials.push(actual);
      }
    });
  },
};
