import axios from 'axios';
import { each, has } from 'lodash';
import Gift from '@/entities/modules/gift-store/Gift';
import Cart from '@/entities/modules/gift-store/Cart';
import Order from '@/entities/modules/gift-store/Order';
import Pagination from '@/entities/common/Pagination';
import { prepareHtml } from '@/providers/wysiwygRedactorProvider';

export default {
  async getCart(): Promise<Cart> {
    const payload: any = await axios.get('/shop/cart');

    return new Cart(payload.data.success);
  },

  getTerms() {
    return axios
      .get('/shop/conditions')
      .then(response => prepareHtml(response.data.success.conditions));
  },

  async getGift(id: number): Promise<Gift> {
    const payload: any = await axios.get(`/shop/products/${id}`);

    return new Gift(payload.data.success.product);
  },

  editGiftToCart(id: number, amount: number) {
    return axios
      .post(`/shop/cart/product/${id}/edit`, { amount })
      .then(response => new Cart(response.data.success));
  },

  addGiftToCart(id: number, amount: number) {
    return axios
      .post(`/shop/cart/product/${id}/add`, { amount })
      .then(response => new Cart(response.data.success));
  },

  removeGiftFromCart(id: number) {
    return axios
      .post(`/shop/cart/product/${id}/delete`)
      .then(response => new Cart(response.data.success));
  },

  async getOrders(page: number, perPage: number) {
    const payload: any = await axios.get(`/shop/orders?page=${page}&per_page=${perPage}`);

    const orders: Order[] = [];

    const points: number = parseInt(payload.data.success.data.user_points, 10);

    if (!has(payload, 'data.success.orders')) {
      return [];
    }

    each(payload.data.success.orders, order => {
      orders.push(new Order(order));
    });

    return {
      orders,
      points,
    };
  },

  async getGifts(page: number, perPage: number, sort: any) {
    const payload: any = await axios.get(
      `/shop/products?page=${page}&per_page=${perPage}&sort=${sort}`,
    );

    const meta: Pagination = new Pagination(payload.data.success.meta);

    const products: Gift[] = [];

    const points = parseInt(payload.data.success.data.user_points, 10);

    if (!has(payload, 'data.success.products')) {
      return [];
    }

    each(payload.data.success.products, product => {
      if (product.available_count > 0 || product.is_unlimited) {
        products.push(new Gift(product));
      }
    });

    return {
      products,
      points,
      meta,
    };
  },

  createOrder() {
    return axios.post('/shop/order/create');
  },

  async showOrder(id: number) {
    const response = await axios.get(`/shop/order/${id}/view`);

    const order: any = {};

    const products: Gift[] = [];

    if (!has(response, 'data.success.products')) {
      return [];
    }

    each(response.data.success.products, product => {
      products.push(new Gift(product));
    });

    order.comment = response.data.success.comment;
    order.products = products;

    return order;
  },

  cancelOrder(id: number) {
    return axios.post(`/shop/order/${id}/reject`);
  },

  setNotReceivedOrder(id: number) {
    return axios.post(`/shop/order/${id}/set-not-received`);
  },
};
