import { has, each } from 'lodash';
import CartPosition from './CartPosition';

export default class Cart {
  positions: CartPosition[] = [];
  items: number = 0;
  total: number = 0;
  orderCount: number | null;

  constructor(payload: any) {
    if (has(payload, 'products')) {
      each(payload.products, product => {
        this.positions.push(new CartPosition(product));

        if (product.enough_products && product.is_available) {
          this.items += product.amount;
          this.total += product.amount * product.price;
        } else if (product.available_count > 0 && product.is_available) {
          this.items += product.amount;
          this.total += product.amount * product.price;
        }
      });
    }

    this.orderCount = has(payload, 'data.new_count') ? parseInt(payload.data.new_count, 10) : null;
  }
}
