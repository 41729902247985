<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M12.3895 2.89481C12.2992 2.90938 12.203 2.92395 12.101 2.93561C11.7164 2.98514 10.8539 3.08713 10.3352 3.08713C9.52803 3.08713 8.79664 2.88899 8.0565 2.73163C7.30471 2.57137 6.5296 2.40527 5.70787 2.40527C4.07025 2.40527 3.51369 2.75786 3.45541 2.79574L3.38174 2.84785C3.35301 2.86817 3.33594 2.90116 3.33594 2.93634V13.1751C3.33594 13.385 3.48746 13.5656 3.69435 13.5918C3.94203 13.621 4.15184 13.4287 4.15184 13.1868V8.6877C4.15184 8.57697 4.23051 8.48081 4.33833 8.4575C4.64429 8.39631 5.09012 8.34386 5.70787 8.34386C6.47132 8.34386 7.18524 8.63525 7.93994 8.79552C8.70922 8.9587 9.35611 9.13062 10.3702 9.13062C11.3713 9.13062 12.4349 8.94317 12.4618 8.93839C12.4627 8.93824 12.4628 8.93823 12.4636 8.9381C12.5767 8.92009 12.6634 8.82422 12.6634 8.7081V3.12501C12.6605 2.98223 12.5323 2.8715 12.3895 2.89481Z"
      fill="#6B7280"
    />
  </svg>
</template>

<script>
export default {
  name: 'flag-icon',
};
</script>
