import { resolver } from '@/providers/promiseReturnerProvider';

const MAX_EXECUTIONS = 100;

/**
 * Функция вызовет переданный коллбэк с переданными параметрами столько раз,
 * сколько необходимо, чтобы получить все данные
 * @param requestCallback
 * @param args
 */
export async function paginationResolver<T>(requestCallback: Function, args: any): Promise<T[]> {
  const elements: T[] = [];

  let page = 1;

  let goNext = true;

  while (goNext && page < MAX_EXECUTIONS) {
    // eslint-disable-next-line no-await-in-loop
    const [pagination, payload] = await requestCallback.apply(null, [page, ...args]);
    elements.push(...payload);

    // нам не нужно загружать следующую страницу, если элементов пришло меньше, чем должно быть на странице
    // или если загруженная страница совпадает с максимальным количеством страниц
    if (pagination.currentPage === pagination.pagesCount) {
      goNext = false;
    } else {
      page += 1;
    }
  }

  return resolver(elements);
}

export async function paginationResolverWithAddedMeta<T>(requestCallback: Function, args: any) {
  const elements: T[] = [];

  let addedMeta = null;

  let page = 1;

  let goNext = true;

  while (goNext && page < MAX_EXECUTIONS) {
    // eslint-disable-next-line no-await-in-loop
    const [pagination, payload, payloadMeta] = await requestCallback.apply(null, [page, ...args]);
    elements.push(...payload);

    if (!addedMeta) {
      addedMeta = payloadMeta;
    }

    // нам не нужно загружать следующую страницу, если элементов пришло меньше, чем должно быть на странице
    // или если загруженная страница совпадает с максимальным количеством страниц
    if (pagination.currentPage === pagination.pagesCount) {
      goNext = false;
    } else {
      page += 1;
    }
  }

  return resolver({ elements, addedMeta });
}
