import { each, filter, cloneDeep } from 'lodash';
import IFilter from '@/entities/modules/my-team/filters/IFilter';
import Pagination from '@/entities/common/Pagination';

export default class BaseGroup implements IFilter {
  id: number;
  parentId: number;
  name: string;

  children?: BaseGroup[] = [];
  childrenPagination?: Pagination;
  route?: BaseGroup;
  hasUsers: boolean = false;

  checked: boolean = false;
  childChecked: boolean = false;
  parent?: BaseGroup;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.parentId = payload.parent_id ? parseInt(payload.parent_id, 10) : 0;
    this.name = payload.name;

    if (payload.child) {
      this.children = [new BaseGroup(payload.child)];
    }
  }

  getLastChild(): BaseGroup {
    let group: BaseGroup | null = this;

    while (group.getFirstChild()) {
      group = <BaseGroup>group.getFirstChild();
    }

    return group;
  }

  getFirstChild(): BaseGroup | null {
    return this.children ? this.children[0] : null;
  }

  setChildren(childGroups: BaseGroup[]): void {
    this.children = childGroups;
  }

  addChildren(childGroups: BaseGroup[]): void {
    if (this.children) {
      this.children.push(...childGroups);
    } else {
      this.children = childGroups;
    }
  }

  setChildrenPagination(pagination: Pagination): void {
    this.childrenPagination = pagination;
  }

  setChildrenById(childGroups: BaseGroup[], id: number): boolean {
    if (!this.children) {
      return false;
    }

    for (const child of this.children) {
      if (child.id === id) {
        child.setChildren(childGroups);

        return true;
      }

      if (child.setChildrenById(childGroups, id)) {
        return true;
      }
    }

    return false;
  }

  createTreeGroups(groups: BaseGroup[], parent: BaseGroup) {
    // составляем route, чтобы использовать его в поиске
    if (this.id !== parent.id) {
      const parentForRoute = new BaseGroup({
        id: parent.id,
        name: parent.name,
      });

      if (!parent.route) {
        this.route = parentForRoute;
      } else {
        this.route = cloneDeep(parent.route);
        this.route.getLastChild().setChildren([parentForRoute]);
      }
    }

    this.children = filter(groups, group => group.parentId === this.id);
    this.children = this.children.sort((a, b) => a.name.localeCompare(b.name));
    this.parent = parent;

    // добавляем группу "пользователи группы ..."
    if (this.hasUsers && this.children.length) {
      this.children.unshift(
        new BaseGroup({
          id: this.id,
          parent_id: this.id,
          name: this.name,
        }),
      );
    }

    each(this.children, child => {
      if (this.id !== child.id) {
        child.createTreeGroups(groups, this);
      }
    });
  }

  searchGroups(searchValue: string) {
    const searchGroups: BaseGroup[] = [];

    if (this.name.toUpperCase().includes(searchValue.toUpperCase()) && this.id !== this.parentId) {
      searchGroups.push(this);
    }
    each(this.children, child => {
      searchGroups.push(...child.searchGroups(searchValue));
    });

    return searchGroups.sort((a, b) => a.name.localeCompare(b.name));
  }

  changeChecked(status: boolean) {
    if (this.checked) {
      this.checked = status;
      this.childChecked = status;
    } else {
      this.checked = status;
    }
    each(this.children, child => {
      child.changeChecked(status);
    });
  }

  changeChildChecked() {
    let checked = true;

    let childChecked = false;
    each(this.children, child => {
      if (!childChecked && (child.checked || child.childChecked)) {
        childChecked = true;
      }

      if (checked && !child.checked) {
        checked = false;
      }
    });

    this.checked = checked;
    this.childChecked = childChecked;
  }

  recalculateParentChecked() {
    let { parent } = this;

    while (parent) {
      parent.changeChildChecked();
      parent = parent.parent;
    }
  }

  getCheckedGroups(): BaseGroup[] {
    const checkedGroups: BaseGroup[] = [];

    if (this.checked) {
      return [this];
    }

    each(this.children, child => {
      checkedGroups.push(...child.getCheckedGroups());
    });

    return checkedGroups;
  }

  clearChecked() {
    this.checked = false;
    this.childChecked = false;

    each(this.children, child => {
      child.clearChecked();
    });
  }
}
