import each from 'lodash/each';
import TestSection from '@/entities/modules/learning-programs/test/TestSection';
import Pagination from '../../../common/Pagination';

export function createTestSections(payload: any): [Pagination, TestSection[]] {
  const pagination = new Pagination(payload.meta.pagination);

  const sections: TestSection[] = [];

  each(payload.sections, section => {
    sections.push(new TestSection(section));
  });

  return [pagination, sections];
}
