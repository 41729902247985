import axios from 'axios';
import { each, find } from 'lodash';
import Statistic from '@/entities/modules/competencies/Statistic';
import Settings from '@/entities/modules/competencies/Settings';
import Test from '@/entities/modules/competencies/Test';
import Question from '@/entities/common/testing/Question';
import UpdateState from '@/entities/common/testing/updates/UpdateState';
import { errorLogProvider } from '@/providers/logProvider';
import CompetenciesTestUpdate from '@/entities/modules/competencies/CompetenciesTestUpdate';

export default {
  async getCompetenciesUpdates(): Promise<CompetenciesTestUpdate[]> {
    const response = await axios.get('/competencies/tests/updates');

    const competenciesUpdates: CompetenciesTestUpdate[] = [];
    each(response.data.success.tests, test =>
      competenciesUpdates.push(new CompetenciesTestUpdate(test)),
    );

    return competenciesUpdates;
  },

  async getCompetencies(statistic: Statistic[], settings: Settings): Promise<Test[]> {
    const response = await axios.get('/competencies/tests');

    const competencies: Test[] = [];
    each(response.data.success.tests, test => {
      const materialId = parseInt(test.id, 10);

      const pretenderStatistic = find(statistic, o => o.id === materialId);

      if (!pretenderStatistic) {
        errorLogProvider(`Statistic for material #${materialId} not found in statistic list`);

        return;
      }
      competencies.push(new Test(test, pretenderStatistic, settings));
    });

    return competencies;
  },

  async postCompetencies(
    competenciesIds: number[],
    statistic: Statistic[],
    settings: Settings,
  ): Promise<Test[]> {
    const response = await axios.post('/competencies/tests', {
      tests: competenciesIds,
    });

    const competencies: Test[] = [];
    each(response.data.success.tests, test => {
      const materialId = parseInt(test.id, 10);

      const pretenderStatistic = find(statistic, o => o.id === materialId);

      if (!pretenderStatistic) {
        errorLogProvider(`Statistic for material #${materialId} not found in statistic list`);

        return;
      }
      competencies.push(new Test(test, pretenderStatistic, settings));
    });

    return competencies;
  },

  async getCompetenciesStatistic(): Promise<Statistic[]> {
    const response = await axios.get('/competencies/tests/stat');

    const statistics: Statistic[] = [];
    each(response.data.success.tests, test => statistics.push(new Statistic(test)));

    return statistics;
  },

  async postCompetenciesStatistic(competenciesIds: number[]): Promise<Statistic[]> {
    const response = await axios.post('/competencies/tests/stat', {
      tests: competenciesIds,
    });

    const statistics: Statistic[] = [];
    each(response.data.success.tests, test => statistics.push(new Statistic(test)));

    return statistics;
  },

  async getUpdates(): Promise<UpdateState[]> {
    const response = await axios.get('/competencies/tests/updates');

    const updateList: UpdateState[] = [];
    each(response.data.success.tests, o => updateList.push(new UpdateState(o)));

    return updateList;
  },

  async getStatistic(loadedIdList: number[]): Promise<Statistic[]> {
    let response: any;

    if (loadedIdList.length === 0) {
      response = await axios.get('/competencies/tests/stat');
    } else {
      response = await axios.post('/competencies/tests/stat', { tests: loadedIdList });
    }

    const statistics: Statistic[] = [];
    each(response.data.success.tests, o => statistics.push(new Statistic(o)));

    return statistics;
  },

  async getMaterials(
    loadedIdList: number[],
    statistic: Statistic[],
    settings: Settings,
  ): Promise<Test[]> {
    let response: any;

    if (loadedIdList.length === 0) {
      response = await axios.get('/competencies/tests');
    } else {
      response = await axios.post('/competencies/tests', { tests: loadedIdList });
    }

    const materials: Test[] = [];
    each(response.data.success.tests, test => {
      const materialId = parseInt(test.id, 10);

      const pretenderStatistic = find(statistic, o => o.id === materialId);

      if (!pretenderStatistic) {
        errorLogProvider(`Statistic for material #${materialId} not found in statistic list`);

        return;
      }

      materials.push(new Test(test, pretenderStatistic, settings));
    });

    return materials;
  },

  clearIsNew(competenciesTestId: number) {
    return axios.post('/competencies/tests/clear-is-new', {
      tests: [competenciesTestId],
    });
  },

  async sendStatistic(statistic: any) {
    await axios.post(`/competencies/tests/${statistic.id}`, statistic);
  },

  async getQuestions(id: number, needsUuid: number) {
    const response = await axios.get(
      `/competencies/tests/${id}/start-cr?reset_turn_uuid=${needsUuid}`,
    );

    const questions: Question[] = [];

    each(response.data.success.questions, question => questions.push(new Question(question)));

    const timestamp = parseInt(response.data.success.timestamp, 10);

    const turnUuid = response.data.success.turn_uuid;

    return { questions, timestamp, turnUuid };
  },
};
