import ImageSource, { createImageFromStringWithSize } from '@/entities/common/sources/ImageSource';

export class MyTeamUser {
  id: number;
  name: string;
  image?: ImageSource;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.name = payload.name;
    this.image = createImageFromStringWithSize(payload.image.url, payload.image.size);
  }
}
