<template>
  <div class="app-material-card">
    <div
      class="app-material-card__wrapper"
      :class="{
        'app-material-card__wrapper--locked': isLockedMaterial,
        'app-material-card__wrapper--unknown': widget.isUnknown,
        'app-material-card__wrapper--stack': isStack,
      }"
      :data-element-id="widget.id"
      @click="checkMaterial"
    >
      <router-link
        v-if="isRouterLink && computedTo"
        :to="computedTo"
      />
      <a
        v-if="!isRouterLink && computedTo"
        :href="computedTo"
      >
      </a>

      <slot></slot>

      <div class="app-material-card__container">
        <LockedIcon v-if="isLocked || widget.isUnknown" />
        <div
          v-if="!hasImage && hasOuterFooter"
          class="app-material-card__empty-image"
        >
          <TestEmptySvg
            v-if="['tests', 'test'].includes(widget.type) || widget.moduleCode === 'tests'"
          />
          <SurveyEmptySvg
            v-else-if="widget.type === 'interviews' || widget.moduleCode === 'interviews'"
          />
          <EventEmptySvg v-else-if="widget.type === 'events' || widget.type === 'myRatingEvents'" />

          <ScormMaterialEmptySvg v-else-if="widget.subtype === 'scorm'" />
          <VideoMaterialEmptySvg v-else-if="widget.subtype === 'video'" />
          <TaskMaterialEmptySvg v-else-if="widget.type === 'tasks'" />
          <LearningProgramMaterialEmptySvg v-else-if="isLearningItem" />

          <NotImageSvg v-else />
        </div>
        <div
          class="app-material-card__outer"
          :class="{ 'app-material-card__outer--bs-contain': isOriginalProportions }"
          :style="hasImage ? `background-image: url(${image})` : ''"
        >
          <div
            class="app-material-card__inner"
            :class="{ 'app-material-card__inner--empty': !hasOuterFooter }"
          >
            <div class="app-material-card__inner-badge-wrapper">
              <div
                v-if="$slots.badge"
                class="app-material-card__badge"
              >
                <slot name="badge"></slot>
              </div>
              <div
                v-if="widget.tagLabel"
                class="app-material-card__tag app-tag app-tag--medium app-tag--dark-gray"
              >
                {{ widget.tagLabel }}
              </div>
            </div>

            <div
              v-if="!isLocked && typeof isFavorite !== 'undefined'"
              class="app-material-card__favorite"
              :class="{
                'app-material-card__favorite--light': isLightTheme,
                'app-material-card__favorite--not-checked': !isFavorite,
              }"
            >
              <AppFavorite
                :material-id="widget.id"
                :material-type="widget.apiName"
                :is-favorite="isFavorite"
                :material="widget"
                @changeFavoriteStatus="changeFavoriteStatus"
              />
            </div>
            <div
              v-if="widget.type === 'video' && widget.duration > 0"
              class="app-material-card__duration"
            >
              {{ duration }}
            </div>
          </div>
        </div>
      </div>
      <app-material-card-footer
        :widget="widget"
        :is-show-deadline-text="isShowDeadlineText"
        :is-outer-footer="hasOuterFooter"
        :has-info-bottom="hasInfoBottom"
        :to="computedTo"
        :points-display-status="status"
      >
        <template #footer>
          <slot name="footer"></slot>
        </template>
      </app-material-card-footer>
    </div>
    <div
      v-if="isStack"
      class="app-material-card__stack"
    ></div>
  </div>
</template>

<script>
import { has, isEmpty } from 'lodash';
import LockedIcon from '@/components/helpers/locked-icon.vue';
import AppMaterialCardFooter from '@/components/helpers/cards/material-card-footer.vue';

import TestEmptySvg from '@/components/svg/images/cards/test-empty.vue';
import SurveyEmptySvg from '@/components/svg/images/cards/survey-empty.vue';
import EventEmptySvg from '@/components/svg/images/cards/event-empty.vue';
import ScormMaterialEmptySvg from '@/components/svg/images/cards/scorm-material-empty.vue';
import VideoMaterialEmptySvg from '@/components/svg/images/cards/video-material-empty.vue';
import NotImageSvg from '@/components/svg/images/cards/not-image.vue';

import { secondsToHHMMSS } from '@/providers/dateProvider';
import AppFavorite from '@/components/helpers/favorite.vue';
import { sendEvent } from '@/providers/analyticsProvider';
import { isIRoute } from '@/entities/common/interfaces/routes/IRoute';
import { routeTo } from '@/providers/routerLinkProvider';
import TaskMaterialEmptySvg from '@/components/svg/images/cards/task-empty.vue';
import LearningProgramMaterialEmptySvg from '@/components/svg/images/cards/learning-program-empty.vue';

export default {
  name: 'material-card',

  components: {
    LearningProgramMaterialEmptySvg,
    TaskMaterialEmptySvg,
    AppFavorite,
    LockedIcon,
    AppMaterialCardFooter,

    TestEmptySvg,
    SurveyEmptySvg,
    EventEmptySvg,
    ScormMaterialEmptySvg,
    VideoMaterialEmptySvg,
    NotImageSvg,
  },

  props: {
    widget: {
      type: Object,
      required: true,
    },

    isLocked: {
      type: Boolean,
      default: false,
    },

    eventLabel: {
      type: [String, Array],
      default: '',
    },

    hasOuterFooter: {
      type: Boolean,
      default: false,
    },

    hasInfoBottom: {
      type: Boolean,
      default: false,
    },

    isShowDeadlineText: {
      type: Boolean,
      default: true,
    },

    isLightTheme: {
      type: Boolean,
      default: false,
    },

    status: {
      type: String,
      default: '',
    },

    isStack: {
      type: Boolean,
      default: false,
    },

    isOriginalProportions: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    isFavorite: false,
  }),

  computed: {
    isLearningItem() {
      return [
        'learning_programs',
        'learning-program',
        'learning-programs',
        'learning-track',
        'learning_program',
      ].includes(this.widget.type);
    },

    isLockedMaterial() {
      return (
        this.isLocked ||
        (typeof this.widget.isAvailable !== 'undefined' && !this.widget.isAvailable)
      );
    },

    /**
     * Есть или нет картинка
     * @returns {boolean}
     */
    hasImage() {
      return typeof this.widget.image !== 'undefined' && this.widget.image !== null;
    },

    /**
     * Если есть картинка, вернет путь на нее
     * @returns {null|string}
     */
    image() {
      if (this.hasImage) {
        if (has(this.widget.image, 'thumb')) {
          return this.widget.image.thumb;
        }

        return this.widget.image;
      }

      return null;
    },

    isRouterLink() {
      return isIRoute(this.widget) || typeof this.widget.to === 'object';
    },

    computedTo() {
      if (
        this.isLocked ||
        (typeof this.widget.isAvailable !== 'undefined' && !this.widget.isAvailable)
      ) {
        return null;
      }

      if (isIRoute(this.widget)) {
        return routeTo(this.widget);
      }

      if (isEmpty(this.widget.to)) {
        return null;
      }

      return this.widget.to;
    },

    duration() {
      return secondsToHHMMSS(this.widget.duration);
    },
  },

  watch: {
    widget: {
      deep: true,
      handler() {
        this.isFavorite = this.widget.isFavorite;
      },
    },
  },

  created() {
    this.isFavorite = this.widget.isFavorite;
  },

  methods: {
    checkMaterial() {
      if (typeof this.widget.isAvailable !== 'undefined' && !this.widget.isAvailable) {
        this.showNotification();
      }

      if (this.widget.isUnknown) {
        sendEvent(this.$analytics, 'lj_unsupported_open_btn');
        this.$store.dispatch('helpers/showNotification', {
          title: this.$t('myresults_material_will_be_available_in_future_ver_alert_title'),
          message: this.$t('myresults_material_will_be_available_in_future_ver_alert_text'),
        });
      }

      this.sendAnalyticsEvent();
    },

    showNotification() {
      this.$store.dispatch('helpers/showNotification', {
        message: this.$t('common_downloads_material_unavailable_error'),
      });
    },

    sendAnalyticsEvent() {
      if (this.isLocked || this.widget.to === null) {
        return;
      }

      if (!this.eventLabel) {
        return;
      }

      if (Array.isArray(this.eventLabel) && this.eventLabel.length) {
        this.eventLabel.forEach(event => sendEvent(this.$analytics, event));

        return;
      }

      sendEvent(this.$analytics, this.eventLabel);
    },

    changeFavoriteStatus(status, id) {
      this.$emit('changeFavoriteStatus', status, id);
      this.$emit('changeFavoriteStatusMaterialResult', { status, id });
      this.isFavorite = !this.isFavorite;
    },
  },
};
</script>
