<template>
  <base-home-widget
    :widget-name="widget.name"
    :to="routeToVideos"
    :count-text="countText"
  >
    <template #content>
      <app-slider
        :columns="ww4Columns"
        :column-size="ww4ColumnsForIsX"
      >
        <AppVideoCard
          v-for="(item, i) in widget.videos"
          :key="i"
          :video="item"
          :event-label="'dashboard_discover_view_btn'"
        />
      </app-slider>
    </template>
  </base-home-widget>
</template>

<script>
import { mapGetters } from 'vuex';
import VideoWidget from '@/entities/modules/dashboard/VideoWidget';
import BaseHomeWidget from '@/components/app/home/base-home-widget';
import AppVideoCard from '@/components/helpers/cards/material-card-video';
import AppSlider from '@/components/helpers/slider';
import CustomRoute from '@/entities/common/CustomRoute';

export default {
  components: {
    AppSlider,
    AppVideoCard,
    BaseHomeWidget,
  },

  props: {
    widget: {
      type: VideoWidget,
      required: true,
    },
  },

  computed: {
    ...mapGetters('helpers', ['ww4Columns', 'ww4ColumnsForIsX']),

    countText() {
      return this.$tc('common_d_materials_text_caps', this.widget.videos.length, {
        0: this.widget.videos.length,
      });
    },

    routeToVideos() {
      return {
        name: CustomRoute.TO_VIDEOS,
        query: {
          from: 'dashboard-widget',
        },
      };
    },
  },
};
</script>
