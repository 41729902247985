import { ImageWideType } from '@/entities/common/ImageTypes';
import { FeedbackStatusType } from './FeedbackStatus';

export interface IFeedbackResponse {
  id: number;
  user_id: number;
  user_name: string;
  mark: number;
  status: FeedbackStatusType;
  comment: string;
  image: ImageWideType;
  created_at: number;
  updated_at: number;
}

export default class LearningItemFeedback {
  id: number;

  userId: number;

  userName: string;

  mark: number;

  status: FeedbackStatusType;

  comment: string;

  image: ImageWideType;

  createdAt: number;

  updatedAt: number;

  constructor(payload: IFeedbackResponse) {
    this.id = payload.id;
    this.userId = payload.user_id;
    this.userName = payload.user_name;
    this.mark = payload.mark;
    this.status = payload.status;
    this.comment = payload.comment;
    this.image = payload.image;
    this.createdAt = payload.created_at;
    this.updatedAt = payload.updated_at;
  }
}
