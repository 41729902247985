import axios from 'axios';
import each from 'lodash/each';
import Video from '@/entities/modules/video/Video';
import Category from '@/entities/modules/video/Category';
import VideoUpdate from '@/entities/modules/video/VideoUpdate';

export default {
  async getDiscovers(): Promise<[Video[], Category[]]> {
    const response = await axios.get('/discovers');

    const discovers: Video[] = [];

    const categories: Category[] = [];

    each(response.data.success.categories, category => {
      categories.push(new Category(category));
      each(category.discovers, discover => discovers.push(new Video(discover)));
    });

    return [discovers, categories];
  },

  async postDiscovers(
    discoversIds: number[],
    categoriesIds: number[] = [],
  ): Promise<[Video[], Category[]]> {
    const response = await axios.post('/discovers', {
      discovers: discoversIds,
      categories: categoriesIds,
    });

    const discovers: Video[] = [];

    const categories: Category[] = [];

    each(response.data.success.categories, category => categories.push(new Category(category)));
    each(response.data.success.discovers, discover => discovers.push(new Video(discover)));

    return [discovers, categories];
  },

  async getDiscoversUpdates(): Promise<[VideoUpdate[], VideoUpdate[]]> {
    const response = await axios.get('/discovers/updates');

    const discoversUpdates: VideoUpdate[] = [];

    const categoriesUpdates: VideoUpdate[] = [];
    each(response.data.success.discovers, discover =>
      discoversUpdates.push(new VideoUpdate(discover)),
    );
    each(response.data.success.categories, category =>
      categoriesUpdates.push(new VideoUpdate(category)),
    );

    return [discoversUpdates, categoriesUpdates];
  },

  /**
   * Поставит лайк на видео
   * @param id
   */
  async like(id: number) {
    await axios.post('/discover/likes', { likes: [{ id, like: 1 }] });
  },

  /**
   * Поставит лайк на видео
   * @param id
   */
  async removeLike(id: number) {
    await axios.post('/discover/likes', { likes: [{ id, like: 0 }] });
  },

  /**
   * Сбросит флаг is new
   * @param id
   */
  async markAsRead(id: number) {
    await axios.post('/discovers/clear-is-new', { discovers: [id] });
  },

  /**
   * Отметит как просмотренный
   * @param id
   */
  async markAsViewed(id: number) {
    await axios.post('/discovers/views', { views: [id] });
  },
};
