<template>
  <div
    v-if="!isLoading"
    class="home"
  >
    <ProfileHeader class="home__profile-header" />

    <div
      v-if="banner && banner.slides"
      class="q-container home__banner"
    >
      <Banner :banner="banner" />
    </div>

    <NextMaterial
      v-if="nextMaterial"
      :material="nextMaterial"
    />

    <div v-if="widgets.length > 0">
      <template v-for="(widget, i) in widgets">
        <MonthFocusWidget
          v-if="widget.type === 'month_focus'"
          :key="`widget-${i}`"
          :month-focus="widget"
        />

        <FavoritesWidget
          v-if="widget.type === 'favorites' && widget.favorites.length"
          :key="`widget-${i}`"
          :widget="widget"
        />

        <MyAwardsWidget
          v-if="widget.type === 'badge'"
          :key="`widget-${i}`"
          :from-home="true"
          :award="widget"
          :event-label="'dashboard_badge_view_btn'"
          @updateAward="updateAward"
        />

        <MyTeamWidget
          v-if="widget.type === 'team'"
          :key="`widget-${i}`"
          :team="widget"
        />

        <LearningProgramsWidget
          v-if="
            widget.type === 'learning_program' ||
            widget.type === 'entities_with_passing_date' ||
            widget.type === 'learning_items'
          "
          :key="`widget-${i}`"
          :widget="widget"
        />

        <MyResultsWidget
          v-if="widget.type === 'results' && widget.results.length > 0"
          :key="`widget-${i}`"
          :widget="widget"
        />

        <TestsWidget
          v-if="widget.type === 'test'"
          :key="`widget-${i}`"
          :widget="widget"
        />

        <VideoWidget
          v-if="widget.type === 'discover'"
          :key="`widget-${i}`"
          :widget="widget"
        />

        <KpiWidget
          v-if="widget.type === 'kpi'"
          :key="`widget-${i}`"
          :kpi="widget"
        />
      </template>
    </div>
  </div>

  <AppPreloadContentPage
    v-else
    :has-header="true"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import ProfileHeader from '@/components/app/dashboard/profile-head.vue';
import Banner from '@/components/app/home/banner/banner.vue';
import NextMaterial from '@/components/app/home/next-material.vue';
import MonthFocusWidget from '@/components/app/home/month-focus.vue';
import FavoritesWidget from '@/components/app/home/favorites.vue';
import MyTeamWidget from '@/components/app/home/my-team.vue';
import LearningProgramsWidget from '@/components/app/home/learning-programs.vue';
import TestsWidget from '@/components/app/home/tests.vue';
import MyAwardsWidget from '@/components/app/home/my-awards.vue';
import MyResultsWidget from '@/components/app/home/my-results.vue';
import VideoWidget from '@/components/app/home/video.vue';
import KpiWidget from '@/components/app/home/kpi.vue';
import AppPreloadContentPage from '@/components/helpers/pre-loading-page/base-loading.vue';

export default {
  name: 'home-dashboard',

  components: {
    ProfileHeader,
    Banner,
    NextMaterial,
    MonthFocusWidget,
    FavoritesWidget,
    MyTeamWidget,
    LearningProgramsWidget,
    TestsWidget,
    MyAwardsWidget,
    MyResultsWidget,
    VideoWidget,
    KpiWidget,
    AppPreloadContentPage,
  },

  data: () => ({
    isLoading: true,
  }),

  computed: {
    ...mapGetters('auth', ['user']),

    ...mapGetters('settings', ['modulesList']),

    ...mapGetters('dashboard', ['banner', 'nextMaterial', 'widgets']),
  },

  async created() {
    await this.$store.dispatch('dashboard/getDashboard');
    this.isLoading = false;
  },

  methods: {
    updateAward(id) {
      this.$store.dispatch('dashboard/clearIsNewAward', id);
    },
  },
};
</script>
