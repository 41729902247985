import BaseGroup from '@/entities/common/groups/BaseGroup';
import Pagination from '@/entities/common/Pagination';
import { each, has } from 'lodash';

export default class ChildGroup extends BaseGroup {
  isAvailableOnRegistration: boolean;
  hasChildren: boolean;
  children: ChildGroup[] = [];

  constructor(payload: any) {
    super(payload);

    this.isAvailableOnRegistration = has(payload, 'is_available_on_registration')
      ? parseInt(payload.is_available_on_registration, 10) === 1
      : false;
    this.hasChildren = parseInt(payload.has_children, 10) === 1;
  }
}

export function createChildrenGroupsList(payload: any): [Pagination, ChildGroup[], ChildGroup] {
  const pagination = new Pagination(payload.meta.pagination);

  const groups: ChildGroup[] = [];

  const parent = new ChildGroup(payload.parent);

  each(payload.groups, group => {
    groups.push(new ChildGroup(group));
  });

  return [pagination, groups, parent];
}
