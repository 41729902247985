import CustomRoute from '@/entities/common/CustomRoute';
import ImageSource, { createImageFromString } from '@/entities/common/sources/ImageSource';
import { has } from 'lodash';
import { LearningItemTypes } from '@/entities/modules/learning-items/LearningItemType';
import { i18n } from '@/lang';

export default class LearningItemsMaterial {
  id: number;
  name: string;
  moduleName: string;
  moduleId: number;
  order: number;
  isNew: boolean;
  isRating: boolean;
  isRequired: boolean;
  isChecked: boolean;
  maxPoints: number;
  percent: number;
  image?: ImageSource;
  status: string;
  passingDeadline: number;
  passingNoticeDeadline: number;
  endTime: number;
  to: Object;
  type: string;
  parts: any;
  isInProgress: boolean;
  isPassed: boolean;
  isFailed: boolean;
  isChecking: boolean;
  isAssigned: boolean;
  tagLabel: string;

  constructor(payload: any) {
    this.id = payload.entity_id ? parseInt(payload.entity_id, 10) : parseInt(payload.id, 10);
    this.name = payload.name;
    this.moduleName = payload.module_name;
    this.moduleId = parseInt(payload.module_id, 10);
    this.order = parseInt(payload.order, 10);
    this.type =
      payload.entity_type === 'learning_track'
        ? LearningItemTypes.TRACK
        : LearningItemTypes.PROGRAM;
    this.to = {
      name:
        this.type === LearningItemTypes.TRACK
          ? CustomRoute.TO_LEARNING_TRACK
          : CustomRoute.TO_LEARNING_PROGRAMS_MATERIAL,
      params: {
        moduleId: this.moduleId,
        id: this.id,
      },
    };
    this.image = payload.image_wide
      ? createImageFromString(payload.image_wide.origin.url)
      : createImageFromString(payload.image.origin.url);
    this.isNew = parseInt(payload.is_new, 10) === 1;
    this.isRating = parseInt(payload.is_rating, 10) === 1;
    this.isRequired = parseInt(payload.is_required, 10) === 1;
    this.status = has(payload, 'status') ? payload.status : payload.progress_status;
    this.isInProgress = this.status === 'in_progress' || this.status === 'progress';
    this.isPassed = this.status === 'completed';
    this.isChecking = this.status === 'checking';
    this.isFailed = this.status === 'fail';
    this.isAssigned = this.status === 'appointed';
    this.isChecked = parseInt(payload.is_checked, 10) === 1;
    this.percent = parseInt(payload.percent, 10);
    this.maxPoints = parseInt(payload.max_points, 10);
    this.passingDeadline = parseInt(payload.deadline_for_passing_at, 10);
    this.passingNoticeDeadline = parseInt(payload.deadline_for_passing_notice_for_time, 10);
    this.endTime = parseInt(payload.end_time, 10);

    const parts = [];

    const points = parseInt(payload.points, 10);

    if (this.isRating && this.maxPoints > 0) {
      // если новый или назначен, пишем только сколько он может заработать
      if (this.isNew || this.isAssigned) {
        parts.push([
          'learn_earn_d_points_text',
          this.maxPoints,
          { 0: this.maxPoints.toLocaleString() },
        ]);
      } else if (this.isInProgress) {
        // в процессе прохождения, выводим из скольки-то баллов
        parts.push([
          'learn_d_from_d_points_text',
          this.maxPoints,
          { 0: points.toLocaleString(), 1: this.maxPoints.toLocaleString() },
        ]);
      } else if (this.isPassed || this.isFailed) {
        // успешно пройден, если на максимум -- выводим без количества попыток только баллы,
        // если не на максимум -- если есть попытки, выводим сколько и сколько баллов из скольки
        if (points < this.maxPoints) {
          parts.push([
            'learn_d_from_d_points_text',
            this.maxPoints,
            { 0: points.toLocaleString(), 1: this.maxPoints.toLocaleString() },
          ]);
        } else {
          parts.push([
            'common_point_number_text',
            this.maxPoints,
            { 0: this.maxPoints.toLocaleString() },
          ]);
        }
      }
    }

    this.parts = parts;
    this.tagLabel =
      this.type === LearningItemTypes.PROGRAM
        ? i18n.t('common_learning_journeys_text').toString()
        : '';
  }
}
