<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
  >
    <g clip-path="url(#clip0_14322_199210)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.5481 10.0723C2.5481 6.69066 5.28946 3.9493 8.67111 3.9493C12.0528 3.9493 14.7941 6.69066 14.7941 10.0723C14.7941 13.454 12.0528 16.1953 8.67111 16.1953C5.28946 16.1953 2.5481 13.454 2.5481 10.0723ZM5.93416 10.9077C5.47303 10.9077 5.0992 10.5339 5.0992 10.0728C5.0992 9.61163 5.47303 9.23781 5.93416 9.23781H7.88239V6.1763C7.88239 5.71517 8.25621 5.34135 8.71735 5.34135C9.17848 5.34135 9.5523 5.71517 9.5523 6.1763V9.68311C9.5523 10.3594 9.00402 10.9077 8.32769 10.9077H5.93416Z"
        :fill="color"
      />
      <path
        d="M6.17114 2.05751C6.17114 1.58408 6.55493 1.20029 7.02836 1.20029H10.3139C10.7874 1.20029 11.1711 1.58408 11.1711 2.05751C11.1711 2.53094 10.7874 2.91473 10.3139 2.91473H7.02836C6.55493 2.91473 6.17114 2.53094 6.17114 2.05751Z"
        :fill="color"
      />
    </g>
    <defs>
      <clipPath id="clip0_14322_199210">
        <rect
          width="16.2996"
          height="16.2996"
          :fill="color"
          transform="translate(0.521484 0.700409)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'time-near',

  props: {
    color: {
      type: String,
      default: 'white',
    },
  },
};
</script>
