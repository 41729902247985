<template>
  <div
    :class="{ 'my-team-employee-card--locked': isLocked }"
    class="my-team-employee-card"
  >
    <AppFavorite
      v-if="hasFavoriteButton"
      :material-type="apiName"
      :material-id="employee.id"
      :is-favorite="employee.isFavorite"
      @changeFavoriteStatus="changeFavoriteStatus"
    />

    <div
      class="my-team-employee-card__outer"
      @click="sendAnalytics()"
    >
      <div
        class="my-team-employee-card__inner"
        @click="showNoticeIfInterview()"
      >
        <AppSuccessCircle
          v-if="!fromDashboard && employee.progress.status === 'success' && !isLocked"
        />

        <LockedIcon v-if="isLocked" />

        <div class="my-team-employee-card-head">
          <router-link
            v-if="linkTo"
            :to="linkTo"
            class="my-team-employee-card-head__title my-team-employee-card-head__title--as-block-link"
          >
            {{ employee.name }}
          </router-link>
          <p
            v-else
            class="my-team-employee-card-head__title"
          >
            {{ employee.name }}
          </p>
          <div>{{ showDate }}</div>

          <div v-if="hasDiagram && getFormattedDate">
            {{ getFormattedDate }}
          </div>
        </div>
        <div class="my-team-employee-card-footer">
          <div
            v-if="hasDiagram"
            class="my-team-employee-card-footer__diagram"
          >
            <AppDonutDiagram
              :segments="segments"
              :total="total"
            />

            <div>
              <div>
                {{
                  $tc('common_d_from_d_text', total, { 0: employee.progress.successed, 1: total })
                }}
              </div>
              <div>
                {{
                  $tc('common_materials_plural_text', total, {
                    0: employee.progress.successed,
                    1: total,
                  })
                }}
              </div>
            </div>
          </div>
          <div
            v-if="type === 'events' && employee.eventStatus"
            :class="`app-tag--${eventTagStyle}`"
            class="app-tag app-tag--medium"
          >
            {{ $t(employee.eventStatus.myTeamLokaliseKey) }}
          </div>
          <div v-if="!fromDashboard && type !== 'events'">
            <div class="my-team-employee-card-footer__statistic">
              <div>{{ info }}</div>
            </div>
            <div class="my-team-employee-card-footer__status">
              <span
                v-if="!fromDashboard && statusForMaterial"
                class="app-tag app-tag--medium"
                :class="`app-tag--${materialTagStyle}`"
              >
                {{ statusForMaterial }}
              </span>
              <AppDatePassing
                v-if="employee.passingDeadline"
                :deadline="deadline"
                :status="status"
                :tooltip="tooltip"
              >
                <template #icon>
                  <TimeSvg v-if="status === 'gray'" />
                  <TimeNear v-if="status === 'orange'" />
                  <TimeOut v-if="status === 'success' || status === 'error'" />
                </template>
              </AppDatePassing>
            </div>
          </div>
          <div
            v-if="!isMobile"
            class="my-team-employee-card__image"
          >
            <div
              v-if="employee.image"
              :style="{ 'background-image': 'url(' + image + ')' }"
              class="my-team-employee-card-image__background"
            ></div>
            <UserSvg v-else />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { has } from 'lodash';
import AppSuccessCircle from '@/components/helpers/success-circle.vue';
import AppDonutDiagram from '@/components/helpers/charts/donut.vue';
import UserSvg from '@/components/svg/icons/user.vue';
import slugProvider from '@/providers/slugProvider';
import { localeDateFromTimestamp } from '@/providers/js/dateProvider';
import LockedIcon from '@/components/helpers/locked-icon.vue';
import AppFavorite from '@/components/helpers/favorite.vue';
import { mapGetters } from 'vuex';
import { sendEvent } from '@/providers/analyticsProvider';
import ApiName from '@/entities/common/ApiName';
import CustomRoute from '@/entities/common/CustomRoute';
import AppDatePassing from '@/components/helpers/date-passing.vue';
import {
  getDatePassing,
  getDeadlineStatusMaterial,
  getTooltipText,
} from '@/providers/deedlineProvider';
import TimeNear from '@/components/svg/icons/time-near.vue';
import TimeOut from '@/components/svg/icons/time-out.vue';
import TimeSvg from '@/components/svg/icons/time.vue';

export default {
  name: 'my-team-employee-card',

  components: {
    TimeSvg,
    TimeOut,
    TimeNear,
    AppSuccessCircle,
    AppDonutDiagram,
    UserSvg,
    LockedIcon,
    AppFavorite,
    AppDatePassing,
  },

  props: {
    employee: {
      type: Object,
      default: null,
    },

    type: {
      type: String,
      default: null,
    },

    materialId: {
      type: Number,
      default: null,
    },

    materialSlug: {
      type: String,
      default: null,
    },

    materialStatus: {
      type: String,
      default: null,
    },

    showDetails: {
      type: Boolean,
      default: false,
    },

    hasDiagram: {
      type: Boolean,
      default: false,
    },

    showManagerId: {
      type: Number,
      default: null,
    },

    fromDashboard: {
      type: Boolean,
      default: false,
    },

    learningProgramType: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    apiName: ApiName.TEAM,
  }),

  computed: {
    ...mapGetters('auth', ['user']),

    ...mapGetters('helpers', ['isMobile']),

    id() {
      return parseInt(this.$route.params.id, 10);
    },

    slug() {
      return slugProvider(this.employee.name);
    },

    showDate() {
      if (this.type === 'tests' && this.employee.progress.status !== 'not_started') {
        return localeDateFromTimestamp(this.employee.progress.test.complete_date);
      }

      if (this.type === 'interviews' && this.employee.progress.status !== 'not_started') {
        return localeDateFromTimestamp(this.employee.progress.interview.complete_date);
      }

      if (this.type === 'learning_programs') {
        if (this.employee.lastActive !== 0) {
          return localeDateFromTimestamp(this.employee.lastActive);
        }

        return this.$t('myteam_last_active_never_text_main_user_list');
      }

      return null;
    },

    managerId() {
      if (this.showManagerId) {
        return this.showManagerId;
      }

      if (has(this.$route.params, 'managerId')) {
        return parseInt(this.$route.params.managerId, 10);
      }

      return null;
    },

    linkTo() {
      if (this.hasDiagram || this.fromDashboard) {
        return {
          name: CustomRoute.TO_MY_TEAM_EMPLOYEE_MATERIAL,
          params: {
            managerId: this.managerId,
            id: this.employee.id,
            slug: this.slug,
            employee: this.employee,
          },
          query: this.$route.query,
        };
      }

      if (this.type === ApiName.INTERVIEW_MODULE && this.materialStatus === 'successed') {
        if (this.showDetails) {
          return {
            name: CustomRoute.TO_MY_TEAM_EMPLOYEE_RESULTS_FOR_INTERVIEW,
            params: {
              managerId: this.managerId,
              id: this.employee.id,
              slug: this.slug,
              type: 'interviews',
              typeId: this.materialId,
              typeSlug: this.materialSlug,
              employee: this.employee,
            },
            query: this.$route.query,
          };
        }

        return null;
      }

      if (this.type === ApiName.LEARNING_PROGRAM) {
        return {
          name: CustomRoute.TO_MY_TEAM_EMPLOYEE_RESULTS_FOR_LEARNING_PROGRAMS_DETAILS,
          params: {
            managerId: this.managerId,
            id: this.employee.id,
            slug: this.slug,
            type: this.type,
            typeId: this.materialId,
            typeSlug: this.materialSlug,
            employee: this.employee,
          },
          query: this.$route.query,
        };
      }

      if (this.type === ApiName.LEARNING_TRACK) {
        return {
          name: CustomRoute.TO_MY_TEAM_EMPLOYEE_RESULTS_FOR_LEARNING_TRACK_DETAILS,
          params: {
            managerId: this.managerId,
            id: this.employee.id,
            slug: this.slug,
            type: this.type,
            typeId: this.materialId,
            employee: this.employee,
          },
          query: this.$route.query,
        };
      }

      if (this.materialStatus === 'notStarted') {
        return null;
      }

      if (this.type === ApiName.EVENT_MODULE) {
        return null;
      }

      return {
        name: CustomRoute.TO_MY_TEAM_EMPLOYEE_RESULTS_BY_CODE_DETAILS,
        params: {
          managerId: this.managerId,
          id: this.employee.id,
          slug: this.slug,
          type: this.type,
          typeId: this.materialId,
          typeSlug: this.materialSlug,
          employee: this.employee,
        },
        query: this.$route.query,
      };
    },

    eventTagStyle() {
      if (this.employee.eventStatus) {
        switch (this.employee.eventStatus.code) {
          case 'not-filed':
            return 'gray';
          case 'filed':
            return 'gray';
          case 'waiting-for-confirmation':
            return 'dark-gray';
          case 'approved':
            return 'dark-gray';
          case 'missed':
            return 'error';
          case 'rejected':
            return 'gray';
          case 'participated':
            return 'success';
          default:
            return '';
        }
      }

      return '';
    },

    materialTagStyle() {
      switch (this.employee.progress.status) {
        case 'appointed':
          return 'gray';
        case 'in_progress':
          return 'gray';
        case 'not_started':
          return 'gray';
        case 'completed':
          return 'success';
        case 'success':
          return 'success';
        case 'fail':
          return 'error';
        case 'checking':
          return 'dark-gray';
        default:
          return '';
      }
    },

    statusForMaterial() {
      let status = null;

      switch (this.employee.progress.status) {
        case 'appointed':
        case 'not_started':
          status =
            this.type === 'learning_program'
              ? this.$t('learn_filter_assigned_fem_gen_text')
              : this.$t('learn_filter_assigned_text');
          break;

        case 'in_progress':
          status = this.$t('learn_filter_being_checked_text');
          break;

        case 'success':
          status =
            this.type === 'learning_program'
              ? this.$t('learn_filter_passed_fem_gen_text')
              : this.$t('learn_filter_passed_text');
          break;

        case 'completed':
          status =
            this.type === 'learning_program'
              ? this.$t('learn_filter_passed_fem_gen_text')
              : this.$t('learn_filter_passed_text');
          break;

        case 'fail':
          status =
            this.type === 'learning_program'
              ? this.$t('lj_filter_failed_fem_gen_text')
              : this.$t('learn_filter_failed_text');
          break;

        case 'checking':
          status = this.$t('common_under_check_status_text');
          break;

        default:
          break;
      }

      return status;
    },

    info() {
      let info;

      if (this.employee.progress.status !== 'not_started') {
        if (this.type === 'tests') {
          info = this.$tc(
            'common_d_from_d_attempts_text',
            this.employee.progress.test.turns_count,
            {
              0: this.employee.progress.test.current_turn,
              1: this.employee.progress.test.turns_count,
            },
          );
        }

        if (this.type === 'learning_program') {
          if (this.employee.progress.points !== this.employee.progress.max_points) {
            info = this.$tc('common_d_from_d_points_text', this.employee.progress.max_points, {
              0: this.employee.progress.points,
              1: this.employee.progress.max_points,
            });
          } else {
            info = this.$tc('common_point_number_text', this.employee.progress.max_points, {
              0: this.employee.progress.max_points.toLocaleString(),
            });
          }
        }
      }

      return info;
    },

    total() {
      return (
        this.employee.progress.successed +
        this.employee.progress.failed +
        this.employee.progress.notStarted
      );
    },

    segments() {
      const segments = [];

      segments.push({
        count: this.employee.progress.successed,
        status: 'success',
      });

      segments.push({
        count: this.employee.progress.failed,
        status: 'failed',
      });

      return segments;
    },

    getFormattedDate() {
      if (this.employee.lastActiveTimestamp) {
        return localeDateFromTimestamp(this.employee.lastActiveTimestamp);
      }

      return this.$t('myteam_last_active_never_text_main_user_list');
    },

    isLocked() {
      if (this.type === 'events') {
        return true;
      }

      if (
        this.materialStatus === 'notStarted' &&
        this.type !== 'events' &&
        this.type !== 'learning_program' &&
        this.type !== 'learning_track'
      ) {
        return true;
      }

      if (this.type === 'interviews' && !this.showDetails && this.materialStatus === 'successed') {
        return true;
      }

      return false;
    },

    hasFavoriteButton() {
      return !this.fromDashboard && this.showManagerId === this.user.id;
    },

    /**
     * Если есть картинка, вернет путь на нее
     * @returns {null|string}
     */
    image() {
      if (this.employee.image) {
        if (has(this.employee.image, 'thumb')) {
          return this.employee.image.thumb;
        }

        return this.employee.image;
      }

      return null;
    },

    deadline() {
      return getDatePassing(
        this.employee.passingDeadline,
        this.employee.progress.status,
        this.employee.endTime,
        this.learningProgramType,
      );
    },

    status() {
      // TODO: поменять статусы после рефакторинга
      const isFailed = this.employee.progress.status === 'fail';

      const isPassed = this.employee.progress.status === 'completed';

      return getDeadlineStatusMaterial(
        this.employee.passingDeadline,
        this.employee.passingNoticeDeadline,
        this.employee.endTime,
        isFailed,
        isPassed,
        this.learningProgramType,
        this.employee.progress.status,
      );
    },

    tooltip() {
      return this.employee.passingDeadline ? getTooltipText(this.employee.passingDeadline) : '';
    },
  },

  created() {
    sessionStorage.setItem('fromDetails', 1);
  },

  methods: {
    showNoticeIfInterview() {
      if (this.materialStatus === 'notStarted' && this.type !== 'learning_program') {
        this.$store.dispatch('helpers/showNotification', {
          message: this.$t('myteam_no_stats_for_this_material_yet_alert'),
        });
        sendEvent(this.$analytics, 'myteam_details_unavailable_btn');
      }

      if (this.type === 'events' && this.materialStatus === 'successed') {
        this.$store.dispatch('helpers/showNotification', {
          message: this.$t('myteam_event_details_not_available_toast_text'),
        });
        sendEvent(this.$analytics, 'myteam_details_unavailable_btn');
      }

      if (this.type === 'interviews' && !this.showDetails && this.materialStatus === 'successed') {
        this.$store.dispatch('helpers/showNotification', {
          message: this.$t('myteam_survey_details_unavailable_alert_text_web'),
        });
        sendEvent(this.$analytics, 'myteam_details_unavailable_btn');
      }
    },

    changeFavoriteStatus(status) {
      this.employee.setFavorite(status);
    },

    sendAnalytics() {
      if (this.fromDashboard) {
        sendEvent(this.$analytics, 'dashboard_myteam_employee_btn');
      } else if (!this.type) {
        sendEvent(this.$analytics, 'myteam_employee_view_btn');
      }
    },
  },
};
</script>
