<template>
  <div
    ref="outer"
    class="app-open-in-mobile"
  >
    <div
      class="app-open-in-mobile__close"
      @click="close"
    >
      <Close24px />
    </div>
    <div class="app-open-in-mobile__icon">
      <img
        v-if="mobileStore.image"
        :src="mobileStore.image.origin"
        alt=""
      />
      <img
        v-else
        src="@/assets/images/logo-one-letter.svg"
        alt=""
      />
    </div>
    <div class="app-open-in-mobile__label">
      {{ $t('common_resolution_unsupported_error_hint_web') }}
    </div>
    <div class="app-open-in-mobile__button">
      <a
        class="app-open-in-mobile-button"
        @click="prepareToOpen"
      >
        {{ $t('common_download_button_text') }}
      </a>
    </div>
  </div>
</template>

<script>
import { sendEvent } from '@/providers/analyticsProvider';
import { mapGetters } from 'vuex';
import Close24px from '@/components/svg/icons/close-24px';

export default {
  name: 'open-in-mobile',
  components: { Close24px },
  props: {
    mobileStore: {
      type: Object,
      required: false,
      default: () => {},
    },
  },

  computed: {
    ...mapGetters('settings', ['id']),

    ...mapGetters('helpers', ['ww']),
  },

  watch: {
    ww() {
      this.recalculate();
    },
  },

  mounted() {
    this.recalculate();
  },

  methods: {
    recalculate() {
      if (this.$refs.outer) {
        this.$refs.outer.style.maxWidth = `${window.screen.width}px`;
      }
    },

    prepareToOpen() {
      sendEvent(this.$analytics, 'web_redirect_to_app_banner_btn');

      setTimeout(() => {
        this.open();
      }, 500);
    },

    open() {
      const startDate = new Date();

      setTimeout(() => {
        const endDate = new Date();

        const seconds = (endDate.getTime() - startDate.getTime()) / 1000;

        if (seconds < 2) {
          location.href = this.mobileStore.url;
        }
      }, 1000);
    },

    close() {
      sessionStorage.setItem('do_not_show_link_to_store', '1');
      this.$emit('close');
    },
  },
};
</script>
