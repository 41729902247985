<template>
  <component :is="currentIcon" />
</template>

<script>
import LinkIcon from '@/components/svg/icons/learning-programs/link';
import DocumentIcon from '@/components/svg/icons/learning-programs/document';
import FileIcon from '@/components/svg/icons/learning-programs/file';
import ImageIcon from '@/components/svg/icons/learning-programs/image';
import PresentationIcon from '@/components/svg/icons/learning-programs/presentation';
import SheetIcon from '@/components/svg/icons/learning-programs/sheet';
import VideoIcon from '@/components/svg/icons/learning-programs/video';
import ScormIcon from '@/components/svg/icons/learning-programs/scorm';
import HtmlIcon from '@/components/svg/icons/learning-programs/html-document';
import TestIcon from '@/components/svg/icons/learning-programs/test';
import LongreadIcon from '@/components/svg/icons/learning-programs/longread';
import UnknownIcon from '@/components/svg/icons/learning-programs/unknown';
import PdfIcon from '@/components/svg/icons/learning-programs/pdf';
import SurveyIcon from '@/components/svg/icons/learning-programs/survey';
import TaskIcon from '@/components/svg/icons/learning-programs/task';
import LearningProgramIcon from '@/components/svg/icons/learning-programs/learning-program';

export default {
  name: 'material-icon',

  components: {
    LinkIcon,
    DocumentIcon,
    FileIcon,
    ImageIcon,
    PresentationIcon,
    SheetIcon,
    VideoIcon,
    ScormIcon,
    HtmlIcon,
    TestIcon,
    LongreadIcon,
    UnknownIcon,
    PdfIcon,
    SurveyIcon,
    TaskIcon,
    LearningProgramIcon,
  },

  props: {
    materialType: {
      type: String,
      default: '',
    },
  },

  computed: {
    currentIcon() {
      const icons = new Map([
        ['link', 'LinkIcon'],
        ['pdf', 'PdfIcon'],
        ['document', 'DocumentIcon'],
        ['sheet', 'SheetIcon'],
        ['presentation', 'PresentationIcon'],
        ['image', 'ImageIcon'],
        ['file', 'FileIcon'],
        ['video', 'VideoIcon'],
        ['scorm', 'ScormIcon'],
        ['html', 'HtmlIcon'],
        ['test' || 'tests', 'TestIcon'],
        ['longread', 'LongreadIcon'],
        ['survey', 'SurveyIcon'],
        ['task', 'TaskIcon'],
        ['learning_program', 'LearningProgramIcon'],
      ]);

      return icons.get(this.materialType) !== 'undefined'
        ? icons.get(this.materialType)
        : 'UnknownIcon';
    },
  },
};
</script>
