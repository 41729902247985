import axios from 'axios';
import { each, sortBy } from 'lodash';
import Kpi from '@/entities/modules/kpi/Kpi';
import KpiMeta from '@/entities/modules/kpi/KpiMeta';
import KpiInfo from '@/entities/modules/kpi/KpiInfo';
import KpiTransaction from '@/entities/modules/kpi/KpiTransaction';
import KpiActionsInfo from '@/entities/modules/kpi/KpiActionsInfo';
import Pagination from '@/entities/common/Pagination';

export default {
  getKpiList() {
    return axios.get('/kpi').then(response => {
      const meta = new KpiMeta(response.data.success);

      const info = new KpiInfo(response.data.success.info);

      let kpi = [];

      each(response.data.success.kpi, item => {
        kpi.push(new Kpi(item));
      });

      kpi = sortBy(kpi, ['order']);

      return { ...meta, info, kpi };
    });
  },

  getTransactions(params) {
    const idParam = params.kpiId ? `&kpi_id=${params.kpiId}` : '';

    return axios
      .get(`/kpi/transactions?per_page=${params.perPage}&page=${params.page}${idParam}`)
      .then(response => {
        const transactions = [];
        each(response.data.success.transactions, item =>
          transactions.push(new KpiTransaction(item)),
        );

        const meta = new Pagination(response.data.success.meta);

        const info = new KpiActionsInfo(response.data.success.info);

        const totalAmount = parseFloat(response.data.success.total_amount);

        const totalRatingAmount = parseFloat(response.data.success.total_rating_amount);

        return {
          transactions,
          meta,
          info,
          totalAmount,
          totalRatingAmount,
        };
      });
  },

  getKpiInfo() {
    return axios.get('/kpi/info').then(response => new KpiInfo(response.data.success));
  },
};
