import { each } from 'lodash';
import ApiName from '@/entities/common/ApiName';
import CertificatesListItem from './CertificatesListItem';
import LearningMaterialCertificateListItem from './LearningMaterialCertificateListItem';

export default class CertificatesSectionList {
  moduleId: number;
  moduleName: string;
  type: string;
  items: CertificatesListItem[];

  constructor(payload: any) {
    this.moduleId = parseInt(payload.module_id, 10);
    this.moduleName = payload.module_name;
    this.type = payload.entity_type;

    this.items = [];
    each(payload.items, item => {
      const certificateItem =
        this.type === ApiName.LEARNING_ITEM_MODULE
          ? new LearningMaterialCertificateListItem(item)
          : new CertificatesListItem(item);

      this.items.push(certificateItem);
    });

    this.sortCertificates();
  }

  sortCertificates() {
    this.items.sort((a, b) => {
      if (!a.order || !b.order) {
        return -1;
      }

      return a.order - b.order;
    });
  }
}
