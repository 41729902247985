<template>
  <div
    class="banner"
    :class="{ 'banner--with-pagination': hasMoreThenOneSlide }"
  >
    <Flicking
      ref="flicking"
      :options="$options.bannerOptions"
      :plugins="plugins"
      @ready="startTimer"
      @changed="startTimer"
    >
      <BannerSlide
        v-for="(slide, index) in banner.slides"
        :key="slide.id"
        :slide="slide"
        class="banner__slide flicking-panel"
        :class="{ 'banner__slide--single': !hasMoreThenOneSlide }"
        @mouseenter="putOnPause"
        @touchstart="putOnPause"
        @mouseleave="continueTimer"
        @touchend="continueTimer"
        @clickToSlide="clickToSlide($event, index)"
        @clickToButton="clickToButton($event, index)"
      />
    </Flicking>
    <div v-show="isShowArrows">
      <div
        class="banner__control banner__control--prev flicking-arrow-prev"
        @click="clickOnControl($options.PREV_DIRECTION)"
      >
        <span class="banner__arrow banner__arrow--prev">
          <ChevronLeftOutline />
        </span>
      </div>
      <span
        class="banner__control banner__control--next flicking-arrow-next"
        @click="clickOnControl($options.NEXT_DIRECTION)"
      >
        <span class="banner__arrow banner__arrow--next">
          <ChevronLeftOutline />
        </span>
      </span>
    </div>
    <div
      class="flicking-pagination"
      :style="{ '--progress': `${progress}deg` }"
      @click="resetTime"
    ></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { sendEvent } from '@/providers/analyticsProvider';
import { Flicking } from '@egjs/vue-flicking';
import { Arrow, Pagination } from '@egjs/flicking-plugins';
import ChevronLeftOutline from '@/components/svg/icons/chevron-left-outline.vue';
import BannerSlide from '@/components/app/home/banner/banner-slide.vue';
import BannerWidget from '@/entities/modules/dashboard/BannerWidget';

const INTERVAL = 100;

const MAX_PAGINATION_BULLETS = 5;

const PREV_DIRECTION = 'prev';

const NEXT_DIRECTION = 'next';

export default {
  name: 'banner-widget',

  components: {
    ChevronLeftOutline,
    Flicking,
    BannerSlide,
  },

  PREV_DIRECTION,
  NEXT_DIRECTION,

  props: {
    banner: {
      type: BannerWidget,
      default: () => null,
      required: true,
    },
  },

  data() {
    return {
      elapsedTime: 0,
      timerId: null,
      startTime: 0,
      onPause: false,
      plugins: [
        new Arrow({
          parentEl: document.body,
          prevElSelector: '.banner__control--prev',
          nextElSelector: '.banner__control--next',
        }),
      ],
      currentSlide: 0,
    };
  },

  bannerOptions: {
    align: 'center',
    adaptive: true,
    autoResize: true,
    circular: true,
    circularFallback: 'bound',
    moveType: 'strict',
    useResizeObserver: true,
  },

  computed: {
    ...mapGetters('helpers', ['isDesktop']),

    slideSwitchingTime() {
      return this.banner.speed * 1000;
    },

    hasMoreThenOneSlide() {
      return this.numberOfSlides > 1;
    },

    hasTwoSlides() {
      return this.numberOfSlides === 2;
    },

    isShowArrows() {
      return this.isDesktop && this.hasMoreThenOneSlide;
    },

    numberOfSlides() {
      if (!this.banner?.slides) {
        return 0;
      }

      return this.banner.slides.length;
    },

    percent() {
      return (this.elapsedTime * 100) / this.slideSwitchingTime;
    },

    progress() {
      return this.percent * 3.6;
    },
  },

  created() {
    if (this.hasMoreThenOneSlide) {
      this.plugins.push(
        new Pagination({
          parentEl: document.body,
          type: 'scroll',
          bulletCount: MAX_PAGINATION_BULLETS,
        }),
      );
    }
  },

  methods: {
    getSlideLink(slideId) {
      const slide = this.banner.slides.find(item => item.id === slideId);

      return slide.deeplink;
    },

    redirect(slideId) {
      const deeplink = this.getSlideLink(slideId);

      if (!deeplink) {
        return;
      }

      location.href = deeplink.url;
    },

    sendAnalytic(eventsName) {
      sendEvent(this.$analytics, eventsName);
    },

    clickToSlide(slideId, index) {
      if (index === this.currentSlide) {
        this.sendAnalytic('dashboard_banner_view');
        this.redirect(slideId);

        return;
      }

      this.$refs.flicking.moveTo(index);
    },

    clickToButton(slideId, index) {
      if (index === this.currentSlide) {
        this.sendAnalytic('dashboard_banner_view_btn');
        this.redirect(slideId);
      }

      this.$refs.flicking.moveTo(index);
    },

    changeTime() {
      const diff = new Date().getTime() - this.startTime;

      if (diff > this.slideSwitchingTime) {
        this.clearTimer();
        this.changeSlide();
      }

      this.elapsedTime += INTERVAL;
    },

    goToPrevSlide() {
      this.$refs.flicking.prev();
    },

    goToNextSlide() {
      this.$refs.flicking.next();
    },

    changeSlide(direction = NEXT_DIRECTION) {
      const isNextDirection = direction === NEXT_DIRECTION;

      if (this.hasTwoSlides) {
        if (this.currentSlide === 1 && isNextDirection) {
          this.goToPrevSlide();

          return;
        }

        if (this.currentSlide === 0 && !isNextDirection) {
          this.goToNextSlide();

          return;
        }
      }

      if (isNextDirection) {
        this.goToNextSlide();

        return;
      }

      this.goToPrevSlide();
    },

    clearTimer() {
      clearInterval(this.timerId);
    },

    setTimer() {
      this.clearTimer();
      this.startTime = new Date().getTime() - this.elapsedTime;
      this.timerId = setInterval(this.changeTime, INTERVAL);
    },

    putOnPause() {
      this.onPause = true;
      this.clearTimer();
    },

    continueTimer() {
      this.onPause = false;
      this.setTimer();
    },

    startTimer() {
      this.currentSlide = this.$refs.flicking.index;

      if (this.elapsedTime !== 0) {
        this.resetTime();
      }

      if (!this.onPause) {
        this.setTimer();
      }
    },

    resetTime() {
      this.elapsedTime = 0;
      this.clearTimer();
    },

    clickOnControl(direction) {
      this.changeSlide(direction);
    },
  },
};
</script>

<style lang="scss">
@import '@egjs/vue-flicking/dist/flicking.css';
@import '@egjs/flicking-plugins/dist/pagination.css';

$neutral-300: #d0d5dc;
$neutral-400: #9ca3b0;
$neutral-500: #6b7280;

.banner {
  position: relative;

  &--with-pagination {
    padding-bottom: 24px;
  }

  &__slide {
    margin-right: 16px;

    &:not(.banner__slide--single) {
      @media (min-width: $screen-xxxl) {
        height: 411px;
        width: 1152px;
      }
    }
  }

  &__control {
    position: absolute;
    top: calc(50% - 42px);
    z-index: 5;

    &--prev {
      justify-content: start;
      left: -24px;
    }

    &--next {
      justify-content: end;
      right: -24px;
    }
  }

  &__arrow {
    align-items: center;
    background-color: var(--primary);
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    height: 48px;
    transition: ease 0.3s;
    width: 48px;
    z-index: 10;

    svg path {
      fill: var(--surfaceWhite);
    }

    &--next {
      svg {
        transform: rotate(180deg);
      }
    }

    &:hover {
      transform: scale(1.03);
    }
  }

  .flicking-pagination {
    transform: translate(-50%, 50%);
  }

  .flicking-pagination-scroll {
    text-align: left;
  }

  .flicking-pagination-bullet {
    background-color: $neutral-300;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    height: 12px;
    margin: 0 5px;
    position: relative;
    transform: scale(0);
    transition: transform 0.2s, left 0.2s;
    vertical-align: middle;
    width: 12px;

    &:hover {
      background-color: $neutral-400;
    }
  }

  .flicking-pagination-bullet-prev,
  .flicking-pagination-bullet-next {
    transform: scale(0.666);
  }

  .flicking-pagination-bullet-prev2,
  .flicking-pagination-bullet-next2 {
    transform: scale(0.333);
  }

  .flicking-pagination-bullet-active {
    background: conic-gradient($neutral-300 var(--progress), $neutral-500 0 360deg), $neutral-500;
    transform: scale(1);
  }

  .flicking-viewport {
    border-radius: 16px;

    @media (min-width: $screen-xxxl) {
      border-radius: 0;
    }
  }
}
</style>
