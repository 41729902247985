import { each, find } from 'lodash';
import IStatistic from '@/entities/modules/learning-programs/IStatistic';
import MaterialPdf from '@/entities/modules/learning-programs/MaterialPdf';
import MaterialScorm from '@/entities/modules/learning-programs/MaterialScorm';
import MaterialLink from '@/entities/modules/learning-programs/MaterialLink';
import MaterialHtml from '@/entities/modules/learning-programs/MaterialHtml';
import MaterialTest from '@/entities/modules/learning-programs/MaterialTest';
import CustomSettings from '@/entities/modules/learning-programs/CustomSettings';
import MaterialVideo from '@/entities/modules/learning-programs/MaterialVideo';
import MaterialLongread from '@/entities/modules/learning-programs/MaterialLongread';
import MaterialDocument from '@/entities/modules/learning-programs/MaterialDocument';
import MaterialUnknown from '@/entities/modules/learning-programs/MaterialUnknown';
import BaseMaterial from '@/entities/modules/learning-programs/BaseMaterial';
import Pagination from '../../common/Pagination';

export function createMaterialsList(
  statistics: IStatistic[],
  payload: any,
  learningProgramsSettings: CustomSettings,
): [Pagination, BaseMaterial[]] {
  const pagination = new Pagination(payload.meta.pagination);

  const materials: BaseMaterial[] = [];
  each(payload.materials, material => {
    const materialId = parseInt(material.id, 10);

    const statistic = find(statistics, o => o.materialId === materialId);

    if (statistic) {
      switch (material.type) {
        case 'pdf':
          materials.push(new MaterialPdf(statistic, material));
          break;

        case 'link':
          materials.push(new MaterialLink(statistic, material));
          break;

        case 'scorm':
          materials.push(new MaterialScorm(statistic, material));
          break;

        case 'html':
          materials.push(new MaterialHtml(statistic, material));
          break;

        case 'test':
          materials.push(new MaterialTest(statistic, material, learningProgramsSettings));
          break;

        case 'video':
          materials.push(new MaterialVideo(statistic, material));
          break;

        case 'longread':
          materials.push(new MaterialLongread(statistic, material));
          break;

        case 'document':
          materials.push(new MaterialDocument(statistic, material));
          break;

        default:
          materials.push(new MaterialUnknown(statistic, material));
      }
    }
  });

  return [pagination, materials];
}
