import { find } from 'lodash';
import store from '@/store';
import { i18n } from '@/lang';
import CorrectName from '@/entities/common/CorrectName';

export default function getModuleNameByCorrectName(
  correctName: CorrectName,
  moduleId?: number,
): string {
  if (correctName === CorrectName.LEARNING_MATERIALS && !moduleId) {
    throw new Error('getModuleNameByCorrectName: moduleId required for LEARNING_MATERIALS');
  }

  let module;

  if (moduleId) {
    module = find(
      store.getters['settings/allModules'],
      o => o.correctName === correctName && o.id === moduleId,
    );
  } else {
    module = find(store.getters['settings/allModules'], o => o.correctName === correctName);
  }

  if (module && module.title) {
    return module.title;
  }

  switch (correctName) {
    case CorrectName.DASHBOARD:
      return i18n.t('dashboard_screen_title_text').toString();
    case CorrectName.EVALUATIONS:
      return i18n.t('tests_screen_title_text').toString();
    case CorrectName.TESTS:
      return i18n.t('tests_test_tab').toString();
    case CorrectName.INTERVIEW:
      return i18n.t('common_surveys_text').toString();
    case CorrectName.COMPETENCY:
      return i18n.t('competencies_screen_title_text').toString();
    case CorrectName.RATING:
      return i18n.t('achievements_section_name_label').toString();
    case CorrectName.MY_RESULTS:
      return i18n.t('myresults_section_title').toString();
    case CorrectName.LEARNING_MATERIALS:
      return i18n.t('common_lm_default_section_title').toString();
    case CorrectName.LEARNING_PROGRAMS:
      return i18n.t('common_learning_journeys_text').toString();
    case CorrectName.LEARNING_TRACK:
      return i18n.t('common_trajectories_default_text').toString();
    case CorrectName.VIDEO:
      return i18n.t('discover_screen_title_text').toString();
    case CorrectName.EVENT:
      return i18n.t('events_section_title').toString();
    case CorrectName.TEAM:
      return i18n.t('myteam_section_name_label').toString();
    case CorrectName.GIFT_STORE:
      return i18n.t('giftstore_title_text').toString();
    case CorrectName.TASK:
      return i18n.t('common_tasks_title').toString();
    case CorrectName.INFORMATION:
      return i18n.t('info_screen_title_text').toString();
    case CorrectName.MESSAGES:
      return i18n.t('messages_screen_title_text').toString();
    case CorrectName.MATERIAL:
      return i18n.t('common_search_learn_material_section_title').toString();
    default:
      return '';
  }
}
