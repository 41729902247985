import { each } from 'lodash';
import KpiEntity from '@/entities/modules/kpi/KpiEntity';
// @ts-ignore
import createDate from '@/providers/js/dateProvider';

export default class KpiInfo {
  infoString: string;
  lastTransactionDate: string;
  lastTransactionAmount: number;
  lastTransactionAmountUnit: string;
  kpis: KpiEntity[] = [];

  constructor(payload: any) {
    this.infoString = payload.info_string;
    this.lastTransactionDate = createDate(payload.last_transaction_date);
    this.lastTransactionAmount = parseInt(payload.last_transaction_amount, 10);
    this.lastTransactionAmountUnit = payload.last_transaction_amount_unit;

    each(payload.kpis, kpi => {
      this.kpis.push(new KpiEntity(kpi));
    });
  }
}
