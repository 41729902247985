import BaseEvent from '@/entities/modules/events/BaseEvent';
import { TimeFrame } from '@/entities/modules/events/TimeFrame';

export default class Event extends BaseEvent {
  type: string = 'event';
  timeFrame: TimeFrame;

  constructor(payload: any) {
    super(payload);

    this.timeFrame = new TimeFrame(payload);
  }
}
