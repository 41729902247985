import { each, filter, find } from 'lodash';
import BaseQuestionStatistic from '@/entities/common/testing/BaseQuestionStatistic';
import QuestionType from '@/entities/common/testing/QuestionType';
import UserAnswer from '@/entities/common/testing/user-answers/UserAnswer';
import SelectImageAnswerOption from '@/entities/common/testing/answer-oprions/SelectImageAnswerOption';

export default class QuestionStatistic extends BaseQuestionStatistic {
  type: QuestionType;

  constructor(payload: any) {
    super(payload);

    this.name = payload.question;
    this.recommendation = payload.recommendation;

    switch (payload.type) {
      case 'mcq':
        this.type = QuestionType.MCQ;
        break;
      default:
        this.type = QuestionType.SELECT_IMAGE;
        break;
    }

    switch (this.type) {
      case QuestionType.MCQ:
        each(payload.user_answers, o => this.userAnswers.push(new UserAnswer(o)));
        break;

      case QuestionType.SELECT_IMAGE:
        each(payload.user_answers, o => this.userAnswers.push(new SelectImageAnswerOption(o)));
        break;

      default:
        break;
    }

    this.setStatuses();
  }

  setStatuses() {
    this.isSuccess =
      filter(this.userAnswers, answer => answer.isCorrect && answer.isChecked).length ===
        filter(this.userAnswers, answer => answer.isCorrect).length &&
      filter(this.userAnswers, answer => !answer.isCorrect && answer.isChecked).length === 0;

    this.isFailed = !this.isSuccess && !!find(this.userAnswers, answer => answer.isChecked);

    this.notAnswer = !find(this.userAnswers, answer => answer.isChecked);
  }
}
