import { Module } from 'vuex';
import { RootState } from '../../types';
import { VideoState } from './types';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

export const state: VideoState = {
  videos: [],
  categories: [],
  selectedCategory: null,
  selectedTag: null,
  selectedSortMethod: 'default',
};

const namespaced: boolean = true;

export const video: Module<VideoState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
