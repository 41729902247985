<template>
  <AppModal
    v-if="isDisplayedModal"
    :is-displayed-close="false"
    :has-indents="false"
    :max-width="536"
  >
    <template>
      <template v-if="isDisplayedControls">
        <div
          v-if="isDisplayedPrev"
          class="app-blocked-messages__prev app-blocked-messages-arrow"
          @click="currentMessageIndex -= 1"
        >
          <ArrowSvg />
        </div>
        <div
          v-if="isDisplayedNext"
          class="app-blocked-messages__next app-blocked-messages-arrow"
          @click="nextMessage"
        >
          <ArrowSvg />
        </div>
      </template>

      <div class="app-blocked-messages">
        <div
          v-if="getCurrentMessage().hasImage()"
          class="app-blocked-messages__image"
        >
          <img
            :src="getCurrentMessage().image.origin"
            alt=""
          />
        </div>
        <div class="app-blocked-messages__tags">
          <span class="app-tag app-tag--medium app-tag--orange">
            {{ $t('messages_tag_important_news_text') }}
          </span>
          <span
            v-if="isMultipleMessages"
            class="app-tag app-tag--medium app-tag--gray"
          >
            {{
              $tc('common_d_from_d_text', blockedMessages.length, {
                0: currentMessageIndex + 1,
                1: blockedMessages.length,
              })
            }}
          </span>
        </div>
        <div class="app-blocked-messages__text">
          <AppMarkdown
            class="app-wysiwyg"
            :source="getCurrentMessage().text"
          />
        </div>
        <div class="app-blocked-messages__footer">
          <AppButton
            :is-large="true"
            :is-loading="false"
            :label="getCurrentMessage().unblockText"
            @click="nextMessage"
          />
        </div>
      </div>
    </template>
  </AppModal>
</template>

<script>
import AppModal from '@/components/helpers/modals/modal.vue';
import AppButton from '@/components/helpers/button.vue';
import ArrowSvg from '@/components/svg/icons/back-arrow.vue';
import { mapGetters } from 'vuex';
import { sendEvent } from '@/providers/analyticsProvider';
import AppMarkdown from '@/components/helpers/markdown.vue';

export default {
  name: 'blocked-messages',

  components: {
    AppModal,
    AppButton,
    ArrowSvg,
    AppMarkdown,
  },

  data: () => ({
    blockedMessages: [],
    pagination: null,
    currentMessageIndex: 0,
  }),

  computed: {
    ...mapGetters('auth', ['user']),

    ...mapGetters('helpers', ['isDesktop']),

    isDisplayedModal() {
      return this.blockedMessages.length > 0;
    },

    isMultipleMessages() {
      return this.blockedMessages.length > 1;
    },

    isDisplayedControls() {
      return this.isDesktop && this.isMultipleMessages;
    },

    isDisplayedPrev() {
      return this.currentMessageIndex !== 0;
    },

    isDisplayedNext() {
      return this.currentMessageIndex + 1 !== this.blockedMessages.length;
    },
  },

  async created() {
    await this.getBlockedMessages();
  },

  methods: {
    async getBlockedMessages() {
      this.blockedMessages = await this.$store.dispatch('messages/getBlockedMessages', {
        scope: 'block',
      });
    },

    getCurrentMessage() {
      return this.blockedMessages[this.currentMessageIndex];
    },

    async nextMessage() {
      if (this.getCurrentMessage().isBlocked) {
        await this.$store.dispatch('messages/unblockMessage', this.getCurrentMessage().id);
        sendEvent(this.$analytics, 'messages_block_read_btn');
        this.getCurrentMessage().markAsUnblocked();
      }

      if (this.currentMessageIndex + 1 === this.blockedMessages.length) {
        await this.$store.dispatch('auth/unblockMessages');

        return;
      }

      this.currentMessageIndex += 1;
    },
  },
};
</script>
