export default abstract class BaseAuthType {
  type: string;
  companyId: number;
  buttonLabel: string;
  isLoading: boolean = false;
  description: string;
  name: string;
  placeholder: string;

  constructor(payload: any) {
    this.type = payload.type;
    this.companyId = parseInt(payload.company_id, 10);
    this.buttonLabel = payload.button_label ? payload.button_label : '';
    this.description = payload.description;
    this.name = payload.name;
    this.placeholder = payload.placeholder ? payload.placeholder : '';
  }

  abstract getName(): string;
  abstract getDescription(): string;
}
