<template>
  <div class="app-auth-accesscode-content">
    <div
      v-if="logo"
      class="app-auth-accesscode__logo"
    >
      <img
        :src="logo"
        alt=""
      />
    </div>
    <div class="app-auth-accesscode">
      <div class="app-auth-accesscode__border"></div>

      <AuthHeader
        :title="$t('auth_access_code_text')"
        :subtitle="subtitle"
      />

      <p
        v-if="isAccessCodeStatus.log.attempts === 0 && expiresIn !== 0"
        class="app-auth-accesscode--tryagain"
      >
        {{ $t('auth_wrong_access_try_again_in_d_minutes_error_text', [secToTime(expiresIn)]) }}
      </p>

      <div class="app-auth-form">
        <form
          autocomplete="off"
          @submit.prevent="sendAccessCode"
        >
          <div
            v-if="showFormText"
            class="app-auth-form__item app-auth-form__item--accesscode"
          >
            <FormText
              v-model="isCode"
              :label-text="$t('auth_enter_access_code_placeholder_text')"
              :is-fullwidth="true"
              :error="errors.isCode"
            />

            <div
              v-if="showFormBtn && isAccessCodeStatus.log.attempts > 0"
              class="app-auth-accesscode__snoska"
            >
              {{
                $tc('auth_enter_access_code_text', isAccessCodeStatus.log.attempts, {
                  0: isAccessCodeStatus.log.attempts,
                })
              }}
            </div>
          </div>
          <div class="app-auth-form__item app-auth-form__item--button">
            <FormButton
              :is-submit="true"
              :is-disabled="isDisabled"
              :text="$t('common_confirm_button')"
              :is-loading="isLoading"
            />

            <a
              href=""
              class="app-auth-form__item--skip"
              @click.prevent="logout"
            >
              {{ $t('common_logout_button') }}
            </a>
          </div>
        </form>
      </div>

      <div class="app-auth-accesscode-overlay"></div>
    </div>
    <div class="app-auth-accesscode-footer">
      <div class="app-auth__version">{{ $t('auth_version_hint_text_web', [version]) }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AuthHeader from '@/components/app/auth/header';
import FormText from '@/components/form/text';
import FormButton from '@/components/form/button';

export default {
  name: 'access-code-modal',

  components: {
    AuthHeader,
    FormText,
    FormButton,
  },

  data: () => ({
    isCode: '',
    expiresIn: 0,
    version: '',

    isLoading: false,

    errors: {
      isCode: null,
    },
  }),

  computed: {
    ...mapGetters('auth', ['isAccessCode', 'isAccessCodeStatus']),

    ...mapGetters('settings', ['branding']),

    isDisabled() {
      return this.isCode.length === 0;
    },

    subtitle() {
      if (this.isAccessCodeStatus.log.attempts === 0 && this.expiresIn !== 0) {
        return this.$pt('auth_wrong_access_code_all_attempts_used_error_text');
      }

      if (this.isAccessCodeStatus.log.attempts !== 0 || this.expiresIn === 0) {
        return this.$pt('auth_get_access_code_from_manager_text');
      }

      return '';
    },

    logo() {
      if (this.branding && this.branding.coloredLogo) {
        return this.branding.coloredLogo;
      }

      return null;
    },

    showFormText() {
      return this.isAccessCodeStatus.log.attempts !== 0 || this.expiresIn === 0;
    },

    showFormBtn() {
      return !this.errors.isCode && this.isAccessCodeStatus.log.attempts !== -10;
    },
  },

  watch: {
    isCode() {
      this.errors.isCode = null;
    },
  },

  created() {
    this.$store.dispatch('helpers/hideBackground');
    this.version = process.env.VUE_APP_VERSION;

    if (!this.isAccessCode) {
      if (this.isAccessCodeStatus.log.attempts === 0) {
        this.getAccessCode();
      }
    }
  },

  methods: {
    sendAccessCode() {
      this.isLoading = true;

      this.$store
        .dispatch('auth/sendAccessCode', {
          code: this.isCode,
        })
        .then(() => {
          this.$store.dispatch('auth/enterAccessCode', {
            accessCode: true,
          });

          // eslint-disable-next-line
          // location.reload() был добавлен возможно с проблемой в авторизации
          // и из за этого запросы не возобновлялись после ввода кода доступа
          // я его оставлю если вдруг что то пойдет не так, уже будем знать что к чему
          location.reload();
        })
        .catch(error => {
          this.isAccessCodeStatus.log = error.getErrorLog(true);

          this.isLoading = false;

          if (error.getErrorLog(true).attempts === 0) {
            this.isCode = '';
            this.processNewCode(error.getErrorLog(true).expires_in);
          }
          this.errors.isCode = this.$tc(
            'auth_wrong_access_code_d_attempts_left_error_text',
            error.getErrorLog(true).attempts,
            { 0: error.getErrorLog(true).attempts },
          );
        });
    },

    getAccessCode() {
      this.$store.dispatch('auth/getAccessCode').then(response => {
        this.processNewCode(response);
      });
    },

    processNewCode(payload) {
      this.expiresIn = payload;

      if (this.expiresIn > 0) {
        const vh = this;

        const interval = setInterval(() => {
          vh.expiresIn -= 1;

          if (vh.expiresIn <= 0) {
            const codeStatus = {};
            codeStatus.attempts = 3;
            codeStatus.expiresIn = 900;

            this.$store.dispatch('auth/accessCodeStatus', {
              log: codeStatus,
            });

            vh.expiresIn = 0;

            clearInterval(interval);
          }
        }, 1000);
      }
    },

    secToTime(sec) {
      const dt = new Date();
      dt.setTime(sec * 1000);

      const minutes = dt.getUTCMinutes() < 10 ? `0${dt.getUTCMinutes()}` : dt.getUTCMinutes();

      const seconds = dt.getUTCSeconds() < 10 ? `0${dt.getUTCSeconds()}` : dt.getUTCSeconds();

      return `${minutes}:${seconds}`;
    },

    logout() {
      this.$store.dispatch('auth/logout');
      window.location.href = '/';
    },
  },
};
</script>
