import IUpdatable from '@/entities/common/testing/updates/IUpdatable';

export default class UpdateState implements IUpdatable {
  id: number;

  updatedAt: number;
  timestamp?: number;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);

    this.updatedAt = parseInt(payload.updated_at, 10);

    if (payload.timestamp) {
      this.timestamp = parseInt(payload.timestamp, 10);
    }
  }

  getTimestamp(): number {
    if (this.timestamp) {
      return this.timestamp;
    }

    return 0;
  }
}
