<template>
  <div class="app-locked-icon">
    <LockedSvg />
  </div>
</template>

<script>
import LockedSvg from '@/components/svg/icons/lock';

export default {
  name: 'app-locked-icon',

  components: {
    LockedSvg,
  },
};
</script>
