<template>
  <div class="app-notification-content">
    <transition-group
      :class="{
        'app-notification--center': isWhiteOverlay,
        'app-notification--bottom': isAuth,
      }"
      :name="notificationAnimate"
      tag="div"
      class="app-notification"
    >
      <AppNotification
        v-for="notification in notifications"
        :key="notification.id"
        :notification="notification"
      />
    </transition-group>

    <div
      v-if="notificationHasButton && !notificationHasNotOverlay"
      :class="{
        'app-notification-overlay--dark': isWhiteOverlay,
        'app-notification-overlay--transparent': hasTransparentOverlay,
      }"
      class="app-notification-overlay"
    ></div>
  </div>
</template>

<script>
import { find } from 'lodash';
import AppNotification from '@/components/helpers/notification/notification';
import CustomRoute from '@/entities/common/CustomRoute';

export default {
  name: 'notification-list',

  components: {
    AppNotification,
  },

  props: {
    notifications: {
      type: Array,
      default: null,
    },
  },

  computed: {
    notificationHasButton() {
      return !!find(this.notifications, o => o.buttons && o.buttons.length > 0);
    },

    // по умолчанию все уведомления с кнопками имеют оверлей, за исключением тех, у которых передан спец. флаг "hasNotOverlay"
    notificationHasNotOverlay() {
      return !!find(
        this.notifications,
        o => typeof o.hasNotOverlay !== 'undefined' && o.hasNotOverlay,
      );
    },

    isWhiteOverlay() {
      return !!find(this.notifications, o => o.isWhite);
    },

    hasTransparentOverlay() {
      return !!find(this.notifications, o => o.hasTransparentOverlay);
    },

    isAuth() {
      return (
        this.$route.name === CustomRoute.TO_AUTH_VERIFICATION_EMAIL ||
        this.$route.name === CustomRoute.TO_AUTH_VERIFICATION_EMAIL_VERIFY ||
        this.$route.name === CustomRoute.TO_AUTH_VERIFICATION_PHONE ||
        this.$route.name === CustomRoute.TO_AUTH_VERIFICATION_PHONE_VERIFY ||
        this.$route.name === CustomRoute.TO_AUTH_ENTRY_POINT ||
        this.$route.name === CustomRoute.TO_AUTH_LOGIN_PHONE ||
        this.$route.name === CustomRoute.TO_AUTH_LOGIN
      );
    },

    notificationAnimate() {
      return this.isWhiteOverlay ? 'fade' : 'slide-fade';
    },
  },
};
</script>
