import { i18n } from '@/lang';
import moment from 'moment';
import { has } from 'lodash';
// eslint-disable-next-line import/no-cycle
import { pt } from '@/plugins/lokalisePlugin';

export default class Badge {
  id: number;
  assignmentId: number;
  awardDate: number;
  badgeId: number;
  canReject: boolean;
  categoryId: number;
  assignerAvatar: string;
  comment: string;
  description: string;
  image: string;
  assignerName: string;
  assignerType: string;
  isNew: boolean;
  name: string;
  order: number;
  scores: number;
  updatedAt: number;
  isLimited: boolean;
  remainingCount: number;
  isAvailable: boolean;

  constructor(payload: any) {
    this.assignmentId = parseInt(payload.assignment_id, 10);
    this.awardDate = parseInt(payload.award_date, 10);
    this.badgeId = parseInt(payload.badge_id, 10);
    this.id = parseInt(payload.id, 10);
    this.canReject = parseInt(payload.can_reject, 10) === 1;
    this.categoryId = parseInt(payload.category_id, 10);
    this.assignerAvatar = payload.assigner_avatar ? payload.assigner_avatar.url : null;
    this.comment = payload.comment;
    this.description = payload.description;
    this.image = payload.icon.url;
    this.assignerName = payload.assigner_name;
    this.assignerType = payload.assigner_type;
    this.isNew = parseInt(payload.is_new, 10) === 1;
    this.name = payload.name;
    this.order = parseInt(payload.order, 10);
    this.scores = parseInt(payload.scores, 10);
    this.updatedAt = parseInt(payload.updated_at, 10);
    this.isLimited = parseInt(payload.is_limited, 10) === 1;
    this.remainingCount = parseInt(payload.remain_count, 10);
    this.isAvailable = has(payload, 'is_available')
      ? parseInt(payload.is_available, 10) === 1
      : true;
  }

  getAssignedName(): string {
    if (!this.assignmentId) {
      return '';
    }

    if (this.assignerType === 'manager' && !this.assignerName) {
      return pt('profile_manager_title_text');
    }

    if (this.assignerType === 'admin' && !this.assignerName) {
      return i18n.t('badges_administrator_text').toString();
    }

    return this.assignerName;
  }

  getCancelButtonText(): string {
    return this.scores >= 0
      ? i18n.t('myteam_revoke_award_text').toString()
      : i18n.t('myteam_revoke_warning_text').toString();
  }

  getAwardsScores(): string {
    if (this.scores > 0) {
      return i18n.tc('common_point_number_text', this.scores, { 0: this.scores.toLocaleString() });
    }

    return i18n.tc('common_point_number_text', this.scores * -1, {
      0: this.scores.toLocaleString(),
    });
  }

  getAwardsLimit(): string {
    if (this.scores >= 0) {
      if (this.remainingCount > 0) {
        return i18n.t('myteam_badges_limit_text').toString();
      }

      return i18n.t('myteam_for_this_user_text').toString();
    }

    return i18n.t('myteam_warns_limit_text').toString();
  }

  getAwardsCount(): string {
    return `${i18n
      .tc('common_d_counter_text', this.remainingCount, { 0: this.remainingCount })
      .toString()} ${
      this.remainingCount === 0 ? `, ${i18n.t('myteam_limit_reached_text').toString()}` : ''
    }`;
  }

  getDate(): string {
    return moment(this.awardDate * 1000).format('lll');
  }
}
