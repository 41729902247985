import each from 'lodash/each';
import find from 'lodash/find';
import ProgramStatistic from '@/entities/modules/learning-programs/ProgramStatistic';
import Program from './Program';
import Pagination from '../../common/Pagination';

export function createProgramsList(
  allowToSkip: boolean,
  programStatistics: ProgramStatistic[],
  payload: any,
): [Pagination, Program[]] {
  const pagination = new Pagination(payload.meta.pagination);

  const programs: Program[] = [];
  each(payload.learning_programs, lp => {
    const program = new Program(lp);

    program.setAllowToSkip(allowToSkip);

    const statistic = find(programStatistics, o => o.id === program.id);

    if (statistic) {
      program.setStatistic(statistic);
    }

    programs.push(program);
  });

  return [pagination, programs];
}
