// @ts-ignore
import createDate from '@/providers/js/dateProvider';

export default class KpiMeta {
  kpiStartDate: string;
  kpiEndDate: string;
  totalAmount: number;
  totalRatingAmount: number;

  constructor(payload: any) {
    this.kpiStartDate = createDate(payload.kpi_start_date);
    this.kpiEndDate = createDate(payload.kpi_end_date);
    this.totalAmount = parseFloat(payload.total_amount);
    this.totalRatingAmount = parseFloat(payload.total_rating_amount);
  }
}
