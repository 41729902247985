// Устанавливает cookie
export function setCookie(name, value, options) {
  const optionsData = options || {};

  let { expires } = optionsData;

  const { path } = optionsData;

  if (path && typeof path === 'string') {
    optionsData.path = path;
  } else {
    optionsData.path = '/';
  }

  if (typeof expires === 'number' && expires) {
    const d = new Date();
    d.setTime(d.getTime() + expires * 1000);
    optionsData.expires = d;
    expires = optionsData.expires;
  }

  if (expires && expires.toUTCString) {
    optionsData.expires = expires.toUTCString();
  }

  const convertValue = encodeURIComponent(value);

  let updatedCookie = `${name}=${convertValue}`;

  for (const propName in optionsData) {
    if (Object.prototype.hasOwnProperty.call(optionsData, propName)) {
      updatedCookie += `; ${propName}`;

      const propValue = optionsData[propName];

      if (propValue !== true) {
        updatedCookie += `=${propValue}`;
      }
    }
  }

  document.cookie = updatedCookie;
}

// Возвращает значение по cookie имени
export function getCookie(name) {
  const matches = document.cookie.match(
    new RegExp(
      // eslint-disable-next-line no-useless-escape
      `(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`,
    ),
  );

  return matches ? decodeURIComponent(matches[1]) : undefined;
}
