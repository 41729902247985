import { i18n } from '@/lang';

export const LearningItemStatuses = {
  APPOINTED: 'appointed',
  IN_PROGRESS: 'in_progress',
  CHECKING: 'checking',
  COMPLETED: 'completed',
  FAILED: 'fail',
};

export type LearningItemStatus = keyof typeof LearningItemStatuses;

const STATUS_STYLES = new Map([
  [LearningItemStatuses.IN_PROGRESS, 'gray'],
  [LearningItemStatuses.COMPLETED, 'success'],
  [LearningItemStatuses.FAILED, 'error'],
  [LearningItemStatuses.APPOINTED, 'gray'],
  [LearningItemStatuses.CHECKING, 'dark-gray'],
]);

const STATUS_KEYS = new Map([
  [LearningItemStatuses.IN_PROGRESS, 'common_in_progress_status_text'],
  [LearningItemStatuses.COMPLETED, 'common_completed_status_text'],
  [LearningItemStatuses.FAILED, 'common_failed_status_text'],
  [LearningItemStatuses.APPOINTED, 'common_assigned_lowcase_text'],
  [LearningItemStatuses.CHECKING, 'common_under_check_status_text'],
]);

export function getStatusStyle(status: string) {
  return STATUS_STYLES.get(status);
}

export function getStatusLabel(status: string) {
  const key = STATUS_KEYS.get(status);

  return key ? i18n.t(key) : '';
}
