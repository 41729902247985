export default class QuestionnaireQuestion {
  id: number;
  name: string;
  order: number;
  type: string;
  hint: string;
  isRequired: boolean;
  useRegex: boolean;
  availableValues: Object[];

  // данные пользователя введенные в форме
  value: any = '';
  fileInput: any = null;
  error: string = '';
  isSuccessfulValidation: boolean = false;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.name = payload.name;
    this.order = parseInt(payload.order, 10);
    this.type = payload.type;
    this.hint = payload.hint ? payload.hint : '';
    this.isRequired = parseInt(payload.is_required, 10) === 1;
    this.useRegex = parseInt(payload.use_regex, 10) === 1;
    this.availableValues = payload.available_values;
  }

  setValue(value: any) {
    this.value = value;
  }

  setFileInput(fileInput: any) {
    this.fileInput = fileInput;
  }

  isFilled(): boolean {
    return this.type === 'date'
      ? this.value && this.value instanceof Date
      : this.value && this.value.length > 0;
  }

  isValidFilled(): boolean {
    return this.type === 'date'
      ? this.value && this.value instanceof Date && this.isRequired && this.isSuccessfulValidation
      : this.value &&
          this.value.length > 0 &&
          this.isRequired &&
          this.error.length === 0 &&
          this.isSuccessfulValidation;
  }

  setError(error: string) {
    this.error = error;

    if (error !== '') {
      this.setIsSuccessfulValidation(false);
    }
  }

  setIsSuccessfulValidation(isSuccessfulValidation: boolean) {
    this.isSuccessfulValidation = isSuccessfulValidation;
  }
}
