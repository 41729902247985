import axios from 'axios';
import {
  paginationResolver,
  paginationResolverWithAddedMeta,
} from '@/providers/paginationProvider';
import { createGroupsList } from '@/entities/common/groups/RootGroup';
import { createChildrenGroupsList } from '@/entities/common/groups/ChildGroup';
import BaseGroup from '@/entities/common/groups/BaseGroup';
import { createSearchGroupsList } from '@/entities/common/groups/SearchResultGroup';

async function paginationGetRootGroups(page: number) {
  const response = await axios.get(`/groups/root-groups?page=${page}`);

  return createGroupsList(response.data.success);
}

async function paginationGetAvailableOnRegistrationGroups(
  page: number,
  id: number,
  rootId: number,
) {
  const response = await axios.post(
    `/groups/available-on-registration?page=${page}`,
    {
      group_id: id,
      root_id: rootId,
    },
    { headers: { globalErrorHandler: false } },
  );

  return createChildrenGroupsList(response.data.success);
}

export default {
  async getRootGroups() {
    return paginationResolver<BaseGroup>(paginationGetRootGroups, []);
  },

  async getAvailableOnRegistrationGroups(id: number, rootId: number) {
    return paginationResolverWithAddedMeta<BaseGroup>(paginationGetAvailableOnRegistrationGroups, [
      id,
      rootId,
    ]);
  },

  async getAvailableOnRegistrationGroupsByPage(id: number, rootId: number, page: number) {
    return paginationGetAvailableOnRegistrationGroups(page, id, rootId);
  },

  async getAvailableOnRegistrationGroupsBySearch(id: number, value: string, page: number) {
    const response = await axios.post(`/groups/available-on-registration/search?page=${page}`, {
      root_id: id,
      name: value,
    });

    return createSearchGroupsList(response.data.success);
  },
};
