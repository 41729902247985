import { MutationTree } from 'vuex';
import { sortBy } from 'lodash';
import { CompetenciesState } from './types';

export const mutations: MutationTree<CompetenciesState> = {
  setCompetenciesTests(state, payload) {
    state.materials = sortBy(payload, ['order']).reverse();
  },

  changeSelectedFilter(state, payload) {
    state.selectedFilter = payload;
  },
};
