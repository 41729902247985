import CorrectName from '@/entities/common/CorrectName';
import CustomRoute from '@/entities/common/CustomRoute';
import { NavigationGuardNext, Route } from 'vue-router';

export default [
  {
    path: '/lp/:moduleId-:moduleSlug/:id-:slug',
    meta: {
      title: 'common_learning_journeys_text',
      moduleCode: CorrectName.LEARNING_PROGRAMS,
      eventOnEnter: 'lj_program_open_btn',
      from: {
        'continue-learning': 'dashboard_continue_course_btn',
        'my-results': 'myresults_go_to_lj_btn',
      },
    },
    redirect: { name: CustomRoute.TO_LEARNING_PROGRAMS_MATERIAL },
    component: () =>
      import(/* webpackChunkName: "learning-programs" */ '@/views/learning-programs/show.vue'),
  },
  {
    path: '/lp/:moduleId-:moduleSlug/:id-:slug/video/:materialId-:materialSlug',
    meta: {
      title: 'common_learning_journeys_text',
      moduleCode: CorrectName.LEARNING_PROGRAMS,
      showIntercom: false,
      showBlockedMessages: false,
    },
    redirect: { name: CustomRoute.TO_LEARNING_PROGRAMS_VIDEO },
    component: () =>
      import(/* webpackChunkName: "learning-programs" */ '@/views/learning-programs/video.vue'),
  },
  {
    path: '/lp/:moduleId-:moduleSlug/:id-:slug/pdf/:materialId-:materialSlug',
    meta: {
      title: 'common_learning_journeys_text',
      moduleCode: CorrectName.LEARNING_PROGRAMS,
      eventOnEnter: 'lj_pdf_open_btn',
      showIntercom: false,
      showBlockedMessages: false,
    },
    redirect: { name: CustomRoute.TO_LEARNING_PROGRAMS_PDF },
    component: () =>
      import(/* webpackChunkName: "learning-programs" */ '@/views/learning-programs/pdf.vue'),
  },
  {
    path: '/lp/:moduleId-:moduleSlug/:id-:slug/document/:materialId-:materialSlug',
    meta: {
      title: 'common_learning_journeys_text',
      moduleCode: CorrectName.LEARNING_PROGRAMS,
      eventOnEnter: 'lj_document_open_btn',
      showIntercom: false,
      showBlockedMessages: false,
    },
    redirect: { name: CustomRoute.TO_LEARNING_PROGRAMS_DOCUMENT },
    component: () =>
      import(/* webpackChunkName: "learning-programs" */ '@/views/learning-programs/document.vue'),
  },
  {
    path: '/lp/:moduleId-:moduleSlug/:id-:slug/scorm/:materialId-:materialSlug',
    meta: {
      title: 'common_learning_journeys_text',
      moduleCode: CorrectName.LEARNING_PROGRAMS,
      eventOnEnter: 'lj_scorm_open_btn',
      showIntercom: false,
      showBlockedMessages: false,
    },
    redirect: { name: CustomRoute.TO_LEARNING_PROGRAMS_SCORM },
    component: () =>
      import(/* webpackChunkName: "learning-programs" */ '@/views/learning-programs/scorm.vue'),
  },
  {
    path: '/lp/:moduleId-:moduleSlug/:id-:slug/html/:materialId-:materialSlug',
    meta: {
      title: 'common_learning_journeys_text',
      moduleCode: CorrectName.LEARNING_PROGRAMS,
      eventOnEnter: 'lj_html_open_btn',
      showIntercom: false,
      showBlockedMessages: false,
    },
    redirect: { name: CustomRoute.TO_LEARNING_PROGRAMS_HTML },
    component: () =>
      import(/* webpackChunkName: "learning-programs" */ '@/views/learning-programs/html.vue'),
  },
  {
    path: '/lp/:moduleId-:moduleSlug/:id-:slug/link/:materialId-:materialSlug',
    meta: {
      title: 'common_learning_journeys_text',
      moduleCode: CorrectName.LEARNING_PROGRAMS,
      showIntercom: false,
      showBlockedMessages: false,
    },
    redirect: { name: CustomRoute.TO_LEARNING_PROGRAMS_LINK },
    component: () =>
      import(/* webpackChunkName: "learning-programs" */ '@/views/learning-programs/link.vue'),
  },
  {
    path: '/lp/:moduleId-:moduleSlug/:id-:slug/longread/:materialId-:materialSlug',
    meta: {
      title: 'common_learning_journeys_text',
      moduleCode: CorrectName.LEARNING_PROGRAMS,
      showIntercom: false,
      showBlockedMessages: false,
    },
    redirect: { name: CustomRoute.TO_LEARNING_PROGRAMS_LONGREAD },
    component: () =>
      import(/* webpackChunkName: "learning-programs" */ '@/views/learning-programs/longread.vue'),
  },
  {
    path: '/lp/:moduleId-:moduleSlug/:id-:slug/test/:materialId-:materialSlug',
    meta: {
      title: 'common_learning_journeys_text',
      moduleCode: CorrectName.LEARNING_PROGRAMS,
      moduleName: CorrectName.LEARNING_PROGRAMS,
    },
    redirect: { name: CustomRoute.TO_LEARNING_PROGRAMS_TEST },
    component: () =>
      import(
        /* webpackChunkName: "learning-programs" */ '@/views/learning-programs/test/start-page.vue'
      ),
  },
  {
    path: '/lp/:moduleId-:moduleSlug/:id-:slug/test/:materialId-:materialSlug/go',
    meta: {
      title: 'common_learning_journeys_text',
      moduleCode: CorrectName.LEARNING_PROGRAMS,
      eventOnEnter: 'lj_test_open_btn',
      showIntercom: false,
      showBlockedMessages: false,
      enableSidebar: false,
    },
    beforeEnter: (to: Route, from: Route, next: NavigationGuardNext) => {
      if (from.name === CustomRoute.TO_LEARNING_PROGRAMS_TEST && !to.params.passTest) {
        return next({
          name: CustomRoute.TO_LEARNING_PROGRAMS_MATERIAL,
          params: {
            moduleId: to.params.moduleId,
            id: to.params.id,
          },
        });
      }

      if (!to.params.passTest) {
        return next({
          name: CustomRoute.TO_LEARNING_PROGRAMS_TEST,
          params: {
            id: to.params.id,
            materialId: to.params.materialId,
            moduleId: to.params.moduleId,
          },
        });
      }

      next();
    },
    component: () =>
      import(
        /* webpackChunkName: "learning-programs" */ '@/views/learning-programs/test/passing.vue'
      ),
  },
  {
    path: '/lp/:moduleId-:moduleSlug/:id-:slug/test/:materialId-:materialSlug/results',
    meta: { title: 'common_learning_journeys_text', moduleCode: CorrectName.LEARNING_PROGRAMS },
    redirect: { name: CustomRoute.TO_LEARNING_PROGRAMS_TEST_RESULTS },
    component: () =>
      import(
        /* webpackChunkName: "learning-programs" */ '@/views/learning-programs/test/result-page.vue'
      ),
  },
  {
    path: '/lp/:moduleId-:moduleSlug/:id-:slug/test/:materialId-:materialSlug/correct',
    meta: {
      title: 'common_learning_journeys_text',
      moduleCode: CorrectName.LEARNING_PROGRAMS,
      eventOnEnter: 'lj_test_details_btn',
      showIntercom: false,
      showBlockedMessages: false,
      enableSidebar: false,
    },
    redirect: { name: CustomRoute.TO_LEARNING_PROGRAMS_TEST_MY_ANSWERS },
    component: () =>
      import(
        /* webpackChunkName: "learning-programs" */ '@/views/learning-programs/test/show-correct.vue'
      ),
  },
];
