<template>
  <button
    v-if="!isTargetBlank"
    :type="isSubmit ? 'submit' : 'button'"
    :disabled="isDisabled"
    :class="classes"
    class="v2-button"
    @click="onClick"
  >
    <template v-if="!isSlotAfter">
      <div class="v2-button__slot v2-button__slot--left">
        <slot></slot>
      </div>
    </template>
    <span>{{ label }}</span>
    <template v-if="isSlotAfter">
      <div class="v2-button__slot v2-button__slot--right">
        <slot></slot>
      </div>
    </template>
  </button>

  <a
    v-else
    :disabled="isDisabled"
    :class="classes"
    :href="to.path"
    target="_blank"
    rel="noopener noreferrer"
    class="v2-button"
    @click="$emit('click')"
    @click.middle="$emit('click')"
  >
    <template v-if="!isSlotAfter">
      <slot></slot>
    </template>
    <span>{{ label }}</span>
    <template v-if="isSlotAfter">
      <slot></slot>
    </template>
  </a>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'default',
      validator: value => ['default', 'circle'].includes(value),
    },

    view: {
      type: String,
      default: 'filled',
      validator: value => ['filled', 'outlined', 'flat'].includes(value),
    },

    color: {
      type: String,
      default: 'primary',
      validator: value =>
        ['primary', 'surfaceDisabledEmphasis', 'surfaceWhite', 'no-color'].includes(value),
    },

    isOneLine: {
      type: Boolean,
      default: false,
    },

    to: {
      type: Object,
      default: null,
    },

    label: {
      type: String,
      default: '',
    },

    isSubmit: {
      type: Boolean,
      default: false,
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isFullWidth: {
      type: Boolean,
      default: false,
    },

    isLarge: {
      type: Boolean,
      default: false,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    isSlotAfter: {
      type: Boolean,
      default: false,
    },

    isIconSmall: {
      type: Boolean,
      default: false,
    },

    isTargetBlank: {
      type: Boolean,
      default: false,
    },

    isThreeTwelfthSize: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classes() {
      const classes = [
        `v2-button--type-${this.type}`,
        `v2-button--view-${this.view}`,
        `v2-button--color-${this.color}`,
      ];

      if (this.isLoading) {
        classes.push('v2-button--loading');
      }

      if (this.isLarge) {
        classes.push('v2-button--large');
      }

      if (this.isFullWidth) {
        classes.push('v2-button--full-width');
      }

      if (this.isOneLine) {
        classes.push('v2-button--one-line');
      }

      if (this.isIconSmall) {
        classes.push('v2-button--icon-small');
      }

      if (this.isThreeTwelfthSize) {
        classes.push('v2-button--three-column');
      }

      return classes;
    },
  },

  methods: {
    onClick(event) {
      this.$emit('click', event);

      if (this.to) {
        if (this.to.byLocation) {
          location.href = this.to.path;
        } else {
          this.$router.push(this.to);
        }
      }
    },
  },
};
</script>
