export default class SectionMaterial {
  materialId: number;
  isRequired: boolean;
  isLocked: boolean;
  order: number;

  constructor(payload: any) {
    this.materialId = parseInt(payload.id, 10);
    this.isRequired = parseInt(payload.is_required, 10) === 1;
    this.isLocked = false;
    this.order = parseInt(payload.order, 10);
  }
}
