// eslint-disable-next-line max-classes-per-file
import each from 'lodash/each';
import sortBy from 'lodash/sortBy';

export class TestsScoresReport {
  constructor({ users, percent }) {
    this.users = users;
    this.percent = percent;
  }
}

class User {
  constructor({ id, name, image, scores, maxScores, userPercent }) {
    this.id = id;
    this.name = name;
    this.image = image;
    this.scores = scores;
    this.maxScores = maxScores;
    this.userPercent = userPercent;
  }
}

export function createTestsScoresReport(payload) {
  let users = [];

  each(payload.users, material => {
    users.push(
      Object.freeze(
        new User({
          id: material.id,
          name: material.name,
          image: material.image.url,
          scores: material.scores,
          maxScores: material.max_scores,
          userPercent: material.user_percent,
        }),
      ),
    );
  });

  users = sortBy(users, ['name']);

  return Object.freeze(
    new TestsScoresReport({
      users,
      percent: payload.percent,
    }),
  );
}
