import { ActionTree } from 'vuex';
import { each, find } from 'lodash';
import competenciesApi from '@/services/competencies';
import CorrectName from '@/entities/common/CorrectName';
import Test from '@/entities/modules/competencies/Test';
import Statistic from '@/entities/modules/competencies/Statistic';

import { CompetenciesState } from './types';
import { RootState } from '../../types';

export const actions: ActionTree<CompetenciesState, RootState> = {
  async syncMaterials({ rootState, state, commit }) {
    let competenciesTestsStatistics: Statistic[] = [];

    let competenciesTests: Test[] = [];

    let moduleSettings = null;

    if (find(rootState.settings.modules, o => o.correctName === CorrectName.COMPETENCY)) {
      moduleSettings = find(
        rootState.settings.modules,
        o => o.correctName === CorrectName.COMPETENCY,
      ).settings;
    }

    if (state.materials.length > 0) {
      const competenciesTestsUpdates = await competenciesApi.getCompetenciesUpdates();

      const newCompetenciesTestsId: number[] = [];

      const newCompetenciesTestStatisticsId: number[] = [];

      const actualCompetenciesTests: Test[] = [];

      const actualCompetenciesTestStatistics: Statistic[] = [];
      each(competenciesTestsUpdates, currentUpdate => {
        const competenciesTest = find(state.materials, o => o.id === currentUpdate.id);

        if (
          !competenciesTest ||
          (competenciesTest && competenciesTest.updatedAt !== currentUpdate.updatedAt)
        ) {
          newCompetenciesTestsId.push(currentUpdate.id);
        }

        if (competenciesTest && competenciesTest.updatedAt === currentUpdate.updatedAt) {
          actualCompetenciesTests.push(competenciesTest);
        }

        if (
          !competenciesTest ||
          competenciesTest?.getStatistics()?.timestamp !== currentUpdate.timestamp
        ) {
          newCompetenciesTestStatisticsId.push(currentUpdate.id);
        }

        if (
          competenciesTest &&
          competenciesTest.getStatistics()?.timestamp === currentUpdate.timestamp
        ) {
          // @ts-ignore
          actualCompetenciesTestStatistics.push(competenciesTest.getStatistics());
        }
      });

      if (newCompetenciesTestStatisticsId.length !== 0) {
        const newCompetenciesTestsStatistics = await competenciesApi.postCompetenciesStatistic(
          newCompetenciesTestStatisticsId,
        );
        actualCompetenciesTestStatistics.push(...newCompetenciesTestsStatistics);
      }

      if (newCompetenciesTestsId.length !== 0) {
        const newCompetenciesTests = await competenciesApi.postCompetencies(
          newCompetenciesTestsId,
          actualCompetenciesTestStatistics,
          moduleSettings,
        );
        actualCompetenciesTests.push(...newCompetenciesTests);
      }

      competenciesTestsStatistics = actualCompetenciesTestStatistics;
      competenciesTests = actualCompetenciesTests;
    } else {
      competenciesTestsStatistics = await competenciesApi.getCompetenciesStatistic();
      competenciesTests = await competenciesApi.getCompetencies(
        competenciesTestsStatistics,
        moduleSettings,
      );
    }

    commit('setCompetenciesTests', competenciesTests);
  },

  async getById({ state, dispatch }, id) {
    const material = find(state.materials, o => o.id === id);

    if (material) {
      return material;
    }

    await dispatch('syncMaterials');

    return find(state.materials, o => o.id === id);
  },

  async getNextMaterialFor({ state }, id) {
    let index = -1;
    each(state.materials, (test, position) => {
      if (test.id === id && position + 1 < state.materials.length) {
        index = position + 1;
      }
    });

    return index === -1 ? null : state.materials[index];
  },

  async getTestQuestions({ state }, { id, needsUuid }) {
    const material = find(state.materials, test => test.id === id);

    if (material) {
      const { questions, timestamp, turnUuid } = await competenciesApi.getQuestions(id, needsUuid);

      material.setQuestions(questions);

      if (turnUuid) {
        material.enableStrictMode(turnUuid, timestamp);
      }
    }
  },

  async save({ state }, { id, payload }) {
    const material = find(state.materials, test => test.id === id);

    if (!material) {
      return;
    }

    await competenciesApi.sendStatistic(material.getSaving(payload));

    const materialStatistic = await competenciesApi.getStatistic([id]);
    material.setStatistic(materialStatistic[0]);
  },

  async clearIsNew({}, id) {
    await competenciesApi.clearIsNew(id);
  },
};
