export default class Module {
  id: number;
  name: string;
  type: string;

  constructor(payload: any) {
    this.id = payload.id;
    this.type = payload.type;
    this.name = payload.name;
  }
}
