import { find, remove } from 'lodash';
import { getCurrentTimestamp } from '@/providers/dateProvider';

interface Tab {
  id: number;
  ts: number;
}

export default function multiTabsDetectProvider(callback: any): void {
  const id = getTabId();

  const ts = getCurrentTimestamp();

  let tabs: Tab[] = [];

  const json = localStorage.getItem('multi_tabs_detect_tabs');

  if (json) {
    tabs = JSON.parse(json);
  } else {
    localStorage.setItem('multi_tabs_detect_tabs', JSON.stringify(tabs));
  }

  remove(tabs, o => ts - o.ts > 6);

  const current = find(tabs, o => o.id === id);

  if (current) {
    current.ts = ts;
  } else {
    tabs.push({ id, ts });

    if (tabs.length > 1) {
      callback(tabs.length);
    }
  }

  localStorage.setItem('multi_tabs_detect_tabs', JSON.stringify(tabs));

  setInterval(updateTabTs, 5000);
}

function updateTabTs(): void {
  const id = getTabId();

  const ts = getCurrentTimestamp();

  let tabs: Tab[] = [];

  const json = localStorage.getItem('multi_tabs_detect_tabs');

  if (json) {
    tabs = JSON.parse(json);
  }

  const current = find(tabs, o => o.id === id);

  if (current) {
    current.ts = ts;
  }

  localStorage.setItem('multi_tabs_detect_tabs', JSON.stringify(tabs));
}

function generateTabId(): number {
  return Math.floor(Math.random() * 100000);
}

function getTabId(): number {
  const id = sessionStorage.getItem('multi_tabs_detect_id');

  if (id !== null) {
    return parseInt(id, 10);
  }

  const newId = generateTabId();
  sessionStorage.setItem('multi_tabs_detect_id', newId.toString());

  return newId;
}
