<template>
  <app-material-card
    :widget="infoMaterial"
    :has-outer-footer="true"
  >
    <a
      @click="markAsRead"
      @click.middle="markAsRead"
    ></a>
  </app-material-card>
</template>

<script>
import AppMaterialCard from '@/components/helpers/cards/material-card.vue';
import { sendEvent } from '@/providers/analyticsProvider';
import InfoMaterial from '@/entities/modules/dashboard/InfoMaterial';
import openExternalLink from '@/providers/externalLinkProvider';

export default {
  components: {
    AppMaterialCard,
  },

  props: {
    infoMaterial: {
      type: InfoMaterial,
      required: true,
    },
  },

  computed: {
    /**
     * Если это внутренняя ссылка
     */
    isInternal() {
      return this.infoMaterial.url.search(/^http/) === -1;
    },

    /**
     * Если это внешняя ссылка с токеном
     */
    isExternalWithToken() {
      return this.infoMaterial.url.search(/^http/) !== -1 && this.infoMaterial.useIntegrationToken;
    },
  },

  methods: {
    /**
     * Отметит ссылку как просмотренную
     * @returns {Promise<void>}
     */
    async markAsRead() {
      await this.$store.dispatch('information/markAsRead', this.infoMaterial.id);
      await this.$store.dispatch('information/sendViewStatistic', {
        id: this.infoMaterial.id,
        categoryId: this.infoMaterial.categoryId,
        viewDate: Math.round(new Date() / 1000),
      });

      await this.$store.dispatch('settings/updateMenuNewItems');
      sendEvent(this.$analytics, 'dashboard_focus_widget_material_btn');

      if (this.isExternalWithToken) {
        await openExternalLink(this.infoMaterial.url, true);
      } else if (this.isInternal) {
        window.location = this.infoMaterial.url;
      } else {
        window.open(this.infoMaterial.url, '_blank');
      }
    },
  },
};
</script>
