export interface PaginationScheme {
  current_page: number;
  pages_count: number;
  per_page: number;
  total_count: number;
}

export default class Pagination {
  currentPage: number;
  perPage: number;
  pagesCount: number;
  totalCount: number;

  constructor(payload: PaginationScheme) {
    this.currentPage = payload.current_page;
    this.perPage = payload.per_page;
    this.pagesCount = payload.pages_count;
    this.totalCount = payload.total_count;
  }

  setCurrentPage(currentPage: number) {
    this.currentPage = currentPage;
  }
}
