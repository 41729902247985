import SingleField from '@/entities/modules/tasks/SingleField';
import { each } from 'lodash';
import ImageSource, { createImageMdSource } from '@/entities/common/sources/ImageSource';
import Pagination from '@/entities/common/Pagination';
import { i18n } from '@/lang';
import moment, { Moment } from 'moment';
import MultiplyField from '@/entities/modules/tasks/MultiplyField';
import Field from '@/entities/modules/tasks/Field';
import FieldType from '@/entities/modules/tasks/FieldType';

export default class Task {
  id: number;
  categoryId: number;
  name: string;
  answersLimitType: string;
  isAnswersLimitReached: boolean;
  isNew: boolean;
  answersCount: number;
  answersLimit: number;
  maxPoints: number;
  points: number;
  description: string;
  isCheckRequired: boolean;
  isFavorite: boolean;
  isRating: boolean;
  submitForReviewText: string;
  successfulCompletionText: string;
  image?: ImageSource;
  fields: Field[];
  isRequiredGeolocationOrCamera: boolean;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.categoryId = parseInt(payload.category_id, 10);
    this.name = payload.name;
    this.answersLimitType = payload.answers_limit_type;
    this.isAnswersLimitReached = parseInt(payload.is_answers_limit_reached, 10) === 1;
    this.isNew = parseInt(payload.is_new, 10) === 1;
    this.answersCount = parseInt(payload.answers_count, 10);
    this.answersLimit = parseInt(payload.answers_limit, 10);
    this.description = payload.description;
    this.maxPoints = parseInt(payload.max_points, 10) || 0;
    this.points = parseInt(payload.points, 10);
    this.isCheckRequired = parseInt(payload.is_check_required, 10) === 1;
    this.isRating = parseInt(payload.is_rating, 10) === 1;
    this.submitForReviewText = payload.submit_for_review_text;
    this.successfulCompletionText = payload.successful_completion_text;
    this.image = createImageMdSource(payload.image);
    this.isFavorite = parseInt(payload.is_favorite, 10) === 1;
    this.isRequiredGeolocationOrCamera =
      parseInt(payload.is_required_sending_geolocation, 10) === 1;

    this.fields = [];
    each(payload.fields, field => {
      if (field.type === FieldType.MULTIPLY_CHOICE) {
        this.fields.push(new MultiplyField(field));
      } else {
        this.fields.push(new SingleField(field));
      }
    });
  }

  getLimitText(): string {
    let limitTypeText;

    if (this.answersLimitType === 'day') {
      limitTypeText = i18n.t('common_day_text_lowcase');
    } else if (this.answersLimitType === 'week') {
      limitTypeText = i18n.t('common_week_text_lowcase_acccase');
    } else if (this.answersLimitType === 'month') {
      limitTypeText = i18n.t('common_month_text_lowcase');
    } else {
      return '';
    }

    return i18n.tc('tasks_limit_for_s_reached', 0, { 0: limitTypeText });
  }

  getComeBackText(): string {
    let comeBackText;

    if (this.answersLimitType === 'day') {
      comeBackText = i18n.t('tasks_come_back_tomorrow_hint_text');
    } else if (this.answersLimitType === 'week') {
      const date = moment().add(7, 'days');
      comeBackText = i18n.tc('tasks_come_back_at_hint_text', 0, { 0: this.getFormattedDate(date) });
    } else if (this.answersLimitType === 'month') {
      const date = moment().add(1, 'month');
      comeBackText = i18n.tc('tasks_come_back_at_hint_text', 0, { 0: this.getFormattedDate(date) });
    } else {
      return '';
    }

    return String(comeBackText);
  }

  getFormattedDate(date: Moment) {
    return moment(date).format('D MMMM YYYY');
  }

  getFields(): Field[] {
    return this.fields;
  }

  setFavorite(status: boolean): void {
    this.isFavorite = status;
  }

  getSaving(): any {
    const answers: object[] = [];
    each(this.fields, field => {
      answers.push(field.getSaving());
    });

    return answers;
  }
}

export function createTasksList(payload: any): [Pagination, Task[], Boolean] {
  const pagination = new Pagination(payload.meta.pagination);

  const hasNew = parseInt(payload.meta.has_new, 10) === 1;

  const tasks: Task[] = [];

  each(payload.tasks, (taskItem: Task) => {
    tasks.push(new Task(taskItem));
  });

  return [pagination, tasks, hasNew];
}
