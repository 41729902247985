import SearchResult from '@/entities/modules/search/SearchResult';
import CorrectName from '@/entities/common/CorrectName';
import { UserStatus } from '@/entities/modules/events/UserStatus';
import MaterialStatus from '@/entities/common/testing/MaterialStatus';

export default class SearchResultMaterial extends SearchResult {
  type: string;
  learningProgramId: number;
  learningProgramName: number;
  isLocked: boolean;
  status?: UserStatus;

  constructor(payload: any) {
    super(payload);

    this.learningProgramId = payload.additional_data
      ? parseInt(payload.additional_data.learning_program_id, 10)
      : 0;
    this.learningProgramName = payload.additional_data
      ? payload.additional_data.learning_program_name
      : '';
    this.isLocked = payload.additional_data
      ? parseInt(payload.additional_data.availability, 10) === 0
      : false;
    this.moduleCode = CorrectName.MATERIAL;
    this.type = payload.type;

    let lokaliseKey = '';

    let materialStatus = payload.additional_data.status;

    switch (materialStatus) {
      case MaterialStatus.PASSED:
        break;

      case 'completed': {
        lokaliseKey = 'common_completed_status_text';
        materialStatus = MaterialStatus.PASSED;
        break;
      }

      case 'fail': {
        lokaliseKey = 'common_failed_status_text';
        materialStatus = MaterialStatus.FAILED;
        break;
      }

      case MaterialStatus.IN_PROGRESS: {
        lokaliseKey = 'common_in_progress_status_text';
        break;
      }

      case 'appointed': {
        lokaliseKey = 'common_assigned_lowcase_text';
        materialStatus = MaterialStatus.ASSIGNED;
        break;
      }

      case MaterialStatus.CHECKING: {
        lokaliseKey = 'common_under_check_status_text';
        break;
      }

      default:
        break;
    }
    this.status = new UserStatus(materialStatus, lokaliseKey, '');
  }

  getMaterialTypeKey() {
    switch (this.type) {
      case 'pdf': {
        return 'common_pdf_text';
      }

      case 'link': {
        return 'common_link_tag_text';
      }

      case 'scorm': {
        return 'learn_course_digital_course_text';
      }

      case 'html': {
        return 'learn_course_interactive_presentation_text';
      }

      case 'test': {
        return 'common_test_lowcase_text';
      }

      case 'video': {
        return 'common_video_text';
      }

      case 'longread': {
        return 'common_longread_title';
      }

      case 'document': {
        return 'lj_document_document_type_label_text';
      }

      default:
        break;
    }
  }
}
