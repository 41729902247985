// eslint-disable-next-line import/no-cycle
import Badge from '@/entities/common/Badge';
import BadgesCategory from '@/entities/modules/rating/BadgesCategory';
import { each } from 'lodash';

export default class BadgeInfo {
  badges: Badge[];
  categories: BadgesCategory[];

  constructor(payload: any) {
    this.badges = this.getBadges(payload);
    this.categories = this.getBadgesCategories(payload);
  }

  private getBadges(payload: any): Badge[] {
    const badges: Badge[] = [];
    each(payload.data.success.badges, badge => {
      badges.push(new Badge(badge));
    });

    return badges;
  }

  private getBadgesCategories(payload: any): BadgesCategory[] {
    const categories: BadgesCategory[] = [];
    each(payload.data.success.categories, category => {
      categories.push(Object.freeze(new BadgesCategory(category)));
    });

    return categories;
  }
}
