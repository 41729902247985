import { find, each, cloneDeep, sortBy } from 'lodash';

export function sortModuleElementsProvider(moduleList: any[], elements: any[]): any[] {
  let elementsCopy = cloneDeep(elements);
  each(elementsCopy, element => {
    if (!element.moduleId) {
      // eslint-disable-next-line no-param-reassign
      element.sortIndex = 0;
    }

    const module = find(moduleList, moduleItem => moduleItem.id === element.moduleId);

    if (module) {
      // eslint-disable-next-line no-param-reassign
      element.sortIndex = module.sortIndex;
      element.moduleName = module.title;
    }
  });

  elementsCopy = sortBy(elementsCopy, ['sortIndex']);

  each(elementsCopy, element => {
    // eslint-disable-next-line no-param-reassign
    delete element.sortIndex;
  });

  return elementsCopy;
}
