import { each } from 'lodash';
import QuestionAnswer from '@/entities/common/testing/QuestionAnswer';
import dateProvider from '@/providers/dateProvider';

export default class Statistic {
  interviewId: number;
  timestamp: number;
  startTime?: Date;
  completeTime?: Date;
  completed: Boolean;
  questionAnswers: QuestionAnswer[] = [];
  currentTurn: number = 0;

  constructor(payload: any) {
    this.interviewId = parseInt(payload.id, 10);
    this.timestamp = parseInt(payload.timestamp, 10);
    this.startTime = dateProvider(payload.start_time);
    this.completeTime = dateProvider(payload.complete_date);
    this.completed = parseInt(payload.completed, 10) === 1;

    if (this.completed) {
      this.currentTurn = 1;
    }

    each(payload.user_results, o => this.questionAnswers.push(new QuestionAnswer(o)));
  }
}
