import { has } from 'lodash';

export default abstract class BasePolicy {
  id: number;
  type: string;
  order: number;

  isSuccess?: boolean;

  protected constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.type = payload.type;
    this.order = parseInt(payload.order, 10);

    if (has(payload, 'is_success')) {
      this.isSuccess = parseInt(payload.is_success, 10) === 1;
    }
  }

  abstract getLabel(): string;
}
