import moment from 'moment';
import 'moment/locale/ru';
import each from 'lodash/each';
import find from 'lodash/find';
import has from 'lodash/has';
import uniq from 'lodash/uniq';
import { i18n } from '@/lang';
// @ts-ignore
import { getCurrentTimestamp } from '@/providers/js/dateProvider';
import Section from '@/entities/modules/learning-programs/Section';
import SectionMaterial from '@/entities/modules/learning-programs/SectionMaterial';
import MaterialStatus from '@/entities/common/testing/MaterialStatus';
import ToggleSection from '@/entities/modules/learning-programs/ToggleSection';
import ToggleSectionMaterial from '@/entities/modules/learning-programs/ToggleSectionMaterial';
import IStatistic from '@/entities/modules/learning-programs/IStatistic';
import Certificate from '@/entities/modules/learning-programs/Certificate';
// @ts-ignore
import { createMaterialCardWidget } from '@/entities/common/MaterialCardWidget';
import slugProvider from '@/providers/slugProvider';
import Feedback from '@/entities/modules/learning-programs/feedback/Feedback';
import ProgramStatistic from '@/entities/modules/learning-programs/ProgramStatistic';
import ApiName from '@/entities/common/ApiName';
import NumberingType, {
  getNumberingTypeByCode,
} from '@/entities/modules/learning-programs/NumberingType';
import BaseMaterial from '@/entities/modules/learning-programs/BaseMaterial';
import CustomRoute from '@/entities/common/CustomRoute';
import IRouteMaterial from '@/entities/common/interfaces/routes/IRouteMaterial';
import IRouteModule from '@/entities/common/interfaces/routes/IRouteModule';
import { getDeadlineStatusMaterial, getRemainingTime } from '@/providers/deedlineProvider';
import CorrectName from '@/entities/common/CorrectName';
import ICommentable from '@/entities/common/interfaces/ICommentable';
import UpDownPagination from '@/entities/common/UpDownPagination';
import { filter, map } from 'lodash';
import OffsetPagination from '@/entities/common/OffsetPagination';
import PaginationDirection from '@/entities/common/PaginationDirection';
import LearningItem from '@/entities/modules/learning-items/LearningItem';
import Tag from './Tag';
import Category from './Category';
import UserComment from '../messages/UserComment';

export default class Program implements IRouteMaterial, IRouteModule, ICommentable {
  id: number;

  customRoute: CustomRoute = CustomRoute.TO_LEARNING_PROGRAMS_MATERIAL;

  category: Category;
  tags: Tag[] = [];

  private sections: Section[] = [];
  private materials: BaseMaterial[] = [];
  private feedback: Feedback;
  private statistic: ProgramStatistic;

  passingDeadline: number;
  passingNoticeDeadline: number;

  moduleId: number;
  moduleName: string;
  moduleSlug: string;
  moduleCode: CorrectName = CorrectName.LEARNING_PROGRAMS;

  name: string;
  slug: string;
  description: string;
  image: string;
  updatedAt: number;
  order: number;

  isRating: boolean = false;
  isRequired: boolean = false;
  maxPoints: number = 0;
  maxBonusPoints: number = 0;
  bonusPoints: number = 0;
  maxPenaltyPoints: number = 0;
  maxProgramPoints: number = 0;
  penaltyPoints: number = 0;
  isFavorite: boolean = false;
  numberingType: NumberingType = NumberingType.NONE;
  feedbackAverageMark: number = 0;
  feedbackCount: number = 0;
  isAnotherUserFeedbackAvailable: boolean = false;
  isFeedbackAvailable: boolean = false;
  isFeedbackCommentAvailable: boolean = false;

  allowToSkip: boolean = false;

  isCommentingAllowed: boolean = false;
  comments: number = 0;
  commentsList: UserComment[] = [];
  isCommented: boolean = false;
  newComments: number = 0;
  commentsPagination?: UpDownPagination | undefined;
  isAllowToSkipMaterials: boolean;

  track: LearningItem | null = null;

  minNumbersOfFeedback: number = 5;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.order = parseInt(payload.order, 10);
    this.name = payload.name;
    this.slug = slugProvider(payload.name);
    this.description = payload.description;
    this.updatedAt = parseInt(payload.updated_at, 10);
    this.isRequired = parseInt(payload.is_required, 10) === 1;
    this.maxPoints = parseInt(payload.max_points, 10);
    this.maxBonusPoints = parseInt(payload.max_bonus_points, 10);
    this.maxPenaltyPoints = parseInt(payload.max_penalty_points, 10);
    this.maxProgramPoints = parseInt(payload.max_program_points, 10);
    this.isRating = this.maxPoints > 0 || this.maxProgramPoints > 0;
    this.isFavorite = parseInt(payload.is_favorite, 10) === 1;
    this.passingDeadline = parseInt(payload.deadline_for_passing_at, 10);
    this.passingNoticeDeadline = parseInt(payload.deadline_for_passing_notice_for_time, 10);
    this.image = payload.image_wide.xlg.url;
    this.feedback = this.getFeedback();
    this.statistic = this.getStatistic();
    this.numberingType = getNumberingTypeByCode(payload.numbering_type);
    this.feedbackAverageMark = parseFloat(payload.feedback_average_mark);
    this.feedbackCount = parseInt(payload.feedback_count, 10);
    this.isAnotherUserFeedbackAvailable =
      parseInt(payload.is_another_user_feedback_available, 10) === 1;
    this.isFeedbackAvailable = parseInt(payload.is_feedback_available, 10) === 1;
    this.isFeedbackCommentAvailable = parseInt(payload.is_feedback_comment_available, 10) === 1;

    this.isCommentingAllowed = parseInt(payload.allow_commenting, 10) === 1;
    this.comments = parseInt(payload.count_comments, 10);
    this.isCommented = parseInt(payload.is_commented, 10) === 1;
    this.newComments = parseInt(payload.count_new_comments, 10);
    this.isAllowToSkipMaterials = parseInt(payload.allow_skip_material, 10) === 1;

    each(payload.tags, tag => {
      this.tags.push(new Tag(tag));
    });

    let position = 1;
    each(payload.sections, section => {
      // не обрабатываем секции у которых нет материалов
      if (section.materials.length > 0) {
        this.sections.push(new Section(section, position));
        position += 1;
      }
    });

    this.category = new Category(payload.category);
    this.moduleId = parseInt(payload.module_id, 10);
    this.moduleName = payload.module_name;
    this.moduleSlug = slugProvider(payload.module_name);
  }

  setCommentsPagination(pagination: OffsetPagination, paginationDirection: PaginationDirection) {
    if (paginationDirection === PaginationDirection.DEFAULT || !this.commentsPagination) {
      this.commentsPagination = new UpDownPagination(pagination);
    } else if (this.commentsPagination && paginationDirection === PaginationDirection.UP) {
      this.commentsPagination.setMinOffset(pagination);
    } else if (this.commentsPagination && paginationDirection === PaginationDirection.DOWN) {
      this.commentsPagination.setMaxOffset(pagination);
    }
  }

  setCommentsList(comments: UserComment[], total: number) {
    this.commentsList = comments;
    this.comments = total;
  }

  getUnreadComments(): UserComment[] {
    return filter(this.commentsList, comment => comment.isNew);
  }

  getReadComments(): UserComment[] {
    return filter(this.commentsList, comment => !comment.isNew);
  }

  isFirstPageComments(): boolean {
    return !!(this.commentsPagination && this.commentsPagination.isFirstPage());
  }

  isLastPageComments(): boolean {
    return !!(this.commentsPagination && this.commentsPagination.isLastPage());
  }

  markCommentsAsRead() {
    const isNewCommentsCount = filter(this.commentsList, comment => comment.isNew).length;
    this.newComments -= isNewCommentsCount;
    each(this.commentsList, comment => comment.markAsRead());
  }

  getCommentsIsNewIds() {
    return map(
      filter(this.commentsList, o => o.isNew),
      'id',
    );
  }

  markAsCommented() {
    this.isCommented = true;
  }

  markAllCommentsAsRead() {
    this.newComments = 0;
  }

  setIsCommentingAllowed(isCommentingAllowed: boolean) {
    this.isCommentingAllowed = isCommentingAllowed;
  }
  /**
   * Установит возможность пропускать программу обучения
   * @param allowToSkip
   */
  setAllowToSkip(allowToSkip: boolean) {
    this.allowToSkip = allowToSkip;
  }

  setMaterials(materials: BaseMaterial[]): void {
    this.materials = materials;

    // обновим статусы, замки на материалах и т.д.
    this.recalculate();

    // обновим порядковые номера
    this.recalculateNumberingTypes();
  }

  getMaterials(): BaseMaterial[] {
    return this.materials;
  }

  /**
   * Вернет список материалов (ID) программы обучения
   * (сгруппированный по ID)
   */
  getListOfMaterialIds(): number[] {
    const list: number[] = [];

    each(this.sections, (section: Section) => {
      each(section.materials, (sectionMaterial: SectionMaterial) => {
        list.push(sectionMaterial.materialId);
      });
    });

    return uniq(list);
  }

  /**
   * Вернет секцию по ID
   * @param id
   */
  getSectionById(id: number): Section | undefined {
    return find(this.sections, o => o.id === id);
  }

  /**
   * Вернет секцию по ID материала
   * @param id
   */
  getSectionByMaterialId(id: number): Section | undefined {
    return find(
      this.sections,
      section => !!find(section.materials, material => material.materialId === id),
    );
  }

  /**
   * Вернет материал по ID
   * @param id
   */
  getMaterialById(id: number): BaseMaterial | undefined {
    return find(this.materials, o => o.id === id);
  }

  /**
   * Вернет материалы по ID секции
   * @param id
   */
  getMaterialsBySectionId(id: number): BaseMaterial[] {
    const section = this.getSectionById(id);

    if (!section) {
      return [];
    }

    const materials: BaseMaterial[] = [];

    // !!!
    // мы не можем просто взять и смаппить id материалов и вернуть по фильтру,
    // т.к. должны сохранить порядок из админки
    // поэтому each

    each(section.materials, sectionMaterial => {
      const material = find(this.materials, o => o.id === sectionMaterial.materialId);

      if (material) {
        materials.push(material);
      }
    });

    return materials;
  }

  /**
   * Вернет все секции
   */
  getToggleSections(): ToggleSection[] {
    const sections: ToggleSection[] = [];

    each(this.sections, (section: Section) => {
      sections.push(section.getToggleWidget(this.getMaterialsBySectionId(section.id)));
    });

    return sections;
  }

  /**
   * Вернет материалы по ID секции
   * @param sectionId
   * @param config
   */
  getToggleMaterialsBySectionId(sectionId: number, config: any): ToggleSectionMaterial[] {
    const materialsList: ToggleSectionMaterial[] = [];

    const section = this.getSectionById(sectionId);

    if (!section) {
      return [];
    }

    const materials = this.getMaterialsBySectionId(sectionId);
    each(materials, material => {
      const sectionMaterial = find(
        section.materials,
        (o: SectionMaterial) => o.materialId === material.id,
      );

      if (sectionMaterial) {
        materialsList.push(material.getToggleWidget(sectionMaterial, config));
      }
    });

    return materialsList;
  }

  /**
   * Вернет true, если материал обязательный
   * @param id
   */
  isMaterialIsRequired(id: number): boolean {
    const section = this.getSectionByMaterialId(id);

    if (!section) {
      return false;
    }

    const material = find(section.materials, o => o.materialId === id);

    if (material) {
      return material.isRequired;
    }

    return false;
  }

  /**
   * Пересчитает статусы программы обучения
   */
  recalculate() {
    let isFirstRequiredNotPassed = false;
    each(this.sections, (section: Section) => {
      each(section.materials, (sectionMaterial: SectionMaterial) => {
        // если пользователь может изучать материалы в любом порядке, просто разрешаем
        if (this.allowToSkip || this.isAllowToSkipMaterials) {
          // eslint-disable-next-line no-param-reassign
          sectionMaterial.isLocked = false;

          return;
        }

        const material = this.getMaterialById(sectionMaterial.materialId);

        if (material) {
          const status = material.getStatistic().getMaterialStatus();

          if (isFirstRequiredNotPassed) {
            // eslint-disable-next-line no-param-reassign
            sectionMaterial.isLocked = true;
          } else if (sectionMaterial.isRequired) {
            // eslint-disable-next-line no-param-reassign
            sectionMaterial.isLocked = false;

            if (status !== MaterialStatus.PASSED) {
              isFirstRequiredNotPassed = true;
            }
          } else {
            // eslint-disable-next-line no-param-reassign
            sectionMaterial.isLocked = false;
          }
        }
      });
    });
  }

  recalculateNumberingTypes() {
    let through = 0;

    let sections = 0;

    each(this.sections, section => {
      sections = 0;

      each(section.materials, sectionMaterial => {
        through += 1;
        sections += 1;

        const material = this.getMaterialById(sectionMaterial.materialId);

        if (material) {
          material.setNumberingTypes(through, sections, section.position);
        }
      });
    });
  }

  // локальный пересчет статуса и процента прохождения
  getStatisticByMaterials() {
    const programStatus = this.getProgramStatus();
    this.statistic.isInProgress = programStatus === MaterialStatus.IN_PROGRESS;
    this.statistic.isPassed = programStatus === MaterialStatus.PASSED;
    this.statistic.isFailed = programStatus === MaterialStatus.FAILED;
    this.statistic.isAssigned = programStatus === MaterialStatus.ASSIGNED;
    this.statistic.isChecking = programStatus === MaterialStatus.CHECKING;
    this.statistic.userPoints = this.getUserScore();
    this.statistic.percent = this.getProgressPercent();
  }

  /**
   * Вернет TRUE, если у программы обучения все материалы в статусе НАЗНАЧЕН
   * @private
   */
  private hasOnlyAssignedStatistic(): boolean {
    let hasOnlyAssignedStatistic = true;

    each(this.materials, (material: BaseMaterial) => {
      if (material.getStatistic().getMaterialStatus() !== MaterialStatus.ASSIGNED) {
        hasOnlyAssignedStatistic = false;
      }
    });

    return hasOnlyAssignedStatistic;
  }

  /**
   * Вернет TRUE, если в программе обучения есть хотя бы один обязательный материал
   * @private
   */
  private hasRequiredMaterials(): boolean {
    let hasRequiredMaterials = false;

    each(this.sections, (section: Section) => {
      each(section.materials, (sectionMaterial: SectionMaterial) => {
        if (sectionMaterial.isRequired) {
          hasRequiredMaterials = true;
        }
      });
    });

    return hasRequiredMaterials;
  }

  /**
   * Вернет TRUE, если ЕСТЬ обязательные материалы и ВСЕ ОБЯЗАТЕЛЬНЫЕ с нужным статусом
   * ИЛИ
   * Вернет TRUE, если НЕТ обязательных материалов и ВСЕ материалы с нужным статусом
   * @param hasRequiredMaterials
   * @param neededMaterialStatus
   * @private
   */
  private isAllMaterialsHasNeededStatus(
    hasRequiredMaterials: boolean,
    neededMaterialStatus: MaterialStatus,
  ): boolean {
    let isAllMaterialsHasNeededStatus = true;

    each(this.sections, (section: Section) => {
      each(section.materials, (sectionMaterial: SectionMaterial) => {
        if ((hasRequiredMaterials && sectionMaterial.isRequired) || !hasRequiredMaterials) {
          const material = this.getMaterialById(sectionMaterial.materialId);

          if (material && material.getStatistic().getMaterialStatus() !== neededMaterialStatus) {
            isAllMaterialsHasNeededStatus = false;
          }
        }
      });
    });

    return isAllMaterialsHasNeededStatus;
  }

  /**
   * Вернет TRUE, если ЕСТЬ обязательные материалы и хотя бы один ИЗ ОБЯЗАТЕЛЬНЫХ материалов с нужным статусом
   * ИЛИ
   * Вернет TRUE, если НЕТ обязательных материалов и хотя бы один ИЗ НЕОБЯЗАТЕЛЬНЫХ материалов с нужным статусом
   * @param hasRequiredMaterials
   * @param neededMaterialStatus
   * @private
   */
  private isAnyMaterialsHasNeededStatus(
    hasRequiredMaterials: boolean,
    neededMaterialStatus: MaterialStatus,
  ): boolean {
    let isAnyMaterialsHasNeededStatus = false;

    each(this.sections, (section: Section) => {
      each(section.materials, (sectionMaterial: SectionMaterial) => {
        if ((hasRequiredMaterials && sectionMaterial.isRequired) || !hasRequiredMaterials) {
          const material = this.getMaterialById(sectionMaterial.materialId);

          if (material && material.getStatistic().getMaterialStatus() === neededMaterialStatus) {
            isAnyMaterialsHasNeededStatus = true;
          }
        }
      });
    });

    return isAnyMaterialsHasNeededStatus;
  }

  /**
   * Вернет статус прохождения программы обучения
   * @private
   */
  private getProgramStatus(): MaterialStatus {
    let status = MaterialStatus.ASSIGNED;

    // если новый
    if (this.statistic.isNew) {
      status = MaterialStatus.NEW;
    } else if (!this.hasOnlyAssignedStatistic()) {
      // если ВСЕ МАТЕРИАЛЫ в статусе НАЗНАЧЕН -- очевидно (но это не точно),
      // что у программы обучения статус НАЗНАЧЕН

      const hasRequiredMaterials = this.hasRequiredMaterials();

      if (this.isAllMaterialsHasNeededStatus(hasRequiredMaterials, MaterialStatus.PASSED)) {
        status = MaterialStatus.PASSED;
      } else if (this.isAnyMaterialsHasNeededStatus(hasRequiredMaterials, MaterialStatus.FAILED)) {
        status = MaterialStatus.FAILED;
      } else if (
        this.isAnyMaterialsHasNeededStatus(hasRequiredMaterials, MaterialStatus.CHECKING)
      ) {
        status = MaterialStatus.CHECKING;
      } else {
        status = MaterialStatus.IN_PROGRESS;
      }
    }

    return status;
  }

  /**
   * Вернет процент прохождения программы обучения
   */
  private getProgressPercent(): number {
    const hasRequiredMaterials = this.hasRequiredMaterials();

    let countOfNeededMaterials = 0;

    let countOfPassedNeededMaterials = 0;

    each(this.sections, (section: Section) => {
      each(section.materials, (sectionMaterial: SectionMaterial) => {
        if ((hasRequiredMaterials && sectionMaterial.isRequired) || !hasRequiredMaterials) {
          countOfNeededMaterials += 1;

          const material = this.getMaterialById(sectionMaterial.materialId);

          if (material && material.getStatistic().getMaterialStatus() === MaterialStatus.PASSED) {
            countOfPassedNeededMaterials += 1;
          }
        }
      });
    });

    if (countOfNeededMaterials === 0) {
      return 0;
    }

    return Math.floor((countOfPassedNeededMaterials / countOfNeededMaterials) * 100);
  }

  /**
   * Вернет сколько заработал юзер
   */
  private getUserScore(): number {
    let score = 0;

    each(this.sections, section => {
      each(section.materials, sectionMaterial => {
        const material = find(this.materials, o => o.id === sectionMaterial.materialId);

        if (material && material.isRating) {
          score += material.getStatistic().getPoints();
        }
      });
    });

    return score;
  }

  /**
   * Вернет дату начала прохождения программы обучения
   */
  getStartDate(): Date | undefined {
    return this.statistic.startTime;
  }

  getDeadlineStatus(): string {
    return getDeadlineStatusMaterial(
      this.passingDeadline,
      this.passingNoticeDeadline,
      this.statistic.endTime,
      this.statistic.isFailed,
      this.statistic.isPassed,
    );
  }

  getDatePassing(): string {
    const { endTime, isPassed, isFailed } = this.statistic;

    const currentTimestamp = getCurrentTimestamp();

    const dateDiff = this.passingDeadline * 1000 - currentTimestamp * 1000;

    const formatDayMonth = moment(this.passingDeadline * 1000).format('D MMMM');

    const formatTime = moment(this.passingDeadline * 1000).format('LT');

    const formatForPassed = `, ${formatDayMonth}, ${formatTime}`;

    const defaultFormat = `${formatDayMonth}, ${formatTime}`;

    const commonPassDefault = i18n.tc('common_pass_by_date_text', 0, { 0: defaultFormat });

    if (!isPassed && !isFailed) {
      if (dateDiff > 0) {
        const remainingTime = getRemainingTime(this.passingDeadline);

        return `${commonPassDefault} (${remainingTime})`;
      }

      // (Не изучено в срок)
      if (dateDiff < 0) {
        return i18n.tc('common_not_passed_the_deadline_date_time_text', 0, { 0: formatForPassed });
      }
    }

    if (isPassed || isFailed) {
      // (Изучено в срок)
      if (dateDiff > 0) {
        return i18n.tc('common_passed_the_deadline_date_time_text', 0, { 0: formatForPassed });
      }

      if (dateDiff < 0) {
        // завершен раньше крайнего срока прохождения (Изучено в срок)
        if (endTime !== undefined && endTime * 1000 < this.passingDeadline * 1000) {
          return i18n.tc('common_passed_the_deadline_date_time_text', 0, { 0: formatForPassed });
        }

        // завершен позже крайнего срока прохождения (Не изучено в срок)
        if (endTime !== undefined && endTime * 1000 > this.passingDeadline * 1000) {
          return i18n.tc('common_not_passed_the_deadline_date_time_text', 0, {
            0: formatForPassed,
          });
        }
      }
    }

    return commonPassDefault;
  }

  /**
   * Вернет дату последней активности по программе обучения
   */
  getLastActivityDate(): Date | undefined {
    return this.statistic.lastActivity;
  }

  /**
   * Виджет для карточки материала
   */
  getWidget(fromResults: boolean = false): object {
    const parts: any[] = [];

    let maxPointsForProgram = this.maxPoints + this.maxProgramPoints;

    let pointsForProgram = this.statistic.userPoints + this.statistic.programPoints;

    if (fromResults) {
      maxPointsForProgram += this.maxBonusPoints;
      pointsForProgram += this.statistic.bonusPoints - this.statistic.penaltyPoints;
    }

    if (
      fromResults &&
      ((this.statistic.percent > 0 && this.statistic.percent < 100 && !this.statistic.isChecking) ||
        (this.statistic.isFailed && this.statistic.percent === 0))
    ) {
      parts.push(`${this.statistic.percent}&#8239;%`);
    }

    const isFewRating = this.feedbackCount < this.minNumbersOfFeedback;

    if (this.isRating && maxPointsForProgram > 0) {
      // если новый или назначен, пишем только сколько он может заработать
      if (this.statistic.isNew || this.statistic.isAssigned || this.statistic.isChecking) {
        parts.push([
          'learn_earn_d_points_text',
          maxPointsForProgram,
          { 0: maxPointsForProgram.toLocaleString() },
        ]);
      } else if (this.statistic.isInProgress) {
        // в процессе прохождения, выводим из скольки-то баллов
        parts.push([
          'learn_d_from_d_points_text',
          maxPointsForProgram,
          { 0: pointsForProgram.toLocaleString(), 1: maxPointsForProgram.toLocaleString() },
        ]);
      } else if (this.statistic.isPassed || this.statistic.isFailed) {
        // успешно пройден, если на максимум -- выводим без количества попыток только баллы,
        // если не на максимум -- если есть попытки, выводим сколько и сколько баллов из скольки
        if (pointsForProgram < maxPointsForProgram) {
          parts.push([
            'learn_d_from_d_points_text',
            maxPointsForProgram,
            { 0: pointsForProgram.toLocaleString(), 1: maxPointsForProgram.toLocaleString() },
          ]);
        } else {
          parts.push([
            'common_point_number_text',
            maxPointsForProgram,
            { 0: maxPointsForProgram.toLocaleString() },
          ]);
        }
      }
    }

    return createMaterialCardWidget({
      id: this.id,
      order: this.order,
      name: this.name,
      apiName: ApiName.LEARNING_PROGRAM,
      type: 'learning_programs',
      to: {
        name: CustomRoute.TO_LEARNING_PROGRAMS_MATERIAL,
        params: {
          id: this.id,
          slug: slugProvider(this.name),
          moduleId: this.moduleId,
          moduleSlug: slugProvider(this.moduleName),
        },
      },
      tagLabel: '',
      image: this.image,
      isRating: this.isRating,
      isRequired: this.isRequired,
      isInProgress: this.statistic.isInProgress,
      isPassed: this.statistic.isPassed,
      isChecking: this.statistic.isChecking,
      isChecked: this.statistic.isChecked,
      isFailed: this.statistic.isFailed,
      isAssigned: this.statistic.isAssigned,
      isNew: this.statistic.isNew,
      isFavorite: this.isFavorite,
      parts,
      userScore: this.statistic.userPoints,
      maxScore: this.maxPoints,
      maxBonusPoints: this.maxBonusPoints,
      maxProgramPoints: this.maxProgramPoints,
      bonusPoints: this.statistic.bonusPoints,
      maxPenaltyPoints: this.maxPenaltyPoints,
      penaltyPoints: this.statistic.penaltyPoints,
      userHasFeedback: this.statistic.userHasFeedback,
      isFeedbackShowed: this.isFeedbackAvailable,
      isFewRating,
      feedbackAverageMark: this.feedbackAverageMark,
      percent: this.statistic.percent,
      passingDeadline: this.passingDeadline,
      passingNoticeDeadline: this.passingNoticeDeadline,
      endTime: this.statistic.endTime,
    });
  }

  /**
   * Вернет TRUE если у программы есть материал
   * @param materialId
   */
  hasMaterial(materialId: number): boolean {
    const material = find(this.materials, o => o.id === materialId);

    return !!material;
  }

  /**
   * Обновит статистику у материала
   * @param statistic
   */
  updateMaterialStatistic(statistic: IStatistic): void {
    const material = find(this.materials, o => o.id === statistic.materialId);

    if (material) {
      material.setStatistic(statistic);
    }

    this.recalculate();
    this.getStatisticByMaterials();
  }

  /**
   * Вернет материал следующий после материала с materialId, если материал последний вернет undefined
   * @param materialId
   */
  getNextMaterialFor(materialId: number): any {
    let hasMaterial = false;

    let stop = false;

    let sectionMaterial: SectionMaterial | undefined;

    each(this.sections, section => {
      each(section.materials, sm => {
        if (!stop) {
          if (hasMaterial) {
            sectionMaterial = sm;
            stop = true;
          } else if (sm.materialId === materialId) {
            hasMaterial = true;
          }
        }
      });
    });

    if (!sectionMaterial) {
      return undefined;
    }

    const material = this.getMaterialById(sectionMaterial.materialId);

    if (!material) {
      return undefined;
    }

    const toggleWidget = material.getToggleWidget(sectionMaterial);

    if (!toggleWidget) {
      return undefined;
    }

    let externalLink = false;

    if (toggleWidget.type === 'link' && has(toggleWidget, 'url')) {
      externalLink = toggleWidget.url.search(/^http/) !== -1;
    }

    let target = '_self';

    if (toggleWidget.type === 'scorm' || toggleWidget.type === 'html' || externalLink) {
      target = '_blank';
    }

    const { buttonLabel } = toggleWidget;

    const { lokaliseButtonLabel } = toggleWidget;

    const params = {
      id: this.id,
      slug: slugProvider(this.name),
      materialId: toggleWidget.id,
      materialSlug: slugProvider(toggleWidget.name),
    };

    const isTrack = !!this.track?.entityId;

    if (isTrack) {
      // @ts-ignore
      params.trackId = this.track.entityId;
    }

    return {
      ...sectionMaterial,
      link: {
        name: isTrack ? toggleWidget.routerTrackTo : toggleWidget.routerTo,
        params,
      },
      target,
      buttonLabel,
      lokaliseButtonLabel,
    };
  }

  setCertificate(certificate: Certificate | undefined): void {
    this.statistic.certificate = certificate;
  }

  getCertificate(): Certificate | undefined {
    return this.statistic.certificate;
  }

  setFeedback(feedback: Feedback): void {
    this.feedback = feedback;
  }

  getFeedback(): Feedback {
    return this.feedback;
  }

  setStatistic(statistic: ProgramStatistic): void {
    this.statistic = statistic;
  }

  getStatistic(): ProgramStatistic {
    return this.statistic;
  }

  updateProgramAfterFeedback(newProgram: Program) {
    this.feedbackAverageMark = newProgram.feedbackAverageMark;
    this.feedbackCount = newProgram.feedbackCount;

    if (!this.statistic.userHasFeedback) {
      this.statistic.userHasFeedback = true;
    }
  }

  setFavorite(status: boolean) {
    this.isFavorite = status;
  }

  getTextTags() {
    return this.tags.map(o => o.name);
  }

  getDeadlineStates() {
    const { endTime, isPassed, isFailed } = this.getStatistic();

    const deadline = this.passingDeadline;

    const notice = this.passingNoticeDeadline;

    const dateDiff = deadline - getCurrentTimestamp();

    const hasntDeadline: boolean = !deadline;

    const hasNotice: boolean =
      !!deadline && !isPassed && !isFailed && dateDiff > 0 && dateDiff < notice;

    const hasDeadline: boolean = !!deadline && !isPassed && !isFailed && dateDiff > 0;

    const isPassedDeadline: boolean = !!deadline && (isPassed || isFailed) && endTime < deadline;
    const isExceededDeadline: boolean =
      !!deadline &&
      dateDiff < 0 &&
      ((endTime > deadline && (isPassed || isFailed)) || (!isPassed && !isFailed));

    return {
      hasntDeadline,
      hasNotice,
      isPassedDeadline,
      isExceededDeadline,
      hasDeadline,
    };
  }

  clearNewComments() {
    this.isCommented = false;
    this.newComments = 0;
  }

  setTrack(track: LearningItem | null) {
    this.track = track;
    this.customRoute = this.track
      ? CustomRoute.TO_LEARNING_TRACK_PROGRAMS
      : CustomRoute.TO_LEARNING_PROGRAMS_MATERIAL;
  }
}
