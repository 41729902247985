import ImageSource, { createImageMdSource } from '@/entities/common/sources/ImageSource';
import { Route } from 'vue-router';
import has from 'lodash/has';
import cloneDeep from 'lodash/cloneDeep';
import { i18n } from '@/lang';

export default class BaseExternalAuthType {
  image?: ImageSource;
  id: number;
  type: string;
  companyId: number;
  buttonLabel: string;
  isLoading: boolean = false;

  constructor(payload: any) {
    this.type = payload.type;

    this.companyId = parseInt(payload.company_id, 10);
    this.buttonLabel = payload.button_label;

    this.image = createImageMdSource(payload.image);
    this.id = parseInt(payload.params.id, 10);
  }

  getRedirectUrl(route: any) {
    localStorage.setItem('id_auth_type', this.id.toString());

    if (route.query && route.query.router_back) {
      localStorage.setItem('router_back', route.query.router_back);
    }

    return `${window.location.origin}/0/auth`;
  }

  getCheckData(route: Route) {
    const queryParameters = cloneDeep(route.query);

    if (has(queryParameters, 'SAMLResponse')) {
      queryParameters.SAMLResponse = queryParameters.SAMLResponse.toString().replace(/ /g, '+');
    }

    return {
      ...queryParameters,
      id: this.id,
      redirect_uri: `${window.location.origin}/0/auth`,
    };
  }

  getButtonText() {
    return this.buttonLabel === '' ? i18n.t('auth_oauth2_service_text') : this.buttonLabel;
  }
}
