import each from 'lodash/each';
import sortBy from 'lodash/sortBy';
import FeedbackItem from '@/entities/modules/learning-programs/feedback/FeedbackItem';
import FeedbackMeta from '@/entities/modules/learning-programs/feedback/FeedbackMeta';
import Pagination from '../../../common/Pagination';

export default class Feedback {
  feedbackList: FeedbackItem[];
  feedbackMeta: FeedbackMeta;

  constructor(payload: any) {
    this.feedbackList = sortBy(payload.elements, ['updatedAt']).reverse();
    this.feedbackMeta = payload.addedMeta;
  }
}

export function createFeedbackList(payload: any): [Pagination, FeedbackItem[], FeedbackMeta] {
  const pagination = new Pagination(payload.meta.pagination);

  const feedbackMeta = new FeedbackMeta(payload.feedback_meta);

  const feedback: FeedbackItem[] = [];

  each(payload.feedback_list, feedbackItem => {
    feedback.push(new FeedbackItem(feedbackItem));
  });

  return [pagination, feedback, feedbackMeta];
}
