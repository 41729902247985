/* eslint-disable */
enum NumberingType {
  NONE,
  HIERARCHICAL,
  SECTIONS,
  THROUGH,
}

export function getNumberingTypeByCode(code: string): NumberingType {
  switch (code) {
    case 'hierarchical':
      return NumberingType.HIERARCHICAL;
    case 'sections':
      return NumberingType.SECTIONS;
    case 'through':
      return NumberingType.THROUGH;
    default:
      return NumberingType.NONE;
  }
}

export default NumberingType;
