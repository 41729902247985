// eslint-disable-next-line max-classes-per-file
import each from 'lodash/each';

export class TestsPassingReport {
  constructor({ materials, percent }) {
    this.materials = materials;
    this.percent = percent;
  }
}

class Material {
  constructor({ id, name, percent, type }) {
    this.id = id;
    this.name = name;
    this.percent = percent;
    this.type = type;
    this.showTag = false;
  }
}

export function createTestsPassingReport(payload) {
  const materials = [];

  each(payload.materials, material => {
    materials.push(
      Object.freeze(
        new Material({
          id: material.id,
          name: material.name,
          percent: material.percent,
          type: material.type,
        }),
      ),
    );
  });

  return Object.freeze(
    new TestsPassingReport({
      materials,
      percent: payload.percent,
    }),
  );
}
