var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-notification-content"},[_c('transition-group',{staticClass:"app-notification",class:{
      'app-notification--center': _vm.isWhiteOverlay,
      'app-notification--bottom': _vm.isAuth,
    },attrs:{"name":_vm.notificationAnimate,"tag":"div"}},_vm._l((_vm.notifications),function(notification){return _c('AppNotification',{key:notification.id,attrs:{"notification":notification}})}),1),(_vm.notificationHasButton && !_vm.notificationHasNotOverlay)?_c('div',{staticClass:"app-notification-overlay",class:{
      'app-notification-overlay--dark': _vm.isWhiteOverlay,
      'app-notification-overlay--transparent': _vm.hasTransparentOverlay,
    }}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }