import Post from '@/entities/modules/messages/Post';
import { createImageFromStringWithSize } from '@/entities/common/sources/ImageSource';
import dateProvider from '@/providers/dateProvider';
import PostOwner from '@/entities/modules/messages/PostOwner';
import FileSource, { createFileSource } from '@/entities/common/sources/FileSource';
import Pagination from '@/entities/common/Pagination';
import PostSurvey from '@/entities/modules/messages/PostSurvey';

export default class FeedPost extends Post {
  file?: FileSource;
  survey?: PostSurvey;

  isSharedToUnderlyingManagers: boolean = false;
  commentsPagination?: Pagination;

  constructor(payload: any) {
    super(payload);

    this.owner = new PostOwner(payload.owner);

    if (payload.body.files && payload.body.files.length) {
      this.file = createFileSource(payload.body.files[0]);
    }

    if (payload.body.images && payload.body.images.length) {
      this.image = createImageFromStringWithSize(
        payload.body.images[0].url,
        payload.body.images[0].size,
      );
    }

    if (payload.body.survey) {
      this.survey = new PostSurvey(payload.body.survey);
    }

    this.text = payload.body.text;

    this.isNew = payload.is_new;
    this.createdAt = dateProvider(payload.created_at);

    this.isCommentingAllowed = payload.body.settings.is_comments_enabled;
    this.comments = parseInt(payload.comments, 10);
    this.newComments = parseInt(payload.new_comments, 10);

    this.likes = parseInt(payload.likes, 10);
    this.isLiked = payload.is_liked;

    this.hasViews = true;
    this.views = parseInt(payload.views, 10);

    this.isEditingAllowed = true;
    this.isSharedToUnderlyingManagers = payload.body.settings.is_shared_to_underlying_managers;
  }

  markCommentsAsRead() {
    this.newComments = 0;
  }

  setCommentsPagination(pagination: Pagination, currentPage: number) {
    this.commentsPagination = pagination;
    this.commentsPagination.setCurrentPage(currentPage);
  }

  isFirstPageComments(): boolean {
    return !!(
      this.commentsPagination && this.commentsList.length >= this.commentsPagination.totalCount
    );
  }

  // в ленте по умолчанию отдается последняя страница комментариев,
  // поэтому если есть комментарии значит это последняя страница загружена
  isLastPageComments(): boolean {
    return this.commentsList.length > 0;
  }
}
