/* eslint-disable operator-linebreak */
import axios from 'axios';
import { each, find, has } from 'lodash';
import slugProvider from '@/providers/slugProvider';
// @ts-ignore
// eslint-disable-next-line import/no-cycle
import ExtendedEmployee from '@/entities/modules/my-team/ExtendedEmployee';
import { createEmployeeFilter } from '@/entities/modules/my-team/EmployeeFormFilter';
// @ts-ignore
import { createMaterialCard } from '@/entities/modules/my-team/MaterialCard';
// @ts-ignore
import { createEmployeeStatistic } from '@/entities/modules/my-team/EmployeeStatistic';
// @ts-ignore
import { createEmployeeResult } from '@/entities/modules/my-team/EmployeeResult';
// @ts-ignore
import { createEmployeeAwardCategory } from '@/entities/modules/my-team/EmployeeAwardCategory';
// @ts-ignore
import { createReport } from '@/entities/modules/my-team/reports/Report';
// @ts-ignore
import { createUsersActivityReport } from '@/entities/modules/my-team/reports/UsersActivityReport';
// @ts-ignore
import { createMaterialsPassingReport } from '@/entities/modules/my-team/reports/MaterialsPassingReport';
// @ts-ignore
import { createTestsPassingReport } from '@/entities/modules/my-team/reports/TestsPassingReport';
// @ts-ignore
import { createTestsScoresReport } from '@/entities/modules/my-team/reports/TestsScoresReport';
// @ts-ignore
import { createInterviewPassingReport } from '@/entities/modules/my-team/reports/InterviewPassingReport';
// @ts-ignore
import { createMaterialCardWidget } from '@/entities/common/MaterialCardWidget';
// @ts-ignore
import { createLearningProgramMaterial } from '@/entities/modules/my-team/LearningProgramMaterial';
// @ts-ignore
import { createLearningProgramMaterialStatistic } from '@/entities/modules/my-team/LearningProgramMaterialStatistic';
import { createDetailsForLearningProgram } from '@/entities/modules/my-team/DetailsForLearningProgram';
import { i18n } from '@/lang';
// eslint-disable-next-line import/no-cycle
import Badge from '@/entities/common/Badge';
import Employee from '@/entities/modules/my-team/Employee';
import EmployeeMeta from '@/entities/modules/my-team/EmployeeMeta';
import CustomRoute from '@/entities/common/CustomRoute';
import FilterGroup, { createFilterGroupsList } from '@/entities/common/groups/FilterGroup';
import { paginationResolverWithAddedMeta } from '@/providers/paginationProvider';
import MaterialCardMeta from '@/entities/modules/my-team/MaterialCardMeta';
import IFilter from '@/entities/modules/my-team/filters/IFilter';
import UserFilter from '@/entities/modules/my-team/filters/UserFilter';
import Pagination from '@/entities/common/Pagination';
import Module from '@/entities/modules/my-team/Module';
import MaterialStatus from '@/entities/modules/my-team/MaterialStatus';
import Answer from '@/entities/modules/tasks/Answer';
import MaterialAttempt from '@/entities/modules/my-team/MaterialAttempt';
import Category from '@/entities/common/Category';
import UserFilterType from '@/entities/modules/my-team/filters/UserFilterType';
import UserTaskFilter from '@/entities/modules/my-team/filters/UserTaskFilter';
import { userLocale } from '@/locale';

import type {
  SavedUserBaseFilter,
  SavedUserTaskFilter,
  UserFiltersResponse,
} from '@/types/my-team/filters';
import ApiName from '@/entities/common/ApiName';
import { createDetailsForLearningTrack } from '@/entities/modules/my-team/DetailsForLearningTrack';

async function paginationGetGroups(page: number, managerId: number, rootId: number) {
  const response = await axios.post(`/myteam/groups?manager_id=${managerId}&page=${page}`, {
    root_id: rootId,
  });

  return createFilterGroupsList(response.data.success);
}

/* async function paginationGetAnswers(managerId: number, filterId: number, searchValue: string, page: number) {
  return axios.post(`/myteam/employees/form-answers?manager_id=${managerId}&page=${page}`, {
    question_ids: [filterId],
    search: searchValue,
  });
} */

type GetMaterialsParams = {
  page: number;
  id: number;
  moduleId: number;
  search?: string;
  type?: 'interviews' | 'tests' | 'learning_materials';
  managerId?: number;
};

export default {
  async getMyTeam(page: number, perPage: number, id: number) {
    const response = await axios.get(
      `/myteam/employees?page=${page}&per_page=${perPage}&manager_id=${id}`,
    );

    const items: Employee[] = [];

    each(response.data.success, employee => {
      items.push(new Employee(employee));
    });

    return items;
  },

  async getEmployeeMeta(id: number, managerId: number) {
    const response = await axios.get(`/myteam/employees?manager_id=${managerId}`, {
      headers: {
        globalErrorHandler: false,
      },
    });

    const meta = find(response.data.success, item => item.id === id);

    if (!meta) {
      return null;
    }

    return new EmployeeMeta(meta);
  },

  async getEmployee(id: number, managerId: number) {
    const response = await axios.get(
      `/myteam/employees/${id}/info?manager_id=${managerId}&withGroups=1`,
    );

    return new ExtendedEmployee(response.data.success);
  },

  async getEmployeeAwards(id: number) {
    const response = await axios.get(`/achievements/badges/user/${id}/available`);

    const categories = createEmployeeAwardCategory(response.data.success.categories);

    const badges: Badge[] = [];

    each(response.data.success.badges, badge => {
      badges.push(new Badge(badge));
    });

    return {
      categories,
      badges,
    };
  },

  async assignEmployeeAward(badgeId: number, userId: number, comment: string) {
    return axios.post('/achievements/badges/users/assign', {
      badge_id: badgeId,
      user_id: userId,
      comment,
    });
  },

  async cancelEmployeeAward(id: number) {
    return axios.post('/achievements/badges/users/remove', {
      badge_assignment_id: id,
    });
  },

  async getMaterials(params: GetMaterialsParams) {
    const response = await axios.get('/myteam/materials', {
      params: {
        page: params.page,
        module_id: params.moduleId,
        manager_id: params.id,
        type: params.type,
        search: params.search,
      },
    });

    // переделать на объект
    const materials: Object[] = [];

    let tagLabel: string = '';

    each(response.data.success, material => {
      let image = null;

      switch (material.type) {
        case ApiName.INTERVIEW_MODULE:
          tagLabel = i18n.t('common_survey_lowcase_text').toString();
          break;

        case ApiName.TEST_MODULE:
          tagLabel = i18n.t('common_test_lowcase_text').toString();
          break;

        case ApiName.EVENT_MODULE:
          tagLabel = i18n.t('common_event_lowcase_text').toString();
          break;

        case ApiName.LEARNING_PROGRAM:
          tagLabel = i18n.t('myteam_learning_journey_title_text').toString();
          break;

        case ApiName.LEARNING_TRACK:
          tagLabel = i18n.t('common_trajectories_default_text').toString();
          break;
        default:
          break;
      }

      if (has(material, 'image_wide')) {
        image = material.image_wide.xlg.url;
      } else if (has(material, 'image')) {
        image = material.image.xlg.url;
      }

      // графики которые выводим на карточках материалов в моей команде
      const chart = [];

      let diagram = null;

      let amountOfMaterialsForChart = 0;

      if (material.type !== 'tasks') {
        chart.push({
          count: parseInt(material.progress.success, 10),
          status: 'success',
        });
      }

      amountOfMaterialsForChart = material.progress.success + material.progress.not_started;

      if (material.type === 'tasks') {
        chart.push({
          count: parseInt(material.progress.accepted, 10),
          status: 'success',
        });
        amountOfMaterialsForChart = material.progress.assigned;
      }

      if (material.type !== 'interviews' && material.type !== 'tasks') {
        chart.push({
          count: parseInt(material.progress.fail, 10),
          status: 'failed',
        });
        amountOfMaterialsForChart =
          material.progress.success + material.progress.fail + material.progress.not_started;
      }

      diagram = {
        entities: chart,
        amountOfMaterialsForChart,
        numberOfSuccessful: material.progress.success ?? material.progress.accepted,
      };

      const parts = [];

      parts.push([
        'common_d_from_d_text',
        amountOfMaterialsForChart,
        {
          0: material.progress.success ?? material.progress.accepted,
          1: amountOfMaterialsForChart,
        },
      ]);
      parts.push(['myteam_passed_plural_text', amountOfMaterialsForChart]);

      let passingDeadline = 0;

      let passingNoticeDeadline = 0;

      let hasNotPassedUsers = 0;

      let deadlineType = 'absolute';

      let deadlineNotificationTime = 0;

      let deadlineTime = 0;

      let deadlineAccomplishedUsers = 0;

      let deadlineNotificationApproachingUsers = 0;

      let deadlineApproachingUsers = 0;

      let deadlineOverdueUsers = 0;

      if ([ApiName.LEARNING_PROGRAM, ApiName.LEARNING_TRACK].includes(material.type)) {
        passingDeadline = material[material.type].deadline_for_passing_at;
        passingNoticeDeadline = material[material.type].deadline_for_passing_notice_for_time;
        hasNotPassedUsers = material[material.type].has_not_passed_users;
        deadlineType = material[material.type].deadline.type;
        deadlineNotificationTime = material[material.type].deadline.notification_time;
        deadlineTime = material[material.type].deadline.time;
        deadlineAccomplishedUsers = material[material.type].deadline_completion.accomplished;
        deadlineNotificationApproachingUsers =
          material[material.type].deadline_completion.notification_approaching;
        deadlineApproachingUsers = material[material.type].deadline_completion.approaching;
        deadlineOverdueUsers = material[material.type].deadline_completion.overdue;
      }

      let route = null;

      if (material.type !== 'tasks') {
        if (params.managerId) {
          route = {
            name: CustomRoute.TO_MY_TEAM_MATERIALS_MATERIAL_CARD,
            params: {
              moduleId: material.module_id,
              moduleType: material.type,
              managerId: params.id,
              id: params.managerId,
              materialId: material.id,
              slug: slugProvider(material.name),
            },
          };
        } else {
          route = {
            name: CustomRoute.TO_MY_TEAM_MATERIALS_MATERIAL_CARD,
            params: {
              moduleId: material.module_id,
              moduleType: material.type,
              managerId: params.id,
              materialId: material.id,
              slug: slugProvider(material.name),
            },
          };
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (params.managerId) {
          route = {
            name: CustomRoute.TO_MY_TEAM_LEARNING_MATERIAL_STATUSES,
            params: {
              id: params.id,
              managerId: params.managerId,
              moduleId: material.module_id,
              moduleType: material.type,
              materialId: material.id,
            },
          };
        } else {
          route = {
            name: CustomRoute.TO_MY_TEAM_LEARNING_MATERIAL_STATUSES,
            params: {
              id: params.id,
              moduleId: material.module_id,
              moduleType: material.type,
              materialId: material.id,
            },
          };
        }
      }

      materials.push(
        Object.freeze(
          createMaterialCardWidget({
            name: material.name,
            id: material.id,
            moduleName: material.module_name,
            moduleId: material.module_id,
            image,
            tagLabel,
            diagram,
            type: material.type,
            parts,
            to: route,
            passingDeadline,
            passingNoticeDeadline,
            hasNotPassedUsers,
            deadlineType,
            deadlineNotificationTime,
            deadlineTime,
            deadlineAccomplishedUsers,
            deadlineNotificationApproachingUsers,
            deadlineApproachingUsers,
            deadlineOverdueUsers,
          }),
        ),
      );
    });

    const pagination = new Pagination(response.data.meta.pagination);

    return {
      materials,
      pagination,
    };
  },

  async getMaterial(params: any) {
    const response = await axios.get(
      `/myteam/${params.category}/${params.id}/employees?manager_id=${params.managerId}${
        params.status ? `&status=${params.status}` : ''
      }`,
    );

    return {
      employees: createMaterialCard(response.data.success),
      material: new MaterialCardMeta(response.data.success.material),
    };
  },

  async getLearningProgramsDetails(userId: number, programId: number, managerId: number) {
    const response = await axios.get(
      `/myteam/employee/${userId}/progress/learning_program/${programId}?manager_id=${managerId}`,
    );

    return createDetailsForLearningProgram(response);
  },

  async getLearningProgramMaterialDetails(
    userId: number,
    programId: number,
    materialId: number,
    managerId: number,
  ) {
    const response = await axios.get(
      `/myteam/employee/${userId}/progress/learning_program/${programId}/material/${materialId}?manager_id=${managerId}`,
    );

    const questions = [];

    for (let i = 0; i < response.data.success.questions.length; i += 1) {
      questions.push(response.data.success.questions[i]);
    }

    const material = createLearningProgramMaterial(response.data.success.material);

    const statistic = createLearningProgramMaterialStatistic(
      response.data.success.statistic,
      questions,
    );

    return {
      statistic,
      material,
    };
  },

  async getLearningTrackDetails(userId: number, trackId: string, managerId: number) {
    const response = await axios.get(
      `/myteam/employee/${userId}/progress/learning_track/${trackId}`,
      {
        params: {
          manager_id: managerId,
        },
      },
    );

    return createDetailsForLearningTrack(response.data.success);
  },

  async getEmployeeResult(id: number, managerId: number) {
    const response = await axios.get(`/myteam/employees/${id}/materials?manager_id=${managerId}`);

    return createEmployeeResult(response.data.success);
  },

  async getFormFilters(managerId: number): Promise<IFilter[][]> {
    const response = await axios.get(`/myteam/filters?manager_id=${managerId}`);

    return createEmployeeFilter(response.data.success);
  },

  async getAnswerForFilter(managerId: number, filterId: number, searchValue: string, page: number) {
    return axios.post(`/myteam/employees/form-answers?manager_id=${managerId}&page=${page}`, {
      question_ids: [filterId],
      search: searchValue,
    });
  },

  async getUserCountForFilter(managerId: number, convertObj: Object) {
    const response = await axios.post(
      `/myteam/employees/filter/count?manager_id=${managerId}`,
      convertObj,
    );

    return response.data.success.users_count;
  },

  async showUsers(managerId: number, convertObj: Object, page: number = 1) {
    const response = await axios.post(
      `/myteam/employees/filter?manager_id=${managerId}&page=${page}`,
      convertObj,
    );

    const users: Employee[] = [];
    each(response.data.success.users, user => {
      users.push(new Employee(user));
    });

    return {
      users,
      pagination: new Pagination(response.data.success.meta.pagination),
    };
  },

  async getEmployeeStatistic(id: number, type: string, typeId: number, managerId: number) {
    const response = await axios.get(
      `/myteam/${type}/${typeId}/employees/${id}/answers?manager_id=${managerId}`,
    );

    return createEmployeeStatistic(response.data.success);
  },

  async getEmployeeStatisticMeta(id: number, type: string, typeId: number, managerId: number) {
    const response = await axios.get(`/myteam/employees/${id}/materials?manager_id=${managerId}`);

    const meta = find(
      response.data.success.materials,
      item => item.id === typeId && item.type === type,
    );

    if (!meta) {
      return null;
    }

    return meta;
  },

  async getReport(managerId: number) {
    const response = await axios.get(`/myteam/reports?manager_id=${managerId}`);

    const reports: Object[] = [];

    each(response.data.success.reports, report => {
      reports.push(Object.freeze(createReport(report)));
    });

    return reports;
  },

  async getUsersActivityReport(managerId: number) {
    const response = await axios.get(`/myteam/reports/users_activity?manager_id=${managerId}`);

    return createUsersActivityReport(response.data.success);
  },

  async getMaterialsPassingReport(managerId: number, type: string, moduleId: number) {
    let material = '';

    if (type === 'learning_programs') {
      material = `&material_type=learning_programs&module_id=${moduleId}`;
    }

    if (type !== 'all' && type !== 'learning_programs') {
      material = `&material_type=${type}`;
    }

    const response = await axios.get(
      `/myteam/reports/materials_passing?manager_id=${managerId}${material}`,
    );

    return createMaterialsPassingReport(response.data.success.materials);
  },

  async getTestsPassingReport(managerId: number) {
    const response = await axios.get(
      `myteam/reports/tests_average_passing?manager_id=${managerId}`,
    );

    return createTestsPassingReport(response.data.success);
  },

  async getTestsScoresReport(managerId: number) {
    const response = await axios.get(`myteam/reports/tests_scores?manager_id=${managerId}`);

    return createTestsScoresReport(response.data.success);
  },

  async getInterviewReport(interviewId: number, userId: number, managerId: number) {
    const response = await axios.get(
      `/myteam/interviews/${interviewId}/employees/${userId}/answers?manager_id=${managerId}`,
    );

    return createInterviewPassingReport(response.data.success);
  },

  async getGroups(rootId: number, managerId: number) {
    return paginationResolverWithAddedMeta<FilterGroup>(paginationGetGroups, [managerId, rootId]);
  },

  async getUserFilters() {
    const response = await axios.get('/myteam/user-filters');

    const userFilters: any = [];

    each(response.data.success.items, userFilter => {
      if (userFilter.name === UserFilterType.TEAM_USER_TASKS) {
        userFilters.push(new UserTaskFilter(userFilter));
      } else {
        userFilters.push(new UserFilter(userFilter));
      }
    });

    return userFilters;
  },

  async getMyTeamMaterialsCategories(managerId: number, moduleId: number) {
    const response = await axios.request({
      url: '/myteam/materials/categories',
      method: 'get',
      params: {
        manager_id: managerId,
        module_id: moduleId,
      },
    });

    const categories: Category[] = [];
    response.data.success.categories.forEach((category: any) =>
      categories.push(new Category(category)),
    );

    return categories;
  },

  async getMyTeamAssignedEmployees(managerId: number, moduleId: number) {
    const response = await axios.get(
      `/myteam/employees/assigned?manager_id=${managerId}&module_id=${moduleId}`,
    );

    const employees: Employee[] = [];

    response.data.success.users.forEach((employee: any) => employees.push(new Employee(employee)));

    return employees;
  },

  async saveUserFilter(
    userFilter: SavedUserBaseFilter | SavedUserTaskFilter,
  ): Promise<UserFiltersResponse> {
    const response: UserFiltersResponse = await axios.post('/myteam/user-filters/save', userFilter);

    return response;
  },

  async getModules(managerId: number) {
    const response = await axios.get(`/myteam/modules?manager_id=${managerId}`, {
      headers: {
        'Accept-Language': userLocale,
      },
    });

    if (!response.data.success) {
      return [];
    }

    return response.data.success.map((module: any) => new Module(module));
  },

  /**
   * @param {Object} payload
   * @param {string} payload.materialType
   * @param {number} payload.materialId
   * @param {number} payload.managerId
   */
  async getMaterialStatuses(payload: any) {
    const response = await axios.get(
      `/myteam/${payload.materialType}/${payload.materialId}/statuses?manager_id=${payload.managerId}`,
    );

    return Object.values(response.data.success.statuses).map(
      (module: any) => new MaterialStatus(module),
    );
  },

  async getTaskUserResult(params: any) {
    const response = await axios.get(
      `/myteam/${params.materialType}/${params.materialId}/employees/${params.userId}/answers?&answer_id=${params.attemptId}`,
    );

    return new Answer(response.data.success[0]);
  },

  /**
   * @param {Object} payload
   * @param {string} payload.moduleType
   * @param {number} payload.materialId
   * @param {number} payload.managerId
   * @param {number} payload.page
   * @param {string} payload.status
   */
  async getMaterialAttempts(payload: any) {
    const params = {
      manager_id: payload.managerId,
      page: payload.page,
      check_required_by_manager: payload.checkRequiredByManager ? 1 : 0,
    };

    if (payload.materialId) {
      // @ts-ignore
      params.material_id = payload.materialId;
    }

    if (payload.status) {
      // @ts-ignore
      params.status = payload.status;
    }

    if (payload.search) {
      // @ts-ignore
      params.search = payload.search;
    }

    const response = await axios.get(`/myteam/${payload.moduleType}/attempts`, { params });

    const pagination = new Pagination(response.data.success.meta.pagination);

    if (!response.data?.success?.attempts?.length) return { pagination, attempts: [] };

    const attempts = response.data.success.attempts.map(
      (attempt: any) => new MaterialAttempt(attempt),
    );

    return { pagination, attempts };
  },

  async saveCheckingTask(payload: any, materialType: string, answerId: number) {
    try {
      const response = await axios.post(
        `/myteam/${materialType}/answer/${answerId}/update`,
        payload,
      );

      if (!response.data) {
        return null;
      }

      return response.data.success;
    } catch (e) {
      throw e;
    }
  },

  getCheckingTasks() {
    return axios.get('/myteam/widgets/tasks/review-required-widget');
  },

  clearIsNew(attemptsIds: Array<number>) {
    return axios.request({
      url: '/myteam/clear-is-new',
      method: 'post',
      data: {
        task_answer: attemptsIds,
      },
    });
  },

  deleteComment(commentId: number, answerId: number, materialType: string) {
    return axios.delete(`/myteam/${materialType}/answer/${answerId}/delete-comment/${commentId}`);
  },
};
