import { MutationTree } from 'vuex';
import { MyResultsState } from '@/store/modules/my-results/types';

export const mutations: MutationTree<MyResultsState> = {
  changeRequiredFilter(state, payload) {
    state.selectedRequiredFilter = payload;
  },

  changeModuleFilter(state, payload) {
    state.selectedModuleFilter = payload;
  },

  changeSortFilter(state, payload) {
    state.selectedSortFilter = payload;
  },

  changeDeadlineFilter(state, payload) {
    state.selectedDeadlineFilter = payload;
  },

  addMaterials(state, materials) {
    state.materials = materials;
  },
};
