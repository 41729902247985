import { each, has } from 'lodash';
import { prepareHtml } from '@/providers/wysiwygRedactorProvider';
import ApiName from '@/entities/common/ApiName';
import ImageSource, { createOnlyImageSiteSource } from '@/entities/common/sources/ImageSource';
// @ts-ignore
import createDate from '@/providers/js/dateProvider';
// @ts-ignore
import { createMaterialCardWidget } from '@/entities/common/MaterialCardWidget';

export default class Gift {
  id: number;
  image: ImageSource | undefined;
  images: string[] = [];
  name: string;
  sku: string;
  description: string | null;
  price: number;
  isHit: boolean;
  isAvailable: boolean;
  availableTo: string;
  availableCount: number;
  isUnlimited: boolean;
  amount: number;
  isFavorite: boolean;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.image = createOnlyImageSiteSource(payload.image);

    if (has(payload, 'images')) {
      each(payload.images, o => {
        this.images.push(o.site.url);
      });
    }

    this.name = payload.name;
    this.sku = payload.sku;
    this.description = has(payload, 'description') ? prepareHtml(payload.description) : null;
    this.price = parseInt(payload.price, 10);
    this.isHit = parseInt(payload.is_hit, 10) === 1;
    this.isFavorite = parseInt(payload.is_favorite, 10) === 1;
    this.isAvailable = parseInt(payload.is_available, 10) === 1;
    this.availableTo = createDate(payload.available_till);
    this.availableCount = parseInt(payload.available_count, 10);
    this.isUnlimited = parseInt(payload.is_unlimited, 10) === 1;
    this.amount = has(payload, 'amount') ? payload.amount : null;
  }

  getWidget() {
    return createMaterialCardWidget({
      id: this.id,
      name: this.name,
      apiName: ApiName.GIFT_STORE,
      image: this.image,
      to: {},
      type: 'gifts',
      price: this.price,
      isFavorite: this.isFavorite,
    });
  }

  setFavorite(status: boolean): void {
    this.isFavorite = status;
  }
}
