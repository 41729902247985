import UserValueFilter from '@/entities/modules/my-team/filters/UserValueFilter';
import UserFilterType from '@/entities/modules/my-team/filters/UserFilterType';
import type {
  IUserFilter,
  UserFilterResponseItem,
  IUserDefaultValueFilter,
  SavedUserBaseFilter,
} from '@/types/my-team/filters';
import CorrectName from '@/entities/common/CorrectName';
import { each, isEqual, sortBy } from 'lodash';

const exceptionModulesName = [
  UserFilterType.TEAM_MATERIALS_TESTS,
  UserFilterType.TEAM_MATERIALS_INTERVIEWS,
  UserFilterType.TEAM_MATERIALS_EVENTS,
];

export default class UserFilter implements IUserFilter<UserValueFilter, SavedUserBaseFilter> {
  name: string;
  value: UserValueFilter;
  // isDefault: boolean;

  constructor(payload: UserFilterResponseItem<IUserDefaultValueFilter>) {
    this.name = payload.name;
    this.value = new UserValueFilter(payload.value, payload.name);
    // this.isDefault = parseInt(payload.is_default, 10) === 1;
  }

  isDefault(): boolean {
    switch (this.name) {
      case UserFilterType.TEAM_REPORT_USER_ACTIVITY:
        return this.value.countDays === 30;

      case UserFilterType.TEAM_REPORT_MATERIALS_PASSING:
      case UserFilterType.TEAM_REPORT_TESTS_AVERAGE_PASSING:
        return (
          !this.value.isRequired &&
          this.value.modules.length === 0 &&
          this.value.deadline.length === 0
        );

      case UserFilterType.TEAM_MATERIALS_EVENTS:
        return !this.value.isRequired && this.value.sort === 'by_new';

      case UserFilterType.TEAM_MATERIALS_LEARNING_MATERIALS:
        return (
          !this.value.isRequired &&
          this.value.types.length === 0 &&
          this.value.deadline.length === 0 &&
          this.value.sort === 'by_deadline'
        );

      case UserFilterType.TEAM_MATERIALS_INTERVIEWS:
      case UserFilterType.TEAM_MATERIALS_TESTS:
        return this.value.sort === 'by_new';

      default:
        return (
          !this.value.isRequired &&
          this.value.modules.length === 0 &&
          this.value.deadline.length === 0 &&
          this.value.sort === 'by_deadline'
        );
    }
  }

  // @ts-ignore
  isEqual(filter: IUserFilter<IUserDefaultValueFilter, SavedUserBaseFilter>): boolean {
    switch (this.name) {
      case UserFilterType.TEAM_REPORT_USER_ACTIVITY:
        return this.value.countDays === filter.value.countDays;

      case UserFilterType.TEAM_REPORT_MATERIALS_PASSING:
      case UserFilterType.TEAM_REPORT_TESTS_AVERAGE_PASSING:
        return (
          this.value.isRequired === filter.value.isRequired &&
          isEqual(sortBy(this.value.modules), sortBy(filter.value.modules)) &&
          isEqual(sortBy(this.value.deadline), sortBy(filter.value.deadline))
        );

      case UserFilterType.TEAM_MATERIALS_LEARNING_MATERIALS:
        return (
          this.value.isRequired === filter.value.isRequired &&
          isEqual(sortBy(this.value.deadline), sortBy(filter.value.deadline)) &&
          isEqual(sortBy(this.value.types), sortBy(filter.value.types)) &&
          this.value.sort === filter.value.sort
        );

      case UserFilterType.TEAM_MATERIALS_EVENTS:
        return (
          this.value.isRequired === filter.value.isRequired && this.value.sort === filter.value.sort
        );

      case UserFilterType.TEAM_MATERIALS_TESTS:
      case UserFilterType.TEAM_MATERIALS_INTERVIEWS:
        return this.value.sort === filter.value.sort;

      default:
        return (
          this.value.isRequired === filter.value.isRequired &&
          isEqual(sortBy(this.value.modules), sortBy(filter.value.modules)) &&
          isEqual(sortBy(this.value.deadline), sortBy(filter.value.deadline)) &&
          this.value.sort === filter.value.sort
        );
    }
  }

  transformStateForSave(): SavedUserBaseFilter {
    const value: any = {};

    if (this.name === UserFilterType.TEAM_REPORT_USER_ACTIVITY) {
      value.count_days = this.value.countDays;
    } else {
      value.is_required = this.value.isRequired ? 1 : 0;
      value.deadline = this.value.deadline;
      value.order = this.value.sort;

      if (this.name !== UserFilterType.TEAM_USER_MATERIALS) {
        value.module_ids = [];
        value.types = [];
        each(this.value.modules, (module: string | number) => {
          if (
            module === CorrectName.TESTS ||
            module === CorrectName.INTERVIEW ||
            module === CorrectName.EVENT
          ) {
            value.types.push(module);
          } else {
            value.module_ids.push(module);

            if (
              this.name === UserFilterType.TEAM_MATERIALS_LEARNING_MATERIALS &&
              this.value.types.length
            ) {
              value.types = this.value.types;
            }
          }
        });
      }
    }

    return {
      name: this.name,
      value,
    };
  }

  clear() {
    if (this.name === UserFilterType.TEAM_REPORT_USER_ACTIVITY) {
      this.value.countDays = 30;
    } else {
      this.value.isRequired = false;
      this.value.modules = [];
      this.value.deadline = [];
      this.value.types = [];
      this.value.sort = exceptionModulesName.some(filter => filter === this.name)
        ? 'by_new'
        : 'by_deadline';
    }
  }
}
