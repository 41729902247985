export class Report {
  constructor({ type, percent, lokaliseTitle, lokaliseSubtitle }) {
    this.type = type;
    this.percent = percent;
    this.lokaliseTitle = lokaliseTitle;
    this.lokaliseSubtitle = lokaliseSubtitle;
  }
}

export function createReport(report) {
  switch (report.type) {
    // Активность пользователей
    case 'users_activity':
      return Object.freeze(
        new Report({
          type: report.type,
          percent: report.percent,
          lokaliseTitle: 'myteam_reports_user_activity_title_text',
          lokaliseSubtitle: 'myteam_reports_activity_active_section_text',
        }),
      );

    // Все материалы
    case 'materials_passing':
      return Object.freeze(
        new Report({
          type: report.type,
          percent: report.percent,
          lokaliseTitle: 'myteam_reports_progress_all_materials_text',
          lokaliseSubtitle: 'common_passed_text',
        }),
      );

    // Результаты тестов процент прохождения
    case 'tests_average_passing':
      return Object.freeze(
        new Report({
          type: report.type,
          percent: report.percent,
          lokaliseTitle: 'myteam_reports_test_results_title_text',
          lokaliseSubtitle: 'myteam_reports_average_score_text',
        }),
      );

    // Результаты тестов результат команды
    case 'tests_scores':
      return Object.freeze(
        new Report({
          type: report.type,
          percent: report.percent,
          lokaliseTitle: 'myteam_reports_test_results_title_text',
          lokaliseSubtitle: 'myteam_reports_team_result_text',
        }),
      );

    default:
      return null;
  }
}
