<template>
  <div
    v-if="badge"
    class="home-awards"
  >
    <div
      class="home-awards__content"
      @click.stop="onBadgeClick"
    >
      <div class="home-awards__head">
        <p class="home-awards__title">{{ badge.name }}</p>
        <span
          v-if="badge.isNew && isNewBadge"
          class="app-tag app-tag--orange"
          style="margin: 9px 10px 0 0"
        >
          {{ $t('common_new_status_text') }}
        </span>
        <span
          v-if="badge.comment"
          class="app-tag app-tag--gray"
        >
          {{ $t('common_comment_text') }}
        </span>
      </div>
      <div class="home-awards__footer">
        <div>
          <span
            :class="{ 'home-awards-footer__scores--low': badge.scores < 0 }"
            class="home-awards-footer__scores"
          >
            {{ badge.getAwardsScores() }}
          </span>
        </div>
        <div
          v-if="badge.image"
          :class="{ 'home-awards-footer__logo--locked': !isManager && !badge.assignmentId }"
          class="home-awards-footer__logo"
        >
          <div
            v-if="!badge.assignmentId && !isManager"
            class="home-awards-modal__locked-icon"
          >
            <AppLockedIcon />
          </div>

          <div
            :class="{ 'home-awards-footer__logo--lockedBlock': !isManager && !badge.assignmentId }"
            :style="{ 'background-image': 'url(' + badge.image + ')' }"
            class="home-awards-footer__logo--image"
          ></div>
        </div>
      </div>
    </div>

    <AppModal
      v-if="modalStatus"
      :max-width="632"
      @close="closeModal"
    >
      <MyAwardsInfo
        :badge="badge"
        :category-name="categoryName"
        :is-manager="isManager"
        :awards-type="awardsType"
        :is-it-deep-manager="isItDeepManager"
        :can-reject="badge.canReject && !fromRating"
        @closeModal="closeModal"
        @updateAwards="updateAwards"
        @cancelBadge="cancelBadge"
      />
    </AppModal>
  </div>
</template>

<script>
import { sendEvent } from '@/providers/analyticsProvider';
import AppModal from '@/components/helpers/modals/modal.vue';
import MyAwardsInfo from '@/components/app/home/my-awards-modal.vue';
import AppLockedIcon from '@/components/svg/icons/lock.vue';

export default {
  name: 'my-awards-badge',

  components: {
    MyAwardsInfo,
    AppModal,
    AppLockedIcon,
  },

  props: {
    fromRating: {
      type: Boolean,
      default: false,
    },

    badge: {
      type: Object,
      default: null,
    },

    isManager: {
      type: Boolean,
      default: false,
    },

    awardsType: {
      type: String,
      default: null,
    },

    categoryName: {
      type: String,
      default: null,
    },

    isItDeepManager: {
      type: Boolean,
      default: false,
    },

    eventLabel: {
      type: String,
      default: '',
    },

    awardIdModal: {
      type: Number,
      default: 0,
    },
  },

  data: () => ({
    modalStatus: false,
    isNewBadge: false,
  }),

  created() {
    this.isNewBadge = this.badge.isNew;

    if (this.awardIdModal && this.awardIdModal === this.badge.badgeId) {
      this.onBadgeClick();
    }
  },

  methods: {
    onBadgeClick() {
      if (this.badge.isAvailable) {
        this.modalStatus = true;

        if (this.badge.isNew) {
          this.isNewBadge = false;
          this.$store.dispatch('rating/clearIsNew', this.badge.assignmentId);
          this.$emit('clearIsNew', this.badge.assignmentId);
        }

        if (this.eventLabel !== '') {
          sendEvent(this.$analytics, this.eventLabel);
        }
      } else {
        this.showNotification();
      }
    },

    showNotification() {
      this.$store.dispatch('helpers/showNotification', {
        message: this.$t('common_badge_unavailable_error'),
      });
    },

    closeModal() {
      this.modalStatus = false;
    },

    cancelBadge(id) {
      this.modalStatus = false;
      this.$emit('cancelBadge', id);
    },

    updateAwards() {
      this.$emit('updateAwards');
    },
  },
};
</script>
