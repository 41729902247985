import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { each } from 'lodash';
import { userLocale } from '@/locale';
import { IntercomState } from './types';

export const actions: ActionTree<IntercomState, RootState> = {
  setIntercomParameters({ rootState, dispatch }) {
    // @ts-ignore
    const createdAt = Math.round(new Date() / 1000);
    const company: any = {
      company_id: rootState.auth.user.companyId,
      name: rootState.settings.companyName,
    };
    const settings: any = {
      app_id: rootState.settings.support.intercomAppId,
      name: rootState.auth.user.firstName,
      user_id: rootState.auth.user.id,
      platform: 'web',
      app_version: process.env.VUE_APP_VERSION,
      created_at: createdAt, // Signup date as a Unix timestamp
      company,
      login: rootState.auth.user.login,
      language_override: userLocale,
      role: rootState.auth.user.isManager ? 'manager' : 'employee',
    };

    // добавляем группы
    each(rootState.auth.user.groups, rootGroup => {
      const group = rootGroup.getLastChild();
      settings[rootGroup.name] = `[${group.id}] ${group.name}`;
    });

    // добавляем поля анкеты
    each(rootState.auth.user.formAnswers, answer => {
      settings[answer.name] = answer.answer;
    });

    // @ts-ignore
    window.intercomSettings = settings;

    dispatch('setIntercomVisibility');
  },

  setIntercomDefaultParameters({ rootState, dispatch }) {
    if (
      rootState.settings &&
      rootState.settings.support &&
      rootState.settings.support.intercomAppId !== ''
    ) {
      // @ts-ignore
      const createdAt = Math.round(new Date() / 1000);
      // @ts-ignore
      window.intercomSettings = {
        app_id: rootState.settings.support.intercomAppId,
        platform: 'web',
        app_version: process.env.VUE_APP_VERSION,
        created_at: createdAt, // Signup date as a Unix timestamp
        language_override: userLocale,
      };

      dispatch('setIntercomVisibility');
    }
  },

  setIntercomVisibility() {
    // @ts-ignore
    window.Intercom('update', {
      hide_default_launcher: false,
      // vertical_padding: 24,
      // horizontal_padding: 126,
    });
  },
};
