import CorrectName from '@/entities/common/CorrectName';
import CustomRoute from '@/entities/common/CustomRoute';

export default [
  {
    path: '/tasks',
    name: CustomRoute.TO_TASKS,
    meta: {
      title: 'common_tasks_title',
      moduleCode: CorrectName.TASK,
      eventOnEnter: 'tasks_menu_btn',
    },
    component: () => import(/* webpackChunkName: "tasks" */ '@/views/tasks/index.vue'),
  },

  {
    path: '/tasks/history/:status?',
    alias: '/tasks/:taskId/answers/:answerId',
    name: CustomRoute.TO_TASKS_HISTORY,
    meta: {
      title: 'tasks_history_subsection_title_text',
      moduleCode: CorrectName.TASK,
      eventOnEnter: 'tasks_history_tab_btn',
    },
    component: () => import(/* webpackChunkName: "tasks" */ '@/views/tasks/history.vue'),
  },

  {
    path: '/tasks/:id/:answerId?',
    name: CustomRoute.TO_TASKS_MATERIAL,
    meta: {
      title: 'common_tasks_title',
      moduleCode: CorrectName.TASK,
      from: {
        'task-list': 'tasks_view_task_cell',
        'task-feedback': 'tasks_view_task_again_btn',
        'task-history': 'tasks_history_view_task_again_btn',
      },
      showBlockedMessages: false,
    },
    component: () => import(/* webpackChunkName: "tasks" */ '@/views/tasks/task.vue'),
  },

  {
    path: '/tasks/:id/feedback',
    name: CustomRoute.TO_TASKS_FEEDBACK,
    meta: {
      title: 'common_tasks_title',
      moduleCode: CorrectName.TASK,
      showBlockedMessages: false,
    },
    props: true,
    component: () => import(/* webpackChunkName: "tasks" */ '@/views/tasks/task-feedback.vue'),
  },
];
