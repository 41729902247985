export default class FileSource {
  url: string;
  size?: number;

  constructor(url: string, size?: number) {
    this.url = url;
    this.size = size;
  }
}

export function createFileSource(payload: any): FileSource | undefined {
  if (payload && payload.url) {
    return new FileSource(payload.url, payload.size);
  }
}
