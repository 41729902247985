import ImageSource, { createImageFromString } from '@/entities/common/sources/ImageSource';
import VideoSource, { createVideoSource } from '@/entities/common/sources/VideoSource';
// @ts-ignore
import { createMaterialCardWidget } from '@/entities/common/MaterialCardWidget';
import slugProvider from '@/providers/slugProvider';
import dateProvider from '@/providers/dateProvider';
import has from 'lodash/has';
import CustomRoute from '@/entities/common/CustomRoute';

export default class VideoMaterial {
  id: number;
  name: string;
  image?: ImageSource;
  sources?: VideoSource;
  isNew: boolean;
  views: number;
  likes: number;
  isViewed: boolean;
  isLiked: boolean;
  updatedAt?: Date;

  constructor(payload: any) {
    this.id = parseInt(has(payload, 'entity_id') ? payload.entity_id : payload.id, 10);
    this.name = payload.name;
    this.image = createImageFromString(payload.image.origin.url);
    this.sources = createVideoSource(payload.video);
    this.isNew = parseInt(payload.is_new, 10) === 1;
    this.views = parseInt(payload.views, 10);
    this.likes = parseInt(payload.likes, 10);
    this.isViewed = parseInt(payload.viewed, 10) === 1;
    this.isLiked = parseInt(payload.liked, 10) === 1;
    this.updatedAt = dateProvider(payload.updated_at);
  }

  getWidget(): object {
    return createMaterialCardWidget({
      id: this.id,
      to: {
        name: CustomRoute.TO_VIDEOS_MATERIAL,
        params: { id: this.id, slug: slugProvider(this.name) },
      },
      name: this.name,
      type: 'video',
      image: this.image,
      isNew: this.isNew,
      likes: this.likes,
      isLiked: this.isLiked,
      views: this.views,
      isViewed: this.isViewed,
    });
  }
}
