<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.02661 9.3719C2.02661 5.99025 4.76798 3.24889 8.14962 3.24889C11.5313 3.24889 14.2726 5.99025 14.2726 9.3719C14.2726 12.7535 11.5313 15.4949 8.14962 15.4949C4.76798 15.4949 2.02661 12.7535 2.02661 9.3719ZM10.9485 10.2074C11.4096 10.2074 11.7834 9.83353 11.7834 9.3724C11.7834 8.91126 11.4096 8.53744 10.9485 8.53744H9.00025V5.47593C9.00025 5.0148 8.62642 4.64098 8.16529 4.64098C7.70416 4.64098 7.33033 5.0148 7.33033 5.47593V8.98274C7.33033 9.65907 7.87861 10.2074 8.55495 10.2074H10.9485Z"
      :fill="color"
    />
    <path
      d="M5.64991 1.35722C5.64991 0.883791 6.03371 0.5 6.50714 0.5H9.79269C10.2661 0.5 10.6499 0.883791 10.6499 1.35722C10.6499 1.83065 10.2661 2.21444 9.79269 2.21444H6.50714C6.03371 2.21444 5.64991 1.83065 5.64991 1.35722Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'time',

  props: {
    color: {
      type: String,
      default: '#00000066',
    },
  },
};
</script>
