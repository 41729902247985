<template>
  <div class="app-tabs">
    <router-link
      v-for="(tab, index) in tabs"
      :key="index"
      :to="tab.to"
      class="app-tabs__item"
      :class="{
        'app-tabs__item--active': tab.isActive,
        'app-tabs__item--has-new': tab.hasNew,
      }"
    >
      {{ tab.label }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'app-tabs',

  props: {
    tabs: {
      type: Array,
      default: null,
    },
  },
};
</script>
