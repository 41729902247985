import { ActionTree } from 'vuex';
import searchApi from '@/services/search';
import { SearchState } from './types';
import { RootState } from '../../types';

export const actions: ActionTree<SearchState, RootState> = {
  async groupedSearch({}, q) {
    return searchApi.groupedSearch(q);
  },

  async search({}, { q, moduleCode, moduleIds }) {
    return searchApi.search(q, moduleCode, moduleIds);
  },
};
