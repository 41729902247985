<template>
  <base-home-widget
    :widget-name="resultTitle"
    :to="to"
    :count-text="countText"
  >
    <template #content>
      <slot></slot>

      <app-slider
        :key="`slider-${componentId}-${sliderIndex}`"
        :columns="ww4Columns"
        :column-size="ww4ColumnsForIsX"
      >
        <MyResultEntity
          v-for="(entity, i) in sortResults"
          :id="id"
          :key="i"
          :my-team="myTeam"
          :slug="slug"
          :entity="entity"
        />
      </app-slider>
    </template>
  </base-home-widget>
</template>

<script>
import { mapGetters } from 'vuex';
import AppSlider from '@/components/helpers/slider.vue';
import MyResultEntity from '@/components/app/home/my-result-entity.vue';
import { find } from 'lodash';
import ResultsWidget from '@/entities/modules/dashboard/ResultsWidget';
import BaseHomeWidget from '@/components/app/home/base-home-widget.vue';
import { sortModuleElementsProvider } from '@/providers/sortModuleElementsProvider';
import CustomRoute from '@/entities/common/CustomRoute';

export default {
  name: 'home-my-results',

  components: {
    AppSlider,
    MyResultEntity,
    BaseHomeWidget,
  },

  props: {
    widget: {
      type: ResultsWidget,
      default: null,
    },

    myTeam: {
      type: Boolean,
      default: false,
    },

    id: {
      type: Number,
      default: null,
    },

    managerId: {
      type: Number,
      default: null,
    },

    slug: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    sliderIndex: 0,
    componentId: null,
  }),

  computed: {
    ...mapGetters('helpers', ['ww4Columns', 'ww4ColumnsForIsX']),

    ...mapGetters('settings', ['modulesList', 'allModules']),

    resultTitle() {
      const module = find(this.modulesList, o => o.code === 'my-results');

      return module && module.title ? module.title : this.$t('myteam_results_title_text');
    },

    sortResults() {
      return sortModuleElementsProvider(this.allModules, this.widget.results);
    },

    to() {
      return this.myTeam
        ? {
            name: CustomRoute.TO_MY_TEAM_EMPLOYEE_RESULTS,
            params: { id: this.id, slug: this.slug, modules: [] },
            query: this.$route.query,
          }
        : {
            name: CustomRoute.TO_MY_RESULTS_LEARNING,
            query: { from: 'dashboard-widget-name' },
          };
    },

    countText() {
      if (this.myTeam) {
        return '';
      }

      const studiedMaterialsAmount = this.widget.getStudiedMaterialsAmount();

      const totalAmount = this.widget.getTotalAmount();

      const percent = Math.floor((studiedMaterialsAmount * 100) / totalAmount) || 0;

      return this.$t('myresults_section_pass_percentage_total_text_web', [
        studiedMaterialsAmount,
        totalAmount,
        percent,
      ]);
    },
  },

  watch: {
    widget: {
      deep: true,
      handler() {
        this.sliderIndex += 1;
      },
    },
  },

  mounted() {
    this.componentId = this._uid;
  },
};
</script>
