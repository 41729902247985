<template>
  <div class="app-search-results-item">
    <AppFavorite
      v-if="showFavorite"
      :material-id="material.id"
      :material-type="materialType"
      :is-favorite="material.isFavorite"
      @changeFavoriteStatus="changeFavoriteStatus"
    />

    <div class="app-search-results-item__view">
      <div
        v-if="material.type !== 'events'"
        :class="classes"
        :style="styles"
        class="app-search-results-item__image"
      >
        <template v-if="!material.image">
          <MaterialIcon :material-type="defaultNotFoundImageType" />
        </template>
      </div>

      <div
        v-if="isMaterial && material.isLocked"
        class="app-search-results-item__lock"
      >
        <div class="app-search-results-item__lock-icon">
          <LockedSvg />
        </div>
      </div>
      <div
        v-if="material.moduleCode === 'events'"
        class="app-search-results-item__event"
      >
        <div v-if="eventDate">{{ eventDate }}</div>
        <div v-if="eventTime">{{ eventTime }}</div>
        <span v-if="!eventDate && !eventTime"></span>
      </div>
    </div>
    <div class="app-search-results-item__content">
      <router-link
        :to="routeTo"
        class="app-search-results-item__name"
      >
        <span v-html="highlightedName"></span>
      </router-link>
      <AppMarkdown
        tag="div"
        class="app-search-results-item__type"
        :source="sectionName"
      />
    </div>
  </div>
</template>

<script>
import slugProvider from '@/providers/slugProvider';
import CorrectName, { getApiCodeByCorrectName } from '@/entities/common/CorrectName';
import getModuleNameByCorrectName from '@/providers/correctNameProvider';
import ApiName from '@/entities/common/ApiName';
import AppFavorite from '@/components/helpers/favorite.vue';
import AppMarkdown from '@/components/helpers/markdown.vue';
import moment from 'moment';
import { has } from 'lodash';
import { mapGetters } from 'vuex';
import CustomRoute from '@/entities/common/CustomRoute';
import MaterialIcon from '@/components/app/learning-programs/material-icon.vue';
import LockedSvg from '@/components/svg/icons/lock.vue';

export default {
  name: 'search-result-entity',

  components: {
    AppFavorite,
    MaterialIcon,
    AppMarkdown,
    LockedSvg,
  },

  props: {
    material: {
      type: Object,
      default: null,
    },

    q: {
      type: String,
      default: '',
    },
  },

  computed: {
    ...mapGetters('auth', ['user']),

    highlightedName() {
      const name = this.material.name ? this.material.name.replace(/<[^>]*>?/gm, '') : '';

      if (!this.q && this.q.length === 0) {
        return name;
      }

      const index = name.toUpperCase().indexOf(this.q.toUpperCase());

      if (index === -1) {
        return name;
      }

      const before = name.substring(0, index);

      const needed = name.substring(index, index + this.q.length);

      const after = name.substring(index + this.q.length);

      return `${before}<span>${needed}</span>${after}`;
    },

    sectionName() {
      if (this.isMaterial) {
        let name = this.$pt(this.material.getMaterialTypeKey());
        name += '&nbsp;&nbsp;·&nbsp;&nbsp;';
        name += this.material.learningProgramName;

        return name;
      }

      return getModuleNameByCorrectName(this.material.moduleCode, this.material.moduleId);
    },

    isIconAre16on9() {
      if (!this.material.image) {
        return true;
      }

      switch (this.material.moduleCode) {
        case CorrectName.VIDEO:
        case CorrectName.LEARNING_PROGRAMS:
        case CorrectName.MATERIAL:
          return true;
        default:
          return false;
      }
    },

    isIconLikeCircle() {
      return this.material.moduleCode === CorrectName.TEAM && this.material.image;
    },

    isTestOrCompetency() {
      return (
        this.material.moduleCode === CorrectName.TESTS ||
        this.material.moduleCode === CorrectName.COMPETENCY
      );
    },

    isMaterial() {
      return this.material.moduleCode === CorrectName.MATERIAL;
    },

    defaultNotFoundImageType() {
      switch (this.material.moduleCode) {
        case CorrectName.TESTS:
        case CorrectName.COMPETENCY:
          return 'test';
        case CorrectName.INTERVIEW:
          return 'survey';
        case CorrectName.TASK:
          return 'task';
        case CorrectName.MATERIAL:
          return this.material.type;
        case CorrectName.LEARNING_PROGRAMS:
          return 'learning_program';
        case CorrectName.VIDEO:
          return 'video';
        default:
          return 'image';
      }
    },

    showFavorite() {
      return this.material.moduleCode !== CorrectName.MATERIAL;
    },

    eventDate() {
      if (this.material.isEvent) {
        return moment(this.material.eventDate).format('D MMM');
      }

      return '';
    },

    eventTime() {
      if (this.material.isEvent) {
        return moment(this.material.eventDate).format('dd LT');
      }

      return '';
    },

    materialType() {
      switch (this.material.moduleCode) {
        case CorrectName.INFORMATION:
          if (this.material.categoryId && has(this.material, 'subcategoryId')) {
            return ApiName.INFORMATION;
          }

          return ApiName.INFORMATION_CATEGORY;

        case CorrectName.TESTS:
          return ApiName.TEST;
        case CorrectName.COMPETENCY:
          return ApiName.COMPETENCY;
        case CorrectName.INTERVIEW:
          return ApiName.INTERVIEW;
        case CorrectName.LEARNING_TRACK:
          return ApiName.LEARNING_TRACK;
        case CorrectName.LEARNING_PROGRAMS:
          return ApiName.LEARNING_PROGRAM;
        case CorrectName.LEARNING_MATERIALS:
          return getApiCodeByCorrectName(this.material.type) || ApiName.LEARNING_MATERIALS;
        case CorrectName.VIDEO:
          return ApiName.VIDEO;
        case CorrectName.EVENT:
          return ApiName.EVENT;
        case CorrectName.TEAM:
          return ApiName.TEAM;
        case CorrectName.MATERIAL:
          return ApiName.MATERIAL;
        default:
          return '';
      }
    },

    routeTo() {
      if (this.material.moduleCode === 'material') {
        return {
          name: CustomRoute.TO_LEARNING_PROGRAMS_MATERIAL,
          params: {
            moduleId: this.material.moduleId,
            id: this.material.learningProgramId,
            searchedMaterialId: this.material.id,
          },
        };
      }

      switch (this.material.type) {
        case CorrectName.INFORMATION:
          const hasSubcategory = this.material.subcategoryId !== undefined;

          if (this.material.categoryId) {
            if (hasSubcategory) {
              return {
                name: CustomRoute.TO_INFORMATION_MATERIAL,
                params: {
                  categoryId: this.material.categoryId,
                  categorySlug: 'not-provided',
                  subcategoryId: this.material.subcategoryId,
                  subcategorySlug: 'not-provided',
                  id: this.material.id,
                  slug: slugProvider(this.material.name),
                },
              };
            }

            return {
              name: CustomRoute.TO_INFORMATION_SUBCATEGORY,
              params: {
                categoryId: this.material.categoryId,
                categorySlug: 'not-provided',
                id: this.material.id,
                slug: slugProvider(this.material.name),
              },
            };
          }

          return {
            name: CustomRoute.TO_INFORMATION_CATEGORY,
            params: {
              id: this.material.id,
              slug: slugProvider(this.material.name),
            },
          };

        case CorrectName.TESTS:
          return {
            name: CustomRoute.TO_TESTS_MATERIAL,
            params: {
              id: this.material.id,
              slug: slugProvider(this.material.name),
            },
          };

        case CorrectName.COMPETENCY:
          return {
            name: CustomRoute.TO_COMPETENCIES_MATERIAL,
            params: {
              id: this.material.id,
              slug: slugProvider(this.material.name),
            },
          };

        case CorrectName.INTERVIEW:
          return {
            name: CustomRoute.TO_INTERVIEWS_MATERIAL,
            params: {
              id: this.material.id,
              slug: slugProvider(this.material.name),
            },
          };

        case CorrectName.LEARNING_PROGRAMS:
          return {
            name: CustomRoute.TO_LEARNING_PROGRAMS_MATERIAL,
            params: {
              moduleId: this.material.moduleId,
              id: this.material.id,
            },
          };

        case CorrectName.LEARNING_TRACK:
          return {
            name: CustomRoute.TO_LEARNING_TRACK,
            params: {
              moduleId: this.material.moduleId,
              id: this.material.id,
            },
          };

        case CorrectName.VIDEO:
          return {
            name: CustomRoute.TO_VIDEOS_MATERIAL,
            params: {
              id: this.material.id,
              slug: slugProvider(this.material.name),
            },
          };

        case CorrectName.EVENT:
          return {
            name: this.material.isEvent
              ? CustomRoute.TO_EVENTS_SCHEDULED_MATERIAL
              : CustomRoute.TO_EVENTS_PLANS_MATERIAL,
            params: {
              id: this.material.id,
              slug: slugProvider(this.material.name),
            },
          };

        case CorrectName.TEAM:
          return {
            name: CustomRoute.TO_MY_TEAM_EMPLOYEE_MATERIAL,
            params: {
              managerId: this.user.id,
              id: this.material.id,
              slug: slugProvider(this.material.name),
            },
          };

        default:
          return {};
      }
    },

    classes() {
      const classes = [];

      if (this.isIconAre16on9) {
        classes.push('app-search-results-item__image--16on9');
      }

      if (!this.isIconAre16on9) {
        classes.push('app-search-results-item__image--row');
      }

      if (this.isIconLikeCircle) {
        classes.push('app-search-results-item__image--circle');
      }

      if (this.isMaterial && this.material.isLocked) {
        classes.push('app-search-results-item__image--locked');
      }

      return classes;
    },

    styles() {
      return this.material.image
        ? `background-image: url(${this.material.image.thumb}); background-color: none`
        : '';
    },
  },

  methods: {
    changeFavoriteStatus(status) {
      this.material.setFavorite(status);
    },
  },
};
</script>
