export default [
  {
    path: '*',
    name: 'error-404',
    meta: { title: 'common_404_error_title_text_web' },
    component: () => import(/* webpackChunkName: "other" */ '@/views/errors/404.vue'),
  },
  // не удалять
  {
    path: '/404',
    name: 'error-404-page',
    meta: { title: 'common_404_error_title_text_web' },
    component: () => import(/* webpackChunkName: "other" */ '@/views/errors/404.vue'),
  },
];
