<template>
  <div class="app-sl-header">
    <div class="container">
      <div class="app-sl-header__title">
        <div class="app-sl__line app-sl-w1/3"></div>
      </div>
      <div class="app-sl-header__tag">
        <div class="app-sl__wrapper app-sl-mb25">
          <div class="app-sl__ellipse app-sl-mr25"></div>
          <div class="app-sl__ellipse app-sl-mr25"></div>
          <div class="app-sl__ellipse app-sl-mr25"></div>
          <div class="app-sl__ellipse"></div>
        </div>
      </div>

      <div class="app-sl-header__tabs">
        <div class="app-sl__wrapper">
          <div class="app-sl__ellipse app-sl__ellipse-small app-sl-mr25"></div>
          <div class="app-sl__ellipse app-sl__ellipse-small app-sl-mr25"></div>
          <div class="app-sl__ellipse app-sl__ellipse-small app-sl-mr25"></div>
          <div class="app-sl__ellipse app-sl__ellipse-small"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'header-loading',
};
</script>
