import BaseAuthType from '@/entities/modules/settings/auth-types/BaseAuthType';
import { i18n } from '@/lang';

export default class SmsAuthType extends BaseAuthType {
  getName() {
    return this.name === '' ? i18n.t('auth_via_sms_text').toString() : this.name;
  }

  getDescription() {
    return this.description === ''
      ? i18n.t('auth_enter_phone_number_hint_text').toString()
      : this.description;
  }
}
