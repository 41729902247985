<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :class="classes"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.0002
    5.99988C9.24691 5.99988 6.79976 7.12784 5.06408 8.33886C4.19192 8.94738 3.47292 9.59494 2.96074 10.17C2.70587 10.4562 2.48768
    10.7407 2.32668 11.0077C2.19303 11.2294 2.00024 11.5947 2.00024 11.9999C2.00024 12.4051 2.19303 12.7703 2.32668 12.992C2.48768
    13.2591 2.70587 13.5436 2.96074 13.8297C3.47292 14.4048 4.19192 15.0524 5.06408 15.6609C6.79976 16.8719 9.24691 17.9999
    12.0002 17.9999C14.7536 17.9999 17.2007 16.8719 18.9364 15.6609C19.8086 15.0524 20.5276 14.4048 21.0398 13.8297C21.2946
    13.5436 21.5128 13.2591 21.6738 12.992C21.8075 12.7703 22.0002 12.4051 22.0002 11.9999C22.0002 11.5947 21.8075 11.2294
    21.6738 11.0077C21.5128 10.7407 21.2946 10.4562 21.0398 10.17C20.5276 9.59494 19.8086 8.94738 18.9364 8.33886C17.2007
    7.12784 14.7536 5.99988 12.0002 5.99988ZM3.97946 12.1545C3.97938 12.1547 3.97945 12.1545 3.97946 12.1545L3.98036 12.152C3.97995
    12.153 3.97959 12.1541 3.97946 12.1545ZM4.45428 11.5002C4.27361 11.703 4.14579 11.8727 4.06462 11.9999C4.14579 12.1271 4.27361
    12.2967 4.45428 12.4996C4.85295 12.9472 5.45197 13.4928 6.20849 14.0207C7.73017 15.0824 9.78301 15.9999 12.0002 15.9999C14.2175
    15.9999 16.2703 15.0824 17.792 14.0207C18.5485 13.4928 19.1475 12.9472 19.5462 12.4996C19.7269 12.2967 19.8547 12.1271 19.9359
    11.9999C19.8547 11.8727 19.7269 11.703 19.5462 11.5002C19.1475 11.0525 18.5485 10.5069 17.792 9.97908C16.3504 8.97321 14.4319
    8.09678 12.3487 8.00738C12.1227 8.37766 11.9925 8.81276 11.9925 9.27827C11.9925 10.6277 13.0865 11.7217 14.436 11.7217C14.9917
    11.7217 15.5041 11.5362 15.9146 11.2237C15.5335 12.8094 13.9258 13.9999 12.0002 13.9999C9.79111 13.9999 8.00024 12.4329 8.00024
     10.4999C8.00024 9.83997 8.20897 9.22272 8.57179 8.69591C7.69762 9.04593 6.89845 9.49768 6.20849 9.97908C5.45197 10.5069
     4.85295 11.0525 4.45428 11.5002ZM3.98037 11.8478C3.97971 11.8461 3.97943 11.8453 3.97946 11.8453L3.98037 11.8478Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'view',

  props: {
    isPrimary: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classes() {
      return this.isPrimary ? 'svg-fill-primary' : 'svg-fill-surface-emphasis20';
    },
  },
};
</script>
