import axios from 'axios';
import { each, find } from 'lodash';
import UpdateState from '@/entities/common/testing/updates/UpdateState';
import Statistic from '@/entities/modules/tests/Statistic';
import Level from '@/entities/modules/tests/Level';
import Test from '@/entities/modules/tests/Test';
import Settings from '@/entities/modules/tests/Settings';
import QuestionType from '@/entities/common/testing/QuestionType';
import { errorLogProvider, warningLogProvider } from '@/providers/logProvider';
import has from 'lodash/has';

export default {
  async getUpdates(): Promise<[UpdateState[], UpdateState[]]> {
    const response = await axios.get('/evaluations/tests/updates');

    const levelsUpdateList: UpdateState[] = [];

    const testsUpdateList: UpdateState[] = [];

    each(response.data.success.levels, o => levelsUpdateList.push(new UpdateState(o)));
    each(response.data.success.tests, o => testsUpdateList.push(new UpdateState(o)));

    return [levelsUpdateList, testsUpdateList];
  },

  async getStatistic(loadedIdList: number[]): Promise<Statistic[]> {
    let response: any;

    if (loadedIdList.length === 0) {
      response = await axios.get('/evaluations/tests/statistic');
    } else {
      response = await axios.post('/evaluations/tests/statistic', { items: loadedIdList });
    }

    const statistics: Statistic[] = [];
    each(response.data.success.tests, o => statistics.push(new Statistic(o)));

    return statistics;
  },

  async getMaterials(statistic: Statistic[], settings: Settings): Promise<[Level[], Test[]]> {
    const response = await axios.get('/evaluations/tests-cr');

    const levels: Level[] = [];

    const materials: Test[] = [];

    each(response.data.success.levels, level => {
      const currentLevel = new Level(level);
      levels.push(currentLevel);

      each(level.tests, test => {
        const materialId = parseInt(test.id, 10);

        const pretenderStatistic = find(statistic, o => o.id === materialId);

        if (!pretenderStatistic) {
          errorLogProvider(`Statistic for material #${materialId} not found in statistic list`);

          return;
        }

        const pretender = new Test(test, pretenderStatistic, settings);

        if (pretender.questions.length === 0) {
          warningLogProvider(`0 questions in material #${materialId}`);

          return;
        }

        pretender.setIsAvailable(currentLevel.isAvailable);

        let hasBrokenQuestion = false;
        each(pretender.questions, question => {
          if (question.options.length === 0 && question.type !== QuestionType.OPEN_QUESTION) {
            hasBrokenQuestion = true;
          }
        });

        if (hasBrokenQuestion) {
          return;
        }

        materials.push(pretender);
      });
    });

    return [levels, materials];
  },

  async getMaterialsByList(
    loadedIdList: number[],
    statistic: Statistic[],
    settings: Settings,
  ): Promise<Test[]> {
    const response = await axios.post('/evaluations/tests-cr', { tests: loadedIdList });

    const materials: Test[] = [];

    each(response.data.success.tests, test => {
      const materialId = parseInt(test.id, 10);

      const pretenderStatistic = find(statistic, o => o.id === materialId);

      if (!pretenderStatistic) {
        errorLogProvider(`Statistic for material #${materialId} not found in statistic list`);

        return;
      }

      const pretender = new Test(test, pretenderStatistic, settings);

      if (pretender.questions.length === 0) {
        warningLogProvider(`0 questions in material #${materialId}`);

        return;
      }

      let hasBrokenQuestion = false;
      each(pretender.questions, question => {
        if (question.options.length === 0 && question.type !== QuestionType.OPEN_QUESTION) {
          hasBrokenQuestion = true;
        }
      });

      if (hasBrokenQuestion) {
        return;
      }

      materials.push(pretender);
    });

    return materials;
  },

  async getUuid(id: number): Promise<[string, number] | undefined> {
    const response = await axios.post(`/evaluations/test/${id}/increment-turn`);

    if (has(response, 'data.success.turn_uuid')) {
      return [response.data.success.turn_uuid, parseInt(response.data.success.timestamp, 10)];
    }
  },

  async sendStatistic(payload: any) {
    const response = await axios.post('/evaluations/tests/update-statistic', { tests: [payload] });

    if (has(response, 'data.success')) {
      return parseInt(response.data.success[0].success.percent, 10);
    }
  },

  async clearIsNew(id: number) {
    await axios.post('/evaluations/tests/clear-is-new', { tests: [id] });
  },

  async clearIsChecked(id: number) {
    await axios.post('/evaluations/tests/clear-is-checked', { tests: [id] });
  },
};
