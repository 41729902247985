<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="svg-error"
      d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.889 7.88889C15.889 5.74111 14.1479 4 12.0001 4C9.85234 4 8.11122 5.74112 8.11122 7.88889V10.0133C7.65934 10.0338 7.35138 10.0858 7.09202 10.218C6.71569 10.4097 6.40973 10.7157 6.21799 11.092C6 11.5198 6 12.0799 6 13.2V15.8C6 16.9201 6 17.4802 6.21799 17.908C6.40973 18.2843 6.71569 18.5903 7.09202 18.782C7.51984 19 8.0799 19 9.2 19H14.8C15.9201 19 16.4802 19 16.908 18.782C17.2843 18.5903 17.5903 18.2843 17.782 17.908C18 17.4802 18 16.9201 18 15.8V13.2V13.2C18 12.0799 18 11.5198 17.782 11.092C17.5903 10.7157 17.2843 10.4097 16.908 10.218C16.6487 10.0859 16.3408 10.0338 15.889 10.0133V7.88889ZM9.66688 8.00012V10H14.3336V8.00012C14.3336 6.71145 13.2889 5.66678 12.0002 5.66678C10.7116 5.66678 9.66688 6.71145 9.66688 8.00012ZM14 16L12 13L10 16H14Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'blocked',
};
</script>

<style scoped></style>
