import { each } from 'lodash';

export function prepareHtml(text: string) {
  if (!text) {
    return '';
  }

  const parser = new DOMParser();

  const doc = parser.parseFromString(text, 'text/html');

  const p = doc.querySelectorAll('p');

  each(p, element => {
    if (element.children.length === 1 && element.innerHTML.trim() === '<br>') {
      // @ts-ignore
      element.removeChild(element.firstChild);
    }
  });

  return (doc.querySelector('body') as HTMLBodyElement).innerHTML;
}
