import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { userLocale, userLocaleJson, getChoiceIndex } from './locale';

VueI18n.prototype.getChoiceIndex = getChoiceIndex;

Vue.use(VueI18n);

const ru = userLocale === 'ru' ? userLocaleJson : {};

const en = userLocale === 'en' ? userLocaleJson : {};

const fr = userLocale === 'fr' ? userLocaleJson : {};

const et = userLocale === 'et' ? userLocaleJson : {};

const pl = userLocale === 'pl' ? userLocaleJson : {};

const kk = userLocale === 'kk' ? userLocaleJson : {};

const uk = userLocale === 'uk' ? userLocaleJson : {};

const ka = userLocale === 'ka' ? userLocaleJson : {};

const hy = userLocale === 'hy' ? userLocaleJson : {};

const uz = userLocale === 'uz' ? userLocaleJson : {};

const mn = userLocale === 'mn' ? userLocaleJson : {};

const az = userLocale === 'az' ? userLocaleJson : {};

const ro = userLocale === 'ro' ? userLocaleJson : {};

const tr = userLocale === 'tr' ? userLocaleJson : {};

const zh = userLocale === 'zh' ? userLocaleJson : {};

const ky = userLocale === 'ky' ? userLocaleJson : {};

const de = userLocale === 'de' ? userLocaleJson : {};

const ar = userLocale === 'ar' ? userLocaleJson : {};

const tg = userLocale === 'tg' ? userLocaleJson : {};

const hk = userLocale === 'hk' ? userLocaleJson : {};

export const i18n = new VueI18n({
  locale: userLocale,
  fallbackLocale: userLocale,
  messages: {
    ru,
    en,
    fr,
    et,
    pl,
    kk,
    uk,
    ka,
    hy,
    uz,
    mn,
    az,
    ro,
    tr,
    zh,
    ky,
    de,
    ar,
    tg,
    hk,
  },
});
