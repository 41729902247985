<template>
  <div class="app-button">
    <button
      :type="isSubmit ? 'submit' : 'button'"
      :disabled="isDisabled || isLoading"
      :class="{ loading: isLoading, fullwidth: isFullwidth }"
    >
      {{ text }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },

    isSubmit: {
      type: Boolean,
      default: false,
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isFullwidth: {
      type: Boolean,
      default: true,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    componentId: null,
  }),

  mounted() {
    this.componentId = this._uid;
  },
};
</script>
