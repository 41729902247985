<template>
  <div
    :class="{
      'app-notification__item--is-big': notification.title,
      'app-notification__item--white': notification.isWhite,
    }"
    class="app-notification__item"
  >
    <AppMarkdown
      v-if="notification.title"
      class="app-notification__title"
      :source="notification.title"
    />

    <AppMarkdown
      class="app-notification__text"
      :source="notification.message"
    />

    <span
      v-if="!notification.buttons || notification.hasCloseIcon"
      class="app-notification__close"
      :class="{
        'app-notification__close--small-padding':
          notification.buttons && notification.buttons.length === 1,
      }"
      @click="removeNotification(notification.id)"
    >
      <AppCloseIcon :is-white="true" />
    </span>

    <div
      v-if="notification.buttons"
      class="app-notification__buttons"
    >
      <a
        v-for="(button, i) in notification.buttons"
        :key="i"
        @click="clickNotificationButton(notification, button)"
      >
        {{ button.label }}
      </a>
    </div>
  </div>
</template>

<script>
import AppCloseIcon from '@/components/svg/icons/close-24px';
import AppMarkdown from '@/components/helpers/markdown';

export default {
  name: 'notification',

  components: {
    AppCloseIcon,
    AppMarkdown,
  },

  props: {
    notification: {
      type: Object,
      default: null,
    },
  },

  methods: {
    removeNotification(id) {
      this.$store.dispatch('helpers/removeNotification', id);
    },

    clickNotificationButton(notification, button) {
      if (button.callback && typeof button.callback === 'function') {
        button.callback();
      }

      this.removeNotification(notification.id);
    },
  },
};
</script>
