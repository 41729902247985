export default class BadgesCategory {
  id: number;
  name: string;
  order: number;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.name = payload.name;
    this.order = parseInt(payload.order, 10);
  }
}
