import { ActionTree } from 'vuex';
import { MyTeamState } from '@/store/modules/my-team/types';
import { RootState } from '@/store/types';
// eslint-disable-next-line import/no-cycle
import myTeamApi from '@/services/my-team';
import groupsApi from '@/services/groups';
import { each } from 'lodash';
import { createEmployeeFormFilterResult } from '@/entities/modules/my-team/EmployeeFormFilterResult';
import { getSavingDataEmployeeFilters } from '@/entities/modules/my-team/EmployeeFormFilter';
import Pagination from '@/entities/common/Pagination';
import UserFilter from '@/entities/modules/my-team/filters/UserFilter';
import UserTaskFilter from '@/entities/modules/my-team/filters/UserTaskFilter';
import {
  IUserDefaultValueFilter,
  IUserTaskValueFilter,
  UserFilterResponseItem,
} from '@/types/my-team/filters';

const isTasksUserFilter = (filter: UserFilter | UserTaskFilter): filter is UserTaskFilter =>
  (filter as UserTaskFilter).value.categories !== undefined;

const isTaskResponseFilter = (
  filter:
    | UserFilterResponseItem<IUserDefaultValueFilter>
    | UserFilterResponseItem<IUserTaskValueFilter>,
): filter is UserFilterResponseItem<IUserTaskValueFilter> =>
  (filter as UserFilterResponseItem<IUserTaskValueFilter>).value.categories !== undefined;

export const actions: ActionTree<MyTeamState, RootState> = {
  getMyTeam({}, { page, id }) {
    return myTeamApi.getMyTeam(page, 0, id);
  },

  async getEmployee(o, { id, managerId }) {
    const employee = await myTeamApi.getEmployee(id, managerId);

    if (!employee) {
      return null;
    }

    return employee;
  },

  async getEmployeeMeta(o, { id, managerId }) {
    const meta = await myTeamApi.getEmployeeMeta(id, managerId);

    if (!meta) {
      return null;
    }

    return meta;
  },

  getEmployeeAwards(o, id) {
    return myTeamApi.getEmployeeAwards(id);
  },

  async assignEmployeeAward(o, { badgeId, userId, comment }) {
    const payload = await myTeamApi.assignEmployeeAward(badgeId, userId, comment);

    if (!payload) {
      return null;
    }

    return payload;
  },

  async cancelEmployeeAward(o, id) {
    const payload = await myTeamApi.cancelEmployeeAward(id);

    if (!payload) {
      return null;
    }

    return payload;
  },

  async getMaterials(o, payload) {
    return myTeamApi.getMaterials(payload);
  },

  async getMaterial(o, { category, id, managerId, status = null }) {
    const payload = await myTeamApi.getMaterial({ category, id, managerId, status });

    if (!payload) {
      return null;
    }

    return payload;
  },

  getLearningProgramsDetails(o, { userId, programId, managerId }) {
    return myTeamApi.getLearningProgramsDetails(userId, programId, managerId);
  },

  async getLearningProgramMaterialDetails(o, { userId, programId, materialId, managerId }) {
    const payload = await myTeamApi.getLearningProgramMaterialDetails(
      userId,
      programId,
      materialId,
      managerId,
    );

    if (!payload) {
      return null;
    }

    return payload;
  },

  async getEmployeeResult(o, { id, managerId }) {
    return myTeamApi.getEmployeeResult(id, managerId);
  },

  async getEmployeeFilters({ commit }, { managerId }) {
    const groups = await groupsApi.getRootGroups();

    const groupFilter = groups.sort((a, b) => a.name.localeCompare(b.name));

    const formFilter = await myTeamApi.getFormFilters(managerId);
    formFilter.push(groupFilter);

    commit('setEmployeeTeamsFilters', formFilter);
  },

  async getAnswerForFilter(o, { managerId, filterId, searchValue }) {
    const answers = [];

    let page = 1;

    let totalCount = 0;

    do {
      // eslint-disable-next-line no-await-in-loop
      const payload = await myTeamApi.getAnswerForFilter(managerId, filterId, searchValue, page);

      if (!payload) {
        return false;
      }

      answers.push(...payload.data.success.answers);

      const meta = new Pagination(payload.data.success.meta.pagination);
      page = meta.currentPage + 1;
      totalCount = meta.totalCount;
    } while (answers.length < totalCount);

    return createEmployeeFormFilterResult(answers);
  },

  async getGroups({ state }, { rootId, managerId }) {
    for (const section of state.employeeTeamFilters) {
      // @ts-ignore
      for (const sectionFilter of section) {
        if (sectionFilter.id === rootId) {
          // @ts-ignore
          if (sectionFilter.children.length === 0) {
            // eslint-disable-next-line no-await-in-loop
            const { elements, addedMeta } = await myTeamApi.getGroups(rootId, managerId);
            // @ts-ignore
            sectionFilter.setHasUsers(addedMeta.hasUsers);
            // @ts-ignore
            sectionFilter.createTreeGroups(elements, addedMeta);
          }

          return;
        }
      }
    }
  },

  async getUserCountForFilter({ state, commit }, { managerId }) {
    const response = await myTeamApi.getUserCountForFilter(
      managerId,
      getSavingDataEmployeeFilters(state.employeeTeamFilters),
    );

    if (!response) {
      commit('changeCountEmployeeForFilter', 0);
    }
    commit('changeCountEmployeeForFilter', response);
  },

  async showUsers({ commit, state }, { managerId, page }) {
    const response = await myTeamApi.showUsers(
      managerId,
      getSavingDataEmployeeFilters(state.employeeTeamFilters),
      page,
    );

    if (!response) {
      return false;
    }

    commit('changeCountAppliedSubfilters');

    return response;
  },

  getEmptyFilter({ commit }) {
    commit('setEmptyFilter');
    commit('changeCountAppliedSubfilters');
    commit('changeCountEmployeeForFilter', 0);
  },

  async dropFilter({ commit, state }, { managerId }) {
    const formFilters: Object = [];
    each(state.employeeTeamFilters, section => {
      each(section, sectionFilter => {
        // @ts-ignore
        sectionFilter.clearSubfilters();
      });
    });

    const response = await myTeamApi.showUsers(managerId, formFilters);

    if (!response) {
      return false;
    }

    commit('changeCountAppliedSubfilters');
    commit('changeCountEmployeeForFilter', 0);

    return response;
  },

  async getEmployeeStatistic(o, { id, type, typeId, managerId }) {
    const payload = await myTeamApi.getEmployeeStatistic(id, type, typeId, managerId);

    if (!payload) {
      return false;
    }

    const material = await myTeamApi.getEmployeeStatisticMeta(id, type, typeId, managerId);

    if (!material) {
      return null;
    }

    return {
      payload,
      material,
    };
  },

  clearMaterials({ commit }) {
    commit('setMaterials', []);
  },

  getReport(o, managerId) {
    return myTeamApi.getReport(managerId);
  },

  getUsersActivityReport(o, managerId) {
    return myTeamApi.getUsersActivityReport(managerId);
  },

  getMaterialsPassingReport(o, { managerId, type, moduleId }) {
    return myTeamApi.getMaterialsPassingReport(managerId, type, moduleId);
  },

  getTestsPassingReport(o, managerId) {
    return myTeamApi.getTestsPassingReport(managerId);
  },

  getTestsScoresReport(o, managerId) {
    return myTeamApi.getTestsScoresReport(managerId);
  },

  getInterviewReport(o, { interviewId, userId, managerId }) {
    return myTeamApi.getInterviewReport(interviewId, userId, managerId);
  },

  async getModules({ commit, getters }, managerId) {
    const modules = getters.getModules;

    if (modules.length) {
      return modules;
    }

    const response = await myTeamApi.getModules(managerId);

    commit('setModules', response);

    return response;
  },

  getMaterialStatuses(o, payload) {
    return myTeamApi.getMaterialStatuses(payload);
  },

  async getTaskUserResult(o, { materialType, materialId, userId, attemptId }) {
    return myTeamApi.getTaskUserResult({ materialType, materialId, userId, attemptId });
  },

  async getUserFilters({ commit }) {
    const userFilters = await myTeamApi.getUserFilters();
    commit('setUserFilters', userFilters);
  },

  async getMyTeamMaterialsCategories(o, { managerId, moduleId }) {
    return myTeamApi.getMyTeamMaterialsCategories(managerId, moduleId);
  },

  async getMyTeamAssignedEmployees(o, { managerId, moduleId }) {
    return myTeamApi.getMyTeamAssignedEmployees(managerId, moduleId);
  },

  async saveUserFilters({ commit }, filter: UserFilter | UserTaskFilter) {
    const response = await myTeamApi.saveUserFilter(filter.transformStateForSave());

    const { filter: responseFilter } = response.data.success;

    if (isTasksUserFilter(filter)) {
      if (isTaskResponseFilter(responseFilter)) {
        const actualUserFilter = new UserTaskFilter(responseFilter);

        actualUserFilter.value.categories = filter.value.categories;
        actualUserFilter.value.users = filter.value.users;

        commit('updateUserFilters', actualUserFilter);
      }
    } else if (!isTaskResponseFilter(responseFilter)) {
      const actualUserFilter = new UserFilter(responseFilter);

      actualUserFilter.value.modules = filter.value.modules;

      commit('updateUserFilters', actualUserFilter);
    }
  },

  getMaterialAttempts(o, payload) {
    return myTeamApi.getMaterialAttempts(payload);
  },

  async saveCheckingTask(o, { payload, moduleType, answerId }) {
    try {
      return await myTeamApi.saveCheckingTask(payload, moduleType, answerId);
    } catch (e) {
      throw e;
    }
  },

  getCheckingTasks() {
    return myTeamApi.getCheckingTasks();
  },

  clearIsNew(o, attemptsIds: Array<number>) {
    return myTeamApi.clearIsNew(attemptsIds);
  },

  deleteComment(o, payload: any) {
    return myTeamApi.deleteComment(payload.commentId, payload.answerId, payload.materialType);
  },
};
