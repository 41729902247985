import { LearningItemType } from '@/entities/modules/learning-items/LearningItemType';
import CertificatesListItem from './CertificatesListItem';

export default class LearningMaterialCertificateListItem extends CertificatesListItem {
  entityId: number;
  entityType: LearningItemType;

  constructor(payload: any) {
    super(payload);

    this.entityId = payload.entity_id;
    this.entityType = payload.entity_type;
  }
}
