export interface LearningItemTagScheme {
  id: number;
  name: string;
}

export default class LearningItemTag {
  id: number;
  name: string;

  constructor(payload: LearningItemTagScheme) {
    this.id = payload.id;
    this.name = payload.name;
  }
}
