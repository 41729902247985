// @ts-ignore
import { createMaterialCardWidget } from '@/entities/common/MaterialCardWidget';
import MaterialStatus from '@/entities/common/testing/MaterialStatus';

export class EventResult {
  id: number;
  name: string;
  points: number;
  maxPoints: number;
  userStatus: number;
  deeplink: string;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.name = payload.name;
    this.points = parseInt(payload.points, 10);
    this.maxPoints = parseInt(payload.max_points, 10);
    this.userStatus = payload.user_status;
    this.deeplink = payload.deeplink;
  }

  getStatus() {
    switch (this.userStatus) {
      case 4:
        return MaterialStatus.PASSED;
      case 6:
        return MaterialStatus.FAILED;
      case 0:
        return MaterialStatus.ASSIGNED;
      default:
        return;
    }
  }

  getWidget() {
    const isPassed = this.userStatus === 4;

    const isFailed = this.userStatus === 6;

    const isAssigned = this.userStatus === 0;

    const parts = [];

    if (isPassed) {
      parts.push([
        'common_d_from_d_points_text',
        this.maxPoints,
        { 0: this.points, 1: this.maxPoints },
      ]);
    }

    if (isAssigned) {
      parts.push([
        'learn_earn_d_points_text',
        this.maxPoints,
        { 0: this.maxPoints.toLocaleString() },
      ]);
    }

    if (isFailed) {
      parts.push(['common_d_from_d_points_text', this.maxPoints, { 0: 0, 1: this.maxPoints }]);
    }

    return createMaterialCardWidget({
      name: this.name,
      type: 'myRatingEvents',
      parts,
      isRating: true,
      isPassed,
      isFailed,
      isAssigned,
      to: this.deeplink,
    });
  }
}
