import BaseGroup from '@/entities/common/groups/BaseGroup';
import { each, find, cloneDeep } from 'lodash';
import Pagination from '@/entities/common/Pagination';

export default class SearchResultGroup extends BaseGroup {
  children: SearchResultGroup[] = [];

  constructor(payload: any, path: any = []) {
    super(payload);

    if (payload.path) {
      const child = find(payload.path, pathItem => pathItem.parent_id === 0);

      if (child) {
        this.children = [new SearchResultGroup(child, payload.path)];
      }
    } else {
      const child = find(path, pathItem => pathItem.parent_id === this.id);

      if (child) {
        this.children = [new SearchResultGroup(child, path)];
      }
    }
  }

  getRoute() {
    if (this.getLastChild()) {
      const route: SearchResultGroup = cloneDeep(this);

      const child: SearchResultGroup = cloneDeep(this);
      child.setChildren([]);
      route.getLastChild()?.setChildren([child]);

      return route.getFirstChild();
    }

    return this;
  }
}

export function createSearchGroupsList(payload: any): [Pagination, SearchResultGroup[]] {
  const pagination = new Pagination(payload.meta.pagination);

  const groups: SearchResultGroup[] = [];

  each(payload.groups, group => {
    groups.push(new SearchResultGroup(group));
  });

  return [pagination, groups];
}
