const defaults = {
  primary: '#12A6A6',
  info: '#FD8F00',
  inActive: '#12A6A6',
  success: '#00CB9D',
  error: '#FF6767',
  linkColor: '#5DB9F2',
  surfaceGrey: '#F5F5F5',
  surfaceWhite: '#ffffff',
  textOnSurface: '#000000',
  textOnPrimary: '#ffffff',
  authText: '#ffffff',
  neutral100: '#f3f4f6',
  neutral300: '#d0d4db',
  neutral500: '#6b7280',
};

/**
 * преобразовывает цвет
 * @param color
 * @param alpha
 * @returns {*}
 */
function hexToRgba(color, alpha) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
  const rgb = result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;

  if (!rgb) {
    return false;
  }

  return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${alpha})`;
}

/**
 * Вернет брендирование для вебки
 * @param {CompanyBranding} branding
 * @returns Object
 */
export default function brandingProvider(branding) {
  // eslint-disable-next-line no-param-reassign
  branding = branding || {};

  return {
    '--primary': branding.primaryColor || defaults.primary,
    '--primary80': hexToRgba(branding.primaryColor, '.8') || hexToRgba(defaults.primary, '.8'),
    '--primary60': hexToRgba(branding.primaryColor, '.6') || hexToRgba(defaults.primary, '.6'),
    '--primary40': hexToRgba(branding.primaryColor, '.4') || hexToRgba(defaults.primary, '.4'),
    '--primary30': hexToRgba(branding.primaryColor, '.3') || hexToRgba(defaults.primary, '.3'),
    '--primary15': hexToRgba(branding.primaryColor, '.15') || hexToRgba(defaults.primary, '.15'),
    '--primary10': hexToRgba(branding.primaryColor, '.1') || hexToRgba(defaults.primary, '.1'),
    '--info': branding.infoColor || defaults.info,
    '--info10': hexToRgba(branding.infoColor, '.1') || hexToRgba(defaults.infoColor, '.1'),
    '--info05': hexToRgba(branding.infoColor, '.05') || hexToRgba(defaults.infoColor, '.05'),
    '--inActive': hexToRgba(branding.primaryColor, '.15') || hexToRgba(defaults.primary, '.15'),
    '--success': branding.successColor || defaults.success,
    '--success15': hexToRgba(branding.successColor, '.15') || hexToRgba(defaults.success, '.15'),
    '--error': branding.errorColor || defaults.error,
    '--error20': hexToRgba(branding.errorColor, '.2') || hexToRgba(defaults.errorColor, '.2'),
    '--error15': hexToRgba(branding.errorColor, '.15') || hexToRgba(defaults.errorColor, '.15'),
    '--linkColor': branding.linkColor || defaults.linkColor,
    '--surfaceGrey': branding.surfaceGreyColor || defaults.surfaceGrey,
    '--surfaceWhite': branding.surfaceWhiteColor || defaults.surfaceWhite,

    '--textOnSurface': branding.textOnSurfaceColor || defaults.textOnSurface,
    '--surfaceHighEmphasis':
      hexToRgba(branding.textOnSurfaceColor, '.87') || hexToRgba(defaults.textOnSurface, '.87'),
    '--surfaceMediumEmphasis':
      hexToRgba(branding.textOnSurfaceColor, '.6') || hexToRgba(defaults.textOnSurface, '.6'),
    '--surfaceDisabledEmphasis':
      hexToRgba(branding.textOnSurfaceColor, '.4') || hexToRgba(defaults.textOnSurface, '.4'),
    '--surfaceEmphasis20':
      hexToRgba(branding.textOnSurfaceColor, '.2') || hexToRgba(defaults.textOnSurface, '.2'),
    '--surfaceEmphasis02':
      hexToRgba(branding.textOnSurfaceColor, '.02') || hexToRgba(defaults.textOnSurface, '.02'),

    '--textOnPrimary': branding.textOnPrimaryColor || defaults.textOnPrimary,
    '--primaryHighEmphasis':
      hexToRgba(branding.textOnPrimaryColor, '1') || hexToRgba(defaults.textOnPrimary, '1'),
    '--primaryMediumEmphasis':
      hexToRgba(branding.textOnPrimaryColor, '.6') || hexToRgba(defaults.textOnPrimary, '.6'),
    '--primaryDisabledEmphasis':
      hexToRgba(branding.textOnPrimaryColor, '.4') || hexToRgba(defaults.textOnPrimary, '.4'),
    '--primaryEmphasis20':
      hexToRgba(branding.textOnPrimaryColor, '.2') || hexToRgba(defaults.textOnPrimary, '.2'),
    '--authText': branding.authTextColor || defaults.authText,
    '--neutral100': defaults.neutral100,
    '--neutral300': defaults.neutral300,
    '--neutral500': defaults.neutral500,
  };
}
