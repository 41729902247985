export class AccessCode {
  constructor({ attempts, expiresIn }) {
    this.attempts = attempts;
    this.expiresIn = expiresIn;
  }
}

export function createAccessCode(data) {
  return Object.freeze(
    new AccessCode({
      attempts: data.attempts,
      expiresIn: data.expires_in,
    }),
  );
}
