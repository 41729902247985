export default class OffsetPagination {
  offset: number;
  limit: number;
  total: number;

  constructor(payload: any) {
    this.offset = parseInt(payload.offset, 10);
    this.limit = parseInt(payload.limit, 10);
    this.total = parseInt(payload.total_count, 10);
  }
}
