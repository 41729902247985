<template>
  <svg
    :class="classes"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.7005 9.70711L14.9352 12.3891L11.1699 16.0711C10.7706 16.4616 10.1231 16.4616 9.72371 16.0711L6.28365 12.7071C5.88429 12.3166 5.88429 11.6834 6.28365 11.2929C6.68301 10.9024 7.3305 10.9024 7.72986 11.2929L10.3625 13.8673C10.3903 13.8651 10.4184 13.864 10.4468 13.864C10.4752 13.864 10.5033 13.8651 10.5311 13.8673L16.2543 8.29289C16.6536 7.90237 17.3011 7.90237 17.7005 8.29289C18.0998 8.68342 18.0998 9.31658 17.7005 9.70711Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'check',

  props: {
    isSurfaceHighEmphasis: {
      type: Boolean,
      default: false,
    },

    isSurfaceEmphasis20: {
      type: Boolean,
      default: false,
    },

    isSurfaceWhite: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classes() {
      const classes = [];

      if (this.isSurfaceHighEmphasis) {
        classes.push('surface-high-emphasis');
      }

      if (this.isSurfaceEmphasis20) {
        classes.push('surface-emphasis-20');
      }

      if (this.isSurfaceWhite) {
        classes.push('surface-white');
      }

      return classes;
    },
  },
};
</script>

<style scoped>
svg path {
  fill: var(--success);
}

svg.surface-high-emphasis path {
  fill: var(--surfaceHighEmphasis);
}

svg.surface-emphasis-20 path {
  fill: var(--surfaceEmphasis20);
}

svg.surface-white path {
  fill: var(--surfaceWhite);
}
</style>
