import dateProvider from '@/providers/dateProvider';
import { each, find } from 'lodash';
import QuestionStatistic from '@/entities/modules/tests/QuestionStatistic';
import Question from '@/entities/common/testing/Question';

export default class Statistic {
  id: number;
  startDate?: Date;
  completeDate?: Date;
  completed: boolean;
  isChecking: boolean;
  currentTurn: number;
  timestamp: number;
  certificate: null;

  rawUserResults: any;
  userResults: QuestionStatistic[] = [];

  userScores: number;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.startDate = dateProvider(payload.start_time);
    this.completeDate = dateProvider(payload.complete_date);
    this.completed = parseInt(payload.completed, 10) === 1;
    this.isChecking = parseInt(payload.is_checking, 10) === 1;
    this.currentTurn = parseInt(payload.current_turn, 10);
    this.userScores = parseInt(payload.user_scores, 10);
    this.timestamp = parseInt(payload.timestamp, 10);
    this.certificate = payload.certificate;

    this.rawUserResults = payload.user_results;
  }

  setStatuses(questions: Question[]) {
    if (this.rawUserResults.length > 0) {
      this.userResults = [];
    }

    each(this.rawUserResults, userResult => {
      const question = find(questions, o => o.id === parseInt(userResult.id, 10));

      if (!question) {
        return;
      }

      this.userResults.push(new QuestionStatistic(userResult, question));
    });

    this.rawUserResults = '';
  }

  setUserResults(userResults: QuestionStatistic[]) {
    this.userResults = userResults;
  }
}
