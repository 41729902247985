import { ActionTree } from 'vuex';
// eslint-disable-next-line import/no-cycle
import { RatingState } from '@/store/modules/rating/types';
import { RootState } from '@/store/types';
// eslint-disable-next-line import/no-cycle
import ratingApi from '@/services/rating';

export const actions: ActionTree<RatingState, RootState> = {
  async getUsersList({ commit }, { ratingId, limit, offset }) {
    const payload = await ratingApi.getUsersList(ratingId, limit, offset);

    commit('setUsersList', payload.users);
    commit('setUsersMeta', payload.meta);
  },

  async getUser({ state, commit, dispatch }, { userId, isMyRating }) {
    if (!state.rating) {
      await dispatch('getRatingCategories');
    }

    if (state.rating) {
      const payload = await ratingApi.getUser(state.rating.id, userId, isMyRating);

      commit('setCurrentUser', payload.user);
      commit('setBadges', payload.badges);
      commit('setBadgesCategory', payload.categories);
      commit('setMaterialResultSections', payload.getMaterialResultSections());
      commit('setKpi', payload.kpi);
    }
  },

  async getRatingCategories({ commit }) {
    const payload = await ratingApi.getRatingCategories();

    commit('setRating', payload.pop());
  },

  async getTerms({ state, dispatch }) {
    if (!state.rating) {
      await dispatch('getRatingCategories');
    }

    if (state.rating) {
      return ratingApi.getTerms(state.rating.id);
    }
  },

  async getMyBadges({ commit }) {
    const payload = await ratingApi.getMyBadges();

    commit('setMyBadges', payload.badges);
    commit('setMyBadgesCategories', payload.categories);
  },

  async clearIsNew(o, id) {
    return ratingApi.clearIsNew(id);
  },
};
