import dateProvider from '@/providers/dateProvider';

export class TimeFrame {
  start?: Date;
  end?: Date;
  canRegisterTo?: Date;
  duration: number;

  isPast?: boolean;
  inProgress?: boolean;

  constructor(payload: any) {
    this.start = dateProvider(payload.start_date);
    this.end = dateProvider(payload.end_date);
    this.canRegisterTo = dateProvider(payload.register_end_date);
    this.duration = parseInt(payload.event_time, 10);

    const current = new Date();

    this.isPast = this.end && this.end < current;
    this.inProgress = this.start && this.start <= current && this.end && this.end > current;
  }
}
