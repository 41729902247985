export default class Category {
  id: number;
  name: string;
  order: number;
  count: number = 0;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.name = payload.name;
    this.order = parseInt(payload.order, 10);
  }
}
