import UserTasksValueFilter from '@/entities/modules/my-team/filters/UserTasksValueFilter';
import { SavedUserTaskFilter } from '@/types/my-team/filters';
import { sortBy, isEqual } from 'lodash';
import type {
  IUserFilter,
  IUserTaskValueFilter,
  UserFilterResponseItem,
} from '@/types/my-team/filters';

export default class UserTaskFilter
  implements IUserFilter<IUserTaskValueFilter, SavedUserTaskFilter>
{
  name: string;
  value: IUserTaskValueFilter;

  constructor(payload: UserFilterResponseItem<IUserTaskValueFilter>) {
    this.name = payload.name;
    this.value = new UserTasksValueFilter(payload.value);
  }

  isDefault(): boolean {
    return (
      !this.value.countDays && this.value.categories.length === 0 && this.value.users.length === 0
    );
  }

  isEqual(filter: IUserFilter<IUserTaskValueFilter, SavedUserTaskFilter>): boolean {
    return (
      this.value.countDays === filter.value.countDays &&
      isEqual(sortBy(this.value.categories), sortBy(filter.value.categories)) &&
      isEqual(sortBy(this.value.users), sortBy(filter.value.users))
    );
  }

  transformStateForSave(): SavedUserTaskFilter {
    const value: any = {};
    value.category_ids = [];
    value.user_ids = [];

    this.value.categories.forEach((category: any) => value.category_ids.push(category));
    this.value.users.forEach((user: any) => value.user_ids.push(user));
    value.count_days = this.value.countDays;

    return {
      name: this.name,
      value,
    };
  }

  clear() {
    this.value.countDays = 0;
    this.value.categories = [];
    this.value.users = [];
  }
}
