<template>
  <base-home-widget
    :widget-name="sectionTitle ? sectionTitle : $t('myresults_kpi_subsection_title')"
    :to="routeToKPI"
  >
    <template #content>
      <div class="q-row-cols-10">
        <div class="home-section__container">
          <router-link :to="routeToKPI">
            <div
              v-if="needShowBalanceKpiTitle"
              class="home-section__kpi-head"
            >
              {{ kpi.info }}
            </div>
            <div class="home-section__kpi-name">{{ kpiNames }}</div>
          </router-link>
        </div>
      </div>
    </template>
  </base-home-widget>
</template>

<script>
import { mapGetters } from 'vuex';
import each from 'lodash/each';
import isArray from 'lodash/isArray';
import KpiWidget from '@/entities/modules/dashboard/KpiWidget';
import BaseHomeWidget from '@/components/app/home/base-home-widget';
import CustomRoute from '@/entities/common/CustomRoute';

export default {
  name: 'kpi',

  components: {
    BaseHomeWidget,
  },

  props: {
    kpi: {
      type: KpiWidget,
      default: null,
    },
  },

  computed: {
    ...mapGetters('settings', ['needShowBalanceKpiTitle']),

    sectionTitle() {
      return this.kpi.name;
    },

    kpiNames() {
      if (this.kpi.kpiNames.length === 0) {
        return null;
      }

      const p = [];
      each(this.kpi.kpiNames, part => {
        if (isArray(part)) {
          p.push(...part);
        } else {
          p.push(part);
        }
      });

      return p.join(', ');
    },

    routeToKPI() {
      return {
        name: CustomRoute.TO_MY_RESULTS_KPI,
        query: {
          from: 'dashboard-widget',
        },
      };
    },
  },
};
</script>
