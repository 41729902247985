export default class User {
  id: number;
  name: string;
  points: number;
  avatar: string;
  place: number;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.name = payload.name;
    this.place = parseInt(payload.place, 10);
    this.points = parseInt(payload.points, 10);
    this.avatar = payload.avatar.url;
  }
}
