import { render, staticRenderFns } from "./avatar.vue?vue&type=template&id=14184f8e&scoped=true&"
var script = {}
import style0 from "./avatar.vue?vue&type=style&index=0&id=14184f8e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14184f8e",
  null
  
)

export default component.exports