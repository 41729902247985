import BaseWidget from '@/entities/modules/dashboard/BaseWidget';
import LearningItemsMaterial from '@/entities/modules/dashboard/LearningItemsMaterial';

type LearningItemsWidgetType = {
  id: number;
  type: string;
  name: string;
  order: number;
  data: {
    learning_items: [];
  };
};

export default class LearningItemsWidget extends BaseWidget {
  learningPrograms: any[] = [];

  constructor(payload: LearningItemsWidgetType) {
    super(payload);

    const materials = payload.data.learning_items || payload.data;

    materials.forEach(material => {
      this.learningPrograms.push(new LearningItemsMaterial(material));
    });
  }
}
