import { ActionTree } from 'vuex';
import tasksApi from '@/services/tasks';
import { resolver } from '@/providers/promiseReturnerProvider';
import { find, each } from 'lodash';
import Task from '@/entities/modules/tasks/Task';
import Answer from '@/entities/modules/tasks/Answer';
import { RootState } from '../../types';
import { TasksState } from './types';

// @ts-ignore
export const actions: ActionTree<TasksState, RootState> = {
  async getTasks(o, { category, page }) {
    return tasksApi.getTasks(category, page);
  },

  downloadFile({}, file) {
    return tasksApi.downloadFile(file);
  },

  /**
   * Удаление меток isNew с заданий
   * @param id
   */
  async clearIsNewTask({}, id): Promise<boolean> {
    return resolver(await tasksApi.clearIsNewTask(id));
  },

  /**
   * Вернет task по ID
   * @param id
   */
  async getTaskById({}, id): Promise<Task> {
    return resolver(await tasksApi.getTask(id));
  },

  /**
   *  Валидация ответа
   */
  async validateAnswer({}, { taskId, field }) {
    let data;

    const selected: number[] = [];

    if (field.type === 'multiple_choice') {
      each(field.options, option => {
        if (option.selected) {
          selected.push(option.id);
          data = {
            option_ids: selected,
            comment: field.comment,
          };
        }
      });
    } else {
      data = field.value;
    }
    await tasksApi.validateAnswer(taskId, field.id, data);
  },

  /**
   * Сохранит отзыв
   * @param state
   * @param dispatch
   * @param task
   */
  async saveAnswers({}, task: Task) {
    return tasksApi.saveAnswers(task);
  },

  async getAnswers({ commit }, statuses: Array<string>): Promise<boolean> {
    const payload = await tasksApi.getAnswers(statuses);

    commit('changeAnswers', payload.elements);

    return resolver(true);
  },

  async getAnswer(o, id): Promise<Answer> {
    return resolver(await tasksApi.getAnswer(id));
  },

  /**
   * Удаление меток "isNew" со статусов
   * @param state
   * @param id
   */
  async clearIsNewStatuses({ state }, id): Promise<boolean> {
    const answer = find(state.answers, task => task.id === id);

    if (!answer) {
      return false;
    }

    answer.setStatus(false);

    return resolver(await tasksApi.clearIsNewStatuses(id));
  },

  /**
   * Получение количества заданий в статусе "Требует доработки"
   */
  async getRefinementCount() {
    const response = await tasksApi.getRefinementCount();

    if (!response) {
      return null;
    }

    return response;
  },

  /**
   * Изменение ответа
   */
  async refinementAnswer(o, payload: any) {
    return tasksApi.refinementAnswer(payload.taskId, payload.answerId, payload.task);
  },
};
