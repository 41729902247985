import { ActionTree } from 'vuex';
// eslint-disable-next-line import/no-cycle
import dashboardApi from '@/services/dashboard';
import { sortBy } from 'lodash';
import { DashboardState } from './types';
import { RootState } from '../../types';

export const actions: ActionTree<DashboardState, RootState> = {
  async getDashboard({ commit }) {
    const dashboardDate = await dashboardApi.getDashboard();
    commit('changeWidgets', sortBy(dashboardDate.widgets, ['order']));
    commit('changeNextMaterial', dashboardDate.nextMaterial);
    commit('changeRating', dashboardDate.rating);
    commit('changeBanner', dashboardDate.banner);
  },
};
