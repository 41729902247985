import { render, staticRenderFns } from "./down-arrow.vue?vue&type=template&id=066cc82b&scoped=true&"
import script from "./down-arrow.vue?vue&type=script&lang=js&"
export * from "./down-arrow.vue?vue&type=script&lang=js&"
import style0 from "./down-arrow.vue?vue&type=style&index=0&id=066cc82b&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "066cc82b",
  null
  
)

export default component.exports