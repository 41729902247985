import AnswerField from '@/entities/modules/tasks/AnswerField';

export default class AnswerTextField extends AnswerField {
  value: string | Date;

  constructor(payload: any) {
    super(payload);

    this.value = payload.value;
  }
}
