import ImageSource, { createImageFromString } from '@/entities/common/sources/ImageSource';

export default class PostOwner {
  id: number;
  name: string = '';
  image?: ImageSource;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.name = payload.name;

    if (payload.avatar && payload.avatar.sm) {
      this.image = createImageFromString(payload.avatar.sm.url);
    }
  }
}
