<template>
  <base-home-widget
    :widget-name="widget.name ? widget.name : $t('common_learning_journeys_text')"
    :count-text="countText"
    :to="
      widget.type === 'entities_with_passing_date'
        ? { name: TO_DASHBOARD_PROFILE_MATERIALS_OF_DEADLINE }
        : {}
    "
  >
    <template #content>
      <app-slider
        :columns="ww4Columns"
        :column-size="ww4ColumnsForIsX"
      >
        <app-material-card
          v-for="(item, key) in widget.learningPrograms"
          :key="key"
          :event-label="[
            isTrack(item) ? 'dashboard_lj_trajectory_btn' : 'dashboard_lj_view_btn',
            'dashboard_with_the_data_of_passage_material_btn',
          ]"
          :widget="item"
          :is-stack="isTrack(item)"
          :has-outer-footer="true"
        >
          <template
            v-if="isTrack(item)"
            #badge
          >
            <div class="app-material-card__badge">
              <div class="app-material-card__badge-icon">
                <LearningTrackIcon />
              </div>
              <span class="app-material-card__badge-label">
                {{ $tc('common_trajectories_default_text') }}
              </span>
            </div>
          </template>
        </app-material-card>
      </app-slider>
    </template>
  </base-home-widget>
</template>
<script>
import { mapGetters } from 'vuex';
import AppSlider from '@/components/helpers/slider.vue';
import AppMaterialCard from '@/components/helpers/cards/material-card.vue';
import LearningsProgramsWidget from '@/entities/modules/dashboard/LearningsProgramsWidget';
import BaseHomeWidget from '@/components/app/home/base-home-widget.vue';
import CustomRoute from '@/entities/common/CustomRoute';
import LearningTrackIcon from '@/components/svg/icons/learning-track.vue';
import { LearningItemTypes } from '@/entities/modules/learning-items/LearningItemType';

export default {
  components: {
    LearningTrackIcon,
    AppSlider,
    AppMaterialCard,
    BaseHomeWidget,
  },

  props: {
    widget: {
      type: LearningsProgramsWidget,
      required: true,
    },

    // eslint-disable-next-line vue/require-default-prop
    sectionTitle: {
      type: String,
    },
  },

  data: () => ({
    TO_DASHBOARD_PROFILE_MATERIALS_OF_DEADLINE:
      CustomRoute.TO_DASHBOARD_PROFILE_MATERIALS_OF_DEADLINE,
  }),

  computed: {
    ...mapGetters('helpers', ['ww4Columns', 'ww4ColumnsForIsX']),

    countText() {
      return this.$tc('common_d_materials_text_caps', this.widget.learningPrograms.length, {
        0: this.widget.learningPrograms.length,
      });
    },
  },

  methods: {
    isTrack(item) {
      return item.type === LearningItemTypes.TRACK;
    },
  },
};
</script>
