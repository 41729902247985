import moment from 'moment';
import { i18n } from '@/lang';
// @ts-ignore
import dateProvider, { getCurrentTime, getCurrentTimestamp } from '@/providers/js/dateProvider';

const secondsInMinute = 60;

const secondsInHour = 3600;

const secondsInDay = 86400;

export function getRemainingTime(passingDeadline: number) {
  const deadlineTimestamp = dateProvider(passingDeadline).getTime();

  const currentTimestamp = new Date().getTime();

  const timeDifferenceInSeconds = Math.floor((deadlineTimestamp - currentTimestamp) / 1000);

  if (timeDifferenceInSeconds < secondsInMinute) {
    return i18n.tc('common_seconds_number_text', timeDifferenceInSeconds, {
      0: timeDifferenceInSeconds,
    });
  }

  if (timeDifferenceInSeconds < secondsInHour) {
    const remainingMinutes = Math.floor(
      (timeDifferenceInSeconds % secondsInHour) / secondsInMinute,
    );

    return i18n.tc('common_minutes_number_text', remainingMinutes, { 0: remainingMinutes });
  }

  if (timeDifferenceInSeconds < secondsInDay) {
    const remainingHours = Math.floor((timeDifferenceInSeconds % secondsInDay) / secondsInHour);

    return i18n.tc('common_d_hours_text', remainingHours, { 0: remainingHours });
  }

  const remainingDays = Math.floor(timeDifferenceInSeconds / secondsInDay);

  return i18n.tc('common_d_days_text', remainingDays, { 0: remainingDays });
}

export function getDatePassing(
  passingDeadline: number,
  status: string = '',
  endDate: number,
  type: string = '',
  dateFormat: string = 'D MMM',
): string {
  if (!passingDeadline) {
    return '';
  }

  const currentDate = getCurrentTime().getDate();

  const currentMonth = getCurrentTime().getMonth();

  const deadlineDate = dateProvider(passingDeadline).getDate();

  const deadlineMonth = dateProvider(passingDeadline).getMonth();

  const isProgramWithDeadline = type && endDate && passingDeadline;

  if (isProgramWithDeadline && (status === 'completed' || status === 'fail')) {
    const statusText =
      endDate > passingDeadline
        ? 'common_not_passed_in_the_deadline_text'
        : 'common_passed_in_the_deadline_text';

    return i18n.t(statusText).toString();
  }

  if (getCurrentTime().getFullYear() < dateProvider(passingDeadline).getFullYear()) {
    return moment(passingDeadline * 1000).format(dateFormat);
  }

  if (getCurrentTimestamp() > passingDeadline) {
    return moment(passingDeadline * 1000).format(dateFormat);
  }

  if (deadlineMonth > currentMonth || deadlineDate - currentDate > 7) {
    return moment(passingDeadline * 1000).format(dateFormat);
  }

  return getRemainingTime(passingDeadline);
}

export function getTooltipText(passingDeadline: number): string {
  const deadlineDay = moment(passingDeadline * 1000).format('D MMM');

  const deadlineTime = moment(passingDeadline * 1000).format('LT');

  // @ts-ignore
  return `${i18n.tc('common_pass_by_date_text', `${deadlineDay}, ${deadlineTime}`, {
    0: `${deadlineDay}, ${deadlineTime}`,
  })}`;
}

export function getDeadlineStatusTeam(
  diagram: any,
  passingDeadline: number,
  passingNoticeDeadline: number,
  hasNotPassedUsers: boolean,
) {
  const successfulUsersCount = diagram.numberOfSuccessful;

  const allUsersCount = diagram.amountOfMaterialsForChart;

  if (successfulUsersCount < allUsersCount) {
    if (
      passingDeadline - getCurrentTimestamp() < passingNoticeDeadline &&
      getCurrentTimestamp() < passingDeadline
    ) {
      return 'orange';
    }

    if (getCurrentTimestamp() > passingDeadline) {
      return 'error';
    }

    if (getCurrentTimestamp() < passingNoticeDeadline && getCurrentTimestamp() < passingDeadline) {
      return 'gray';
    }
  }

  if (successfulUsersCount === allUsersCount) {
    if (!hasNotPassedUsers) {
      return 'success';
    }

    if (hasNotPassedUsers) {
      return 'error';
    }
  }
}

export function getDeadlineStatusMaterial(
  passingDeadline: number,
  passingNoticeDeadline: number,
  endTime: number,
  isFailed: boolean,
  isPassed: boolean,
) {
  const dateDiff = passingDeadline - getCurrentTimestamp();

  if (isFailed || isPassed) {
    if ((endTime && endTime < passingDeadline) || dateDiff > 0) {
      return 'success';
    }

    return 'error';
  }

  if (dateDiff > 0 && dateDiff <= passingNoticeDeadline) {
    return 'orange';
  }

  if (getCurrentTimestamp() > passingDeadline) {
    return 'error';
  }

  return 'gray';
}
