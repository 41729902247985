export class UserStatus {
  code: string;
  lokaliseKey: string;
  myTeamLokaliseKey: string;

  constructor(code: string, lokaliseKey: string, myTeamLokaliseKey: string) {
    this.code = code;
    this.lokaliseKey = lokaliseKey;
    this.myTeamLokaliseKey = myTeamLokaliseKey;
  }
}

export function createUserStatus(status: number): UserStatus {
  switch (status) {
    // заявка не подана
    case 0:
      return new UserStatus('not-filed', '', 'myteam_events_no_application_text');

    // заявка на рассмотрении
    case 1:
      return new UserStatus(
        'filed',
        'events_status_under_consideration_text',
        'events_status_under_consideration_text',
      );

    // я иду (одобрили, или, если одобрять не нужно - он записался)
    case 2:
      return new UserStatus(
        'approved',
        'events_status_i_am_going_text',
        'myteam_events_approved_text',
      );

    // ожидание подтверждения участия
    case 3:
      return new UserStatus(
        'waiting-for-confirmation',
        'events_status_waiting_for_attendance_confirmation_text',
        'myteam_event_status_waiting_for_attendance_confirmation_text',
      );

    // участвовал
    case 4:
      return new UserStatus(
        'participated',
        'events_status_complete_text',
        'myresults_events_participated_text',
      );

    // заявка отклонена
    case 5:
      return new UserStatus(
        'rejected',
        'events_status_declined_text',
        'events_status_declined_text',
      );

    // пропустил мероприятие (хотя записался)
    case 6:
      return new UserStatus('missed', 'events_status_missed_text', 'myteam_events_missed_text');

    default:
      break;
  }

  return new UserStatus('unknown', '', '');
}
