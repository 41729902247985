import { i18n } from '@/lang';
import CorrectName from '@/entities/common/CorrectName';

export default class ResultsItem {
  type: string;
  totalAmount: number;
  studiedPercent: number;
  moduleName: string;
  moduleId: number;
  order: number = 0;
  fail: number = 0;
  notStarted: number = 0;
  success: number = 0;
  notParticipated: number = 0;
  participated: number = 0;
  request: number = 0;
  requestSuccess: number = 0;
  isBlocked: boolean = false;

  constructor(payload: any) {
    this.totalAmount = 0;

    for (const key in payload.amount) {
      if (Object.prototype.hasOwnProperty.call(payload.amount, key)) {
        this.totalAmount += payload.amount[key];
      }
    }

    this.type = payload.type;
    this.moduleName = payload.module_name;
    this.moduleId = parseInt(payload.module_id, 10);

    if (this.type === CorrectName.EVENT) {
      this.requestSuccess = parseInt(payload.amount.success, 10);
      this.notParticipated = parseInt(payload.amount.not_participated, 10);
      this.participated = parseInt(payload.amount.participated, 10);
      this.request = parseInt(payload.amount.request, 10);
    } else {
      this.success = parseInt(payload.amount.success, 10);
      this.fail = parseInt(payload.amount.fail, 10);
      this.notStarted = parseInt(payload.amount.not_started, 10);
    }

    this.studiedPercent = Math.floor((this.success * 100) / this.totalAmount) || 0;
  }

  setIsBlocked(isBlocked: boolean) {
    this.isBlocked = isBlocked;
  }

  getName() {
    switch (this.type) {
      case 'events': {
        return this.moduleName ? this.moduleName : i18n.t('events_section_title');
      }

      case 'tests': {
        return i18n.t('tests_test_tab');
      }

      case 'interviews': {
        return i18n.t('tests_interview_tab');
      }

      case 'learning_program': {
        return this.moduleName ? this.moduleName : i18n.t('common_learning_journeys_text');
      }

      case 'learning_programs': {
        return this.moduleName ? this.moduleName : i18n.t('common_learning_journeys_text');
      }

      case 'learning_tracks': {
        return this.moduleName ? this.moduleName : i18n.t('common_trajectories_s_default_text');
      }

      case 'learning_items':
        return this.moduleName ? this.moduleName : i18n.t('common_lm_default_section_title');

      case 'learning_materials': {
        return this.moduleName ? this.moduleName : i18n.t('common_lm_default_section_title');
      }

      case 'all': {
        return i18n.t('common_total_title_text');
      }

      default:
        break;
    }

    return '';
  }
}
