export class Shop {
  constructor({ storeSupportEmail, canMarkOrderNotReceived }) {
    this.storeSupportEmail = storeSupportEmail;
    this.canMarkOrderNotReceived = canMarkOrderNotReceived;
  }
}

export function createShopSettings(data) {
  return Object.freeze(
    new Shop({
      storeSupportEmail: data.store_support_email,
      canMarkOrderNotReceived: parseInt(data.user_can_mark_order_not_received, 10) === 1,
    }),
  );
}
