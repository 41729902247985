<template>
  <div class="app-home-widget">
    <transition
      enter-active-class="animated fadeInSlow"
      leave-active-class="animated fadeOutSlow"
    >
      <div
        v-if="showNextMaterial"
        class="app-home-widget-info"
      >
        <span
          class="app-home-widget-info__exit"
          @click="nextMaterialStateChange"
        >
          <CloseSvg />
        </span>
        <div
          v-if="material.image"
          class="app-home-widget-info__img"
        >
          <img
            :src="material.image.thumb"
            alt=""
          />
        </div>
        <div
          class="app-home-widget-info__content"
          :class="{ 'app-home-widget-info__content--full': !material.image }"
        >
          <div class="app-home-widget-info__name">{{ material.materialName }}</div>
          <a :href="linkToMaterial">{{ material.name }}</a>
        </div>
      </div>
    </transition>
    <div
      class="app-home-widget-play"
      @click="nextMaterialStateChange"
    >
      <div
        :class="{ 'app-home-widget-play__overlay--hide': showNextMaterial }"
        class="app-home-widget-play__overlay"
      ></div>

      <NextMaterialSvg />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CloseSvg from '@/components/svg/icons/close-16px.vue';
import NextMaterialSvg from '@/components/svg/icons/next-material.vue';
import NextMaterialWidget from '@/entities/modules/dashboard/NextMaterialWidget';

export default {
  name: 'home-next-material',

  components: {
    CloseSvg,
    NextMaterialSvg,
  },

  props: {
    material: {
      type: NextMaterialWidget,
      required: true,
    },
  },

  computed: {
    ...mapGetters('helpers', ['ww4ColumnsForIsX']),

    ...mapGetters('settings', ['showNextMaterial']),

    ...mapGetters('intercom', ['showIntercomWindow']),

    linkToMaterial() {
      return `${this.material.deeplink}?from=continue-learning`;
    },
  },

  watch: {
    showNextMaterial() {
      if (!this.showNextMaterial) {
        this.changeNewMessagesPositionToDown();
      } else {
        this.changeNewMessagesPositionToUp();
      }
    },
  },

  created() {
    if (!this.showIntercomWindow) {
      this.$store.commit('settings/setShowNextMaterial', true);
      this.changeNewMessagesPositionToUp();
    } else {
      this.$store.commit('settings/setShowNextMaterial', false);
    }
  },

  beforeDestroy() {
    this.changeNewMessagesPositionToDown();
  },

  methods: {
    nextMaterialStateChange() {
      if (!this.showNextMaterial && this.showIntercomWindow) {
        this.$store.commit('intercom/setShowIntercomWindow', false);
      }
      this.$store.commit('settings/setShowNextMaterial', !this.showNextMaterial);
    },

    changeNewMessagesPositionToUp() {
      const intercomElement = document.querySelector('.intercom-app');

      if (intercomElement) {
        intercomElement.setAttribute('class', 'intercom-app intercom-app--up');
      }
    },

    changeNewMessagesPositionToDown() {
      const intercomElement = document.querySelector('.intercom-app');

      if (intercomElement) {
        intercomElement.setAttribute('class', 'intercom-app');
      }
    },
  },
};
</script>
