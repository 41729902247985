import { GetterTree } from 'vuex';
// eslint-disable-next-line import/no-cycle
import { MyTeamState } from '@/store/modules/my-team/types';
import { RootState } from '@/store/types';

export const getters: GetterTree<MyTeamState, RootState> = {
  countEmployeeForFilter: state => state.countEmployeeForFilter,

  countAppliedSubfilters: state => state.countAppliedSubfilters,

  employeeTeamFilters: state => state.employeeTeamFilters,

  userFilters: state => state.userFilters,

  getModules: state => state.modules,

  checkEnabledMyTeamModuleByType: state => (moduleType: string) =>
    !!state.modules.find(module => module.type === moduleType),
};
