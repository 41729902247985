<template>
  <div class="app-view">
    <ViewSvg :is-primary="isViewed" />
    <span
      class="app-view__views"
      :class="{ 'app-view__views--gray': isGrayLabel }"
    >
      {{ viewsCount.toLocaleString() }}
    </span>
  </div>
</template>

<script>
import ViewSvg from '@/components/svg/icons/view';

export default {
  components: {
    ViewSvg,
  },

  props: {
    viewsCount: {
      type: Number,
      default: 0,
    },

    isViewed: {
      type: Boolean,
      default: false,
    },

    isGrayLabel: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
