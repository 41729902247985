import each from 'lodash/each';

export class EmployeeAwardCategory {
  constructor({ id, name, order, count }) {
    this.id = id;
    this.name = name;
    this.order = order;
    this.count = count;
  }
}

/**
 * вернет массив категорий наград
 * @param {Array} массив категорий наград из ответа сервера
 */
export function createEmployeeAwardCategory(data) {
  const categories = [];

  each(data, category => {
    categories.push(
      new EmployeeAwardCategory({
        id: parseInt(category.id, 10),
        name: category.name,
        order: parseInt(category.order, 10),
        count: 0,
      }),
    );
  });

  return categories;
}
