<template>
  <div
    id="award-categories"
    class="award-categories"
  >
    <div class="award-categories__content">
      <a
        v-for="category in categories"
        :key="category.id"
        class="award-categories__item"
        @click="chooseCategory(category.id)"
      >
        {{ getFullName(category) }}
      </a>
    </div>
    <div
      v-if="isShowMoreTextButton"
      id="show-all"
      class="award-categories__show-all"
      @click="chooseCategory(null)"
    >
      {{ $t('common_more_text') }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { sendEvent } from '@/providers/analyticsProvider';
import CustomRoute from '@/entities/common/CustomRoute';

export default {
  name: 'award-categories',

  props: {
    categories: {
      type: Array,
      default: null,
    },

    fromMyTeam: {
      type: Boolean,
      default: false,
    },

    fromHome: {
      type: Boolean,
      default: false,
    },

    fromMyBadges: {
      type: Boolean,
      default: false,
    },

    isItDeepManager: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters('helpers', ['ww', 'isDesktop']),

    isShowMoreTextButton() {
      return this.isDesktop && this.categories.length > 1;
    },

    id() {
      return parseInt(this.$route.params.id, 10);
    },

    managerId() {
      return parseInt(this.$route.params.managerId, 10);
    },

    ratingId() {
      return parseInt(this.$route.params.ratingId, 10);
    },

    userId() {
      return parseInt(this.$route.params.userId, 10);
    },
  },

  watch: {
    ww() {
      this.showAndHideButton();
    },
  },

  mounted() {
    this.showAndHideButton();
  },

  methods: {
    /**
     * Выбор категории
     * @param id
     */
    chooseCategory(category) {
      if (this.fromHome) {
        sendEvent(this.$analytics, 'dashboard_badge_category_btn');
        this.$router.push({
          name: CustomRoute.TO_RATING_MY_RATING_BADGES,
          params: {
            fromHome: true,
            categoryId: category,
          },
        });
      } else if (this.fromMyTeam) {
        this.$router.push({
          name: CustomRoute.TO_MY_TEAM_EMPLOYEE_BADGES,
          params: {
            fromMyTeam: true,
            id: this.id,
            managerId: this.managerId,
            categoryId: category,
            isItDeepManager: this.isItDeepManager,
          },
        });
      } else if (this.fromMyBadges) {
        this.$router.push({
          name: CustomRoute.TO_RATING_MY_RATING_BADGES,
          params: { categoryId: category },
        });
      } else {
        this.$router.push({
          name: CustomRoute.TO_RATING_USER_BADGES,
          params: {
            ratingId: this.ratingId,
            userId: this.userId,
            categoryId: category,
          },
        });
      }
    },

    getFullName(category) {
      return `${category.name}, ${this.$tc('common_point_number_text', category.count, {
        0: category.count,
      })}`;
    },

    showAndHideButton() {
      if (this.isShowMoreTextButton) {
        this.$nextTick(() => {
          const categoriesBlock = document.getElementById('award-categories');

          const showAll = document.getElementById('show-all');

          if (categoriesBlock.scrollHeight > categoriesBlock.offsetHeight) {
            showAll.style.display = 'inline-block';
          }

          const delta = 5;

          if (categoriesBlock.scrollHeight < categoriesBlock.offsetHeight + delta) {
            showAll.style.display = 'none';
          }
        });
      }
    },
  },
};
</script>
