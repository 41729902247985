<template>
  <div class="card-loading-wrapper">
    <div
      v-for="material in materialCardCount"
      :key="material"
      class="app-sl-card app-sl__wrapper"
    >
      <div class="app-sl-card__content">
        <div>
          <div class="app-sl__wrapper">
            <div class="app-sl-wrap">
              <div class="app-sl__line app-sl__line--small app-sl-w1"></div>
            </div>
            <div class="app-sl-wrap">
              <div class="app-sl__line app-sl__line--small app-sl__line--50 app-sl-w1"></div>
            </div>
          </div>
        </div>

        <MaterialCardInfoLoading />
      </div>
    </div>
  </div>
</template>

<script>
import MaterialCardInfoLoading from '@/components/helpers/pre-loading-page/material-card-info';

export default {
  name: 'card-loading',

  components: {
    MaterialCardInfoLoading,
  },

  props: {
    materialCardCount: {
      type: Number,
      default: 1,
    },
  },
};
</script>
