import IAnswerOption from '@/entities/common/testing/answer-oprions/IAnswerOption';

export default class UserAnswer implements IAnswerOption {
  answerId: number;
  isChecked: boolean;
  isCorrect: boolean;
  option: string;

  isFailed: boolean;
  isSuccess: boolean;

  constructor(payload: any) {
    this.answerId = parseInt(payload.id, 10);
    this.isChecked = parseInt(payload.checked, 10) === 1;
    this.isCorrect = parseInt(payload.correct, 10) === 1;
    this.option = payload.answer;

    this.isFailed = this.isChecked && !this.isCorrect;
    this.isSuccess = this.isCorrect;
  }
}
