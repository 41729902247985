export class Events {
  constructor({ isZoomAvailable, allowPdfSharing }) {
    this.isZoomAvailable = isZoomAvailable;
    this.allowPdfSharing = allowPdfSharing;
  }
}

export function createEventsSettings(data) {
  return Object.freeze(
    new Events({
      isZoomAvailable: parseInt(data.is_zoom_available, 10) === 1,
      allowPdfSharing: parseInt(data.allow_pdf_sharing, 10) === 1,
    }),
  );
}
