<template>
  <div
    class="app-discussion"
    :class="{ 'app-discussion--commented': material.isCommented }"
    @click.stop="$emit('toggleComment')"
  >
    <CommentBigSvg v-if="isBig" />
    <CommentSmallSvg v-else />
    <div
      class="app-discussion__comments"
      :class="{ 'app-discussion__comments--grey': notNewComments === 0 && !isBig }"
    >
      {{ label }}
      <span v-if="material.newComments > 0">+ {{ material.newComments.toLocaleString() }}</span>
    </div>
  </div>
</template>

<script>
import CommentBigSvg from '@/components/svg/icons/comment-32px';
import CommentSmallSvg from '@/components/svg/icons/comment-24px-gray';

export default {
  components: {
    CommentBigSvg,
    CommentSmallSvg,
  },

  props: {
    material: {
      /** @type ICommentable */
      type: Object,
      required: true,
    },

    isBig: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    label() {
      if (this.notNewComments > 0) {
        return this.notNewComments.toLocaleString();
      }

      return this.material.newComments === 0 ? this.$t('newsfeed_comments_discuss') : '';
    },

    notNewComments() {
      return this.material.comments - this.material.newComments;
    },
  },
};
</script>
