export class EventType {
  code: string;
  lokaliseKey: string;

  constructor(code: string, lokaliseKey: string) {
    this.code = code;
    this.lokaliseKey = lokaliseKey;
  }
}

export function createEventType(type: number): EventType {
  switch (type) {
    case 1:
      return new EventType('offline', 'events_tag_offline_text');
    case 2:
      return new EventType('online', 'events_tag_online_text');
    case 3:
      return new EventType('zoom', 'events_zoom_text');
    default:
      return new EventType('unknown', '');
  }
}
