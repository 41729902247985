<script>
import each from 'lodash/each';
import { mapGetters } from 'vuex';

export default {
  name: 'app-slider',

  props: {
    columns: {
      type: Number,
      default: 3,
    },

    columnSize: {
      type: String,
      default: 'is-one-quarter',
    },
  },

  data: () => ({
    sliderIndex: 0,
    state: {
      observer: null,
      isIntersected: false,
    },
    // lastSlide: null,
    slotsLength: 0,
  }),

  computed: {
    ...mapGetters('helpers', ['ww', 'isDesktop']),

    /**
     * Все слайды (слоты)
     */
    slots: {
      cache: false,
      get() {
        if (this.$slots.default) {
          return this.$slots.default;
        }

        return [];
      },
    },

    lastSlide: {
      cache: false,
      get() {
        return this.slots[this.slots.length - 1].elm;
      },
    },

    /**
     * На сколько влево можно сдвинуть слайдер
     */
    toLeft() {
      const toIndex = this.sliderIndex;

      if (toIndex > this.columns) {
        return this.columns;
      }

      return toIndex;
    },

    /**
     * На сколько вправо можно сдвинуть слайдер
     */
    toRight() {
      const fromIndex = this.slots.length - this.sliderIndex - this.columns;

      if (fromIndex > this.columns) {
        return this.columns;
      }

      return fromIndex;
    },

    /**
     * Вернет true, если можно слайдить слайдер влево
     * @returns {Boolean}
     */
    canSlideLeft() {
      return this.toLeft > 0;
    },

    /**
     * Вернет true, если можно слайдить слайдер вправо
     * @returns {Boolean}
     */
    canSlideRight() {
      return this.toRight > 0;
    },

    /**
     * Смещение
     */
    translateOffset() {
      return -1 * this.sliderIndex * (100 / this.columns);
    },
  },

  watch: {
    translateOffset() {
      this.recalculateOffset();
    },

    ww() {
      this.recalculateOffset();
    },

    columns() {
      this.recalculateOffset();
    },

    sliderIndex(count) {
      if (this.slots.length - (count + this.columns) <= this.columns) {
        this.$emit('countToRight');
      }
    },

    // eslint-disable-next-line func-names
    'state.isIntersected': function (value) {
      if (value) {
        this.$emit('intersected', this.lastSlide);
      }
    },

    isDesktop(value) {
      if (value) {
        this.unobserve();
      } else {
        this.state.isIntersected = false;
        this.observe();
      }
    },
  },

  mounted() {
    this.$refs.sliderRef.style.left = '0px';
    this.slotsLength = this.slots.length;

    if ('IntersectionObserver' in window && !this.isDesktop) {
      if (!this.state.isIntersected) {
        this.observe();
      }
    } else {
      this.state.isIntersected = true;
    }
  },

  updated() {
    if (this.slots.length > this.slotsLength && !this.isDesktop) {
      this.slotsLength = this.slots.length;
      this.state.isIntersected = false;
      this.observe();
    }
  },

  beforeDestroy() {
    if (!this.state.isIntersected) {
      this.unobserve();
    }
  },

  /**
   * Render-функция, отвечающая за отображение слайдера
   * ( https://ru.vuejs.org/v2/guide/render-function.html )
   */
  render(createElement) {
    // формируем шапку слайдера
    const header = [];
    // если слайдов больше, чем количество колонок, выводим стрелочки влево/вправо

    if (this.slots.length > this.columns) {
      header.push(
        createElement('div', { class: ['app-slider__header'] }, [
          createElement('div', { class: ['app-slider__navigation', 'app-slider-navigation'] }, [
            createElement(
              'div',
              { class: ['app-slider-navigation__item app-slider-navigation__item--left'] },
              [
                createElement(
                  'a',
                  {
                    class: { active: this.canSlideLeft },
                    on: { click: this.moveSliderLeft },
                  },
                  [
                    createElement(
                      'svg',
                      {
                        attrs: {
                          width: 10,
                          height: 14,
                          viewBox: '0 0 10 14',
                          fill: 'none',
                          xmlns: 'http://www.w3.org/2000/svg',
                        },
                      },
                      [
                        createElement(
                          'path',
                          {
                            attrs: {
                              fillRule: 'evenodd',
                              clipRule: 'evenodd',
                              d: 'M7.32854 13.3995C7.71906 13.79 8.35223 13.79 8.74275 13.3995C9.13328 13.009 9.13328 12.3758 8.74275 11.9853L3.793 7.03552L8.74275 2.08577C9.13328 1.69524 9.13328 1.06208 8.74275 0.671555C8.35223 0.281031 7.71906 0.281031 7.32854 0.671555L0.964576 7.03552L7.32854 13.3995Z',
                            },
                          },
                          [],
                        ),
                      ],
                    ),
                  ],
                ),
              ],
            ),
            createElement(
              'div',
              { class: ['app-slider-navigation__item app-slider-navigation__item--right'] },
              [
                createElement(
                  'a',
                  {
                    class: { active: this.canSlideRight },
                    on: { click: this.moveSliderRight },
                  },
                  [
                    createElement(
                      'svg',
                      {
                        attrs: {
                          width: 10,
                          height: 14,
                          viewBox: '0 0 10 14',
                          fill: 'none',
                          xmlns: 'http://www.w3.org/2000/svg',
                        },
                      },
                      [
                        createElement(
                          'path',
                          {
                            attrs: {
                              fillRule: 'evenodd',
                              clipRule: 'evenodd',
                              d: 'M2.67146 0.600523C2.28094 0.209999 1.64777 0.209999 1.25725 0.600523C0.866724 0.991047 0.866724 1.62421 1.25725 2.01474L6.207 6.96448L1.25725 11.9142C0.866724 12.3048 0.866724 12.9379 1.25725 13.3284C1.64777 13.719 2.28094 13.719 2.67146 13.3284L9.03542 6.96448L2.67146 0.600523Z',
                            },
                          },
                          [],
                        ),
                      ],
                    ),
                  ],
                ),
              ],
            ),
          ]),
        ]),
      );
    }

    // формируем сам слайдер
    const content = [];
    // сначала каждый слот поместим в column

    const slots = [];
    each(this.slots, (slot, index) => {
      slots.push(
        createElement(
          'div',
          {
            class: ['column', this.columnSize],
            key: index,
          },
          [slot],
        ),
      );
    });

    // а теперь все column поместим в columns
    content.push(
      createElement('div', { class: ['app-slider__content'] }, [
        createElement(
          'div',
          {
            class: ['columns'],
            ref: 'sliderRef',
          },
          slots,
        ),
      ]),
    );

    return createElement('div', { class: ['app-slider'] }, [header, content]);
  },

  methods: {
    observe() {
      this.state.observer = new IntersectionObserver(this.onIntersection);
      this.state.observer.observe(this.lastSlide);
    },

    onIntersection(entries) {
      this.state.isIntersected = entries.some(entry => entry.intersectionRatio > 0);

      if (this.state.isIntersected) {
        this.unobserve();
      }
    },

    unobserve() {
      if ('IntersectionObserver' in window) {
        this.state.observer.unobserve(this.lastSlide);
      }
    },

    /**
     * Сдвинет слайдер влево
     */
    moveSliderLeft() {
      if (this.canSlideLeft) {
        this.sliderIndex -= this.toLeft;
      }
    },

    /**
     * Сдвинет слайдер вправо
     */
    moveSliderRight() {
      if (this.canSlideRight) {
        this.sliderIndex += this.toRight;
      }
    },

    /**
     * Хитрый расчет отступа
     */
    recalculateOffset() {
      if (this.$refs.sliderRef) {
        const exp = this.ww >= 1366 ? 8 : 0;

        const paddingOffset = (this.sliderIndex / this.columns) * exp;
        this.$refs.sliderRef.style.left = `calc(${this.translateOffset}% - ${paddingOffset}px)`;
      }
    },
  },
};
</script>
