// eslint-disable-next-line no-shadow
enum AnswerStatus {
  CHECKING = 'checking',
  COMPLETED = 'completed',
  FAILED = 'failed',
  REFINEMENT = 'refinement',
}

// @ts-ignore
export default AnswerStatus;
