export default class FrameSettings {
  isShowLogo: boolean;
  isShowSearch: boolean;
  isShowSidebar: boolean;
  isShowUserInfo: boolean;
  isShowSectionName: boolean;
  showPreviousPageButton: boolean;
  isShowPersonalData: boolean;
  redirectToFrameUrl: string;
  isShowTabs: boolean;
  isShowFilterMaterialsTags: boolean;
  isShowFilterMaterialsParams: boolean;
  isShowSectionConditions: boolean;
  isShowShopOrderElements: boolean;
  isShowBreadcrumbs: boolean;
  isShowInFrame: boolean;
  enablePassThroughAuthorization: boolean;
  authFrameTypeId: number;
  isShowSortItems: boolean;

  constructor(payload: any) {
    this.isShowLogo = parseInt(payload.logo, 10) === 1;
    this.isShowSearch = parseInt(payload.search, 10) === 1;
    this.isShowSortItems = parseInt(payload.sort_items, 10) === 1;
    this.isShowSidebar = parseInt(payload.sidebar, 10) === 1;
    this.isShowUserInfo = parseInt(payload.block_user_avatar, 10) === 1;
    this.isShowSectionName = parseInt(payload.section_name, 10) === 1;
    this.showPreviousPageButton = parseInt(payload.previous_page_button, 10) === 1;
    this.isShowPersonalData = parseInt(payload.personal_data, 10) === 1;
    this.isShowTabs = parseInt(payload.subsections_navigation, 10) === 1;
    this.isShowFilterMaterialsTags = parseInt(payload.filter_materials_tags, 10) === 1;
    this.isShowFilterMaterialsParams = parseInt(payload.filter_materials_params, 10) === 1;
    this.isShowSectionConditions = parseInt(payload.section_conditions, 10) === 1;
    this.isShowShopOrderElements = parseInt(payload.shop_order_elem, 10) === 1;
    this.isShowBreadcrumbs = parseInt(payload.fast_document_navigation, 10) === 1;
    this.isShowInFrame = parseInt(payload.display_check, 10) === 1;
    this.enablePassThroughAuthorization = parseInt(payload.continuous_auth_frame, 10) === 1;
    this.authFrameTypeId = parseInt(payload.auth_frame_type_id, 10);
    this.redirectToFrameUrl = payload.redirect_url_not_frame;
  }
}
