import axios from 'axios';
import OffsetPagination from '@/entities/common/OffsetPagination';
import UserComment from '@/entities/modules/messages/UserComment';
import each from 'lodash/each';

export default {
  async getComments(
    type: string,
    id: number,
    commentId: number | null,
    offset: number | null,
    limit: number | null,
  ): Promise<[UserComment[], OffsetPagination]> {
    const linkRequest = commentId
      ? `/${type}/${id}/comments?comment_id=${commentId}${limit !== null ? `&limit=${limit}` : ''}${
          offset !== null ? `&offset=${offset}` : ''
        }`
      : `/${type}/${id}/comments?${limit !== null ? `&limit=${limit}` : ''}${
          offset !== null ? `&offset=${offset}` : ''
        }`;

    const response = await axios.get(linkRequest);

    const offsetPagination = new OffsetPagination(response.data.success.meta.pagination);

    const comments: UserComment[] = [];
    each(response.data.success.comments, comment => comments.push(new UserComment(comment)));

    return [comments, offsetPagination];
  },

  async sendComment(type: string, id: number, comment: string, parentId: number) {
    await axios.post(
      `/${type}/${id}/add-comment`,
      {
        parent_id: parentId,
        text: comment,
      },
      { headers: { globalErrorHandler: false } },
    );
  },

  async clearCommentsIsNew(type: string, id: number, ids: number[]) {
    await axios.post(`/${type}/${id}/comments/clear-is-new`, { comments: ids });
  },
};
