export default class VideoUpdate {
  id: number;
  updatedAt: number;
  isViewed: boolean;
  isNew: boolean;
  isLiked: boolean;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.updatedAt = parseInt(payload.updated_at, 10);
    this.isViewed = parseInt(payload.is_viewed, 10) === 1;
    this.isNew = parseInt(payload.is_new, 10) === 1;
    this.isLiked = parseInt(payload.is_liked, 10) === 1;
  }
}
