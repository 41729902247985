export class Kpi {
  constructor({ allActionsButtonTitle, balanceTitle, hideBalanceTitle, showActionsDates }) {
    this.allActionsButtonTitle = allActionsButtonTitle;
    this.balanceTitle = balanceTitle;
    this.hideBalanceTitle = hideBalanceTitle;
    this.showActionsDates = showActionsDates;
  }
}

export function createKpiSettings(data) {
  return Object.freeze(
    new Kpi({
      allActionsButtonTitle: data.all_actions_button_title,
      balanceTitle: data.balance_title,

      hideBalanceTitle: parseInt(data.hide_balance_title, 10) === 1,
      showActionsDates: parseInt(data.show_actions_dates, 10) === 1,
    }),
  );
}
