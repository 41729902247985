import {
  LearningItemStatus,
  LearningItemStatuses,
} from '@/entities/modules/learning-items/LearningItemStatus';
import NodeItem, { NodeItemScheme } from './NodeItem';

export interface CurrentNodeItemScheme extends NodeItemScheme {
  stat: { status: LearningItemStatus };
}

export default class CurrentNodeItem extends NodeItem {
  status: LearningItemStatus;

  isCompleted: boolean;

  constructor(payload: CurrentNodeItemScheme) {
    super(payload);

    this.status = payload.stat.status;
    this.isCompleted = [LearningItemStatuses.COMPLETED, LearningItemStatuses.FAILED].includes(
      this.status,
    );
  }
}
