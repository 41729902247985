// eslint-disable-next-line no-shadow
enum MaterialStatus {
  ASSIGNED = 'assigned',
  IN_PROGRESS = 'in_progress',
  PASSED = 'passed',
  FAILED = 'failed',
  CHECKING = 'checking',
  ANY = 'any',
  NEW = 'new',
  CHECKED = 'checked',
}

// @ts-ignore
export default MaterialStatus;
