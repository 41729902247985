<template>
  <div
    class="app-loading"
    :class="{
      'app-loading__big': bigSize,
      'app-loading__medium': mediumSize,
    }"
  ></div>
</template>

<script>
export default {
  props: {
    bigSize: {
      type: Boolean,
      default: false,
    },

    mediumSize: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
