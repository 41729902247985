export default class UserVerified {
  email: boolean;
  phone: boolean;
  firstName: boolean;
  lastName: boolean;
  form: boolean;
  groups: boolean;
  groupsIds: number[] = [];
  password: boolean;

  constructor(payload: any) {
    this.email = !payload.user_fields || !payload.user_fields.includes('email');
    this.phone = !payload.user_fields || !payload.user_fields.includes('phone');
    this.firstName = !payload.user_fields || !payload.user_fields.includes('first_name');
    this.lastName = !payload.user_fields || !payload.user_fields.includes('last_name');
    this.form = !payload.user_fields || !payload.user_fields.includes('form');
    this.groups = !payload.groups || payload.groups.length === 0;

    if (!this.groups) {
      this.groupsIds = payload.groups;
    }
    this.password = !payload.user_fields || !payload.user_fields.includes('password');
  }
}

// TODO: сделать это методом после переписывания auth.js на ts
export function getCountFieldsForVerification(userVerified: any) {
  let countFields = 0;

  if (!(userVerified.firstName && userVerified.lastName && userVerified.groups)) {
    countFields += 1;
  }

  if (userVerified.email === false) {
    countFields += 1;
  }

  if (userVerified.phone === false) {
    countFields += 1;
  }

  if (userVerified.form === false) {
    countFields += 1;
  }

  if (userVerified.password === false) {
    countFields += 1;
  }

  return countFields;
}
