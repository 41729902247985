import MaterialStatus from '@/entities/common/testing/MaterialStatus';
// @ts-ignore
import dateProvider from '@/providers/js/dateProvider';
import IStatistic from '@/entities/modules/learning-programs/IStatistic';
import { has } from 'lodash';
// @ts-ignore
import { decrypt } from '@/providers/js/cryptProvider';

export default class MaterialScormStatistic implements IStatistic {
  materialId: number;
  updatedAt?: Date;
  data?: string;
  percent: number;
  status: string;
  points: number;
  updatedAtTimestamp: number;

  constructor(payload: any) {
    this.materialId = parseInt(payload.id, 10);
    this.percent = payload.percent ? JSON.parse(decrypt(payload.percent)) : 0;
    this.status = payload.status ? decrypt(payload.status) : '';
    this.updatedAt = dateProvider(payload.updated_at);

    if (has(payload, 'data')) {
      this.data = payload.data;
    }
    this.data = payload.data;
    this.points = payload.points ? JSON.parse(decrypt(payload.points)) : 0;
    this.updatedAtTimestamp = payload.updated_at;
  }

  getMaterialStatus(): MaterialStatus {
    if (this.status === 'complete' || this.status === 'passed' || this.status === 'completed') {
      return MaterialStatus.PASSED;
    }

    if (
      this.status === 'not_completed' ||
      this.status === 'incomplete' ||
      this.status === 'in_progress'
    ) {
      return MaterialStatus.IN_PROGRESS;
    }

    return MaterialStatus.ASSIGNED;
  }

  getPoints(): number {
    return this.points;
  }

  getPercent(): number {
    return this.percent;
  }
}
