import { MutationTree } from 'vuex';
import { DashboardState } from './types';

export const mutations: MutationTree<DashboardState> = {
  changeWidgets(state, payload) {
    state.widgets = payload;
  },

  changeNextMaterial(state, payload) {
    state.nextMaterial = payload;
  },

  changePersonalData(state, payload) {
    state.personalData = payload;
  },

  changeRating(state, payload) {
    state.rating = payload;
  },

  changeBanner(state, payload) {
    state.banner = payload;
  },
};
