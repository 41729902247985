import each from 'lodash/each';

export class EmployeeStatistic {
  constructor({ id, name, status, order }) {
    this.id = id;
    this.name = name;
    this.status = status;
    this.order = order;
  }
}

export function createEmployeeStatistic(data) {
  const questions = [];

  let status = '';

  each(data, question => {
    switch (question.status) {
      case 'not_correct':
        status = 'notCorrected';
        break;

      case 'correct':
        status = 'corrected';
        break;

      case 'no_answer':
        status = 'notAnswered';
        break;

      default:
        status = '';
        break;
    }

    questions.push(
      Object.freeze(
        new EmployeeStatistic({
          id: question.id,
          name: question.question,
          status,
          order: question.order,
        }),
      ),
    );
  });

  return questions;
}
