<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
  >
    <path
      d="M12.2131 11.3955L14.2305 13.4128L12.2131 15.4302"
      stroke="black"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.2295 13.4126L9.22949 13.4126L5.75655 13.4126C4.36089 13.4126 3.22949 12.2812 3.22949 10.8856V10.8856C3.22949 9.48994 4.36089 8.35853 5.75655 8.35854L11.7778 8.35854C13.1318 8.35854 14.2295 7.26086 14.2295 5.90681V5.90681C14.2295 4.55275 13.1318 3.45508 11.7778 3.45508L6.58978 3.45508"
      stroke="black"
      stroke-width="1.2"
      stroke-linecap="round"
    />
    <circle
      cx="3.62041"
      cy="3.45537"
      r="1.38603"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: 'learning-track-icon',
};
</script>
