<template>
  <base-home-widget
    :widget-name="widget.name"
    :to="{ name: TO_DASHBOARD_PROFILE_FAVORITES, query: { from: 'dashboard-widget' } }"
    :count-text="countText"
  >
    <template #content>
      <app-slider
        :columns="ww4Columns"
        :column-size="ww4ColumnsForIsX"
      >
        <app-material-card
          v-for="(item, key) in widget.favorites"
          :key="key"
          :event-label="'dashboard_favorite_material_btn'"
          :widget="item"
          :is-stack="item.type === 'learning_track'"
          :has-outer-footer="true"
        >
          <template
            v-if="item.type === 'learning_track'"
            #badge
          >
            <div class="app-material-card__badge">
              <div class="app-material-card__badge-icon">
                <LearningTrackIcon />
              </div>
              <span class="app-material-card__badge-label">
                {{ $tc('common_trajectories_default_text') }}
              </span>
            </div>
          </template>
        </app-material-card>
      </app-slider>
    </template>
  </base-home-widget>
</template>
<script>
import { mapGetters } from 'vuex';
import AppSlider from '@/components/helpers/slider.vue';
import AppMaterialCard from '@/components/helpers/cards/material-card.vue';
import FavoritesWidget from '@/entities/modules/dashboard/FavoritesWidget';
import BaseHomeWidget from '@/components/app/home/base-home-widget.vue';
import CustomRoute from '@/entities/common/CustomRoute';
import LearningTrackIcon from '@/components/svg/icons/learning-track.vue';

export default {
  name: 'favorites-widget',

  components: {
    LearningTrackIcon,
    AppSlider,
    AppMaterialCard,
    BaseHomeWidget,
  },

  props: {
    widget: {
      type: FavoritesWidget,
      required: true,
    },
  },

  data: () => ({
    TO_DASHBOARD_PROFILE_FAVORITES: CustomRoute.TO_DASHBOARD_PROFILE_FAVORITES,
  }),

  computed: {
    ...mapGetters('helpers', ['ww4Columns', 'ww4ColumnsForIsX']),

    countText() {
      return this.$tc('common_d_materials_text_caps', this.widget.favorites.length, {
        0: this.widget.favorites.length,
      });
    },
  },
};
</script>
