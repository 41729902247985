import { createUserStatus, UserStatus } from '@/entities/modules/events/UserStatus';
import { createEventType, EventType } from '@/entities/modules/events/EventType';
import { createVisitType, VisitType } from '@/entities/modules/events/VisitType';
import PdfSource, { createPdfSource } from '@/entities/common/sources/PdfSource';
import has from 'lodash/has';

export default class BaseEvent {
  id: number;

  status: UserStatus;
  eventType: EventType;
  visitType: VisitType;

  name: string;
  description: string;

  schedule: string;
  scores: number;
  comment: string;

  address: string;
  url: string;

  trainerName: string;
  trainerOrganization: string;
  maxPoints: number;
  points: number;
  needsToConfirm: boolean;
  isLimitReached: boolean;
  isRating: boolean;
  allowPdfSharing: boolean | null;

  isNew: boolean;

  pdf?: PdfSource;
  isFavorite: boolean;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);

    this.status = createUserStatus(parseInt(payload.user_status, 10));
    this.eventType = createEventType(parseInt(payload.type, 10));
    this.visitType = createVisitType(parseInt(payload.visit_type, 10));

    this.name = payload.name;
    this.description = payload.description;

    this.schedule = payload.schedule;
    this.scores = parseInt(payload.scores, 10);
    this.comment = payload.admin_comment;

    this.address = payload.address;
    this.url = payload.url;

    this.trainerName = payload.trainer_name;
    this.trainerOrganization = payload.trainer_organization;

    this.maxPoints = parseInt(payload.max_points, 10);
    this.points = parseInt(payload.points, 10);
    this.isRating = parseInt(payload.is_rating, 10) === 1;

    this.needsToConfirm = parseInt(payload.confirm_visit, 10) === 1;
    this.isLimitReached = parseInt(payload.is_limit_reached, 10) === 1;
    this.allowPdfSharing = payload.custom_settings
      ? parseInt(payload.custom_settings.allow_pdf_sharing, 10) === 1
      : null;

    this.isNew = parseInt(payload.is_new, 10) === 1;
    this.isFavorite = parseInt(payload.is_favorite, 10) === 1;

    if (has(payload.materials, 'pdf.url') && payload.materials.pdf.url) {
      this.pdf = createPdfSource(payload.materials.pdf);
    }
  }

  setFavorite(status: boolean): void {
    this.isFavorite = status;
  }

  setStatus(status: UserStatus) {
    this.status = status;
  }
}
