export class PhoneOrMailVerificationCode {
  constructor({ resendTime = '', expireTime = '' }) {
    this.resendTime = resendTime;
    this.expireTime = expireTime;
  }
}

export function createPhoneOrMailVerificationCode(data) {
  return Object.freeze(
    new PhoneOrMailVerificationCode({
      resendTime: data.resend_time,
      expireTime: data.expire_time,
    }),
  );
}
