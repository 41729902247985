import { MutationTree } from 'vuex';
import { IntercomState } from './types';

export const mutations: MutationTree<IntercomState> = {
  /**
   * Сохранит состояние для Интеркома
   * @param state
   * @param payload
   */
  setShowIntercomIcon(state, payload) {
    state.showIntercomIcon = payload;
  },

  /**
   * Сохранит состояние окна Интеркома
   * @param state
   * @param payload
   */
  setShowIntercomWindow(state, payload) {
    state.showIntercomWindow = payload;
  },
};
